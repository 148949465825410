import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';

@Component({
  selector: 'app-po-item-list-model',
  templateUrl: './po-item-list-model.component.html',
  styleUrls: ['./po-item-list-model.component.scss']
})
export class PoItemListModelComponent implements OnInit {
  poDetails:any = [];

  constructor(
    public dialogRef: MatDialogRef<PoItemListModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private executionPurchaseService:ExecutionPurchaseService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.poDetails = this.data;
  // console.log(this.data)
  }

  close() {
    this.dialogRef.close(true);
  }

}
