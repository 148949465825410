import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPhotoModalComponent } from '../add-photo-modal/add-photo-modal.component';
import { LoadingState } from '../../loading/loading.component';
import { TenderService } from '../../../services/tender.service';
import { projectSiteServices } from '../../../services/project-sites.service';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-site-photo-tab',
  templateUrl: './site-photo-tab.component.html',
  styleUrls: ['./site-photo-tab.component.scss']
})
export class SitePhotoTabComponent implements OnInit {
  @Input() tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  tenderSitePhotoList: any = [];
  projectSiteList: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  form: FormGroup;
  tenderBasicDetails: any = {
    site_location: null
  };
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService,
    private projectSiteServices: projectSiteServices,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      site_location: [null, Validators.required],
    });
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    forkArray.push(this.tenderService.getTenderSitePhotoList(this.tenderId))
    forkArray.push(this.projectSiteServices.getProjectSitesList())
    forkArray.push(this.tenderService.getTenderBasicDetails(this.tenderId))
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
        //console.log(result)
        for (var i = 0; i < result.length; i++) {
          if (i == 0) {
            this.tenderSitePhotoList = result[i]['result'];
          }
          if (i == 1) {
            this.projectSiteList = result[i]['result'];
          }
          if (i == 2) {
            this.tenderBasicDetails = result[i];
          }
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  save() {
    if (this.form.valid) {
     // console.log(this.form.value)
      this.loading = LoadingState.Processing;
      const updateTenderData = {
        site_location: this.form.value.site_location
      }
      this.tenderService.editTender(this.tenderId, updateTenderData).subscribe(
        res => {
         // console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
         // console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  editSitePhoto(index: number) {
   // console.log(index)
    let SitePhotoData = Object.assign({}, this.tenderSitePhotoList[index]);
    let dialogRef = this.dialog.open(AddPhotoModalComponent, {
      width: '525px',
      data: { SitePhotoDetails: SitePhotoData }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result != undefined) {
        if (result['data'] != undefined) {
         // console.log(result['data'])
          this.tenderSitePhotoList[index] = result['data']
         // console.log(this.tenderSitePhotoList)
        }
      }
    })
  }

  viewDoc(documentData){
   // console.log(documentData)
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  deleteSitePhoto(index: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
     // console.log(index)
      this.loading = LoadingState.Processing
      this.tenderService.deleteTenderSitePhoto(this.tenderSitePhotoList[index]['id']).subscribe(
        res => {
        //  console.log(res);
          this.tenderSitePhotoList.splice(index, 1)
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })

   
  }

  openAddPhotoModal() {
    let dialogRef = this.dialog.open(AddPhotoModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result != undefined) {
        if (result['data'] != undefined) {
        //  console.log(result['data'])
          this.tenderSitePhotoList.push(result['data'])
          //console.log(this.tenderSitePhotoList)
        }
      }
    })
  }


  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
