import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-add-data-factor-modal',
  templateUrl: './add-data-factor-modal.component.html',
  styleUrls: ['./add-data-factor-modal.component.scss']
})
export class AddDataFactorModalComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  tenderId: number;
  hydrologicalDetails: any = {
    name: '',
    details: '',
    latitude: '',
    longitude: ''
  };
  form: FormGroup;
  modal_title: string;
  constructor(
    public dialogRef: MatDialogRef<AddDataFactorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
  ) {
    this.tenderId = data['tenderId'];
    if (data['hydrologicalDetails'] != undefined) {
      this.hydrologicalDetails = data['hydrologicalDetails']
    }

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      name: ['', Validators.required],
      details: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      address: [''],
    });

    this.loading = LoadingState.Ready
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
    //console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing
      this.tenderService.addTenderResourceHydrological(this.form.value).subscribe(
        res => {
         // console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update() {
   // console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var hydrologicalId = this.hydrologicalDetails['id']
      this.tenderService.editTenderResourceHydrological(hydrologicalId, this.form.value).subscribe(
        res => {
        //  console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
