import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DailyWorksheetService {


  constructor(
    private http: HttpClient
  ) {

  }

  addDailyWorksheet(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/daily_work/add/', data)
  }

  getDailyWorksheet(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/daily_work/list/?' + params)
  }

  getDailyWorksheetExcel(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/daily_work/list/download/?' + params)
  }

  updateDailyWorksheet(id: any, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/daily_work/edit/' + id + '/', data)
  }

  deleteDailyWorksheet(id: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/daily_work/delete/' + id + '/', {})
  }
}