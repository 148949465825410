import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LoadingState } from '../../loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TenderService } from '../../../services/tender.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddCriteriaModalComponent } from '../add-criteria-modal/add-criteria-modal.component';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-eligibility-inner-data-tab',
  templateUrl: './eligibility-inner-data-tab.component.html',
  styleUrls: ['./eligibility-inner-data-tab.component.scss']
})
export class EligibilityInnerDataTabComponent implements OnInit {
  @Input() tenderId: number;
  @Input() eligibilityType: string;
  @Input() eligibilityList: any;
  @Output() valueChange = new EventEmitter();
  form: FormGroup;
  reasonForm: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  ineligibilityStatus: boolean;
  ineligibilityReason: string;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tenderService: TenderService
  ) { }

  ngOnInit() {
    this.loading = LoadingState.Ready;
   // console.log(this.tenderId)
    this.form = this.formBuilder.group({
      tender_id: [this.tenderId, Validators.required],
      eligibility_type: [this.eligibilityType, Validators.required],
      eligibility_fields: this.formBuilder.array([]),
    });

    this.reasonForm = this.formBuilder.group({
      ineligibility_reason: ['', Validators.required]
    });

    //console.log(this.eligibilityList.length)
   // console.log(this.eligibilityList)
    this.eligibilityList.forEach(y => {

      this.add_eligibility_field(y)
    })

    this.getEligibilityStatusReason();
  }

  getEligibilityStatusReason() {
    this.tenderService.getTenderEligibilityStatusReason(this.tenderId, this.eligibilityType).subscribe(
      res => {
       // console.log(res)
        this.ineligibilityStatus = !res['eligibility_status'];
        this.ineligibilityReason = res['ineligibility_reason'];
      },
      error => {
        //console.log(error);
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  create_eligibility_field(val: any) {
    return this.formBuilder.group({
      field_label: [val['field_label'], Validators.required],
      eligible: [val['eligible'], Validators.required],
      field_value: [val['field_value'], Validators.required],
    });
  }

  add_eligibility_field(val: any) {
    const control = <FormArray>this.form.controls['eligibility_fields'];
    control.push(this.create_eligibility_field(val));
  }

  delete_eligibility_field(index: number) {
    const control = <FormArray>this.form.controls['eligibility_fields'];
    control.removeAt(index);
    this.eligibilityList.splice(index, 1);
    this.valueChange.emit(this.eligibilityList);
  }

  getFormArrayControl(form) {
    return form.get('eligibility_fields').controls
  }

  saveTenderEligibility() {
    if (this.form.valid) {
      //console.log(this.form.value)
      this.loading = LoadingState.Processing
      this.tenderService.tenderEligibilityFieldsAdd(this.form.value).subscribe(
        res => {
         // console.log(res)
          this.ineligibilityStatus = !res['eligibility_status'];
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
         // console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  submitReason() {
    if (this.reasonForm.valid) {
      //console.log(this.reasonForm.value)
      this.loading = LoadingState.Processing
      this.tenderService.addTenderEligibilityStatusReason(this.tenderId, this.eligibilityType, this.reasonForm.value).subscribe(
        res => {
          //console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
         // console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.reasonForm)
    }
  }

  openCriteriaModal() {
    let dialogRef = this.dialog.open(AddCriteriaModalComponent, {
      width: '525px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result['data'] != undefined) {
          var d = {
            field_label: result['data']['field_label'],
            eligible: null,
            field_value: ''
          }
          this.add_eligibility_field(d)
          this.eligibilityList.push(d)
          this.valueChange.emit(this.eligibilityList);
        }
      }

    })
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
