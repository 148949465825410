import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-survey-details-tab',
  templateUrl: './survey-details-tab.component.html',
  styleUrls: ['./survey-details-tab.component.scss']
})
export class SurveyDetailsTabComponent implements OnInit {
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  addTenderConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  activeTab: any = 'photo';

  constructor() {
    this.addTenderConfig = AppPageStateConfig.addTenderConfig.surveyConfig;
    this.activeTab = this.addTenderConfig.tab;
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
  }

  switchNgBTab(id: string) {
    console.log(id)
    this.ctdTabset.select(id);
  }

  onTabChange(event) {
    console.log(event)
    this.activeTab = event.nextId;
   // this.addTenderConfig.tab = this.activeTab
   
  }


}
