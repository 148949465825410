import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bill-approval-confirm',
  templateUrl: './bill-approval-confirm.component.html',
  styleUrls: ['./bill-approval-confirm.component.scss']
})
export class BillApprovalConfirmComponent implements OnInit {

  confirmMessage: string;
  remark: string = '';
  constructor(
    public dialogRef: MatDialogRef<BillApprovalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    //console.log(this.data)
  }
}
