import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-office-setup-approve-tab',
  templateUrl: './office-setup-approve-tab.component.html',
  styleUrls: ['./office-setup-approve-tab.component.scss']
})
export class OfficeSetupApproveTabComponent implements OnInit {
  @Input() projectId:number;
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  officeStructureData:any = [];
  electricalConnectionOffice:any = [];
  waterConnectionOffice:any = [];
  internetConnectionOffice:any = [];
  furnitureOffice:any = [];
  computerOffice:any = [];
  stationaryListOffice:any=[];
  stationaryOffice:any = [];
  toiletOffice:any =[];
  vehicleOffice:any=[];
  vehicleDriverListOffice:any =[];
  bikeOffice:any =[];
  labourHutmentOffice:any = [];
  labourToiletOffice:any = [];
  labourWaterOffice:any = [];
  labourElectricalOffice:any = [];
  materialYardOffice:any = [];
  cementGodownOffice:any = [];
  qcLabOffice:any = [];
  allTypeReqSurveyOffice:any = [];
  safetyOffice:any = [];
  securityRoomOffice:any = [];

  driverCount:number = 4;

  approve:number;
  approveData:any = [];
  


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      project:[this.projectId],
      pre_execution_tabs:[this.tabId],
      approved_status:[null,Validators.required],
      request_modification:[null]

    })

    //console.log(this.projectId);
    if(this.projectId){
      this.loadData();
      this.getStatus();
    }
  }

  loadData() {
    var forkArray = [];

    forkArray.push(this.perExecutionService.getOfficeStructureOffice(this.projectId))
    forkArray.push(this.perExecutionService.getElectricalConnectionOffice(this.projectId))
    forkArray.push(this.perExecutionService.getWaterConnectionOffice(this.projectId))
    forkArray.push(this.perExecutionService.getInternetConnectionOffice(this.projectId))
    forkArray.push(this.perExecutionService.getAllTypefurnitureOffice(this.projectId))
    forkArray.push(this.perExecutionService.getComputerOffice(this.projectId))
    forkArray.push(this.perExecutionService.getStationaryListOffice(this.projectId))
    forkArray.push(this.perExecutionService.getStationaryOffice(this.projectId))
    forkArray.push(this.perExecutionService.getToiletOffice(this.projectId))
    forkArray.push(this.perExecutionService.getVehicleOffice(this.projectId))
    forkArray.push(this.perExecutionService.getVehicleDriverListOffice(this.projectId))
    forkArray.push(this.perExecutionService.getBikeOffice(this.projectId))
    forkArray.push(this.perExecutionService.getLabourHutmentOffice(this.projectId))
    forkArray.push(this.perExecutionService.getLabourToiletOffice(this.projectId))
    forkArray.push(this.perExecutionService.getLabourWaterOffice(this.projectId))
    forkArray.push(this.perExecutionService.getLabourElectricalOffice(this.projectId))
    forkArray.push(this.perExecutionService.getMaterialYardOffice(this.projectId))
    forkArray.push(this.perExecutionService.getCementGodownOffice(this.projectId))
    forkArray.push(this.perExecutionService.getQcLabOffice(this.projectId))
    forkArray.push(this.perExecutionService.getAllTypeReqSurveyOffice(this.projectId))
    forkArray.push(this.perExecutionService.getSafetyOffice(this.projectId))
    forkArray.push(this.perExecutionService.getSecurityRoomOffice(this.projectId))
    
    
    
    
    

    forkJoin(forkArray).subscribe(
      (result: any[]) => {
        //console.log(result);
       for (var i = 0; i < result.length; i++) {
        if (i == 0) {
          this.officeStructureData = result[i]['result'][0];
        }
        if(i == 1){
          this.electricalConnectionOffice = result[i]['result'][0];
        }
        if(i == 2){
          this.waterConnectionOffice = result[i]['result'][0];
        }
        if(i == 3){
          this.internetConnectionOffice = result[i]['result'][0];
        }
        if(i == 4){
          this.furnitureOffice = result[i]['result'];
        }
        if(i==5){
          this.computerOffice =  result[i]['result'][0];
        }
        if(i==6){
          this.stationaryListOffice =  result[i]['result'];
        }
        if(i==7){
          this.stationaryOffice =  result[i]['result'][0];
        }
        if(i==8){
          this.toiletOffice =  result[i]['result'][0];
        }
        if(i==9){
          this.vehicleOffice =  result[i]['result'][0];
        }
        if(i==10){
          this.vehicleDriverListOffice =  result[i]['result'];
          if(this.vehicleDriverListOffice){
            this.driverCount += this.vehicleDriverListOffice.length;
          }
        }
        if(i==11){
          this.bikeOffice =  result[i]['result'][0];
        }
        if(i==12){
          this.labourHutmentOffice =  result[i]['result'][0];
        }
        if(i==13){
          this.labourToiletOffice =  result[i]['result'][0];
        }
        if(i==14){
          this.labourWaterOffice =  result[i]['result'][0];
        }
        if(i==15){
          this.labourElectricalOffice =  result[i]['result'][0];
        }
        if(i==16){
          this.materialYardOffice =  result[i]['result'][0];
        }
        if(i==17){
          this.cementGodownOffice =  result[i]['result'][0];
        }
        if(i==18){
          this.qcLabOffice =  result[i]['result'][0];
        }
        if(i==19){
          this.allTypeReqSurveyOffice =  result[i]['result'];  
        }
        if(i==20){
          this.safetyOffice =  result[i]['result'][0];  
        }
        if(i==21){
          this.securityRoomOffice =  result[i]['result'][0];  
        }
        
        
        
        

       }
        this.loading = LoadingState.Ready;
      },
      err => {
        //console.log(err);
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal server error', '', {
          timeOut: 3000,
        });

      }
    )
  }

  handleChange(e: any) {
    //console.log(e)
    this.approve = e.target.value;
    if(this.approve!=3){
      this.form.patchValue({
        request_modification:null
      })
    }
  }

  save()
  {
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var data;
      if(this.approve!=3){
         data = {
          project:this.projectId,
          pre_execution_tabs:this.tabId,
          approved_status:this.form.value.approved_status
          
        }
      }
      else{
          data = {
          project:this.projectId,
          pre_execution_tabs:this.tabId,
          approved_status:this.form.value.approved_status,
          request_modification:this.form.value.request_modification
         
        }
      }


      this.perExecutionService.addApprovalTab(this.projectId,data).subscribe(
        res =>{
         // console.log(res)
          
          this.loading = LoadingState.Ready;
          this.toastr.error('Data has been submitted.', '', {
            timeOut: 3000,
          });

        },
        error =>{
        this.loading = LoadingState.Ready;
          this.toastr.error('Somthing Went Worng !', '', {
            timeOut: 3000,
          });
        })
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }


  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        //console.log(res);
        if(res['result'][0]){
          this.approveData = res['result'][0];
          this.approve = this.approveData.approved_status;
          this.approveData.approved_status=  this.approveData.approved_status.toString();
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      })

  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }
  
  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }
  
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }
}
