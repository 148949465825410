import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-utilities-utensils-inner-tab',
  templateUrl: './utilities-utensils-inner-tab.component.html',
  styleUrls: ['./utilities-utensils-inner-tab.component.scss']
})
export class UtilitiesUtensilsInnerTabComponent implements OnInit {
  
  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  utensilsDocumentList:any = [];
  loading: LoadingState = LoadingState.NotReady;
  utensilsListData :any = [];
  utensilsDetails: any = [];
  utensilsTypeDetails :any = []
  listEdit:boolean;
  approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    //console.log(this.projectId);

    this.form = this.formBuilder.group({
      available:[null,Validators.required],
      utensils_types:this.formBuilder.group({
        type_of_utensils:['',Validators.required],
        quantity:['',Validators.required],
        rate:['',Validators.required],
      }),
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
       // this.createDocument()
      ])
    })

    if(this.projectId){
      this.getUtensilsDetails();
      this.getUtensilsList();
      this.getStatus();
    }
   
   
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      utensils_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }

  save()
  {
   if(this.form.valid){
    this.loading = LoadingState.Processing;
    var utensils_documents_data = [];
    utensils_documents_data = this.form.value.documents;

    const data = {
      project:this.projectId,
      available:this.form.value.available,
      requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
      requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
      budgeted_cost:this.form.value.budgeted_cost,
      executed_cost:this.form.value.executed_cost,
      utensils_types:
      {             
        project:this.projectId,
        type_of_utensils:this.form.value.utensils_types.type_of_utensils,
        quantity:this.form.value.utensils_types.quantity,
        rate:this.form.value.utensils_types.rate
      }
    }
    //console.log(data);
    this.utensilsDocumentList = [];
    this.perExecutionService.addUtensilsUtilitiesAminites(data).subscribe(
      res => {
        console.log(res);
        var forkArray = [];

        utensils_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project",this.projectId);
            formData.append("module_id", res.utensils_types.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.utensils_document, x.utensils_document['name']);
            forkArray.push(this.perExecutionService.addUtensilsDocumentUtilitiesAminites(formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
            // console.log(result)
            result.forEach(y => {
              this.utensilsDocumentList.push(y)
            })
            
            // this.add_tender_document()
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )

        this. getUtensilsList(); 
        this.loading = LoadingState.Ready;
      },
      error=>{
        console.log(error);
        this.loading = LoadingState.Ready;
      })




    }else
    {
     this.markFormGroupTouched(this.form);
    }
  }

  getUtensilsDetails(){
    this.perExecutionService.getUtensilsUtilitiesAminites(this.projectId).subscribe(
      res => {
       if(res['result'][0]){
        this.utensilsDetails = res['result'][0];
        console.log(this.utensilsDetails);

        var start_date = new Date(this.utensilsDetails['requirment_s_date'])
            this.utensilsDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.utensilsDetails['requirment_e_date'])
            this.utensilsDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          
          this.utensilsDetails.budgeted_cost = parseFloat(this.utensilsDetails.budgeted_cost).toFixed(2);
          this.utensilsDetails.executed_cost = parseFloat(this.utensilsDetails.executed_cost).toFixed(2);
       }
       
        this.loading = LoadingState.Ready;
      },
      error =>{
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }
  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }

  getUtensilsList(){
    
    this.perExecutionService.getUtensilsListUtilitiesAminites(this.projectId).subscribe(
      res => {
        this.utensilsListData = res['result'];
        console.log("List");
         console.log(this.utensilsListData);
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  deleteUtensilsList(id){

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteUtensilsListUtilitiesAminites(id).subscribe(
        res => {
         this.getUtensilsList();
           console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been Deleted','',{
            timeOut: 3000,
          });
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
	 }
	 this.dialogRef = null;
	 })
   
  }


  update(){
    if(this.form.valid){

      this.loading = LoadingState.Processing;
      var utensils_documents_data = [];
      utensils_documents_data = this.form.value.documents;

      const updateData = {
        project:this.projectId,
        available:this.form.value.available,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
        utensils_types:
        {             
          project:this.projectId,
          type_of_utensils:this.form.value.utensils_types.type_of_utensils,
          quantity:this.form.value.utensils_types.quantity,
          rate:this.form.value.utensils_types.rate
        }
      }
    //console.log(updateData);

  this.perExecutionService.editUtensilsUtilitiesAminites(this.utensilsDetails.id,updateData).subscribe(
    res =>{
      // console.log(res);
      // console.log(res.tiffin_box_types.id)
     
      if(this.form.value.documents.length > 0){
        var forkArray = [];
        utensils_documents_data.forEach(x => {
          const formData: any = new FormData();
          formData.append("project",this.projectId);
          formData.append("module_id", res.utensils_types.id);
          formData.append("document_name", x.document_name);
          formData.append("document", x.utensils_document, x.utensils_document['name']);
          forkArray.push(this.perExecutionService.addUtensilsDocumentUtilitiesAminites(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.utensilsDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
           
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
       }
      this.getUtensilsList();
      this.loading = LoadingState.Ready;
      this.toastr.success('Data has been saved','',{
        timeOut: 3000,
      });
    },
    error =>{
      this.loading = LoadingState.Ready;
      this.toastr.error('Something went wrong', '', {
        timeOut: 3000,
      });
      console.log(error)
    })
  }
  else{
    this.markFormGroupTouched(this.form);
    console.log("error");
  }
}

updateUtensils()
{
  //console.log(this.tiffinBoxTypeDetails);
  //console.log(this.tiffinBoxDetails);
  var utensils_documents_data = [];
  utensils_documents_data = this.form.value.documents;

  if(this.form.valid){

    this.loading = LoadingState.Processing;

    const updateUtensilsData = {
      type_of_utensils: this.form.value.utensils_types.type_of_utensils,
      quantity: this.form.value.utensils_types.quantity,
      rate: this.form.value.utensils_types.rate,
      project: this.projectId,
      utensils: this.utensilsTypeDetails.utensils,
      utensils_details:{
        available:this.form.value.available,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }
    }
    console.log(updateUtensilsData);

    //this.tiffinBoxDocumentList = [];
    this.perExecutionService.editUtensilsListUtilitiesAminites(this.utensilsTypeDetails.id,updateUtensilsData).subscribe(
      res =>{
        console.log(res);
        var forkArray = [];

        if(this.form.value.documents.length > 0){
          utensils_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project",this.projectId);
            formData.append("module_id", this.utensilsTypeDetails.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.utensils_document, x.utensils_document['name']);
            forkArray.push(this.perExecutionService.addUtensilsDocumentUtilitiesAminites(formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
            // console.log(result)
            result.forEach(y => {
              this.utensilsDocumentList.push(y)
            })
            
            // this.add_tender_document()
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
    }
    this.getUtensilsList(); 
    this.loading = LoadingState.Ready;
    this.toastr.success('Data has been saved','',{
      timeOut: 3000,
    });
      },
      error =>{
        console.log(error);
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
    

  }else{
    console.log("error");
  }
}

  editUtensils(data){
    this.utensilsTypeDetails = data;
    console.log(this.utensilsTypeDetails);
    this.utensilsDocumentList = this.utensilsTypeDetails.utensils_document_details;
    this.listEdit = true;
    this.getUtensilsList(); 
  }


  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.utensilsDocumentList[index]['isEdit'] = true
    this.utensilsDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.utensilsDocumentList[index]['document_name'] == '') {
      this.utensilsDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.utensilsDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.utensilsDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editTiffinBoxDocumentUtilitiesAminites(this.utensilsDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.utensilsDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.loading = LoadingState.Processing
    this.perExecutionService.deleteTiffinBoxDocumentUtilitiesAminites(this.utensilsDocumentList[index]['id']).subscribe(
      res => {
        this.utensilsDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
        this.getUtensilsList();
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }

  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }



  onSelectFile(event, index){
    console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          utensils_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }



  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.markFormGroupTouched(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
   }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
