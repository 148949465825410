import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-tender-approval-tab',
  templateUrl: './tender-approval-tab.component.html',
  styleUrls: ['./tender-approval-tab.component.scss']
})
export class TenderApprovalTabComponent implements OnInit {
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  tenderApprovalDetails: any = {
    is_approved: null,
    reject_reason: ''
  };
  visible_key: boolean;
  constructor(
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reject_reason: ['', Validators.required]
    });
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    forkArray.push(this.tenderService.getTenderApprovalDetails(this.tenderId))
    forkJoin(forkArray).subscribe(
      ([approvalData]) => {
        //console.log(approvalData)
        if(approvalData['result'] != "" && approvalData['result'] != null){
          this.tenderApprovalDetails = approvalData['result'];
          //console.log(this.tenderApprovalDetails);
        }
        this.visible_key = true;
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  handleChange(e: any) {
    //console.log(this.tenderApprovalDetails)
    if (this.tenderApprovalDetails.is_approved) {
      var data = {
        tender: this.tenderId,
        is_approved: this.tenderApprovalDetails.is_approved
      }
      this.loading = LoadingState.Processing
      this.tenderService.addTenderApproval(this.tenderId, data).subscribe(
        res => {
         // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  save() {
   // console.log(this.form.value)
    if (this.form.valid) {
      var data = {
        tender: this.tenderId,
        is_approved: this.tenderApprovalDetails.is_approved,
        reject_reason: this.form.value.reject_reason
      }
      this.loading = LoadingState.Processing
      this.tenderService.addTenderApproval(this.tenderId, data).subscribe(
        res => {
         // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
