import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../components/loading/loading.component';
import { AttendanceService } from '../../../services/attendance.service';
import { EmployeesService } from '../../../services/employees.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.scss']
})
export class ApprovalDialogComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  attendanceApprovalData: any;
  leaveApprovalData: any
  conveyanceApprovalData: any
  missPunchData: any
  specialLeaveReportData: any
  isModify: boolean = true;
  approvedStatusValid: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private attendanceService: AttendanceService,
    private employeesService: EmployeesService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    //console.log(this.data)
    this.form = this.formBuilder.group({
      approved_status: [null, Validators.required],
      remarks: [null]
    })

    if (this.data.from == 'leaveApproval') {
      this.leaveApprovalData = this.data['result'];
      // console.log(this.leaveApprovalData)
    }
  }



  leaveApply() {
    this.loading = LoadingState.Processing;
    if (!this.form.value.approved_status) {
      this.approvedStatusValid = true;
    } else {
      this.approvedStatusValid = false;
    }

    if (this.form.valid) {
      if (this.data.leaveType == 'advance') {
        let data = {
          remarks: this.form.value.remarks,
          advance_leaves_approvals: [
            {
              req_id: this.leaveApprovalData.id, approved_status: this.form.value.approved_status
            }
          ]
        }

        this.attendanceService.addMultiAdavnceLeaveApproval(data).subscribe(
          res => {
            this.close(true);
            this.toastr.success('Your request has been added.', '', {
              timeOut: 3000,
            });
            this.loading = LoadingState.Ready;
          },
          error => {
            this.close(false);
            this.loading = LoadingState.Ready;
            this.toastr.error('Internal Server error !', '', {
              timeOut: 3000,
            });
          }
        );
      }
      if (this.data.leaveType == 'normal') {
        let data = {
          remarks: this.form.value.remarks,
          attendence_approvals: [
            {
              req_id: this.leaveApprovalData.id,
              approved_status: this.form.value.approved_status
            }
          ]
        }

        this.attendanceService.addMultiAttendanceApprovalV2(data).subscribe(
          res => {
            this.close(true);
            this.toastr.success('Your request has been added.', '', {
              timeOut: 3000,
            });
            this.loading = LoadingState.Ready;
          },
          error => {
            this.close(false);
            this.loading = LoadingState.Ready;
            this.toastr.error('Internal Server error !', '', {
              timeOut: 3000,
            });
          }
        );
      }

    } else {
      this.markFormGroupTouched(this.form)
    }


  }

  close(status) {
    this.dialogRef.close(status);
  }




  leaveRadioChange(event) {
    if (event.value != '') {
      this.approvedStatusValid = false;
    }
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
