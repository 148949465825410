import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  defaultPagination: number;
  advancedPagination: number;
  paginationSize: number;
  disabledPagination: number;
  isDisabled: boolean;

  constructor() {
      this.defaultPagination = 1;
      this.advancedPagination = 1;
      this.paginationSize = 1;
      this.disabledPagination = 1;
      this.isDisabled = true;
  }
  ngOnInit(){
    
  }
  toggleDisabled() {
      this.isDisabled = !this.isDisabled;
  }
}
