import { Component, OnInit,Input } from '@angular/core';
import { LoadingState } from '../../loading/loading.component';
import {LiveTrackingService } from '../../../services/live-tracking.service';
import { ToastrService } from 'ngx-toastr';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-employee-live-tracking',
  templateUrl: './employee-live-tracking.component.html',
  styleUrls: ['./employee-live-tracking.component.scss']
})
export class EmployeeLiveTrackingComponent implements OnInit {
  @Input() employeeDetails: any;

  zoom: number = 12;
  lat:number;
  lng:number;
  siteLat:number;
  siteLng:number;
  projectSiteLat:number;
  projectSitelng:number;
  officeLat:number;
  officeLng:number;
  radius:number;
  markers: marker[] =[];
  gestHouseLat:number;
  gestHouseLng:number;
  long_lat_details:any = [];
  

  constructor(
    public liveTrackingService:LiveTrackingService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    //console.log(this.employeeDetails)
    if(this.employeeDetails.current_project_details!=null){
      this.projectSitelng = parseFloat(this.employeeDetails.current_project_details.user_project__site_location__site_longitude);
      this.projectSiteLat = parseFloat(this.employeeDetails.current_project_details.user_project__site_location__site_latitude);
      this.siteLng = parseFloat(this.employeeDetails.current_project_details.user_project__site_location__site_longitude);
      this.siteLat = parseFloat(this.employeeDetails.current_project_details.user_project__site_location__site_latitude);
      this.radius = this.employeeDetails.current_project_details.user_project__site_location__geo_fencing_area!=null?parseInt(this.employeeDetails.current_project_details.user_project__site_location__geo_fencing_area):1000;

      this.officeLng = parseFloat(this.employeeDetails.current_project_details.user_project__site_location__office_latitude);
      this.officeLat = parseFloat(this.employeeDetails.current_project_details.user_project__site_location__office_longitude);

      this.gestHouseLat=parseFloat(this.employeeDetails.current_project_details.user_project__site_location__gest_house_latitude);
      this.gestHouseLng=parseFloat(this.employeeDetails.current_project_details.user_project__site_location__gest_house_longitude);

      this.long_lat_details = this.employeeDetails.current_project_details.lat_lng;

      if(this.employeeDetails.current_project_details.current_position_details!=null){
        this.markers.push({
          lat: parseFloat(this.employeeDetails.current_project_details.current_position_details.latitude),
          lng: parseFloat(this.employeeDetails.current_project_details.current_position_details.longitude),
          label:'',
          lb:this.employeeDetails.current_project_details.current_position_details.address,
          draggable: false
        });
      }
     // console.log(this.markers)
      }
      else{
        this.siteLat = 22.5726;
        this.siteLng = 88.3639;
      }
     
  }




  clickedMarker(label: string, index: number) {
    //console.log(`clicked the marker: ${label || index}`)
  }
  
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    //console.log('dragEnd', m, $event);
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  lb?:string;
  draggable: boolean;
}

