import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-add-designation-modal',
  templateUrl: './add-designation-modal.component.html',
  styleUrls: ['./add-designation-modal.component.scss']
})
export class AddDesignationModalComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  tenderId: number;
  designationDetails: any = {
    name: ''
  };
  form: FormGroup;
  modal_title: string;
  constructor(
    public dialogRef: MatDialogRef<AddDesignationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
  ) {
    this.tenderId = data['tenderId'];
    if (data['designationDetails'] != undefined) {
      this.designationDetails = data['designationDetails']
    }

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      name: ['', Validators.required]
    });

    this.loading = LoadingState.Ready
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
  //  console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing
      this.tenderService.addTenderResourceDesignation(this.form.value).subscribe(
        res => {
         // console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update() {
   // console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var designationId = this.designationDetails['id']
      this.tenderService.editTenderResourceDesignation(designationId, this.form.value).subscribe(
        res => {
        //  console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
