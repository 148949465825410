import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManpowerService {
  constructor(
    private http: HttpClient
  ) {

  }

  getManpowerList(module_id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'manpower_list/' + module_id + '/')
  }

  getManpowerListByProject(module_id: number, params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'manpower_list/' + module_id + '/?' + params)
  }

  manpowerTransfer(data) {
    return this.http.post(environment.apiEndpoint + 'projects_manpower_reassign_transfer/', data)
  }

  machinaryTransfer(data) {
    return this.http.post(environment.apiEndpoint + 'projects_machinary_reassign_transfer/', data)
  }


  getManpowerListWP(module_id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'manpower_list_wo_pagination/' + module_id + '/')
  }
  getDesignationList(module_id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'manpower_designation_list/?mmg_module=' + module_id)
  }
}
