import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
//import {environment.apiEndpoint,preExecutionToken} from '../../core/globals';


@Injectable({
  providedIn: 'root'
})
export class PerExecutionService {

  constructor(private http: HttpClient) {


  }

  //Project Lists

  getActiveProjectList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list/?state=' + id)
  }

  getActiveProjectListWithPagination(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list/?' + params)
  }


  addGuestHouseFinding(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_execution_guest_house_finding_add/', data)
  }
  getGuestHouseFinding(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_execution_guest_house_finding_add/?project=' + id)
  }
  updateGuestHouseFinding(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_execution_guest_house_finding_edit/' + id + '/', data)
  }


  // furnitures

  getfurnitureSiteMobilization(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_execution_furniture_add/?project=' + id)
  }

  addfurnitureSiteMobilization(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_execution_furniture_add/', data)
  }

  addfurnitureRequirementSiteMobilization(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_execution_furniture_requirements_add/', data)
  }

  editfurnitureSiteMobilization(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_execution_furniture_edit/' + id + '/', data)
  }

  getfurnitureTypeSiteMobilization(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_execution_furniture_requirements_add/?f_requirements__project=' + id)
  }

  editfurnitureTypeSiteMobilization(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_execution_furniture_requirements_edit/' + id + '/', data)
  }

  editfurnitureTypeDocumentSiteMobilization(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_execution_furniture_requirements_document_edit/' + id + '/', data)
  }

  deletefurnitureTypeSiteMobilization(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_execution_furniture_requirements_delete/' + id + '/', {})
  }



  /* Utilities & Aminites */


  addCookUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_cook_add/', data)
  }

  getCookUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_cook_add/?project=' + id)
  }

  updateCookUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_cook_edit/' + id + '/', data)
  }


  addWaterUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_water_add/', data)
  }

  addWaterDocumentUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_water_document_add/', data)
  }

  getWaterDeatilsUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_water_add/?project=' + id)
  }

  editWaterDeatilsUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_water_edit/' + id + '/', data)
  }

  getWaterDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_water_document_add/?project=' + id)
  }

  editWaterDocumentUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_water_document_edit/' + id + '/', data)
  }

  deleteWaterDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_water_document_delete/' + id + '/', {})
  }

  // fuel

  addFuelUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_fuel_add/', data)
  }

  getFuelUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_fuel_add/?project=' + id)
  }

  editFuelUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_fuel_edit/' + id + '/', data)
  }

  getFuelDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_fuel_document_add/?project=' + id)
  }

  addFuelDocumentUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_fuel_document_add/', data)
  }

  editFuelDocumentUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_fuel_document_edit/' + id + '/', data)
  }

  deleteFuelDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_fuel_document_delete/' + id + '/', {})
  }

  // electrical

  addElectricalUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_electrical_add/', data)
  }

  getElectricalUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_electrical_add/?project=' + id)
  }

  editElectricalUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_electrical_edit/' + id + '/', data)
  }

  getElectricalDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_electrical_document_add/?project=' + id)
  }

  addElectricalDocumentUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_electrical_document_add/', data)
  }

  editElectricalDocumentUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_electrical_document_edit/' + id + '/', data)
  }

  deleteElectricalDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_electrical_document_delete/' + id + '/', {})
  }

  // tiffin Box

  getTiffinBoxUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_add/?project=' + id)
  }

  addTiffinBoxUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_add/', data)
  }

  editTiffinBoxUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_edit/' + id + '/', data)
  }

  getTiffinBoxListUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_types_list/?project=' + id)
  }

  addTiffinBoxDocumentUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_document_add/', data)
  }

  editTiffinBoxDocumentUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_document_edit/' + id + '/', data)
  }

  deleteTiffinBoxDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_document_delete/' + id + '/', {})
  }

  deleteTiffinBoxListUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_types_delete/' + id + '/', {})
  }

  getTiffinBoxDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_document_add/?project=' + id)
  }

  editTiffinBoxListUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_tiffin_box_types_edit/' + id + '/', data)
  }

  // utensils

  getUtensilsUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_utensils_add/?project=' + id)
  }

  addUtensilsUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_utensils_add/', data)
  }

  editUtensilsUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_utensils_edit/' + id + '/', data)
  }

  getUtensilsListUtilitiesAminites(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_utilities_utensils_types_list/?project=' + id)
  }

  addUtensilsDocumentUtilitiesAminites(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_utilities_utensils_document_add/', data)
  }

  editUtensilsDocumentUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_utensils_document_edit/' + id + '/', data)
  }

  deleteUtensilsDocumentUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_utensils_document_delete/' + id + '/', {})
  }

  deleteUtensilsListUtilitiesAminites(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_utensils_types_delete/' + id + '/', {})
  }

  editUtensilsListUtilitiesAminites(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_utilities_utensils_types_edit/' + id + '/', data)
  }

  // Office setup



  // getOfficeSetupMaster(): Observable<any>
  // {
  //   return this.http.get(environment.apiEndpoint + 'pre_excution_office_set_up_master_add/')
  // }

  addOfficeStructureOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_execution_office_structure_add/', data)
  }

  editOfficeStructureOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_execution_office_structure_edit/' + id + '/', data)
  }

  getOfficeStructureOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_execution_office_structure_add/?project=' + id)
  }

  addOfficeStructureDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_execution_office_structure_document_add/', data)
  }

  //connection
  // Electrical

  addElectricalConnectionOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_electrical_connection_add/', data)
  }

  getElectricalConnectionOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_electrical_connection_add/?project=' + id)
  }

  editElectricalConnectionOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_electrical_connection_edit/' + id + '/', data)
  }

  addElectricalConnectionDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_electrical_connection_document_add/', data)
  }

  editElectricalConnectionDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_electrical_connection_document_edit/' + id + '/', data)
  }

  deleteElectricalConnectionDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_electrical_connection_document_delete/' + id + '/', {})
  }

  // water

  addWaterConnectionOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_water_connection_add/', data)
  }

  getWaterConnectionOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_water_connection_add/?project=' + id)
  }

  editWaterConnectionOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_water_connection_edit/' + id + '/', data)
  }

  addWaterConnectionDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_water_connection_document_add/', data)
  }

  editWaterConnectionDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_water_connection_document_edit/' + id + '/', data)
  }

  deleteWaterConnectionDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_water_connection_document_delete/' + id + '/', {})
  }

  //internet

  addInternetConnectionOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_internet_connection_add/', data)
  }

  getInternetConnectionOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_internet_connection_add/?project=' + id)
  }

  editInternetConnectionOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_internet_connection_edit/' + id + '/', data)
  }

  addInternetConnectionDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_internet_connection_document_add/', data)
  }

  editInternetConnectionDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_internet_connection_document_edit/' + id + '/', data)
  }

  deleteInternetConnectionDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_internet_connection_document_delete/' + id + '/', {})
  }

  //office set up Furniture

  addfurnitureOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_furniture_add/', data)
  }

  getfurnitureOffice(typeId: number, id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_furniture_add/?furniture_type=' + typeId + '&project=' + id)
  }

  getAllTypefurnitureOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_furniture_add/?project=' + id)
  }

  editfurnitureOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_furniture_edit/' + id + '/', data)
  }

  addfurnitureDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_furniture_document_add/', data)
  }

  editfurnitureDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_furniture_document_edit/' + id + '/', data)
  }

  deletefurnitureDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_furniture_document_delete/' + id + '/', {})
  }

  //office set up Computer

  addComputerOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_computer_add/', data)
  }

  getComputerOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_computer_add/?project=' + id)
  }

  editComputerOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_computer_edit/' + id + '/', data)
  }

  addComputerDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_computer_document_add/', data)
  }

  editComputerDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_computer_document_edit/' + id + '/', data)
  }

  deleteComputerDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_computer_document_delete/' + id + '/', {})
  }

  //office set up Stationary

  getStationaryOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_stationary_add/?project=' + id)
  }

  addStationaryOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_stationary_add/', data)
  }

  editStationaryOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_stationary_edit/' + id + '/', data)
  }

  getStationaryListOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_list/?project=' + id)
  }

  addStationaryDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_document_add/', data)
  }

  getStationaryDocumentOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_document_add/?project=' + id)
  }
  editStationaryDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_document_edit/' + id + '/', data)
  }

  deleteStationaryDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_document_delete/' + id + '/', {})
  }

  deleteStationaryListOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_delete/' + id + '/', {})
  }

  editStationaryListOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_stationary_requirements_edit/' + id + '/', data)
  }

  //office set up Toilet


  addToiletOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_toilet_add/', data)
  }

  getToiletOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_toilet_add/?project=' + id)
  }

  editToiletOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_toilet_edit/' + id + '/', data)
  }

  addToiletDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_toilet_document_add/', data)
  }

  editToiletDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_toilet_document_edit/' + id + '/', data)
  }

  deleteToiletDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_toilet_document_delete/' + id + '/', {})
  }

  //office set up bike

  addBikeOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_bike_add/', data)
  }

  getBikeOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_bike_add/?project=' + id)
  }

  editBikeOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_bike_edit/' + id + '/', data)
  }

  addBikeDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_bike_document_add/', data)
  }

  editBikeDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_bike_document_edit/' + id + '/', data)
  }

  deleteBikeDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_bike_document_delete/' + id + '/', {})
  }


  //office set up Labour hutment

  addLabourHutmentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_labour_hutment_add/', data)
  }

  getLabourHutmentOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_labour_labour_hutment_add/?project=' + id)
  }

  editLabourHutmentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_labour_hutment_edit/' + id + '/', data)
  }

  addLabourHutmentDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_labour_hutment_document_add/', data)
  }

  editLabourHutmentDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_labour_hutment_document_edit/' + id + '/', data)
  }

  delteLabourHutmentDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_labour_hutment_document_delete/' + id + '/', {})
  }


  //office set up Labour Toilet

  addLabourToiletOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_toilet_add/', data)
  }

  getLabourToiletOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_labour_toilet_add/?project=' + id)
  }

  editLabourToiletOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_toilet_edit/' + id + '/', data)
  }

  addLabourToiletDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_toilet_document_add/', data)
  }

  editLabourToiletDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_toilet_document_edit/' + id + '/', data)
  }

  deleteLabourToiletDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_toilet_document_delete/' + id + '/', {})
  }

  //office set up Labour Toilet water

  addLabourWaterOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_water_connection_add/', data)
  }

  getLabourWaterOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_labour_water_connection_add/?project=' + id)
  }

  editLabourWaterOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_water_connection_edit/' + id + '/', data)
  }

  addLabourWaterDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_water_connection_document_add/', data)
  }

  editLabourWaterDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_water_connection_document_edit/' + id + '/', data)
  }

  delteLabourWaterDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_water_connection_document_delete/' + id + '/', {})
  }


  //office set up Labour  Electrical

  addLabourElectricalOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_electrical_connection_add/', data)
  }

  getLabourElectricalOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_labour_electrical_connection_add/?project=' + id)
  }

  editLabourElectricalOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_electrical_connection_edit/' + id + '/', data)
  }

  addLabourElectricalDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_labour_electrical_connection_document_add/', data)
  }

  editLabourElectricalDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_electrical_connection_document_edit/' + id + '/', data)
  }

  deleteLabourElectricalDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_labour_electrical_connection_document_delete/' + id + '/', {})
  }

  //office set up Raw Material yard

  addMaterialYardOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_raw_material_yard_add/', data)
  }

  getMaterialYardOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_raw_material_yard_add/?project=' + id)
  }

  editMaterialYardOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_raw_material_yard_edit/' + id + '/', data)
  }

  addMaterialYardDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_raw_material_yard_document_add/', data)
  }

  editMaterialYardDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_raw_material_yard_document_edit/' + id + '/', data)
  }

  deleteMaterialYardDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_raw_material_yard_document_delete/' + id + '/', {})
  }


  //office set up cement godown

  addCementGodownOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_cement_go_down_add/', data)
  }

  getCementGodownOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_cement_go_down_add/?project=' + id)
  }

  editCementGodownOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_cement_go_down_edit/' + id + '/', data)
  }

  addCementGodownDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_cement_go_down_document_add/', data)
  }

  editCementGodownDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_cement_go_down_document_edit/' + id + '/', data)
  }

  deleteCementGodownDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_cement_go_down_document_delete/' + id + '/', {})
  }


  //office set up Qc Lab

  addQcLabOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_lab_add/', data)
  }

  getQcLabOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_lab_add/?project=' + id)
  }

  editQcLabOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_lab_edit/' + id + '/', data)
  }

  addQcLabDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_lab_document_add/', data)
  }

  editQcLabDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_lab_document_edit/' + id + '/', data)
  }

  delteQcLabDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_lab_document_delete/' + id + '/', {})
  }


  //office set up required surevey

  getReqSurveyOffice(id: number, type: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_add/?project=' + id + '&survey_instrument_type_tab=' + type)
  }

  getAllTypeReqSurveyOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_add/?project=' + id)
  }


  addReqSurveyOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_add/', data)
  }

  editReqSurveyOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_edit/' + id + '/', data)
  }

  addReqSurveyInstrumentDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_types_document_add/', data)
  }

  editReqSurveyInstrumentDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_types_document_edit/' + id + '/', data)
  }

  deleteReqSurveyInstrumentDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_types_document_delete/' + id + '/', {})
  }

  deleteReqSurveyInstrumentListOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_types_delete/' + id + '/', {})
  }

  getReqSurveyInstrumentListOffice(id: number, type: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_types_list/?project=' + id + '&survey_instrument__survey_instrument_type_tab=' + type)
  }

  editReqSurveyInstrumentListOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_survey_instrument_types_edit/' + id + '/', data)
  }

  //office set up safety

  getSafetyOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_add/?project=' + id)
  }

  addSafetyOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_add/', data)
  }

  editSafetyOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_edit/' + id + '/', data)
  }

  addSafetyAccessoryDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_document_add/', data)
  }

  editSafetyAccessoryDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_document_edit/' + id + '/', data)
  }

  deleteSafetyAccessoryDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_document_delete/' + id + '/', {})
  }
  getSafetyAccessoryListOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_accessory_list/?project=' + id)
  }

  deleteSafetyAccessoryListOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_accessory_delete/' + id + '/', {})
  }

  editSafetyAccessoryListOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_safety_ppes_accessory_edit/' + id + '/', data)
  }

  // office set up Security Room



  addSecurityRoomOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_security_room_add/', data)
  }

  editSecurityRoomOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_security_room_edit/' + id + '/', data)
  }

  getSecurityRoomOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_security_room_add/?project=' + id)
  }

  addSecurityRoomDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_security_room_document_add/', data)
  }

  editSecurityRoomDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_security_room_document_edit/' + id + '/', data)
  }

  deleteSecurityRoomDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_security_room_document_delete/' + id + '/', {})
  }



  // office set up Vehicle
  addVehicleOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_add/', data)
  }

  editVehicleOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_edit/' + id + '/', data)
  }

  getVehicleOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_add/?project=' + id)
  }

  getVehicleDriverListOffice(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_driver_list/?project=' + id)
  }

  addVehicleDocumentOffice(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_document_add/', data)
  }

  editVehicleDocumentOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_document_edit/' + id + '/', data)
  }

  deleteVehicleDocumentOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_document_delete/' + id + '/', {})
  }

  deleteVehicleDriverListOffice(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_driver_delete/' + id + '/', {})
  }

  editVehicleDriverOffice(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_office_setup_vehicle_driver_edit/' + id + '/', data)
  }

  // P AND M

  getMasterPandM(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_p_and_m_master_add/?project=' + id)
  }
  machinaryTypeListByProject(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machinary_type_list_by_project/' + id + '/')
  }

  addDetailsPandM(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_p_and_m_details_add/', data)
  }

  addDetailsByTypePandM(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_p_and_m_machinery_type_details_add/', data)
  }
  addDetailsByPandMType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_p_and_m_machinery_type_ex_details_add/', data)
  }

  editDetailsPandM(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_p_and_m_details_edit/' + id + '/', data)
  }
  editDetailsByTypePandM(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_p_and_m_details_edit/' + id + '/', data)
  }

  getDetailsPandM(id: number, pm: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_p_and_m_details_add/?project=' + id + '&p_and_m=' + pm)
  }

  getDetailsByTypePandM(id: number, pm: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_p_and_m_machinery_type_ex_details_add/?project=' + id + '&machinary_type=' + pm)
  }

  addDetailsDocumentPandM(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_p_and_m_details_document_add/', data)
  }

  editDetailsDocumentPandM(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_p_and_m_details_document_edit/' + id + '/', data)
  }

  deleteDetailsDocumentPandM(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_p_and_m_details_document_delete/' + id + '/', {})
  }


  //Manpower

  // getMasterManpower(id:number): Observable<any>
  // {
  //   return this.http.get(environment.apiEndpoint + 'pre_excution_manpower_master_add/?project='+id)
  // }

  getMasterManpower(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'manpower_designation_list_wo_pagination/')
  }

  getManpowerPerDesignation(moduleId: number, designationId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'manpower_list_by_designation_id_wo_pagination/' + moduleId + '/' + designationId + '/')
  }


  addDetailsManpower(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_manpower_requirement_add/', data)
  }

  getDetailsManpower(id: number, dId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_manpower_requirement_add/?project=' + id + '&designation=' + dId)
  }

  editDetailsManpower(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_manpower_requirement_edit/' + id + '/', data)
  }

  getListDetailsManpower(id: number, mm: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_manpower_details_list/?project=' + id + '&manpower_requirement__manpower_master=' + mm)
  }

  editListDetailsManpower(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_manpower_requirement_edit/' + id + '/', data)
  }

  deleteListDetailsManpower(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_manpower_requirement_delete/' + id + '/', {})
  }

  addDocumentManpower(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_manpower_details_document_add/', data)
  }

  editDocumentManpower(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_manpower_details_document_edit/' + id + '/', data)
  }

  delteDocumentManpower(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_manpower_details_document_delete/' + id + '/', {})
  }


  //Site Puja

  addSitePuja(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_site_puja_add/', data)
  }

  getSitePuja(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_site_puja_add/?project=' + id)
  }

  editSitePuja(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_site_puja_edit/' + id + '/', data)
  }

  //Cost Analysis

  addDocumentCostAnalysis(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_cost_analysis_add/', data)
  }

  getDocumentCostAnalysis(id: number, type: number) {
    return this.http.get(environment.apiEndpoint + 'pre_excution_cost_analysis_add/?project=' + id + '&analysis_type=' + type)
  }

  getAllTypeDocumentCostAnalysis(id: number) {
    return this.http.get(environment.apiEndpoint + 'pre_excution_cost_analysis_add/?project=' + id)
  }



  //Contactor Finalization

  addContractorFinalization(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pre_excution_contractor_finalization_add/', data)
  }

  getMachineryListWp(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_wp_list/')
  }

  getExternalUserList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_add/')
  }

  getContractorFinalizationList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_excution_contractor_finalization_add/?project=' + id)
  }

  deleteContractorFinalizationList(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pre_excution_contractor_finalization_delete/' + id + '/', {})
  }



  // Approval Section

  addApprovalTab(id: number, data: any) {
    return this.http.post(environment.apiEndpoint + 'pre_execution_approval_add_or_update/' + id + '/', data)
  }

  getApprovalTab(id: number, tabId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pre_execution_approval_add_or_update/' + id + '/?project=' + id + '&pre_execution_tabs=' + tabId)
  }



} //end of class
