import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-office-rawmaterial-inner-tab',
  templateUrl: './office-rawmaterial-inner-tab.component.html',
  styleUrls: ['./office-rawmaterial-inner-tab.component.scss']
})
export class OfficeRawmaterialInnerTabComponent implements OnInit {
@Input() projectId:number;
@Input() approveStatus:any = [];

  constructor() { }

  ngOnInit() {
  }

}
