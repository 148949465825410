import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ProjectServices {

  constructor(private http: HttpClient) {
  }

  getProjectList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list/')
  }

  getProjectListWithOutPagination(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list_wp/')
  }

  getProjectListWithPagination(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list/?' + params)
  }
  closedProjectsList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'closed_projects_list/?' + params)
  }

  deleteProject(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'projects_delete/' + id + '/', {})
  }

  getProjectDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_edit/' + id + '/')
  }

  updateProject(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'projects_edit/' + id + '/', data)
  }

  getProjectListCount(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list_count/')
  }
  getUserListByProject(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_list_with_pagination/?' + params)
  }



  getProjectListLatLng(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_list_with_lat_long/')
  }

  getProjectListByLoggedUser(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/projects_by_login_user/')
  }
}