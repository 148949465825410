import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-material-document-view-modal',
  templateUrl: './material-document-view-modal.component.html',
  styleUrls: ['./material-document-view-modal.component.scss']
})
export class MaterialDocumentViewModalComponent implements OnInit {

  documentList: any = [];
  constructor(
    public dialogRef: MatDialogRef<MaterialDocumentViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.documentList = this.data['items'];
    //console.log(this.data)
  }


  viewDoc(documentData){
    
    window.open(documentData,'','height=700,width=800,scrollbars=yes');
  }

  close() {
    this.dialogRef.close(true);
  }

}
