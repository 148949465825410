import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ContractorsService } from '../../../../core/services/contractors.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contractor-modal',
  templateUrl: './contractor-modal.component.html',
  styleUrls: ['./contractor-modal.component.scss']
})
export class ContractorModalComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  contractorTypeList: any = [];
  contractorId: number;
  detailsData: any;
  contacts: any = [];
  constructor(
    public dialogRef: MatDialogRef<ContractorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private contractorsService: ContractorsService,
    private toastr: ToastrService
  ) {
    // console.log(data)
    this.contractorTypeList = data['contractorTypeList']
    if (data['item'] != undefined) {
      this.detailsData = data['item']
      this.detailsData['category'] = data['item']['category']['id']
      this.contractorId = data['item']['id']
      this.contacts = data['item']['phone_no'].split(',')      
    }
    else {
      this.detailsData = {
        category: null,
        name: null,
        contact_person_name: null,
        phone_no: null,
        email: null,
        website: null,
        address: null
      }
    }
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      category: [null, Validators.required],
      name: [null, Validators.required],
      contact_person_name: [null, Validators.required],     
      contacts: this.formBuilder.array([]),
      email: [null, [
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/)
      ]],
      website: [null],
      address: [null],

    });
    if(this.contacts.length > 0){
      this.contacts.forEach(x => {
        this.addRowForm(x)
      })
    }
    else{
      this.addRowForm()
    }
    
    this.loading = LoadingState.Ready
  }

  createRow(val?) {
    var phone = val ? val : null;
    return this.formBuilder.group({
      phone_no: [phone, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(12)
      ]]
    })
  }

  addRowForm(data?) {
    const control = <FormArray>this.form.controls['contacts'];
    control.push(this.createRow(data));
  }

  removeRow(index: number) {
    const control = <FormArray>this.form.controls['contacts'];
    control.removeAt(index);
  }
  getFormArrayControl(form) {
    return form.get('contacts').controls
  }


  save() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      let data = Object.assign({},this.form.value)
      delete data.contacts
      let contact = this.form.value.contacts.map(x=>x.phone_no).join(",")
      data['phone_no'] = contact
      this.contractorsService.addContractor(data).subscribe(
        res => {
          this.loading = LoadingState.Ready
          this.toastr.success('Contractor has been added', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(true)
        },
        error => {
          // console.log(error);
          this.dialogRef.close(false)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      let data = Object.assign({},this.form.value)
      delete data.contacts
      let contact = this.form.value.contacts.map(x=>x.phone_no).join(",")
      data['phone_no'] = contact
      this.contractorsService.updateContractor(this.contractorId, data).subscribe(
        res => {
          this.loading = LoadingState.Ready
          this.toastr.success('Contractor has been updated', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(true)
        },
        error => {
          // console.log(error);
          this.dialogRef.close(false)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
