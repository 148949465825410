import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private http: HttpClient) {
  }


  getAttendanceReportByPermisson(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list_by_permisson/?' + params)
  }
  getAttendanceReportByPermissonV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/attandance_approval_log_list_by_permisson/?' + params)
  }

  getAttendanceApprovalByPermisson(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list_by_permisson/?' + params)
  }
  getAttendanceApprovalDeviationByPermisson(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_list_with_only_deviation_by_permisson/?' + params)
  }

  getAttendanceApprovalDeviationByPermissonV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/attandance_approval_list_with_only_deviation_by_permisson/?' + params)
  }

  getAttendanceTrackReportByPermisson(id: number, module_id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list_by_permisson/?module_id=' + module_id + '&attendance=' + id)
  }

  getAttendanceReport(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list/?' + params)
  }

  getAttendanceApproval(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list/?' + params)
  }

  getAttendanceTrackReport(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list/?attendance=' + id)
  }

  getAttendanceTrackReportV2(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/attandance_approval_log_list/?attendance=' + id)
  }

  getAttendanceMapowerTrackReport(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list/?' + params)
  }

  getAttendanceMapowerTrackReportV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/attandance_approval_log_list/?' + params)
  }

  updateAttendanceApproval(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'attandance_deviation_approval/' + id + '/', data)
  }

  updateAttendanceApprovalV2(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'v2/attandance_deviation_approval/', data)
  }

  getAttendanceMarker(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_log_add/?' + params)
  }

  getAdvanceLeave(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'advance_leave_apply/?' + params)
  }

  updateAdvanceLeave(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'advance_leave_apply_edit/' + id + '/', data)
  }

  multiAttendanceApproval(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'attandance_mass_deviation_approval/', data)
  }

  multileaveApproval(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'advance_leave_mass_apply_edit/', data)
  }

  //conveyance

  getConveyanceList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'employee_conveyance_list/?' + params)
  }

  updateConveyanceList(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'employee_conveyance_edit/' + id + '/', data)
  }
  addConveyance(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'employee_conveyance_add/', data)
  }

  //fooding


  getFoodingList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_approval_log_list_with_fooding/?' + params)
  }
  addFooding(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'employee_fooding_add/', data)
  }

  //Daily Attendance

  getDailyAttendancePerEmployee(id: number, params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'attandance_list_by_employee/' + id + '/?' + params)
  }

  getFlexiAttendancePerEmployee(id: number, params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/attandance_list_by_employee/' + id + '/?' + params)
  }

  getAdvanceLeaveListPerEmployee(id: number, params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'leave_list_by_employee/' + id + '/?' + params)
  }

  applyAdvanceLeave(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'advance_leave_apply/', data)
  }

  attendanApprovalRequest(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'attandance_deviation_justification/' + id + '/', data)
  }

  attendanApprovalRequestV2(id: number, data: any, params): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'v2/attandance_deviation_justification/' + id + '/?' + params, data)
  }

  // 14-05-2020 Manna

  flexiGraceLeaveListV2(param) {
    return this.http.get(environment.apiEndpoint + 'v2/pms_attendance_grace_leave_list_first_modified/?' + param);
  }

  getLeaveRecord(param) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_grace_leave_list_first_modified/?' + param);
  }

  getLeaveHistoryList(param) {
    return this.http.get(environment.apiEndpoint + 'attendance_advance_leave_list/?' + param);
  }

  getSpecialLeaveHistoryList(param) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_special_leave_list/?' + param);
  }

  applyAdvanceLeaveV2(data) {
    return this.http.post(environment.apiEndpoint + 'v2/attendance_advance_leave_add/', data);
  }

  applyAdvanceLeavePmsV2(data) {
    return this.http.post(environment.apiEndpoint + 'v2/pms_attendance_advance_leave_add/', data);
  }

  applySpecialLeave(data) {
    return this.http.post(environment.apiEndpoint + 'v2/attendance_special_leave_add/', data);
  }

  getAttendanceSummary(employee, param) {
    return this.http.get(environment.apiEndpoint + 'v2/attandance_summary_by_employee/' + employee + '/?' + param);
  }

  getOldAttendanceSummary(employee, param) {
    return this.http.get(environment.apiEndpoint + 'attandance_summary_by_employee/' + employee + '/?' + param);
  }

  attendanceLateConveyanceDocumentAdd(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'attendance_late_conveyance_document_add/', data)
  }

  attendanceLateConveyanceApply(id: number, data: any) {
    return this.http.put(environment.apiEndpoint + 'attendance_late_conveyance_apply/' + id + '/', data);
  }

  getAttendanceVehicalType() {
    return this.http.get(environment.apiEndpoint + 'attendance_vehicle_type_master_add/');
  }

  graceLeaveList(param) {
    return this.http.get(environment.apiEndpoint + 'attendance_grace_leave_list_first_modified/?' + param);
  }

  getLockStatus(module_name) {
    return this.http.get(environment.apiEndpoint + 'object_top_lavel_permission_by_module/' + module_name + '/');
  }

  getNormalLeaveReport(params) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_approval_report/?' + params);
  }

  getAdvanceLeaveReport(params) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_advance_leave_report/?' + params);
  }

  exportAdvanceLeaveReport(params) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_advance_leave_report_export_download/?' + params);
  }

  exportNormalLeaveReport(params) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_approval_report_export_download/?' + params);
  }

  leaveNormalList(param) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_leave_approval_list/?' + param);
  }

  addMultiAttendanceApprovalV2(data) {
    return this.http.post(environment.apiEndpoint + 'v2/e_task_attendance_approval/', data);
  }

  advancePendingListNew(param) {
    return this.http.get(environment.apiEndpoint + 'v2/admin_attendance_advance_leave_pending_list/?' + param);
  }

  addMultiAdavnceLeaveApproval(data) {
    return this.http.post(environment.apiEndpoint + 'admin_attendance_advance_leave_approval/', data);
  }

  getFornightAttendance(params) {
    return this.http.get(environment.apiEndpoint + 'v2/flexi_team_fortnight_attendance/?' + params);
  }

}
