import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../../components/loading/loading.component';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { LocalService } from '../../../../services/local.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quotation-approval-modal',
  templateUrl: './quotation-approval-modal.component.html',
  styleUrls: ['./quotation-approval-modal.component.scss']
})
export class QuotationApprovalModalComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  quotationData: any;
  isModify: boolean = false;
  vendorList: any = [];
  selectedVendorRank: string;
  userId: number;
  selectedVendor: number;
  requisition_id: number;
  currentPermissionUser: any;
  checkStatus: any;
  approvalList: any = [];
  comparativeData: any = [];

  constructor(
    public dialogRef: MatDialogRef<QuotationApprovalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    // console.log(this.data)
    this.form = this.formBuilder.group({
      quotation_approval: ['Approve', Validators.required],
      modified_vendor: [{ value: null, disabled: true }],
      comments: [''],
    });
    this.quotationData = this.data['result']['items'];
    this.comparativeData = this.data['result']['comparitive_statements'];
    this.requisition_id = this.data.requisition_id;
    this.currentPermissionUser = this.data.currentPermissionUser;
    this.quotationData.forEach(element => {
      let obj  = {vendor_name: element.vendor_name, vendor_code: element.vendor_code, vendor_id: element.vendor_id, ranking: element.ranking};
      this.vendorList.push(obj);
    });
    this.approvalList = this.data['result'].permission_details;
    this.defaultRanking();
    this.loadData();
    this.loading = LoadingState.Ready;
  }

  loadData() {
    this.executionPurchaseService.getrequisitionSubmitForApproval(this.requisition_id).subscribe(
      res => {
        // console.log(res)
        this.checkStatus = res['result'];        
      },
      error => {
        // console.log(error)
      }
    )
  }

  defaultRanking() {
    this.userId = +this.localService.getJsonValue('userid')
    var permissionIndex = this.approvalList.findIndex(x => x.user_details.id == this.userId)
    var isPrevApproverAction = false;
    this.approvalList.forEach(x => {
      if (x.approval == true) {
        isPrevApproverAction = true;
      }
    })
    if (!isPrevApproverAction) {
      var vendorRankIndex = this.vendorList.findIndex(k => k.ranking == 'L1')
      this.selectedVendorRank = this.vendorList[vendorRankIndex]['ranking']
      this.selectedVendor = this.vendorList[vendorRankIndex]['vendor_id']
    }
    else {
      this.approvalList.forEach((x, i) => {
        if (i <= permissionIndex) {
          if (x.approved_vendor_id != null) {
            this.selectedVendor = x.approved_vendor_id
            var vendorRankIndex = this.vendorList.findIndex(k => k.vendor_id == this.selectedVendor)
            this.selectedVendorRank = this.vendorList[vendorRankIndex]['ranking']
          }
        }
      })
    }
  }

  checkQuotationApproval(event) {
    this.form.patchValue(
      {
        approved_expenses: null
      }
    )
    let radioValue = event.value;
    if (radioValue == 'Modify') {
      this.isModify = true;
      this.form.controls['modified_vendor'].enable();
      this.form.controls['modified_vendor'].setValidators([Validators.required]);
      this.form.controls['modified_vendor'].setErrors({ required: true });
    } else {
      this.isModify = false;
      this.form.controls['modified_vendor'].disable();
      this.form.controls['modified_vendor'].clearValidators();
      this.form.controls['modified_vendor'].setErrors(null);
    }
  }

  apply() {

    if (this.form.valid) {
      this.loading = LoadingState.Processing
      var vendorId;
      if (this.form.value.quotation_approval == 'Modify') {
        vendorId = this.form.value.modified_vendor
      }
      else {
        vendorId = this.selectedVendor
      }
      var ranking_vendor = this.comparativeData.filter(h => h.vendor_id == vendorId)[0];
      var data = {
        requisitions_master: this.requisition_id,
        vendor: vendorId,
        uom: ranking_vendor.uom_id,
        item: ranking_vendor.item,
        discount: ranking_vendor.discount,
        final_price: ranking_vendor.final_price,
        price_basis: ranking_vendor.final_price,
        make: ranking_vendor.make,
        base_price: ranking_vendor.base_price,
        packaging_and_forwarding: ranking_vendor.packaging_and_forwarding,
        freight_up_to_site: ranking_vendor.freight_up_to_site,
        cgst: ranking_vendor.cgst,
        sgst: ranking_vendor.sgst,
        igst: ranking_vendor.igst,
        payment_terms: ranking_vendor.payment_terms,
        delivery_time: ranking_vendor.delivery_time,
        total_order_value: +ranking_vendor.total_order_value,
        net_landed_cost: +ranking_vendor.net_landed_cost,
        inco_terms: ranking_vendor.inco_terms,
        warranty_guarantee: ranking_vendor.warranty_guarantee,
        approval_permission_user_level: this.currentPermissionUser.id,
        comment: this.form.value.comments,
        quotation_group : ranking_vendor.quotation_group
      }
      if (this.form.value.quotation_approval == 'Reject') {
        data['is_rejected'] = true
      }
      this.executionPurchaseService.approveVendorPerItem(ranking_vendor.id, data).subscribe(
        res => {
          this.updateMainStatus()
        },
        error => {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(false);
        }
      )

    } else {
      this.markFormGroupTouched(this.form)
    }
  }

  updateMainStatus() {
    //now hit share for approval API for top level user
    var topLevelUserId = this.approvalList[this.approvalList.length - 1].user_details.id;
    var currentUserId = this.currentPermissionUser.user_details.id
    if (topLevelUserId == currentUserId && this.checkStatus.status < 4) {
      var params = {
        status: 4

      }
      this.executionPurchaseService.updateSubmitForApproval(this.requisition_id, params).subscribe(
        res => {
          this.loading = LoadingState.Ready;
          if(this.form.value.quotation_approval === 'Reject') {
            this.toastr.success('Data has been Rejected', '', {
              timeOut: 3000,
            });
          } else {
            this.toastr.success('Data has been Approved', '', {
              timeOut: 3000,
            });
          }
          this.dialogRef.close(true);
        },
        error => {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(false);
        }
      )
    }
    else {
      this.loading = LoadingState.Ready;
      if(this.form.value.quotation_approval === 'Reject') {
        this.toastr.success('Data has been Rejected', '', {
          timeOut: 3000,
        });
      } else {
        this.toastr.success('Data has been Approved', '', {
          timeOut: 3000,
        });
      }
      this.dialogRef.close(true);
    }
  }


  

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
