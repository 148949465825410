import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { StockService } from '../../../../services/stock.service';
import { LoadingState } from '../../../../components/loading/loading.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { forkJoin } from 'rxjs';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../../../core/services/setting.service';

@Component({
  selector: 'app-new-issue-slip',
  templateUrl: './new-issue-slip.component.html',
  styleUrls: ['./new-issue-slip.component.scss']
})
export class NewIssueSlipComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  siteList: any = [];
  projectList: any = [];

  issueId: number;
  
  siteId: any = '';
  projectId: any = '';
  typeList: any = [];
  modeList: any = [];
  contractorList: any = [];
  externalUserTypeList: any = [];
  userType: string;
  itemTypeItems: any = [];
  itemsUom: any = [];
  userTypeId:number;
  seletedItemDetail:any = [];
  itemList: any = [];
  issueItemData:any = [];
  itemTypeDetail = [];
  totalIssueList: any = [];
  issue_items: any = [];
  typeCode = '';
  getStockOfAllUnit:any = [];
  contractorDropdown:number = null
  typeDropdown:string = null
  issueSlipNo:string = null
  issueDate:string
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  
  constructor(
    private formBuilder: FormBuilder, 
    public dialog: MatDialog, 
    private router: Router,
    public projectSiteServices: projectSiteServices,
    public stockService: StockService, 
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    private settingService:SettingService,
    ) { }

  ngOnInit() {

    this.userType=="contractor"

    this.getMasterData();
    this.form = this.formBuilder.group({
      issue_slip_no: [null, Validators.required],
      name_of_contractor: [null, Validators.required],
      issue_date: [null, Validators.required],
      type: [null, Validators.required],
      issue_items: this.formBuilder.array([this.createStockSlipForm()]),
    });

    this.getIssueList();

    this.issueItemData = [
      {
        item:null,
        itemCode:null,
        description:'',
        wbs_number: null,
        unit_id: null,
        quantity:null,
        currentStock:0,
        mode_id:null,
        remarks:''
      }
    ]

  }

  createStockSlipForm() {
    return this.formBuilder.group({
      item: [null, Validators.required],
      itemCode: [null],
      description: ['', Validators.required],
      wbs_number: [null, Validators.required],
      unit_id: [null, Validators.required],
      quantity: [null, Validators.required],
      currentStock: [0],
      mode_id: [null, Validators.required],
      remarks: ['', Validators.required],
    })
  }

  getMasterData(){
    this.loading = LoadingState.Processing;
    var forkArray = [];

    forkArray.push(this.projectSiteServices.getProjectSitesList())
    forkArray.push(this.executionPurchaseService.requisitionTypes())
    forkArray.push(this.projectSiteServices.getModeList())
    forkArray.push(this.settingService.getExternalUserTypeList())
    
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
        for (var i = 0; i < result.length; i++) {
          if(i == 0){
            this.siteList = result[i]['result'];
          }
          if(i == 1){
            this.typeList = result[i]['result'];
          }
          if(i == 2){
            this.modeList = result[i]['result'];
          }
          if(i == 3){
            this.externalUserTypeList = result[i];
            var index = this.externalUserTypeList.findIndex(x => x['type_name'].toLowerCase() == "contractor");
            if (index != -1) {
              this.userTypeId= this.externalUserTypeList[index]['id'];
              this.getExternalUserList(this.userTypeId);
            }
          }
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready;
        
      }
    )
  }

  setItemType(typeCode){
    this.getStockOfAllUnit = []
    const control = <FormArray>this.form.controls['issue_items'];
    for(let i = control.length-1; i >= 1; i--) {
      this.issueItemData.splice(i, 1)
      control.removeAt(i)
    }
    this.issueItemData = [
      {
        item:null,
        itemCode:null,
        description:'',
        wbs_number: null,
        unit_id: null,
        quantity:null,
        currentStock:0,
        mode_id:null,
        remarks:''
      }
    ]
    
    this.typeCode = typeCode;
    if(typeCode && this.projectId){
      this.loading = LoadingState.Processing;
      this.executionPurchaseService.getTypeStockItems(typeCode, this.projectId, this.siteId).subscribe(
        res => {
         
          this.itemTypeItems = res['result'];


          this.loading = LoadingState.Ready
        },
        error => {
          this.loading = LoadingState.Ready
        }
      )
    }else{
      
    }

  }

  getItemDetail(itemcode,index){
    this.seletedItemDetail = this.itemTypeItems.filter( obj => obj.id == itemcode)[0];
    let uomFilterArray = this.itemList.filter(x => x.item_details.id == this.seletedItemDetail.id)

    let uomFilter =[];
    let unitFilter = [];

    if(uomFilterArray.length > 0){
      uomFilterArray.forEach(x => {
        uomFilter.push(x.uom)
      })
    }
    
    if(this.seletedItemDetail){
      
      this.seletedItemDetail.unit.forEach((x,index) => {
        if(uomFilter.indexOf(x.id) == -1){
          unitFilter.push(x)
         }
      })

      this.itemsUom[index] = unitFilter;
      this.issueItemData[index].itemCode = this.seletedItemDetail.code;
 
    }
   
  }

  getItemCurrentStock(id,index){
   // console.log(this.itemsUom);
    this.form.controls['issue_items']['controls'][index].currentStock = 0;
    if(id && id!= undefined){
          var seletedUomDetail = this.itemsUom[index].filter( obj => obj.id == id)[0];
        //console.log(seletedUomDetail);
    
        this.form.controls['issue_items']['controls'][index].currentStock = seletedUomDetail.current_stock;
        // console.log(this.form.controls['issue_items']['controls'][index].currentStock);
    }


  }

  getExternalUserList(user_type)
  {
    var params = 'page_size=0&user_type='+user_type;
    this.settingService.getExternalUserListByType(params).subscribe(
      res => {
        this.contractorList = res['results'];
      },
      error=>{
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  setProjectSiteId(val) {
    this.contractorDropdown = null
    this.typeDropdown = null
    this.issueSlipNo = null
    this.issueDate = ''
    this.getStockOfAllUnit = []

    const control = <FormArray>this.form.controls['issue_items'];
    for(let i = control.length-1; i >= 1; i--) {
      this.issueItemData.splice(i, 1)
      control.removeAt(i)
    }
    this.issueItemData = [
      {
        item:null,
        itemCode:null,
        description:'',
        wbs_number: null,
        unit_id: null,
        quantity:null,
        currentStock:0,
        mode_id:null,
        remarks:''
      }
    ]
  
    this.loading = LoadingState.Processing;
    this.projectSiteServices.getProjectBySite(val).subscribe(
      res => {
        this.projectList = res['result'];
        this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
        console.log(error)
      }
    );
  }

  changeProject(){
    this.contractorDropdown = null
    this.typeDropdown = null
    this.issueSlipNo = null
    this.issueDate = ''
    this.getStockOfAllUnit = []

    const control = <FormArray>this.form.controls['issue_items'];
    for(let i = control.length-1; i >= 1; i--) {
      this.issueItemData.splice(i, 1)
      control.removeAt(i)
    }
    this.issueItemData = [
      {
        item:null,
        itemCode:null,
        description:'',
        wbs_number: null,
        unit_id: null,
        quantity:null,
        currentStock:0,
        mode_id:null,
        remarks:''
      }
    ]
  }

  checkStockQuantity(i){
   // console.log(this.issueItemData[i].quantity)
    //console.log(this.form.controls['issue_items']['controls'][i].currentStock)
    if(this.form.controls['issue_items']['controls'][i].currentStock == undefined){
      this.form.controls['issue_items']['controls'][i].currentStock = 0;
    }

    if(this.issueItemData[i].quantity > parseInt(this.form.controls['issue_items']['controls'][i].currentStock)){
     
      this.form.controls['issue_items']['controls'][i].quantity = 0;
      this.issueItemData[i].quantity = 0
      
      this.toastr.error('Total quantity can not greater than stock('+this.form.controls['issue_items']['controls'][i].currentStock+') ', '', {
        timeOut: 3000,
      });
    }
  }

  addItem() {
    const control = <FormArray>this.form.controls['issue_items'];
    control.push(this.createStockSlipForm());
    var q =  {
      item:null,
      itemCode:null,
      description:'',
      wbs_number: null,
      unit_id: null,
      quantity:null,
      currentStock:0,
      mode_id:null,
      remarks:''
    }
    this.issueItemData.push(q);
  }

  removeItem(index: number) {
    const control = <FormArray>this.form.controls['issue_items'];
    control.removeAt(index);
    this.issueItemData.splice(index, 1)
  }

  saveIssueSlip() {
    this.loading = LoadingState.Processing;
    if (this.form.valid) {
      this.typeList.forEach(x => {
        if(x.code == this.form.value.type){
          this.form.value.type = x.id
        }
      });

      this.issue_items = []

      this.form.value.issue_items.forEach(y=>
        {
          var q =  {
            type_item_id:y.item,
            description:y.description,
            wbs_number: y.wbs_number,
            unit_id: y.unit_id,
            quantity:y.quantity,
            mode_id:y.mode_id,
            remarks:y.remarks
          }
          this.issue_items.push(q);
        })

      var data = {
        site_location:this.siteId,
        project_id:this.projectId,
        issue_date: this.settingService.getProperDate(this.form.value.issue_date).toISOString(),//aa
        issue_slip_no: this.form.value.issue_slip_no,
        name_of_contractor: this.form.value.name_of_contractor,
        type: this.form.value.type,
        issue_items: this.issue_items
      }
      this.loading = LoadingState.Processing;
     // console.log(data);
      this.projectSiteServices.addIssueSlip(data).subscribe(
          res=>{
          
          this.issueId = res['id'];
          if(this.issueId){
            this.form.reset()
            this.getIssueList();
            this.toastr.success('Issue Slip successfully added.','',{
              timeOut: 3000,
            });
          }
          
            this.loading = LoadingState.Ready; 
          },
          error=> {
            this.loading = LoadingState.Ready; 
            console.log(error)
          }
      );
      
    } else {
      this.markFormGroupTouched(this.form);
    }
  }

  getIssueList(){
    if(this.issueId != undefined){
      this.projectSiteServices.getTotalIssueList(this.issueId).subscribe(
        res => {
          this.totalIssueList = res['results'];
          this.loading = LoadingState.Ready;
         
        },
        error => {
          this.loading = LoadingState.Ready;
        }
      );
    }
    
  }

  issueSubmitForApproval()
  {
    var data ={
      "issue_stage_status":2
    }
    this.loading = LoadingState.Processing;
    this.stockService.issueSubmitForApproval(this.issueId,data).subscribe(
      res=>{
        this.loading = LoadingState.Ready;
        
        this.toastr.success('Submitted for approval successfully.','',{
          timeOut: 3000,
        });
        this.router.navigateByUrl('/stock');
        
      },
      error=> {
        
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    );
  }
  

  searchByDescription(index,event){
    if(event.keyCode == 40 || event.keyCode == 38){
      event.preventDefault();
    }
    else{
      if(event.target.value.length > 2 && this.typeCode && this.projectId && this.siteId){
        var params = 'type_code='+this.typeCode+'&project='+this.projectId+'&site_location='+this.siteId+'&search='+event.target.value;
        this.executionPurchaseService.getTypeDetail(params).subscribe(
          res => {
            this.itemTypeDetail = res['result'];
            
            if(this.itemList.length > 0){ //filter if item available in the list

              this.itemList.forEach(x => {
                this.itemTypeDetail = this.itemTypeDetail.filter( obj => obj.description != x.item_details.description);
              });

              if(this.itemTypeDetail.length > 0){
                this.issueItemData.forEach(x => {
                  this.itemTypeDetail = this.itemTypeDetail.filter( obj => obj.description != x.description);
                });
              }

              
            }else{  // filter on the form

              if(this.itemTypeDetail.length > 0){
                this.issueItemData.forEach(x => {
                  this.itemTypeDetail = this.itemTypeDetail.filter( obj => obj.description != x.description);
                });
              }
            }
          
        //    console.log(this.itemTypeDetail)
            this.loading = LoadingState.Ready
          },
          error => {
            this.loading = LoadingState.Ready
          }
        )
      }else{
        this.itemTypeDetail = [];
      //  this.getItemDetail('',index);
      this.issueItemData[index].uom = null
    // this.stockArray[index].stock = null
        this.issueItemData[index].uom_name = null
      }
    }
  }

  onSelectionChanged(index,event){
    this.getStockOfAllUnit[index] = ''
    this.issueItemData[index].unit_id = null
    this.issueItemData[index].uom_name = null;
    this.issueItemData[index].uom = null;
    this.issueItemData[index].quantity = ''
    this.issueItemData[index].mode_id = null

    if (this.itemTypeDetail.length > 0) {
      this.itemTypeDetail = this.itemTypeDetail.filter( obj => obj.id == event.option.value);
      this.issueItemData[index].description = this.itemTypeDetail[0].description;
      
      this.issueItemData[index].editedItemId = this.itemTypeDetail[0].id;
      this.issueItemData[index].itemCode = this.itemTypeDetail[0].code;
      this.itemsUom[index] = this.itemTypeDetail[0].unit;
     this.getItemDetail(this.itemTypeDetail[0].id,index)
     
    // console.log(this.itemTypeDetail[index])
     var stringOut:any = []
     this.itemsUom[index].forEach((x,index)=> {
       if(x.current_stock != undefined){
        var string = x.unit+' : '+x.current_stock;
        stringOut.push(string)
       }else{
        var string = x.unit+' : 0';
        stringOut.push(string)
       }
       
     });
     this.getStockOfAllUnit[index] = stringOut.toString();
     this.itemTypeDetail = [];
    }
  }

  getFormArrayControl(form) {
    return form.get('issue_items').controls
  }

  delete() {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
       // console.log("hi");
      }
      this.dialogRef = null;
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}