import { Component, OnInit, Output, EventEmitter, Input,ViewChild} from '@angular/core';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-leaves',
  templateUrl: './leaves.component.html',
  styleUrls: ['./leaves.component.scss']
})
export class LeavesComponent implements OnInit {
  @Output() getGraceDetailsFromChild = new EventEmitter<string>();
  @Input() leaveDetail;
  myAttendanceConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  activeTab: any = 'advance';


  constructor(

  ) { 
    this.myAttendanceConfig = AppPageStateConfig.myAttendanceConfig.leaveConfig;
    this.activeTab = this.myAttendanceConfig.tab;
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }

  callFromChild(event) {
    this.getGraceDetailsFromChild.next();
  }

  onTabChange(event) {
    //console.log(event)
    this.activeTab = event.nextId;
    this.myAttendanceConfig.tab = this.activeTab
   
  }

}
