export class AppPageStateConfig {

    public static myAttendanceConfig = {
        tab: 'daily_attendance',
        dailyAttendanceConfig: {
            is_previous: null
        },
        attendanceSummeryConfig: {
            tab: 'new_summary'
        },
        leaveConfig: {
            tab: 'advance'
        }

    }

    public static attendanceConfig = {
        attendanceReportConfig: {
            search_key: '',
            employeeId: null,
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            projectSite: []
        },
        attendanceApprovalConfig: {
            search_key: '',
            employeeId: null,
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            projectSite: []
        },
        attendanceLeaveConfig: {
            search_key: '',
            employeeId: null,
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            leave_type: null
        },
        attendanceConveyanceConfig: {
            search_key: '',
            employeeId: null,
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            projectSite: []
        },
        attendanceFoodingConfig: {
            search_key: '',
            employeeId: null,
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            projectSite: []
        },
        attendanceLeaveApprovalConfig: {
            advanceLeaveConfig: {
                search_key: '',
                employeeId: undefined,
                dateRange: null,
                leaveStartDate: '',
                leaveEndDate: '',
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                department: null,
                designation: null,
                reportingManager: null,
                leaveType: null,
                leaveCheckbox: null,
            }
        },
        attendanceLeaveReportConfig: {
            advanceLeaveConfig: {
                search_key: '',
                employeeId: undefined,
                dateRange: null,
                leaveStartDate: '',
                leaveEndDate: '',
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                approveTypeCheckbox: [],
                approveCheckList: [],
                reportTypeCheckbox: [],
                leaveType: null,
                leaveCheckbox: null,
            }
        },
        dailyWorksheetConfig: {            
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null
        }
    }
    public static pmsFortnightSummaryConfig = {
        itemPerPage: null,
        pageSize: null,
        defaultPagination: null,
        sortBy: null,
        sortType: null,
        search_key: '',
        employeeId: null,
        month: '',
        year: '',
        monthyear: null      
    };

    public static addTenderConfig = {
        tab: 'tender',
        tenderConfig: {
            is_previous: null
        },
        eligibilityConfig: {
            tab: 'technical_eligibility'
        },
        bidderTypeConfig: {
            tab: 'bidder-type'
        },
        surveyConfig: {
            tab: 'survey-details',
            coorfinateConfig: {
                tab: 'coordinate_material',
            },
            resourceConfig: {
                tab: 'material',
                vendorConfig: {
                    tab: 'contractor',
                }
            }
        },
        costingConfig: {
            tab: 'initial-costing'
        },
        tenderApprovalConfig: {
            tab: 'tender-approval'
        },
        documentsConfig: {
            tab: 'documents'
        },
        statusConfig: {
            tab: 'tender-status'
        }

    }

    public static tenderListConfig = {
        search_key: '',
        dateRange: null,
        fromDate: '',
        toDate: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        eligibility: [],
        bidder_type: null,
        status: null
    }

    public static archivedTenderListConfig = {
        search_key: '',
        dateRange: null,
        fromDate: '',
        toDate: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        eligibility: [],
        bidder_type: null,
        status: null
    }

    public static requisitionListConfig = {
        dateRange: null,
        fromDate: '',
        toDate: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        eligibility: [],
        search_key: ''
    }

    public static requisitionMaterialReceivedConfig = {
        dateRange: null,
        fromDate: '',
        toDate: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        projectChecked: [],
        typeChecked: null,
        search_key: ''
    }

    public static requisitionApprovalConfig = {
        tab: 'Requisition',
        search_key: '',
        requisitionConfig: {
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            eligibility: [],
            projectChecked: '',
            typeChecked: null
        },
        quotationConfig: {
            dateRange: null,
            fromDate: '',
            toDate: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            eligibility: [],
            projectChecked: '',
            typeChecked: null
        }

    }

    public static purchaseRequisitionConfig = {
        tab: 'requisition',
        requisitionConfig: {

        },
        requisitionApprovalConfig: {

        },
        quotationsConfig: {

        },
        comparativeConfig: {

        },
        poConfig: {

        },
        dispatchConfig: {

        },
        deliveryConfig: {

        },
        paymentConfig: {

        }

    }

    public static progressReportsConfig = {
        tab: 'daily',
        siteid: null,
        dailyProgressReportConfig: {
            tab: 'progress',
            date: null,
        },
        weeklyProgressReportConfog: {
            tab: 'progress',
            startDate: null,
            endDate: null,

        },
        monthlyProgressReportConfig: {
            tab: 'progress',
            projectid: null,
            date: null
        }
    }

    public static dailyProgressConfig = {
        tab: 'daily'
    }

    public static projectPlanConfig = {
        tab: 18
    }

    public static stockReportConfig = {
        tab: 'mat_stock_statement',
        siteId: null,
        projectId: null,
        materialStockConfig: {
            search_key: undefined,
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            selectedMonths: undefined,
            mnth: []
        },
        monthlyStockConfig: {
            search_key: undefined,
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            monthString: null,
            month: null,
            year: null,
            maxDate: null,
            minDate: null
        },
        currentStockConfig: {
            search_key: undefined,
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
        }

    }

    public static stockIssueConfig = {
        tab: 'issue_list',
        siteId: null,
        projectId: null,

    }

    public static preExecutionConfig = {
        tab: 'site-mobilization',

    }

    public static machineryManagementConfig = {
        machineryListConfig: {
            tab: 'Purchase-Equiments',
            purchaseEquipmentsConfig: {
                search_key: null,
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                projectSite: []
            },
            rentedEquipmentsConfig: {
                search_key: null,
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                projectSite: []
            },
            equipmentsFromContractorConfig: {
                search_key: null,
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                projectSite: []
            },
            equipmentsFromLeaseConfig: {
                search_key: null,
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                projectSite: []
            }
        },

        dieselConsumptionConfig: {
            tab: 'daily',
            dailyConfig: {
                currentDate: null,
                projectId: null,
                siteId: null
            },
            dateRangeConfig: {
                fromDate: '',
                toDate: '',
                dateRange: null,
                projectId: null,
                siteId: null,
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
            }
        },
        machineryReportConfig: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            siteId: undefined,
            projectId: undefined,
            dateRange: null
        },

    }

    public static manPowerConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        projectSite: []
    }

    public static projectConfig = {
        search_key: '',
        dateRange: null,
        fromDate: '',
        toDate: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        projectSite: [],
        state: null
    }

    public static materialListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static boqListConfig = {
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static assetsListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static sparesListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static consumablesListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }
    public static fuelandLubricantsListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static electricalItemListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static generalToolsListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static safetyListConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null
    }

    public static externalUserConfig = {
        vendorListConfig: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null
        },
        contractorListConfig: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null
        },
        partnerListConfig: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null
        },
        operatorListConfig: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null
        },
        crusherListConfig: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null
        }
    }

    public static dailyExpConfig ={
        dailyExpReportConfig:{
            project_manager:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            project_coordinator:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            account:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            ho:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            }
            
        },
        dailyExpApprovalConfig:{
            project_manager:{
                status:null,
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            project_coordinator:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            account:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            ho:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            }

        },
        dailyExpPaymentConfig: {
            report: {
                status: null,
                search_key: '',
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null
            },
            approval:{
                status:null,
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null
            }            
        },
        myExpReportConfig:{
            search_key:'',
            defaultPagination:null,
            pageSize:null,
            itemPerPage:null,
            fromDate:'',
            toDate:'',
            dateRange:null,
        },
        paymentConfig:{
            approval:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            },
            report:{
                search_key:'',
                defaultPagination:null,
                pageSize:null,
                itemPerPage:null,
                fromDate:'',
                toDate:'',
                dateRange:null,
                employeeId:null,
                project:null
            }
        }
    }
    public static preExecutionActiveProjectsConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        fromDate: '',
        toDate: '',
        dateRange: null,
        eligibility: []
    }

    public static preExecutionApproveProjectsConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        fromDate: '',
        toDate: '',
        dateRange: null,
        eligibility: []
    }

    public static preExecutionClosedProjectsConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        endFromDate: '',
        endToDate: '',
        dateRange: null,
        approvals: []
    }

    public static employeeAbsentConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        endFromDate: '',
        endToDate: '',
        dateRange: null,
        project: null,
        leave_approved: '',
        employeeId: null,
    }

    public static cementRegisterConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        endFromDate: '',
        endToDate: '',
        dateRange: null,
        project: null,
        cement_type: null,
    }

    public static siteCashConfig = {
        search_key: '',
        defaultPagination: null,
        pageSize: null,
        itemPerPage: null,
        endFromDate: '',
        endToDate: '',
        dateRange: null,
        project: null,
    }


    public static travelAndTourConfig = {
        pmapproval: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            project: null,
            employeeId: null
        },
        pcapproval: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            project: null,
            employeeId: null
        },
        hoapproval: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            project: null,
            employeeId: null
        },
        accountapproval: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            project: null,
            employeeId: null
        },
        report: {
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            project: null,
            employeeId: null
        },
        mytour: {            
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            project: null            
        },
        payment: {
            approval: {
                search_key: '',
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                fromDate: '',
                toDate: '',
                dateRange: null,
                project: null,
                employeeId: null
            },
            report: {
                search_key: '',
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                fromDate: '',
                toDate: '',
                dateRange: null,
                project: null,
                employeeId: null
            }
        }
    }

    public static batchingPlantConfig = {
        batchRepotList:{
                search_key: '',
                defaultPagination: null,
                pageSize: null,
                itemPerPage: null,
                fromDate: '',
                toDate: '',
                dateRange: null,
                projectId: null,
        },
        batchRepotOutput:{
            search_key: '',
            defaultPagination: null,
            pageSize: null,
            itemPerPage: null,
            fromDate: '',
            toDate: '',
            dateRange: null,
            projectId: null,
            sort_by:'',
            sort_type:''
    }
    }

    public static billInvoiceConfig ={
        billInvoiceApprovalConfig:{
            search_key:'',
            defaultPagination:null,
            pageSize:null,
            itemPerPage:null,
            fromDate:'',
            toDate:'',
            dateRange:null,
            employeeId:null,
            project:null,           
        },
        billInvoiceReportConfig:{
            status:null,
            search_key:'',
            defaultPagination:null,
            pageSize:null,
            itemPerPage:null,
            fromDate:'',
            toDate:'',
            dateRange:null,
            employeeId:null,
            project:null,           
        },
        billInvoiceByCategoryConfig:{
            search_key:'',
            defaultPagination:null,
            pageSize:null,
            itemPerPage:null,
            fromDate:'',
            toDate:'',
            dateRange:null,
            status: null
        }
    }
}