import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SettingService } from '../../../../core/services/setting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentViewModalComponent } from '../../../../core/components/document-view-modal/document-view-modal.component';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';
import * as Globals from '../../../../core/globals';
import { MachineryTransferComponent } from '../../machinery-transfer/machinery-transfer.component';
import { AppPageStateConfig } from '../../../../app.config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-equipments-from-contractor',
  templateUrl: './equipments-from-contractor.component.html',
  styleUrls: ['./equipments-from-contractor.component.scss']
})
export class EquipmentsFromContractorComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;

  contractMachineryList: any = [];
  totalMachineryCount: number
  defaultPagination: number;
  itemNo: number;
  paginationMaxSize: number;
  itemPerPage: number;
  lower_count: number;
  upper_count: number;
  pageSize: number;

  @Input() project: string;
  @Input() projectList: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  hasScroll: boolean = false
  scrollAmount;
  search_key: any = null;
  headerThOption: any = [];
  sort_by = '';
  sort_type = '';
  eligibility = [];
  disabled: boolean = false;
  equipmentsFromContractorConfig: any;

  constructor(
    private settingService: SettingService,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private element: ElementRef,
    private toastr: ToastrService,
  ) {
    this.equipmentsFromContractorConfig = AppPageStateConfig.machineryManagementConfig.machineryListConfig.equipmentsFromContractorConfig;
  }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    

    if (this.project == undefined) {
      if (this.equipmentsFromContractorConfig.search_key != null) {
        this.search_key = this.equipmentsFromContractorConfig.search_key;
      }
      if (this.equipmentsFromContractorConfig.projectSite.length > 0) {
        this.eligibility = this.equipmentsFromContractorConfig.projectSite;
      }
      if (this.equipmentsFromContractorConfig.defaultPagination != null) {
        this.defaultPagination = this.equipmentsFromContractorConfig.defaultPagination;
      }
      if (this.equipmentsFromContractorConfig.itemPerPage != null) {
        this.itemPerPage = this.equipmentsFromContractorConfig.itemPerPage;
      }
      if (this.equipmentsFromContractorConfig.pageSize != null) {
        this.pageSize = this.equipmentsFromContractorConfig.pageSize;
      }
    }
    this.headerThOption = [
      {
        id: 1,
        name: "Equipment Name",
        code: "equipment_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },

      {
        id: 2,
        name: "Equipment Category",
        code: "equipment_category",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 18,
        name: "Projects",
        code: "projects",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      // {
      //   id: 3,
      //   name: "Equipment Type",
      //   code: "equipment_type",
      //   sort_type: '',
      //   has_tooltip: false,
      //   tooltip_msg: '',
      //   selected: true
      // },
      {
        id: 4,
        name: "Equipment Make",
        code: "equipment_make",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Equipment Model No",
        code: "equipment_model_no",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Equipment Chassis Serial No",
        code: "equipment_chassis",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 7,
        name: "Equipment Engine Serial No",
        code: "equipment_engine",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 8,
        name: "Equipment Registration No",
        code: "equipment_registration",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 9,
        name: "Power/Ratings",
        code: "power",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 10,
        name: "Unit",
        code: "unit",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 11,
        name: "Standard Fuel Consumption",
        code: "fuel_consumption",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 18,
        name: "Equipment Reading in",
        code: "equipment_reading_in",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 19,
        name: "Running KM/HR",
        code: "running_km_hr",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 12,
        name: "Price",
        code: "price",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 13,
        name: "Contractor",
        code: "contractor",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 14,
        name: "Rent Type",
        code: "rent_type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 15,
        name: "Rent Amount",
        code: "rent_amount",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 16,
        name: "Start Date",
        code: "start_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 17,
        name: "End Date",
        code: "end_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 18,
        name: "Files",
        code: "files",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      }
    ];
    if (this.project != undefined) {
      this.eligibility.push(parseInt(this.project))
      this.disabled = true;
    }
    this.getMachineryList();
  }

  getConfigData(data) {
    this.headerThOption = data
  }

  ShowColunm(column_code) {
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if (index != -1) {
      if (this.headerThOption[index]['selected']) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  ngAfterViewInit() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    if (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }
  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  getMachineryList() {
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    params.set('page', this.defaultPagination.toString());
    if (this.itemPerPage)
      params.set('page_count', this.itemPerPage.toString());
    if (this.search_key != null)
      params.set('search', this.search_key.toString());
    if (this.sort_by != '')
      params.set('field_name', this.sort_by.toString());
    if (this.sort_type != '')
      params.set('order_by', this.sort_type.toString());
    params.set('owner_type', '3');
    if (this.project != undefined) {
      params.set('project', this.project.toString());
    }
    if (this.eligibility.length > 0)
      params.set('project', decodeURI(this.eligibility.toString()));
    this.settingService.getMachineryList(params).subscribe(
      res => {
        //console.log(res);
        this.totalMachineryCount = res['count'];
        this.contractMachineryList = res['results'];
        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalMachineryCount > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
        }
        this.loading = LoadingState.Ready
      },
      error => {
        if (error.status == 404 && this.equipmentsFromContractorConfig.defaultPagination > 1) {
          this.equipmentsFromContractorConfig.defaultPagination = 1;
          this.ngOnInit();
        }
        else {
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      }
    )
  }
  excelDownload() {
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    params.set('page', this.defaultPagination.toString());
    if (this.itemPerPage)
      params.set('page_count', this.itemPerPage.toString());
    if (this.project != undefined)
      params.set('project', this.project.toString());
    if (this.eligibility.length > 0)
      params.set('project', decodeURI(this.eligibility.toString()));
    if (this.search_key != null) {
      params.set('search', this.search_key.toString());

    }
    if (this.sort_by != '')
      params.set('field_name', this.sort_by.toString());
    if (this.sort_type != '')
      params.set('order_by', this.sort_type.toString());
    params.set('owner_type', '3');
    this.settingService.getMachineryListExcelDownload(params).subscribe(
      res => {
        if (res['results']['url'] == null) {
          this.toastr.error('No data Found !', '', {
            timeOut: 3000,
          });
        } else {
          window.open(res['results']['url'], '_blank');
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }

  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getOwnerType(type: number) {
    if (type == 1) {
      return 'Rental'
    }
    else if (type == 2) {
      return 'Own'
    }
    else if (type == 3) {
      return 'Contract'
    }
    else if (type == 4) {
      return 'Lease'
    }
  }

  pagination() {
    this.loading = LoadingState.Processing;
    if (this.project == undefined) {
      this.equipmentsFromContractorConfig.defaultPagination = this.defaultPagination;
    }
    this.getMachineryList();
  };

  edit(id) {
    this.router.navigateByUrl('/machinery/edit-machinery/' + id);
  }

  delete(index, id) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing
        this.settingService.deleteMachinery(id).subscribe(
          res => {
            //console.log(res);
            this.contractMachineryList.splice(index, 1);
            this.loading = LoadingState.Ready
          },
          error => {
            //console.log(error);
            this.loading = LoadingState.Ready
          }
        )
      }
      this.dialogRef = null;
    })
  }

  viewDoc(items: any) {
    // console.log(items)
    var data = [];
    items.forEach(x => {
      var item = {
        name: x.document_name,
        url: x.document
      }
      data.push(item)
    })
    let dialogRef = this.dialog.open(DocumentViewModalComponent, {
      width: '525px',
      data: { items: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
    })
  }

  transfer(item) {
    let dialogRef = this.dialog.open(MachineryTransferComponent, {
      disableClose: false,
      width: "60%",
      data: { item: item, project: this.projectList }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result)
      if (result != undefined) {
        this.getMachineryList();
      }
      dialogRef = null;
    })
  }

  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
    
    if (this.project == undefined) {
      this.equipmentsFromContractorConfig.itemPerPage = +item;
      this.equipmentsFromContractorConfig.defaultPagination = this.defaultPagination;
      this.equipmentsFromContractorConfig.pageSize = +item;
    }
    this.getMachineryList();
  }

  getSearchValue() {
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
   
    if (this.project == undefined) {
      this.equipmentsFromContractorConfig.search_key = this.search_key;
      this.equipmentsFromContractorConfig.defaultPagination = this.defaultPagination;
      this.equipmentsFromContractorConfig.pageSize = +this.itemPerPage;
    }
    this.getMachineryList();
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.defaultPagination = 1;
    this.loading = LoadingState.Processing;
    this.getMachineryList();
  }

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
   
    if (this.project == undefined) {
      this.equipmentsFromContractorConfig.projectSite = this.eligibility;
      this.equipmentsFromContractorConfig.defaultPagination = this.defaultPagination;
      this.equipmentsFromContractorConfig.pageSize = +this.itemPerPage;
    }
    this.getMachineryList();
  }

  havePermission(section, parentSection) {
    return this.settingService.havePermission(section, parentSection);
  }

}
