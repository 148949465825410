import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../components/loading/loading.component';
import { TravelAndTourService } from '../../../services/travel-and-tour.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-approve-reject-modal',
  templateUrl: './approve-reject-modal.component.html',
  styleUrls: ['./approve-reject-modal.component.scss']
})
export class ApproveRejectModalComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  attendanceApprovalData: any;
  leaveApprovalData: any
  expenseData: any
  isModify: boolean = true;
  approvedStatusValid: boolean = false;
  approval_status = 'Approve';

  constructor(
    public dialogRef: MatDialogRef<ApproveRejectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private tourService: TravelAndTourService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.form = this.formBuilder.group({
      tour_expence_approvals: [this.data.id],
      request_by: [this.data.from],
      comment: [null, Validators.required]
    })    
    this.loading = LoadingState.Ready;
  }

  apply() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;      
      var data = {
        tour_expence_approvals: this.data.id,
        request_by: this.data.from,
        comment: this.form.value.comment
      }

      if(this.approval_status == 'Approve'){
        var apiUrl = this.tourService.tourAndTravelApprove(data);
      } else if(this.approval_status == 'Reject') {
        var apiUrl = this.tourService.tourAndTravelReject(data);
      }    
      
      apiUrl.subscribe(
        res => {
          this.close(true);
          this.loading = LoadingState.Ready;
        },
        error => {
          this.close(false);          
        }
      )
    } else {
      this.markFormGroupTouched(this.form)
    }
  }

  close(status) {
    this.dialogRef.close(status);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
