import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../loading/loading.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AttendanceService } from '../../../../services/attendance.service';
import { DocumentViewModalComponent } from '../../../../components/document-view-modal/document-view-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import * as Globals from '../../../../globals';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { LocalService } from '../../../../services/local.service';

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.scss']
})
export class AdvanceComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  @Output() getGraceDetailsFromChild = new EventEmitter<string>();
  @Input() leaveDetail;

  defaultPagination: number;
  totalLeavesList: number;
  sort_by = '';
  sort_type = '';
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  pageSize: number;
  leavesList: any = [];

  leaveStartDate: string;
  leaveEndDate: string;
  dateRange: any = null;
  selectedDocument: boolean = false;

  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
  //public maxDate: Object =  new Date(this.currentYear, this.currentMonth+1, 20);
  salayTypeCode:string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private formBuilder: FormBuilder,
    private settingService: SettingService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      date_range: [null, Validators.required],
      leave_type: [null, Validators.required],
      reason: [null, Validators.required],
      document: [null],
      file: null,
    });

    // console.log(this.leaveDetail)

    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;

    this.getLeaveHistoryList()
    this.salayTypeCode = this.leaveDetail['salary_type_code']
  }

  getLeaveHistoryList() {
    var userId = this.localService.getJsonValue('userid');
    let params: URLSearchParams = new URLSearchParams();
    params.set('emp_id', userId.toString());
    params.set('page', this.defaultPagination.toString());   
    if (this.itemPerPage) {
      params.set('page_count', this.itemPerPage.toString());
    }
    this.loading = LoadingState.Processing;
    this.attendanceService.getLeaveHistoryList(params).subscribe(
      (res: any) => {
        this.leavesList = res['results'];
        this.totalLeavesList = res.count;
        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalLeavesList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
        }
        else {
          this.upper_count = this.totalLeavesList
        }

        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )

  }

  saveLeave() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;

      const data: any = new FormData();
      
      data.append("employee ", this.localService.getJsonValue('userid'));
      data.append("leave_type ", this.form.value.leave_type);
      data.append("start_date ", this.leaveStartDate);
      data.append("end_date ", this.leaveEndDate);
      data.append("reason ", this.form.value.reason);
      data.append("is_confirm ", 0);
      data.append("module ", 'PMS');
      if(this.form.value.leave_type == "AB"){
        data.append("is_confirm ", 1);
      }

      if(this.form.value.document != null && this.form.value.document != undefined){
        data.append("document ", this.form.value.document);
      }
      
      // let data = {
      //   employee: this.localService.getJsonValue('userid'),
      //   leave_type: this.form.value.leave_type,
      //   start_date: this.leaveStartDate,
      //   end_date: this.leaveEndDate,
      //   reason: this.form.value.reason,
      //   is_confirm: 0
      // }
      // if(data.leave_type == "AB"){
      //   data.is_confirm = 1
      // }
      
      this.attendanceService.applyAdvanceLeaveV2(data).subscribe(
        res => {
          //console.log(res)
          if (!res['result']['is_confirm']) {
            this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
              disableClose: false
            })
            var content = "You have insufficient leave <br/> Your leave has been deducted as LWP from" + res['result']['leave_without_pay']['start_date'] + " to " + res['result']['leave_without_pay']['end_date'] + ", <br/> Are you want to procced ?"
            this.dialogRef.componentInstance.confirmMessage = content;

            this.dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.saveAdvanceLeaveConfirm(data)
              }
              else{
                this.loading = LoadingState.Ready;
              }
            })
          }
          else {
            this.getLeaveHistoryList();
            this.form.reset();
            this.selectedDocument = false
            this.getGraceDetailsFromChild.next();
            this.loading = LoadingState.Ready;
            this.toastr.success('Your Leave Appliaction has been Submitted', '', {
              timeOut: 3000,
            });
          }


        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready;
          if (error.error.error.msg) {
            this.toastr.error(error.error.error.msg, '', {
              timeOut: 3000,
            });
          }
          else {
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        }
      )

    } else {
      this.markFormGroupTouched(this.form)
    }
  }

  saveAdvanceLeaveConfirm(data) {
    data.append("is_confirm ", 1);
    //data['is_confirm'] = 1;
    this.attendanceService.applyAdvanceLeaveV2(data).subscribe(
      res => {
        //console.log(res)
        if (res['result']['is_confirm']) {
          this.getLeaveHistoryList();
          this.form.reset();
          this.selectedDocument = false
          this.getGraceDetailsFromChild.next();
          this.loading = LoadingState.Ready;
          this.toastr.success('Your Leave Appliaction has been Submitted', '', {
            timeOut: 3000,
          });
        }


      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready;
        if (error.error.error.msg) {
          this.toastr.error(error.error.error.msg, '', {
            timeOut: 3000,
          });
        }
        else {
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      }
    )
  }

  selectFile(event) {
    //console.log(event.target.files[0].type)
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg"
      || event.target.files[0].type == "image/jpg"
      || event.target.files[0].type == "application/pdf"
    ) {
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event) => {

          }
          this.form.patchValue({
            document: event.target.files[0]
          })
          reader.readAsDataURL(event.target.files[i]);
         
          this.selectedDocument = true;
        }


      }
    } else {
      this.toastr.error('Image or PDF file type allowed', '', {
        timeOut: 3000,
      });
    }

  }

  viewDoc(url:string){
    window.open(url,'','height=700,width=800,scrollbars=yes');
   }

  pagination() {
    this.loading = LoadingState.Processing;
    this.getLeaveHistoryList();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  dateTimeReset() {
    this.dateRange = null;
    this.leaveStartDate = ''
    this.leaveEndDate = ''
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      let currentDate = new Date();
      let timeFormat = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()
      this.leaveStartDate = this.settingService.formatDate(event.value[0]) + " " + timeFormat
      this.leaveEndDate = this.settingService.formatDate(event.value[1]) + " " + timeFormat
    }
  }

}
