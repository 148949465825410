import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-office-connections-inner-tab',
  templateUrl: './office-connections-inner-tab.component.html',
  styleUrls: ['./office-connections-inner-tab.component.scss']
})
export class OfficeConnectionsInnerTabComponent implements OnInit {
  @Input() projectId:number;
  @Input() approveStatus:any = [];

  constructor() { }

  ngOnInit() {
    //console.log(this.approveStatus);
  }

}
