import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../services/setting.service';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import * as Globals from '../../../../core/globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { DocumentViewModalComponent } from '../../../../core/components/document-view-modal/document-view-modal.component';
@Component({
  selector: 'app-dispatch-tab',
  templateUrl: './dispatch-tab.component.html',
  styleUrls: ['./dispatch-tab.component.scss']
})
export class DispatchTabComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  vendorList:any = [];
  externalUserTypeList:any =[];
  transportType:number;
  defaultPagination: number;
  totalDispatchList: number;
  itemPerPage: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemNo: number;
  dispatchList: any = [];
  poListings: any = [];
  itemListByPo: any = [];
  savedResult: any = [];
  openForm: number;
  disabledVendor:any = [];
  poNumberList:any = [];
  dispatchPOList:any = [];
  finalPoNumberList:any = [];
  hidePO:any = [];
  @Input() requisitionId:number;
  //dispatchListItem:any = [];
  deliveredItemList:any = [];
  selectedFile: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private settingService:SettingService,
    private executionPurchaseService:ExecutionPurchaseService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    
    this.form = this.formBuilder.group({
      po_id:[null,Validators.required],
     dispatchFormControl: this.formBuilder.array([ 
        this.createDispatchForm()
      ]),
    });

   
   
    this.loadDispatchData();
    //this.getDispatchListItem();

    this.itemListByPo = [{
      description:null,
      id:null,
      mat_code:null,
      name:null,
      quantity:null,
      uom:null,
      uom_name:null,
      vendorId:null,
      vendorName:null,
      vendorCode:null,
      poAmount:null,
      poNumber:null,

    }];
    this.openForm = 0;
  }

  

  loadDispatchData(){
    var forkArray = [];

    forkArray.push(this.executionPurchaseService.getPurchaseOrderList(this.requisitionId))
    forkArray.push(this.executionPurchaseService.getDispatchList(this.requisitionId))
   // forkArray.push(this.executionPurchaseService.getDeliveryItemList(this.requisitionId))

    forkJoin(forkArray).subscribe(
      (res: any[]) => {
     
        for (var i = 0; i < res.length; i++) {
          if(i == 0){
            this.poListings = res[i]['result'];
           // console.log(this.poListings)
            this.poNumberList = [];
            this.poListings.forEach(x => {
              //
              this.poNumberList.push(x.po_no);

            });
            
            //console.log( this.poNumberList)
          }
          if(i == 1){
            
            this.dispatchList = res[i]['result']; 
            //console.log(this.dispatchList)
            this.dispatchList.forEach((x,index)=>{
              
              if(this.dispatchPOList.indexOf(x.po_no)==-1){
                this.dispatchPOList.push(x.po_no);
              }
            });
           // console.log(this.poListings)
            this.finalPoNumberList =  this.poNumberList;
            // if(this.dispatchList.length > 0){
            //   this.finalPoNumberList =  this.poNumberList;
            // }else{
            //  this.finalPoNumberList =  this.poNumberList;
             
            // }

            this.loading = LoadingState.Ready;
          }
          // if(i==2){
          //   this.deliveredItemList = res[i]['result'];
          //   if(this.deliveredItemList.length > 0){
          //       this.dispatchList.forEach((x,index)=>{
          //         let deliveredItem = this.deliveredItemList.filter(z => z.received_item == x.dispatch_item);
          //         if(deliveredItem.length > 0){
          //           this.dispatchList[index]['owned_by'] = 'dasmann90';
          //         }

          //       })

          //       console.log(this.dispatchList)
          //   }
          // }
                    
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready;
        
      }
    )

  }

  compare(arr1,arr2){
    const finalarray = [];
    arr1.forEach((e1)=> arr2.forEach((e2)=>
      {
        
        if(arr2.indexOf(e1)==-1){
          if(finalarray.indexOf(e1)==-1){
            finalarray.push(e1);
          }
          
        }
      }
    ));
    return finalarray;
  }
  selectedPoNumber(poNumber: string){

    var len = this.form.controls['dispatchFormControl']['controls'].length;
    var control = <FormArray>this.form.controls['dispatchFormControl'];
    let tempItemListByPo = [];

    for(let i=0;i<len;i++){
        control.removeAt(0);
        this.itemListByPo.splice(0,1)
      }

    let dispatchedItemPoNumber = this.dispatchList.filter(x=> x.po_no == poNumber);
    

    this.poListings.forEach((x,index) => {
      if(x.po_no == poNumber){
       
        this.disabledVendor[index]= true;
        this.openForm = 1;
        if(dispatchedItemPoNumber.length > 0){
          dispatchedItemPoNumber.forEach((z,index)=>{
            x.items.forEach((item,index) =>{
              if(z.dispatch_item == item.id){
                x.items.splice(index,1);
              }
            })
          })
        }

        tempItemListByPo = x.items;
        
        
        console.log(tempItemListByPo)
        
        tempItemListByPo.forEach((j,index) => {
          
         // let dispatchedItem =  dispatchedItemPoNumber.filter(z => z.dispatch_item == j.id);
         // if(dispatchedItem.length == 0){
            var d = {
              description:null,
              id:null,
              mat_code:null,
              name:null,
              quantity:null,
              uom:null,
              uom_name:null,
              vendorId:null,
              vendorName:null,
              vendorCode:null,
              poAmount:null,
              poNumber:null,
              poDate:null
            }
            const current = new Date(x.date_of_po);
            this.addDispatch();
            this.itemListByPo.push(d);
            this.itemListByPo[index].id = j.id; 
            this.itemListByPo[index].name = j.name; 
            this.itemListByPo[index].uom_name = j.uom_name; 
            this.itemListByPo[index].uom = j.uom; 
            this.itemListByPo[index].quantity = j.quantity; 
            
            this.itemListByPo[index].vendorId = x.vendor;
            this.itemListByPo[index].vendorName = x.vendor_name;
            this.itemListByPo[index].vendorCode = x.vendor_code;
            this.itemListByPo[index].poAmount = x.po_amount;
            this.itemListByPo[index].poNumber = x.po_no;
            this.itemListByPo[index].poDate = {
              year: current.getFullYear(),
              month: current.getMonth() + 1,
              day: current.getDate()
            }
         // } 
          console.log(this.itemListByPo)
        })
      }
     
    });

  }

  deleteDispatch(dispatchId: number){
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing
        this.executionPurchaseService.deleteDispatch(dispatchId).subscribe(
          res => {
            this.loadDispatchData();
            this.loading = LoadingState.Ready
          },
          error => {
            // console.log(error)
            this.loading = LoadingState.Ready
          }
        )
      }
      this.dialogRef = null;
  })
  }

  // getDispatchListItem(){
  //   this.loading = LoadingState.Processing;
  //  this.executionPurchaseService.getDispatchList(this.requisitionId).subscribe(
  //    res => {
  //      console.log(res)
  //     this.dispatchListItem = res['result'];
  //    },
  //    error =>{
  //     console.log(error)
  //    }
  //  )
  // }

  createDispatchForm(){
    return this.formBuilder.group({
      requisitions_master: [null],
      po_no: [null, Validators.required],
      dispatched_item:["",Validators.required],
      dispatch_item:["",Validators.required],
      umo:["",Validators.required],
      umo_name:["",Validators.required],
      date_of_dispatch: ["",Validators.required],
      quantity: ["",Validators.required],
      vendor_name: ["",Validators.required],
      vendor: ["",Validators.required],
      dispatch_cost: ["",Validators.required],
      document: ["", Validators.required],
      document_name: ["", Validators.required],
      dispatch_document:[""]

    })
  }


  saveDispatch(){

    if(this.form.valid){
      // console.log(this.form.value.dispatchFormControl)
     
      this.loading = LoadingState.Processing;
     
      var dispatch_data = [];
      dispatch_data = this.form.value.dispatchFormControl;

      dispatch_data.forEach((x,index) => {
        var data = {
          requisitions_master:this.requisitionId,
          // completed_status:0,
          dispatch_item:x.dispatch_item,
          quantity:x.quantity,
          vendor:x.vendor,
          uom:x.umo,
          date_of_dispatch: this.settingService.getProperDate(x.date_of_dispatch).toISOString(),//aa
          po_no:x.po_no,
          dispatch_cost:x.dispatch_cost
        
        }
       
       
        this.executionPurchaseService.addDispatch(data).subscribe(
          res=>{
            
            var forkArray = [];
            const formData: any = new FormData(); 
            formData.append("dispatch", res['id']);
            formData.append("document_name", x.document_name);
            formData.append("document", x.dispatch_document, x.dispatch_document['name']);
            forkArray.push(this.executionPurchaseService.addDispatchDocument(formData))

            forkJoin(forkArray).subscribe(
              (result: any[]) => {
                
               // console.log(result)
                // result.forEach(y => {
                  
                // })
              //  console.log(this.requisitionId)
                this.loadDispatchData();
               this.loading = LoadingState.Ready;
              },
              err => {
                this.loading = LoadingState.Ready;
                
              }
            )

           
            if(index !=0){
              this.removeDispatch(1);
            }
            this.selectedFile = [];
            this.form.reset();
            
            this.loading = LoadingState.Ready;  

            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
            });
            
            
          },
          error =>{
           // console.log(error)
            this.loading = LoadingState.Ready; 
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            }); 
          }
        )
        
      });
      
    }
    else{
      this.markFormGroupTouched(this.form)
    }

    
    //this.getDispatchListItem();
  }

  addDispatch(){
    const control = <FormArray>this.form.controls['dispatchFormControl'];
    control.push(this.createDispatchForm());

  } 

  removeDispatch(index:number)
  {
    const control = <FormArray>this.form.controls['dispatchFormControl'];
    this.itemListByPo.splice(index,1)
    control.removeAt(index);
    //control.reset();

  }

  getFormArrayControl(form) {
    return form.get('dispatchFormControl').controls
  }

  onSelectFile(event, index){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['dispatchFormControl'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          dispatch_document: event.target.files[0]
          
        })
        reader.readAsDataURL(event.target.files[i]);
        var data = { index: index}
        this.selectedFile.push(data);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


  viewDispatchDocument(documents: any){
    // console.log(documents)
     var data = [];
     documents.forEach(x =>{
       if (x.document_name != null)
       data.push({
         name: x.document_name,
         url: x.document
       });
     })
     //console.log(data);
 
       if (data.length > 0) {
         let dialogRef = this.dialog.open(DocumentViewModalComponent, {
           width: '525px',
           data: { items: data }
         });
         dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
         })
       }
   }
}
