import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { LoadingState } from '../../../components/loading/loading.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-labour',
  templateUrl: './labour.component.html',
  styleUrls: ['./labour.component.scss']
})
export class LabourComponent implements OnInit {
  @Input() currentmaintab: string;
  @Input() date: any;
  @Input() smonthdate: any;
  @Input() sweekstartdate: any;
  @Input() sweekenddate: any;
  @Input() projectid: number;
  @Input() siteid: number;
  public loading: LoadingState = LoadingState.NotReady;
  progressparam: any = '';
  locationName: string;
  reportarr: any = [];
  @Output() getDownloadLink: EventEmitter<number> = new EventEmitter<number>();
  
  constructor(
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    public router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getLabourReports()
  }

  getLabourReports() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'daily') {
      if (this.date) {
        let date = this.settingService.getProperDate(this.date);
        this.progressparam = 'date=' + date.toISOString().substr(0, 10);//aa
      }
      else {
        this.progressparam = '';
      }
    }
    else if (this.currentmaintab == 'monthly') {
      if (this.smonthdate) {
        let date = this.settingService.getOwlProperDate(this.smonthdate);
        // console.log(date);
        let sdate = new Date(date.getFullYear(), date.getMonth(), 1);
        let edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
        sdate = this.settingService.getOwlProperDate(sdate);
        edate = this.settingService.getOwlProperDate(edate);
        this.progressparam = 'start_date=' + sdate.toISOString().substr(0, 10) + '&end_date=' + edate.toISOString().substr(0, 10);//aa //aa
      }
      else {
        this.progressparam = '';
      }
    }
    // console.log(this.progressparam)
    if (this.projectid && this.siteid && this.progressparam) {
      if(this.currentmaintab === 'daily' || this.currentmaintab === 'monthly') {
        this.reportService.getLabourReportData(this.projectid, this.siteid, this.progressparam).subscribe(
          data => {
            var res: any = data['results'];
            this.reportarr = [];
            this.reportarr = res.execution_daily_lab;
            this.locationName = res.site_location_name;
            this.getDownloadLink.emit(res.url)
            this.loading = LoadingState.Ready;
          },
          error => {
            this.loading = LoadingState.Ready;
            console.log(error)
          }
        );
      }
    }
    else {
      this.loading = LoadingState.Ready;
    }

  }

  excelDownload() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'daily') {
      if (this.date) {
        let date = this.settingService.getProperDate(this.date);
        this.progressparam = 'is_download=yes&date=' + date.toISOString().substr(0, 10);//aa
      }
      else {
        this.progressparam = '';
      }
    } else if (this.currentmaintab == 'weekly') {
      if (this.sweekstartdate) {
        let sdate = this.sweekstartdate;
        let edate = this.sweekenddate;
        this.progressparam = 'is_download=yes&start_date=' + sdate + '&end_date=' + edate
      }
      else {
        this.progressparam = '';
      }
    } else if (this.currentmaintab == 'monthly') {
      if (this.smonthdate) {
        let date = this.settingService.getOwlProperDate(this.smonthdate);
        // console.log(date);
        let sdate = new Date(date.getFullYear(), date.getMonth(), 1);
        let edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
        sdate = this.settingService.getOwlProperDate(sdate);
        edate = this.settingService.getOwlProperDate(edate);
        this.progressparam = 'is_download=yes&start_date=' + sdate.toISOString().substr(0, 10) + '&end_date=' + edate.toISOString().substr(0, 10);//aa //aa
      }
      else {
        this.progressparam = '';
      }
    }

    if (this.projectid && this.siteid && this.progressparam) {
      this.reportService.getLabourReportData(this.projectid, this.siteid, this.progressparam).subscribe(
        data => {
          window.open(data.url, '_blank');
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready;
          console.log(error)
        }
      );
    }
    else {
      this.loading = LoadingState.Ready;
    }
  }
}
