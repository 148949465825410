import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { LocalService } from '../../../../services/local.service';
import { StockService } from '../../../../services/stock.service';
import { LoadingState } from '../../../../components/loading/loading.component';
import { ToastrService } from 'ngx-toastr';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { forkJoin } from 'rxjs';
import * as Globals from '../../../../../core/globals';
import {AppPageStateConfig} from '../../../../../app.config';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.scss']
})
export class IssueListComponent implements OnInit {
 
  public loading: LoadingState = LoadingState.NotReady;
  siteList: any = [];
  projectList: any = [];
  siteId: any = '';
  projectId: any = '';
  issueList: any = [];
  slipId: number;
  showIssueDetail: number = 0;
  itemList:any;
  totalIssueList: any = [];
  isApprovedList: any = [];
  isApproved: boolean = false;
  isApprove: number;
  permissionList: any = [];
  currentUserArray: any = []
  errorShow:boolean = true;
  higherLevelPermission:number;

  defaultPagination: number;
  totalListCount: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  pageSize:number;
  headerThOption = [];
  stockIssueConfig:any;

  constructor(
    private router: Router,
    public projectSiteServices: projectSiteServices,
    public stockService: StockService,
    private toastr: ToastrService,
    private executionPurchaseService: ExecutionPurchaseService,
    private localService: LocalService,
  ) { 
    this.stockIssueConfig = AppPageStateConfig.stockIssueConfig;
    if(this.stockIssueConfig.siteId!=null){
      this.siteId = this.stockIssueConfig.siteId;
      this.setProjectSiteId(this.siteId)
    }
  }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    this.headerThOption = [
      {
        id: 1,
        name: "Project ID",
        code: "project_id",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Date of Issue",
        code: "issue_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Issue Slip No.",
        code: "issue_slip_no",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "Name of Contractor",
        code: "contractor_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "No. of Items",
        code: "items_no",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Requisitioned by",
        code: "requisitioned_by",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 7,
        name: "Authorized by",
        code: "authorized_by",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 8,
        name: "Received by",
        code: "received_by",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },

      {
        id: 9,
        name: "Store keeper",
        code: "store_keeper",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
    ]
    this.getPermissionsAndProjects();
  }

  getPermissionsAndProjects(){
    var forkArray = [];
   let params: URLSearchParams = new URLSearchParams();
    params.set('section', 'Stock Issue');
    
    forkArray.push(this.projectSiteServices.getProjectSitesList())
    forkArray.push(this.executionPurchaseService.getPermissionLevel(params))
    
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
       for (var i = 0; i < result.length; i++) {
          if(i==0){
            this.siteList = result[i]['result'];   
          }
          if(i==1){
            var userid = this.localService.getJsonValue('userid');
      
            if(result[i]['result'].user_approve_details.length > 0){
              this.permissionList = result[i]['result'];
              this.higherLevelPermission = this.permissionList['user_approve_details'].length - 1;
              this.permissionList.user_approve_details.forEach(x => {
                if(x.user_details.id == userid){
                  this.currentUserArray = x;
                }
              });
            //  console.log(this.higherLevelPermission)
            }  
            
          }
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        //console.log(err);
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal server error', '', {
          timeOut: 3000,
        });

      }
    )
  }
  // getProjectSiteList()
  // {
  //   this.projectSiteServices.getProjectSitesList().subscribe(
  //     res=>{          
  //       this.loading = LoadingState.Ready;
  //       this.siteList = res['result'];          
  //     },
  //     error=> {
  //       this.loading = LoadingState.Ready;
  //       this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //     }
  //   );
  // }
  // getPermissionLevel(){
  //   let params: URLSearchParams = new URLSearchParams();
  //   params.set('section', 'requisition');
  //   this.executionPurchaseService.getPermissionLevel(params).subscribe(
  //     res => {
  //       var userid = this.localService.getJsonValue('userid');
      
  //       if(res['result'].user_approve_details.length > 0){
  //         this.permissionList = res['result'];
  //         this.permissionList.user_approve_details.forEach(x => {
  //           if(x.user_details.id == userid){
  //             this.currentUserArray = x;
  //           }
  //         });
  //       } 
       
  //     //  console.log(this.currentUserArray);

  //       this.loading = LoadingState.Ready;
  //     },
  //     error => {
  //       this.loading = LoadingState.Ready
  //     }
  //   )
  // }

  setProjectSiteId(val) {    
    if(val){
      this.stockIssueConfig.siteId = val;
      this.loading = LoadingState.Processing;
      this.projectSiteServices.getProjectBySite(val).subscribe(
        res=>{
          this.projectList = res['result'];
          this.projectId = this.projectList[0].id;
          this.getIssueList()

         // this.loading = LoadingState.Ready; 
        },
        error=> {
          this.loading = LoadingState.Ready; 
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    }else{
      this.projectId = '';
      this.issueList = []
    }
    
  }

  getIssueList(){
    if(this.projectId && this.siteId){
      let params: URLSearchParams = new URLSearchParams();
      params.set('page', this.defaultPagination.toString());
      if (this.itemPerPage) {
        params.set('page_count', this.itemPerPage.toString());
      }
      params.set('section_name', 'Stock Issue');

      this.loading = LoadingState.Processing;
      this.stockService.getStockIssueList(this.projectId,this.siteId,params).subscribe(
        res=>{
          this.issueList = res['results'];
          this.totalListCount = res.count;
          this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
          this.lower_count = this.itemNo + 1;
          if (this.totalListCount > this.itemPerPage * this.defaultPagination) {
            this.upper_count = this.itemPerPage * this.defaultPagination
          }
          else {
            this.upper_count = this.totalListCount
          }

          this.loading = LoadingState.Ready; 
        },
        error=> {
          this.loading = LoadingState.Ready; 
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    }else{
      this.issueList = []
    }
    

  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.getIssueList();
  }


  viewSlipDetails(id: number){

    this.showIssueDetail = 1;
    this.slipId = id;
    this.getIssueItemList();
    this.isApproveCheck();
  }
 
  getIssueItemList(){
    if(this.slipId != undefined){
      var params = '&section_name=Stock Issue';
      this.projectSiteServices.getTotalIssueList(this.slipId,params).subscribe(
        res => {
          this.totalIssueList = res['results'];
         // console.log(this.totalIssueList)
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    }
    
  }

  
  isApproveCheck(){
    this.projectSiteServices.isApproveIssueList(this.slipId).subscribe(
      res=>{          
        
        this.isApproved = res.is_approved;
        this.isApprovedList = res;
      //  console.log(this.isApprovedList)
        this.loading = LoadingState.Ready;
      },
      error=> {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    );
  }

  // updateIssue(){
  //   var data = {"is_approved":this.isApprove,"project_id":this.projectId,"site_location":this.siteId};
  //   this.projectSiteServices.addApprovalStatus(this.slipId,data).subscribe(
  //     res=>{
  //       this.toastr.success('Data has been submitted.', '', {
  //         timeOut: 3000,
  //       });          
  //       this.showIssueDetail = 0;
  //       this.loading = LoadingState.Ready;
  //     },
  //     error=> {
  //       this.loading = LoadingState.Ready;
  //       this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //     }
  //   );
  
  //   console.log(this.isApprove);
  // }

  goBack(){
    this.showIssueDetail = 0;
    this.isApprove = undefined;
  }

  issueSubmitForApproval()
  {
    if(this.isApprove != undefined){
    
      delete this.isApprovedList.created_at; 
      delete this.isApprovedList.created_by; 
      delete this.isApprovedList.owned_by; 
      delete this.isApprovedList.updated_at; 
      console.log(this.isApprove);

      this.isApprovedList.issue_approval = this.isApprove;

      this.isApprovedList.issue_master = this.totalIssueList.id;
      this.isApprovedList.approval_permission_user_level = this.currentUserArray.id;
      // console.log(this.slipId)
      // console.log(this.isApprovedList)
  
      this.loading = LoadingState.Processing;
      var param = "section_name=Stock Issue";
      this.stockService.insertSubmitForApproval(this.slipId,param,this.isApprovedList).subscribe(
        res=>{
          this.loading = LoadingState.Ready;
          this.toastr.success('Submitted for approval successfully.','',{
            timeOut: 3000,
          });
          //this.router.navigateByUrl('/stock');
          this.goBack();
        },
        error=> {
          
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    }else{
      this.errorShow = false
    }
 }

  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.getIssueList();
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
  }
}
