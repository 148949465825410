import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-mobilization-utilities-inner-tab',
  templateUrl: './site-mobilization-utilities-inner-tab.component.html',
  styleUrls: ['./site-mobilization-utilities-inner-tab.component.scss']
})
export class SiteMobilizationUtilitiesInnerTabComponent implements OnInit {
  @Input() projectId:number;
  @Input() tabId:number;

  constructor() { }

  ngOnInit() {

    //console.log(this.projectId);
  }

}
