import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparative-statement-allviews',
  templateUrl: './comparative-statement-allviews.component.html',
  styleUrls: ['./comparative-statement-allviews.component.scss']
})
export class ComparativeStatementAllviewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
