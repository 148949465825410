import { Component,  Input, OnInit } from '@angular/core';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { SettingService } from '../../../../core/services/setting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-external-user-add',
  templateUrl: './external-user-add.component.html',
  styleUrls: ['./external-user-add.component.scss']
})
export class ExternalUserAddComponent implements OnInit {

  @Input() userType: string;
  userTypeStr:string;
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  externalUserTypeList: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService,
    private router: Router,
    private location: Location,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
   // console.log(this.userType);
   
    if(this.userType=="vendor")
    {       
      this.userTypeStr = "Vendor"
    }
    if(this.userType=="contractor")
    {       
      this.userTypeStr = "Contractor"
    }
    if(this.userType=="partner")
    {       
      this.userTypeStr = "Partner"
    }
    if(this.userType=="operator")
    {       
      this.userTypeStr = "Operator"
    }
    if(this.userType=="crusher")
    {       
      this.userTypeStr = "Crusher"
    }
    this.form = this.formBuilder.group({
      contact_person_name: ['', Validators.required],
      user_type: ['', Validators.required],
      contact_no: [''],
      email: [''],
      address: [''],
      organisation_name: ['', Validators.required],
      // 
      code: [''],
      adhar_no: [''],
      adhar_doc: [''],
      bank_ac_no: [''],
      cancelled_cheque_doc: [''],      
      gst_no: [''],
      gst_doc: [''],
      pan_no: [''],
      pan_doc: [''],
      salary: [''],
      trade_licence_doc: [''],
      // 
      documents: this.formBuilder.array([])
    });
    this.getExternalUserTypeList();
  }

  getExternalUserTypeList() {
    this.settingService.getExternalUserTypeList().subscribe(
      res => {
       // console.log(res);
        this.externalUserTypeList = res;
        var index = this.externalUserTypeList.findIndex(x => x['type_name'].toLowerCase() == this.userType);
        if (index != -1) {
          this.form.patchValue({
            user_type: this.externalUserTypeList[index]['id']
          })
        }
       // console.log(index)
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
      }
    )
  }

  create_document() {
    return this.formBuilder.group({
      document_name: [''],
      file_hidden: [null],
      document: null
    });
  }
  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }
  add_document() {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.create_document());
  }

  delete_document(index: number) {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  onSelectFile(event, index) {
   // console.log(event);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          file_hidden: event.target.files[0]['name'],
          document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectAdharFile(event) {
    //console.log(event);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          adhar_doc: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectCancelledChequeFile(event) {
    //console.log(event);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          cancelled_cheque_doc: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectGSTDocFile(event) {
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          gst_doc: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectPanDocFile(event) {
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          pan_doc: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectTradeLicenceDocFile(event) {
    //console.log(event);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          trade_licence_doc: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  save() {
    //console.log(this.form.value)

    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var documents_data = [];
      documents_data = this.form.value.documents;
      const data: any = new FormData();
      data.append("contact_person_name", this.form.value.contact_person_name);
      data.append("user_type", this.form.value.user_type);
      data.append("contact_no", this.form.value.contact_no);
      data.append("email", this.form.value.email);
      data.append("organisation_name", this.form.value.organisation_name);
      data.append("code", this.form.value.code);
      data.append("adhar_no", this.form.value.adhar_no);
      if(this.form.value.adhar_doc)
      {
        data.append("adhar_doc", this.form.value.adhar_doc, this.form.value.adhar_doc['name']);
      }
      //data.append("adhar_doc", this.form.value.adhar_doc, this.form.value.adhar_doc['name']);
      data.append("bank_ac_no", this.form.value.bank_ac_no);
      if(this.form.value.cancelled_cheque_doc)
      {
        data.append("cancelled_cheque_doc", this.form.value.cancelled_cheque_doc, this.form.value.cancelled_cheque_doc['name']);
      }
      data.append("gst_no", this.form.value.gst_no);
      if(this.form.value.gst_doc)
      {
        data.append("gst_doc", this.form.value.gst_doc, this.form.value.gst_doc['name']);
      }
      
      data.append("pan_no", this.form.value.pan_no);
      if(this.form.value.pan_doc)
      {
        data.append("pan_doc", this.form.value.pan_doc, this.form.value.pan_doc['name']);
      }
     
      data.append("salary", this.form.value.salary);
      if(this.form.value.trade_licence_doc)
      {
        data.append("trade_licence_doc", this.form.value.trade_licence_doc, this.form.value.trade_licence_doc['name']);
      }
      
      data.append("address", this.form.value.address);
      this.settingService.addExternalUser(data).subscribe(
        res => {
         // console.log(res);
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
          if (documents_data.length > 0) {
            var forkArray = [];
            documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("external_user", res['id']);
              formData.append("document_name", x.document_name);
              formData.append("document", x.document, x.document['name']);
              forkArray.push(this.settingService.addExternalUserDoc(formData))
            })
            forkJoin(forkArray).subscribe(
              result => {
                // console.log(result)
                if(this.userType=="vendor")
                {            
                  this.router.navigateByUrl('/vendor/vendor-list');
                }
                if(this.userType=="contractor")
                {       
                  this.router.navigateByUrl('/contractor/contractor-list');
                }
                if(this.userType=="partner")
                {       
                  this.router.navigateByUrl('/partners/partner-list');
                }
                if(this.userType=="operator")
                {       
                  this.router.navigateByUrl('/operator/operator-list');
                }
                if(this.userType=="crusher")
                {       
                  this.router.navigateByUrl('/crusher/crusher-list');
                }
                this.loading = LoadingState.Ready;
                
              },
              err => {
                this.loading = LoadingState.Ready;
                // console.log(err)
               
              }
            )
          }
          else {
            if(this.userType=="vendor")
            {  
              this.router.navigateByUrl('/vendor/vendor-list');
            }
            if(this.userType=="contractor")
            {       
              this.router.navigateByUrl('/contractor/contractor-list');
            }
            if(this.userType=="partner")
            {       
              this.router.navigateByUrl('/partners/partner-list');
            }
            if(this.userType=="operator")
            {       
              this.router.navigateByUrl('/operator/operator-list');
            }
            if(this.userType=="crusher")
            {       
              this.router.navigateByUrl('/crusher/crusher-list');
            }
            this.loading = LoadingState.Ready;
          }
        },
        error => {
          //console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
