import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../components/loading/loading.component';
import { DailyExpenseService } from '../../../services/daily-expense.service';
import { LocalService } from '../../../services/local.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: ['./approval-modal.component.scss']
})
export class ApprovalModalComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  attendanceApprovalData: any;
  leaveApprovalData: any
  expenseData: any
  isModify: boolean = true;
  approvedStatusValid: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ApprovalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dailyExpenseService: DailyExpenseService,
    private toastr: ToastrService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.form = this.formBuilder.group({
      expense_approval: [null, Validators.required],
      approved_expenses: [{ value: null, disabled: true }],
      remarks: [''],
    })
    this.expenseData = this.data['result'];
    this.loading = LoadingState.Ready;
  }

  checkExpenseApproval(event) {
    // console.log(event)
    if (event.value != '') {
      this.approvedStatusValid = false;
    }

    this.form.patchValue(
      {
        approved_expenses: null
      }
    )
    let radioValue = event.value;
    if (radioValue == 'Modify') {
      this.isModify = false;
      this.form.controls['approved_expenses'].enable();
      this.form.controls['approved_expenses'].setValidators([Validators.required]);
      this.form.controls['approved_expenses'].setErrors({ required: true });
    } else {
      this.isModify = true;
      this.form.controls['approved_expenses'].disable();
      this.form.controls['approved_expenses'].clearValidators();
      this.form.controls['approved_expenses'].setErrors(null);
    }

    if (radioValue != '') {
      this.approvedStatusValid = false;
    }
  }

  apply() {

    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var daily_expence_approvals = [];
      var data = {
        status: this.form.value.expense_approval,
        approval_user_level_name: this.expenseData.approval_user_level_name,
        approval_user_level: null,
        daily_expence_approvals: [],
        remarks: this.form.value.remarks
      }

      if (this.form.value.expense_approval == 'Modify') {
        data['approve_amount'] = this.form.value.approved_expenses
        data['status'] = "Approve"
      }
      
      daily_expence_approvals.push(this.expenseData.id)
      data['daily_expence_approvals'] = daily_expence_approvals    
      var index = this.expenseData.approvals.findIndex(x => x.user == +this.localService.getJsonValue('userid')  && x.level == this.expenseData.approval_user_level_name)
      
      data['approval_user_level'] = this.expenseData.approvals[index]['id'] 
      this.dailyExpenseService.dailyExpStatusUpdate(data).subscribe(
        res => {
          this.close(true);
          this.loading = LoadingState.Ready;
        },
        error => {
          this.close(false);
          // console.log(error)
        }
      )


    } else {
      this.markFormGroupTouched(this.form)
    }
  }

  close(status) {
    this.dialogRef.close(status);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
