import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingState } from '../../loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TenderService } from '../../../services/tender.service';

@Component({
  selector: 'app-add-photo-modal',
  templateUrl: './add-photo-modal.component.html',
  styleUrls: ['./add-photo-modal.component.scss']
})
export class AddPhotoModalComponent implements OnInit {
  form: FormGroup;
  tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  SitePhotoDetails: any = {
    latitude: '',
    longitude: '',
    address: '',
    additional_notes: '',
    document_name: '',
    document: ''
  };
  constructor(
    public dialogRef: MatDialogRef<AddPhotoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private tenderService: TenderService
  ) { }

  ngOnInit() {
    this.loading = LoadingState.Ready;
    this.tenderId = this.data['tenderId'];

    this.form = this.formBuilder.group({
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      address: ['', Validators.required],
      additional_notes: ['', Validators.required],
      document_name: ['', Validators.required],
      document: null,
      file: ['', Validators.required],
    });
    if (this.tenderId == undefined) {
      this.SitePhotoDetails = this.data['SitePhotoDetails']
      if (this.SitePhotoDetails.document != null) {
        var file = this.SitePhotoDetails.document.split('/')
        var fileName = file[file.length - 1]
        console.log(fileName)
        this.form.patchValue({
          file: fileName
        })
      }
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          file: event.target.files[0]['name'],
          document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
    //console.log(this.form.value)
    if (this.form.valid) {
      //console.log(this.tenderId)
      if (this.tenderId != undefined) {
        this.loading = LoadingState.Processing
        const formData: any = new FormData();
        formData.append("tender", this.tenderId);
        formData.append("latitude", this.form.value.latitude);
        formData.append("longitude", this.form.value.longitude);
        formData.append("address", this.form.value.address);
        formData.append("additional_notes", this.form.value.additional_notes);
        formData.append("document_name", this.form.value.document_name);
        formData.append("document", this.form.value.document, this.form.value.document['name']);
        this.tenderService.addTenderSitePhoto(formData).subscribe(
          res => {
            //console.log(res)
            this.dialogRef.close({ data: res });
            this.loading = LoadingState.Ready
          },
          error => {
            console.log(error)
            this.loading = LoadingState.Ready
          }
        )
      }
      else {
       // console.log(this.form.value)
        this.loading = LoadingState.Processing
        const formData: any = new FormData();
        formData.append("latitude", this.form.value.latitude);
        formData.append("longitude", this.form.value.longitude);
        formData.append("address", this.form.value.address);
        formData.append("additional_notes", this.form.value.additional_notes);
        formData.append("document_name", this.form.value.document_name);
      //  console.log(this.form.value.file)
        if (this.form.value.document != null) {
          formData.append("document", this.form.value.document, this.form.value.document['name']);
        }
        this.tenderService.editTenderSitePhoto(this.SitePhotoDetails['id'], formData).subscribe(
          res => {
            //console.log(res)
            this.dialogRef.close({ data: res });
            this.loading = LoadingState.Ready
          },
          error => {
            console.log(error)
            this.loading = LoadingState.Ready
          }
        )

      }

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  getFileName(filename) {
    if (filename == '') {

    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
