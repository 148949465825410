import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class projectSiteServices {

  constructor(private http: HttpClient) {
  }

  getProjectSitesType(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'project_site_management_site_type_add/')
  }

  getProjectSitesList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'project_site_management_site_add/')
  }

  getProjectBySite(sid): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_details_by_project_site_id/?site_location=' + sid)
  }

  addProjectSites(data: any) {
    //console.log(data);
    return this.http.post(environment.apiEndpoint + 'project_site_management_site_add/', data)
  }

  deleteProjectSites(id: number): Observable<any> {

    return this.http.put(environment.apiEndpoint + 'project_site_management_site_delete/' + id + '/', {})
  }

  getProjectSitesDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'project_site_management_site_edit/' + id + '/')
  }

  updateProjectSitesDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'project_site_management_site_edit/' + id + '/', data)
  }

  getMaterialsList(projectId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'materials_list/?project=' + projectId)
  }
  getMechinaryList(projectId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_list_filter_for_report/?project=' + projectId)
  }
  getModeList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_issue_mode_add/')
  }
  getTotalIssueList(issueId: number, params = ''): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_issue_item_list_by_issue_id/?issue_id=' + issueId + params)
  }
  isApproveIssueList(issueId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_stock_issue_approved/' + issueId + '/')
  }

  addIssueSlip(data: any) {
    //console.log(data);
    return this.http.post(environment.apiEndpoint + 'execution_stock_stock_issue_add/', data)
  }

  addApprovalStatus(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_stock_stock_issue_approved/' + id + '/', data)
  }

  // 25-06-2020
  getProjectSitesListByUser(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'project_sites_by_login_user/')
  }

  getUserListBySiteLocation(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_list_by_location/?' + params)
  }
  
  getEmployeeListByProjectAndSite(site: number, project: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'employee_list_by_site_project/' + site + '/?project=' + project);
  }
  
  getRainfallShortageStatus(params):  Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_list_by_location/?' + params)
  }
}