import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service'
import { LoadingState } from '../../loading/loading.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-connection-internet-inner-tab',
  templateUrl: './connection-internet-inner-tab.component.html',
  styleUrls: ['./connection-internet-inner-tab.component.scss']
})
export class ConnectionInternetInnerTabComponent implements OnInit {
  @Input() projectId:number;
  isJioFi:boolean;
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  internetDetails:any = [];
  internetDocumentList: any = [];
  structure_name: string;
  @Input() approveStatus:any= [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(  
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private perExecutionService:PerExecutionService,
    public settingService:SettingService) {
      this.isJioFi=true;
      this.structure_name = 'jio';
     }

  ngOnInit() {

    this.form = this.formBuilder.group({
      project:this.projectId,
      
      office_connection:[''],
      connection_type:[1],
      quantity:[null],
      model:[null],
      package_availed:[null],
      price_limit:[null],
      supplier_name:[null],
      supplier_ph_no:[null],
      supplier_address:[null],
      service:[null],
      initial_installation_charges:[null],
     
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
  })

  // this.form.get("office_connection").valueChanges.subscribe(
  //     (mode: string) => {
  //         if (mode === 'jio') {
  //           // this.form.get('model').setValidators([Validators.required]);
  //           this.form.get('supplier_name').clearValidators();
  //           this.form.get('supplier_ph_no').clearValidators();
  //           this.form.get('supplier_address').clearValidators();
  //           this.form.get('service').clearValidators();
  //       }
  //       else if (mode === 'broadBand') {
  //         this.form.get('supplier_name').setValidators([Validators.required]);
  //         this.form.get('supplier_ph_no').setValidators([Validators.required]);
  //         this.form.get('supplier_address').setValidators([Validators.required]);
  //         this.form.get('service').setValidators([Validators.required]);
  //       }
  //     }
  //   )

  this.getInternetDetails();
  }


getInternetDetails(){
  this.loading = LoadingState.Processing;
  this.perExecutionService.getInternetConnectionOffice(this.projectId).subscribe(
    res=>{
      if(res['result'][0]){
        this.internetDetails = res['result'][0];

        this.internetDocumentList = res['result'][0].document_details;
          var start_date = new Date(this.internetDetails['requirment_s_date'])
            this.internetDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.internetDetails['requirment_e_date'])
            this.internetDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }

            this.structure_name = this.internetDetails.connection_type==1?'jio':'broadBand';
            this.isJioFi = this.internetDetails.connection_type==1?true:false;
          
          this.internetDetails.budgeted_cost = parseFloat(this.internetDetails.budgeted_cost).toFixed(2);
          this.internetDetails.executed_cost = parseFloat(this.internetDetails.executed_cost).toFixed(2);
          if(this.internetDetails.price_limit!=null){
            this.internetDetails.price_limit=parseFloat(this.internetDetails.price_limit).toFixed(2);  
          }
         
        //console.log(this.internetDetails);
      }
      this.loading = LoadingState.Ready;
    },
    error => {
     // console.log(error);
      this.loading = LoadingState.Ready;
      this.toastr.error('Something went wrong', '', {
        timeOut: 3000,
      });
    }
  )
}

getFile(file: string) {
  var fileName = file.split('.')
  var fileExt = fileName[fileName.length - 1];
  if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
    return file;
  }
  else {
    return 'assets/img/pdf.png'
  }
}

editDoc(index) {
  this.internetDocumentList[index]['isEdit'] = true
  this.internetDocumentList[index]['isFieldError'] = false
}

renameDoc(index) {
  if (this.internetDocumentList[index]['document_name'] == '') {
    this.internetDocumentList[index]['isFieldError'] = true;
  }
  else {
    this.loading = LoadingState.Processing
    this.internetDocumentList[index]['isFieldError'] = false;
    var data = {
      document_name: this.internetDocumentList[index]['document_name']
    }

    
    this.perExecutionService.editInternetConnectionDocumentOffice(this.internetDocumentList[index]['id'], data).subscribe(
      res => {
        // console.log(res)
        this.internetDocumentList[index]['isEdit'] = false
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }
}

deleteDoc(index) {

  this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
    disableClose: false
  });

  this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

  this.dialogRef.afterClosed().subscribe(result => {
   if(result){
    this.loading = LoadingState.Processing
    this.perExecutionService.deleteInternetConnectionDocumentOffice(this.internetDocumentList[index]['id']).subscribe(
    res => {
      this.internetDocumentList.splice(index, 1)
      // console.log(res)
      this.loading = LoadingState.Ready
    },
    error => {
      // console.log(error)
      this.loading = LoadingState.Ready
    }
  )
 }
 this.dialogRef = null;
 })
  
}

viewDoc(documentData){
  window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
}

handleChange(e: any) {
  var type;
    if (e.target.value == "jio") {
      this.isJioFi= true;
      type=1;
   }
   else{
    this.isJioFi= false;
    type=2;
   }

   this.form.patchValue({
    connection_type: type
  })
}

save(){
  if(this.form.valid){
    var internet_documents_data = [];
    internet_documents_data = this.form.value.documents;

    var data;
    if(this.form.value.connection_type==1){
       data = {
        
        project: this.projectId,
        connection_type: this.form.value.connection_type,
        quantity: this.form.value.quantity,
        model:this.form.value.model,
        package_availed:this.form.value.package_availed,
        price_limit:this.form.value.price_limit,
        supplier_name: null,
        supplier_ph_no: null,
        supplier_address: null,
        service: null,
        initial_installation_charges: null,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }

    }
    else{
       data = {
     
        project: this.projectId,
        connection_type: this.form.value.connection_type,
        quantity: null,
        model:null,
        package_availed:this.form.value.package_availed,
        price_limit:null,
        supplier_name: this.form.value.supplier_name,
        supplier_ph_no: this.form.value.supplier_ph_no,
        supplier_address: this.form.value.supplier_address,
        service: this.form.value.service,
        initial_installation_charges: this.form.value.initial_installation_charges,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }
    }

    this.internetDocumentList= [];
    this.perExecutionService.addInternetConnectionOffice(data).subscribe(
      res=>{
        if(this.form.value.documents.length > 0){
          var forkArray = [];
          internet_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
                formData.append("module_id", res.id);
                formData.append("document_name", x.document_name);
                formData.append("document", x.internet_document, x.internet_document['name']);
                forkArray.push(this.perExecutionService.addInternetConnectionDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            result => {
              // console.log(result)
              result.forEach(y => {
                this.internetDocumentList.push(y)
              })
             
              // this.add_tender_document();
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
         
        }
        this.loading = LoadingState.Ready;
        this.getInternetDetails()
        this.toastr.success('Data has been saved','',{
          timeOut: 3000,
        });
      },
      error=>{
        //console.log(error);
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )

    //console.log(data);

  }
  else{
    this.markFormGroupTouched(this.form);
  }
}

update(){
  if(this.form.valid){
    var internet_documents_data = [];
    internet_documents_data = this.form.value.documents;

    if(this.form.value.documents.length > 0){
      var forkArray = [];
      internet_documents_data.forEach(x => {
          const formData: any = new FormData();
          formData.append("project", this.projectId);
          formData.append("module_id", this.internetDetails.id);
          formData.append("document_name", x.document_name);
          formData.append("document", x.internet_document, x.internet_document['name']);
          forkArray.push(this.perExecutionService.addInternetConnectionDocumentOffice(formData))
      })
      forkJoin(forkArray).subscribe(
        result => {
          // console.log(result)
          result.forEach(y => {
            this.internetDocumentList.push(y)
          })
         
          // this.add_tender_document();
          this.loading = LoadingState.Ready;
        },
        err => {
          this.loading = LoadingState.Ready;
          // console.log(err)
        }
      )
      this.updateInternet();
    }
    else{
      this.updateInternet();
    }
  }
  else{
    this.markFormGroupTouched(this.form);
  }
}

updateInternet(){
  if(this.form.valid){
    this.loading = LoadingState.Processing;
    var updateData;
    if(this.form.value.connection_type==1){
      updateData = {
        
        project: this.projectId,
        connection_type: this.form.value.connection_type,
        quantity: this.form.value.quantity,
        model:this.form.value.model,
        package_availed:this.form.value.package_availed,
        price_limit:this.form.value.price_limit,
        supplier_name: null,
        supplier_ph_no: null,
        supplier_address: null,
        service: null,
        initial_installation_charges: null,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
        
      }
    }else{
      updateData = {
        
        project: this.projectId,
        connection_type: this.form.value.connection_type,
        quantity: null,
        model:null,
        package_availed:this.form.value.package_availed,
        price_limit:null,
        supplier_name: this.form.value.supplier_name,
        supplier_ph_no: this.form.value.supplier_ph_no,
        supplier_address: this.form.value.supplier_address,
        service: this.form.value.service,
        initial_installation_charges: this.form.value.initial_installation_charges,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }
    }
//console.log(updateData);
    this.perExecutionService.editInternetConnectionOffice(this.internetDetails.id,updateData).subscribe(
      res =>{
       // console.log(res)
       this.loading = LoadingState.Ready;
 
       this.toastr.success('Data has been saved','',{
        timeOut: 3000,
      });
       },
      error =>{
       this.loading = LoadingState.Ready;
        //console.log(error)
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )

  }
  else
  {
    this.markFormGroupTouched(this.form);
  }
}




createDocument(){
  return this.formBuilder.group({
    document_name: ['', Validators.required],
    document: [null, Validators.required],
    internet_document: null
  })
}

getFormArrayControl(form) {
  return form.get('documents').controls
}

addDocument()
{
  const control = <FormArray>this.form.controls['documents'];
  control.push(this.createDocument());
}

deleteDocument(index:number)
{
  const control = <FormArray>this.form.controls['documents'];
  control.removeAt(index);
}


onSelectFile(event, index){
  console.log(event.target.files);
  if (event.target.files && event.target.files[0]) {
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();
      reader.onload = (event) => {
        // console.log(event.target['result']);
      }
      const control = <FormArray>this.form.controls['documents'];
      control.at(index).patchValue({
        document: event.target.files[0]['name'],
        internet_document: event.target.files[0]
      })
      reader.readAsDataURL(event.target.files[i]);
    }
  }
}

markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    if (control.controls) {
      control.controls.forEach(c => this.markFormGroupTouched(c));
    }
  });
}

isFieldValid(form: FormGroup, field: string) {
  return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
}

displayFieldCss(form: FormGroup, field: string) {
  return {
    'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
    'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
  };
}


}
