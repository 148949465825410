import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-travel-and-tour-remarks-modal',
  templateUrl: './travel-and-tour-remarks-modal.component.html',
  styleUrls: ['./travel-and-tour-remarks-modal.component.scss']
})
export class TravelAndTourRemarksModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TravelAndTourRemarksModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data)
  }
  getLetter(author){
    var x = author.split(' ');
    if(x.length > 1){
      return x[0].charAt(0) + x[1].charAt(0)
    }
    else{
      return x[0].charAt(0)
    }
    
  }

  close() {
    this.dialogRef.close();
  }
}
