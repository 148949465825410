import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExecutionPurchaseService {
  constructor(private http: HttpClient) {

  }


  getRequisitionList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_requisition_total_list/?' + params)
  }

  getActivities(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_requisitions_activities_master_add/')
  }
  getUnit(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'unit_add/')
  }

  getProjectId(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'projects_details_by_project_site_id/' + params)
  }

  saveRequisitionForm(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchase_requisition_add/', data)
  }

  getPaymentTermsMaster(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_payment_terms_master_add/')
  }

  itemsListings(requisition_id, params = ''): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_requisition_list/' + requisition_id + '/' + params)
  }

  updateRequisitionForm(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_requisition_edit/' + id + '/', data)
  }

  requisitionTypes(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_master_add/')
  }
  getMaterialReceivedList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_total_delivery_material_recieved_list/?' + params)
  }
  getTypeDetail(params): Observable<any> {

    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_to_item_code/?' + params)
  }
  getMaterialList(params): Observable<any> {

    return this.http.get(environment.apiEndpoint + 'materials_list/?' + params)
  }
  getMachineryList(params): Observable<any> {

    return this.http.get(environment.apiEndpoint + 'machineries_list/?' + params)
  }
  getTypeStockItems(data, projectId: number, siteLocation: number): Observable<any> {

    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_to_item_code_for_stock/?type_code=' + data + '&project=' + projectId + '&site_location=' + siteLocation)
  }
  requisitionSubmitForApproval(requisitionId: number, data): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_requisition_submit_for_approval/' + requisitionId + '/', data)
  }

  updateSubmitForApproval(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_requisition_submit_for_approval/' + id + '/', data)
  }

  getrequisitionSubmitForApproval(requisitionId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_requisition_submit_for_approval/' + requisitionId + '/')
  }


  /** Quotaion tab **/

  addPurchaseQuotation(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchase_quotations_add/', data)
  }

  getPurchaseQuotation(requisitionId, itemId, uomId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_item_list/' + requisitionId + '/' + itemId + '/' + uomId + '/')
  }

  getPreviousPurchaseQuotation(itemId, uomId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_prev_purchases/' + itemId + '/' + uomId + '/')
  }
  getPurchaseItemQuotation(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_approved_item_list/' + id + '/')
  }

  getAllQuotationItems(requisitionId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_total_list/' + requisitionId + '/')
  }

  updatePurchaseQuotation(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_quotations_edit/' + id + '/', data)
  }

  deleteSavedQuotations(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_quotations_delete/' + id + '/', {})

  }

  /* approvals*/
  approvedQuotationListings(requisition_id, project_id, site_id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_requisition_approval_list/' + requisition_id + '/' + project_id + '/' + site_id + '/')
  }
  submitApprovals(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchase_requisition_approval/?section_name=requisition', data)
  }

  /* Purchase Order*/
  transportCostList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_order_transportcost_master_add/')
  }

  getPOVendorsList(requisitionId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_purchases_order_list/' + requisitionId + '/')
  }

  addPurchaseOrder(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchases_purchases_order_add/', data)
  }

  addPurchaseOrderDocument(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchases_order_document_add/', data)
  }

  getPurchaseOrderList(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_purchases_order_total_list/' + id + '/')
  }


  /* Comparative Statement */
  addComparativeItem(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_comparitive_statement_add/', data)
  }

  updateComparativeItem(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_purchases_comparitive_statement_edit/' + id + '/', data)
  }

  approveVendorPerItem(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_purchases_comparitive_statement_approval/' + id + '/?section_name=comparative statement', data)
  }

  addcomparativeDocuments(data: any) {
    return this.http.post(environment.apiEndpoint + '/execution_purchases_comparitive_statement_document_add/', data)
  }

  getComparativeItemData(requisitionId: number, itemId: number, uomId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_comparitive_statement_add/?requisitions_master_id=' + requisitionId + '&item_id=' + itemId + '&uom=' + uomId + '&section_name=comparative statement')
  }

  comparativeShareApproval(requisitionId, itemID): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_comparitive_statement_ord_list/' + requisitionId + '/' + itemID + '/')
  }


  /* DISPATCH */
  getDispatchList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_dispatch_list/?requisitions_master=' + id)
  }
  deleteDispatch(dispatchId: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_purchases_dispatch_delete/' + dispatchId + '/', {})

  }

  addDispatch(data: any) {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_dispatch_add/', data)
  }

  addDispatchDocument(data: any) {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_dispatch_document_add/', data)
  }

  /* Delivery  */

  addDeliveryItem(data: any) {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_delivery_add/', data)
  }
  addDeliveryItemDocument(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_delivery_document_add/', data)
  }
  getDeliveryItemList(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_delivery_list/?requisitions_master=' + id)
  }

  updateDeliveryItemList(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_purchases_delivery_edit/' + id + '/', data)
  }

  /* PAYMENT*/
  getTotalAmountPayable(requisitionId) {
    return this.http.get(environment.apiEndpoint + 'excution_purchases_total_amount_payable_list/?requisitions_master=' + requisitionId)
  }

  addPayment(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_payments_made_add/', data)
  }
  addPaymentPlan(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_payment_plan_add/', data)
  }

  getPaymentPlanList(id) {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_payment_plan_add/?requisitions_master=' + id)
  }

  addPaymentDocument(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_payments_made_document_add/', data)
  }

  getPaymentList(id) {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_payments_made_list/?requisitions_master=' + id)
  }

  getStock(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_to_item_code_current_stock/?' + params)
  }


  getPendingApprovalList(params): Observable<any> {
    // return this.http.get(environment.apiEndpoint + 'purchase_requisition_approval_total_list/?'+params)
    return this.http.get(environment.apiEndpoint + 'purchase_requisition_total_approval_list/?' + params)
  }

  getPendingQuotationApprovalList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_for_approved_list/?' + params)
  }

  getPermissionLevel(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'approval_permission_list/?' + params)
  }

  quotationItemApproval(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_quotation_approved/' + id + '/', {})
  }

  quotationItemRevertApproval(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_quotation_revert_approval/' + id + '/', {})
  }

  // 30-06-2020
  exportRequisitionList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_requisition_total_list/download/?' + params)
  }

  // 20-08-2020
  approveQuotationItem(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_comparitive_statement_approval_batch/?section_name=comparative statement', data)
  }

  // Date: 13-08-2020
  // Task: Multiple Approval/Reject
  btachPurchaseRequisitionApproval(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchase_requisition_approval_batch/?section_name=requisition', data)
  }

  // Date: 28-08-2020
  // Task: Download Comparative Statement in Excel
  downloadComparativeDataInExcel(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_comparitive_statement_download/' + id + '/')
  }
}
