import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { StockService } from '../../../../services/stock.service';
import { LoadingState } from '../../../../components/loading/loading.component';
import * as Globals from '../../../../globals';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AppPageStateConfig } from '../../../../../app.config';


@Component({
  selector: 'app-material-stock-statement',
  templateUrl: './material-stock-statement.component.html',
  styleUrls: ['./material-stock-statement.component.scss']
})
export class MaterialStockStatementComponent implements OnInit {

  @Input() siteLocation: number;
  @Input() RefprojectId: number;
  @Output() siteIdEmit: EventEmitter<number> = new EventEmitter<number>();
  @Output() projectIdEmit: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('target', {static: true}) tableContent: ElementRef;
  hasScroll: boolean = false
  scrollAmount;
  public loading: LoadingState = LoadingState.NotReady;
  siteId: number;
  projectId: number;
  itemList: any = [];
  monthList: any = [];
  defaultPagination: number;
  totalStockList: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  pageSize: number;
  search_key: any;
  monthsList: any = [];
  selectedMonths: any;
  stockReportConfig: any;
  mnth:any = [];

  constructor(
    public projectSiteServices: projectSiteServices,
    public stockService: StockService,
    private toastr: ToastrService,
    private element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.stockReportConfig = AppPageStateConfig.stockReportConfig.materialStockConfig;
   }

  ngOnInit() {
    console.log(this.stockReportConfig)
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    this.loading = LoadingState.Processing;
    if (this.route.snapshot.params['projectId']== undefined) {
      if (this.stockReportConfig.search_key != undefined) {
        this.search_key = this.stockReportConfig.search_key;
      }
      if (this.stockReportConfig.defaultPagination != null) {
        this.defaultPagination = this.stockReportConfig.defaultPagination;
      }
      if (this.stockReportConfig.itemPerPage != null) {
        this.itemPerPage = this.stockReportConfig.itemPerPage;
      }
      if (this.stockReportConfig.pageSize != null) {
        this.pageSize = this.stockReportConfig.pageSize;
      }
      if (this.stockReportConfig.selectedMonths != undefined) {
        this.selectedMonths = this.stockReportConfig.selectedMonths;
        
      }
      if (this.stockReportConfig.mnth.length > 0) {
        this.mnth = this.stockReportConfig.mnth;
      }
    }
    if ((this.siteLocation) && (this.RefprojectId)) {
      this.siteId = this.siteLocation;
      this.projectId = this.RefprojectId;
      this.getMonths(this.projectId);
    } else
      this.loading = LoadingState.Ready;
  }

  checkScroll() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    // console.log(this.scrollAmount.scrollWidth, this.scrollAmount.offsetWidth, this.scrollAmount.clientWidth)
    if (this.scrollAmount && (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth || this.scrollAmount.scrollWidth == this.scrollAmount.offsetWidth)) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }
  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  getMonths(projectId) {
    this.stockService.getMonthListofProject(this.projectId).subscribe(
      res => {
        var data = res['result'];
        data.forEach(element => {
          this.monthsList.push({ name: element, value: element })
        });
      },
      error => {
        console.log(error)
      }
    );
    this.materialStockStatement();
  }

  selectDate(e) {
    this.selectedMonths = null;
    e.forEach(element => {
      if (this.selectedMonths != undefined)
        this.selectedMonths += ',' + element;
      else
        this.selectedMonths = element;
    });
    if (this.route.snapshot.params['projectId']== undefined) {
        this.stockReportConfig.selectedMonths = this.selectedMonths;
        this.stockReportConfig.mnth = this.mnth;
    }
    this.materialStockStatement()
  }

  materialStockStatement() {
    this.loading = LoadingState.Processing;
    this.itemList = [];
    this.projectIdEmit.emit(this.projectId);
    if (this.projectId && this.siteId) {
      let params: URLSearchParams = new URLSearchParams();
      params.set('page', this.defaultPagination.toString());
      if (this.itemPerPage)
        params.set('page_size', this.itemPerPage.toString());
      if (this.search_key != undefined)
        params.set('product_name', this.search_key)
      if (this.selectedMonths != undefined)
        params.set('month_year', this.selectedMonths.toString());

      this.stockService.getMaterialStockReportData(this.projectId, this.siteId, params).subscribe(
        res => {
          var data = res['results'];
          this.totalStockList = res['count'];
          this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
          this.lower_count = this.itemNo + 1;
          if (this.totalStockList > this.itemPerPage * this.defaultPagination) {
            this.upper_count = this.itemPerPage * this.defaultPagination
          }
          this.itemList = [];
          this.monthList = [];
          this.itemList = data;
          if (this.itemList.length > 0) {
            this.monthList = this.itemList[0]['year_stock_details'];
          }
          this.checkScroll();
          this.loading = LoadingState.Ready;
        },
        error => {
          if (error.status == 404 && this.stockReportConfig.defaultPagination > 1) {
            this.stockReportConfig.defaultPagination = 1;
            this.ngOnInit();
          }
          else{
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
            this.loading = LoadingState.Ready
          }
        }
      );
    } else {
      this.itemList = []
      this.loading = LoadingState.Ready;
    }

  }

  pagination() {
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.defaultPagination = this.defaultPagination;
    }
    this.materialStockStatement();
  }

  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.defaultPagination = this.defaultPagination;
      this.stockReportConfig.itemPerPage = +item;
    }
    this.materialStockStatement();
  }

  getSearchValueFromParent(siteId, projectId) {
    this.loading = LoadingState.Processing;
    this.siteId = siteId;
    this.projectId = projectId;
    this.getMonths(this.projectId);
  }

  exportReport() {
    // hit api for excel
    if (this.itemList.length > 0) {
      this.loading = LoadingState.Processing;
      let params: URLSearchParams = new URLSearchParams();
      if (this.search_key != undefined)
        params.set('product_name', this.search_key)
      if (this.selectedMonths != undefined)
        params.set('month_year', this.selectedMonths.toString());

      this.stockService.getMaterialStockExcel(this.projectId, this.siteId, params).subscribe(
        res => {
          // console.log(res)
          window.open(res.url)
          this.loading = LoadingState.Ready;
        },
        error => {
          // console.log(error)
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    } else {
      this.toastr.error('No data available to export', '', {
        timeOut: 3000,
      });
    }
  }
}
