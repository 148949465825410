import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-view-modal',
  templateUrl: './document-view-modal.component.html',
  styleUrls: ['./document-view-modal.component.scss']
})
export class DocumentViewModalComponent implements OnInit {
  documentList: any = [];
  constructor(
    public dialogRef: MatDialogRef<DocumentViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.documentList = this.data['items'];
    //console.log(this.data)
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }
  viewDoc(documentData){
    
    window.open(documentData.url,documentData.name,'height=700,width=800,scrollbars=yes');
  }

  close() {
    this.dialogRef.close(true);
  }

}
