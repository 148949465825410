import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import {PerExecutionService} from '../../../services/per-execution.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-site-puja-tab',
  templateUrl: './site-puja-tab.component.html',
  styleUrls: ['./site-puja-tab.component.scss']
})
export class SitePujaTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() tabId:number; 
  approveStatus:any = [];
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  sitePujaDetail:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
		public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      budget:[null,Validators.required],
      location:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      date:[null,Validators.required]

    })
    if(this.projectId){
      this.getSitePujaDetails();
      this.getStatus();
    }
  }

  save(){
    if(this.form.valid){
      this.loading = LoadingState.NotReady;
      var data = {
        project:this.projectId,
        location:this.form.value.location,
        latitude:this.form.value.latitude,
        longitude:this.form.value.longitude,
        date: new Date(this.form.value.date.year, this.form.value.date.month-1, this.form.value.date.day),
        budget:this.form.value.budget
      }
      this.perExecutionService.addSitePuja(data).subscribe(
        res=>{
         // console.log(res);
          //this.getGuestHouseFind();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
          
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )

    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  
  getSitePujaDetails(){
    this.loading = LoadingState.NotReady;
     this.perExecutionService.getSitePuja(this.projectId).subscribe(
      res =>{
        if(res['result'][0]){
          this.sitePujaDetail = res['result'][0];
          var pujaDate = new Date(this.sitePujaDetail['date'])
            this.sitePujaDetail.date = {
              year: pujaDate.getFullYear(),
              month: pujaDate.getMonth()+1,
              day: pujaDate.getDate()
        
            }
            this.sitePujaDetail.budget = parseFloat(this.sitePujaDetail.budget).toFixed(2);
            this.form.patchValue({
              latitude:this.sitePujaDetail.latitude,
              longitude:this.sitePujaDetail.longitude
           })
        }
        this.loading = LoadingState.Ready;
      },
      error =>{
       // console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
     )
   }

   getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
       // console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
      })
  
    }

  update(){
   
    if(this.form.valid)
    {  
      this.loading = LoadingState.Processing;
      var data = {
        project:this.projectId,
        location:this.form.value.location,
        latitude:this.form.value.latitude,
        longitude:this.form.value.longitude,
        date: new Date(this.form.value.date.year, this.form.value.date.month-1, this.form.value.date.day),
        budget:this.form.value.budget
      }
      this.perExecutionService.editSitePuja(this.sitePujaDetail.id,data).subscribe(
        res=>{
         // console.log(res);
          //this.getGuestHouseFind();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )

    }else{
      this.markFormGroupTouched(this.form);
    }
   }
  

  handleAddressChange(address) {
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      location: formattedAddress
    })
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
