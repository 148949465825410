import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-initial-costing-tab',
  templateUrl: './initial-costing-tab.component.html',
  styleUrls: ['./initial-costing-tab.component.scss']
})
export class InitialCostingTabComponent implements OnInit {
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  fieldLabelValueList: any = [];
  tenderInitialCostingDetails: any;
  constructor(
    private tenderService: TenderService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      client: ['', Validators.required],
      tender_notice_no_bid_id_no: ['', Validators.required],
      name_of_work: ['', Validators.required],
      is_approved: [null, Validators.required],
      received_estimate: ['', Validators.required],
      quoted_rate: ['', Validators.required],
      difference_in_budget: ['', Validators.required],
    });
    this.getTenderInitialCosting()
  }

  getTenderInitialCosting() {
    this.tenderService.getTenderInitialCosting(this.tenderId).subscribe(
      res => {
       // console.log(res)
        if (res['result'] != "" && res['result'] != null) {
          this.tenderInitialCostingDetails = res['result'];
          if (this.tenderInitialCostingDetails.field_label_value.length > 0) {
            this.fieldLabelValueList = this.tenderInitialCostingDetails.field_label_value
          }
          this.form.patchValue({
            client: this.tenderInitialCostingDetails.client,
            tender_notice_no_bid_id_no: this.tenderInitialCostingDetails.tender_notice_no_bid_id_no,
            name_of_work: this.tenderInitialCostingDetails.name_of_work,
            is_approved: this.tenderInitialCostingDetails.is_approved,
            received_estimate: this.tenderInitialCostingDetails.received_estimate,
            quoted_rate: this.tenderInitialCostingDetails.quoted_rate,
            difference_in_budget: this.tenderInitialCostingDetails.difference_in_budget,
          })
        }

        this.loading = LoadingState.Ready
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }

        const formData: any = new FormData();
        formData.append("tender", this.tenderId);
        formData.append("document", event.target.files[0], event.target.files[0]['name']);
        this.loading = LoadingState.Processing
        this.tenderService.uploadTenderInitialCosting(formData).subscribe(
          res => {
           // console.log(res)
            this.fieldLabelValueList = res['field_label_value']
            this.loading = LoadingState.Ready
          },
          error => {
            console.log(error)
            this.loading = LoadingState.Ready
          }
        )

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  calculateDiff(e) {
    var estimate = +this.form.value.received_estimate
    var quoted = +this.form.value.quoted_rate
    var diff = +(estimate - quoted)
    this.form.patchValue({
      difference_in_budget: diff
    })
  }

  save() {
    if (this.form.valid) {
      // console.log(this.form.value)
      var data = this.form.value;
      data['field_label_value'] = this.fieldLabelValueList
      //console.log(data)
      this.loading = LoadingState.Processing
      this.tenderService.addTenderInitialCosting(data).subscribe(
        res => {
         // console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
         // console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
