import { Component, OnInit,Input } from '@angular/core';
import { LoadingState } from '../loading/loading.component';
import {LiveTrackingService } from '../../services/live-tracking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-live-tracking',
  templateUrl: './project-live-tracking.component.html',
  styleUrls: ['./project-live-tracking.component.scss']
})
export class ProjectLiveTrackingComponent implements OnInit {
  @Input() projectId: number;
  loading: LoadingState = LoadingState.NotReady;
  zoom: number = 13;
  lat:number;
  lng:number;
  siteLat:number;
  siteLng:number;
  officeLat:number;
  officeLng:number;
  radius:number;
  markers: marker[] =[];
  gestHouseLat:number;
  gestHouseLng:number;
  employeeLiveTarcking:any;
  long_lat_details:any = [];


  constructor(
    public liveTrackingService:LiveTrackingService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

   // console.log(this.projectId)
   this.getProjectSiteLiveTracking();
  }

  getProjectSiteLiveTracking(){
    this.loading = LoadingState.Processing;
    this.liveTrackingService.getProjectSiteLiveTrackingOfEmployee(this.projectId).subscribe(
      res=>{
        this.employeeLiveTarcking = res['result'];
        // console.log(this.employeeLiveTarcking)
        this.siteLng = parseFloat(this.employeeLiveTarcking.site_location__site_longitude);
        this.siteLat = parseFloat(this.employeeLiveTarcking.site_location__site_latitude);

        this.officeLng = parseFloat(this.employeeLiveTarcking.site_location__office_longitude);
        this.officeLat = parseFloat(this.employeeLiveTarcking.site_location__office_latitude);

        this.gestHouseLat=parseFloat(this.employeeLiveTarcking.site_location__gest_house_latitude);
        this.gestHouseLng=parseFloat(this.employeeLiveTarcking.site_location__gest_house_longitude);

        this.long_lat_details = this.employeeLiveTarcking.lat_lng;
       
        this.radius = this.employeeLiveTarcking.site_location__geo_fencing_area!=null?parseInt(this.employeeLiveTarcking.site_location__geo_fencing_area):1000;
      
        console.log(this.employeeLiveTarcking.employee_details);
        this.employeeLiveTarcking.employee_details.forEach(x => {
          if(x.current_position_details !=null){
            this.markers.push({
              lat: parseFloat(x.current_position_details.latitude),
              lng: parseFloat(x.current_position_details.longitude),
              label:'',
              lb:{fname:x.user_details.cu_user__first_name,lname:x.user_details.cu_user__last_name,designation:x.user_details.designation__cod_name,loginTime:x.current_position_details.attandance__login_time,distance:this.getHighestDistance(x)},
              draggable: false
            });
          }
          
        });

        console.log(this.markers)
        this.loading = LoadingState.Ready;
      },
      error => {

        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  clickedMarker(label: string, index: number) {
   // console.log(`clicked the marker: ${label || index}`)
   }

   getHighestDistance(data:any){ 
    
      let distance= null;
      let response = this.distance(this.siteLat,this.siteLng,data.current_position_details.latitude,data.current_position_details.longitude).toFixed(1);
      distance = parseFloat(response);
      if(distance){
        return distance +'KM';
      }else{
        return null;
      }

    }
    
  


   distance(lat1, lon1, lat2, lon2) { 
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
            c(lat1 * p) * c(lat2 * p) * 
            (1 - c((lon2 - lon1) * p))/2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  lb?:{};
  draggable: boolean;
}
