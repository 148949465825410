import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PlantMachineryService {
  constructor(
    private http: HttpClient
  ) {

  }

  getMachineryListForReport(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_list_for_report/?' + params)
  }

  addMachineryReportData(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'machineries_report_add/', data)
  }

  getMachineryReportDataList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_report_add/?' + params)
  }
  getMachineryReportDataListV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/machineries_daily_report/?' + params)
  }
  getMachineryDateWiseReportDataListV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/machineries_monthly_report/?' + params)
  }

  getMachineryDateWiseReportDataCountV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/machineries_monthly_report/details_calculation/?' + params)
  }
  getMachineryReportDataListExcelDownloadV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/machineries_daily_report/download/?' + params)
  }
  getMachineryReportDataRangeWiseListExcelDownloadV2(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/machineries_monthly_report/download/?' + params)
  }
  getMachineryReportDataListByProject(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_report_add/?' + params)
  }

  getMachineryReportDataDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_report_edit/' + id + '/')
  }

  updateMachineryReportData(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machineries_report_edit/' + id + '/', data)
  }

  getDieselConsumptionListByProject(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_dieselconsumption_report/?' + params)
  }

  getDieselConsumptionListByProjectDownload(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_dieselconsumption_report/download/?' + params)
  }

  addMachineryDailyReportData(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'v2/machineries_report_add/', data)
  }

  getMachineryDailyReportData(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/machineries_report_add/?' + params)
  }

  updateMachineryDailyReportData(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'v2/machineries_report_edit/' + id + '/', data)
  }

}
