import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttendanceService } from '../../../services/attendance.service'
import { LocalService } from '../../../services/local.service'
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../components/loading/loading.component';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import {LeaveStatusModalComponent} from '../../modal/leave-status-modal/leave-status-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-daily-attendance',
  templateUrl: './daily-attendance.component.html',
  styleUrls: ['./daily-attendance.component.scss']
})
export class DailyAttendanceComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  attendanceList: any = [];
  justificationModel: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  is_previous: boolean = false;
  @Output() getGraceDetailsFromChild = new EventEmitter<boolean>();
  @Input() leaveDetail;
  checkedLeaveType: string;
  selectedDocument: any;
  is_prev: boolean = true;
  form: FormGroup;

  constructor(
    private attendanceService: AttendanceService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public localService: LocalService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    var date = new Date();
    if((date.getMonth() + 1 == 4) && (date.getDate() <= 25)){
      this.is_prev = false
    }
    this.dailyAttendanceList();
  }

  dailyAttendanceList() {
    this.loading = LoadingState.Processing;
    var dateObj = new Date()
    // dateObj.setDate(dateObj.getDate() - 1); 
    var current_date = dateObj.toISOString().slice(0, 10);
    const emp_id = parseInt(this.localService.getJsonValue('userid'));
    let params: URLSearchParams = new URLSearchParams();
    params.set('current_date', current_date.toString());
    params.set('is_previous', this.is_previous.toString())
    this.attendanceService.getFlexiAttendancePerEmployee(emp_id, params).subscribe(
      res => {
        //console.log(res)
        this.attendanceList = res['results'];
        this.attendanceList.forEach((y, index) => {
          this.justificationModel[index] = [];
         
          if(typeof y.login_logout_details == 'object'){
            
            y.login_logout_details.forEach((x, indexK) => {
              this.justificationModel[index][indexK] = [];
              x.deviation_details.forEach((z, indexZ) => {
                if(!z.lock_status){
                  this.justificationModel[index][indexK].push(
                    {
                      request_type: z.deviation_type,
                      is_requested: z.is_requested,
                      enableRow: z.is_requested,
                      lock_status: z.lock_status,
                      checkValidation: false,
                      justification: z.justification,
                      document: z.document,
                    }
                  );
                }
                else{
                  this.justificationModel[index][indexK].push(
                    {
                      request_type: z.deviation_type,
                      is_requested: z.is_requested,
                      enableRow: true,
                      lock_status: z.lock_status,
                      checkValidation: false,
                      justification: z.justification,
                      document: z.document,
                    }
                  );
                }
              })
            })
          }
        })
        // console.log(this.justificationModel)
        this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )


  }

  getLeaveType(e,i,j,z){
    let type = e.target.value;
    
    if(type=='HD' || type == 'FD'){
      this.viewLeaveFrom(i,j,z)
    }
    else{
      this.checkedLeaveType = undefined;
    }
    
  }

  viewLeaveFrom(i,j,z){    
    let dialogRef = this.dialog.open(LeaveStatusModalComponent, {
      width: '325px',
      data: {leaveDetail:this.leaveDetail}
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result != undefined && result != null){
        this.checkedLeaveType = result;
      }
      else{
        this.justificationModel[i][j][z].request_type = null
      }
     
    })
  }

  submit(parentIndex: number, logIndex: number, childIndex: number, id: number,date) {
    this.justificationModel[parentIndex][logIndex][childIndex].checkValidation = true;
    const formData = new FormData();
    formData.append('justification', this.justificationModel[parentIndex][logIndex][childIndex].justification)
    formData.append('deviation_type', this.justificationModel[parentIndex][logIndex][childIndex].request_type)
    if(this.justificationModel[parentIndex][logIndex][childIndex].document != null) {
      formData.append('document', this.justificationModel[parentIndex][logIndex][childIndex].document)
    }
    if(this.checkedLeaveType != undefined){
      formData.append('leave_type', this.checkedLeaveType)
    }

    if (this.justificationModel[parentIndex][logIndex][childIndex].checkValidation == true && this.justificationModel[parentIndex][logIndex][childIndex].justification && this.justificationModel[parentIndex][logIndex][childIndex].request_type) {

      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage = "Are you sure for this action ?"

      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loading = LoadingState.Processing;
          const emp_id = parseInt(this.localService.getJsonValue('userid'));
          let params: URLSearchParams = new URLSearchParams();
          params.set('date', date.toString());
          params.set('employee_id', emp_id.toString())
          this.attendanceService.attendanApprovalRequestV2(id, formData,params).subscribe(
            res => {
              this.loading = LoadingState.Ready;
              this.toastr.success('Your request has been added.', '', {
                timeOut: 3000,
              });
              this.justificationModel[parentIndex][logIndex][childIndex].is_requested = true;
              this.justificationModel[parentIndex][logIndex][childIndex].enableRow = true;
              this.justificationModel[parentIndex][logIndex][childIndex].document = res.document; 
              this.getGraceDetailsFromChild.next(this.is_previous);
            },
            error => {
              this.loading = LoadingState.Ready;
              if (error.error.error.msg) {
                this.toastr.error(error.error.error.msg, '', {
                  timeOut: 3000,
                });
              }
              else {
                this.toastr.error('Something went wrong', '', {
                  timeOut: 3000,
                });
              }
            }
          )
        }
        this.dialogRef = null;
      })

    }

  }

  viewAttendance() {
    this.is_previous = !this.is_previous;
    this.dailyAttendanceList();
    this.getGraceDetailsFromChild.next(this.is_previous);
  }

  selectFile(event, parentIndex, logIndex, childIndex) {
     if (event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg"
     || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/doc" || event.target.files[0].type == "application/docx"
     || event.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
     || event.target.files[0].type == "application/msword") {
      
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {}
        this.justificationModel[parentIndex][logIndex][childIndex].document = event.target.files[0];
        reader.readAsDataURL(event.target.files[i]);
      }

     } else {
       this.toastr.error('Image/PDF/Doc/Excel file type are allowed', '', {
         timeOut: 3000,
       });
     }
    }

  viewDoc(url) {
    if(url.includes('.xls') || url.includes('.xlsx') || url.includes('.doc') || url.includes('.docx')) {
      window.open(url, '_blank');
    } else {
      window.open(url,'','height=700,width=800,scrollbars=yes');
    }
  }

}
