import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {TravelAndTourService} from '../../../../core/services/travel-and-tour.service';
import { LoadingState } from '../../../../core/components/loading/loading.component';

@Component({
  selector: 'app-travel-and-tour-expense-modal',
  templateUrl: './travel-and-tour-expense-modal.component.html',
  styleUrls: ['./travel-and-tour-expense-modal.component.scss']
})
export class TravelAndTourExpenseModalComponent implements OnInit {
  expenseData:any = [];
  loading: LoadingState = LoadingState.NotReady;

  constructor(
    public dialogRef: MatDialogRef<TravelAndTourExpenseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private travelAndTourService:TravelAndTourService,
  ) { }

  ngOnInit() {
    if(this.data.id != undefined){
      this.getExp();
    }
  }
  close() {
    this.dialogRef.close();
  }

  getExp(){
    this.loading = LoadingState.Processing;
    this.travelAndTourService.getTourAndTravelExp(this.data.id).subscribe(
      res => {
        this.expenseData = res['result'][0];
        this.loading = LoadingState.Ready;
      },
      error =>{
        console.log(error)
        this.loading = LoadingState.Ready;
      }
    )
  }

  viewDocument(item: any) {
    window.open(item, '', 'height=700,width=800,scrollbars=yes');
  }
}
