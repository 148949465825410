import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-eligibility-financial-inner-tab',
  templateUrl: './eligibility-financial-inner-tab.component.html',
  styleUrls: ['./eligibility-financial-inner-tab.component.scss']
})
export class EligibilityFinancialInnerTabComponent implements OnInit {
  @Input() tenderId: number;
  @Input() eligibilityList: any;
  eligibilityType: string;
  @Output() valueChange = new EventEmitter();
  constructor() {
    this.eligibilityType = "financial";
  }

  ngOnInit() {
    //console.log(this.tenderId)
  }

  getInnerDataTabValue(data: any) {
   // console.log(data)
    this.eligibilityList = data;
    this.valueChange.emit(this.eligibilityList)
  }

}
