import { Component, OnInit,ElementRef, ViewChild,Input } from '@angular/core';
import { LoadingState } from '../../../core/components/loading/loading.component';
import { PlantMachineryService } from '../../../core/services/plant-machinery.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as Globals from '../../../core/globals';


@Component({
  selector: 'app-diesel-consumption',
  templateUrl: './diesel-consumption.component.html',
  styleUrls: ['./diesel-consumption.component.scss']
})
export class DieselConsumptionComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  @Input() projectId: number;
  scrollAmount:any;
  hasScroll:boolean;
  headerThOption = [];

  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList:any =[];
  pageSize:number;
  sort_by = '';
  sort_type= '';
  search_key='';
  projectList: any = [];
  constructor(
    private plantMachineryService: PlantMachineryService,
    public dialog: MatDialog,
    private element: ElementRef,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.headerThOption = [
      {
        id: 1,
        name: "SL No.",
        code: "",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 2,
        name: "Equipment Description",
        code: "equipment_description",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 3,
        name: "Equipment Make",
        code: "equipment_make",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 4,
        name: "Equipment Model",
        code: "equipment_model",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 5,
        name: "Planned availability Period (A)",
        code: "planned_availability_period",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 6,
        name: "Start KMS reading (B)",
        code: "start_KMS_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 7,
        name: "Closing KMS reading (C)",
        code: "closing_KMS_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 8,
        name: "Total working KMS (D)",
        code: "",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 9,
        name: "Strat HRS reading (E)",
        code: "strat_HRS_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 10,
        name: "Closing HRS reading (F)",
        code: "closing_HRS_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 11,
        name: "Total working HRS (G)",
        code: "total_working_HRS",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 12,
        name: "B/D period (H)",
        code: "bd_period",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 13,
        name: "Idel Period (I)",
        code: "idel_period",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 15,
        name: "Utilization % (J)",
        code: "utilization",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 16,
        name: "HSD Consumed Period (K)",
        code: "hsd_consumed_period",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 17,
        name: "UOM",
        code: "uom",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 18,
        name: "Act. Avg. (L)",
        code: "act_avg",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 19,
        name: "Std. Avg (M)",
        code: "",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      
    ];
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
  }

}
