import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveTrackingService {
  constructor(
    private http: HttpClient
  ) {

  }


  getProjectSiteLiveTrackingOfEmployee(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms_live_tracking_for_all_employees/' + id + '/')
  }

  getManpowerLiveTracking(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms_live_tracking_for_a_employee_in_project/' + id + '/')
  }

}
