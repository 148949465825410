import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../loading/loading.component';
import { AttendanceService } from '../../../../services/attendance.service';
import { LocalService } from '../../../../services/local.service';
import * as Globals from '../../../../globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-attendance',
  templateUrl: './new-attendance.component.html',
  styleUrls: ['./new-attendance.component.scss']
})
export class NewAttendanceComponent implements OnInit {

  @ViewChild('target', {static: true}) tableContent: ElementRef;
  @Output() getMonthYear = new EventEmitter();
  loading: LoadingState = LoadingState.NotReady;

  monthYear: any;
  attendanceList: any = [];
  monthParam: string = '';
  yearParam: string = '';
  conveyanceList: any = [];
  yearInt: number;
  thisMonthNumber;
  indexOfThisMonth: number;
  thisDate: number
  nextMonthDataAfter: any;
  scrollAmount;
  hasScroll: boolean = false

  constructor(
    private toastr: ToastrService,
    private attendanceService: AttendanceService,
    public dialog: MatDialog,
    private element: ElementRef,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    var date = new Date();
    this.thisDate = date.getDate();
    this.thisMonthNumber = date.getMonth() + 1;    
    this.yearInt = new Date().getFullYear();

    if (this.thisMonthNumber == 1 || this.thisMonthNumber == 2 || this.thisMonthNumber == 3) {
      this.monthYear = [
        { index: 1, no: 4, year: (this.yearInt - 1), value: "April " + (this.yearInt - 1) },
        { index: 2, no: 5, year: (this.yearInt - 1), value: "April " + (this.yearInt - 1) + " - May " + (this.yearInt - 1) },
        { index: 3, no: 6, year: (this.yearInt - 1), value: "May " + (this.yearInt - 1) + " - June " + (this.yearInt - 1) },
        { index: 4, no: 7, year: (this.yearInt - 1), value: "June " + (this.yearInt - 1) + " - July " + (this.yearInt - 1) },
        { index: 5, no: 8, year: (this.yearInt - 1), value: "July " + (this.yearInt - 1) + " - August " + (this.yearInt - 1) },
        { index: 6, no: 9, year: (this.yearInt - 1), value: "August " + (this.yearInt - 1) + " - September " + (this.yearInt - 1) },
        { index: 7, no: 10, year: (this.yearInt - 1), value: "September " + (this.yearInt - 1) + " - October " + (this.yearInt - 1) },
        { index: 8, no: 11, year: (this.yearInt - 1), value: "October " + (this.yearInt - 1) + " - November " + (this.yearInt - 1) },
        { index: 9, no: 12, year: (this.yearInt - 1), value: "November " + (this.yearInt - 1) + " - December " + (this.yearInt - 1) },
        { index: 10, no: 1, year: this.yearInt, value: "December " + (this.yearInt - 1) + " - January " + (this.yearInt) },
        { index: 11, no: 2, year: this.yearInt, value: "January " + (this.yearInt) + " - February " + (this.yearInt) },
        { index: 12, no: 3, year: this.yearInt, value: "February " + (this.yearInt) + " - March " + (this.yearInt) }
      ];
    } else {
      this.monthYear = [
        { index: 1, no: 4, year: this.yearInt, value: "April " + this.yearInt },
        { index: 2, no: 5, year: this.yearInt, value: "April " + this.yearInt + " - May " + this.yearInt },
        { index: 3, no: 6, year: this.yearInt, value: "May " + this.yearInt + " - June " + this.yearInt },
        { index: 4, no: 7, year: this.yearInt, value: "June " + this.yearInt + " - July " + this.yearInt },
        { index: 5, no: 8, year: this.yearInt, value: "July " + this.yearInt + " - August " + this.yearInt },
        { index: 6, no: 9, year: this.yearInt, value: "August " + this.yearInt + " - September " + this.yearInt },
        { index: 7, no: 10, year: this.yearInt, value: "September " + this.yearInt + " - October " + this.yearInt },
        { index: 8, no: 11, year: this.yearInt, value: "October " + this.yearInt + " - November " + this.yearInt },
        { index: 9, no: 12, year: this.yearInt, value: "November " + this.yearInt + " - December " + this.yearInt },
        { index: 10, no: 1, year: this.yearInt + 1, value: "December " + this.yearInt + " - January " + (this.yearInt + 1) },
        { index: 11, no: 2, year: this.yearInt, value: "January " + (this.yearInt) + " - February " + (this.yearInt) },
        { index: 12, no: 3, year: this.yearInt, value: "February " + (this.yearInt) + " - March " + (this.yearInt) }
      ];
    }


    //get index if the current month to use in template
    this.indexOfThisMonth = this.monthYear.filter(x => x.no == this.thisMonthNumber)[0];

    if (this.thisDate > 25) {
      if (this.thisMonthNumber == 12) {
        this.nextMonthDataAfter = this.monthYear.filter(x => x.no == 1)[0];
      } else {
        this.nextMonthDataAfter = this.monthYear.filter(x => x.no == this.thisMonthNumber + 1)[0];
      }

      this.thisMonthNumber = this.thisMonthNumber + 1;
      //console.log(this.nextMonthDataAfter)
      if (this.thisMonthNumber == 13) { //now conver month to january if december 25 over
        this.thisMonthNumber = 1;
      }
    }

    this.selectDate(this.thisMonthNumber)
  }

  ngAfterViewInit() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    if (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }

  selectDate(event) {
    var row = this.monthYear.filter(x => x.no == event)[0];
    
    if (this.thisDate) {
      this.loading = LoadingState.Processing;
      if (this.thisMonthNumber == 1) {
        if (event == 1 && this.thisDate > 25 && this.thisDate <= 31) {
          this.yearParam = (row.year).toString();
        } else {
          this.yearParam = (row.year).toString();
        }
      } else {
        this.yearParam = row.year.toString();
      }

      // this.yearParam = row.year.toString();

      //console.log( this.yearParam)
      this.monthParam = event.toString();
      var data = {
        year: row.year,
        month: event
      }
      this.getMonthYear.emit(data)
      this.getAttendanceList();
    } else {
      this.attendanceList = [];
    }

  }

  getAttendanceList() {
    var emp_id = this.localService.getJsonValue('userid');
    var current_date = new Date().toISOString().slice(0, 10);


    let params: URLSearchParams = new URLSearchParams();

    
    if (this.monthParam != '' && this.yearParam != '') {
      params.set('month', this.monthParam.toString());
      params.set('year', this.yearParam.toString());
    } else {
      params.set('current_date', current_date.toString());
    }


    this.loading = LoadingState.Processing;
    this.attendanceService.getAttendanceSummary(emp_id,params).subscribe(
      (res: any) => {
        //console.log(res)
        this.attendanceList = res['results'];
        //console.log(this.attendanceList)
        this.loading = LoadingState.Ready;

      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  

  pagination() {
    this.loading = LoadingState.Processing;
    this.getAttendanceList();
  }

  

  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }


}
