import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { SettingService } from '../../../services/setting.service';

@Component({
  selector: 'app-add-p-and-m-modal',
  templateUrl: './add-p-and-m-modal.component.html',
  styleUrls: ['./add-p-and-m-modal.component.scss']
})
export class AddPAndMModalComponent implements OnInit {
  checkReadonly: boolean = false;
  loading: LoadingState = LoadingState.NotReady;
  tenderId: number;
  PMDetails: any = {
    tender: '',
    machinary_type: '',
    name: '',
    make: '',
    hire: '',
    khoraki: '',
    latitude: '',
    longitude: '',
    address: '',
    description: ''
  };
  form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddPAndMModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
    private settingService: SettingService,
  ) {
    this.tenderId = data['tenderId'];
    if (data['PMDetails'] != undefined) {
      this.PMDetails = data['PMDetails']
    }
    if (data['checkReadonly'] != undefined) {
      this.checkReadonly = true
    }

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [''],
      machinary_type: [''],
      name: ['', Validators.required],
      make: ['', Validators.required],
      hire: ['', Validators.required],
      khoraki: ['', Validators.required],
      address: ['', Validators.required],
      longitude: ['', Validators.required],
      latitude: ['', Validators.required],
      description: ['', Validators.required]
    });

    if (this.PMDetails.id != undefined) {
      this.form.patchValue({
        latitude: this.PMDetails.latitude,
        longitude: this.PMDetails.longitude
      })
    }
    this.loading = LoadingState.Ready
  }

  handleAddressChange(address) {
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      address: formattedAddress
    })
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
   // console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing
      this.settingService.addTenderMachineryType(this.form.value).subscribe(
        res => {
        //  console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var PMId = this.PMDetails['id']
      
      this.settingService.addEditTenderMachineryType(this.form.value).subscribe(
        res => {
          //console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
