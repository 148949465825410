import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { SettingService} from '../../../services/setting.service'
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss']
})
export class DocumentsTabComponent implements OnInit {
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  // isDocument: boolean = true;
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  technicalEligibilityList: any = [];
  financialEligibilityList: any = [];
  specialEligibilityList: any = [];
  technicalEligibilityForm: FormGroup;
  financialEligibilityForm: FormGroup;
  specialEligibilityForm: FormGroup;
  priceForm: FormGroup;
  extraTechnicalEligibilityDocument: any = [];
  extraFinancialEligibilityDocument: any = [];
  extraSpecialEligibilityDocument: any = [];
  extraPriceEligibilityDocument: any = [];
  tenderDocumentEligibleDetails: any = {
    is_upload_document: true,
    reason_for_no_documentation: ''
  };
  constructor(
    private tenderService: TenderService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reason_for_no_documentation: ['', Validators.required]
    });
    this.technicalEligibilityForm = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      tender_eligibility_type: ['technical', Validators.required],
      eligible_document: this.formBuilder.array([]),
    });
    this.financialEligibilityForm = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      tender_eligibility_type: ['financial', Validators.required],
      eligible_document: this.formBuilder.array([]),
    });
    this.specialEligibilityForm = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      tender_eligibility_type: ['special', Validators.required],
      eligible_document: this.formBuilder.array([]),
    });
    this.priceForm = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      eligible_document: this.formBuilder.array([]),
    });
    this.loadData()
  }
  // technical_eligibility
  create_technical_eligibility_document() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document_date_o_s: ['', Validators.required],
      eligibility_file: [null, Validators.required],
      tab_document: null
    });
  }

  add_technical_eligibility_document() {
    const control = <FormArray>this.technicalEligibilityForm.controls['eligible_document'];
    control.push(this.create_technical_eligibility_document());
  }

  delete_technical_eligibility_document(index: number) {
    const control = <FormArray>this.technicalEligibilityForm.controls['eligible_document'];
    control.removeAt(index);
  }

  getTechnicalEligibilityFormArrayControl(form) {
    return form.get('eligible_document').controls
  }

  technicalEligibilityOnSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.technicalEligibilityForm.controls['eligible_document'];
        control.at(index).patchValue({
          eligibility_file: event.target.files[0]['name'],
          tab_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  updateTechnicalEligibilityExtraDoc() {
    if (this.technicalEligibilityForm.valid) {
      var technicalEligibilityExtraDoc = [];
      technicalEligibilityExtraDoc = this.technicalEligibilityForm.value.eligible_document;
      if (technicalEligibilityExtraDoc.length > 0) {
        var forkArray = [];
        technicalEligibilityExtraDoc.forEach(x => {
          const formData: any = new FormData();
          formData.append("tender", this.tenderId);
          formData.append("tender_eligibility_type", this.technicalEligibilityForm.value.tender_eligibility_type);
          var documentDate_o_s = this.settingService.getProperDate(x.document_date_o_s);
          formData.append("document_date_o_s", documentDate_o_s.toISOString());//aa
          formData.append("document_name", x.document_name);
          formData.append("tab_document", x.tab_document, x.tab_document['name']);
          forkArray.push(this.tenderService.addTenderEligibilityTabDocument(this.tenderId, this.technicalEligibilityForm.value.tender_eligibility_type, formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
           // console.log(result)
            result.forEach(x => {
              this.extraTechnicalEligibilityDocument.push(x)
            })
            for (var k = technicalEligibilityExtraDoc.length - 1; k >= 0; k--) {
              this.delete_technical_eligibility_document(k)
            }
            this.loading = LoadingState.Ready;
            this.toastr.success('Document has been added','',{
              timeOut: 3000,
              });
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
              });
          }
        )
      }
    }
    else {
      this.markFormGroupTouched(this.technicalEligibilityForm)
    }
  }

  deleteTechnicalEligibilityExtraDoc(i) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing;
      this.tenderService.deleteTenderEligibilityTabDocument(this.extraTechnicalEligibilityDocument[i]['id']).subscribe(
        res => {
         // console.log(res)
          this.extraTechnicalEligibilityDocument.splice(i, 1)
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      )
	 }
	 this.dialogRef = null;
	 })
    
  }
  // financial
  create_financial_eligibility_document() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document_date_o_s: ['', Validators.required],
      eligibility_file: [null, Validators.required],
      tab_document: null
    });
  }

  add_financial_eligibility_document() {
    const control = <FormArray>this.financialEligibilityForm.controls['eligible_document'];
    control.push(this.create_technical_eligibility_document());
  }

  delete_financial_eligibility_document(index: number) {
    const control = <FormArray>this.financialEligibilityForm.controls['eligible_document'];
    control.removeAt(index);
  }

  getFinancialEligibilityFormArrayControl(form) {
    return form.get('eligible_document').controls
  }

  financialEligibilityOnSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.financialEligibilityForm.controls['eligible_document'];
        control.at(index).patchValue({
          eligibility_file: event.target.files[0]['name'],
          tab_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  updateFinancialEligibilityExtraDoc() {
    if (this.financialEligibilityForm.valid) {
      var financialEligibilityExtraDoc = [];
      financialEligibilityExtraDoc = this.financialEligibilityForm.value.eligible_document;
      if (financialEligibilityExtraDoc.length > 0) {
        var forkArray = [];
        financialEligibilityExtraDoc.forEach(x => {
          const formData: any = new FormData();
          formData.append("tender", this.tenderId);
          formData.append("tender_eligibility_type", this.financialEligibilityForm.value.tender_eligibility_type);
         
          var documentDate_o_s = this.settingService.getProperDate(x.document_date_o_s);
          formData.append("document_date_o_s", documentDate_o_s.toISOString());//aa
          formData.append("document_name", x.document_name);
          formData.append("tab_document", x.tab_document, x.tab_document['name']);
          forkArray.push(this.tenderService.addTenderEligibilityTabDocument(this.tenderId, this.financialEligibilityForm.value.tender_eligibility_type, formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
            console.log(result)
            result.forEach(x => {
              this.extraFinancialEligibilityDocument.push(x)
            })
            for (var k = financialEligibilityExtraDoc.length - 1; k >= 0; k--) {
              this.delete_financial_eligibility_document(k)
            }
            this.loading = LoadingState.Ready;
            this.toastr.success('Document has been added','',{
              timeOut: 3000,
              });
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
              });
          }
        )
      }
    }
    else {
      this.markFormGroupTouched(this.financialEligibilityForm)
    }
  }

  deleteFinancialEligibilityExtraDoc(i) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing;
      this.tenderService.deleteTenderEligibilityTabDocument(this.extraFinancialEligibilityDocument[i]['id']).subscribe(
        res => {
        //  console.log(res)
          this.extraFinancialEligibilityDocument.splice(i, 1)
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      )
	 }
	 this.dialogRef = null;
   })
   
   
  }
  // special
  create_special_eligibility_document() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document_date_o_s: ['', Validators.required],
      eligibility_file: [null, Validators.required],
      tab_document: null
    });
  }

  add_special_eligibility_document() {
    const control = <FormArray>this.specialEligibilityForm.controls['eligible_document'];
    control.push(this.create_technical_eligibility_document());
  }

  delete_special_eligibility_document(index: number) {
    const control = <FormArray>this.specialEligibilityForm.controls['eligible_document'];
    control.removeAt(index);
  }

  getSpecialEligibilityFormArrayControl(form) {
    return form.get('eligible_document').controls
  }

  specialEligibilityOnSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.specialEligibilityForm.controls['eligible_document'];
        control.at(index).patchValue({
          eligibility_file: event.target.files[0]['name'],
          tab_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  updateSpecialEligibilityExtraDoc() {
    if (this.specialEligibilityForm.valid) {
      var specialEligibilityExtraDoc = [];
      specialEligibilityExtraDoc = this.specialEligibilityForm.value.eligible_document;
      if (specialEligibilityExtraDoc.length > 0) {
        var forkArray = [];
        specialEligibilityExtraDoc.forEach(x => {
          const formData: any = new FormData();
          formData.append("tender", this.tenderId);
          formData.append("tender_eligibility_type", this.specialEligibilityForm.value.tender_eligibility_type);
          var documentDate_o_s = this.settingService.getProperDate(x.document_date_o_s);
          formData.append("document_date_o_s", documentDate_o_s.toISOString());//aa
          formData.append("document_name", x.document_name);
          formData.append("tab_document", x.tab_document, x.tab_document['name']);
          forkArray.push(this.tenderService.addTenderEligibilityTabDocument(this.tenderId, this.specialEligibilityForm.value.tender_eligibility_type, formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
            console.log(result)
            result.forEach(x => {
              this.extraSpecialEligibilityDocument.push(x)
            })
            for (var k = specialEligibilityExtraDoc.length - 1; k >= 0; k--) {
              this.delete_special_eligibility_document(k)
            }
            this.loading = LoadingState.Ready;
            this.toastr.success('Document has been added','',{
              timeOut: 3000,
              });
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
              });
               
          }
        )
      }
    }
    else {
      this.markFormGroupTouched(this.specialEligibilityForm)
    }
  }

  deleteSpecialEligibilityExtraDoc(i) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing;
      this.tenderService.deleteTenderEligibilityTabDocument(this.extraSpecialEligibilityDocument[i]['id']).subscribe(
        res => {
         // console.log(res)
          this.extraSpecialEligibilityDocument.splice(i, 1)
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      )
	 }
	 this.dialogRef = null;
	 })

    
  }

  // price
  create_price_eligibility_document() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document_date_o_s: ['', Validators.required],
      eligibility_file: [null, Validators.required],
      tab_document: null
    });
  }

  add_price_eligibility_document() {
    const control = <FormArray>this.priceForm.controls['eligible_document'];
    control.push(this.create_price_eligibility_document());
  }

  delete_price_eligibility_document(index: number) {
    const control = <FormArray>this.priceForm.controls['eligible_document'];
    control.removeAt(index);
  }

  getPriceEligibilityFormArrayControl(form) {
    return form.get('eligible_document').controls
  }

  priceEligibilityOnSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.priceForm.controls['eligible_document'];
        control.at(index).patchValue({
          eligibility_file: event.target.files[0]['name'],
          tab_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  updatePriceEligibilityExtraDoc() {
    if (this.priceForm.valid) {
      var priceEligibilityExtraDoc = [];
      priceEligibilityExtraDoc = this.priceForm.value.eligible_document;
      if (priceEligibilityExtraDoc.length > 0) {
        var forkArray = [];
        priceEligibilityExtraDoc.forEach(x => {
          const formData: any = new FormData();
          formData.append("tender", this.tenderId);
          var documentDate_o_s = this.settingService.getProperDate(x.document_date_o_s);
          formData.append("document_date_o_s", documentDate_o_s.toISOString());//aa
          formData.append("document_name", x.document_name);
          formData.append("tab_document", x.tab_document, x.tab_document['name']);
          forkArray.push(this.tenderService.addTenderPriceTabDocument(formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
           // console.log(result)
            result.forEach(x => {
              this.extraPriceEligibilityDocument.push(x)
            })
            for (var k = priceEligibilityExtraDoc.length - 1; k >= 0; k--) {
              this.delete_price_eligibility_document(k)
            }
            this.loading = LoadingState.Ready;
            this.toastr.success('Document has been added','',{
              timeOut: 3000,
              });
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
              });
          }
        )
      }
    }
    else {
      this.markFormGroupTouched(this.priceForm)
    }
  }

  deletePriceEligibilityExtraDoc(i) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing;
      this.tenderService.deleteTenderEligibilityTabDocument(this.extraPriceEligibilityDocument[i]['id']).subscribe(
        res => {
         // console.log(res)
          this.extraPriceEligibilityDocument.splice(i, 1)
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      )
	 }
	 this.dialogRef = null;
	 })
    
  }

  // 

  loadData() {
    var forkArray = [];
    forkArray.push(this.tenderService.getTenderEligibilityFieldsList(this.tenderId, 'technical'))
    forkArray.push(this.tenderService.getTenderEligibilityFieldsList(this.tenderId, 'financial'))
    forkArray.push(this.tenderService.getTenderEligibilityFieldsList(this.tenderId, 'special'))
    forkArray.push(this.tenderService.getTenderEligibilityTabDocument(this.tenderId, 'technical'))
    forkArray.push(this.tenderService.getTenderEligibilityTabDocument(this.tenderId, 'financial'))
    forkArray.push(this.tenderService.getTenderEligibilityTabDocument(this.tenderId, 'special'))
    forkArray.push(this.tenderService.getTenderPriceTabDocument(this.tenderId))
    forkArray.push(this.tenderService.getTenderDocumentCheckEligible(this.tenderId))
    forkJoin(forkArray).subscribe(
      ([technicalResult, financialResult, specialResult, technicalDocResult, financialDocResult, specialDocResult, priceResult, eligibleResult]) => {
        this.technicalEligibilityList = technicalResult['result'];
        this.financialEligibilityList = financialResult['result'];
        this.specialEligibilityList = specialResult['result'];
        this.extraTechnicalEligibilityDocument = technicalDocResult;
        this.extraFinancialEligibilityDocument = financialDocResult;
        this.extraSpecialEligibilityDocument = specialDocResult;
        if (priceResult['result'].length > 0) {
          this.extraPriceEligibilityDocument = priceResult['result'];
        }
        if (eligibleResult['result'] != "") {
          this.tenderDocumentEligibleDetails = eligibleResult['result'];
        }
        
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }

  onSelectTechFinanSpecialFile(event, index, type, id, tenderEligibility) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }

        const formData: any = new FormData();
        formData.append("tender", this.tenderId);
        formData.append("tender_eligibility", tenderEligibility);
        formData.append("document", event.target.files[0], event.target.files[0]['name']);
        this.loading = LoadingState.Processing
        this.tenderService.addTenderEligibilityFieldDocument(id, formData).subscribe(
          res => {
           // console.log(res)
            if (type == 'technical') {
              this.technicalEligibilityList[index]['document'] = res['document']
            }
            else if (type == 'financial') {
              this.financialEligibilityList[index]['document'] = res['document']
            }
            else if (type == 'special') {
              this.specialEligibilityList[index]['document'] = res['document']
            }
            this.loading = LoadingState.Ready
          },
          error => {
            console.log(error)
            this.loading = LoadingState.Ready
          }
        )

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  handleChange(e: any) {
   // console.log(this.tenderDocumentEligibleDetails)
    if (this.tenderDocumentEligibleDetails.is_upload_document) {
      var data = {
        tender: this.tenderId,
        is_upload_document: this.tenderDocumentEligibleDetails.is_upload_document
      }
      this.loading = LoadingState.Processing
      this.tenderService.addTenderDocumentCheckEligible(this.tenderId, data).subscribe(
        res => {
         // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  save() {
   // console.log(this.form.value)
    if (this.form.valid) {
      var data = {
        tender: this.tenderId,
        is_upload_document: this.tenderDocumentEligibleDetails.is_upload_document,
        reason_for_no_documentation: this.form.value.reason_for_no_documentation
      }
      this.loading = LoadingState.Processing
      this.tenderService.addTenderDocumentCheckEligible(this.tenderId, data).subscribe(
        res => {
          //console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
         // console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
