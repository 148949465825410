import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPartnerModalComponent } from '../add-partner-modal/add-partner-modal.component';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bidder-type-tab',
  templateUrl: './bidder-type-tab.component.html',
  styleUrls: ['./bidder-type-tab.component.scss']
})
export class BidderTypeTabComponent implements OnInit {
  bidderType: string;
  isJointVenture: boolean;
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  partnerList: any = [];
  bidderTypeData: any = {
    type_of_partner: null,
    responsibility: null,
    profit_sharing_ratio_actual_amount: null,
    profit_sharing_ratio_tender_specific_amount: null,
    partners: null
  };
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.bidderType = "individual";
    this.loading = LoadingState.Ready;
   // console.log(this.tenderId)
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      bidder_type: ['', Validators.required],
      responsibility: ['', Validators.required],
      type_of_partner: ['', Validators.required],
      profit_sharing_ratio_actual_amount: ['', Validators.required],
      profit_sharing_ratio_tender_specific_amount: ['', Validators.required],
      partners: ['', Validators.required],
    });
    this.getPartnerList();
    this.getBidderDetailsByTenderId();
  }

  getBidderDetailsByTenderId() {
    this.tenderService.getBidderDetailsByTenderId(this.tenderId).subscribe(
      (res) => {
        //console.log(res)        
        if (res['result'] != null && res['result'] != "") {
          this.bidderTypeData = res['result'];
          if(this.bidderTypeData != null){
            this.bidderType = this.bidderTypeData['bidder_type']
          }
          
          if (this.bidderType == "joint_venture") {
            this.isJointVenture = true;
          }
          else {
            this.isJointVenture = false;
          }
        }
      },
      error => {
       // console.log(error);
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  handleChange(e: any) {
    if (e.target.value == "joint_venture") {
      this.isJointVenture = true;
    }
    else {
      this.isJointVenture = false;
    }
  }

  getPartnerList() {
    this.tenderService.getPartnerList().subscribe(
      res => {
      //  console.log(res)
        this.partnerList = res;
      },
      error => {
        console.log(error)
      }
    )
  }

  addTenderBidderType(data) {
    this.loading = LoadingState.Processing
    this.tenderService.addTenderBidderType(data).subscribe(
      res => {
       // console.log(res)
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been saved','',{
          timeOut: 3000,
          });
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  updateTenderBidderType(data) {
    this.loading = LoadingState.Processing
    this.tenderService.updateTenderBidderType(this.tenderId, data).subscribe(
      res => {
        //console.log(res)
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been saved','',{
          timeOut: 3000,
          });
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  saveBidderType() {
    if (this.bidderTypeData == null) {
      if (this.bidderType == "individual") {
        var data = {
          tender: this.tenderId,
          bidder_type: this.bidderType,
          responsibility: "technical_and_financial"
        }
        this.addTenderBidderType(data)
      }
      else if (this.bidderType == "joint_venture") {
        this.form.patchValue({
          bidder_type: 'joint_venture'
        })
        if (this.form.valid) {
         // console.log(this.form.value)
          this.addTenderBidderType(this.form.value)
        }
        else {
          this.markFormGroupTouched(this.form)
        }
      }
    }
    else {
      if (this.bidderType == "individual") {
        var data = {
          tender: this.tenderId,
          bidder_type: this.bidderType,
          responsibility: "technical_and_financial"
        }
        this.updateTenderBidderType(data)
      }
      else if (this.bidderType == "joint_venture") {
        this.form.patchValue({
          bidder_type: 'joint_venture'
        })
        if (this.form.valid) {
        //  console.log(this.form.value)
          this.updateTenderBidderType(this.form.value)
        }
        else {
          this.markFormGroupTouched(this.form)
        }
      }
    }

  }

  openPartnerModal() {
    let dialogRef = this.dialog.open(AddPartnerModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log(result)
      if (result != undefined) {
        if (result['data'] != undefined) {
          this.partnerList.push(result['data'])
        //  console.log(this.partnerList)
        }
      }

    })
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
