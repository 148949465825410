import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rawmaterial-cement-godown-inner-tab',
  templateUrl: './rawmaterial-cement-godown-inner-tab.component.html',
  styleUrls: ['./rawmaterial-cement-godown-inner-tab.component.scss']
})
export class RawmaterialCementGodownInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  godownDocumentList:any = [];
  godownDetail:any = [];
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      protection_type:[null,Validators.required],
      area_of_go_down:[null,Validators.required],
      rental_cost:[null,Validators.required],
      capacity:[null,Validators.required],
      protection_cost:[null,Validators.required],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        // this.createDocument()
      ])
    })

    if(this.projectId){
      this.getDownDetails();
    }
  }

  save(){
    if(this.form.valid){
      var godown_documents_data = [];
      godown_documents_data = this.form.value.documents;
      //console.log(godown_documents_data);

      this.loading = LoadingState.Processing

      const data = {
        project: this.projectId,
        protection_type: this.form.value.protection_type,
        area_of_go_down:this.form.value.area_of_go_down,
        rental_cost: this.form.value.rental_cost,
        capacity: this.form.value.capacity,
        protection_cost: this.form.value.protection_cost,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost
      }
      this.godownDocumentList = [];
      this.perExecutionService.addCementGodownOffice(data).subscribe(
        res => {
          var forkArray = [];

          godown_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.godown_document, x.godown_document['name']);
              forkArray.push(this.perExecutionService.addCementGodownDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.godownDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
     // console.log(data);
    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  getDownDetails(){
    this.perExecutionService.getCementGodownOffice(this.projectId).subscribe(
      res=>{
        if(res['result'][0]){
          this.godownDetail = res['result'][0];
          //console.log(this.godownDetail);
          this.godownDocumentList = res['result'][0].document_details;
          var start_date = new Date(this.godownDetail['requirment_s_date'])
            this.godownDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.godownDetail['requirment_e_date'])
            this.godownDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          
          this.godownDetail.budgeted_cost = parseFloat(this.godownDetail.budgeted_cost).toFixed(2);
          this.godownDetail.executed_cost = parseFloat(this.godownDetail.executed_cost).toFixed(2);
          this.godownDetail.protection_cost = parseFloat(this.godownDetail.protection_cost).toFixed(2);
          this.godownDetail.rental_cost = parseFloat(this.godownDetail.rental_cost).toFixed(2);
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.godownDocumentList[index]['isEdit'] = true
    this.godownDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.godownDocumentList[index]['document_name'] == '') {
      this.godownDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.godownDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.godownDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editCementGodownDocumentOffice(this.godownDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.godownDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteCementGodownDocumentOffice(this.godownDocumentList[index]['id']).subscribe(
      res => {
        this.godownDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
	 })
    
  }

  update(){
    if(this.form.valid){
     // console.log(this.godownDetail);
      var godown_documents_data = [];
      godown_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        godown_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project", this.projectId);
            formData.append("module_id", this.godownDetail.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.godown_document, x.godown_document['name']);
            forkArray.push(this.perExecutionService.addCementGodownDocumentOffice(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.godownDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateGodown();
      }

      else{
        this.updateGodown();
      }
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

  updateGodown(){
    if(this.form.valid){
      this.loading = LoadingState.Processing

      const updateData = {
        project: this.projectId,
        protection_type: this.form.value.protection_type,
        area_of_go_down:this.form.value.area_of_go_down,
        rental_cost: this.form.value.rental_cost,
        capacity: this.form.value.capacity,
        protection_cost: this.form.value.protection_cost,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost
      }

      this.perExecutionService.editCementGodownOffice(this.godownDetail.id,updateData).subscribe(
        res =>{
          //console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error =>{
         this.loading = LoadingState.Ready;
         this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
        }
      )

    }
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: [null, Validators.required],
      document: [null, Validators.required],
      godown_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }
  onSelectFile(event, index){
  //  console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          godown_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
