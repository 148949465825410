import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../services/setting.service';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  @Input() requisitionId:number;
  @Input() vendorList:any = [];
  requisitionApprovalStatus:number;
  @Output() checkStatusEvent = new EventEmitter<string>();

 

  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  quotationItemList:any = [];
  btnShow: boolean = false
  checkStatus:any;
  disabledChild:number = 0;
  constructor(
    private formBuilder: FormBuilder,
    private settingService:SettingService,
    private executionPurchaseService:ExecutionPurchaseService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
   // console.log(this.requisitionId);
    if(this.requisitionId){
      this.getQuotationItem();
    }
    
  }

  btnShowHandler(event:boolean){
    this.btnShow = event
   
  }
  getQuotationItem(){
    this.loading = LoadingState.Processing;

    // this.executionPurchaseService.getPurchaseItemQuotation(this.requisitionId).subscribe(
    //   res => {
      
    //     this.quotationItemList = res['result']['Item Approved List'];
    //     console.log(this.quotationItemList)
    //     this.loading = LoadingState.Ready;
    //   },
    //   error => {
    //     console.log(error)
    //     this.loading = LoadingState.Ready;
    //   }
    // )
    var forkArray = [];
    forkArray.push(this.executionPurchaseService.getrequisitionSubmitForApproval(this.requisitionId))
    forkArray.push(this.executionPurchaseService.getPurchaseItemQuotation(this.requisitionId))
    forkJoin(forkArray).subscribe(
      ([checkStatus,quotationItemList]) => {
    
          if(checkStatus){
            this.checkStatus = checkStatus['result'];
            
            if(this.checkStatus.status == 2){
              this.btnShow = true
            }else{
              this.btnShow = false
            }
          }
          if(quotationItemList){
            this.quotationItemList = quotationItemList['result']['Item_Approved_List'];
            //console.log(this.quotationItemList)
          }
    
       
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
      
      }
    )

  }

  submitForApproval(){
    
    this.loading = LoadingState.Processing;
    var data = {
      status:3
    }

    this.executionPurchaseService.updateSubmitForApproval(this.requisitionId,data).subscribe(
      res => {
        this.requisitionApprovalStatus = res;
       
        this.checkStatusEvent.next();
        this.btnShow = false
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been Approved','',{
          timeOut: 3000,
        });
        this.disabledChild = 1;
      },
      error=>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
  

}
