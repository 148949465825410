import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-site-mobilization-approve-tab',
  templateUrl: './site-mobilization-approve-tab.component.html',
  styleUrls: ['./site-mobilization-approve-tab.component.scss']
})
export class SiteMobilizationApproveTabComponent implements OnInit {

  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  guestHouseData:any = [];
  guestHouseFurnitureListData:any = [];
  guestHouseFurnitureData:any = [];
  electricalUtilitiesAminites:any = [];
  electricalUtilitiesAminitesDoc:any = [];
  waterUtilitiesAminites:any = [];
  waterUtilitiesAminitesDoc:any = [];
  fuelUtilitiesAminites:any = [];
  fuelUtilitiesAminitesDoc:any = [];
  UtensilsUtilitiesAminites:any = [];
  UtensilsUtilitiesAminitesList:any = [];
  UtensilsUtilitiesDoc:any = [];
  TiffinBoxUtilitiesAminites:any =[];
  TiffinBoxUtilitiesAminitesList:any =[];
  cookUtilitiesAminites:any = [];

  utensilNetCost:number = 0;
  tiffinNetCost:number = 0;
  guestHouseFurnitureCost:number = 0;
  totalGuestHouseFurnitureCost:number = 0;
  approve:number;
  approveData:any = [];


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,

  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      pre_execution_tabs:[this.tabId],
      approved_status:[null,Validators.required],
      request_modification:[null]

    })

    if(this.projectId){
      this.loadData();
     this.getStatus();
     
    }
  }

  handleChange(e: any) {
    //console.log(e)
    this.approve = e.target.value;
    if(this.approve!=3){
      this.form.patchValue({
        request_modification:null
      })
    }
  }

  save()
  {
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var data;
      if(this.approve!=3){
         data = {
          project:this.projectId,
          pre_execution_tabs:this.tabId,
          approved_status:this.form.value.approved_status
          
        }
      }
      else{
          data = {
          project:this.projectId,
          pre_execution_tabs:this.tabId,
          approved_status:this.form.value.approved_status,
          request_modification:this.form.value.request_modification
         
        }
      }

      this.perExecutionService.addApprovalTab(this.projectId,data).subscribe(
        res =>{
          //console.log(res)
          
          this.loading = LoadingState.Ready;
          this.toastr.error('Data has been submitted.', '', {
            timeOut: 3000,
          });

        },
        error =>{
        this.loading = LoadingState.Ready;
          this.toastr.error('Somthing Went Worng !', '', {
            timeOut: 3000,
          });
        })
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }


  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
       // console.log(res);
        if(res['result'][0]){
          this.approveData = res['result'][0];
          this.approve = this.approveData.approved_status;
          this.approveData.approved_status=  this.approveData.approved_status.toString();
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      })

  }


  loadData() {
    var forkArray = [];

    forkArray.push(this.perExecutionService.getGuestHouseFinding(this.projectId))
    forkArray.push(this.perExecutionService.getfurnitureTypeSiteMobilization(this.projectId))
    forkArray.push(this.perExecutionService.getfurnitureSiteMobilization(this.projectId))
    forkArray.push(this.perExecutionService.getElectricalUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getWaterDeatilsUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getFuelUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getUtensilsUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getUtensilsListUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getTiffinBoxUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getTiffinBoxListUtilitiesAminites(this.projectId))
    forkArray.push(this.perExecutionService.getCookUtilitiesAminites(this.projectId))

    
    
    
    
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
        //console.log(result);
       for (var i = 0; i < result.length; i++) {
        if (i == 0) {
          this.guestHouseData = result[i]['result'];
          if(this.guestHouseData.length > 0){
            this.guestHouseData.distence_from_site = parseFloat(this.guestHouseData.distence_from_site).toFixed(1);
          }
          
        }
        if(i==1){
          this.guestHouseFurnitureListData = result[i]['result'];
          if(this.guestHouseFurnitureListData.length > 0){
            this.guestHouseFurnitureListData.forEach(x =>{
             this.guestHouseFurnitureCost +=  x.count*x.local_rate;
            })
            
          }
        }
        if(i==2){
          this.guestHouseFurnitureData = result[i]['result'];
          this.totalGuestHouseFurnitureCost += this.guestHouseFurnitureCost + parseInt(this.guestHouseFurnitureData.transporation_cost);
        }
        if(i==3){
          this.electricalUtilitiesAminites = result[i]['result'][0];
          if(this.electricalUtilitiesAminites){
            this.electricalUtilitiesAminitesDoc = this.electricalUtilitiesAminites.document_details;
          }
        }
        if(i==4){
          this.waterUtilitiesAminites = result[i]['result'][0];
          if(this.waterUtilitiesAminites){
            this.waterUtilitiesAminitesDoc = this.waterUtilitiesAminites.document_details;
          }
        }
        if(i==5){
          this.fuelUtilitiesAminites = result[i]['result'][0];
          if(this.fuelUtilitiesAminites){
            this.fuelUtilitiesAminitesDoc = this.fuelUtilitiesAminites.document_details;
          }
        }
        if(i==6){
          this.UtensilsUtilitiesAminites = result[i]['result'][0];
        }
        if(i==7){
          
          this.UtensilsUtilitiesAminitesList = result[i]['result']; 
          if(this.UtensilsUtilitiesAminitesList.length > 0){
            this.UtensilsUtilitiesAminitesList.forEach(x =>{
             this.utensilNetCost +=  x.quantity*x.rate;
            })
          }

        }
        if(i==8){
          this.TiffinBoxUtilitiesAminites = result[i]['result'][0];
        }
        if(i==9){
          this.TiffinBoxUtilitiesAminitesList = result[i]['result'];
          if(this.TiffinBoxUtilitiesAminitesList.length > 0){
            this.TiffinBoxUtilitiesAminitesList.forEach(x =>{
             this.tiffinNetCost +=  x.quantity*x.rate;
            })
          }
        }
        if(i==10){
          this.cookUtilitiesAminites = result[i]['result'][0];
        }

  


        
       }
        this.loading = LoadingState.Ready;
      },
      err => {
        //console.log(err);
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal server error', '', {
          timeOut: 3000,
        });

      }
    )
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }
  
  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }
  
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
