import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalService } from './local.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,private localService: LocalService) {
  }


  addUser(str: any) {
    return this.http.post(environment.apiEndpoint + 'add_user/', str);
  }
  getAllUser() {
    return this.http.get(environment.apiEndpoint + 'add_user/');
  }
  getUsers() {
    return this.http.get(environment.apiEndpoint + 'user-module-role_list/');
  }

  userList(page, str, field, order) {
    return this.http.get(environment.apiEndpoint + 'user_module_list/?page=' + page + '&search=' + str + '&field_name=' + field + '&order_by=' + order);
  }

  getfreeroles(appid: any) {
    return this.http.get(environment.apiEndpoint + 'free_role-module_list/' + appid + '/');
  }

  logout() {
    return this.http.get(environment.apiEndpoint + 'logout/');
  }

  lockunlock(str) {
    return this.http.put(environment.apiEndpoint + 'user_lock_unlock/' + str.id + '/', str);
  }

  changepass(str) {
    return this.http.put(environment.apiEndpoint + 'change_password/', str);
  }

  getUserDetails() {
    return this.http.get(environment.apiEndpoint + 'user_edit/' + this.localService.getJsonValue('userid') + '/');
  }

  userDetails(uid) {
    return this.http.get(environment.apiEndpoint + 'user_edit/' + uid + '/');
  }

  otherProfileUpdate(uid, str) {
    return this.http.put(environment.apiEndpoint + 'user_edit/' + uid + '/', str);
  }

  updateUser(str) {
    return this.http.put(environment.apiEndpoint + 'user_edit/' + this.localService.getJsonValue('userid') + '/', str);
  }
}
