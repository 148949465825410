import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadingState } from '../../../components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-daily-p-m',
  templateUrl: './daily-p-m.component.html',
  styleUrls: ['./daily-p-m.component.scss']
})
export class DailyPMComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  pandmform: FormGroup;
  @Input() sitearr: any = [];
  @Input() machinearr: any = [];
  @Input() unitarr: any = [];
  @Input() employeeList: any = [];
  @Input() pmDetailsData: any;
  @Input() contractorList: any = [];
  @Input() projectList: any = [];
  @Input() activityList: any = [];
  @Input() boqList: any = [];
  @Output() setDetailsData = new EventEmitter<any>();
  @Output() setSiteData = new EventEmitter<any>();
  @Output() setBoqData = new EventEmitter<any>();

  boqDescription = null;

  constructor(private formBuilder: FormBuilder,
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.pandmform = this.formBuilder.group({
      type_of_report: 3,
      project_id: [null, Validators.required],
      site_location: [null, Validators.required],
      date_entry: [null, Validators.required],
      weather: ['', Validators.required],
      date_of_completion: [null, Validators.required],
      milestone_to_be_completed: ['', Validators.required],
      boq: ['', Validators.required],
      rainfall_from: [''],
      rainfall_to: [''],
      temperature_in_cel: ['', Validators.required],
      ir_problem: [''],
      local_issue: [''],
      client_visit: [''],
      administrative_issue: [''],
      document_ir: [null],
      document_li: [null],
      document_cv: [null],
      document_ai: [null],
      p_and_m_data: this.formBuilder.array([this.addPMDataFormGroup()])
    });
    
    if (this.pmDetailsData == undefined) {
      this.pmDetailsData = {
        project_id: null,
        site_location: null,
        date_entry: null,
        date_of_completion: null,
        weather: '',
        milestone_to_be_completed: '',
        boq: '',
        rainfall_from: '',
        rainfall_to: '',
        temperature_in_cel: '',
        ir_problem: null,
        local_issue: null,
        client_visit: null,
        administrative_issue: null,
      }
    } else {
      if(this.pmDetailsData.date_entry != null) {
        this.onPmDateSelected();
      }
    }
    this.loading = LoadingState.Ready;
  }

  /* FormGroup custom validators with range value
    Author - Biswajit Manna */
  addPMDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      activity_id: [null, Validators.required],
      details_of_activity: ['', Validators.required],
      machinery_used_id: [null, Validators.required],
      used_by: ['', Validators.required],
      unit_details_id: [null, Validators.required],
      unit_from: ['', Validators.required],
      unit_to: ['', Validators.required],
      start_time: [null, Validators.required],
      end_time: [null, Validators.required],
      remarks: [''],
      date_entry: [null],
      date_of_completion: [null],
      hsd_consumed: [null, Validators.required],
      fuel_issued: [null, Validators.required],
      breakdown_start_time: [null],
      breakdown_end_time: [null],
    }, { validators: [this.check_PM_end_time] });
  }
  /* FormGroup custom validators with range value
    Author - Biswajit Manna */
  check_PM_end_time(group: FormGroup) {
    if (group.controls.start_time.value != null && group.controls.end_time.value != null) {
      let start_time = group.controls.start_time.value['hour'] + ':' + group.controls.start_time.value['minute'] + ':' + group.controls.start_time.value['second'];
      let end_time = group.controls.end_time.value['hour'] + ':' + group.controls.end_time.value['minute'] + ':' + group.controls.end_time.value['second'];
      var date_entry = group.controls.date_entry.value;
      var date_of_completion = group.controls.date_of_completion.value;
      if (date_entry != null && date_of_completion != null) {
        date_entry = group.controls.date_entry.value;
        date_of_completion = group.controls.date_of_completion.value;
        date_entry = date_entry.month + '/' + date_entry.day + '/' + date_entry.year;
        date_of_completion = date_of_completion.month + '/' + date_of_completion.day + '/' + date_of_completion.year;
        return Date.parse(date_of_completion + ' ' + end_time) > Date.parse(date_entry + ' ' + start_time) ? null : { notGreaterPM: true }
      }
      else {
        return Date.parse('1/1/2020 ' + end_time) > Date.parse('1/1/2020 ' + start_time) ? null : { notGreaterPM: true }
      }
    }
    else {
      return false;
    }
  }
  weatherChange(e){
    this.setDetailsData.emit(this.pmDetailsData)
  }
  milestoneChange(e){
    this.setDetailsData.emit(this.pmDetailsData)
  }
  pmSiteOnchange(val) {
    this.setSiteData.emit(val)
  }


  onDateCompletionSelected() {
    if (this.pmDetailsData.date_of_completion) {
      this.setDetailsData.emit(this.pmDetailsData)
    }
  }

  onPmDateSelected() {
    if (this.pmDetailsData.date_entry) {
      this.setDetailsData.emit(this.pmDetailsData)
      var date = this.settingService.getProperDate(this.pmDetailsData.date_entry)
      var str = 'date=' + date.toISOString().substr(0, 10);
      if (this.pmDetailsData.site_location != null && this.pmDetailsData.project_id != null) {
        this.reportService.fetchDailyProgressCommonData(this.pmDetailsData.project_id, this.pmDetailsData.site_location, str, 3).subscribe(
          res => {
            //console.log(res)
            if (res['result'] != '') {
              var date = new Date(res['result']['date_of_completion'])
              this.pmDetailsData.date_of_completion = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
              }
              this.pmDetailsData.weather = res['result']['weather'];
              this.pmDetailsData.milestone_to_be_completed = res['result']['milestone_to_be_completed'];
              this.pmDetailsData.temperature_in_cel = res['result']['temperature_in_cel'];
              this.pmDetailsData.ir_problem = res['result']['ir_problem'];
              this.pmDetailsData.local_issue = res['result']['local_issue'];
              this.pmDetailsData.client_visit = res['result']['client_visit'];
              this.pmDetailsData.administrative_issue = res['result']['administrative_issue'];
              this.pmDetailsData.boq = res['result']['boq'];
              this.onBOQSelection(this.pmDetailsData.boq);
            }
            else {
              this.pmDetailsData.date_of_completion = null;
              this.pmDetailsData.weather = '';
              this.pmDetailsData.milestone_to_be_completed = '';
              this.pmDetailsData.temperature_in_cel = '';
              this.pmDetailsData.ir_problem = null;
              this.pmDetailsData.local_issue = null;
              this.pmDetailsData.client_visit = null;
              this.pmDetailsData.administrative_issue = null;
              this.pmDetailsData.boq = null;
              this.boqDescription = '';
            }
            this.setDetailsData.emit(this.pmDetailsData)
          },
          error => {
            //console.log(error)
          }
        )
      }
    }

  }


  getMinDate() {
    const current = new Date();
    if (current.getMonth() == 0 && current.getDate() == 1) {
      return {
        year: current.getFullYear() - 1,
        month: 12,
        day: 31
      };
    }
    else if (current.getDate() == 1) {
      var dateObj = new Date();
      dateObj.setDate(0)
      return {
        year: dateObj.getFullYear(),
        month: dateObj.getMonth() + 1,
        day: dateObj.getDate()
      };
    }
    else {
      return {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() - 1
      };
    }

  }

  getCompletionMinDate(form: FormGroup) {
    if (form.value.date_entry) {
      return {
        year: form.value.date_entry.year,
        month: form.value.date_entry.month,
        day: form.value.date_entry.day
      };
    }
    else {
      const current = new Date();
      return {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() - 1
      };
    }
  }


  removePMDataFormGroup(index) {
    const control = <FormArray>this.pandmform.controls['p_and_m_data'];
    control.removeAt(index);
  }

  addPMButtonClick(): void {
    const control = <FormArray>this.pandmform.controls['p_and_m_data'];
    control.push(this.addPMDataFormGroup());
  }

  getFormArrayControl(form) {
    return form.get('p_and_m_data').controls
  }

  savepmdata() {
    this.pandmform.patchValue({
      type_of_report: 3
    })
    //console.log(this.pandmform.value)
    if (this.pandmform.valid) {
      // this.loading = LoadingState.Processing
      const formData: any = new FormData();
      formData.append('date_entry', this.settingService.getProperDate(this.pandmform.value.date_entry).toISOString());
      formData.append('date_of_completion', this.settingService.getProperDate(this.pandmform.value.date_of_completion).toISOString());
      if(this.pandmform.value.rainfall_from != '' && this.pandmform.value.rainfall_from != null) {
        formData.append('rainfall_from', this.pandmform.value.rainfall_from.hour + ':' + this.pandmform.value.rainfall_from.minute);
      } 
      if(this.pandmform.value.rainfall_to != '' && this.pandmform.value.rainfall_to != null) {
        formData.append('rainfall_to', this.pandmform.value.rainfall_to.hour + ':' + this.pandmform.value.rainfall_to.minute);
      }
      formData.append('type_of_report', 3);
      formData.append('project_id', this.pandmform.value.project_id);
      formData.append('site_location', this.pandmform.value.site_location);
      formData.append('weather', this.pandmform.value.weather);
      formData.append('milestone_to_be_completed', this.pandmform.value.milestone_to_be_completed);
      formData.append('boq', this.pandmform.value.boq);
      formData.append('temperature_in_cel', this.pandmform.value.temperature_in_cel);
      formData.append('project_id', this.pandmform.value.project_id);
      if(this.pandmform.value.document_ir != null && this.pandmform.value.document_ir != undefined){
        formData.append('ir_problem',this.pandmform.value.document_ir);
      }
      if(this.pandmform.value.document_li != null && this.pandmform.value.document_li != undefined){
        formData.append('local_issue',this.pandmform.value.document_li);
      }
      if(this.pandmform.value.document_cv != null && this.pandmform.value.document_cv != undefined){
        formData.append('client_visit',this.pandmform.value.document_cv);
      }
      if(this.pandmform.value.document_ai != null && this.pandmform.value.document_ai != undefined){
        formData.append('administrative_issue',this.pandmform.value.document_ai);
      }
      var p_and_m_data = [];
      this.pandmform.value.p_and_m_data.forEach(x => {
        var activity_details_id = x.activity_id
        var activity_details = []
        activity_details_id.forEach(x => {
          var d = {
            activity_id: x
          }
          activity_details.push(d)
        })
        p_and_m_data.push({
          activity_details: activity_details,
          activity_id: null,
          start_time: x.start_time.hour + ':' + x.start_time.minute + ':' + x.start_time.second,
          end_time: x.end_time.hour + ':' + x.end_time.minute + ':' + x.end_time.second,
          machinery_used_id: x.machinery_used_id,
          used_by: x.used_by,
          unit_details_id: x.unit_details_id,
          unit_from: x.unit_from,
          unit_to: x.unit_to,
          remarks: x.remarks,
          hsd_consumed: x.hsd_consumed,
          fuel_issued: x.fuel_issued,
        })
      });
      if(this.pandmform.value.p_and_m_data.length > 0) {
        formData.append('p_and_m_data',JSON.stringify(p_and_m_data));
      }
      this.reportService.savePMData(formData).subscribe(
        data => {
          this.pandmform.reset();
          this.boqDescription = '';
          var res: any = data;
          this.toastr.success('Data has been succesfully saved !', '', {
            timeOut: 3000,
          });
          // this.pandmform.reset()          
          var objData = Object.assign([], this.pandmform.value.p_and_m_data)
          for (var i = objData.length - 1; i >= 0; i--) {
            //console.log(i)
            this.removePMDataFormGroup(i)
          }
          this.addPMButtonClick()
          this.loading = LoadingState.Ready
        },
        error => {
          //console.log(error)
          this.toastr.error('Internal error occurred !', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      );
    }
    else {
      this.markFormGroupTouched(this.pandmform);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  onDocumentSelect(event, fileFor) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg"
      || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpj"
      || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/doc" || event.target.files[0].type == "application/docx"
      || event.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      || event.target.files[0].type == "application/msword"
    ) {
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event) => {

          }
          if(fileFor == "ai") {
            this.pandmform.patchValue({
              document_ai: event.target.files[0]
            })
          } else if(fileFor == "li") {
            this.pandmform.patchValue({
              document_li: event.target.files[0]
            })
          } else if(fileFor == "ir") {
            this.pandmform.patchValue({
              document_ir: event.target.files[0]
            })
          } else if(fileFor == "cv") {
            this.pandmform.patchValue({
              document_cv: event.target.files[0]
            })
          }
          
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      this.toastr.error('Image/PDF/Word file type allowed', '', {
        timeOut: 3000,
      });
    }
  }

  onBOQSelection(val) {
    if(val) {
      this.boqDescription = this.boqList.filter(x => x.id == val)[0]['item_description'];
    } else {
      this.boqDescription = null
    }
  }

  viewDoc(item: any) {
    window.open(item, '', 'height=700,width=800,scrollbars=yes');
  }

  removeDoc(source) {
    this.loading = LoadingState.Processing;
    if(source == 'ir') {
      this.pmDetailsData.ir_problem = null;
    } else if (source == 'cv') {
      this.pmDetailsData.client_visit = null;
    } else if (source == 'li') {
      this.pmDetailsData.local_issue = null;
    } else if (source == 'ai') {
      this.pmDetailsData.administrative_issue = null;
    }
    this.loading = LoadingState.Ready;
  }
}
