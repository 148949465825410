import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-furniture-file-cabinet-inner-tab',
  templateUrl: './furniture-file-cabinet-inner-tab.component.html',
  styleUrls: ['./furniture-file-cabinet-inner-tab.component.scss']
})
export class FurnitureFileCabinetInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  furnitureDocumentList:any = [];
  furnitureDetails:any = [];
  furniture_type:number = 1;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public settingService:SettingService
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      project:[this.projectId],
      
      furniture_type:[this.furniture_type],
      brand:[null,Validators.required],
      quantity:[null,Validators.required],
      rate:[null,Validators.required],
      supplier_name:[null,Validators.required],
      supplier_ph_no:[null,Validators.required],
      supplier_address:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        // this.createDocument()
      ])
    })

    if(this.projectId){
      this.getFurniture();
    }
  }


  save(){
    if(this.form.valid){
      var furniture_documents_data = [];
      furniture_documents_data = this.form.value.documents;
      this.loading = LoadingState.Processing

      const data = {
        project: this.projectId,
     
        furniture_type:this.furniture_type,
        brand: this.form.value.brand,
        quantity: this.form.value.quantity,
        rate: this.form.value.rate,
        supplier_name: this.form.value.supplier_name,
        supplier_ph_no: this.form.value.supplier_ph_no,
        supplier_address:this.form.value.supplier_address,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }

     // console.log(data);


      this.furnitureDocumentList = [];
      this.perExecutionService.addfurnitureOffice(data).subscribe(
        res => {
          var forkArray = [];

          furniture_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.furniture_document, x.furniture_document['name']);
              forkArray.push(this.perExecutionService.addfurnitureDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.furnitureDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.getFurniture();
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
          //console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )



    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  getFurniture(){
    this.perExecutionService.getfurnitureOffice(this.furniture_type,this.projectId).subscribe(
      res=>{
        if(res['result'][0]){
          this.furnitureDetails = res['result'][0];
          //console.log(this.furnitureDetails);
          this.furnitureDocumentList = res['result'][0].document_details;
          var start_date = new Date(this.furnitureDetails['requirment_s_date'])
            this.furnitureDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.furnitureDetails['requirment_e_date'])
            this.furnitureDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          this.form.patchValue({
            latitude: this.furnitureDetails.latitude,
            longitude: this.furnitureDetails.longitude
          })
          this.furnitureDetails.budgeted_cost = parseFloat(this.furnitureDetails.budgeted_cost).toFixed(2);
          this.furnitureDetails.executed_cost = parseFloat(this.furnitureDetails.executed_cost).toFixed(2);
          this.furnitureDetails.rate = parseFloat(this.furnitureDetails.rate).toFixed(2);
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.furnitureDocumentList[index]['isEdit'] = true
    this.furnitureDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.furnitureDocumentList[index]['document_name'] == '') {
      this.furnitureDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.furnitureDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.furnitureDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editfurnitureDocumentOffice(this.furnitureDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.furnitureDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }
  
  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  deleteDoc(index) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deletefurnitureDocumentOffice(this.furnitureDocumentList[index]['id']).subscribe(
        res => {
          this.furnitureDocumentList.splice(index, 1)
          // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
   })
   
   
  }

  update(){
    if(this.form.valid){
     // console.log(this.furnitureDetails);
      var furniture_documents_data = [];
      furniture_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        furniture_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project", this.projectId);
            formData.append("module_id", this.furnitureDetails.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.furniture_document, x.furniture_document['name']);
            forkArray.push(this.perExecutionService.addfurnitureDocumentOffice(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.furnitureDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateFurniture();
      }

      else{
        this.updateFurniture();
      }


    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

  updateFurniture(){
    if(this.form.valid){
      this.loading = LoadingState.Processing

      const updateData = {
        project: this.projectId,
        
        furniture_type:this.furniture_type,
        brand: this.form.value.brand,
        quantity: this.form.value.quantity,
        rate: this.form.value.rate,
        supplier_name: this.form.value.supplier_name,
        supplier_ph_no: this.form.value.supplier_ph_no,
        supplier_address:this.form.value.supplier_address,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }

      this.perExecutionService.editfurnitureOffice(this.furnitureDetails.id,updateData).subscribe(
        res =>{
         // console.log(res)
        
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
            });
        },
        error =>{
          
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          //console.log(error)
        }
      )

    }
  }
  

  handleAddressChange(address) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      supplier_address: formattedAddress
    })
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: [null, Validators.required],
      document: [null, Validators.required],
      furniture_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }


  onSelectFile(event, index){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          furniture_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


}
