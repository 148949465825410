import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-expense',
  templateUrl: './confirm-dialog-expense.component.html',
  styleUrls: ['./confirm-dialog-expense.component.scss']
})
export class ConfirmDialogExpenseComponent implements OnInit {

  confirmMessage: string;
  remark: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogExpenseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    //console.log(this.data)
  }

}
