import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { reportingService } from 'src/app/core/services/reporting.service';
import { SettingService } from 'src/app/core/services/setting.service';
import { LoadingState } from '../../loading/loading.component';


@Component({
  selector: 'app-weekly-p-and-m',
  templateUrl: './weekly-p-and-m.component.html',
  styleUrls: ['./weekly-p-and-m.component.scss']
})
export class WeeklyPAndMComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  @Input() sitearr: any = [];
  @Input() machinearr: any = [];
  @Input() unitarr: any = [];
  @Input() machinaryDetailsData: any;
  @Input() projectList: any = [];
  @Input() boqList: any = [];
  @Input() activityList: any = [];
  @Output() setDetailsData = new EventEmitter<any>();
  @Output() setSiteData = new EventEmitter<any>();
  @Output() setBoqData = new EventEmitter<any>();
  start_date: any = null;
  end_date:any = null;
  dateRange: any = [];
  boqDescription = null;

  constructor(private formBuilder: FormBuilder, private settingService: SettingService,
    public reportService: reportingService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project_id: [null, Validators.required],
      site_location: [null, Validators.required],
      date_range: [null],
      boq: ['', Validators.required],
      p_and_m_data: this.formBuilder.array([this.addDataFormGroup()])
    });

    if (this.machinaryDetailsData == undefined) {
      this.machinaryDetailsData = {
        project_id: null,
        site_location: null,
        date_range: null,
        boq: ''
      }
    }
    this.loading = LoadingState.Ready;
  }

  addDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      activity_id: [null, Validators.required],
      details_of_activity: [null, Validators.required],
      machinery_used: [null, Validators.required],
      unit_details: [null, Validators.required],
      approx_quantum_of_work: ['', Validators.required],
      available_qty: ['', Validators.required],
      mitigation_plan: ['', Validators.required]
    });
  }

  removeDataFormGroup(index) {
    const control = <FormArray>this.form.controls['p_and_m_data'];
    control.removeAt(index);
  }

  addButtonClick(): void {
    const control = <FormArray>this.form.controls['p_and_m_data'];
    control.push(this.addDataFormGroup());
  }
  
  getFormArrayControl(form) {
    return form.get('p_and_m_data').controls
  }

  onProgressDateSelected() {

  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.start_date = this.settingService.formatDate(event.value[0])
      this.end_date = this.settingService.formatDate(event.value[1])
      this.onDateSelected();
    }
  }

  savedata() {
    if (this.form.valid && this.start_date != null && this.end_date != null) {
      this.loading = LoadingState.Processing
      const formData: any = new FormData();
      formData.append('type_of_report', '3');
      formData.append('project_id', this.form.value.project_id);
      formData.append('site_location', this.form.value.site_location);
      formData.append('boq', this.form.value.boq);
      formData.append('start_date',this.start_date);
      formData.append('end_date',this.end_date);
      var p_and_m_data = [];
      this.form.value.p_and_m_data.forEach(x => {
        var activity_details_id = x.activity_id
        var activity_details = []
        activity_details_id.forEach(x => {
          var d = {
            activity_id: x
          }
          activity_details.push(d)
        })
        p_and_m_data.push({
          machinery_used_id: x.machinery_used,
          activity_details: activity_details,
          details_of_activity: x.details_of_activity,
          unit_details_id: x.unit_details,
          approx_quantum_of_work: x.approx_quantum_of_work,
          available_qty: +x.available_qty,
          mitigation_plan: x.mitigation_plan
        })
      });
      formData.append('p_and_m_data', JSON.stringify(p_and_m_data));
      this.reportService.saveWeeklyMachinaryData(formData).subscribe(
        data => {
          var res: any = data;
          this.toastr.success('Data has been succesfully saved !', '', {
            timeOut: 3000,
          });
          var objData = Object.assign([], this.form.value.p_and_m_data)
          for (var i = objData.length - 1; i >= 0; i--) {
            this.removeDataFormGroup(i)
          }
          this.addButtonClick()
          this.loading = LoadingState.Ready
          this.form.reset();
          this.boqDescription = '';
        },
        error => {
          this.toastr.error('Internal error occurred !', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      );
    }
    else {
      this.markFormGroupTouched(this.form);
    }
  }

  siteOnchange(val) {
    this.setSiteData.emit(val);
  }

  projectOnChange(val) {
    this.setBoqData.emit(val);
  }

  onBOQSelection(val) {
    if(val) {
      this.boqDescription = this.boqList.filter(x => x.id == val)[0]['item_description'];
    } else {
      this.boqDescription = null
    }
  }

  onDateSelected() {
    if (this.start_date != null) {
      this.setDetailsData.emit(this.machinaryDetailsData)
      if (this.machinaryDetailsData.site_location != null && this.machinaryDetailsData.project_id != null) {
        this.reportService.fetchWeeklyCommonData(this.machinaryDetailsData.project_id, this.machinaryDetailsData.site_location,this.start_date, this.end_date, 1).subscribe(
          res => {
            //console.log(res)
            if (res['result'] != '') {
              this.machinaryDetailsData.boq = res['result']['boq'];
              this.onBOQSelection(this.machinaryDetailsData.boq);
            }
            else {
              this.machinaryDetailsData.boq = null;
              this.boqDescription = '';
            }
            this.setDetailsData.emit(this.machinaryDetailsData)
          },
          error => {
            //console.log(error)
          }
        )
      }
    }

  }

}
