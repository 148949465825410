import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ContractorsService } from '../../../../core/services/contractors.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-type-modal',
  templateUrl: './type-modal.component.html',
  styleUrls: ['./type-modal.component.scss']
})
export class TypeModalComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  detailsData: any;
  contractorTypeId: number;
  constructor(
    public dialogRef: MatDialogRef<TypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private contractorsService: ContractorsService,
    private toastr: ToastrService
  ) {
    console.log(data)
    if(data != undefined){
      this.detailsData = data
      this.contractorTypeId = data['id']
    }
    else{
      this.detailsData = {
        name: ''
      }
    }
   }

  ngOnInit() {

    this.form = this.formBuilder.group({
      name: [null, Validators.required]
    });
    this.loading = LoadingState.Ready
  }

  
  save() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      this.contractorsService.addContractorType(this.form.value).subscribe(
        res => {
          this.loading = LoadingState.Ready
          this.toastr.success('Contractor Type has been added', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(true)
        },
        error => {
          // console.log(error);
          this.dialogRef.close(false)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update(){
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      this.contractorsService.updateContractorType(this.contractorTypeId,this.form.value).subscribe(
        res => {
          this.loading = LoadingState.Ready
          this.toastr.success('Contractor Type has been updated', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(true)
        },
        error => {
          // console.log(error);
          this.dialogRef.close(false)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }
  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
