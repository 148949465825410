import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { LoadingState } from '../../../components/loading/loading.component';
import { AttendanceService } from '../../../services/attendance.service';
import { EmployeesService } from '../../../services/employees.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-leave-status-modal',
  templateUrl: './leave-status-modal.component.html',
  styleUrls: ['./leave-status-modal.component.scss']
})
export class LeaveStatusModalComponent implements OnInit {
  form: FormGroup;
  autoCheckedLeave:string = null;
  leaveDetail:any = [];
  // type:string;
  // isDisableCL:boolean = false;
  // isDisableEL:boolean = false;
  // isDisableSL:boolean = false;
  // selectedFile = []
  // showLeaveType:boolean = false;
  // moreDocument:number = 0
  salayTypeCode:string
  constructor(
    public dialogRef: MatDialogRef<LeaveStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    // private attendanceService:AttendanceService,
    // private employeesService:EmployeesService,
    // private toastr: ToastrService,
  ) { 
    this.salayTypeCode =  this.data.leaveDetail['salary_type_code']
  }

  ngOnInit() {
    this.leaveDetail = this.data.leaveDetail;
    // this.type = this.data['from'];

    this.form = this.formBuilder.group({
      leave_type: [null, Validators.required]
    });
    //console.log(this.leaveDetail)
    // this.autoCheckedLeave = 'AB';
    // if(this.leaveDetail.total_available_balance >= 1){
    //   this.autoCheckedLeave = 'AL';
    // }
    // else{
    //   this.autoCheckedLeave = 'AB';
    // }
    
  }

  leaveType(event){
    const control = this.form.controls;
    // console.log(control)
    // if(event.target.value == "Special Leave"){
    //   this.showLeaveType = true;
    //   control['special_leave'].setErrors({ required: true });
    //   control['special_leave'].setValidators([Validators.required]);

    // }else{
    //   this.showLeaveType = false;
    //   this.form.controls.special_leave.setValue('');
    //   control['special_leave'].setErrors(null);
    //   control['special_leave'].clearValidators();
    // }
  }

  formSubmit() {
    if (this.form.valid) {
      this.apply()
    }else{
      this.markFormGroupTouched(this.form)
    }
  }

  // onSelectFile(event, index) {
  //   console.log(event.target.files);
  //   if (event.target.files && event.target.files[0]) {
  //     var filesAmount = event.target.files.length;
  //     for (let i = 0; i < filesAmount; i++) {
  //       var reader = new FileReader();
  //       reader.onload = (event) => {
  //         // console.log(event.target['result']);
  //       }
  //       const control = <FormArray>this.form.controls['documents'];
  //       control.at(index).patchValue({
  //         document: event.target.files[0]['name'],
  //         leave_document: event.target.files[0]

  //       })
  //       reader.readAsDataURL(event.target.files[i]);

  //       this.selectedFile.splice(index, 1);
  //       var data = { index: index , name:event.target.files[0]['name']}
  //       this.selectedFile.push(data);
  //     }
  //     console.log(this.selectedFile)
  //   }
    
  // }

  // getFormArrayControl(form) {
  //   return form.get('documents').controls
  // }

  // addDocument()
  // {
  //   const control = <FormArray>this.form.controls['documents'];
  //   control.push(this.createDocument());
  //   this.moreDocument = this.moreDocument+1;
  // }

  // deleteDocument(index:number)
  // {
  //   const control = <FormArray>this.form.controls['documents'];
  //   control.removeAt(index);
  //   this.selectedFile.splice(index, 1);
  //   this.moreDocument = this.moreDocument-1;
  // }
  // createDocument(){
  //   return this.formBuilder.group({
  //     document_name: [''],
  //     document: [null],
  //     leave_document: null
  //   })
  // }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  apply(){
    this.close();
  }

  close() {
    
    // if(this.form.value.special_leave){
    //   this.form.value.leave_type = this.form.value.special_leave
    // }
    // console.log(this.form.value)
    // if(this.form.value.special_leave != null){
    //   this.autoCheckedLeave = this.form.value.special_leave
    // }
    // else{
    //   this.autoCheckedLeave = this.form.value.leave_type
    // }
    
    this.dialogRef.close(this.form.value.leave_type);
  }
}
