import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-add-supplier-modal',
  templateUrl: './add-supplier-modal.component.html',
  styleUrls: ['./add-supplier-modal.component.scss']
})
export class AddSupplierModalComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  tenderId: number;
  type: number;
  tender_survey_material: number;
  supplierDetails: any = {
    supplier_name: '',
    contact: '',
    latitude: '',
    longitude: ''
  };
  form: FormGroup;
  modal_title: string;
  constructor(
    public dialogRef: MatDialogRef<AddSupplierModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
  ) {
    this.tenderId = data['tenderId'];
    this.type = data['type'];
    this.tender_survey_material = data['tender_survey_material'];
    if (data['supplierDetails'] != undefined) {
      this.supplierDetails = data['supplierDetails']
    }

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      type: [this.type, Validators.required],
      tender_survey_material: [this.tender_survey_material, Validators.required],
      supplier_name: ['', Validators.required],
      contact: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(12)
      ]],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      address: [''],
    });
    if (this.type == 1) {
      this.modal_title = "Supplier"
    }
    else if (this.type == 2) {
      this.modal_title = "Crusher"
    }
    this.loading = LoadingState.Ready
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing
      this.tenderService.addTenderSupplierCrusher(this.form.value).subscribe(
        res => {
          //console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var supplierId = this.supplierDetails['id']
      this.tenderService.editTenderSupplierCrusher(supplierId, this.form.value).subscribe(
        res => {
         // console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
