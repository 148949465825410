import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-approval-status',
  templateUrl: './approval-status.component.html',
  styleUrls: ['./approval-status.component.scss']
})
export class BillsInvoicesApprovalStatusComponent implements OnInit {

  approvals: any = [];
  constructor(
    public dialogRef: MatDialogRef<BillsInvoicesApprovalStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.approvals = data
  }

  ngOnInit() {
    console.log(this.approvals)
  }

}
