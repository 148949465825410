import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { LoadingState } from '../../loading/loading.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-office-secuurity-room-inner-tab',
  templateUrl: './office-secuurity-room-inner-tab.component.html',
  styleUrls: ['./office-secuurity-room-inner-tab.component.scss']
})
export class OfficeSecuurityRoomInnerTabComponent implements OnInit {
 

  form: FormGroup;
  @Input() projectId:number;
  security_room_type:number = 1;
  loading: LoadingState = LoadingState.NotReady;
  officeStructureDetails:any = [];
  OfficeStructureDocumentList:any = [];
  structure_name:string;
  isofficeContainer:boolean=true;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    this.structure_name = "office_conatiner";
    
    this.form = this.formBuilder.group({
      project:this.projectId,
      security_room_type:[this.security_room_type],
      size:[null,Validators.required],
      rate:[null],
      agency_name:[null,Validators.required],
      agency_contact_no:[null,Validators.required],
      transportation_cost:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
  })

  this.getOfficeDetail();
 
}


handleChange(e: any) {
  var type;
  if (e.target.value == "office_conatiner") {
    this.isofficeContainer = true;
    type =1;
    
  }
  else {
    this.isofficeContainer = false;
    type =2;
   }

   this.form.patchValue({
    security_room_type:type
  })
 
}




createDocument(){
  return this.formBuilder.group({
    document_name: ['', Validators.required],
    document: [null, Validators.required],
    office_document: null
  })
}

getFormArrayControl(form) {
  return form.get('documents').controls
}

addDocument()
{
  const control = <FormArray>this.form.controls['documents'];
  control.push(this.createDocument());
}

deleteDocument(index:number)
{
  const control = <FormArray>this.form.controls['documents'];
  control.removeAt(index);
}

getOfficeDetail(){
  this.perExecutionService.getSecurityRoomOffice(this.projectId).subscribe(
    res=>{
      // console.log(res);
      if(res['result'][0]){
        this.officeStructureDetails = res['result'][0];
        this.OfficeStructureDocumentList = res['result'][0].document_details;
        //console.log(this.officeStructureDetails);

        var start_date = new Date(this.officeStructureDetails['requirment_s_date'])
          this.officeStructureDetails.requirment_s_date = {
            year: start_date.getFullYear(),
            month: start_date.getMonth()+1,
            day: start_date.getDate()
      
          }
          var end_date = new Date(this.officeStructureDetails['requirment_e_date'])
          this.officeStructureDetails.requirment_e_date = {
            year: end_date.getFullYear(),
            month: end_date.getMonth()+1,
            day: end_date.getDate()
            
          }
       
        this.officeStructureDetails.budgeted_cost = parseFloat(this.officeStructureDetails.budgeted_cost).toFixed(2);
        this.officeStructureDetails.executed_cost = parseFloat(this.officeStructureDetails.executed_cost).toFixed(2);
        if(this.officeStructureDetails.transportation_cost!=null){
          this.officeStructureDetails.transportation_cost = parseFloat(this.officeStructureDetails.transportation_cost).toFixed(2);
        }
        if(this.officeStructureDetails.rate!=null){
          this.officeStructureDetails.rate = parseFloat(this.officeStructureDetails.rate).toFixed(2);
        }


        this.structure_name = this.officeStructureDetails.security_room_type==1?'office_conatiner':'brick_work';
        this.isofficeContainer = this.officeStructureDetails.security_room_type==1?true:false;

        this.form.patchValue({
          security_room_type:this.officeStructureDetails.security_room_type
        })



       // console.log(this.structure_name);
      }
      this.loading = LoadingState.Ready;
    },
    error => {
     // console.log(error);
      this.loading = LoadingState.Ready;
      
      this.toastr.error('Something went wrong', '', {
        timeOut: 3000,
        });
    }
  )
}

save()
{
  if(this.form.valid){
    var office_documents_data = [];
    office_documents_data = this.form.value.documents;
    var data;
    if(this.form.value.security_room_type==1){
       data = {
        office_set_up: 1,
        project: this.projectId,
        security_room_type: this.form.value.security_room_type,
        size: this.form.value.size,
        rate:this.form.value.rate,
        agency_name:this.form.value.agency_name,
        agency_contact_no:this.form.value.agency_contact_no,
        transportation_cost:this.form.value.transportation_cost,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }
    }
    else{
       data = {
        office_set_up: 1,
        project: this.projectId,
        security_room_type: this.form.value.security_room_type,
        size: this.form.value.size,
        agency_name:this.form.value.agency_name,
        agency_contact_no:this.form.value.agency_contact_no,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }
    }
    this.OfficeStructureDocumentList= [];
    this.perExecutionService.addSecurityRoomOffice(data).subscribe(
      res=>{
        if(this.form.value.documents.length > 0){
          var forkArray = [];
          office_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
                formData.append("module_id", res.id);
                formData.append("document_name", x.document_name);
                formData.append("document", x.office_document, x.office_document['name']);
                forkArray.push(this.perExecutionService.addSecurityRoomDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            result => {
              // console.log(result)
              result.forEach(y => {
                this.OfficeStructureDocumentList.push(y)
              })
             
              // this.add_tender_document();
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
         
        }

        this.toastr.success('Data has been saved','',{
          timeOut: 3000,
          });
      },
      error=>{
        //console.log(error);
        
      this.toastr.error('Something went wrong', '', {
        timeOut: 3000,
        });
      }
    )

  }else
  {
    this.markFormGroupTouched(this.form);
  }
}

update()
{
  if(this.form.valid){
    var office_documents_data = [];
    office_documents_data = this.form.value.documents;

    if(this.form.value.documents.length > 0){
      var forkArray = [];
      office_documents_data.forEach(x => {
          const formData: any = new FormData();
          formData.append("project",this.projectId);
            formData.append("module_id", this.officeStructureDetails.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.office_document, x.office_document['name']);
            forkArray.push(this.perExecutionService.addSecurityRoomDocumentOffice(formData))
      })
      forkJoin(forkArray).subscribe(
        result => {
          // console.log(result)
          result.forEach(y => {
            this.OfficeStructureDocumentList.push(y)
          })
         
          // this.add_tender_document();
          this.loading = LoadingState.Ready;
        },
        err => {
          this.loading = LoadingState.Ready;
          // console.log(err)
        }
      )
      this.updateOffice();
    }
    else{
      this.updateOffice();
    }



  }else{
    this.markFormGroupTouched(this.form);
  }
}

updateOffice(){
  if(this.form.valid){
    this.loading = LoadingState.Processing
    var updateData;
    if(this.form.value.security_room_type==1){
     updateData = {
      project: this.projectId,
      security_room_type: this.form.value.security_room_type,
      size: this.form.value.size,
      rate:this.form.value.rate,
      agency_name:this.form.value.agency_name,
      agency_contact_no:this.form.value.agency_contact_no,
      transportation_cost:this.form.value.transportation_cost,
      requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
      requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
      budgeted_cost:this.form.value.budgeted_cost,
      executed_cost:this.form.value.executed_cost,
    }
  }
  else{
     updateData = {
      project: this.projectId,
      security_room_type: this.form.value.security_room_type,
      size: this.form.value.size,
      rate:null,
      agency_name:this.form.value.agency_name,
      agency_contact_no:this.form.value.agency_contact_no,
      transportation_cost:null,
      requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
      requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
      budgeted_cost:this.form.value.budgeted_cost,
      executed_cost:this.form.value.executed_cost
    }
  }
    

    this.perExecutionService.editSecurityRoomOffice(this.officeStructureDetails.id,updateData).subscribe(
      res =>{
        //console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
      },
      error =>{
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
      }
    )

  }
  else{
    this.markFormGroupTouched(this.form);
  }
}

getFile(file: string) {
  var fileName = file.split('.')
  var fileExt = fileName[fileName.length - 1];
  if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
    return file;
  }
  else {
    return 'assets/img/pdf.png'
  }
}

editDoc(index) {
  this.OfficeStructureDocumentList[index]['isEdit'] = true
  this.OfficeStructureDocumentList[index]['isFieldError'] = false
}

renameDoc(index) {
  if (this.OfficeStructureDocumentList[index]['document_name'] == '') {
    this.OfficeStructureDocumentList[index]['isFieldError'] = true;
  }
  else {
    this.loading = LoadingState.Processing
    this.OfficeStructureDocumentList[index]['isFieldError'] = false;
    var data = {
      document_name: this.OfficeStructureDocumentList[index]['document_name']
    }

    
    this.perExecutionService.editSecurityRoomDocumentOffice(this.OfficeStructureDocumentList[index]['id'], data).subscribe(
      res => {
        // console.log(res)
        this.OfficeStructureDocumentList[index]['isEdit'] = false
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }
}

deleteDoc(index) {

  this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
    disableClose: false
  });

  this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

  this.dialogRef.afterClosed().subscribe(result => {
   if(result){
    this.loading = LoadingState.Processing
  this.perExecutionService.deleteSecurityRoomDocumentOffice(this.OfficeStructureDocumentList[index]['id']).subscribe(
    res => {
      this.OfficeStructureDocumentList.splice(index, 1)
      // console.log(res)
      this.loading = LoadingState.Ready
    },
    error => {
      // console.log(error)
      this.loading = LoadingState.Ready
    }
  )
 }
 this.dialogRef = null;
 })
  
}

onSelectFile(event, index){
  //console.log(event.target.files);
  if (event.target.files && event.target.files[0]) {
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();
      reader.onload = (event) => {
        // console.log(event.target['result']);
      }
      const control = <FormArray>this.form.controls['documents'];
      control.at(index).patchValue({
        document: event.target.files[0]['name'],
        office_document: event.target.files[0]
      })
      reader.readAsDataURL(event.target.files[i]);
    }
  }
}

markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    if (control.controls) {
      control.controls.forEach(c => this.markFormGroupTouched(c));
    }
  });
}

isFieldValid(form: FormGroup, field: string) {
  return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
}

displayFieldCss(form: FormGroup, field: string) {
  return {
    'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
    'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
  };
}

}
