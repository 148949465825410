import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-p-and-m-inner-tab',
  templateUrl: './p-and-m-inner-tab.component.html',
  styleUrls: ['./p-and-m-inner-tab.component.scss']
})
export class PAndMInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  @Input() masterId:number; 
  @Input() approveStatus:any = []; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  pmDetail:any = [];
  pmDocumentList:any = [];
  isOperator:boolean;
  operator:string;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private perExecutionService:PerExecutionService,
    public settingService:SettingService,
  ) {
      this.isOperator = true;
      
   }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      p_and_m: [this.masterId],
      types: [null,Validators.required],
      model_of_machinery: [null,Validators.required],
      fuel_consumption: [null,Validators.required],
      quantity:[null,Validators.required],
      capacity: [null,Validators.required],
      rate_per_product: [null,Validators.required],
      operator:[null,Validators.required],
      operator_required: [null,Validators.required],
      operator_name: [null],
      operator_contact_no:[null],
      operator_salary:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date: [null,Validators.required],
      budgeted_cost: [null,Validators.required],
      executed_cost: [null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])

    });
    if(this.projectId && this.masterId){
      this.getPmDetails();
    }

  }

  handleChange(e: any) {
  //  console.log(e.target.value);
    if (e.target.value == "yes") {
      this.isOperator= true;
    }
    else{
      this.isOperator= false;
      this.form.patchValue({
        operator_name:null,
        operator_contact_no:null,
        operator_salary:null

      })
    }

    this.form.patchValue({
      operator_required:this.isOperator
    })
    
  }


  save(){
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var pm_documents_data = [];
      pm_documents_data = this.form.value.documents;

      const data = {
        project:this.projectId,
        machinary_type:this.masterId,
        types:this.form.value.types,
        model_of_machinery:this.form.value.model_of_machinery,
        fuel_consumption:this.form.value.fuel_consumption,
        quantity:this.form.value.quantity,
        capacity:this.form.value.capacity,
        rate_per_product:this.form.value.rate_per_product,
        operator_required:this.form.value.operator_required,
        operator_name:this.form.value.operator_name,
        operator_contact_no:this.form.value.operator_contact_no,
        operator_salary:this.form.value.operator_salary,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost
      }

      this.pmDocumentList = [];
      this.perExecutionService.addDetailsByPandMType(data).subscribe(
        res => {
       //   console.log(res);
          var forkArray = [];

          pm_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.pandm_document, x.pandm_document['name']);
              forkArray.push(this.perExecutionService.addDetailsDocumentPandM(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.pmDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        })

      //console.log(data);

    }else
    {
      //console.log("error");
      this.markFormGroupTouched(this.form);
    
    }
  }

  getPmDetails(){
    this.perExecutionService.getDetailsByTypePandM(this.projectId,this.masterId).subscribe(
      res => {
       if(res['result'][0]){
        this.pmDetail = res['result'][0];
        this.pmDocumentList = res['result'][0].document_details;
       // console.log(this.pmDetail);

        var start_date = new Date(this.pmDetail['requirment_s_date'])
            this.pmDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.pmDetail['requirment_e_date'])
            this.pmDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }

          this.form.patchValue({
            p_and_m:this.masterId,
            operator_required:this.pmDetail.operator_required
          })
          
          this.pmDetail.budgeted_cost = parseFloat(this.pmDetail.budgeted_cost).toFixed(2);
          this.pmDetail.executed_cost = parseFloat(this.pmDetail.executed_cost).toFixed(2);
          this.pmDetail.rate_per_product = parseFloat(this.pmDetail.rate_per_product).toFixed(2);

          this.operator = this.pmDetail.operator_required==true?'yes':'no';
          this.isOperator = this.pmDetail.operator_required;
          
          
       }
       
        this.loading = LoadingState.Ready;
      },
      error =>{
        
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  update(){
    if(this.form.valid){

      this.loading = LoadingState.Processing;
      var pm_documents_data = [];
      pm_documents_data = this.form.value.documents;

      const updateData = {
        project:this.projectId,
        machinary_type:this.masterId,
        types:this.form.value.types,
        model_of_machinery:this.form.value.model_of_machinery,
        fuel_consumption:this.form.value.fuel_consumption,
        quantity:this.form.value.quantity,
        capacity:this.form.value.capacity,
        rate_per_product:this.form.value.rate_per_product,
        operator_required:this.form.value.operator_required,
        operator_name:this.form.value.operator_name,
        operator_contact_no:this.form.value.operator_contact_no,
        operator_salary:this.form.value.operator_salary,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost
      }
    //console.log(updateData);

  this.perExecutionService.addDetailsByPandMType(updateData).subscribe(
    res =>{
      // console.log(res);
      // console.log(res.tiffin_box_types.id)
     
      if(this.form.value.documents.length > 0){
        var forkArray = [];
        pm_documents_data.forEach(x => {
          const formData: any = new FormData();
          formData.append("project",this.projectId);
          formData.append("module_id", res.id);
          formData.append("document_name", x.document_name);
          formData.append("document", x.pandm_document, x.pandm_document['name']);
          forkArray.push(this.perExecutionService.addDetailsDocumentPandM(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.pmDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
       }
     
      this.loading = LoadingState.Ready;
      this.toastr.success('Data has been saved','',{
        timeOut: 3000,
        });
    },
    error =>{
      this.loading = LoadingState.Ready;
      this.toastr.error('Something went wrong', '', {
        timeOut: 3000,
        });
    })
  }
  else{
    this.markFormGroupTouched(this.form);
    //console.log("error");
  }
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      pandm_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);

  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.pmDocumentList[index]['isEdit'] = true
    this.pmDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.pmDocumentList[index]['document_name'] == '') {
      this.pmDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.pmDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.pmDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editDetailsDocumentPandM(this.pmDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.pmDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteDetailsDocumentPandM(this.pmDocumentList[index]['id']).subscribe(
        res => {
          this.pmDocumentList.splice(index, 1)
          // console.log(res)
          this.loading = LoadingState.Ready
          
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })
   
  }

  onSelectFile(event, index){
   // console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          pandm_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
