import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BatchingPlantService {

  
  constructor(
    private http: HttpClient
  ) {
    
  }

  addBatchReportEntry(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/batching/batching_entry/add/', data)
  }
  
  getBatchReportList(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/batching_entry/add/?'+params)
  }

  getBatchReportDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/batching_entry/edit/'+id+'/')
  }

  deleteBatchReportDetails(id): Observable<any> {
    return this.http.delete(environment.apiEndpoint + 'pms/batching/batching_entry/edit/'+id+'/')
  }

  updateBatchReportDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/batching/batching_entry/edit/' + id + '/', data)
  }

  updateStausBatchReportDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/batching/batching_entry/change_status/' + id + '/', data)
  }

  getBatchReportOutput(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/batching_entry/report/?'+params)
  }

  getBatchReportOutputDownloadExcel(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/batching_entry/report_download/?'+params)
  }

  getBatchReportListDownloadExcel(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/batching_entry/list_download/?'+params)
  }

}
