import { Component, Input, OnInit } from '@angular/core';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingService } from '../../../../core/services/setting.service';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-external-user-edit',
  templateUrl: './external-user-edit.component.html',
  styleUrls: ['./external-user-edit.component.scss']
})
export class ExternalUserEditComponent implements OnInit {

  @Input() userType: string;
  @Input() externalUserID: number;
  userTypeStr:string;
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  externalUserTypeList: any = [];
  externalUserDetails: any;
  visibleKey: boolean;
  externalUserDocumentList: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  
  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
   // console.log(this.userType);
   // console.log(this.externalUserID);
  
    if(this.userType=="vendor")
    {       
      this.userTypeStr = "Vendor"
    }
    if(this.userType=="contractor")
    {       
      this.userTypeStr = "Contractor"
    }
    if(this.userType=="partner")
    {       
      this.userTypeStr = "Partner"
    }
    if(this.userType=="operator")
    {       
      this.userTypeStr = "Operator"
    }
    if(this.userType=="crusher")
    {       
      this.userTypeStr = "Crusher"
    }
    this.form = this.formBuilder.group({
      contact_person_name: ['', Validators.required],
      user_type: ['', Validators.required],
      contact_no: [''],
      email: [''],
      address: [''],
      organisation_name: ['', Validators.required],
      // 
      code: [''],
      adhar_no: [''],
      adhar_doc: [''],
      bank_ac_no: [''],
      cancelled_cheque_doc: [''],
      gst_no: [''],
      gst_doc: [''],
      pan_no: [''],
      pan_doc: [''],
      salary: [''],
      trade_licence_doc: [''],
      // 
      documents: this.formBuilder.array([])
    });
    
    this.getExternalUserDetails();
    this.getExternalUserDocList();
  }

  getExternalUserDocList() {
    this.settingService.getExternalUserDocList(this.externalUserID).subscribe(
      res => {
      //  console.log(res)
        this.externalUserDocumentList = res;
      },
      error => {
        console.log(error)
      }
    )
  }

  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getExternalUserDetails() {
    this.settingService.getExternalUserDetails(this.externalUserID).subscribe(
      res => {
       // console.log(res)
        this.externalUserDetails = res;
        this.form.patchValue({
          user_type: this.externalUserDetails.user_type
        })
        this.visibleKey = true;
        this.loading = LoadingState.Ready
      },
      error => {
      //  console.log(error)
        this.visibleKey = true;
        this.loading = LoadingState.Ready
      }
    )
  }

  download_document(doc) {
    window.open(doc);
  }

  create_document() {
    return this.formBuilder.group({
      document_name: [''],
      file_hidden: [null],
      document: null
    });
  }

  add_document() {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.create_document());    
  }

  delete_document(index: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Are you sure to delete this document?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing
        const control = <FormArray>this.form.controls['documents'];
        control.removeAt(index);
        this.toastr.success('Document deleted','',{
          timeOut: 3000,
        });
      }
      this.dialogRef = null;
    });       
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  onSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          file_hidden: event.target.files[0]['name'],
          document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectDoc(event, type) {    
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        this.form.patchValue({
          [type]: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }  

  save() {    
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var documents_data = [];
      documents_data = this.form.value.documents;      
      const data: any = new FormData();
      data.append("contact_person_name", this.form.value.contact_person_name);
      data.append("user_type", this.form.value.user_type);
      data.append("contact_no", this.form.value.contact_no);
      data.append("email", this.form.value.email);
      data.append("organisation_name", this.form.value.organisation_name);
      data.append("code", this.form.value.code);
      data.append("adhar_no", this.form.value.adhar_no);
      if (this.form.value.adhar_doc != '') {
        data.append("adhar_doc", this.form.value.adhar_doc, this.form.value.adhar_doc['name']);
      }
      
      data.append("bank_ac_no", this.form.value.bank_ac_no);
      if (this.form.value.cancelled_cheque_doc != '') {
        data.append("cancelled_cheque_doc", this.form.value.cancelled_cheque_doc, this.form.value.cancelled_cheque_doc['name']);
      }
      
      data.append("gst_no", this.form.value.gst_no);
      if (this.form.value.gst_doc != '') {
        data.append("gst_doc", this.form.value.gst_doc, this.form.value.gst_doc['name']);
      }
      
      data.append("pan_no", this.form.value.pan_no);
      if (this.form.value.pan_doc != '') {
        data.append("pan_doc", this.form.value.pan_doc, this.form.value.pan_doc['name']);
      }
      
      data.append("salary", this.form.value.salary);
      if (this.form.value.trade_licence_doc != '') {
        data.append("trade_licence_doc", this.form.value.trade_licence_doc, this.form.value.trade_licence_doc['name']);
      }
      
      data.append("address", this.form.value.address);
      this.settingService.editExternalUser(this.externalUserID, data).subscribe(
        res => {
         // console.log(res);
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
          if (documents_data.length > 0) {
            var forkArray = [];
            documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("external_user", this.externalUserID);
              formData.append("document_name", x.document_name);
              formData.append("document", x.document, x.document['name']);
              forkArray.push(this.settingService.addExternalUserDoc(formData))
            })
            forkJoin(forkArray).subscribe(
              result => {
                // console.log(result)
                if(this.userType=="vendor")
                {       
                  this.router.navigateByUrl('/vendor/vendor-list');
                }
                if(this.userType=="contractor")
                {       
                  this.router.navigateByUrl('/contractor/contractor-list');
                }
                if(this.userType=="partner")
                {       
                  this.router.navigateByUrl('/partners/partner-list');
                }
                if(this.userType=="operator")
                {       
                  this.router.navigateByUrl('/operator/operator-list');
                }
                if(this.userType=="crusher")
                {       
                  this.router.navigateByUrl('/crusher/crusher-list');
                }
                this.loading = LoadingState.Ready;
               
              },
              err => {
                this.loading = LoadingState.Ready;
                // console.log(err)
               
              }
            )
          }
          else {
            if(this.userType=="vendor")
            {   
              this.router.navigateByUrl('/vendor/vendor-list');
            }
            if(this.userType=="contractor")
            {       
              this.router.navigateByUrl('/contractor/contractor-list');
            }
            if(this.userType=="partner")
            {       
              this.router.navigateByUrl('/partners/partner-list');
            }
            if(this.userType=="operator")
            {       
              this.router.navigateByUrl('/operator/operator-list');
            }
            if(this.userType=="crusher")
            {       
              this.router.navigateByUrl('/crusher/crusher-list');
            }

            this.loading = LoadingState.Ready;
          }

        },
        error => {
        //  console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  getDoc(file: string) {
    var filestr = file.split('/')
    var fileName = filestr[filestr.length - 1];    
    return fileName;
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    } else {
      return 'assets/img/pdf.png';
    }
  }

  deleteExternalUserDoc(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Are you sure to delete this document?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing
        this.settingService.deleteExternalUserDoc(this.externalUserDocumentList[index]['id']).subscribe(
          res => {
            this.externalUserDocumentList.splice(index, 1)          
            this.loading = LoadingState.Ready
            this.toastr.success('Document deleted','',{
              timeOut: 3000,
            });
          },
          error => {          
            this.loading = LoadingState.Ready
            this.toastr.error("Internal problem occurred",'',{
              timeOut: 3000,
            });
          }
        )
        
      }
      this.dialogRef = null;
    });    
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
