import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddMaterialModalComponent } from '../add-material-modal/add-material-modal.component';
import { forkJoin } from 'rxjs';
import { SettingService } from '../../../../core/services/setting.service';
@Component({
  selector: 'app-resource-material-tab',
  templateUrl: './resource-material-tab.component.html',
  styleUrls: ['./resource-material-tab.component.scss']
})
export class ResourceMaterialTabComponent implements OnInit {
  @Input() tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  tenderMaterialList: any = [];
  type: string;
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService,
    private settingService: SettingService
  ) { }

  ngOnInit() {
    this.loadData();
    this.type = "vendor";
  }

  loadData() {
    var forkArray = [];
    forkArray.push(this.settingService.getMaterialList())
    forkJoin(forkArray).subscribe(
      (res: any[]) => {
        //console.log(res)
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.tenderMaterialList = res[i];
          }
        }
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }



  openMaterialModal() {
    let dialogRef = this.dialog.open(AddMaterialModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderMaterialList.push(result['data'])
      }
    })
  }

  editMaterial(i) {
    let dialogRef = this.dialog.open(AddMaterialModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, materialDetailsData: this.tenderMaterialList[i] }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderMaterialList[i] = result['data']
      }
    })
  }

}
