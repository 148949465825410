import { Component, OnInit, Input } from '@angular/core';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { LoadingState } from '../../../components/loading/loading.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-weekly-report-labour',
  templateUrl: './weekly-report-labour.component.html',
  styleUrls: ['./weekly-report-labour.component.scss']
})
export class WeeklyReportLabourComponent implements OnInit {

  @Input() currentmaintab: string;
  @Input() sweekstartdate: any;
  @Input() sweekenddate: any;
  @Input() projectid: number;
  @Input() siteid: number;
  public loading: LoadingState = LoadingState.NotReady;
  progressparam: any = '';
  reportarr: any = [];

  constructor(
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getLabourReports()
  }

  getLabourReports() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'weekly') {
      if (this.sweekstartdate) {
        let sdate = this.sweekstartdate;
        let edate = this.sweekenddate;
        this.progressparam = 'start_date=' + sdate + '&end_date=' + edate + '&site_location=' + this.siteid + '&project=' + this.projectid;//aa //aa
      }
      else {
        this.progressparam = '';
      }
    }
    // console.log(this.progressparam)
    if (this.projectid && this.siteid && this.progressparam) {
      if(this.currentmaintab === 'weekly') {
        this.reportService.getLabourWeeklyReportData(this.progressparam).subscribe(
          data => {
            var res: any = data['results'];
            this.reportarr = [];
            this.reportarr = res;
            this.loading = LoadingState.Ready;
          },
          error => {
            this.loading = LoadingState.Ready;
            console.log(error)
          }
        );
      }
    }
    else {
      this.loading = LoadingState.Ready;
    }

  }

  excelDownload() {
    this.loading = LoadingState.Processing;
    if (this.sweekstartdate) {
      let sdate = this.sweekstartdate;
      let edate = this.sweekenddate;
      this.progressparam = 'is_download=yes&start_date=' + sdate + '&end_date=' + edate + '&site_location=' + this.siteid + '&project=' + this.projectid;
    }
    else {
      this.progressparam = '';
    }
    if (this.projectid && this.siteid && this.progressparam) {
      this.reportService.getLabourWeeklyReportData(this.progressparam).subscribe(
        data => {
          window.open(data.url, '_blank');
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready;
          console.log(error)
        }
      );
    }
    else {
      this.loading = LoadingState.Ready;
    }
  }

}
