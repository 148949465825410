import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadingState } from '../../../components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-daily-labour',
  templateUrl: './daily-labour.component.html',
  styleUrls: ['./daily-labour.component.scss']
})
export class DailyLabourComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  labourform: FormGroup;
  @Input() sitearr: any = [];
  @Input() machinearr: any = [];
  @Input() unitarr: any = [];
  @Input() employeeList: any = [];
  @Input() labourDetailsData: any;
  @Input() contractorList: any = [];
  @Input() projectList: any = [];
  @Input() boqList: any = [];
  @Input() activityList: any = [];
  @Output() setDetailsData = new EventEmitter<any>();
  @Output() setSiteData = new EventEmitter<any>();
  @Output() setBoqData = new EventEmitter<any>();

  boqDescription = null;

  constructor(private formBuilder: FormBuilder,
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.labourform = this.formBuilder.group({
      type_of_report: 2,
      project_id: [null, Validators.required],
      site_location: [null, Validators.required],
      date_entry: [null, Validators.required],
      weather: ['', Validators.required],
      date_of_completion: [null, Validators.required],
      milestone_to_be_completed: ['', Validators.required],
      boq: ['', Validators.required],
      rainfall_from: [''],
      rainfall_to: [''],
      temperature_in_cel: ['', Validators.required],
      ir_problem: [null],
      local_issue: [null],
      client_visit: [null],
      administrative_issue: [null],
      document_ir: [null],
      document_li: [null],
      document_cv: [null],
      document_ai: [null],
      labour_data: this.formBuilder.array([this.addLabourDataFormGroup()])
    });

    if (this.labourDetailsData == undefined) {
      this.labourDetailsData = {
        project_id: null,
        site_location: null,
        date_entry: null,
        date_of_completion: null,
        weather: '',
        milestone_to_be_completed: '',
        boq: '',
        rainfall_from: '',
        rainfall_to: '',
        temperature_in_cel: '',
        ir_problem: null,
        local_issue: null,
        client_visit: null,
        administrative_issue: null,
      }
    } else {
      if(this.labourDetailsData.date_entry != null) {
        this.onLabourDateSelected();
      }
    }
    this.loading = LoadingState.Ready;
  }

  /* FormGroup custom validators with range value
    Author - Biswajit Manna */
  addLabourDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      name_of_contractor_id: [null, Validators.required],
      contractor_type: [null, Validators.required],
      number_skilled: ['', Validators.required],
      number_unskilled: ['', Validators.required],
      number_semi_skilled: ['', Validators.required],
      activity_id: [null, Validators.required],
      details_activity: [''],
      start_time: [null, Validators.required],
      end_time: [null, Validators.required],
      overtime_man_days_skilled : [null],
      overtime_man_days_semi_skilled : [null],
      overtime_man_days_unskilled : [null],
      remarks: [''],
      date_entry: [null],
      date_of_completion: [null]
    }, { validators: [this.check_labour_end_time] });
  }
  /* FormGroup custom validators with range value
    Author - Biswajit Manna */
  check_labour_end_time(group: FormGroup) {
    if (group.controls.start_time.value != null && group.controls.end_time.value != null) {
      let start_time = group.controls.start_time.value['hour'] + ':' + group.controls.start_time.value['minute'] + ':' + group.controls.start_time.value['second'];
      let end_time = group.controls.end_time.value['hour'] + ':' + group.controls.end_time.value['minute'] + ':' + group.controls.end_time.value['second'];
      var date_entry = group.controls.date_entry.value;
      var date_of_completion = group.controls.date_of_completion.value;
      if (date_entry != null && date_of_completion != null) {
        date_entry = group.controls.date_entry.value;
        date_of_completion = group.controls.date_of_completion.value;
        date_entry = date_entry.month + '/' + date_entry.day + '/' + date_entry.year;
        date_of_completion = date_of_completion.month + '/' + date_of_completion.day + '/' + date_of_completion.year;
        return Date.parse(date_of_completion + ' ' + end_time) > Date.parse(date_entry + ' ' + start_time) ? null : { notGreaterLabour: true }
      }
      else {
        return Date.parse('1/1/2020 ' + end_time) > Date.parse('1/1/2020 ' + start_time) ? null : { notGreaterLabour: true }
      }
    }
    else {
      return false;
    }
  }

  weatherChange(e) {
    this.setDetailsData.emit(this.labourDetailsData)
  }
  milestoneChange(e) {
    this.setDetailsData.emit(this.labourDetailsData)
  }

  labourSiteOnchange(val) {
    this.setSiteData.emit(val)
  }

  onDateCompletionSelected() {
    if (this.labourDetailsData.date_of_completion) {
      this.setDetailsData.emit(this.labourDetailsData)
    }
  }
  onLabourDateSelected() {
    if (this.labourDetailsData.date_entry) {
      this.setDetailsData.emit(this.labourDetailsData)
      var date = this.settingService.getProperDate(this.labourDetailsData.date_entry)
      var str = 'date=' + date.toISOString().substr(0, 10);
      if (this.labourDetailsData.site_location != null && this.labourDetailsData.project_id != null) {
        this.reportService.fetchDailyProgressCommonData(this.labourDetailsData.project_id, this.labourDetailsData.site_location, str, 2).subscribe(
          res => {
            //console.log(res)
            if (res['result'] != '') {
              var date = new Date(res['result']['date_of_completion'])
              this.labourDetailsData.date_of_completion = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
              }
              this.labourDetailsData.weather = res['result']['weather'];
              this.labourDetailsData.milestone_to_be_completed = res['result']['milestone_to_be_completed'];
              this.labourDetailsData.temperature_in_cel = res['result']['temperature_in_cel'];
              this.labourDetailsData.ir_problem = res['result']['ir_problem'];
              this.labourDetailsData.local_issue = res['result']['local_issue'];
              this.labourDetailsData.client_visit = res['result']['client_visit'];
              this.labourDetailsData.administrative_issue = res['result']['administrative_issue'];
              this.labourDetailsData.boq = res['result']['boq'];
              this.onBOQSelection(this.labourDetailsData.boq);
            }
            else {
              this.labourDetailsData.date_of_completion = null;
              this.labourDetailsData.weather = '';
              this.labourDetailsData.milestone_to_be_completed = '';
              this.labourDetailsData.temperature_in_cel = '';
              this.labourDetailsData.ir_problem = null;
              this.labourDetailsData.local_issue = null;
              this.labourDetailsData.client_visit = null;
              this.labourDetailsData.administrative_issue = null;
              this.labourDetailsData.boq = null;
              this.boqDescription = '';
            }
            this.setDetailsData.emit(this.labourDetailsData)
          },
          error => {
            //console.log(error)
          }
        )
      }
    }
  }

  getMinDate() {
    const current = new Date();
    if (current.getMonth() == 0 && current.getDate() == 1) {
      return {
        year: current.getFullYear() - 1,
        month: 12,
        day: 31
      };
    }
    else if (current.getDate() == 1) {
      var dateObj = new Date();
      dateObj.setDate(0)
      return {
        year: dateObj.getFullYear(),
        month: dateObj.getMonth() + 1,
        day: dateObj.getDate()
      };
    }
    else {
      return {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() - 1
      };
    }

  }

  getCompletionMinDate(form: FormGroup) {
    if (form.value.date_entry) {
      return {
        year: form.value.date_entry.year,
        month: form.value.date_entry.month,
        day: form.value.date_entry.day
      };
    }
    else {
      const current = new Date();
      return {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() - 1
      };
    }
  }


  removeLabourDataFormGroup(index) {
    const control = <FormArray>this.labourform.controls['labour_data'];
    control.removeAt(index);
  }

  addLabourButtonClick(): void {
    const control = <FormArray>this.labourform.controls['labour_data'];
    control.push(this.addLabourDataFormGroup());
  }

  getFormArrayControl(form) {
    return form.get('labour_data').controls
  }

  saveLabourdata() {
    this.labourform.patchValue({
      type_of_report: 2
    })

    if (this.labourform.valid) {
      this.loading = LoadingState.Processing;
      const formData: any = new FormData();
      formData.append('date_entry', this.settingService.getProperDate(this.labourform.value.date_entry).toISOString());
      formData.append('date_of_completion', this.settingService.getProperDate(this.labourform.value.date_of_completion).toISOString());
      if(this.labourform.value.rainfall_from != '' && this.labourform.value.rainfall_from != null) {
        formData.append('rainfall_from', this.labourform.value.rainfall_from.hour + ':' + this.labourform.value.rainfall_from.minute);
      } 
      if(this.labourform.value.rainfall_to != '' && this.labourform.value.rainfall_to != null) {
        formData.append('rainfall_to', this.labourform.value.rainfall_to.hour + ':' + this.labourform.value.rainfall_to.minute);
      }
      formData.append('type_of_report', 2);
      formData.append('project_id', this.labourform.value.project_id);
      formData.append('site_location', this.labourform.value.site_location);
      formData.append('weather', this.labourform.value.weather);
      formData.append('milestone_to_be_completed', this.labourform.value.milestone_to_be_completed);
      formData.append('boq', this.labourform.value.boq);
      formData.append('temperature_in_cel', this.labourform.value.temperature_in_cel);
      formData.append('project_id', this.labourform.value.project_id);
      if(this.labourform.value.document_ir != null && this.labourform.value.document_ir != undefined){
        formData.append('ir_problem',this.labourform.value.document_ir);
      }
      if(this.labourform.value.document_li != null && this.labourform.value.document_li != undefined){
        formData.append('local_issue',this.labourform.value.document_li);
      }
      if(this.labourform.value.document_cv != null && this.labourform.value.document_cv != undefined){
        formData.append('client_visit',this.labourform.value.document_cv);
      }
      if(this.labourform.value.document_ai != null && this.labourform.value.document_ai != undefined){
        formData.append('administrative_issue',this.labourform.value.document_ai);
      }
      var labour_data = [];
      this.labourform.value.labour_data.forEach(x => {
        var activity_details_id = x.activity_id
        var activity_details = []
        activity_details_id.forEach(x => {
          var d = {
            activity_id: x
          }
          activity_details.push(d)
        })
        labour_data.push({
          activity_details: activity_details,
          activity_id: null,
          start_time: x.start_time.hour + ':' + x.start_time.minute + ':' + x.start_time.second,
          end_time: x.end_time.hour + ':' + x.end_time.minute + ':' + x.end_time.second,
          name_of_contractor_id: x.name_of_contractor_id,
          contractor_type: x.contractor_type,
          number_skilled: x.number_skilled,
          number_unskilled: x.number_unskilled,
          number_semi_skilled: x.number_semi_skilled,
          overtime_man_days_skilled: x.overtime_man_days_skilled,
          overtime_man_days_semi_skilled: x.overtime_man_days_semi_skilled,
          overtime_man_days_unskilled: x.overtime_man_days_unskilled,
          remarks: x.remarks
        })
      });
      formData.append('labour_data', JSON.stringify(labour_data));
      this.reportService.saveLabourData(formData).subscribe(
        data => {
          this.labourform.reset();
          this.boqDescription = '';
          var res: any = data;
          this.toastr.success('Data has been succesfully saved !', '', {
            timeOut: 3000,
          });
          // this.labourform.reset()
          var objData = Object.assign([], this.labourform.value.labour_data)
          for (var i = objData.length - 1; i >= 0; i--) {
            //console.log(i)
            this.removeLabourDataFormGroup(i)
          }
          this.addLabourButtonClick();
          this.labourform.reset();
          this.loading = LoadingState.Ready
        },
        error => {
          //console.log(error)
          this.toastr.error('Internal error occurred !', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      );
    }
    else {
      this.markFormGroupTouched(this.labourform);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) { console.log(formGroup);
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  onDocumentSelect(event, fileFor) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg"
      || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpj"
      || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/doc" || event.target.files[0].type == "application/docx"
      || event.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      || event.target.files[0].type == "application/msword"
    ) {
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event) => {

          }
          if(fileFor == "ai") {
            this.labourform.patchValue({
              document_ai: event.target.files[0]
            })
          } else if(fileFor == "li") {
            this.labourform.patchValue({
              document_li: event.target.files[0]
            })
          } else if(fileFor == "ir") {
            this.labourform.patchValue({
              document_ir: event.target.files[0]
            })
          } else if(fileFor == "cv") {
            this.labourform.patchValue({
              document_cv: event.target.files[0]
            })
          }
          
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      this.toastr.error('Image/PDF/Word file type allowed', '', {
        timeOut: 3000,
      });
    }
  }

  onBOQSelection(val) {
    if(val) {
      this.boqDescription = this.boqList.filter(x => x.id == val)[0]['item_description'];
    } else {
      this.boqDescription = null
    }
  }

  viewDoc(item: any) {
    window.open(item, '', 'height=700,width=800,scrollbars=yes');
  }

  removeDoc(source) {
    this.loading = LoadingState.Processing;
    if(source == 'ir') {
      this.labourDetailsData.ir_problem = null;
    } else if (source == 'cv') {
      this.labourDetailsData.client_visit = null;
    } else if (source == 'li') {
      this.labourDetailsData.local_issue = null;
    } else if (source == 'ai') {
      this.labourDetailsData.administrative_issue = null;
    }
    this.loading = LoadingState.Ready;
  }
}
