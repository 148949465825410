import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalService } from "../core/services/local.service";
import * as Globals from "../core/globals";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-auto-login",
  templateUrl: "./auto-login.component.html",
  styleUrls: ["./auto-login.component.scss"],
})
export class AutoLoginComponent implements OnInit {
  isLoading: boolean = false;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private localService: LocalService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.route.paramMap.subscribe((params) => {
      this.handleLoginData(params.get("loginData"));
    });
  }

  private handleLoginData(loginData: string | null): void {
    if (!loginData) {
      this.toastr.error("No login data provided", "", { timeOut: 2000 });
      this.isLoading = false;
      return;
    }

    try {
      this.data = JSON.parse(atob(loginData));
      this.processLogin();
    } catch (error) {
      console.error("Error parsing login data:", error);
      this.toastr.error("Error parsing login data", "", { timeOut: 2000 });
      this.isLoading = false;
      this.handleSessionExpire();
    }
  }

  private processLogin(): void {
    this.setLocalStorageValues({
      isLoggedin: true,
      loggedinToken: this.data.token,
      userid: this.data.user_id,
      currentModuleId: "1",
      is_superuser: true,
      module_access: this.getModuleAccess(),
    });

    this.updateCurrentModuleAccess();

    setTimeout(() => {
      this.isLoading = false;
      this.router.navigate(["/"]);
    }, 1000); // Simulate some delay
  }

  private updateCurrentModuleAccess(): void {
    const moduleAccess = JSON.parse(
      this.localService.getJsonValue("module_access") || "{}"
    );
    const currentModuleId = this.localService.getJsonValue("currentModuleId");

    if (currentModuleId && moduleAccess[currentModuleId]) {
      this.localService.setJsonValue(
        "current_module_access",
        JSON.stringify(moduleAccess[currentModuleId])
      );
    }
  }

  private setLocalStorageValues(values: Record<string, any>): void {
    Object.entries(values).forEach(([key, value]) =>
      this.localService.setJsonValue(key, value)
    );
  }

  private getModuleAccess(): string {
    return JSON.stringify([
      {
        id: 959,
        user_type_details: { id: 3, name: "Module User" },
        module: {
          id: 3,
          cm_name: "E-Task",
          cm_url: "etask",
          cm_icon:
            "http://shyamsteel.tech:8000/media/core/TCoreModule/Images/e_task.png",
        },
        role: { id: 151, cr_name: "E-Task User", cr_parent_id: 146 },
        object_details: [],
      },
      // Add other module access objects here
    ]);
  }

  private handleSessionExpire(): void {
    this.localService.clearToken();
    this.router.navigate(["/session-expire"]);
    window.open(`${Globals.baseUrl}/session-expire`, "_self");
  }
}
