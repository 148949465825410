import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TravelAndTourService {
  constructor(
    private http: HttpClient
  ) {

  }

  addTourAndTravel(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tour_and_travel_expense_add/', data)
  }

  addDocumentTourAndTravel(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/tour_and_travel_expense_add_document/', data)
  }

  myTourAndTravelApprovalList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'employee_tour_and_travel_expense_approval_list/?' + params)
  }

  exportMyTourandTravel(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/employee_tour_and_travel_expense_list/download/?' + params)
  }

  getTourAndTravelApprovalList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tour_and_travel_expense_approval_list/?' + params)
  }

  getTourAndTravelApprovalListNew(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/tour_and_travel_expense/list/?' + params)
  }

  getTourAndTravelExp(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tour_and_travel_expense_add/?id=' + id)
  }

  addTourAndTravelApproval(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tour_and_travel_expense_master_approval/' + id + '/', data)
  }

  tourAndTravelApprove(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/tour_and_travel_expense_master_approval/', data)
  }

  tourAndTravelReject(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/tour_and_travel_expense_master_reject/', data)
  }

  getTourAndTravelReports(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/tour_and_travel_expense/list/?' + params);
  }

  exportReportTourandTravel(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/employee_tour_and_travel_expense_report/download/?' + params)
  }

  getToursAndTravelsAccountApprover() {
    return this.http.get(environment.apiEndpoint + 'pms/tour_account_user/add/');
  }

  getToursAndTravelsHOApprover() {
    return this.http.get(environment.apiEndpoint + 'pms/tour_ho_user/add/');
  }

  addToursAndTravelsAccountApprover(data) {
    return this.http.post(environment.apiEndpoint + 'pms/tour_account_user/add/', data)
  }

  updateToursAndTravelsAccountApprover(data, id) {
    return this.http.put(environment.apiEndpoint + 'pms/tour_account_user/edit/' + id + '/', data)
  }

  addToursAndTravelsHOApprover(data) {
    return this.http.post(environment.apiEndpoint + 'pms/tour_ho_user/add/', data)
  }

  updateToursAndTravelsHOApprover(data, id) {
    return this.http.put(environment.apiEndpoint + 'pms/tour_ho_user/edit/' + id + '/', data)
  }


  //Payment

  getTourAndTravelPaymentApproval(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/tour_and_travel_expense/list/?' + params)
  }

  tourApprovalPaymentUpdate(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/tour_and_travel_expense/payment/update/', data)
  }






}
