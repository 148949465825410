import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-contractor-finalization-tab',
  templateUrl: './contractor-finalization-tab.component.html',
  styleUrls: ['./contractor-finalization-tab.component.scss']
})
export class ContractorFinalizationTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  machineryList:any = [];
  contractorList:any= [];
  contractorFinalizationList:any= [];
  contractorFinalizationDetail:any = [];
  approveStatus:any =[];

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
  
  ) { }

  ngOnInit() {
  // console.log(this.projectId);
  this.form = this.formBuilder.group({
    project: [this.projectId],
    machinery:[null,Validators.required],
    contractor: [null, Validators.required]
  })

    if(this.projectId){
      this.loadData();
      this.getContractorFinalization();
      this.getStatus();

    }
  }

  save(){
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      const data = {
        project: this.projectId,
        machinery:this.form.value.machinery,
        contractor:this.form.value.contractor
      }
      this.perExecutionService.addContractorFinalization(data).subscribe(
        res =>{
         // console.log(res);
          this.loading = LoadingState.Ready;
          this.getContractorFinalization();
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
         // console.log(error);
        }
      )

      
    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  loadData() {
    var forkArray = [];

    forkArray.push(this.perExecutionService.getMachineryListWp())
    forkArray.push(this.perExecutionService.getExternalUserList())
    
    
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
        //console.log(result);
       for (var i = 0; i < result.length; i++) {
        if (i == 0) {
          this.machineryList = result[i];
        }
        if (i == 1) {
          this.contractorList = result[i];
        }
        
       }
        this.loading = LoadingState.Ready;
      },
      err => {
       // console.log(err);
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal server error', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getContractorFinalization(){
    this.perExecutionService.getContractorFinalizationList(this.projectId).subscribe(
      res=>{
        this.contractorFinalizationList = res['result'];
      },
      error=>{
      // console.log(error);
        
      }
    )
  }

  deleteContractorFinalization(id:number){
    this.loading = LoadingState.Processing;
    this.perExecutionService.deleteContractorFinalizationList(id).subscribe(
      res=>{
        //console.log(res);
        this.loading = LoadingState.Ready;
        this.getContractorFinalization();
        this.toastr.success('Data has been Deleted','',{
          timeOut: 3000,
        });
      },
      error=>{
       // console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  editContractorFinalization(data:any){
    this.contractorFinalizationDetail = data;
    //console.log(data);
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
       // console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }





}
