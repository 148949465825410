import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalService } from './local.service'
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  httpHeaderOptions: any;
  constructor(
    private http: HttpClient,
    private localService: LocalService,
  ) {

    this.httpHeaderOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    }
  }

  havePermission(section, parentSection) {
    var permissionLevel = 0;
    var is_superuser = this.localService.getJsonValue('is_superuser');
    if (is_superuser) {
      permissionLevel = 3;
    }
    else {
      var current_module_access = JSON.parse(this.localService.getJsonValue('current_module_access'));
      if (parentSection == '') {
        var index = current_module_access.object_details.findIndex(k => k.name == section);
        if (index != -1) {
          var permission = current_module_access.object_details[index].permission;
          permissionLevel = permission
        }
        else {
          permissionLevel = 0;
        }
      }
      else {

        var index = current_module_access.object_details.findIndex(k => k.name == parentSection);
        if (index != -1) {

          var childIndex = current_module_access.object_details[index].child_details.findIndex(j => j.cot_name == section);

          if (childIndex != -1) {

            var permission = current_module_access.object_details[index].child_details[childIndex].permission;

            permissionLevel = permission

          }
          else {
            permissionLevel = 0;
          }
        }
        else {
          permissionLevel = 0;
        }
      }
    }

    return permissionLevel;
  }
  getProperDate(date) {
    date = new Date(date.year, date.month - 1, date.day);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }
  getOwlProperDate(date) {
    date = new Date(date)
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }
  // setDate(date){
  //   date = new Date(date)
  //   return date.year + "-" + date.month + "-" + date.day;
  // }
  // setTime(time){
  //   return time.hour + ":" + time.minute + ":" + time.second;
  // }
  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }
  formatTime(time) {
    var date = new Date().toISOString().split('T')[0] + ' ' + time
    return new Date(date)
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  
  formatTimeHMS(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    return strTime;
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatDateForPdf(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  formatHMS(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds;
    return strTime;
  }

  addMachineryWorkingCategory(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'machineries_working_category_add/', data)
  }

  getMachineryWorkingCategoryList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_working_category_add/')
  }

  getMachineryWorkingCategoryDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_working_category_edit/' + id + '/')
  }

  editMachineryWorkingCategory(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machineries_working_category_edit/' + id + '/', data)
  }

  deleteMachineryWorkingCategory(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machineries_working_category_delete/' + id + '/', {})
  }

  //
  getRentTypeList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machinary_rented_type_master_add/')
  }

  addMachinery(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'machineries_add/', data)
  }

  getMachineryList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_list/?' + params)
  }

  getMachineryListExcelDownload(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_list/download/?' + params)
  }

  getMachineryListByProject(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_list/?' + params)
  }

  getMachineryListWp(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_wp_list/')
  }

  getBoqList(data: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/boq/list/?' + data)
  }

  getShortageStatus(data: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/v2/execution_daily_progress/shortfall/check/?' + data)
  }

  getMachineryDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_edit/' + id + '/')
  }

  editMachinery(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machineries_edit/' + id + '/', data)
  }

  deleteMachinery(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machineries_delete/' + id + '/', {})
  }

  addMachineryDoc(data: any) {
    return this.http.post(environment.apiEndpoint + 'machineries_details_document_add/', data)
  }

  getMachineryDocList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_details_document_list/' + id + '/')
  }

  deleteMachineryDoc(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machineries_details_document_delete/' + id + '/', {})
  }
  //
  getExternalUserTypeList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_type_add/')
  }

  addExternalUser(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'external_users_add/', data)
  }

  getExternalUserList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_add/')
  }

  getExternalUserDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_edit/' + id + '/')
  }

  editExternalUser(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'external_users_edit/' + id + '/', data)
  }

  deleteExternalUser(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'external_users_delete/' + id + '/', {})
  }

  addExternalUserDoc(data: any) {
    return this.http.post(environment.apiEndpoint + 'external_users_document_add/', data)
  }

  getExternalUserDocList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_document_list/' + id + '/')
  }

  deleteExternalUserDoc(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'external_users_document_delete/' + id + '/', {})
  }

  // material
  getMaterialUnitList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'unit_add/')
  }

  getMaterialType(): Observable<any> {
    // return this.http.get(environment.apiEndpoint + 'material_type_master_add/')
    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_master_add/')
  }

  addMaterial(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'materials_add/', data)
  }


  getMaterialList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'materials_add/')
  }

  // 18/5/2019 modification for get material list with multipule unit

  getMaterialListWithMutliUnit(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'materials_list/')
  }

  getMaterialTypeList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'materials_list/?' + params)
  }


  getMaterialDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'materials_edit/' + id + '/')
  }

  editMaterial(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'materials_edit/' + id + '/', data)
  }

  deleteMaterial(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'materials_delete/' + id + '/', {})
  }

  // machinery type
  addMachineryType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'machinery_type_add/', data)
  }
  addTenderMachineryType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_machinery_type_de_add/', data)
  }

  getMachineryTypeListByTender(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machinary_type_list_by_tender/' + id + '/')
  }

  getMachineryTypeList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machinery_type_add/')
  }

  getMachineryTypeDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machinery_type_edit/' + id + '/')
  }

  editMachineryType(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machinery_type_edit/' + id + '/', data)
  }

  addEditTenderMachineryType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_machinery_type_ex_de_add/', data)
  }

  deleteMachineryType(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'machinery_type_delete/' + id + '/', {})
  }


  getExternalUserListByType(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_list/?' + params)
  }

  // getExternalUserWithOutPaginationListByType(user_type): Observable<any> {
  //   var params = 'page_size=0'
  //   return this.http.get(environment.apiEndpoint + 'external_users_list/'+user_type+'/?'+params)
  // }

  getPartnersListByType(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'external_users_list_with_pagination/?' + params)
  }

  getRequisitionTypeList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_master_add/')
  }

  getRequisitionTypeDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_requisitions_type_master_edit/' + id + '/')
  }


  addRequisitionType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchases_requisitions_type_master_add/', data)
  }

  editRequisitionType(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchases_requisitions_type_master_edit/' + id + '/', data)
  }


  getPaymentTermsList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_payment_terms_master_add/')
  }

  getPaymentTermsDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchase_quotations_payment_terms_master_edit/' + id + '/')
  }

  addPaymentTerms(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchase_quotations_payment_terms_master_add/', data)
  }

  editPaymentTerms(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchase_quotations_payment_terms_master_edit/' + id + '/', data)
  }

  getTransportCostList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_order_transportcost_master_add/')
  }

  getTransportCostDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'purchases_order_transportcost_master_edit/' + id + '/')
  }

  addTransportCost(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'purchases_order_transportcost_master_add/', data)
  }

  editTransportCost(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'purchases_order_transportcost_master_edit/' + id + '/', data)
  }

  addActivity(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_purchases_requisitions_activities_master_add/', data)
  }
  getActivityList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_requisitions_activities_master_add/')
  }

  editActivity(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_purchases_requisitions_activities_master_edit/' + id + '/', data)
  }
  getActivityDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_purchases_requisitions_activities_master_edit/' + id + '/')
  }


  getStockIssueModeList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_issue_mode_add/')
  }

  getStockIssueModeDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_issue_mode_edit/' + id + '/')
  }

  addStockIssueMode(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'execution_stock_issue_mode_add/', data)
  }

  editStockIssueMode(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_stock_issue_mode_edit/' + id + '/', data)
  }

  getApprovalSectionList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'section_add/')
  }

  getApprovalSectionWithPermissionLevelList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'approval_permission_lavel_master_add/')
  }

  getUserListByModulePermission(module_id, section_id, permission_id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'approval_user_list_by_permission/' + module_id + '/' + section_id + '/' + permission_id + '/')
  }

  addSectionApproval(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'approval_permission_master_add/', data)
  }

  getSectionApprovalList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'approval_permission_master_add/')
  }

  getSectionApprovalDeatils(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'approval_permission_master_add/?section=' + id)
  }


  getReprotingHeadAndHodList(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'hod_or_reporting_head_list/?' + params)
  }

  // 25-06-2020
  getMachineryListByProjectWp(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'machineries_by_project/' + id + '/')
  }

  //Barching Plant Master

  addCementBrand(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/batching/brand_of_cement/add/', data)
  }

  getCementBrandList(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/brand_of_cement/add/?'+params)
  }

  getCementBrandDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/brand_of_cement/edit/'+id+'/')
  }

  deleteCementBrandDetails(id): Observable<any> {
    return this.http.delete(environment.apiEndpoint + 'pms/batching/brand_of_cement/edit/'+id+'/')
  }

  updateCementBrandDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/batching/brand_of_cement/edit/'+id+'/', data)
  }

  addPurpose(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/batching/purpose/add/', data)
  }
  getPurposeList(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/purpose/add/?'+params)
  }

  getPurposeDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/purpose/edit/'+id+'/')
  }

  deletePurposeDetails(id): Observable<any> {
    return this.http.delete(environment.apiEndpoint + 'pms/batching/purpose/edit/'+id+'/')
  }

  updatePurposeDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/batching/purpose/edit/'+id+'/', data)
  }

  addConcrete(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/batching/concrete/add/', data)
  }
  getConcreteList(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/concrete/add/?'+params)
  }

  getConcreteDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/concrete/edit/'+id+'/')
  }

  deleteConcreteDetails(id): Observable<any> {
    return this.http.delete(environment.apiEndpoint + 'pms/batching/concrete/edit/'+id+'/')
  }

  updateConcreteDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/batching/concrete/edit/'+id+'/', data)
  }

  addConcreteIngredient(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/batching/concrete_ingredient/add/', data)
  }

  getConcreteIngredientList(params:any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/concrete_ingredient/add/?'+params)
  }

  getConcreteIngredientDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/batching/concrete_ingredient/edit/'+id+'/')
  }

  deleteConcreteIngredientDetails(id): Observable<any> {
    return this.http.delete(environment.apiEndpoint + 'pms/batching/concrete_ingredient/edit/'+id+'/')
  }

  updateConcreteIngredientDetails(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/batching/concrete_ingredient/edit/'+id+'/', data)
  }

  getMaterialListWithoutStock(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'materials_list_without_stock/?' + params)
  }

  addBoq(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/boq/add/', data)
  }

  getBOQById(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/boq/edit/'+id+'/', )
  }

  editBoq(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/boq/edit/'+id+'/', data)
  }

  getUserList(params) {
    return this.http.get(environment.apiEndpoint + 'user_module_list/?' + params, this.httpHeaderOptions);
  }

  getUserInfo(id) {
    return this.http.get(environment.apiEndpoint + 'user_permission_edit/' + id + '/', this.httpHeaderOptions);
  }

  getmoduleObjectList(app): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'other_list_new_by_module_name/' + app + '/', this.httpHeaderOptions)
  }

  getRolePermission(app, role) {
    return this.http.get(environment.apiEndpoint + 'other_list_with_permission_by_role_module_name/' + app + '/' + role + '/', this.httpHeaderOptions);
  }

  permissionListByUser(app, id) {
    return this.http.get(environment.apiEndpoint + 'other_list_with_permission_by_user_module_name/' + app + '/' + id + "/", this.httpHeaderOptions);
  }

  applicationList() {
    return this.http.get(environment.apiEndpoint + 'add_application/', this.httpHeaderOptions);
  }
  getUserAllDetails(id) {
    return this.http.get(environment.apiEndpoint + 'edit_user_get_new/' + id + '/', this.httpHeaderOptions);
  }
  roleMasterList(app) {
    return this.http.get(environment.apiEndpoint + 'roles_by_module_name/' + app + '/', this.httpHeaderOptions);
  }
  assignRolePermission(data) {
    return this.http.post(environment.apiEndpoint + 'assign_permission_to_role_add_or_update_new/', data, this.httpHeaderOptions);
  }

  assignUserPermission(data) {
    return this.http.post(environment.apiEndpoint + 'assign_permission_to_user_add_or_update_new/', data, this.httpHeaderOptions);
  }

  assignUserRoleModule(id,data)
  {
    return this.http.put(environment.apiEndpoint + 'user/role_module_assign/'+id+'/', data, this.httpHeaderOptions);
  }

}
