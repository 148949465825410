import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingState } from '../../loading/loading.component';
import { TenderService } from '../../../services/tender.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SettingService } from '../../../services/setting.service';

@Component({
  selector: 'app-add-material-modal',
  templateUrl: './add-material-modal.component.html',
  styleUrls: ['./add-material-modal.component.scss']
})
export class AddMaterialModalComponent implements OnInit {

  tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  unitList: any = [];
  materialDetailArr:any = [];
  type: string;
  constructor(
    public dialogRef: MatDialogRef<AddMaterialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
    private settingService: SettingService,
  ) {
    this.tenderId = data['tenderId']
    this.type = data['type']
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      type_code: ['', Validators.required],
      materials_unit: [null, Validators.required],
      mat_code: ['', Validators.required],
      description: ['', Validators.required]
    });
    this.getMaterialUnitAndTypeList();
  }

  // getMaterialUnitList() {
  //   this.settingService.getMaterialUnitList().subscribe(
  //     res => {
  //       console.log(res)
  //       this.unitList = res;
  //       this.loading = LoadingState.Ready
  //     },
  //     error => {
  //       console.log(error)
  //       this.loading = LoadingState.Ready
  //     }
  //   )
  // }

  getMaterialUnitAndTypeList() {
    var forkArray = [];
    forkArray.push(this.settingService.getMaterialUnitList())
    forkArray.push(this.settingService.getMaterialType())

    forkJoin(forkArray).subscribe(
      ([getMaterialUnitList, getMaterialType]) => {
        if (getMaterialUnitList) {
          this.unitList = getMaterialUnitList;
        }
        if (getMaterialType) {
          var materialType = getMaterialType['result'];
          this.form.patchValue({
            type_code: materialType.filter(x => x.type_name == "Materials")[0].code
          })
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        console.log(err);
        this.loading = LoadingState.Ready;
      }
    )
  }

  close() {
    this.dialogRef.close(true);
  }
  
  save() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;

      this.materialDetailArr = {};
      this.materialDetailArr = Object.assign({},this.form.value);
      var unitArr = [];
      this.materialDetailArr.materials_unit.forEach(x=>{
        var d = {
          unit_id:x
        }
  
        unitArr.push(d);
        this.materialDetailArr.materials_unit = unitArr;
      })
      if(this.type == "material"){
        this.materialDetailArr['is_master'] = 1;
      }
      else if(this.type == "crusher"){
        this.materialDetailArr['is_crusher'] = 1;
      }
     // console.log(this.materialDetailArr);
      this.settingService.addMaterial(this.materialDetailArr).subscribe(
        res => {
       //   console.log(res);
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error);
          this.loading = LoadingState.Ready;
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // save() {
  //   console.log(this.form.value)
  //   if (this.form.valid) {
  //     this.loading = LoadingState.Processing;
  //     this.settingService.addMaterial(this.form.value).subscribe(
  //       res => {
  //         console.log(res)
  //         this.dialogRef.close({ data: res });
  //         this.loading = LoadingState.Ready
  //       },
  //       error => {
  //         console.log(error)
  //         this.loading = LoadingState.Ready
  //       }
  //     )
  //   }
  //   else {
  //     this.markFormGroupTouched(this.form)
  //   }
  // }  

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
