import { Component, OnInit, Input,ElementRef, ViewChild} from '@angular/core';
import { LoadingState } from '../../../loading/loading.component';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { LocalService } from '../../../../services/local.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import * as Globals from '../../../../globals';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { SettingService } from '../../../../services/setting.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApprovalLevelBottomSheetComponent } from '../approval-level-bottom-sheet/approval-level-bottom-sheet.component';
import { QuotationApprovalModalComponent } from '../quotation-approval-modal/quotation-approval-modal.component';
import { RankingModalComponent } from '../ranking-modal/ranking-modal.component';
import { QuotationApprovalConfirmModalComponent } from '../quotation-approval-confirm-modal/quotation-approval-confirm-modal.component';
import {AppPageStateConfig} from '../../../../../app.config';
import { forkJoin } from 'rxjs';
import { EligibilitySpecialInnerTabComponent } from '../../../tender-section/eligibility-special-inner-tab/eligibility-special-inner-tab.component';

@Component({
  selector: 'app-quotation-approval',
  templateUrl: './quotation-approval.component.html',
  styleUrls: ['./quotation-approval.component.scss']
})
export class QuotationApprovalComponent implements OnInit {

  @Input() searchKeyword: string
  headerThOption = [];
 
  loading: LoadingState = LoadingState.NotReady;
  pendingRequisitionQuationList:any = [];
  totalPendingRequisitionQuationList:number;
  projectChecked = [];
  typeChecked:number;

  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList:any = [];
  pageSize:number;
  requisitionTypeList = []
  //filter
  fromDate = '';
  toDate = '';
  search_key = '';
  sort_by = '';
  sort_type = '';
  dateRange: any;
  quotationConfig:any;
  scrollAmount: any;
  hasScroll: boolean;
  selectedItem: any =[];
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  allChecked: boolean;
  userId: number;
  permissionList:any = [];
  currentPermissionUser: any;
  bulkApproval: null;
  groupWiseVendorData: any = [];

  constructor(
    private executionPurchaseService: ExecutionPurchaseService,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private projectSiteServices: projectSiteServices,
    public dialog: MatDialog,
    private settingService: SettingService,
    private element: ElementRef,
    private localService: LocalService,
  ) {
    this.quotationConfig = AppPageStateConfig.requisitionApprovalConfig.quotationConfig;
   }

  ngOnInit() {
    
    if (this.quotationConfig.fromDate != '') {
      this.fromDate = this.quotationConfig.fromDate;
    }
    if (this.quotationConfig.toDate != '') {
      this.toDate = this.quotationConfig.toDate;
    }
    if (this.quotationConfig.dateRange != null) {
      this.dateRange = this.quotationConfig.dateRange;
    }
    if (this.quotationConfig.projectChecked.length>0) {
      this.projectChecked = this.quotationConfig.projectChecked;
    }
    if (this.quotationConfig.typeChecked!=null) {
      this.typeChecked = this.quotationConfig.typeChecked;
    }
    this.headerThOption = [
      {
        id: 1,
        name: "Requirement Date",
        code: "mr_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true,
        class: 'fixed-col'
      },
      // {
      //   id: 2,
      //   name: "Project ID",
      //   code: "project",
      //   sort_type: '',
      //   has_tooltip: false,
      //   tooltip_msg: '',
      //   selected: true
      // },
      {
        id: 3,
        name: "Site",
        code: "site_location",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true,
        class: 'fixed-col'
      },      
      {
        id: 4,
        name: "Type",
        code: "type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Comparitive Statement",
        code: "comparative_statement",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },        
    ]
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    if (this.quotationConfig.defaultPagination != null) {
      this.defaultPagination = this.quotationConfig.defaultPagination;
    }
    if (this.quotationConfig.itemPerPage != null) {
      this.itemPerPage = this.quotationConfig.itemPerPage;
    }
    if (this.quotationConfig.pageSize != null) {
      this.pageSize = this.quotationConfig.pageSize;
    }
    this.userId = +this.localService.getJsonValue('userid')
    this.loadData();
    window.addEventListener('scroll', this.scroll, true);    
  }

  

  loadData(){
    this.loading = LoadingState.Processing
    var forkArray = [];
    let param: URLSearchParams = new URLSearchParams();
    param.set('section', 'comparative statement');
    let params: URLSearchParams = new URLSearchParams();
    params.set('section_name', 'comparative statement');
    params.set('page', this.defaultPagination.toString());

    if (this.fromDate != '') {
      params.set('start_date', this.fromDate.toString());
    }
    if (this.toDate != '') {
      params.set('end_date', this.toDate.toString());
    }
    if (this.itemPerPage) {
      params.set('page_count', this.itemPerPage.toString());
    }
    if (this.projectChecked.length > 0) {
      params.set('site_location', decodeURI(this.projectChecked.toString()));
    }
   
    if (this.typeChecked != undefined) {
      params.set('type_name', this.typeChecked.toString());
    }

    if (this.search_key != '') {
      params.set('search', this.search_key.toString());
    }
    if (this.sort_by != '') {
      params.set('field_name', this.sort_by.toString());
    }
    if (this.sort_type != '') {
      params.set('order_by', this.sort_type.toString());
    }
    
    
    forkArray.push(this.executionPurchaseService.requisitionTypes())
    forkArray.push(this.executionPurchaseService.getPermissionLevel(param))
    forkArray.push(this.executionPurchaseService.getPendingQuotationApprovalList(params))

    forkJoin(forkArray).subscribe(
      ([requisitionType,permissionData,quotationData]) => {
        if(requisitionType){
          this.requisitionTypeList = requisitionType['result'];
        }
        if(permissionData['result'].user_approve_details.length > 0){
          this.permissionList = permissionData['result'];
          this.permissionList.user_approve_details.forEach(x => {
            
            if(x.user_details.id == this.userId){
              this.currentPermissionUser = x;              
            }
          });
        }
        if(quotationData){
          this.totalPendingRequisitionQuationList = quotationData['count'];
          if(this.totalPendingRequisitionQuationList > 0) {
            quotationData['results'].forEach(element => {
              if(Object.keys(element.quotation_group_details).length > 0) {
                this.pendingRequisitionQuationList.push(element);
              }
            });
          }
          this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
          this.lower_count = this.itemNo + 1;
          if (this.totalPendingRequisitionQuationList > this.itemPerPage * this.defaultPagination) {
            this.upper_count = this.itemPerPage * this.defaultPagination
          
          }
          else {
            this.upper_count = this.totalPendingRequisitionQuationList;
          }
          this.pendingRequisitionQuationList.forEach(x => {
            var isRankAll = true;
            for (const [k, v] of Object.entries(x.quotation_group_details)) {
              let tempArray : any = [];
              tempArray = v['items'];
              var isRankIndv = false;
              tempArray.forEach(l => {
                if(l.ranking != null){
                  isRankIndv = true
                } else {
                  isRankIndv = false
                }
              })
              if(!isRankIndv){
                isRankAll = isRankIndv
              }
              x.quotation_group_details[k]['is_rank'] = isRankIndv  
              //this.calculateTotalPrice(k)  
            }
            x['is_rank_all'] = isRankAll;
          })
          this.checkedAll(false)
          this.loading = LoadingState.Ready;
        }
      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  ngAfterViewInit() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    this.hasScroll = (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth) ? true : false;
  }

  scroll = (event: any): void => {
    // console.log('hii')
    if (this.selectedItem.length > 0) {
      let prevScrollpos = 150;
      let currentScrollPos = window.pageYOffset;

      if (prevScrollpos > currentScrollPos) {
        document.getElementById("bulkPunchApproval").classList.remove("fixedTotop");
      } else {
        document.getElementById("bulkPunchApproval").classList.add("fixedTotop");
      }
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  checkedAll(event) {
    this.pendingRequisitionQuationList.forEach((x, index) => {      
      if(x.is_rank_all){
        this.checkedThisRow(x, event)
      }      
    })

  }

  checkedThisRow(x, event) {
    if (event.checked) {
      x.checked = true;
      var index = this.selectedItem.findIndex(k => k.requisition_id == x.irequisition_idd)
      if (index == -1) {
        this.selectedItem.push(x)
      }
    }
    else {
      x.checked = false;
      var index = this.selectedItem.findIndex(k => k.requisition_id == x.requisition_id)
      if (index != -1) {
        this.selectedItem.splice(index, 1)
      }
    }
    this.checkAllReverse()
    // console.log(this.selectedItem)
  }

  checkAllReverse() {
    this.allChecked = true;
    this.pendingRequisitionQuationList.forEach((x, index) => {
      if(x.is_rank_all){
        if (!x.checked) {
          this.allChecked = false;
        }
      }
      
    })
  }

  bulkAction($event){
    const approvalDialogRef = this.dialog.open(QuotationApprovalConfirmModalComponent, {
      disableClose: false,
      data: {from:'QuotationApproval'}
    });
    approvalDialogRef.componentInstance.confirmMessage = "Are you sure for this action ?"
    approvalDialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result){
        // console.log($event)
        // console.log(this.selectedItem)
        var approval_details = [];
        this.selectedItem.forEach(d => {
          d.item_data.forEach(r => {
            var selectedVendor;
            var permissionIndex = r.permission_details.findIndex(x => x.user_details.id == this.userId)
            var isPrevApproverAction = false;
            r.permission_details.forEach(x => {
              if(x.approval == true){
                isPrevApproverAction = true;
              }
            })
            if(!isPrevApproverAction){
              var vendorRankIndex = r.vendor_details.findIndex(k => k.ranking == 'L1')
              selectedVendor = r.vendor_details[vendorRankIndex]['vendor_id']
            }
            else{
              r.permission_details.forEach((x,i) => {
                if( i <= permissionIndex){
                  if(x.approved_vendor_id != null){
                    selectedVendor = x.approved_vendor_id
                  }
                }
              })
            }
            
            var ranking_vendor = r.ranking_details.filter(h => h.vendor_id == selectedVendor)[0]
            var obj = {
              id: ranking_vendor.id,
              requisitions_master: d.requisition_id,
              vendor: selectedVendor,
              uom: r.uom,
              item: r.approved_item,
              discount: ranking_vendor.discount,
              final_price: ranking_vendor.final_price,
              price_basis: ranking_vendor.final_price,
              make: ranking_vendor.make,
              base_price: ranking_vendor.base_price,
              packaging_and_forwarding: ranking_vendor.packaging_and_forwarding,
              freight_up_to_site: ranking_vendor.freight_up_to_site,
              cgst: ranking_vendor.cgst,
              sgst: ranking_vendor.sgst,
              igst: ranking_vendor.igst,
              payment_terms: ranking_vendor.payment_terms,
              delivery_time: ranking_vendor.delivery_time,
              total_order_value: +ranking_vendor.total_order_value,
              net_landed_cost: +ranking_vendor.net_landed_cost,
              inco_terms: ranking_vendor.inco_terms,
              warranty_guarantee: ranking_vendor.warranty_guarantee,
              approval_permission_user_level: this.currentPermissionUser.id,
              comment: result==true?'':result
            }
            if ($event == 'Reject') {
              obj['is_rejected'] = true
            }
            approval_details.push(obj)

          })
          
        })
        var data = {
          approval_details: approval_details
        }      
        this.loading = LoadingState.Processing
        this.executionPurchaseService.approveQuotationItem(data).subscribe(
          res => {
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been Approved', '', {
              timeOut: 3000,
            });
            this.getRequisitionQuationPendingList()
            this.bulkApproval = null;
            this.selectedItem = [];
          },
          error => {
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        )
      }
      else{
        this.bulkApproval = null;
      }
    })
    
  }

  

  openApprovalDialog(requisition_id:number,data: any): void {
    // console.log(requisition_id)
    const approvalDialogRef = this.dialog.open(QuotationApprovalModalComponent, {
      width: '450px',
      closeOnNavigation: true,
      data: {result: data,requisition_id: requisition_id,currentPermissionUser: this.currentPermissionUser }
    });
    approvalDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.reload()
      }

    });
  }

  openRankDialog(item: any,mat: any, rowGroupName): void {
    const approvalDialogRef = this.dialog.open(RankingModalComponent, {
      width: '80%',
      closeOnNavigation: true,
      data: {result: item, rowGroupName: rowGroupName }
    });
    approvalDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.reload()
      }

    });
  }

  ngOnChanges() {
    if(this.searchKeyword != '' && this.searchKeyword != undefined){
      this.search_key = this.searchKeyword;
    }else{
      this.search_key = ''
    }
    
  }


  getConfigData(data){
    this.headerThOption = data
  }

  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }

  getRequisitionQuationPendingList(){
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    params.set('section_name', 'comparative statement');
    params.set('page', this.defaultPagination.toString());

    if (this.fromDate != '') {
      params.set('start_date', this.fromDate.toString());
    }
    if (this.toDate != '') {
      params.set('end_date', this.toDate.toString());
    }
    if (this.itemPerPage) {
      params.set('page_count', this.itemPerPage.toString());
    }
    if (this.projectChecked.length > 0) {
      params.set('site_location', decodeURI(this.projectChecked.toString()));
    }
   
    if (this.typeChecked != undefined) {
      params.set('type_name', this.typeChecked.toString());
    }

    if (this.search_key != '') {
      params.set('search', this.search_key.toString());
    }
    if (this.sort_by != '') {
      params.set('field_name', this.sort_by.toString());
    }
    if (this.sort_type != '') {
      params.set('order_by', this.sort_type.toString());
    }

    this.executionPurchaseService.getPendingQuotationApprovalList(params).subscribe(
      res=>{
        this.pendingRequisitionQuationList = [];
        this.totalPendingRequisitionQuationList = res.count;
        if(this.totalPendingRequisitionQuationList > 0) {
          res['results'].forEach(element => {
            if(Object.keys(element.quotation_group_details).length > 0) {
              this.pendingRequisitionQuationList.push(element);
            }
          });
        }
        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalPendingRequisitionQuationList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
         
        }
        else {
          this.upper_count = this.totalPendingRequisitionQuationList;
        }
        this.pendingRequisitionQuationList.forEach(x => {
          var isRankAll = true;
          for (const [k, v] of Object.entries(x.quotation_group_details)) {
            let tempArray : any = [];
            tempArray = v['items'];
            var isRankIndv = false;
            tempArray.forEach(l => {
              if(l.ranking != null){
                isRankIndv = true
              } else {
                isRankIndv = false
              }
            })
            if(!isRankIndv){
              isRankAll = isRankIndv
            }
            x.quotation_group_details[k]['is_rank'] = isRankIndv  
            //this.calculateTotalPrice(k)  
          }
          x['is_rank_all'] = isRankAll;
        });
        this.checkedAll(false)
        this.loading = LoadingState.Ready;
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  calculateTotalPrice(r){
    // console.log(r)
    var selectedVendor = null;
    var permissionIndex = r.permission_details.findIndex(x => x.user_details.id == this.userId)
    var isPrevApproverAction = false;
    r.permission_details.forEach(x => {
      if(x.approval == true){
        isPrevApproverAction = true;
      }
    })
    if(!isPrevApproverAction){
      var vendorRankIndex = r.vendor_details.findIndex(k => k.ranking == 'L1')
      // console.log(vendorRankIndex)
      if(vendorRankIndex != -1){
        selectedVendor = r.vendor_details[vendorRankIndex]['vendor_id']
      }
      
    }
    else{
      r.permission_details.forEach((x,i) => {
        if( i <= permissionIndex){
          if(x.approved_vendor_id != null){
            selectedVendor = x.approved_vendor_id
          }
        }
      })
    }

    if(selectedVendor == null){
      r['total_cost'] = null
    }
    else{
      if(r.ranking_details != undefined){
        var rankIndex = r.ranking_details.findIndex(h => h.vendor_id == selectedVendor)
        if(rankIndex != -1){
          r['total_cost'] = r.ranking_details[rankIndex]['total_order_value']
        }
      }
      else{
        r['total_cost'] = null
      }
      
    }
  }

  redirect(id:number){
    const navigationExtras: NavigationExtras = {
      state: {
        activestate:'comparitive-statement'
      }
    };
    this.router.navigate(['execution-purchase/add-requisition/'+id],navigationExtras);
    //this.router.navigateByUrl('execution-purchase/add-requisition/'+id+'/comparitive-statement');
   
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.getRequisitionQuationPendingList();
  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.quotationConfig.defaultPagination = this.defaultPagination;
    this.getRequisitionQuationPendingList();
  }

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.pageSize = this.itemPerPage;
    
    this.defaultPagination = 1;
    this.quotationConfig.typeChecked = this.typeChecked;
    this.quotationConfig.defaultPagination = this.defaultPagination;
    this.quotationConfig.pageSize = +this.itemPerPage;
    this.getRequisitionQuationPendingList();

  }

  reload() {
    this.loading = LoadingState.Processing;
    this.fromDate = '';
    this.toDate = '';
    this.sort_type = '';
    this.sort_by = '';
    this.search_key = '';
    this.projectChecked = [];
    this.typeChecked = null;
    this.itemPerPage = Globals.itemPerPage;
    
    this.pageSize =  Globals.pageSize;
    this.quotationConfig.dateRange = this.dateRange;
    this.quotationConfig.typeChecked = this.typeChecked;
    this.quotationConfig.fromDate = this.fromDate;
    this.quotationConfig.toDate = this.toDate;
    this.quotationConfig.defaultPagination = this.defaultPagination;
    this.quotationConfig.itemPerPage = this.itemPerPage;
    this.quotationConfig.projectChecked = this.projectChecked;
    this.getRequisitionQuationPendingList();
  }

  openBottomSheet(permission:any) {
    // this._bottomSheet.open(ApprovalLevelBottomSheetComponent,{
    //   data: {from:'quotaion',result:permission}
    // });
    let dialogRef = this.dialog.open(ApprovalLevelBottomSheetComponent, {
      // width: '525px',
      data: {from:'quotaion',result:permission}
    });
    dialogRef.afterClosed().subscribe(result => {
     
    })
  }

  dateTimeReset(){
    this.dateRange = null;
    this.fromDate = '';
    this.toDate = '';
   
    this.quotationConfig.fromDate = this.fromDate;
    this.quotationConfig.toDate = this.toDate;
    this.quotationConfig.dateRange = this.dateRange;
    this.dataFilter();
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.fromDate = this.settingService.formatDate(event.value[0])
      this.toDate = this.settingService.formatDate(event.value[1])
      this.quotationConfig.fromDate = this.fromDate;
      this.quotationConfig.toDate = this.toDate;
      this.quotationConfig.dateRange = this.dateRange;
      this.dataFilter()
    }
    else {
      this.reload()
    }

  }
  


   onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.quotationConfig.itemPerPage = +item;
    this.dataFilter();
  }

  onListProjectsSelectionChange(project): void {
     this.projectChecked = project;
     this.quotationConfig.projectChecked = this.projectChecked;
    this.dataFilter();
  }

}
