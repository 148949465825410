import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-site-puja-approve-tab',
  templateUrl: './site-puja-approve-tab.component.html',
  styleUrls: ['./site-puja-approve-tab.component.scss']
})
export class SitePujaApproveTabComponent implements OnInit {
  @Input() projectId:number;
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  sitePuja:any = [];

  approve:number;
  approveData:any = [];


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      pre_execution_tabs:[this.tabId],
      approved_status:[null,Validators.required],
      request_modification:[null]

    })
    if(this.projectId){
      this.getSitePuja();
      this.getStatus();
    }
  }

  getSitePuja(){
    this.loading = LoadingState.NotReady;
     this.perExecutionService.getSitePuja(this.projectId).subscribe(
      res =>{
        if(res['result'][0]){
          this.sitePuja = res['result'][0];
        }
        
        this.loading = LoadingState.Ready;
      },
      error=>{
        //console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server Error !', '', {
          timeOut: 3000,
        });
      })
  }

  handleChange(e: any) {
    //console.log(e)
    this.approve = e.target.value;
    if(this.approve!=3){
      this.form.patchValue({
        request_modification:null
      })
    }
  }
  save()
  {
    if(this.form.valid){
      this.loading = LoadingState.Processing;
     var data;
      if(this.approve!=3){
         data = {
          project:this.projectId,
          pre_execution_tabs:this.tabId,
          approved_status:this.form.value.approved_status
          
        }
      }
      else{
          data = {
          project:this.projectId,
          pre_execution_tabs:this.tabId,
          approved_status:this.form.value.approved_status,
          request_modification:this.form.value.request_modification
         
        }
      }


      this.perExecutionService.addApprovalTab(this.projectId,data).subscribe(
        res =>{
         // console.log(res)
          
          this.loading = LoadingState.Ready;
          this.toastr.error('Data has been submitted.', '', {
            timeOut: 3000,
          });

        },
        error =>{
        this.loading = LoadingState.Ready;
          this.toastr.error('Somthing Went Worng !', '', {
            timeOut: 3000,
          });
        })
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }


  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        //console.log(res);
        if(res['result'][0]){
          this.approveData = res['result'][0];
          this.approve = this.approveData.approved_status;
          this.approveData.approved_status=  this.approveData.approved_status.toString();
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      })

  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }
  
  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }
  
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
