import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { LocalService} from '../../../services/local.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manpower-inner-tab',
  templateUrl: './manpower-inner-tab.component.html',
  styleUrls: ['./manpower-inner-tab.component.scss']
})
export class ManpowerInnerTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() masterId:number; 
  form: FormGroup;
  manpowerDetail:any = [];
  manpowerDocumentList:any = [];
  manpowerListData:any = [];
  manpowerListDetail:any = [];
  listEdit:boolean;
  loading: LoadingState = LoadingState.NotReady;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  moduleId:number;
  manpowerDetailPerDesignation:any = [];

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
	  public dialog: MatDialog,
    private perExecutionService:PerExecutionService,
    public settingService:SettingService,
    public localService:LocalService,
  ) {
    this.moduleId = +this.localService.getJsonValue('currentModuleId');
   }

  ngOnInit() {

    
    this.form = this.formBuilder.group({
        project:[this.projectId],
        designation: [this.masterId],
        manpower:[null,Validators.required],
        contact_no:[null],
        email:[null],
        total_job_experience:[null,Validators.required],
        qualification:[null,Validators.required],
        job_description:[null,Validators.required],
        requirment_s_date:[null,Validators.required],
        requirment_e_date: [null,Validators.required],
        budgeted_salary: [null,Validators.required],
        expected_salary: [null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])

    });

    if(this.projectId && this.masterId){
      this.loadAllMasterData();

    }
  }

  loadAllMasterData(){
    var forkArray = [];
    forkArray.push(this.perExecutionService.getManpowerPerDesignation(this.moduleId,this.masterId))
    forkArray.push(this.perExecutionService.getDetailsManpower(this.projectId,this.masterId))
   
  
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
    
        //console.log(result);
        for (var i = 0; i < result.length; i++) {
          if (i == 0) {
            this.manpowerDetailPerDesignation = result[i]['result'];
            //console.log(this.manpowerDetailPerDesignation)
          }
          if(i==1){
            this.manpowerListData = result[i]['result'];
            //console.log(this.manpowerListData);
            if(this.manpowerListData.length > 0){
              this.manpowerListData.forEach((x)=>{
                this.manpowerDetailPerDesignation.forEach((z,index)=>{
                  if(z.mmr_user == x.manpower){
                    this.manpowerDetailPerDesignation.splice(index,1);
                  }
                })
              })
            }

          }
         
          

        }
       // console.log(this.vendorList)
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }
 
  getManpowerInfo(event){
   let info =  this.manpowerDetailPerDesignation.filter(x => x.mmr_user == event)[0];
   this.manpowerListDetail.contact_no = info.user_details.contact; 
   this.manpowerListDetail.email = info.user_details.email; 
    //console.log(info.user_details)
  }
  
  save(){
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var tiffin_box_documents_data = [];
      tiffin_box_documents_data = this.form.value.documents;

      //console.log(this.form.value)

      const data = {
        project: this.projectId,
        designation:this.masterId,
        manpower: this.form.value.manpower,
        total_job_experience: this.form.value.total_job_experience,
        qualification: this.form.value.qualification,
        job_description: this.form.value.job_description,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),
        budgeted_salary: this.form.value.budgeted_salary,
        expected_salary: this.form.value.expected_salary
      }

      this.manpowerDocumentList = [];
      this.perExecutionService.addDetailsManpower(data).subscribe(
        res => {
          //console.log(res);
          var forkArray = [];

          tiffin_box_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.manpower_document, x.manpower_document['name']);
              forkArray.push(this.perExecutionService.addDocumentManpower(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.manpowerDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )

          this.loadAllMasterData(); 
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
          this.form.reset();
        },
        error=>{
          //console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        })

     // console.log(data);

    }else
    {
     // console.log("error");
      this.markFormGroupTouched(this.form);
    
    }
  }

  // getManpowerDetails(){
  //   this.perExecutionService.getDetailsManpower(this.projectId,this.masterId).subscribe(
  //     res => {
  //       this.manpowerListData = res['result'];
  //      // console.log(res)
  //     //  if(res['result'][0]){
  //     //   this.manpowerDetail = res['result'][0];
  //     //   console.log(this.manpowerDetail);

  //     //   var start_date = new Date(this.manpowerDetail['requirment_s_date'])
  //     //       this.manpowerDetail.requirment_s_date = {
  //     //         year: start_date.getFullYear(),
  //     //         month: start_date.getMonth()+1,
  //     //         day: start_date.getDate()
        
  //     //       }
  //     //       var end_date = new Date(this.manpowerDetail['requirment_e_date'])
  //     //       this.manpowerDetail.requirment_e_date = {
  //     //         year: end_date.getFullYear(),
  //     //         month: end_date.getMonth()+1,
  //     //         day: end_date.getDate()
              
  //     //       }
  //     //     this.manpowerDetail.budgeted_salary = parseFloat(this.manpowerDetail.budgeted_salary).toFixed(2);
  //     //     this.manpowerDetail.expected_salary = parseFloat(this.manpowerDetail.expected_salary).toFixed(2);
  //     //  }
       
  //       this.loading = LoadingState.Ready;
  //     },
  //     error =>{
  //       console.log(error);
  //       this.loading = LoadingState.Ready;
  //       this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //         });
  //     }
  //   )
  // }

  getManpowerList(){
    this.perExecutionService.getDetailsManpower(this.projectId,this.masterId).subscribe(
      res => {
        this.manpowerListData = res['result'];
        
         //console.log(this.manpowerListData);
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  editManpower(data){
    this.manpowerListDetail = data;
    this.manpowerDocumentList = this.manpowerListDetail.document_details;
    this.listEdit = true;
    
    var start_date = new Date(this.manpowerListDetail['requirment_s_date'])
          this.manpowerDetail.requirment_s_date = {
            year: start_date.getFullYear(),
            month: start_date.getMonth()+1,
            day: start_date.getDate()
      
          }
    var end_date = new Date(this.manpowerListDetail['requirment_e_date'])
    this.manpowerDetail.requirment_e_date = {
      year: end_date.getFullYear(),
      month: end_date.getMonth()+1,
      day: end_date.getDate()
      
    }
    this.manpowerDetail.budgeted_salary = parseFloat(this.manpowerListDetail.budgeted_salary).toFixed(2);
    this.manpowerDetail.expected_salary = parseFloat(this.manpowerListDetail.expected_salary).toFixed(2);
    
    
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.manpowerDocumentList[index]['isEdit'] = true
    this.manpowerDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.manpowerDocumentList[index]['document_name'] == '') {
      this.manpowerDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.manpowerDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.manpowerDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editDocumentManpower(this.manpowerDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.manpowerDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.delteDocumentManpower(this.manpowerDocumentList[index]['id']).subscribe(
        res => {
          this.manpowerDocumentList.splice(index, 1)
          // console.log(res)
          this.loading = LoadingState.Ready
          this.getManpowerList();
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })
   
  }

  deleteManpower(id){
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteListDetailsManpower(id).subscribe(
        res => {
          this.loadAllMasterData();
           this.form.reset();
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })
   
  }

  // update(){
  //   if(this.form.valid){

  //     this.loading = LoadingState.Processing;
  //     var manpower_documents_data = [];
  //     manpower_documents_data = this.form.value.documents;

  //     const updateData = {
  //       project:this.projectId,
  //       manpower_master:this.masterId,
  //      // resource_count:this.form.value.resource_count,
  //       requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
  //       requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
  //       budgeted_salary:this.form.value.budgeted_salary,
  //       expected_salary:this.form.value.expected_salary,
  //       manpower_details:
  //       {             
  //         name:this.form.value.manpower_details.name,
  //         contact_no:this.form.value.manpower_details.contact_no,
  //         email:this.form.value.manpower_details.email,
  //         total_job_experience:this.form.value.manpower_details.total_job_experience,
  //         qualification:this.form.value.manpower_details.qualification,
  //         job_description:this.form.value.manpower_details.job_description
  //       }
  //     }
  //   //console.log(updateData);

  // this.perExecutionService.editDetailsManpower(this.manpowerDetail.id,updateData).subscribe(
  //   res =>{
  //     // console.log(res);
  //     // console.log(res.tiffin_box_types.id)
     
  //     if(this.form.value.documents.length > 0){
  //       var forkArray = [];
  //       manpower_documents_data.forEach(x => {
  //         const formData: any = new FormData();
  //         formData.append("project",this.projectId);
  //         formData.append("module_id", res.manpower_details.id);
  //         formData.append("document_name", x.document_name);
  //         formData.append("document", x.manpower_document, x.manpower_document['name']);
  //         forkArray.push(this.perExecutionService.addDocumentManpower(formData))
  //       })
  //       forkJoin(forkArray).subscribe(
  //         result => {
  //           // console.log(result)
  //           result.forEach(y => {
  //             this.manpowerDocumentList.push(y)
  //           })
           
  //           // this.add_tender_document();
  //           this.loading = LoadingState.Ready;
  //         },
  //         err => {
  //           this.loading = LoadingState.Ready;
  //           // console.log(err)
  //         }
  //       )
  //      }
  //     this.getManpowerList();
  //     this.loading = LoadingState.Ready;
  //     this.toastr.success('Data has been saved','',{
  //       timeOut: 3000,
  //       });
  //   },
  //   error =>{
  //     this.loading = LoadingState.Ready;
      
  //     this.toastr.error('Something went wrong', '', {
  //     timeOut: 3000,
  //     });
     
  //   })
  // }
  // else{
  //   this.markFormGroupTouched(this.form);
  //   console.log("error");
  // }
  // }

  updateManpower()
  {
    var manpower_documents_data = [];
    manpower_documents_data = this.form.value.documents;

    if(this.form.valid){

      this.loading = LoadingState.Processing;
      const updateManpowerData = {
        project: this.projectId,
        designation:this.masterId,
        manpower: this.form.value.manpower,
        total_job_experience: this.form.value.total_job_experience,
        qualification: this.form.value.qualification,
        job_description: this.form.value.job_description,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),
        budgeted_salary: this.form.value.budgeted_salary,
        expected_salary: this.form.value.expected_salary
      }
     

      //this.tiffinBoxDocumentList = [];
      this.perExecutionService.editListDetailsManpower(this.manpowerListDetail.id,updateManpowerData).subscribe(
        res =>{
          console.log(res);
          var forkArray = [];

          if(this.form.value.documents.length > 0){
            manpower_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", this.manpowerListDetail.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.manpower_document, x.manpower_document['name']);
              forkArray.push(this.perExecutionService.addDocumentManpower(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.manpowerDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
      }
      this.getManpowerList(); 
      this.loading = LoadingState.Ready;
      this.toastr.success('Data has been saved','',{
        timeOut: 3000,
        });
        },
        error =>{
          
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
     
        }
      )
      

    }else{
      console.log("error");
    }
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      manpower_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);

  }

  onSelectFile(event, index){
    console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          manpower_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.markFormGroupTouched(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
   }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


}
