import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { LoadingState } from '../../loading/loading.component';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { LocalService } from '../../../services/local.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-requisition-approval',
  templateUrl: './requisition-approval.component.html',
  styleUrls: ['./requisition-approval.component.scss']
})
export class RequisitionApprovalComponent implements OnInit {
  @Input() requisitionId: number;
  @Output() checkStatusEvent = new EventEmitter<string>();

  loading: LoadingState = LoadingState.NotReady;
  itemList: any;
  form: FormGroup;
  approvalnItemData: any = [];
  projectId: number;
  siteLocation: number;
  type: number;
  disabledField: any = [];
  showApprovedBox: number;
  isModification: any = [];
  itemDetails: any = [];
  permissionList: any = [];
  currentUserArray: any = []
  approval_details: any = [];
  checkSubmission: any = []
  checkApproval: any = []
  higherLevelPermission:number;
  currentFormStatus:number;
  getStockOfAllUnit=[]
  constructor(private formBuilder: FormBuilder,private localService: LocalService, private executionPurchaseService: ExecutionPurchaseService, private toastr: ToastrService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      requisitions_master: null,
      project: null,
      site_location: null,
      type: null,
      item_approval_details: this.formBuilder.array([this.createApprovalForm()]),
    });

    this.approvalnItemData = [
      {
        item: "",
        initial_estimate: null,
        as_per_drawing: null,
        current_stock: null,
        l1_approval: null,
        arm_approval: null,
        approved_quantity: null,
        modification_quantity: null,
        approver_remarks: null,
        unit: null
      }
    ]

    this.isModification = [{
      enabled: true,
      isModification: null
    }]

    this.itemDetails = [
      {
        uom:null,
        approved_quantity: null,
        as_per_drawing: null,
        current_stock: null,
        initial_estimate: null,
        item: null,
        arm_approval: null,
        modification_quantity: null,
        approver_remarks: null,
      }
    ]
    this.getPermissionLevel();
    this.checkStatus();
   // this.getItemsBeforeApproveItems();
    //this.getItemsAfterApprove();
    
    //this.getCombinedData();
  }

  checkStatus(){
    this.loading = LoadingState.Processing;
    this.executionPurchaseService.getrequisitionSubmitForApproval(this.requisitionId).subscribe(
      res =>{
        this.currentFormStatus = res['result'].status;
       // console.log(this.currentFormStatus)
        this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
      }
    )
  }
  
  getCombinedData(){
    this.loading = LoadingState.Processing;
    if (this.requisitionId) {
      this.executionPurchaseService.itemsListings(this.requisitionId).subscribe(
        res => {
          this.itemList = res['result']['requisition_master'];
          this.projectId = this.itemList.project;
          this.siteLocation = this.itemList.site_location;
          this.type = this.itemList['type']['id'];
          
          this.loading = LoadingState.Ready;
          forkArray.push(this.executionPurchaseService.getPermissionLevel(params))
          forkArray.push(this.executionPurchaseService.approvedQuotationListings(this.requisitionId, this.projectId, this.siteLocation))
          forkJoin(forkArray).subscribe(
            ([coreData,ListingData]) => {
            
                if(coreData){
                var coreData = coreData['result'];
              //  console.log(coreData);
                }
                if(ListingData){
                var ListingData = ListingData['result'];
               // console.log(ListingData);
                }


              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              this.toastr.error('Something went wrong', '', {
                timeOut: 3000,
              });
              
              
            }
          )
        },
        error => {
          this.loading = LoadingState.Ready
        }
      )
    }
    var forkArray = [];
    let params: URLSearchParams = new URLSearchParams();
    params.set('section', 'requisition');
    

    
  }


  getPermissionLevel(){
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    params.set('section', 'requisition');
    params.set('requisition', this.requisitionId?this.requisitionId.toString():'');
    
    this.executionPurchaseService.getPermissionLevel(params).subscribe(
      res => {
        var userid = this.localService.getJsonValue('userid');
      
        if(res['result'].user_approve_details.length > 0){
          this.permissionList = res['result'];
          this.higherLevelPermission = this.permissionList['user_approve_details'].length - 1;
          this.permissionList.user_approve_details.forEach(x => {
            if(x.user_details.id == userid){
              this.currentUserArray = x;
            }
          });
          this.getItemsBeforeApproveItems();
        } 
       
      
        this.loading = LoadingState.Ready
       
      },
      error => {
        this.loading = LoadingState.Ready
      }
    )
  }

  createApprovalForm() {
    return this.formBuilder.group({
      item: "",
      uom:null,
      initial_estimate: [null, Validators.required],
      as_per_drawing: [null, Validators.required],
      current_stock: [null, Validators.required],
      approved_quantity: [null, Validators.required],
      modification_quantity: [null],
      approver_remarks: [null],
      arm_approval: [null, Validators.required]

    })
  }

  add_dynamic_approval() {
    const control = <FormArray>this.form.controls['item_approval_details'];
    control.push(this.createApprovalForm());
    var q = {
      item: "",
      uom:null,
      initial_estimate: null,
      as_per_drawing: null,
      current_stock: null,
      arm_approval: null,
      approved_quantity: null,
      modification_quantity: null,
      approver_remarks: null,
      unit: null
    }
    this.approvalnItemData.push(q);

    //remove uneccessary row
    control.removeAt(this.itemList['requisition_details'].length);
    this.approvalnItemData.splice(this.itemList['requisition_details'].length, 1)
  }

  getItemsBeforeApproveItems() {
   
    this.loading = LoadingState.NotReady;

    if (this.requisitionId) {
      var params = "?section_name=requisition";
      this.executionPurchaseService.itemsListings(this.requisitionId,params).subscribe(
        res => {
          this.itemList = res['result']['requisition_master'];
        
          if (this.itemList['requisition_details'].length > 0) {
            this.projectId = this.itemList.project;
            this.siteLocation = this.itemList.site_location;
            this.type = this.itemList['type']['id'];
            
            this.itemList['requisition_details'].forEach((x, index) => {
              var s = {
                enabled: true,
                isModification: null
              }
              this.isModification.push(s);
              this.add_dynamic_approval();
              this.approvalnItemData[index].item_id = x.item_details.id;
              this.approvalnItemData[index].item = x.item_details.name;
              this.approvalnItemData[index].new_requsition = x.quantity;
              this.approvalnItemData[index].uom = x.uom;
              this.approvalnItemData[index].remarks = x.remarks;
              this.approvalnItemData[index].total_previous_requisition = x.actual_consumption.avg_of_last_three_requsition;
              this.approvalnItemData[index].avg_of_last_three_requsition = x.actual_consumption.avg_of_last_three_requsition;
              this.approvalnItemData[index].initial_estimate = x.actual_consumption.initial_estimate;
              this.approvalnItemData[index].as_per_drawing = x.actual_consumption.as_per_drawing;
              this.approvalnItemData[index].current_stock = x.current_stock;
              this.approvalnItemData[index].approved_quantity = (x.approved_quantity !== null ) ? x.approved_quantity : x.quantity;
              this.approvalnItemData[index].modification_quantity = null;
              this.approvalnItemData[index].approver_remarks = x.approver_remarks;
              this.approvalnItemData[index].l1_approval = 0;
              this.approvalnItemData[index].unit = x.uom_name;
              this.approvalnItemData[index].arm_approval = 0;
              this.approvalnItemData[index].approval_details =x.permission_details;
              this.checkSubmission = this.approvalnItemData[index].approval_details.filter(z => z.permission_num == this.currentUserArray.permission_num);
              // this.checkApproval = this.approvalnItemData[index].approval_details.filter(z => z.arm_approval == null);
              if(this.checkSubmission == 0) {
                this.disabledField[index] = 1;
              } else {
                var indexForL2;
                if(this.checkSubmission.permission_num < this.approvalnItemData[index].approval_details[this.higherLevelPermission].permission_num && this.checkSubmission[0].permission_num > 0) {
                  indexForL2 = this.approvalnItemData[index].approval_details.findIndex(z => z.permission_num == this.currentUserArray.permission_num);
                }
                if(indexForL2 > 0) {

                } else {
                  if(this.approvalnItemData[index].approval_details[this.higherLevelPermission].approval != null || this.checkSubmission[0].approval != null){
                    this.disabledField[index] = 1;
                  } else{
                    this.disabledField[index] = 0;
                  }
                }
                
              }
              
            });
            this.getStock()
          }
          //console.log(this.checkSubmission[0].approval)
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  getStock(){
    
    this.loading = LoadingState.Processing;
   
    this.itemList['requisition_details'].forEach((x, index) => {

    let params: URLSearchParams = new URLSearchParams();
    params.set('item_code', x.item_details.mat_code.toString());
    params.set('type_code', this.itemList['type'].code.toString());
    params.set('project', this.projectId.toString());
    params.set('site_location', this.siteLocation.toString());

    this.executionPurchaseService.getStock(params)
      .subscribe(
          res => {
            if(res['results']){
                var array = res['results'].unit
                var stringOut:any = []
                array.forEach((x,index)=> {
                  var string = x.unit+' : '+x.current_stock;
                  stringOut.push(string)
                });
                  this.getStockOfAllUnit[index] = stringOut.toString();
       
            //  console.log(this.getStockOfAllUnit)
            }
          this.loading = LoadingState.Ready;
          },
        err => {
          this.loading = LoadingState.Ready;
        }
    )

      })
  }

  getItemsAfterApprove() { //will use 
    this.loading = LoadingState.Processing;

    if (this.requisitionId) {
      this.executionPurchaseService.itemsListings(this.requisitionId).subscribe(
        res => {
          this.itemList = res['result']['requisition_master'];
          this.projectId = this.itemList.project;
          this.siteLocation = this.itemList.site_location;
          this.type = this.itemList['type']['id'];
          this.executionPurchaseService.approvedQuotationListings(this.requisitionId, this.projectId, this.siteLocation).subscribe(
            res2 => {
           
              if (res2['result'].length > 0) { //check if approved or not
             
                res2['result'].forEach((x, index) => {
                  this.add_dynamic_approval();
                  var s = {
                    enabled: true,
                    isModification: null
                  }
                  this.isModification.push(s);

                  this.approvalnItemData[index].item_id = x.item;
                  this.approvalnItemData[index].item = x.actual_consumption_approval.item_name;
                  this.approvalnItemData[index].uom = x.uom;
                  this.approvalnItemData[index].new_requsition = x.actual_consumption_approval.new_requsition;
                  this.approvalnItemData[index].total_previous_requisition = x.actual_consumption_approval.total_previous_requisition;
                  this.approvalnItemData[index].avg_of_last_three_requsition = x.actual_consumption_approval.avg_of_last_three_requsition;
                  this.approvalnItemData[index].initial_estimate = x.initial_estimate;
                  this.approvalnItemData[index].as_per_drawing = x.as_per_drawing;
                  this.approvalnItemData[index].current_stock = x.current_stock;
                  this.approvalnItemData[index].l1_approval = x.l1_approval;
                  this.approvalnItemData[index].arm_approval = x.arm_approval;
                  this.approvalnItemData[index].approved_quantity = x.approved_quantity;
                  this.approvalnItemData[index].modification_quantity = null;
                  this.approvalnItemData[index].approver_remarks = x.approver_remarks;
                  this.approvalnItemData[index].remarks = x.actual_consumption_approval.remarks;
                  this.approvalnItemData[index].unit = x.actual_consumption_approval.unit_name;
                  this.approvalnItemData[index].approval_details =x.permission_details;
                
                
                });
              
                
              } else {
               
                this.getItemsBeforeApproveItems();
              }
            }
          )
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  setModification(index){
    this.form.controls['item_approval_details']['controls'][index]['controls']['modification_quantity'].setValidators([Validators.required]);
    this.form.controls['item_approval_details']['controls'][index]['controls']['modification_quantity'].setErrors({required:true});
    this.isModification[index].enabled = false
  }
  setNonModification(index){
    this.form.controls['item_approval_details']['controls'][index]['controls']['modification_quantity'].clearValidators();
    this.form.controls['item_approval_details']['controls'][index]['controls']['modification_quantity'].setErrors(null);
    this.isModification[index].value = null
    this.isModification[index].enabled = true
   // console.log(this.isModification)
  }

  

  submitItemWiseApproval(index) {
    if(this.form.controls['item_approval_details']['controls'][index].status == 'INVALID') {
      this.markFormGroupItemWiseTouched(this.form, index);
    } else {
      const arrayVal = this.form.controls['item_approval_details']['controls'][index].value;
      this.itemDetails = [
        {
          uom:arrayVal.uom,
          approved_quantity: (arrayVal.modification_quantity) ?  parseFloat(arrayVal.modification_quantity) : arrayVal.approved_quantity,
          as_per_drawing: arrayVal.as_per_drawing,
          current_stock: arrayVal.current_stock,
          initial_estimate: arrayVal.initial_estimate,
          item: arrayVal.item,
          arm_approval: parseFloat(arrayVal.arm_approval),
          modification_quantity: null,
          approver_remarks: arrayVal.approver_remarks,
          permission_level: this.currentUserArray.id,
        }
      ]
      this.form.value.item_approval_details = this.itemDetails.slice(0);
      this.executionPurchaseService.submitApprovals(this.form.value).subscribe(
        res => {
          this.approvalnItemData[index].item_id = res['item_approval_details'][0].item;
          this.approvalnItemData[index].total_previous_requisition = res['item_approval_details'][0].item_details.total_quantity;
          this.approvalnItemData[index].avg_of_last_three_requsition = res['item_approval_details'][0].item_details.last_3_avg;
          this.approvalnItemData[index].approved_quantity = res['item_approval_details'][0].approved_quantity;
          var checkIndex = this.approvalnItemData[index].approval_details.findIndex(z => z.permission_num == this.currentUserArray.permission_num);
          this.approvalnItemData[index].approval_details[checkIndex].approval = res['item_approval_details'][0].arm_approval;
          this.approvalnItemData[index].approval_details[checkIndex].approved_date = res['item_approval_details'][0].created_at;
          console.log(this.approvalnItemData[index]);
          //check if all items are not rejected
          this.checkApproval = res['item_approval_details'].filter(z => z.arm_approval == 0);
          if(this.checkApproval.length != res['item_approval_details'].length){
            if(this.currentUserArray.permission_num == this.permissionList['user_approve_details'][this.higherLevelPermission].permission_num){
              this.checkStatusEvent.next();
            }
          }
          this.disabledField[index] = 1
          this.loading = LoadingState.Ready;
            this.toastr.success('Data has been saved', '', {
            timeOut: 3000,
          });
          this.itemDetails = [
            {
              uom:null,
              approved_quantity: null,
              as_per_drawing: null,
              current_stock: null,
              initial_estimate: null,
              item: null,
              arm_approval: null,
              modification_quantity: null,
              approver_remarks: null,
              permission_level: null,
            }
          ]
        },
        error => {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  markFormGroupItemWiseTouched(formGroup: FormGroup, index) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c, key) => {
          if(key == index) {
            this.markFormGroupTouched(c);
          }
        });
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
  getFormArrayControl(form) {
    return form.get('item_approval_details').controls
  }
}
