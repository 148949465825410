import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddEstablishmentModalComponent } from '../add-establishment-modal/add-establishment-modal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-resource-establishment-tab',
  templateUrl: './resource-establishment-tab.component.html',
  styleUrls: ['./resource-establishment-tab.component.scss']
})
export class ResourceEstablishmentTabComponent implements OnInit {
  @Input() tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  tenderEstablishmentlList: any = [];
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    var params = '';
    params = '?tender=' + this.tenderId
    forkArray.push(this.tenderService.getTenderResourceEstablishmentList(params))
    forkJoin(forkArray).subscribe(
      (res: any[]) => {
       // console.log(res)
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.tenderEstablishmentlList = res[i]['result'];
          }
        }
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }



  openEstablishmentModal() {
    let dialogRef = this.dialog.open(AddEstablishmentModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderEstablishmentlList.push(result['data'])
      }
    })
  }

  editEstablishment(i) {
    let establishmentDetailsData = Object.assign({}, this.tenderEstablishmentlList[i]);
    let dialogRef = this.dialog.open(AddEstablishmentModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, establishmentDetails: establishmentDetailsData }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if (result['data'] != undefined) {
        this.tenderEstablishmentlList[i] = result['data']
      }
    })
  }

}
