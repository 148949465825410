import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { projectSiteServices } from '../../../services/project-sites.service';
import { SettingService } from '../../../services/setting.service'
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { imageData } from './pdf-image'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { forkJoin, from } from 'rxjs';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-new-requisition-tab',
  templateUrl: './new-requisition-tab.component.html',
  styleUrls: ['./new-requisition-tab.component.scss']
})
export class NewRequisitionTabComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  @Input() requisitionId: number;
  @Output() valueChange = new EventEmitter();
  @Output() checkStatusEvent = new EventEmitter<string>();

  form: FormGroup;
  projectSiteList: any = [];
  requisition_id: number;
  activityList: any = [];
  projectId: any = [];
  locationId: number;
  itemListing: any = [];
  requisitionDetailsArr: any = [];
  itemList: any = [];
  typeList: any = [];
  type_id: number;
  itemsUmo: any = [];
  itemTypeDetail: any = [];
  oldFormData: any = [];
  mr_date_val: any;

  editFromList: boolean;
  requisitionData: any = [];
  requisitionItemData: any = [];
  stockArray: any = [];
  quantity: number;
  procurement_from_site: number;
  procurement_from_ho: number;
  stockReadOnly: boolean
  isApproved: number;
  requisition_list: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  tempItemListid: any = [];
  tempItemDetails: any = [];
  seletedItemDetail: any = [];
  approvalFormData: any = [];
  locationName: string;
  projectnName: string;
  minDate;
  readonlyField: number;
  btnHide: number
  getStockOfAllUnit: any = []
  reportLink: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private projectSiteServices: projectSiteServices,
    private ExecutionPurchaseService: ExecutionPurchaseService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public settingService: SettingService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.isApproved = 0;
    this.btnHide = 1;
    this.stockReadOnly = true;
    var mrDates = new Date();

    this.mr_date_val = {
      year: mrDates.getFullYear(),
      month: mrDates.getMonth() + 1,
      day: mrDates.getDate()
    }

    this.form = this.formBuilder.group({
      site_location: [null, Validators.required],
      project: [null, Validators.required],
      mr_date: [null, Validators.required],
      type: [null, Validators.required],
      type_id: [],
      requisition_details: this.formBuilder.array([this.createRequisitionForm()]),
    });

    this.requisitionItemData = [
      {
        item: null,
        itemCode: null,
        description: '',
        //  terms: '',
        uom: null,
        quantity: '',
        current_stock: 0,
        procurement_site: null,
        procurement_ho: null,
        required_by: '',
        required_on: '',
        hsn_sac_code: '',
        activity_details: null,
        remarks: '',
        show: true
      }
    ]
    this.stockArray = [
      {
        stock: null
      }
    ]
    if (this.requisitionId) {
      this.locationId = this.requisitionData.site_location;
      this.getIsRequisitionApprove();
    }


    this.getAllMasterData(); //get initial data


  }

  getIsRequisitionApprove() {

    this.ExecutionPurchaseService.getrequisitionSubmitForApproval(this.requisitionId).subscribe(
      res => {

        if (res['result'].status == 1 || res['result'].status > 1) { //submitted for approval

          this.isApproved = 1;
          this.btnHide = 1;
        } else {
          this.btnHide = 0;
        }
        // this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
  createRequisitionForm() {
    return this.formBuilder.group({

      item: [null, Validators.required],
      itemCode: [null],
      description: [null, Validators.required],
      //  terms: [null,Validators.required],
      uom: [null, Validators.required],
      quantity: [null, Validators.required],
      current_stock: [0],
      procurement_site: [null, Validators.required],
      procurement_ho: [null, Validators.required],
      required_by: [null, Validators.required],
      required_on: [null, Validators.required],
      hsn_sac_code: [null],
      activity_details: [null, Validators.required],
      remarks: [null],
    })
  }

  add_another_requisition() {
    const control = <FormArray>this.form.controls['requisition_details'];
    control.push(this.createRequisitionForm());
    var q = {
      item: null,
      itemCode: null,
      description: '',
      //  terms: '',
      uom: null,
      quantity: '',
      current_stock: 0,
      procurement_site: null,
      procurement_ho: null,
      remarks: '',
      show: true,
      isEdit: false,
      editedItemName: ''
    }
    this.requisitionItemData.push(q);

    var s = {
      stock: null
    }
    this.stockArray.push(s)

    let formItemDetails = this.form.value.requisition_details.filter(x => x.item == this.seletedItemDetail.id);
    this.tempItemDetails.push(this.seletedItemDetail);


    let unitLen: number;
    this.itemTypeDetail.forEach((x, index) => {
      let xIndex = index;
      if (formItemDetails.length > 0) {
        formItemDetails.forEach((z, index) => {
          if (z.item == x.id) {
            x.unit.forEach((u, index) => {
              if (u.id == z.uom) {
                x.unit.splice(index, 1)
                unitLen = x.unit.length;
              }
              if (unitLen < 1) {
                this.itemTypeDetail.splice(xIndex, 1)
              }
            })
          }
        })
      }



    })




  }

  deleteRequisition(index: number, itemId?: number) {
  //  console.log(itemId)
    const control = <FormArray>this.form.controls['requisition_details'];
    control.removeAt(index);
    this.requisitionItemData.splice(index, 1)
    if (this.requisitionId) {
      this.oldFormData.splice(index, 1)
    }
    if (itemId != null) {
      let getRemovedItem = this.tempItemDetails.filter(x => x.id == itemId)

      let checkItem = this.itemTypeDetail.filter(x => x.id == itemId)

      if (getRemovedItem.length > 0 && checkItem.length < 1) {

        this.itemTypeDetail.push(getRemovedItem[0])
      }
      this.tempItemDetails.forEach((x, index) => {
        if (x.id == itemId) {
          this.tempItemDetails.splice(index, 1)
        }
      })
    }
    if (index == 1) {
      this.itemTypeDetail.push(this.tempItemDetails[0])
      this.tempItemDetails = [];
    }
    //console.log(this.tempItemDetails);
  }


  getStock(uomId: number, index: number) {

    this.loading = LoadingState.Processing;

    let itemCode = this.form.value.requisition_details[index].itemCode;
   // console.log(itemCode)
   // console.log(this.seletedItemDetail.code)
    let params: URLSearchParams = new URLSearchParams();
    if (this.seletedItemDetail.code) {
      itemCode = this.seletedItemDetail.code
    }
    params.set('item_code', itemCode.toString());
    params.set('type_code', this.requisitionData.code.toString());
    if (uomId) {
      params.set('unit', uomId.toString());
    }

    params.set('project', this.requisitionData.project.toString());
    params.set('site_location', this.requisitionData.site_location.toString());

    this.ExecutionPurchaseService.getStock(params)
      .subscribe(
        res => {
          if (res['results']) {
            if (!uomId) {
              var array = res['results'].unit
              var stringOut: any = []
              array.forEach((x, index) => {
                var string = x.unit + ' : ' + x.current_stock;
                stringOut.push(string)
              });
              this.getStockOfAllUnit[index] = stringOut.toString();
            }


            this.stockArray[index].stock = res['results'].current_stock;

            if (this.stockArray[index].stock > 0) {

              this.stockReadOnly = true;
              this.form.controls['requisition_details']['controls'][index]['controls']['current_stock'].disable();
            }
            else {
              this.stockReadOnly = false;
              this.form.controls['requisition_details']['controls'][index]['controls']['current_stock'].enable();
            }
          }
          this.loading = LoadingState.Ready;
        },
        err => {
          this.loading = LoadingState.Ready;
        }
      )
  }

  getAllMasterData() {
    this.loading = LoadingState.Processing;
    var forkArray = [];

    forkArray.push(this.projectSiteServices.getProjectSitesList())
    forkArray.push(this.ExecutionPurchaseService.getActivities())
    forkArray.push(this.ExecutionPurchaseService.requisitionTypes())
    //call this api when requisionId is define
    if (this.requisitionId != undefined) {

      forkArray.push(this.ExecutionPurchaseService.itemsListings(this.requisitionId))
    }


    forkJoin(forkArray).subscribe(
      ([projectSiteList, activityList, typeList, oldFormData]) => {

        if (projectSiteList) {
          this.projectSiteList = projectSiteList['result'];
        }
        if (activityList) {
          this.activityList = activityList['result'];
        }
        if (typeList) {
          this.typeList = typeList['result'];
          if (this.typeList && this.requisitionId) {
            this.getItemList();
          }
        }
        if (oldFormData) {
          this.oldFormData = oldFormData['result'].requisition_master.requisition_details;

          var start_date = new Date(oldFormData['result'].requisition_master.mr_date);
          var mrDates = {
            year: start_date.getFullYear(),
            month: start_date.getMonth() + 1,
            day: start_date.getDate()
          }
          this.oldFormData.forEach((x, index) => {

            this.add_another_requisition();
           // console.log(x.item_details.mat_code);
            this.requisitionItemData[index].item = x.item_details.id;
            this.requisitionItemData[index].quantity = x.quantity;
            this.requisitionItemData[index].description = x.item_details.description;
            this.requisitionItemData[index].editedItemName = x.item_details.id;
            //  this.requisitionItemData[index].terms = x.terms;
            this.requisitionItemData[index].uom = x.uom;
            //  this.requisitionData[index].itemCode = x.item_details.mat_code;
            this.requisitionItemData[index].uom_name = x.item_details.id;

            this.requisitionItemData[index].current_stock = x.current_stock;
            this.stockArray[index].stock = x.current_stock;

            this.requisitionItemData[index].procurement_site = x.procurement_site;
            this.requisitionItemData[index].procurement_ho = x.procurement_ho;
            this.requisitionItemData[index].required_by = x.required_by;
            var required_date = new Date(x.required_on)
            this.requisitionItemData[index].required_on = {
              year: required_date.getFullYear(),
              month: required_date.getMonth() + 1,
              day: required_date.getDate()
            }
            var activityArr = [];
            x.activity_details.forEach((z) => {

              activityArr.push(z.id);
            })

            this.requisitionItemData[index].hsn_sac_code = x.hsn_sac_code;
            this.requisitionItemData[index].activity_details = activityArr;
            this.requisitionItemData[index].remarks = x.remarks;
            this.requisitionItemData[index].show = false;
            this.requisitionItemData[index].isEdit = false;
            this.itemsUmo[index] = Array({ id: x.uom, unit: x.uom_name });
          })
        }


        /* START get ready location & project if came from project list*/
        var locationIdFromProject = this.route.snapshot.params['siteLocation'];
        if (locationIdFromProject != undefined) {
          this.projectSiteList.forEach((x, index) => {
            if (x.id == locationIdFromProject) {
              this.requisitionData.site_location = x.id;
              this.readonlyField = x.id
            }
          });
        }
        /* END */

        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )

  }



  requireDate(event) {
    //console.log(event)
    if (event != null) {
      var requireDate = new Date(event.year, event.month - 1, event.day);
      requireDate.setDate(requireDate.getDate() + 7);
      var day = requireDate.getDate();
      var month = requireDate.getMonth() + 1;
      var year = requireDate.getFullYear();
      this.minDate = {
        year: year,
        month: month,
        day: day
      }
    }

  }

  getProjectId(locationId: number) {

    if (locationId) {
      var params = '';
      params = '?site_location=' + locationId;
      this.ExecutionPurchaseService.getProjectId(params)
        .subscribe(
          result => {
            this.locationId = result['result'];
            /*START below if came from project list*/
            var projectIdFromProject = this.route.snapshot.params['projectId'];
            if (projectIdFromProject != undefined) {
              if (this.locationId[0].id == projectIdFromProject) {
                this.requisitionData.project = this.locationId[0].id;
              }
            } 
            this.form.patchValue({
              project: this.locationId[0].id
            })
            /*END*/

            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
          }
        )
    }


  }

  resetAll() {
    this.requisitionData.project = null;
    this.requisitionData.code = null;
    this.form.patchValue({
      mr_date: null
    })
  }

  saveRequisition() {


    if (this.form.valid) {
      if (this.requisitionId == undefined) {

        this.loading = LoadingState.Processing;
        this.requisitionDetailsArr = [];
        this.requisitionDetailsArr = Object.assign([], this.form.value.requisition_details);

        var activityArr = [];

        this.requisitionDetailsArr.forEach((x, index) => {
          var activity_details = x.activity_details;
          activityArr = [];
          activity_details.forEach(y => {
            var d = {
              activity: y
            }
            activityArr.push(d)


          })
          x.activity_details = activityArr
          let required_on_date = this.settingService.getProperDate(this.requisitionDetailsArr[index].required_on);
          x.required_on = required_on_date.toISOString();//aa

          this.requisitionItemData[index].show = false;
          x.current_stock = this.stockArray[index].stock;
        })


        var mrDate = this.settingService.getProperDate(this.form.value.mr_date);

        var data = {
          site_location: this.form.value.site_location,
          project: this.form.value.project,
          type: this.form.value.type_id,
          mr_date: mrDate.toISOString(),//aa
          requisition_details: this.requisitionDetailsArr,
        }

        //console.log(data)

        this.ExecutionPurchaseService.saveRequisitionForm(data).subscribe(
          res => {
            this.requisition_id = res.requisition_master;
            this.requisitionId = res.requisition_master;

            this.btnHide = 0;

            this.getItemList();
            this.add_another_requisition();
            this.toastr.success('Data has been saved', '', {
              timeOut: 3000,
            });

          },
          error => {
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        )
      }
      else {

      }
    } else {
      this.markFormGroupTouched(this.form);
    }
  }

  submitForApproval() {
    this.loading = LoadingState.Processing;
    this.valueChange.emit(this.requisitionId);

    let r_details = [];
    if (this.requisitionId) {
     // console.log(this.requisitionData)
      this.requisitionData.code = this.requisitionData.type.code;
      // var start_date = new Date(this.requisitionData.mr_date);
      // this.requisitionData.mr_date = {
      //   year: start_date.getFullYear(),
      //   month: start_date.getMonth() + 1,
      //   day: start_date.getDate()
      // }
      this.itemList.forEach(x => {
        r_details.push({ id: x.item_details.id, name: x.item_details.name, description: x.item_details.description, mat_code: x.item_details.mat_code, current_stock: x.current_stock, uom: x.uom })
      })

      this.approvalFormData.push({ id: this.requisitionData.id, status: 1, module_name: 'requisitions_tab', site_location: this.requisitionData.site_location, project: this.requisitionData.project, type: this.requisitionData.type, requisition_details: r_details });

      if (this.approvalFormData[0]) {

        var data = this.approvalFormData[0];
        this.ExecutionPurchaseService.requisitionSubmitForApproval(this.requisitionId, data).subscribe(
          res => {
            if (res.status == 1) {
              this.isApproved = 1;

              this.checkStatusEvent.next();
              this.toastr.success('Submitted for approval', '', {
                timeOut: 3000,
              });
            }
            this.loading = LoadingState.Ready;

          },
          error => {
            //console.log(error);
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        )

      }
    }

  }

  update() {

    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      this.requisitionDetailsArr = [];
    //  console.log(this.requisitionDetailsArr);
      this.requisitionDetailsArr = Object.assign([], this.form.value.requisition_details);
      var activityArr = [];

      this.requisitionDetailsArr.forEach((x, index) => {
        var activity_details = x.activity_details;
        activityArr = [];
        activity_details.forEach(y => {
          var d = {
            activity: y
          }
          activityArr.push(d)
        })

        x.activity_details = activityArr;

        let required_on_date = this.settingService.getProperDate(this.requisitionDetailsArr[index].required_on);

        x.required_on = required_on_date.toISOString();//aa

        this.requisitionItemData[index].show = false;
        x.current_stock = this.stockArray[index].stock;
      })

      var data = {
        requisition_list: this.requisitionDetailsArr
      }

      this.ExecutionPurchaseService.updateRequisitionForm(this.requisitionId, data).subscribe(
        res => {
          this.add_another_requisition();
          this.getItemList();
          this.toastr.success('Data has been saved', '', {
            timeOut: 3000,
          });
        },
        error => {
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form);
    }


  }

  deleteItem(id: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
      this.dialogRef = null;
    })
  }

  getItemList() {
    this.loading = LoadingState.Processing;
    let r_details = [];
    if (this.requisitionId) {
      var params = "?section_name=requisition";
      this.ExecutionPurchaseService.itemsListings(this.requisitionId, params).subscribe(
        res => {
         // this.reportLink = res['url']
          this.reportLink = true
          this.requisitionData = res['result'].requisition_master;
          this.requisitionData.code = this.requisitionData.type.code;
          var start_date = new Date(this.requisitionData.mr_date);

          this.requisitionData.mr_date = {
            year: start_date.getFullYear(),
            month: start_date.getMonth() + 1,
            day: start_date.getDate()
          }
          this.itemList = res['result'].requisition_master.requisition_details;
        console.log(this.itemList)
          this.itemList.forEach(x => {
            if (this.tempItemListid.indexOf(x.item_details.id) == -1) {
              this.tempItemListid.push(x.item_details.id);
            }

            // r_details.push({id:x.item_details.id,name:x.item_details.name,description:x.item_details.description,mat_code:x.item_details.mat_code,current_stock:x.current_stock,uom:x.uom})
          })


          this.minDate = {
            year: start_date.getFullYear(),
            month: start_date.getMonth() + 1,
            day: start_date.getDate() + 7
          }

          this.getTypeDetail(this.requisitionData.code);

          //  this.approvalFormData.push({id:this.requisitionData.id,status:1,module_name:'requisitions_tab',site_location:this.requisitionData.site_location,project:this.requisitionData.project,type:this.requisitionData.type,requisition_details:r_details});

          // console.log(this.approvalFormData)

          this.loading = LoadingState.Ready
        },
        error => {
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  getTypeDetail(typeCode) {

    if (typeCode) {
      this.loading = LoadingState.Processing;
      var index = this.typeList.findIndex(k => k.code == typeCode)
      this.type_id = this.typeList[index]['id'];
      //var index = this.typeList.findIndex(k => k. == x)
      if (typeCode == 'T002') {
        //console.log(this.form.controls['requisition_details']['controls'][0]['controls']['uom'].setValidators(null));
        //requisition_details
      }
      var params = 'type_code=' + typeCode + '&project=' + this.requisitionData.project + '&site_location=' + this.requisitionData.site_location
      this.ExecutionPurchaseService.getTypeDetail(params).subscribe(
        res => {

          this.itemTypeDetail = res['result'];
          let uomFilter = [];

          this.tempItemListid.forEach(id => {
            let uomFilterFromItemList = this.itemList.filter(x => x.item_details.id == id);
            let uomFilterFromitemDetails = this.itemTypeDetail.filter(x => x.id == id)[0];

            this.itemTypeDetail.forEach((z, index) => {

              if (id == z.id) {
                if (uomFilterFromItemList.length == uomFilterFromitemDetails.unit.length) {
                  this.itemTypeDetail.splice(index, 1)
                }

              }
            })
          })
          this.loading = LoadingState.Ready
        },
        error => {
          this.loading = LoadingState.Ready
        }
      )

      if (this.requisitionId == undefined) {
        this.form.reset({
          site_location: this.form.value.site_location,
          project: this.form.value.project,
          type: typeCode,
          mr_date: this.form.value.mr_date,
          type_id: this.type_id
        })
      }



    } else {

    }

  }

  getItemDetail(itemcode, index) {

    this.seletedItemDetail = this.itemTypeDetail.filter(obj => obj.id == itemcode)[0];
    //console.log(this.seletedItemDetail)
    let uomFilterArray = this.itemList.filter(x => x.item_details.id == this.seletedItemDetail.id)

    let uomFilter = [];
    let unitFilter = [];

    if (uomFilterArray.length > 0) {
      uomFilterArray.forEach(x => {
        uomFilter.push(x.uom)
      })
    }

    if (this.seletedItemDetail) {
      this.requisitionItemData[index].description = this.seletedItemDetail.description;
      this.seletedItemDetail.unit.forEach((x, index) => {
        if (uomFilter.indexOf(x.id) == -1) {
          unitFilter.push(x)
        }
      })

      this.itemsUmo[index] = unitFilter;
      this.requisitionItemData[index].itemCode = this.seletedItemDetail.code;

    }

    this.requisitionItemData[index].uom = null;
    this.stockArray[index].stock = null;
    this.form.controls['requisition_details']['controls'][index]['controls']['current_stock'].disable();
    //console.log(this.itemTypeDetail)
    //console.log(itemcode)
    //remove selected items from array
    this.itemTypeDetail = this.itemTypeDetail.filter(obj => obj.id != itemcode);
    this.getStock(null, index);

  }
  

  searchByDescription(index, event) {
    if(event.keyCode == 40 || event.keyCode == 38){
      event.preventDefault();
    }
    else{
      if (event.target.value.length > 2 && this.requisitionData.code && this.requisitionData.project && this.requisitionData.site_location) {
        var params = 'type_code=' + this.requisitionData.code + '&project=' + this.requisitionData.project + '&site_location=' + this.requisitionData.site_location + '&search=' + event.target.value;
        this.ExecutionPurchaseService.getTypeDetail(params).subscribe(
          res => {
            this.itemTypeDetail = res['result'];

            if (this.itemList.length > 0) { //filter if item available in the list

              this.itemList.forEach(x => {
                this.itemTypeDetail = this.itemTypeDetail.filter(obj => obj.description != x.item_details.description);
              });

              if (this.itemTypeDetail.length > 0) {
                this.requisitionItemData.forEach(x => {
                  this.itemTypeDetail = this.itemTypeDetail.filter(obj => obj.description != x.description);
                });
              }


            } else {  // filter on the form

              if (this.itemTypeDetail.length > 0) {
                this.requisitionItemData.forEach(x => {
                  this.itemTypeDetail = this.itemTypeDetail.filter(obj => obj.description != x.description);
                });
              }
            }


            this.loading = LoadingState.Ready
          },
          error => {
            this.loading = LoadingState.Ready
          }
        )
      } else {
        this.itemTypeDetail = [];
        //  this.getItemDetail('',index);
        this.requisitionItemData[index].uom = null
        this.stockArray[index].stock = null
        this.requisitionItemData[index].uom_name = null
      }
    }
  }

  onSelectionChanged(index, event) {
   // console.log(index)
    this.requisitionItemData[index].uom_name = null;
    this.requisitionItemData[index].uom = null;
    if (this.itemTypeDetail.length > 0) {
      this.itemTypeDetail = this.itemTypeDetail.filter(obj => obj.id == event.option.value);
      this.requisitionItemData[index].description = this.itemTypeDetail[0].description;
     // console.log(this.itemTypeDetail)
      this.requisitionItemData[index].editedItemName = this.itemTypeDetail[0].id;
      this.itemsUmo[index] = this.itemTypeDetail[0].unit;
      // this.requisitionItemData[index].itemCode = this.itemTypeDetail[index].unit;
      this.getItemDetail(this.itemTypeDetail[0].id, index)
    }
  }

  editItem(index) {

    this.requisitionItemData[index].show = true;

  }
  editAllItem(items) {

    var blankItem = items.length;
    this.deleteRequisition(blankItem);

    items.forEach((x, index) => {
     // console.log(x)
      this.requisitionItemData[index].show = true;
      this.requisitionItemData[index].isEdit = true;
      this.stockArray[index].stock = x.current_stock;
      this.requisitionItemData[index].uom_name = x.item_details.id;
      this.requisitionItemData[index].itemCode = x.item_details.mat_code;
      this.requisitionItemData[index].editedItemName = x.item_details.id;
      this.form.controls['requisition_details']['controls'][index]['controls']['current_stock'].disable();


      //get stock of all unit
      let params: URLSearchParams = new URLSearchParams();
      params.set('item_code', this.requisitionItemData[index].itemCode.toString());
      params.set('type_code', this.requisitionData.code.toString());
      params.set('project', this.requisitionData.project.toString());
      params.set('site_location', this.requisitionData.site_location.toString());
      this.ExecutionPurchaseService.getStock(params)
        .subscribe(
          res => {
            if (res['results']) {
              var array = res['results'].unit
              var stringOut: any = []
              array.forEach((x, index) => {
                var string = x.unit + ' : ' + x.current_stock;
                stringOut.push(string)
              });
              this.getStockOfAllUnit[index] = stringOut.toString();

            //  console.log(this.getStockOfAllUnit)
            }
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
          }
        )
    })
    //this.getStock(null,0);
  }

  checkQuantity(index) {

    this.quantity = this.requisitionItemData[index].quantity != undefined ? parseFloat(this.requisitionItemData[index].quantity) : 0;
    this.procurement_from_ho = this.requisitionItemData[index].procurement_ho != undefined ? parseFloat(this.requisitionItemData[index].procurement_ho) : 0;
    this.procurement_from_site = this.requisitionItemData[index].procurement_site != undefined ? parseFloat(this.requisitionItemData[index].procurement_site) : 0;


    if ((this.procurement_from_ho + this.procurement_from_site) !== this.quantity) {
      //console.log(this.requisitionItemData[index]['procurement_ho'])
      if (this.requisitionItemData[index]['procurement_ho'] !== null && this.requisitionItemData[index]['procurement_site'] !== null) {
        this.requisitionItemData[index]['procurement_ho'] = null;
        this.requisitionItemData[index]['procurement_site'] = null;
        this.toastr.error('Total quantity is not matched', '', {
          timeOut: 3000,
        });
      }

    }

  }

  checkCurrentStock(index, value, itemCode, uomId) {
    this.loading = LoadingState.Processing;

    let params: URLSearchParams = new URLSearchParams();
    params.set('item_code', itemCode.toString());
    params.set('type_code', this.requisitionData.code.toString());
    params.set('unit', uomId.toString());
    params.set('project', this.requisitionData.project.toString());
    params.set('site_location', this.requisitionData.site_location.toString());
    this.ExecutionPurchaseService.getStock(params)
      .subscribe(
        res => {

          if (res['result']) {
            if (res['result'].current_stock == 0 || res['result'].current_stock == '') {
              this.stockArray[index].stock = value;
            } else {
              this.stockArray[index].stock = res['result'].current_stock;
              if (this.stockArray[index].stock > 0) {

                this.stockReadOnly = true;
                this.form.controls['requisition_details']['controls'][index]['controls']['current_stock'].disable();
              }
              else {
                this.form.controls['requisition_details']['controls'][index]['controls']['current_stock'].enable();
              }
            }

          }

          this.loading = LoadingState.Ready;
        },
        err => {
          this.loading = LoadingState.Ready;
        }
      )


  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


  getFormArrayControl(form) {
    return form.get('requisition_details').controls
  }

  createPDF() {
    /***********Requisition info start********/
    var mrDate = this.requisitionData.mr_date.year+'-'+this.requisitionData.mr_date.month+'-'+this.requisitionData.mr_date.day
    var prHeading = [[
      { text: 'Project Name.', fontSize: 8, fillColor: '#ccc', bold: true },
      { text: 'Site Location', fontSize: 8, fillColor: '#ccc', bold: true },
      { text: 'M.R Date', fontSize: 8, fillColor: '#ccc', bold: true },
      { text: 'Type', fontSize: 8, fillColor: '#ccc', bold: true },
    ],
    [
      { text: this.requisitionData.project_name, fontSize: 7, fillColor: '#fff', bold: false },
      { text: this.requisitionData.site_location_name , fontSize: 7, fillColor: '#fff', bold: false },
      { text: mrDate , fontSize: 7, fillColor: '#fff', bold: false },
      { text: this.requisitionData.type.type_name, fontSize: 7, fillColor: '#fff', bold: false }
    ]]
  
/***********Requisition info end********/

/***********item start********/
  var pritem = [[
    { text: 'Item Code', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'Description', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'UOM', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'Quantity', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'Current Stock', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'Site Procurement', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'HO Procurement', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'Required On', fontSize: 8, fillColor: '#ccc', bold: true },
    { text: 'Required By', fontSize: 8, fillColor: '#ccc', bold: true },
   { text: 'Remarks', fontSize: 8, fillColor: '#ccc', bold: true },
  ]]

  this.requisitionData.requisition_details.forEach((x, index) => {
    var data = [
      { text: x.item_details.mat_code, fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.item_details.description, fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.uom_name, fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.quantity, fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.current_stock, fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.procurement_site, fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.procurement_ho, fontSize: 7, fillColor: '#fff', bold: false },
      { text: this.settingService.formatDate(x.required_on), fontSize: 7, fillColor: '#fff', bold: false },
      { text: x.required_by, fontSize: 7, fillColor: '#fff', bold: false },
     { text: x.remarks, fontSize: 7, fillColor: '#fff', bold: true },
    ]
    pritem.push(data);
  });

/***********item start********/
      var dd = {
        
        content: [
          
          {

            image: imageData,
            width: 130,
            style: 'imageAlign'
          },
          {text: 'Purchase Requisition \n', style: 'header',margin: [0, 30, 0, 10],},
          {
            table: {
              headerRows: 1,
              widths: ['*', '*', '*', '*'],
              body: prHeading
            }
          },
          {text: 'Item Details \n', style: 'header',margin: [0, 20, 0, 10]},
          {
            table: {
              headerRows: 1,
              widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto','*'],
              body: pritem
            }
          },
          {text: '', margin: [0, 50, 0, 50]},
          {
            columns: [
              {
                style: 'subheader',
                text: '____________________\nPrepared By',
                alignment: 'center'
              },
              {
                style: 'subheader',
                text: '____________________\nSite Incharge',
                alignment: 'center'
              },
              {
                style: 'subheader',
                text: '____________________\nProject Manager',
                alignment: 'center'
              },
              {
                style: 'subheader',
                text: '____________________\nApproved By',
                alignment: 'center'
              },
              {
                style: 'subheader',
                text: '____________________\nPurchase Head',
                alignment: 'center'
              }
            ]
          }
        ],
        
        styles: {
          imageAlign:
          {   
              alignment: 'center'
          },
          header: {
            fontSize: 12,
            bold: false,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 10,
            bold: false,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        },
        defaultStyle: {
          alignment: 'justify'
        }
      }

      var pdf = pdfMake.createPdf(dd);
      // pdf.download('default reporting dates.pdf');
      pdf.open();

    
  }

}

