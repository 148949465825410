import { Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../loading/loading.component';
import { ToastrService } from 'ngx-toastr';
import * as Globals from '../../../../globals';
import { AttendanceService } from '../../../../services/attendance.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { SettingService } from '../../../../services/setting.service';
import { LocalService } from '../../../../services/local.service';
import { DocumentViewModalComponent } from '../../../../components/document-view-modal/document-view-modal.component';

@Component({
  selector: 'app-normal-leave-report',
  templateUrl: './normal-leave-report.component.html',
  styleUrls: ['./normal-leave-report.component.scss']
})
export class NormalLeaveReportComponent implements OnInit {
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  @Input() employeeId:number;

  loading: LoadingState = LoadingState.NotReady;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  leaveReport: any = [];
  totalLeaveReport: number;
  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList: any = [];
  pageSize: number;
  search_key = '';
  headerThOption = [];
  sort_by = '';
  sort_type = '';
  CL = '';
  EL = '';
  absent = '';
  designationList: any;
  departmentList: any;
  approvalArray: any = [];
  reportTypeCheckbox: any = [];
  approveTypeCheckbox: any = [];
  approveCheckList: any = [];
  leaveCheckList: any = [];
  leaveTypeCheckList: any = [];
  leaveTypesData: any = [];
  leaveTypes = '';
  scrollAmount;
  hasScroll:boolean = false;
  leaveStartDate = '';
  leaveEndDate = '';
  dateRange:any = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private settingService: SettingService,
    private route: ActivatedRoute,
    private element: ElementRef ,
    private localService: LocalService ,
  ) { }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;

    this.headerThOption = [
      {
        id: 1,
        name: "Name",
        code: "name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 10,
        name: "Designation",
        code: "designation",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 11,
        name: "Department",
        code: "department",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 12,
        name: "Reporting Head",
        code: "reporting_head",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 13,
        name: "HOD",
        code: "hod",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Date of Application",
        code: "date_of_application",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Start Date",
        code: "duration_start",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "End Date",
        code: "duration_end",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Duration",
        code: "duration",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Leave Type",
        code: "leave_type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 7,
        name: "Request Type",
        code: "request_type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 8,
        name: "Reason of Leave",
        code: "reason_of_leave",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 9,
        name: "Approval Status",
        code: "approval_status",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
    ];

   // this.getLeaveLsit();
  }

  ngOnChanges(){
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    this.dataFilter();
  }

  ngAfterViewInit(){ //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
      if(this.scrollAmount.scrollWidth >= this.scrollAmount.offsetWidth){
         this.hasScroll = true;
      }else{
        this.hasScroll = false;
      }
  }
  
  getLeaveLsit() {
    var userid = this.localService.getJsonValue('userid');

    this.loading = LoadingState.Processing;
    let param: URLSearchParams = new URLSearchParams();
    param.set('page', this.defaultPagination.toString());
    param.set('team_approval_flag','1');
    if(this.employeeId != undefined)
    {
      param.set('users',this.employeeId.toString());
    }
    if (this.search_key != '') {
      param.set('search', this.search_key.toString());
    }
    if (this.reportTypeCheckbox.length > 0) {
      param.set('request_type', this.reportTypeCheckbox.toString());
    }
    if (this.approveTypeCheckbox.length > 0) {
      param.set('approved_type', this.approveTypeCheckbox.toString());
    }
    if (this.leaveTypesData.length > 0) {
      param.set('leave_type', this.leaveTypesData.toString());
    }

    if (this.leaveStartDate != '') {
      param.set('from_date', this.leaveStartDate.toString());
    }
    if (this.leaveEndDate != '') {
      param.set('to_date', this.leaveEndDate.toString());
    }

    if (this.sort_by != '') {
      param.set('field_name', this.sort_by.toString());
    }

    if (this.sort_type != '') {
      param.set('order_by', this.sort_type.toString());
    }
    if (this.itemPerPage) {
      param.set('page_count', this.itemPerPage.toString());
    }

    this.attendanceService.getNormalLeaveReport(param).subscribe(
      res => {
        this.totalLeaveReport = res['count'];
        this.leaveReport = res['results'];

        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalLeaveReport > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination

        }
        else {
          this.upper_count = this.totalLeaveReport
        }
        this.loading = LoadingState.Ready;

      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
      }
    )
  }

  exportReport(){
    var userid = this.localService.getJsonValue('userid');

    this.loading = LoadingState.Processing;
    let param: URLSearchParams = new URLSearchParams();
    param.set('team_approval_flag','1');
    if(this.employeeId != undefined)
    {
      param.set('users',this.employeeId.toString());
    }
    if (this.search_key != '') {
      param.set('search', this.search_key.toString());
    }
    if (this.reportTypeCheckbox.length > 0) {
      param.set('request_type', this.reportTypeCheckbox.toString());
    }
    if (this.approveTypeCheckbox.length > 0) {
      param.set('approved_type', this.approveTypeCheckbox.toString());
    }
    if (this.leaveTypesData.length > 0) {
      param.set('leave_type', this.leaveTypesData.toString());
    }

    if (this.leaveStartDate != '') {
      param.set('from_date', this.leaveStartDate.toString());
    }
    if (this.leaveEndDate != '') {
      param.set('to_date', this.leaveEndDate.toString());
    }

    if (this.sort_by != '') {
      param.set('field_name', this.sort_by.toString());
    }

    if (this.sort_type != '') {
      param.set('order_by', this.sort_type.toString());
    }

    this.attendanceService.exportNormalLeaveReport(param).subscribe(
      res => {
        //console.log(res)
        var downloadURL = res['url']
        if(downloadURL != null){
          window.open(downloadURL,'_blank')
        }
        this.loading = LoadingState.Ready;

      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
      }
    )
  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.getLeaveLsit();
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.getLeaveLsit();
  };

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
    this.getLeaveLsit();
    
  }

  reload() {
    this.loading = LoadingState.Processing;
    this.reportTypeCheckbox = [];
    this.leaveCheckList = [];
    this.CL = '';
    this.EL = '';
    this.absent = '';
    this.approveTypeCheckbox = [];
    this.leaveStartDate = '';
    this.leaveEndDate = '';
    this.leaveTypeCheckList = [];
    this.leaveTypesData = [];
    this.approveCheckList = [];
    this.dateRange = null;
    this.sort_type = '';
    this.sort_by = '';
    this.itemPerPage = Globals.itemPerPage;
    this.search_key = '';
    this.getLeaveLsit();
    this.pageSize = Globals.pageSize;
    //this.loading = LoadingState.Ready;

  }

  getSearchValueFromParent(val: any) {
    this.search_key = val;
    if (this.search_key != '') {
      this.dataFilter();
    }
  }

  dateTimeReset(){
    this.dateRange = null;
    this.leaveStartDate = '';
    this.leaveEndDate = '';
    this.dataFilter()
  }
  formatHandler(event) {
    if (event.value[0] != null) {
      this.leaveStartDate = this.settingService.formatDate(event.value[0])
      this.leaveEndDate = this.settingService.formatDate(event.value[1])
      this.dataFilter()
    }
    else {
      this.reload()
    }

  }

  approveType(index,e) {
    if (e.checked == true) {
      if (this.approveTypeCheckbox.indexOf(e.source.value) == -1) {
        this.approveTypeCheckbox.push(e.source.value);
      }
      this.approveCheckList[index] = true;
    }
    else {
      this.approveTypeCheckbox.splice(this.approveTypeCheckbox.indexOf(e.source.value), 1);
      this.approveCheckList[index] = false;
    }
    this.dataFilter();
  }
  
  leaveFilter(index,e) {
    if (e.checked == true) {
      if (this.reportTypeCheckbox.indexOf(e.source.value) == -1) {
        this.reportTypeCheckbox.push(e.source.value);
      }
      this.leaveCheckList[index] = true;
    }
    else {
      this.reportTypeCheckbox.splice(this.reportTypeCheckbox.indexOf(e.source.value), 1);
      this.leaveCheckList[index] = false;
    }
    this.dataFilter();
  }
  
  leaveType(index,e) {
    if (e.checked == true) {
      if (this.leaveTypesData.indexOf(e.source.value) == -1) {
        this.leaveTypesData.push(e.source.value);
      }
      this.leaveTypeCheckList[index] = true;
    }
    else {
      this.leaveTypesData.splice(this.leaveTypesData.indexOf(e.source.value), 1);
      this.leaveTypeCheckList[index] = false;
    }
    this.dataFilter();
  }

  viewDocument(items: any){
    var data = [];
    items.forEach(x => {
      var item = {
        name: x.document_name,
        url: x.document
      }
      data.push(item)
    })
    let dialogRef = this.dialog.open(DocumentViewModalComponent, {
      width: '525px',
      data: { items: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
    })
  }
  

  scrollToRight(){
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft(){
    this.tableContent.nativeElement.scrollLeft -= 150;
  }
  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.dataFilter();
  }
  getConfigData(data){
    this.headerThOption = data
  }

  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }

}
