import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) {

  }

  getCurrentStockReportData(pid, sid, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_current_stock_report/' + pid + '/' + sid + '/?' + params);
  }

  getMonthlyStockReportData(pid, sid, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_monthly_stock_report_list/' + pid + '/' + sid + '/?' + params);
  }

  getMaterialStockReportData(pid, sid, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_material_stock_statement/' + pid + '/' + sid + '/?' + params);
  }

  getStockIssueList(pid, sid, param): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_stock_issue_list/' + pid + '/' + sid + '/?' + param);
  }

  issueSubmitForApproval(id, data): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_stock_issue_submit_for_approval/' + id + '/', data);
  }
  insertSubmitForApproval(id, param, data): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'execution_stock_stock_issue_approved/' + id + '/?' + param, data);
  }

  getMonthListofProject(pid): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'project_start_to_end_date_list/' + pid + '/');
  }

  getMonthlyStockExcel(pid, sid, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_stock_monthly_stock_report_download/' + pid + '/' + sid + '/?' + params);
  }

  getMaterialStockExcel(pid, sid, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_material_stock_statement_download/' + pid + '/' + sid + '/?' + params);
  }

  getCurrentStockExcel(pid, sid, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'execution_current_stock_report_download/' + pid + '/' + sid + '/?' + params);
  }

}
