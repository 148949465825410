import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingState } from '../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { projectSiteServices } from '../../../core/services/project-sites.service';
import { ManpowerService } from '../../../core/services/manpower.service';
import { reportingService } from '../../../core/services/reporting.service';
import { SettingService } from '../../../core/services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-machinery-transfer',
  templateUrl: './machinery-transfer.component.html',
  styleUrls: ['./machinery-transfer.component.scss']
})
export class MachineryTransferComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  projectList: any = [];
  machineryProject: any = [];
  selectedProject: any = [];
  selectedProjectItems: any = [];
  machineryName: string;
  constructor(
    public dialogRef: MatDialogRef<MachineryTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    private toastr: ToastrService,
    private manpowerService: ManpowerService
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.machineryName = this.data.item.equipment_name
    this.machineryProject = this.data.item.project;
    this.projectList = this.data.project;
    this.form = this.formBuilder.group({
      machinary: this.data.item.id,
      project_list: this.formBuilder.array([])
    });
    this.machineryProject.forEach(x => {
      var index = this.selectedProject.findIndex(y => y == x.project)
     // console.log(index)
      if (index == -1) {
        this.selectedProject.push(x.project)
        this.selectedProjectItems.push(x.project)
        var projectIndex = this.projectList.findIndex(z => z.id == x.project)
        //console.log(projectIndex)
        if (projectIndex != -1) {
          var startDate = new Date(x.start_date)
          var expireDate = new Date(x.end_date)
          var data = {
            id: x.project,
            site_location_name: this.projectList[projectIndex]['site_location_name']
          }
          data['start_date'] = {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate()
          }
          data['expire_date'] = {
            year: expireDate.getFullYear(),
            month: expireDate.getMonth() + 1,
            day: expireDate.getDate()
          }
          this.add_project_row(data)
        }
      }
    })
    this.loading = LoadingState.Ready
  }

  create_project_list(item) {
    return this.formBuilder.group({
      project: [item.id],
      project_name: [{ value: item.site_location_name, disabled: true }],
      start_date: [item.start_date, Validators.required],
      expire_date: [item.expire_date, Validators.required]
    });
  }

  add_project_row(item) {
    //console.log(item)
    const control = <FormArray>this.form.controls['project_list'];
    control.push(this.create_project_list(item));
  }

  delete_project_row(index: number) {
    const control = <FormArray>this.form.controls['project_list'];
    control.removeAt(index);
  }

  getProjectFormArrayControl(form) {
    return form.get('project_list').controls
  }

  onProjectChange($event) {
    //console.log($event)
    this.selectedProject.forEach(x => {
      var index = this.projectList.findIndex(y => y.id == x);
      if (index != -1) {
        var itemIndex = this.selectedProjectItems.findIndex(z => z == x)
        if (itemIndex == -1) {
          this.selectedProjectItems.push(x)
          var data = {
            id: this.projectList[index]['id'],
            site_location_name: this.projectList[index]['site_location_name'],
            start_date: '',
            expire_date: '',
          }
          this.add_project_row(data)
        }

      }
    })
    // 
    this.selectedProjectItems.forEach(m => {
      var selectIndex = this.selectedProject.findIndex(n => n == m)
      if (selectIndex == -1) {
        var removalIndex = this.selectedProjectItems.findIndex(o => o == m);
        if (removalIndex != -1) {
          this.selectedProjectItems.splice(removalIndex, 1);
          this.delete_project_row(removalIndex)
        }
      }
    })
  }

  submit() {
    if (this.form.valid) {
      var data = {
        machinary: this.form.value.machinary
      }
      var selectedProjectItemList = [];
      this.form.value.project_list.forEach(x => {
        var d = {
          project: x.project
        }
        var start_date = this.settingService.getProperDate(x.start_date)
        d['start_date'] = start_date.toISOString();
        var expire_date = this.settingService.getProperDate(x.expire_date)
        d['expire_date'] = expire_date.toISOString();
        selectedProjectItemList.push(d)
      })
      data['project_list'] = selectedProjectItemList
      //console.log(data)
      this.loading = LoadingState.Processing
      this.manpowerService.machinaryTransfer(data).subscribe(
        res => {
          //console.log(res)
          this.toastr.success('Machinery has been successfully transferred', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(res)
        },
        error => {
          //console.log(error)
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  close() {
    this.dialogRef.close()
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
