import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddLocationModalComponent } from '../add-location-modal/add-location-modal.component';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddMaterialModalComponent } from '../add-material-modal/add-material-modal.component';
import { forkJoin } from 'rxjs';
import { SettingService } from '../../../../core/services/setting.service';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-coordinate-tab',
  templateUrl: './coordinate-tab.component.html',
  styleUrls: ['./coordinate-tab.component.scss']
})
export class CoordinateTabComponent implements OnInit {
  @Input() tenderId: number;
  tenderCoordinateList: any = [];
  loading: LoadingState = LoadingState.NotReady;
  tenderMaterialList: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  addTenderConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  activeTab: any = 'coordinate_material';

  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    private tenderService: TenderService,
    private settingService: SettingService
  ) { 
    this.addTenderConfig = AppPageStateConfig.addTenderConfig.surveyConfig;
    this.activeTab = this.addTenderConfig.coorfinateConfig.tab;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    // forkArray.push(this.settingService.getMaterialList())
    forkArray.push(this.tenderService.getTenderCoordinateList(this.tenderId))
    forkJoin(forkArray).subscribe(
      ([coordinateData]) => {
       // console.log(coordinateData)
        this.tenderCoordinateList =coordinateData['result'];
        this.loading = LoadingState.Ready
      },
      error => {
      //  console.log(error)
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  openAddLocationModal() {
    let dialogRef = this.dialog.open(AddLocationModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderCoordinateList.push(result['data'])
      }
    })
  }

  openUpdateLocationModal(i) {
    let detailsData = Object.assign({}, this.tenderCoordinateList[i]);
    let dialogRef = this.dialog.open(AddLocationModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, locationDetailsData: detailsData }
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log(result)
      if (result['data'] != undefined) {
        var index = this.tenderCoordinateList.findIndex(x => x.id == result['data']['id'])
        if (index != -1) {
          this.tenderCoordinateList[index] = result['data'];
        }
      }
    })
  }

  delete(i) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.tenderService.deleteTenderCoordinate(this.tenderCoordinateList[i]['id']).subscribe(
      res => {
      //  console.log(res)
        this.loading = LoadingState.Ready
        this.tenderCoordinateList.splice(i, 1)
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
	 })
    
  }

  openMaterialModal() {
    let dialogRef = this.dialog.open(AddMaterialModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderMaterialList.push(result['data'])
      }
    })
  }

  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
  }

  switchNgBTab(id: string) {
  //  console.log(id)
    this.ctdTabset.select(id);
  }

  onTabChange(event) {
   // console.log(event)
    this.activeTab = event.nextId;
    //this.addTenderConfig.coorfinateConfig.tab = this.activeTab
   
  }
}
