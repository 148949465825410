import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingState } from '../../loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ObjectService } from '../../../services/object.service';
import { LocalService } from '../../../services/local.service';

@Component({
  selector: 'app-add-object-modal',
  templateUrl: './add-object-modal.component.html',
  styleUrls: ['./add-object-modal.component.scss']
})

export class AddObjectModalComponent implements OnInit {

  form: FormGroup;
  objectId: number;
  loading: LoadingState = LoadingState.NotReady;
  objectDetails: any = {
    cot_is_deleted: false,
    cot_name: '',
    created_by: '',
    description: '',
    id: '',
    mmo_module: '',
    mmo_other: '',
    cot_parent_id: '',
  };

  constructor(
    public dialogRef: MatDialogRef<AddObjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private objectService: ObjectService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    this.loading = LoadingState.Ready;
    //this.objectId = this.data['objectId'];
    if (this.data['enrty'] == 'addObject') {
      this.objectDetails = {
        cot_is_deleted: false,
        cot_name: '',
        created_by: '',
        description: '',
        id: '',
        mmo_module: this.data['moduleId'],
        mmo_other: '',
        cot_parent_id: '',
      };
    }
    else if (this.data['enrty'] == 'editObject') {
      this.objectDetails = this.data.objectDetails;
    }


    //console.log(this.objectDetails)

    this.form = this.formBuilder.group({
      cot_name: ['', Validators.required],
      description: ['']
    });
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
    //console.log(this.form.value)
    if (this.form.valid) {
      if (this.data['enrty'] == 'addObject') {
        let formData = {
          cot_name: this.form.value.cot_name,
          description: this.form.value.description,
          mmo_module: this.objectDetails['mmo_module'],
          cot_parent_id: 0
        }

        //console.log(formData)
        this.objectService.addObject(formData).subscribe(
          res => {
            //console.log(res)
            this.dialogRef.close({ data: res });
            this.loading = LoadingState.Ready
          },
          error => {
            //console.log(error)
            this.loading = LoadingState.Ready
          }
        )
      }
      else {
        let formData = {
          cot_name: this.form.value.cot_name,
          description: this.form.value.description,
          mmo_module: +this.localService.getJsonValue('currentModuleId')
        }
        this.objectService.editObject(this.objectDetails['mmo_other'], formData).subscribe(
          res => {
            //console.log(res)
            this.dialogRef.close({ data: res });
            this.loading = LoadingState.Ready
          },
          error => {
            //console.log(error)
            this.loading = LoadingState.Ready
          }
        )
      }
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
