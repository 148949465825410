import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../services/setting.service';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import * as Globals from '../../../../core/globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { DocumentViewModalComponent } from '../../../../core/components/document-view-modal/document-view-modal.component';

@Component({
  selector: 'app-delivery-tab',
  templateUrl: './delivery-tab.component.html',
  styleUrls: ['./delivery-tab.component.scss']
})
export class DeliveryTabComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  externalUserTypeList:any =[];
  transportType:number;
  returnGood:any = [];
  @Input() requisitionId:number;
  @Input() vendorList:any = [];
  dispatchList:any = [];
  poNumberList :any = [];
  deliveredPoNumberList:any =[];
  finalPoNumberList: any = [];
  deliveryItems:any = [];
  deliveryItemsList:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  isEdit:boolean;
  editDeliveryItemData:any = [];

  selectedFile: any = [];

  defaultPagination: number;
  itemNo: number;
  paginationMaxSize:number;
  itemPerPage:number;

  tempSelectedFile:any = [];
  


  constructor(
    private formBuilder: FormBuilder,
    private settingService:SettingService,
    private executionPurchaseService:ExecutionPurchaseService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) { 
    
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      po_id:[null,Validators.required],
      deliveryFromData: this.formBuilder.array([this.createDeliveryForm()]),
    });

    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    if(this.requisitionId){
      this.loadDeliveryData();
      this.getDeliveryItemsList();
    }

    this.deliveryItems = [{
      received_item:null,
      received_item_name:null,
      received_quantity:null,
      uom:null,
      vendor:null,
      date_of_delivery:null,
      grn_no:null,
      e_way_bill_no:null,
      return_good:null,
      return_and_issue:null,
      return_cost:null,
      compensation:null,
      date_of_receipt:null,
      uom_name:null,
      po_no:null,
      final_quantity:null
      

    }]
    
  }

  createDeliveryForm(){
    return this.formBuilder.group({
      requisitions_master:this.requisitionId,
      completed_status:0,
      po_no:[null,Validators.required],
      received_item:[null,Validators.required],
      received_item_name:[null,Validators.required],
      received_quantity:[null,Validators.required],
      uom_name:[null,Validators.required],
      uom:[null,Validators.required],
      vendor: [null,Validators.required],
      date_of_delivery: [null,Validators.required],
      grn_no: [null],
      e_way_bill_no: [null],
      return_good: [null],
      return_and_issue: [null],
      return_cost: [null],
      compensation: [null],
      date_of_receipt: [null],

      grn_document: this.formBuilder.array([
        this.createDocument()
       ]),
      e_way_bill_document: this.formBuilder.array([
        this.createDocument()
       ]),
      weighment_slip_document: this.formBuilder.array([
        this.createDocument()
       ]),
      
      documents:this.formBuilder.array([
        this.createDocument()
       ])

    })
  }

  getExternalUserListByType(user_type) {

    var params = 'page_size=0&user_type='+user_type;
    this.settingService.getExternalUserListByType(params).subscribe(
      res => {
        // console.log(res)
        this.vendorList = res['results']
      },
      error => {
        console.log(error)
      }
    )
  }


  loadDeliveryData(){
    var forkArray = [];
   

    forkArray.push(this.executionPurchaseService.getDispatchList(this.requisitionId))
    forkArray.push(this.executionPurchaseService.getDeliveryItemList(this.requisitionId))

    forkJoin(forkArray).subscribe(
      ([dispatchData,deliveryData]) => {
        if(dispatchData){
          this.dispatchList = dispatchData['result'];
            this.dispatchList.forEach((x,index)=>{
              
              if(this.poNumberList.indexOf(x.po_no)==-1){
                this.poNumberList.push(x.po_no);
              }
            })
        }
        if(deliveryData){
          this.deliveryItemsList = deliveryData['result'];
            this.deliveryItemsList.forEach((x,index)=>{
              if(this.deliveredPoNumberList.indexOf(x.po_no)==-1){
                this.deliveredPoNumberList.push(x.po_no);
              }
            })
            if(this.deliveryItemsList.length > 0){
              this.finalPoNumberList =  this.poNumberList;
              //this.finalPoNumberList = this.compare(this.poNumberList,this.deliveredPoNumberList);
            }else{
              this.finalPoNumberList =  this.poNumberList;
             
            }
        }     
        
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        
        
      }
    )

  }

  
  compare(arr1,arr2){
    
    const finalarray = [];
    arr1.forEach((e1)=> arr2.forEach((e2)=>
      {
        
        if(arr2.indexOf(e1)==-1){

          if(finalarray.indexOf(e1)==-1){
            finalarray.push(e1);
          }
          
        }
      }
    ));
    return finalarray;
  }

  checkQuantity(e,index){
    var selectedItemsQuantity = parseInt(this.deliveryItems[index].final_quantity);
    var val = parseInt(e);
    if(val > selectedItemsQuantity){
      this.toastr.error('Total quantity exceeded!', '', {
        timeOut: 3000,
      });
      this.deliveryItems[index].received_quantity = selectedItemsQuantity;
    }
    //console.log(selectedItemsQuantity)
  }

  save(){

    if(this.form.valid){
      this.loading = LoadingState.Processing;
   
      var delvery_form_data = [];
      delvery_form_data = this.form.value.deliveryFromData;
     

     // console.log(delvery_form_data)

      delvery_form_data.forEach((x,index) => {
        var other_document_data = [];
        var grn_document_data = [];
        var weighment_slip_document_data = [];
        var e_way_bill_document_data = [];
        
        other_document_data = x.documents;
        grn_document_data = x.grn_document;
        weighment_slip_document_data = x.weighment_slip_document;
        e_way_bill_document_data = x.e_way_bill_document;

        var data = {
          requisitions_master:this.requisitionId,
          // completed_status:4,
          received_item:x.received_item,
          received_quantity:x.received_quantity,
          vendor:x.vendor,
          uom:x.uom,
          date_of_delivery: this.settingService.getProperDate(x.date_of_delivery).toISOString(),//aa
          po_no:x.po_no,
          grn_no:x.grn_no,
          e_way_bill_no:x.e_way_bill_no,
          return_and_issue:x.return_and_issue,
          return_cost:x.return_cost,
          compensation:x.compensation,
          date_of_receipt:(x.date_of_receipt!=null?this.settingService.getProperDate(x.date_of_receipt).toISOString():null)//aa
        }

        

       this.executionPurchaseService.addDeliveryItem(data).subscribe(
          res =>{
           // console.log(res);
            var forkArray = [];
            if(other_document_data.length > 0)
              {
                other_document_data.forEach(y => {
                  if(y.document!=null && y.document_name!=null){
                    const formData: any = new FormData(); 
                    formData.append("delivery", res['id']);
                    formData.append("document_name", y.document_name);
                    formData.append("document", y.other_document, y.other_document['name']);
                    forkArray.push(this.executionPurchaseService.addDeliveryItemDocument(formData))
                  }
                  
              })
            }

            if(grn_document_data.length > 0)
              {
                grn_document_data.forEach(y => {
                  if(y.document!=null && y.document_name!=null){
                  const formData: any = new FormData(); 
                  formData.append("delivery", res['id']);
                  formData.append("document_name", y.document_name);
                  formData.append("document", y.other_document, y.other_document['name']);
                  forkArray.push(this.executionPurchaseService.addDeliveryItemDocument(formData))
                  }
              })
            }

            if(weighment_slip_document_data.length > 0)
              {
                weighment_slip_document_data.forEach(y => {
                  if(y.document!=null && y.document_name!=null){
                  const formData: any = new FormData(); 
                  formData.append("delivery", res['id']);
                  formData.append("document_name", y.document_name);
                  formData.append("document", y.other_document, y.other_document['name']);
                  forkArray.push(this.executionPurchaseService.addDeliveryItemDocument(formData))
                  }
              })
            }
            if(e_way_bill_document_data.length > 0)
              {
                e_way_bill_document_data.forEach(y => {
                  if(y.document!=null && y.document_name!=null){
                  const formData: any = new FormData(); 
                  formData.append("delivery", res['id']);
                  formData.append("document_name", y.document_name);
                  formData.append("document", y.other_document, y.other_document['name']);
                  forkArray.push(this.executionPurchaseService.addDeliveryItemDocument(formData))
                  }
              })
            }



            forkJoin(forkArray).subscribe(
              (result: any[]) => {
                // console.log(result)
                result.forEach(y => {
                  
                })
                
                
                this.loading = LoadingState.Ready;
              },
              err => {
                this.loading = LoadingState.Ready;
                
              }
            )
            this.getDeliveryItemsList();
            this.loadDeliveryData();
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
            });

            if(index>0){
              this.removeDeliveryForm(1);
            }
            this.selectedFile = [];
              this.form.reset();
          },
          error => {
           
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        )
      })


    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  editDelivery(data){
    //console.log(data)
    this.isEdit = true;
    this.editDeliveryItemData = data;
    this.deliveryItems[0].po_no = data.po_no;
    this.deliveryItems[0].received_item_name =  data.item_details.name;
    this.deliveryItems[0].received_quantity = data.received_quantity;
    this.deliveryItems[0].uom_name = data.item_details.uom;
    this.deliveryItems[0].vendor = data.vendor;
    this.deliveryItems[0].grn_no = data.grn_no;
    this.deliveryItems[0].e_way_bill_no = data.e_way_bill_no;
    var start_date = new Date(data.date_of_delivery)
            data.date_of_delivery1 = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
    this.deliveryItems[0].date_of_delivery = data.date_of_delivery1;

    this.deliveryItems[0].return_and_issue = data.return_and_issue;
    this.deliveryItems[0].return_cost = data.return_cost;
    this.deliveryItems[0].compensation = data.compensation;
    if(data.date_of_receipt!=null){
      var re_date = new Date(data.date_of_receipt)
      data.date_of_receipt1 = {
        year: re_date.getFullYear(),
        month: re_date.getMonth()+1,
        day: re_date.getDate()
  
      }
      this.deliveryItems[0].date_of_receipt = data.date_of_receipt1;
    }
   
    if(this.deliveryItems[0].return_and_issue){
      this.returnGood[0] = true;
    }else{
      this.returnGood[0] = false;
    }

   // console.log(this.deliveryItems)
  }

  edit(){
    this.loading = LoadingState.Processing;
    var edit_form_data = this.form.value.deliveryFromData[0];
   // console.log(edit_form_data)
    var data = {
      return_and_issue:edit_form_data.return_and_issue,
      return_cost:edit_form_data.return_cost,
      compensation:edit_form_data.compensation,
      date_of_receipt:(edit_form_data.date_of_receipt!=null?this.settingService.getProperDate(edit_form_data.date_of_receipt).toISOString():null)//aa
    }

    //console.log(data)
    this.executionPurchaseService.updateDeliveryItemList(this.editDeliveryItemData.id,data).subscribe(
      res=>{
        //console.log(res);
        this.getDeliveryItemsList();
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
            });
        this.form.reset();
        this.isEdit = false;
        this.returnGood[0] = false;
      },
      error =>{
       // console.log(error)
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  reload(){
    this.form.reset();
    this.isEdit = false;
    this.returnGood[0] = false;
  }
  selectedPoNumber(e){
  
    var len = this.form.controls['deliveryFromData']['controls'].length
    var control = <FormArray>this.form.controls['deliveryFromData'];
    //console.log(this.deliveryItemsList);
  
    for(let i=0;i<len;i++){
      control.removeAt(0);
      if(i!=0){
        this.deliveryItems.splice(0,1)
      
      }
      
    }
    let deliveredItemPoNumber = this.deliveryItemsList.filter(x=> x.po_no == e);
    let dispatchedListPerPo = this.dispatchList.filter(x=> x.po_no == e);
    if(deliveredItemPoNumber.length > 0){
      deliveredItemPoNumber.forEach((x,index)=>{
        dispatchedListPerPo.forEach((z,index)=>{
          if(x.received_item == z.dispatch_item){
            dispatchedListPerPo.splice(index,1)
          }
        })
      })
    }
    //console.log(this.dispatchList)

    let i = 0;

    dispatchedListPerPo.forEach((x,index) => {
    
    //  if(x.po_no == e){
        const current = new Date(x.date_of_dispatch);
        var p= {
          received_item:null,
          received_item_name:null,
          received_quantity:null,
          uom:null,
          vendor:null,
          date_of_delivery:null,
          date_of_dispatch:null,
          grn_no:null,
          e_way_bill_no:null,
          return_good:null,
          return_and_issue:null,
          return_cost:null,
          compensation:null,
          date_of_receipt:null,
          uom_name:null,
          po_no:null,
          final_quantity:null
          }

        if(i>0){
          this.deliveryItems.push(p);
        }
        this.addDeliveryForm();

       // console.log(i +"==>"+ x.item_details.name)
        
          this.deliveryItems[i].received_item_name = x.item_details.name;
          this.deliveryItems[i].received_item = x.dispatch_item;
          this.deliveryItems[i].final_quantity = x.quantity;
          this.deliveryItems[i].po_no = x.po_no;
          this.deliveryItems[i].received_quantity = x.quantity;
          this.deliveryItems[i].uom = x.uom;
          this.deliveryItems[i].vendor = x.vendor;
          this.deliveryItems[i].uom_name = x.item_details.uom;
          this.deliveryItems[i].date_of_dispatch = {
              year: current.getFullYear(),
              month: current.getMonth() + 1,
              day: current.getDate()
          }
        
          i=i+1
        
        
     // }
    });

    //console.log(this.deliveryItems)
  }

  getDeliveryItemsList(){
    this.executionPurchaseService.getDeliveryItemList(this.requisitionId).subscribe(
      res=>{
        this.deliveryItemsList = res['result'];
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: [null],
      document: [null],
      other_document: null
    })
  }

  addDeliveryForm(){
    const control = <FormArray>this.form.controls['deliveryFromData'];
    control.push(this.createDeliveryForm());
    
  }
  removeDeliveryForm(index:number)
  {
    const control = <FormArray>this.form.controls['deliveryFromData'];
    control.removeAt(index);
    this.returnGood.splice(index, 1);
    this.deliveryItems.splice(index,1);
  }

  addNewDocument(parentIndex){
    const control = <FormArray>this.form.controls['deliveryFromData']['controls'][parentIndex]['controls']['documents'];
    control.push(this.createDocument());
  }
  deleteDocument(index,parentIndex){
    const control = <FormArray>this.form.controls['deliveryFromData']['controls'][parentIndex]['controls']['documents'];
    control.removeAt(index);
    // this.returnGood.splice(index, 1);
  }
 
 

 
  getFormArrayControl(form) {
    return form.get('deliveryFromData').controls
  }

  onSelectFile(event,index,parentIndex,key:string){
    
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.form.controls['deliveryFromData']['controls'][parentIndex]['controls'][key]['controls'][0].controls['document_name'].setValidators([Validators.required]);
      this.form.controls['deliveryFromData']['controls'][parentIndex]['controls'][key]['controls'][0].controls['document_name'].setErrors({required:true});
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
        
        }
       
          const control = <FormArray>this.form.controls['deliveryFromData']['controls'][parentIndex]['controls'][key];
      
        
       
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          other_document: event.target.files[0]
          
        })
        
        //console.log(this.form.controls['deliveryFromData']['controls'][parentIndex]['controls'][key]['controls'][0].controls['document_name'])
        reader.readAsDataURL(event.target.files[i]);
        var data = { index: key}
        this.selectedFile.push(data);
      }
    //  console.log(this.selectedFile)
    }
  }
  
  viewPoDocument(documents: any){
    // console.log(documents)
     var data = [];
     documents.forEach(x =>{
       if (x.document_name != null)
       data.push({
         name: x.document_name,
         url: x.document
       });
     })
    // console.log(data);
 
       if (data.length > 0) {
         let dialogRef = this.dialog.open(DocumentViewModalComponent, {
           width: '525px',
           data: { items: data }
         });
         dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
         })
       }
   }

   
  handleChange(e: any,index:number) {
    this.returnGood[index] = e.srcElement.checked;
    if(this.returnGood == true){ //console.log(1);
      
      this.form.controls['deliveryFromData']['controls'][0]['controls']['return_and_issue'].clearValidators();
      this.form.controls['deliveryFromData']['controls'][0]['controls']['return_cost'].clearValidators();
      this.form.controls['deliveryFromData']['controls'][0]['controls']['compensation'].clearValidators();
      this.form.controls['deliveryFromData']['controls'][0]['controls']['date_of_receipt'].clearValidators();

      this.form.controls['deliveryFromData']['controls'][0]['controls']['return_and_issue'].setErrors(null);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['return_cost'].setErrors(null);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['compensation'].setErrors(null);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['date_of_receipt'].setErrors(null);
    }else{ //console.log(2);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['return_and_issue'].setValidators([Validators.required]);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['return_cost'].setValidators([Validators.required]);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['compensation'].setValidators([Validators.required]);
      this.form.controls['deliveryFromData']['controls'][0]['controls']['date_of_receipt'].setValidators([Validators.required]);
      
    }
    //console.log(this.returnGood);
   }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
