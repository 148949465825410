import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-utilities-water-inner-tab',
  templateUrl: './utilities-water-inner-tab.component.html',
  styleUrls: ['./utilities-water-inner-tab.component.scss']
})
export class UtilitiesWaterInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  waterDocumentList:any = [];
  waterDetails:any = [];
  approveStatus:any = [];

  @Input() isEdit: boolean;
  @Input() isFieldError: boolean;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
  //  console.log(this.projectId);
    this.form = this.formBuilder.group({
      project:[this.projectId],
      submergible_pump:[null,Validators.required],
      quantity:[null,Validators.required],
      depth:[null,Validators.required],
      con_name:[null,Validators.required],
      con_conatct_no:[null,Validators.required],
      con_email_id:[null,[Validators.required,Validators.email]],
      con_address:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        // this.createDocument()
      ])
    })
    if(this.projectId){
      this.getWaterDetails();
      this.getStatus();
    }
    
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: [null, Validators.required],
      document: [null, Validators.required],
      water_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }
  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  save()
  {
    if(this.form.valid){
      var water_documents_data = [];
      water_documents_data = this.form.value.documents;
      console.log(water_documents_data);

      this.loading = LoadingState.Processing

      const data = {
        project: this.projectId,
        submergible_pump: this.form.value.submergible_pump,
        quantity:this.form.value.quantity,
        depth: this.form.value.depth,
        con_name: this.form.value.con_name,
        con_conatct_no: this.form.value.con_conatct_no,
        con_email_id: this.form.value.con_email_id,
        con_address: this.form.value.con_address,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }
      this.waterDocumentList = [];
      this.perExecutionService.addWaterUtilitiesAminites(data).subscribe(
        res => {
          var forkArray = [];

          water_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.water_document, x.water_document['name']);
              forkArray.push(this.perExecutionService.addWaterDocumentUtilitiesAminites(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.waterDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
          console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
      console.log(data);

    }else
    {
      this.markFormGroupTouched(this.form);
    }
  }

  getWaterDetails(){
    this.perExecutionService.getWaterDeatilsUtilitiesAminites(this.projectId).subscribe(
      res=>{
        if(res['result'][0]){
          this.waterDetails = res['result'][0];
          console.log(this.waterDetails);
          this.waterDocumentList = res['result'][0].document_details;
          var start_date = new Date(this.waterDetails['requirment_s_date'])
            this.waterDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.waterDetails['requirment_e_date'])
            this.waterDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          this.form.patchValue({
            latitude: this.waterDetails.latitude,
            longitude: this.waterDetails.longitude
          })
          this.waterDetails.budgeted_cost = parseFloat(this.waterDetails.budgeted_cost).toFixed(2);
          this.waterDetails.executed_cost = parseFloat(this.waterDetails.executed_cost).toFixed(2);
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }
 

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.waterDocumentList[index]['isEdit'] = true
    this.waterDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.waterDocumentList[index]['document_name'] == '') {
      this.waterDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.waterDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.waterDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editWaterDocumentUtilitiesAminites(this.waterDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.waterDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.loading = LoadingState.Processing
    this.perExecutionService.deleteWaterDocumentUtilitiesAminites(this.waterDocumentList[index]['id']).subscribe(
      res => {
        this.waterDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }

  update(){
    if(this.form.valid){
      console.log(this.waterDetails);
      var water_documents_data = [];
      water_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        water_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project", this.projectId);
            formData.append("module_id", this.waterDetails.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.water_document, x.water_document['name']);
            forkArray.push(this.perExecutionService.addWaterDocumentUtilitiesAminites(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.waterDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateWater();
      }

      else{
        this.updateWater();
      }


    }
  }

  updateWater(){
    if(this.form.valid){
      this.loading = LoadingState.Processing

      const updateData = {
        project: this.projectId,
        submergible_pump: this.form.value.submergible_pump,
        quantity:this.form.value.quantity,
        depth: this.form.value.depth,
        con_name: this.form.value.con_name,
        con_conatct_no: this.form.value.con_conatct_no,
        con_email_id: this.form.value.con_email_id,
        con_address: this.form.value.con_address,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }

      this.perExecutionService.editWaterDeatilsUtilitiesAminites(this.waterDetails.id,updateData).subscribe(
        res =>{
          console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error =>{
         this.loading = LoadingState.Ready;
         console.log(error)
         this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        }
      )

    }
  }

  

  handleAddressChange(address) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      con_address: formattedAddress
    })
  }
  onSelectFile(event, index){
    console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          water_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }






}
