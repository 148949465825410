import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-raw-material-crusher-inner-tab',
  templateUrl: './raw-material-crusher-inner-tab.component.html',
  styleUrls: ['./raw-material-crusher-inner-tab.component.scss']
})
export class RawMaterialCrusherInnerTabComponent implements OnInit {

  @Input() tenderId: number;
  @Input() type: string;
  @Input() tenderMaterialList: any = [];
  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
   // console.log(this.tenderId)
    //console.log(this.type)
  }

}
