import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import * as Globals from "../../core/globals";
import { LocalService } from "./local.service";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class InterceptorService {
  constructor(
    public router: Router,
    public localService: LocalService,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = this.localService.getJsonValue("isLoggedin");
    const token = isLoggedIn
      ? this.localService.getJsonValue("loggedinToken")
      : null;

    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Token " + token),
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = "An unknown error occurred!";

    if (error.status === 401) {
      errorMessage = "Session expired. Please log in again.";
      this.localService.clearToken();
      this.router.navigate(["/session-expire"]);
      window.open(`${Globals.baseUrl}/session-expire`, "_self");
    } else if (error.status === 404) {
      errorMessage = "Resource not found!";
    } else {
      errorMessage = error.error.message || errorMessage; // More informative error message if available
    }

    this.toastr.error(errorMessage, "", { timeOut: 2000 });
    return throwError(error);
  }
}
