import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { LocalService } from '../../../../services/local.service';
import { LoadingState } from '../../../loading/loading.component';

@Component({
  selector: 'app-approval-level-bottom-sheet',
  templateUrl: './approval-level-bottom-sheet.component.html',
  styleUrls: ['./approval-level-bottom-sheet.component.scss']
})
export class ApprovalLevelBottomSheetComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  permissionList:any = [];
  higherLevelPermission:number;
  currentUserArray: any = []

  constructor(
    private executionPurchaseService: ExecutionPurchaseService,
    private localService: LocalService,
    public dialogRef: MatDialogRef<ApprovalLevelBottomSheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.getPermissionLevel();
    //console.log(this.data)
  }

  getPermissionLevel(){
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    if(this.data.from == "quotaion"){
      params.set('section', 'comparative statement');
    }else{
      params.set('section', 'requisition');
    }
    
    this.executionPurchaseService.getPermissionLevel(params).subscribe(
      res => {
        var userid = this.localService.getJsonValue('userid');
      
        if(res['result'].user_approve_details.length > 0){
          this.permissionList = res['result'];
          this.higherLevelPermission = this.permissionList['user_approve_details'].length - 1;
          this.permissionList.user_approve_details.forEach(x => {
            if(x.user_details.id == userid){
              this.currentUserArray = x;
            }
          //  console.log(x.user_details.id)
            //console.log(userid)
          });  
         // console.log(this.currentUserArray);
        } 
       this.loading = LoadingState.Ready
       
      },
      error => {
        this.loading = LoadingState.Ready
      }
    )
  }
  
  close() {
    this.dialogRef.close(true);
  }
}
