import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import { SettingService } from '../../../../core/services/setting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { forkJoin } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentViewModalComponent } from '../../../../core/components/document-view-modal/document-view-modal.component';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as Globals from '../../../globals';
import { Location } from '@angular/common';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-external-user-list',
  templateUrl: './external-user-list.component.html',
  styleUrls: ['./external-user-list.component.scss']
})
export class ExternalUserListComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  @Input() userType: string;
  hasScroll: boolean = false
  userTypeStr:string;
  permissionLevel;
  userTypeId:number;
  externalUserList: any = [];
  externalUserTypeList: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  defaultPagination: number;
  totalVendorList: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  pageSize:number;
  scrollAmount;
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  search_key : any = null;
  headerThOption: any = [];
  sort_by = '';
  sort_type = '';
  externalUserConfig:any;

  constructor(
    private settingService: SettingService,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private toastr: ToastrService,
    private element: ElementRef
  ) { 
    this.externalUserConfig = AppPageStateConfig.externalUserConfig;
  }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    
    this.pageSize = Globals.pageSize;

   // console.log(this.userType);
    if(this.userType=="vendor")
    {       
      this.userTypeStr = "Vendor"
      this.permissionLevel = this.havePermission('Vendor','');
      if(this.externalUserConfig.vendorListConfig.search_key!=''){
        this.search_key = this.externalUserConfig.vendorListConfig.search_key;
      }
      if(this.externalUserConfig.vendorListConfig.defaultPagination!=null){
        this.defaultPagination = this.externalUserConfig.vendorListConfig.defaultPagination;
      }
      if(this.externalUserConfig.vendorListConfig.itemPerPage!=null){
        this.itemPerPage = this.externalUserConfig.vendorListConfig.itemPerPage;
      }
      if(this.externalUserConfig.vendorListConfig.pageSize!=null){
        this.pageSize = this.externalUserConfig.vendorListConfig.pageSize;
      }
    }
    if(this.userType=="contractor")
    {       
      this.userTypeStr = "Contractor"
      this.permissionLevel  =this.havePermission('Contractor','');
      if(this.externalUserConfig.contractorListConfig.search_key!=''){
        this.search_key = this.externalUserConfig.contractorListConfig.search_key;
      }
      if(this.externalUserConfig.contractorListConfig.defaultPagination!=null){
        this.defaultPagination = this.externalUserConfig.contractorListConfig.defaultPagination;
      }
      if(this.externalUserConfig.contractorListConfig.itemPerPage!=null){
        this.itemPerPage = this.externalUserConfig.contractorListConfig.itemPerPage;
      }
      if(this.externalUserConfig.contractorListConfig.pageSize!=null){
        this.pageSize = this.externalUserConfig.contractorListConfig.pageSize;
      }
    }
    if(this.userType=="partner")
    {       
      this.userTypeStr = "Partner"
      this.permissionLevel  =this.havePermission('Partners','');
      if(this.externalUserConfig.partnerListConfig.search_key!=''){
        this.search_key = this.externalUserConfig.partnerListConfig.search_key;
      }
      if(this.externalUserConfig.partnerListConfig.defaultPagination!=null){
        this.defaultPagination = this.externalUserConfig.partnerListConfig.defaultPagination;
      }
      if(this.externalUserConfig.partnerListConfig.itemPerPage!=null){
        this.itemPerPage = this.externalUserConfig.partnerListConfig.itemPerPage;
      }
      if(this.externalUserConfig.partnerListConfig.pageSize!=null){
        this.pageSize = this.externalUserConfig.partnerListConfig.pageSize;
      }
    }
    if(this.userType=="operator")
    {       
      this.userTypeStr = "Operator"
      this.permissionLevel  =this.havePermission('Operator','');
      if(this.externalUserConfig.operatorListConfig.search_key!=''){
        this.search_key = this.externalUserConfig.operatorListConfig.search_key;
      }
      if(this.externalUserConfig.operatorListConfig.defaultPagination!=null){
        this.defaultPagination = this.externalUserConfig.operatorListConfig.defaultPagination;
      }
      if(this.externalUserConfig.operatorListConfig.itemPerPage!=null){
        this.itemPerPage = this.externalUserConfig.operatorListConfig.itemPerPage;
      }
      if(this.externalUserConfig.operatorListConfig.pageSize!=null){
        this.pageSize = this.externalUserConfig.operatorListConfig.pageSize;
      }
    }
    if(this.userType=="crusher")
    {       
      this.userTypeStr = "Crusher"
      this.permissionLevel  =this.havePermission('Crusher','');
      if(this.externalUserConfig.crusherListConfig.search_key!=''){
        this.search_key = this.externalUserConfig.crusherListConfig.search_key;
      }
      if(this.externalUserConfig.crusherListConfig.defaultPagination!=null){
        this.defaultPagination = this.externalUserConfig.crusherListConfig.defaultPagination;
      }
      if(this.externalUserConfig.crusherListConfig.itemPerPage!=null){
        this.itemPerPage = this.externalUserConfig.crusherListConfig.itemPerPage;
      }
      if(this.externalUserConfig.crusherListConfig.pageSize!=null){
        this.pageSize = this.externalUserConfig.crusherListConfig.pageSize;
      }
    }
    this.headerThOption = [
      {
        id: 1,
        name: "Contact Person Name",
        code: "contact_person_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Organisation Name",
        code: "organisation_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Code",
        code: "code",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "Email",
        code: "email",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Contact No",
        code: "contact_no",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Address",
        code: "address",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 7,
        name: "Adhar",
        code: "adhar",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 8,
        name: "Pan",
        code: "pan",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 9,
        name: "GST",
        code: "gst",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 10,
        name: "Bank AC No",
        code: "bank_ac_no",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 11,
        name: "Salary",
        code: "salary",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 12,
        name: "Documents",
        code: "documents",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      }
    ];
    this.loadData();
  }

  ngAfterViewInit() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    if (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }
  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  loadData() {
    this.getExternalUserTypeList();
  }
  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }
  getExternalUserList() {
    let params: URLSearchParams = new URLSearchParams();
    params.set('page', this.defaultPagination.toString());
    if (this.itemPerPage)
      params.set('page_size', this.itemPerPage.toString()); 
    if(this.search_key != null)
      params.set('search', this.search_key.toString());
    if (this.sort_by != '') 
      params.set('field_name', this.sort_by.toString());
    if (this.sort_type != '') 
      params.set('order_by', this.sort_type.toString());     
    params.set('user_type', this.userTypeId.toString());
    this.settingService.getExternalUserListByType(params).subscribe(
      res => {        
        this.totalVendorList = res.count;
        this.externalUserList = res['results'];
      
        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalVendorList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
         
        }
        else {
          this.upper_count = this.totalVendorList
        }
        //console.log(this.externalUserList);
        this.loading = LoadingState.Ready;        
      },
      error=>{
        this.loading = LoadingState.Ready;        
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getExternalUserTypeList(){
    this.loading = LoadingState.Processing;
    this.settingService.getExternalUserTypeList().subscribe(
      res => {        
        this.externalUserTypeList = res;
        var index = this.externalUserTypeList.findIndex(x => x['type_name'].toLowerCase() == this.userType);
        if (index != -1) {
            this.userTypeId= this.externalUserTypeList[index]['id'];
            this.getExternalUserList();
        }
        else{
          this.loading = LoadingState.Ready;        
        }
      },
      error=>{       
        this.loading = LoadingState.Ready;        
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  };

  pagination() {
    this.loading = LoadingState.Processing;
    if(this.userType=="vendor")
    { 
      this.externalUserConfig.vendorListConfig.defaultPagination = this.defaultPagination;
    }
    if(this.userType=="contractor")
    { 
      this.externalUserConfig.contractorListConfig.defaultPagination = this.defaultPagination;
    }
    if(this.userType=="partner")
    { 
      this.externalUserConfig.partnerListConfig.defaultPagination = this.defaultPagination;
    }
    if(this.userType=="operator")
    { 
      this.externalUserConfig.operatorListConfig.defaultPagination = this.defaultPagination;
    }
    if(this.userType=="crusher")
    { 
      this.externalUserConfig.crusherListConfig.defaultPagination = this.defaultPagination;
    }
   
    this.getExternalUserList();
  }

  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    if(this.userType=="vendor")
    {  
      this.externalUserConfig.vendorListConfig.itemPerPage = +item;
    }
    if(this.userType=="contractor")
    { 
      this.externalUserConfig.contractorListConfig.itemPerPage = +item;
    }
    if(this.userType=="partner")
    { 
      this.externalUserConfig.partnerListConfig.itemPerPage = +item;
    }
    if(this.userType=="operator")
    { 
      this.externalUserConfig.operatorListConfig.itemPerPage = +item;
    }
    if(this.userType=="crusher")
    { 
      this.externalUserConfig.crusherListConfig.itemPerPage = +item;
    }

   
    this.dataFilter();
  }

  getSearchValue() {
    this.loading = LoadingState.Processing;  
    if(this.userType=="vendor")
    {  
      this.externalUserConfig.vendorListConfig.search_key = this.search_key;
    }
    if(this.userType=="contractor")
    { 
      this.externalUserConfig.contractorListConfig.search_key = this.search_key;
    }
    if(this.userType=="partner")
    { 
      this.externalUserConfig.partnerListConfig.search_key = this.search_key;
    }
    if(this.userType=="operator")
    { 
      this.externalUserConfig.operatorListConfig.search_key = this.search_key;
    }
    if(this.userType=="crusher")
    { 
      this.externalUserConfig.crusherListConfig.search_key = this.search_key;
    }
    this.dataFilter();
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.defaultPagination = 1;
    this.loading = LoadingState.Processing;    
    this.getExternalUserList();
  }

  edit(index) {
    if(this.userType=="vendor")
    {  
      this.router.navigateByUrl('/vendor/edit-vendor/' + this.externalUserList[index]['id']);
    }
    if(this.userType=="contractor")
    {  
      this.router.navigateByUrl('/contractor/edit-contractor/' + this.externalUserList[index]['id']);
    }
    if(this.userType=="partner")
    {       
      this.router.navigateByUrl('/partners/edit-partner/' + this.externalUserList[index]['id']);
    }
    if(this.userType=="operator")
    {       
      this.router.navigateByUrl('/operator/edit-operator/' + this.externalUserList[index]['id']);
    }
    if(this.userType=="crusher")
    {       
      this.router.navigateByUrl('/crusher/edit-crusher/' + this.externalUserList[index]['id']);
    }
  }

  delete(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){

    this.loading = LoadingState.Processing
    this.settingService.deleteExternalUser(this.externalUserList[index]['id']).subscribe(
      res => {
       // console.log(res);
        this.externalUserList.splice(index, 1);
        this.loading = LoadingState.Ready
      },
      error => {
        //console.log(error);
        this.loading = LoadingState.Ready
      }
    )
  }
  this.dialogRef = null;
  })
  }

  getFileCount(item) {
    var count = 0;
    if (item.document_details.length > 0)
      count += item.document_details.length
    if (item.adhar_doc != null && item.adhar_doc != '')
      count++
    if (item.gst_doc != null && item.gst_doc != '')
      count++
    if (item.pan_doc != null && item.pan_doc != '')
      count++
    if (item.cancelled_cheque_doc != null && item.cancelled_cheque_doc != '')
      count++
    if (item.trade_licence_doc != null && item.trade_licence_doc != '')
      count++

    // 
    if (count == 0)
      return 'No File'
    else if (count == 1)
      return count + ' File'
    else
      return count + ' Files'
  }

  viewDoc(item: any) {
   // console.log(item)
    var data = [];
    if (item.adhar_doc != null && item.adhar_doc != '')
      data.push({
        name: "Adhar",
        url: item.adhar_doc
      })
    if (item.gst_doc != null && item.gst_doc != '')
      data.push({
        name: "GST",
        url: item.gst_doc
      })
    if (item.pan_doc != null && item.pan_doc != '')
      data.push({
        name: "Pan",
        url: item.pan_doc
      })
    if (item.cancelled_cheque_doc != null && item.cancelled_cheque_doc != '')
      data.push({
        name: "Cancelled Cheque",
        url: item.cancelled_cheque_doc
      })
    if (item.trade_licence_doc != null && item.trade_licence_doc != '')
      data.push({
        name: "Trade Licence",
        url: item.trade_licence_doc
      })
    if (item.document_details.length > 0)
      item.document_details.forEach(x => {
        var item = {
          name: x.document_name,
          url: x.document
        }
        data.push(item)
      })
    // 
    if (data.length > 0) {
      let dialogRef = this.dialog.open(DocumentViewModalComponent, {
        width: '525px',
        data: { items: data }
      });
      dialogRef.afterClosed().subscribe(result => {
       // console.log(result)
      })
    }
  }

  havePermission(section,parentSection) {    
    return this.settingService.havePermission(section,parentSection);
  }
  getConfigData(data){
    this.headerThOption = data
  }
  
  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
    if(this.userType=="vendor")
    { 
      this.externalUserConfig.vendorListConfig.defaultPagination = this.defaultPagination;
      this.externalUserConfig.vendorListConfig.pageSize = +this.itemPerPage;
    }

    if(this.userType=="contractor")
    { 
      this.externalUserConfig.contractorListConfig.defaultPagination = this.defaultPagination;
      this.externalUserConfig.contractorListConfig.pageSize = +this.itemPerPage;
    }
    if(this.userType=="partner")
    { 
      this.externalUserConfig.partnerListConfig.search_key = this.search_key;
      this.externalUserConfig.partnerListConfig.pageSize = +this.itemPerPage;
      
    }
    if(this.userType=="operator")
    { 
      this.externalUserConfig.operatorListConfig.search_key = this.search_key;
      this.externalUserConfig.operatorListConfig.pageSize = +this.itemPerPage;
    }
    if(this.userType=="crusher")
    { 
      this.externalUserConfig.crusherListConfig.search_key = this.search_key;
      this.externalUserConfig.crusherListConfig.pageSize = +this.itemPerPage;
    }
    this.getExternalUserList();
  }

}
