import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoadingState } from '../../loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TenderService } from '../../../services/tender.service';
import { SettingService} from '../../../services/setting.service'
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-tender-tab',
  templateUrl: './new-tender-tab.component.html',
  styleUrls: ['./new-tender-tab.component.scss']
})
export class NewTenderTabComponent implements OnInit {
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  tenderDocumentList: any = [];
  @Input() isEdit: boolean;
  @Input() isFieldError: boolean;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  tenderBasicDetails: any = {
    tender_opened_on: '',
    tender_received_on: '',
    tender_final_date: '',
    tender_added_by: '',
    tender_type: null,
    tender_aasigned_to: ''
  };
  tenderTypeList: any= [];
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
		public dialog: MatDialog,
    private tenderService: TenderService,
    public settingService:SettingService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender_final_date: ['', Validators.required],
      tender_opened_on: ['', Validators.required],
      tender_added_by: ['', Validators.required],
      tender_type: [null, Validators.required],
      tender_received_on: ['', Validators.required],
      tender_aasigned_to: ['', Validators.required],
      tender_documents: this.formBuilder.array([]),
    });
    //console.log(this.tenderId)
    if (this.tenderId != undefined) {
      this.getTenderBasicDetails();
      this.getTenderDocList();
    }
    else {
      this.add_tender_document()
      this.loading = LoadingState.Ready;
    }
    this.getTenderType()
  }

  getTenderType(){
    this.tenderService.getTenderType().subscribe(
      res => {
        //console.log(res)
        this.tenderTypeList = res;
      },
      error => {
        console.log(error)
      }
    )
  }

  getTenderBasicDetails() {
    this.tenderService.getTenderBasicDetails(this.tenderId).subscribe(
      res => {
       // console.log(res)
        this.tenderBasicDetails = res;
        var tenderOpenedOn = new Date(this.tenderBasicDetails['tender_opened_on'])
        this.tenderBasicDetails.tender_opened_on = {
          year: tenderOpenedOn.getFullYear(),
          month: tenderOpenedOn.getMonth() + 1,
          day: tenderOpenedOn.getDate()
        }
        // 
        var tenderReceivedOn = new Date(this.tenderBasicDetails['tender_received_on'])
        this.tenderBasicDetails.tender_received_on = {
          year: tenderReceivedOn.getFullYear(),
          month: tenderReceivedOn.getMonth() + 1,
          day: tenderReceivedOn.getDate()
        }
        // 
        var tenderFinalDate = new Date(this.tenderBasicDetails['tender_final_date'])
        this.tenderBasicDetails.tender_final_date = {
          year: tenderFinalDate.getFullYear(),
          month: tenderFinalDate.getMonth() + 1,
          day: tenderFinalDate.getDate()
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  getTenderDocList() {
    this.tenderService.getTenderDocList(this.tenderId).subscribe(
      res => {
       // console.log(res)
        this.tenderDocumentList = res['result'];
      },
      error => {
        console.log(error)
      }
    )
  }

  create_tender_document() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      tender_file: [null, Validators.required],
      tender_document: null
    });
  }

  add_tender_document() {
    const control = <FormArray>this.form.controls['tender_documents'];
    control.push(this.create_tender_document());
  }

  delete_tender_document(index: number) {
    const control = <FormArray>this.form.controls['tender_documents'];
    control.removeAt(index);
  }

  getFormArrayControl(form) {
    return form.get('tender_documents').controls
  }

  onSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['tender_documents'];
        control.at(index).patchValue({
          tender_file: event.target.files[0]['name'],
          tender_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  createNewTender() {
    if (this.form.valid) {

      var tender_documents_data = [];
      tender_documents_data = this.form.value.tender_documents;
      this.loading = LoadingState.Processing
      if (this.tenderId == undefined) {
		  
        // tender_final_date
        var tenderFinalDate = this.settingService.getProperDate(this.form.value.tender_final_date)
        // tender_opened_on
        var tenderOpenedOn = this.settingService.getProperDate(this.form.value.tender_opened_on)
        // tender_received_on
        var tenderReceivedOn = this.settingService.getProperDate(this.form.value.tender_received_on)
        const newTenderData = {
          tender_final_date: tenderFinalDate.toISOString(),//aa
          tender_opened_on: tenderOpenedOn.toISOString(),//aa
          tender_received_on: tenderReceivedOn.toISOString(),//aa
          tender_added_by: this.form.value.tender_added_by,
          tender_aasigned_to: this.form.value.tender_aasigned_to,
          tender_type: this.form.value.tender_type
        }
        this.tenderDocumentList = [];
        this.tenderService.addNewTender(newTenderData).subscribe(
          res => {
            // console.log(res)
            this.tenderId = res['id']
            this.valueChange.emit(this.tenderId);
            // this.form.controls['tender_final_date'].disable();
            // this.form.controls['tender_opened_on'].disable();
            // this.form.controls['tender_added_by'].disable();
            // this.form.controls['tender_received_on'].disable();
            // this.form.controls['tender_aasigned_to'].disable();
            var forkArray = [];
            tender_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("tender", this.tenderId);
              formData.append("document_name", x.document_name);
              formData.append("tender_document", x.tender_document, x.tender_document['name']);
              forkArray.push(this.tenderService.addTenderDoc(formData))
            })
            forkJoin(forkArray).subscribe(
              (result: any[]) => {
                // console.log(result)
                result.forEach(y => {
                  this.tenderDocumentList.push(y)
                })
                for (var k = tender_documents_data.length - 1; k >= 0; k--) {
                  this.delete_tender_document(k)
                }
                // this.add_tender_document()
                this.loading = LoadingState.Ready;
              },
              err => {
                this.loading = LoadingState.Ready;
                // console.log(err)
              }
            )
            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
              });  
          },
          error => {
            this.loading = LoadingState.Ready;
            // console.log(error)
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
              });
          }
        )
      }
      else {

        if (tender_documents_data.length > 0) {
          var forkArray = [];
          tender_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("tender", this.tenderId);
            formData.append("document_name", x.document_name);
            formData.append("tender_document", x.tender_document, x.tender_document['name']);
            forkArray.push(this.tenderService.addTenderDoc(formData))
          })
          forkJoin(forkArray).subscribe(
            result => {
             // console.log(result)
             // console.log(this.tenderDocumentList)
              result.forEach(y => {
                this.tenderDocumentList.push(y)
              })
              for (var k = tender_documents_data.length - 1; k >= 0; k--) {
                this.delete_tender_document(k)
              }
              // this.add_tender_document();
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.updateTender(false);
        }
        else {
          this.updateTender(true);
        }

      }

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  updateTender(key: boolean) {
   
    // tender_final_date
    var tenderFinalDate = this.settingService.getProperDate(this.form.value.tender_final_date)
    // tender_opened_on
    var tenderOpenedOn = this.settingService.getProperDate(this.form.value.tender_opened_on)
    // tender_received_on
    var tenderReceivedOn = this.settingService.getProperDate(this.form.value.tender_received_on)

    const updateTenderData = {
      tender_final_date: tenderFinalDate.toISOString(),//aa
      tender_opened_on: tenderOpenedOn.toISOString(),//aa
      tender_received_on: tenderReceivedOn.toISOString(),//aa
      tender_added_by: this.form.value.tender_added_by,
      tender_aasigned_to: this.form.value.tender_aasigned_to,
      tender_type: this.form.value.tender_type,
    }
    this.tenderService.editTender(this.tenderId, updateTenderData).subscribe(
      res => {
      //  console.log(res)
        if (key) {
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        }
      },
      error => {
        //console.log(error)
        if (key) {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      }
    )
  }

  editTenderDoc(index) {
    this.tenderDocumentList[index]['isEdit'] = true
    this.tenderDocumentList[index]['isFieldError'] = false
  }

  deleteTenderDoc(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.tenderService.deleteTenderDoc(this.tenderDocumentList[index]['id']).subscribe(
        res => {
          this.tenderDocumentList.splice(index, 1)
          // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })
   
  }

  renameTenderDoc(index) {
    if (this.tenderDocumentList[index]['document_name'] == '') {
      this.tenderDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.tenderDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.tenderDocumentList[index]['document_name']
      }
      this.tenderService.editTenderDoc(this.tenderDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.tenderDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }

  }

  viewDoc(documentData){
    // console.log(documentData);
    window.open(documentData.tender_document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
