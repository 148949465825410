import { Component, OnInit, ViewChild, ElementRef, Input,EventEmitter,Output} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../loading/loading.component';
import { ToastrService } from 'ngx-toastr';
import * as Globals from '../../../../globals';
import { AttendanceService } from '../../../../services/attendance.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { forkJoin } from 'rxjs';
import { ApprovalDialogComponent } from '../../../../components/dialog/approval-dialog/approval-dialog.component';
import {fadeInOut} from '../../../../../core/animation';
import { SettingService } from '../../../../services/setting.service';
import { EmployeesService } from '../../../../services/employees.service';
import { LocalService } from '../../../../services/local.service';
import { DocumentViewModalComponent } from '../../../../../core/components/document-view-modal/document-view-modal.component';

@Component({
  selector: 'app-normal-leave',
  templateUrl: './normal-leave.component.html',
  styleUrls: ['./normal-leave.component.scss'],
  animations:[fadeInOut]
})
export class NormalLeaveComponent implements OnInit {
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  @Input() employeeId:number;
  @Output() resetSearchKey = new EventEmitter();

  loading: LoadingState = LoadingState.NotReady;
  approvalDialogRef: MatDialogRef<ApprovalDialogComponent>;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  leaveApproval: any = [];
  totalLeaveApproval: number;
  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList: any = [];
  pageSize: number;
  search_key = '';
  headerThOption = [];
  sort_by = '';
  sort_type = '';
  CL = '';
  EL = '';
  absent = '';
  designationList: any;
  departmentList: any;
  reportingHeadList:any;
  approvalArray: any = [];
  leaveTypeCheckbox: any = [];
  leaveTypes = '';
  checkList = [];
  designation:number = null;
  reportingManager:number = null;

  checked = false;
  checkedRow:any = [];
  isCheckedRow:boolean;
  multiAttendanceApprovals:any = [];
  scrollAmount;
  hasScroll:boolean = false
  bulkPunchApproval:string;
  dateRange = null;
  leaveStartDate = '';
  leaveEndDate = '';
  department:number= null;
  requestType:any = [];
  reportTypeCheckbox: any = [];
  leaveCheckList = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private route: ActivatedRoute,
    private element: ElementRef ,
    private settingService:SettingService,
    private employeesService:EmployeesService,
    private localService:LocalService,
  ) { }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;

    this.headerThOption = [
      {
        id: 1,
        name: "Name",
        code: "name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 8,
        name: "Designation",
        code: "designation",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 9,
        name: "Department",
        code: "department",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 10,
        name: "Reporting Head",
        code: "reporting_head",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 11,
        name: "HOD",
        code: "hod",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Date of Application",
        code: "date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Start Date",
        code: "duration_start",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "End Date",
        code: "duration_end",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Leave Type",
        code: "leave_type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Reason of Leave",
        code: "reason_of_leave",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 7,
        name: "Request Type",
        code: "request_type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 7,
        name: "Approval",
        code: "approval",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
    ];

    this.getMasterData();
  //  this.getLeaveLsit();
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnChanges(){
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    if(this.employeeId != undefined){
      this.department = null;
      this.designation = null;
      this.reportingManager = null;
    }
    this.dataFilter();
  }



  ngAfterViewInit(){ //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
      if(this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth){
         this.hasScroll = true;
      }else{
        this.hasScroll = false;
      }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  getMasterData() {
    this.loading = LoadingState.Processing;
    var forkArray = [];
    let reporitnHeadparam: URLSearchParams = new URLSearchParams();
    reporitnHeadparam.set('user_type','reporting_head');

    forkArray.push(this.employeesService.getDepartment())
    forkArray.push(this.settingService.getReprotingHeadAndHodList(reporitnHeadparam))
    forkArray.push(this.employeesService.getDesignation())

    forkJoin(forkArray).subscribe(
      ([Department,reportingHead,designation]) => {
        this.departmentList         = Department['result'];
        this.reportingHeadList      = reportingHead['result'];
        this.designationList        = designation['result'];
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }


  getLeaveLsit() {
    var userid = this.localService.getJsonValue('userid');

    this.loading = LoadingState.Processing;
    let param: URLSearchParams = new URLSearchParams();
    param.set('page', this.defaultPagination.toString());
     param.set('team_approval_flag', '1');

     if(this.employeeId != undefined)
    {
      param.set('users',this.employeeId.toString());
    }

    if (this.search_key != '') {
      param.set('search', this.search_key.toString());
    }
    if (this.itemPerPage) {
      param.set('page_count', this.itemPerPage.toString());
    }
    if (this.leaveTypeCheckbox.length > 0) {
      param.set('leave_type', this.leaveTypeCheckbox.toString());
    }
    if (this.sort_by != '') {
      param.set('field_name', this.sort_by.toString());
    }
    if (this.leaveEndDate != '') {
      param.set('to_date', this.leaveEndDate.toString());
    }
    if (this.leaveStartDate != '') {
      param.set('from_date', this.leaveStartDate.toString());
    }

    if (this.sort_type != '') {
      param.set('order_by', this.sort_type.toString());
    }
    if(this.department!=null)
    {
      param.set('dept_filter', this.department.toString());
    }
    if(this.designation!=null)
    {
      param.set('designation', this.designation.toString());
    }
    if(this.reportingManager!=null)
    {
      param.set('reporting_head', this.reportingManager.toString());
    }
    if (this.reportTypeCheckbox.length > 0) {
      param.set('request_type', this.reportTypeCheckbox.toString());
    }
    this.attendanceService.leaveNormalList(param).subscribe(
      res => {
        this.checkedRow = [];
        this.totalLeaveApproval = res['count'];
        this.leaveApproval = res['results'];

        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalLeaveApproval > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination

        }
        else {
          this.upper_count = this.totalLeaveApproval
        }
        this.leaveApproval.forEach((x,index)=>{
          this.checkedRow[index] = {'id':x.id,'checked':false};
        })
        this.loading = LoadingState.Ready;

      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
      }
    )
  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.isCheckedRow = false;
    this.checked = false;
    this.getLeaveLsit();
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.getLeaveLsit();
  };

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.checked = false;
    this.isCheckedRow = false;
    this.getLeaveLsit();
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
   // console.log(this.requestType)
  }

  reload() {
   
    this.loading = LoadingState.Processing;
    this.leaveTypeCheckbox = [];
    this.checkList = [];
    this.department = null;
    this.designation = null;
    this.reportingManager = null;
    this.checked = false;
    this.isCheckedRow = false;
    this.requestType = [];
    this.sort_type = '';
    this.sort_by = '';
    this.leaveStartDate = '';
    this.reportTypeCheckbox = [];
    this.leaveCheckList = [];
    this.leaveEndDate = '';
    this.dateRange = null;
    this.itemPerPage = Globals.itemPerPage;
    this.search_key = '';
    this.CL = '';
    this.EL = '';
    this.absent = '';
    this.defaultPagination = 1;
    this.pageSize = Globals.pageSize;
    this.getLeaveLsit();
    
    // this.loading = LoadingState.Ready;

  }

  openApprovalDialog(leaveType, approvalData: any,index:number): void {
    const approvalDialogRef = this.dialog.open(ApprovalDialogComponent, {
      width: '450px',
      data: { from: 'leaveApproval', result: approvalData, leaveType: leaveType }
    });
    this.loading = LoadingState.Processing;
    approvalDialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.leaveApproval.splice(index,1);
        this.checkedRow.splice(index,1);
        this.totalLeaveApproval -= 1; // decresing the count value as data splice from array. so itmecount drop-down hide with given condition.
        let check = this.checkedRow.filter(x=>x.checked == true);
        if(check.length < 1){
          this.isCheckedRow = false;
        }
        this.loading = LoadingState.Ready;
        
      }
      else if(result == false){
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready;
      }else{
        this.loading = LoadingState.Ready;
      }
      
    });
  }

  getSearchValueFromParent(val: any) {
    this.search_key = val;
    if (this.search_key != '') {
      this.dataFilter();
    }
  }


  leaveFilter(index, e) {
    if (e.checked == true) {
      if (this.leaveTypeCheckbox.indexOf(e.source.value) == -1) {
        this.leaveTypeCheckbox.push(e.source.value);
      }
      this.checkList[index] = true;
    }
    else {
      this.leaveTypeCheckbox.splice(this.leaveTypeCheckbox.indexOf(e.source.value), 1);
      this.checkList[index] = false;
    }

    this.dataFilter();
  }

  requestFilter(index, e) {
    if (e.checked == true) {
      if (this.reportTypeCheckbox.indexOf(e.source.value) == -1) {
        this.reportTypeCheckbox.push(e.source.value);
      }
      this.leaveCheckList[index] = true;
    }
    else {
      this.reportTypeCheckbox.splice(this.reportTypeCheckbox.indexOf(e.source.value), 1);
      this.leaveCheckList[index] = false;
    }
    this.dataFilter();
  }




  onListCountSelectionChange(item): void {
    this.isCheckedRow = false;
    this.itemPerPage = item;
    this.dataFilter();
  }

  checkedAll(event){
    
    this.leaveApproval.forEach((x,index)=>{
      this.checkedRow[index] = {'id':x.id,'checked':event.checked};
    })
    this.isCheckedRow = event.checked;
  }

  checkedThisRow(index,event){
    let ctr = 0;
    this.checkedRow.forEach((x,index)=>{
      if(ctr<1){
        if(x.checked == true){
          this.isCheckedRow = true;
          ctr++;
        }else{
          this.isCheckedRow = false;
          this.checked = false;
        }
      }
    })
    
    this.checkAllReverse();
  }

  checkAllReverse() {
    this.checked = true;
    this.checkedRow.forEach(x => {
      if (x.checked==false) {
        this.checked = false;
      }
    })
  }

  submitAdvanceLeaveApproval(event){
    
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      data: {from:'multiAttendanceApproval'}
    });

    this.dialogRef.componentInstance.confirmMessage = "Add Remarks"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing;
        this.multiAttendanceApprovals = [];
        this.checkedRow.forEach((x,index)=>{
          if(x.checked == true){
            this.multiAttendanceApprovals.push({req_id:x.id,approved_status:event})
          }
        })
       

        let data = {
          remarks:result==true?'':result,
          attendence_approvals:this.multiAttendanceApprovals
        }

        this.attendanceService.addMultiAttendanceApprovalV2(data).subscribe(
          res=>{
            // console.log(res)
            this.isCheckedRow = false;
            this.bulkPunchApproval = null;
            this.multiAttendanceApprovals.forEach(x=>{
              let index = this.leaveApproval.findIndex(z => x.req_id == z.id);
              let checkRowIndex = this.checkedRow.findIndex(y => x.req_id == y.id)
              if(index != -1){
                this.leaveApproval.splice(index,1);
                this.totalLeaveApproval -= 1; // decresing the count value as data splice from array. so itmecount drop-down hide with given condition.
               }
              if(checkRowIndex !=-1){
                this.checkedRow.splice(checkRowIndex,1)
              }
            })
            // action with checked all need to call reload due to pagintaion confrigration with next pages.
            if(this.checked == true){
              this.reload();
            }
            this.loading = LoadingState.Ready;
            this.toastr.success('Your request has been added.', '', {
              timeOut: 3000,
            });
          },
          error=>{
            this.loading = LoadingState.Ready;
            this.bulkPunchApproval = null;
            this.toastr.error('Internal Server error !', '', {
              timeOut: 3000,
            });
          }
        )

        
       
      }else{
        this.bulkPunchApproval = null;
      }
      this.dialogRef = null;
  })
  }

  scrollToRight(){
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft(){
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  scroll = (event: any): void => {
    //console.log(this.isCheckedRow)
    if(this.isCheckedRow == true){
      let prevScrollpos = 150;
      let currentScrollPos = window.pageYOffset;
     
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("bulkPunchApproval").classList.remove("fixedTotop");
        } else {
          document.getElementById("bulkPunchApproval").classList.add("fixedTotop");
        }
    }  
  }


  dateTimeReset(){
    this.dateRange = null;
    this.leaveStartDate = '';
    this.leaveEndDate = '';
    this.dataFilter()
  }
  formatHandler(event) {
    if (event.value[0] != null) {
      this.leaveStartDate = this.settingService.formatDate(event.value[0])
      this.leaveEndDate = this.settingService.formatDate(event.value[1])
      this.dataFilter()
    }
    else {
      this.reload()
    }

  }

  //filter

  resetFilter(event:number){
    this.resetSearchKey.emit();
    this.employeeId = undefined;
    this.dataFilter();
  }
  viewDocument(items: any){
    var data = [];
    items.forEach(x => {
      var item = {
        name: x.document_name,
        url: x.document
      }
      data.push(item)
    })
    let dialogRef = this.dialog.open(DocumentViewModalComponent, {
      width: '525px',
      data: { items: data }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
    })
  }
  getConfigData(data){
    this.headerThOption = data
  }

  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }

}
