import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddSupplierModalComponent } from '../add-supplier-modal/add-supplier-modal.component';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { SettingService } from '../../../../core/services/setting.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-raw-material-crusher-inner-tab-col',
  templateUrl: './raw-material-crusher-inner-tab-col.component.html',
  styleUrls: ['./raw-material-crusher-inner-tab-col.component.scss']
})
export class RawMaterialCrusherInnerTabColComponent implements OnInit {

  @Input() tenderId: number;
  @Input() type: string;
  @Input() data: any;
  loading: LoadingState = LoadingState.NotReady;
  tenderRawMaterialSupplierList: any = [];
  vendorList: any = [];
  externalUserTypeList: any = [];
  external_user_type: number;
  form: FormGroup;
  selectedVendorList: any = [];
  selectedVendorItems: any = [];
  visibleKey: boolean;
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    //console.log(this.tenderId)
    //console.log(this.type)
    //console.log(this.data)
    this.form = this.formBuilder.group({
      selected_vendor: [null, Validators.required],
      vendor_list: this.formBuilder.array([])
    });
    this.loadData();
  }

  create_vendor_list(item) {
    return this.formBuilder.group({
      external_user: [item.external_user],
      contact_person_name: [{ value: item.contact_person_name, disabled: true }],
      latitude: [item.latitude, Validators.required],
      longitude: [item.longitude, Validators.required],
      address: [item.address, Validators.required]
    });
  }

  add_vendor_row(item) {
    //console.log(item)
    const control = <FormArray>this.form.controls['vendor_list'];
    control.push(this.create_vendor_list(item));
  }

  delete_vendor_row(index: number) {
    const control = <FormArray>this.form.controls['vendor_list'];
    control.removeAt(index);
  }

  getVendorFormArrayControl(form) {
    return form.get('vendor_list').controls
  }


  onVendorChange() {
    this.selectedVendorList.forEach(x => {
      var index = this.vendorList.findIndex(y => y.id == x);
      if (index != -1) {
        var itemIndex = this.selectedVendorItems.findIndex(z => z == x)
        if (itemIndex == -1) {
          this.selectedVendorItems.push(x)
          var data = {
            external_user: this.vendorList[index]['id'],
            contact_person_name: this.vendorList[index]['contact_person_name'],
            latitude: '',
            longitude: '',
            address: '',
          }
          this.add_vendor_row(data)
        }
      }
    })
    // 
    this.selectedVendorItems.forEach(m => {
      var selectIndex = this.selectedVendorList.findIndex(n => n == m)
      if (selectIndex == -1) {
        var removalIndex = this.selectedVendorItems.findIndex(o => o == m);
        if (removalIndex != -1) {
          this.selectedVendorItems.splice(removalIndex, 1);
          this.delete_vendor_row(removalIndex)
        }
      }
    })
    //console.log(this.selectedVendorList)
  }

  getExternalUserListByType(user_type) {

    var params = 'page_size=0&user_type='+user_type;
    this.settingService.getExternalUserListByType(params).subscribe(
      res => {
        // console.log(res)
        this.vendorList = res['results']
        this.getTenderRawMaterialSupplierList();
      },
      error => {
        console.log(error)
      }
    )
  }

  loadData() {
    var forkArray = [];
    forkArray.push(this.settingService.getExternalUserTypeList())
    forkJoin(forkArray).subscribe(
      ([userTypeData]) => {
       // console.log(userTypeData)
        this.externalUserTypeList = userTypeData;
        var index = this.externalUserTypeList.findIndex(x => x['type_name'].toLowerCase() == this.type);
       // console.log(index);
        if (index != -1) {
          this.external_user_type = this.externalUserTypeList[index]['id']
          this.getExternalUserListByType(this.external_user_type)
          
        }
       // console.log(this.vendorList)
        // this.loading = LoadingState.Ready;
      },
      err => {
        // this.loading = LoadingState.Ready;
        // console.log(err)
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  getTenderRawMaterialSupplierList() {
    var params = '';
    params = '?tender=' + this.tenderId + '&external_user_type=' + this.external_user_type + '&tender_survey_material=' + this.data['id']
    this.tenderService.getTenderRawMaterialSupplierList(params).subscribe(
      res => {
       // console.log(res)
        this.tenderRawMaterialSupplierList = res['result'];
        this.tenderRawMaterialSupplierList.forEach(x => {
          var index = this.selectedVendorList.findIndex(y => y == x.external_user)
          //console.log(index)
          if (index == -1) {
            this.selectedVendorList.push(x.external_user)
            this.selectedVendorItems.push(x.external_user)
            var vendorIndex = this.vendorList.findIndex(z => z.id == x.external_user)
            //console.log(vendorIndex)
            if (vendorIndex != -1) {
              var data = {
                external_user: x.external_user,
                contact_person_name: this.vendorList[vendorIndex]['contact_person_name'],
                address: x.address,
                latitude: x.latitude,
                longitude: x.longitude
              }
              this.add_vendor_row(data)
            }
          }
        })
       // console.log(this.selectedVendorList)
        //console.log(this.selectedVendorItems)
        this.visibleKey = true
        this.loading = LoadingState.Ready
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }

  save() {
    //console.log(this.form.value)
    if (this.form.valid) {
      var vendorData = [];
      var data = {
        tender: this.tenderId,
        external_user_type: this.external_user_type,
        tender_survey_material: this.data['id'],
      };
      var mapping_details_data = [];
      vendorData = Object.assign([], this.form.value.vendor_list)
      vendorData.forEach(x => {
        var d = {
          external_user: x.external_user,
          latitude: x.latitude,
          longitude: x.longitude,
          address: x.address
        }
        mapping_details_data.push(d)
        data['mapping_details'] = mapping_details_data;
      })
      this.loading = LoadingState.Processing
      this.tenderService.addTenderRawMaterialSupplier(data).subscribe(
        res => {
         // console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
          //console.log(error)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
     // console.log(data)
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  handleAddressChange(address, index) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    const control = <FormArray>this.form.controls['vendor_list'];
    control.at(index).patchValue({
      latitude: lat,
      longitude: lng,
      address: formattedAddress
    })
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
