import {Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../loading/loading.component';
import { AttendanceService } from '../../../services/attendance.service';
import { LocalService } from '../../../services/local.service';
// import { EChartOption } from 'echarts';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-previous-leave-record',
  templateUrl: './previous-leave-record.component.html',
  styleUrls: ['./previous-leave-record.component.scss']
})
export class PreviousLeaveRecordComponent implements OnInit {

  @Input() year: number;
  @Input() month: number;
  loading: LoadingState = LoadingState.NotReady;
  is_previous:string;
  pieChartLegend = false;
  pieChartOptions = {
    responsive: true
  }

  pieChartLabels = ['Logged In', 'Total'];
  pieChartColor: any = [
    {
      backgroundColor: ['rgba(42,63,84,0.9)',
        'rgba(51, 181, 229, 0.8)'
      ],
      borderWidth: 0,
    }
  ]

  firstPieChartData: any = [
    {
      data: []
    }
  ];

  secondPieChartData: any = [
    {
      data: []
    }
  ];

  flexiLeaves: any;
  salayTypeCode: string;

  constructor(
    private toastr: ToastrService,
    private attendanceService:AttendanceService,
    private localService:LocalService,
  ) { }

  ngOnInit() {
    // this.is_previous='false';
    // this.graceLeaveList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.is_previous='false';
    this.graceLeaveList();
    this.flexiLeaves = undefined;
    this.salayTypeCode = "";
    this.firstPieChartData = [
      {
        data: []
      }
    ]
    this.secondPieChartData = [
      {
        data: []
      }
    ]
  }
  

  graceLeaveList(){
    this.loading = LoadingState.Processing;
    var current_date = this.year + '-' + this.month + '-' + 1;
    var emp_id = this.localService.getJsonValue('userid');

    let params: URLSearchParams = new URLSearchParams();
  
    if(emp_id !='')
    {
      params.set('employee_id', emp_id.toString());
    }

    if(current_date !='')
    {
      params.set('date', current_date.toString());
      
    }
    if(this.is_previous!=''){
      params.set('is_previous', this.is_previous.toString())
    }
    //console.log(this.year)
    //console.log(this.month)

    this.attendanceService.flexiGraceLeaveListV2(params).subscribe(
      (res: any) => {
        this.flexiLeaves = res['result'];
        this.salayTypeCode = this.flexiLeaves['salary_type_code']
        /*FIRST FORTNIGHT*/
        var finalTotal;
        var loggedIn;
        if (this.flexiLeaves.fortnight[0].total_hours != undefined && this.flexiLeaves.fortnight[0].total_hours != null) {
          var tmpTotalTime = this.flexiLeaves.fortnight[0].total_hours.replace(" hrs ", ":")
          tmpTotalTime = tmpTotalTime.replace(" mins", "")
          tmpTotalTime = tmpTotalTime.split(":");
          var tmpTotalHours = tmpTotalTime[0];
          var tmpTotalMin = tmpTotalTime[1] * 100 / 60;

          finalTotal = parseFloat(tmpTotalHours + '.' + tmpTotalMin);

        }
        if (this.flexiLeaves.fortnight[0].working_hours != undefined && this.flexiLeaves.fortnight[0].working_hours != null) {
          var tmpTotalLogedin = this.flexiLeaves.fortnight[0].working_hours.replace(" hrs ", ":")
          tmpTotalLogedin = tmpTotalLogedin.replace(" mins", "")
          tmpTotalLogedin = tmpTotalLogedin.split(":");
          var tmpTotalLoggedinHours = tmpTotalLogedin[0];
          var tmpTotalLoggedinMin = tmpTotalLogedin[1] * 100 / 60;

          loggedIn = parseFloat(tmpTotalLoggedinHours + '.' + tmpTotalLoggedinMin);

        }

        if (loggedIn > finalTotal) {
          loggedIn = finalTotal;
        }

        this.firstPieChartData = [{ "data": [loggedIn, finalTotal] }];
        this.loading = LoadingState.Ready;

        /*SECOND FORTNIGHT*/
        var finalTotal2;
        var loggedIn2;
        if (this.flexiLeaves.fortnight[1].total_hours != undefined && this.flexiLeaves.fortnight[1].total_hours != null) {
          var tmpTotalTime2 = this.flexiLeaves.fortnight[1].total_hours.replace(" hrs ", ":")
          tmpTotalTime2 = tmpTotalTime2.replace(" mins", "")
          tmpTotalTime2 = tmpTotalTime2.split(":");
          var tmpTotalHours2 = tmpTotalTime2[0];
          var tmpTotalMin2 = tmpTotalTime2[1] * 100 / 60;

          finalTotal2 = parseFloat(tmpTotalHours2 + '.' + tmpTotalMin2);

        }
        if (this.flexiLeaves.fortnight[1].working_hours != undefined && this.flexiLeaves.fortnight[1].working_hours != null) {
          var tmpTotalLogedin2 = this.flexiLeaves.fortnight[1].working_hours.replace(" hrs ", ":")
          tmpTotalLogedin2 = tmpTotalLogedin2.replace(" mins", "")
          tmpTotalLogedin2 = tmpTotalLogedin2.split(":");
          var tmpTotalLoggedinHours2 = tmpTotalLogedin2[0];
          var tmpTotalLoggedinMin2 = tmpTotalLogedin2[1] * 100 / 60;

          loggedIn2 = parseFloat(tmpTotalLoggedinHours2 + '.' + tmpTotalLoggedinMin2);

        }

        if (loggedIn2 > finalTotal2) {
          loggedIn2 = finalTotal2;
        }

        this.secondPieChartData = [{ "data": [loggedIn2, finalTotal2] }];
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

}
