import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../../components/loading/loading.component';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { LocalService } from '../../../../services/local.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-ranking-modal',
  templateUrl: './ranking-modal.component.html',
  styleUrls: ['./ranking-modal.component.scss']
})
export class RankingModalComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  requisitionId: number;
  itemDetail: any = [];
  currentUserArray: any = []
  comparativeId: number;
  itemID: number;
  externalUserTypeList: any = [];
  paymentTerm: any = [];
  totalAmount: number;
  comparativeListing: any = [];
  units: any = [];
  price: any = [];
  comparativeListData: any = [];
  packaging_forwarding: any;
  freight_up_to_site: any;
  savedListArray: any = [];
  savedVendorId: any = [];
  cgst: any;
  sgst: any;
  igst: any;
  deliveryTime: any = [];
  lastPoDetails: any = [];
  packageForwording: any;
  disabledField: any = [];
  approvalVendorList: any = [];
  statusDetails: any;
  approveDisable: boolean;
  permissionList: any = []
  vendorForApproval: number;
  validationError: boolean = false
  finalPermissionApproval: any = [];
  lastApprovedVendor: any = [];
  editEnabled: any = [];
  form: FormGroup;
  isModify: boolean = false;
  remarks: any =[];
  rowGroupName: string = '';
  quotationGroupData: any = [];
  uomId: any;

  constructor(
    public dialogRef: MatDialogRef<RankingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    private localService: LocalService,
  ) {
    this.requisitionId = data['result']['requisition_id']
    this.quotationGroupData = data['result']['quotation_group_details'];
    this.rowGroupName = data['rowGroupName'];
    for (const [k, v] of Object.entries(this.quotationGroupData)) {
      if( k === this.rowGroupName) {
        this.itemID = v['items'][0].item_id;
        this.uomId = v['items'][0].unit_id;
      }
    }
   }

  ngOnInit() {
    // this.loading = LoadingState.Processing;
    this.loading = LoadingState.Ready;
    this.price = [
      { final: 0, base_price: 0, freight_up_to_site: 0, total: 0, packaging_forwarding: 0, freightup_site: 0, cgst: 0, sgst: 0, igst: 0, netAmount: 0 }
    ];
    this.loadQuotationData();
    this.form = this.formBuilder.group({
      quotation_approval: ['Approve', Validators.required],
      modified_vendor: [{ value: null, disabled: true }],
      comments: [''],
    })
  }

  checkQuotationApproval(event) {
    this.form.patchValue(
      {
        approved_expenses: null
      }
    )
    let radioValue = event.value;
    if (radioValue == 'Modify') {
      this.isModify = true;
      this.form.controls['modified_vendor'].enable();
      this.form.controls['modified_vendor'].setValidators([Validators.required]);
      this.form.controls['modified_vendor'].setErrors({ required: true });
    } else {
      this.isModify = false;
      this.form.controls['modified_vendor'].disable();
      this.form.controls['modified_vendor'].clearValidators();
      this.form.controls['modified_vendor'].setErrors(null);
    }
  }

  loadQuotationData() {
    let params: URLSearchParams = new URLSearchParams();
    params.set('section', 'comparative statement');
    var forkArray = [];
    forkArray.push(this.executionPurchaseService.getPaymentTermsMaster())
    forkArray.push(this.executionPurchaseService.getUnit())
    forkArray.push(this.executionPurchaseService.getPurchaseQuotation(this.requisitionId, this.itemID, this.uomId))
    forkArray.push(this.executionPurchaseService.getComparativeItemData(this.requisitionId, this.itemID, this.uomId))
    forkArray.push(this.executionPurchaseService.getrequisitionSubmitForApproval(this.requisitionId))
    forkArray.push(this.executionPurchaseService.getPermissionLevel(params))
    forkArray.push(this.executionPurchaseService.getPermissionLevel(params))

    forkJoin(forkArray).subscribe(
      ([paymentTerm, units, comparativeListing, mainVendorList, statusDetails, permissionList]) => {
        if (paymentTerm) {
          this.paymentTerm = paymentTerm['result'];
        }
        if (units) {

          this.units = units;
        }
        if (comparativeListing) {
          this.comparativeListing = comparativeListing['result']['details_by_group'];
          const allowed = [this.rowGroupName];
          Object.keys(this.comparativeListing).filter(key => !allowed.includes(key)).forEach(key => delete this.comparativeListing[key]);
          for (const [k, v] of Object.entries(this.comparativeListing)) {
            if(k!='') {
              let tempArray: any = [];
              tempArray = v['data'];
              tempArray.forEach((x, index) => {
              //get compared days
              var createdDate = new Date(x.created_at);
              var vekiveryDate = new Date(x.delivery_date);
              var oneDay = 24 * 60 * 60 * 1000;
              this.deliveryTime[index] = Math.round((vekiveryDate.getTime() - createdDate.getTime()) / (oneDay));

              var q = { final: 0, base_price: 0, freight_up_to_site: 0, total: 0, packaging_forwarding: 0, freightup_site: 0, cgst: 0, sgst: 0, igst: 0, netAmount: 0 };
              this.price.push(q);
              var d = {
                uom: x.unit,
                discount: 0,
                final_price: 0,
                price_basis: 0,
                make: null,
                base_price: 0,
                packaging_and_forwarding: 0,
                freight_up_to_site: 0,
                cgst: 0,
                sgst: 0,
                igst: 0,
                payment_terms: null,
                delivery_time: '',
                total_order_value: 0,
                net_landed_cost: 0,
                inco_terms: null,
                warranty_guarantee: null,
                cId: null,
                test: null,
                totalAmount: 0
              }
              if(this.comparativeListData[k] !== undefined) {
                this.comparativeListData[k].push(d);
              } else {
                this.comparativeListData[k] = new Array();
                this.comparativeListData[k].push(d);
              }
            });
          }
        }
        }

        if (mainVendorList) {
          var mainVendorListArray = this.comparativeListing;
          var savedDataLength = mainVendorList['result'].length;
          for (const [k, v] of Object.entries(this.comparativeListing)) {
            mainVendorList['result'].forEach(element => {
              if(k == element.quotation_group) {
                if(this.savedListArray[k] !== undefined) {
                  this.savedListArray[k].push(element);
                } else {
                  this.savedListArray[k] = new Array();
                  this.savedListArray[k].push(element);
                }
              }
            });
            if (savedDataLength > 0 && this.savedListArray[k]!== undefined) {
              this.editEnabled[k] = true;
            } else {
              this.editEnabled[k] = false;
            }
            //check if L4 approved
            if(Object.keys(mainVendorList['permission_details']).length > 0) {
              this.approvalVendorList[k] = mainVendorList['permission_details'][k];
              if(this.approvalVendorList[k] !==  undefined) {
                var finalPermission = this.approvalVendorList[k].length - 1;
                this.finalPermissionApproval[k] = this.approvalVendorList[k][finalPermission];
                //get last approved vendor id
                this.approvalVendorList[k].forEach(x => {
                  if (x.approved_vendor_id) {
                    this.lastApprovedVendor[k] = x.approved_vendor_id;
                  }
                });
              } else {
                this.approvalVendorList[k] = new Array();
              }
            }
            this.lastPoDetails = mainVendorList['last_po'];
            this.remarks[k] = mainVendorList['comparitive_statement_remarks'] ? mainVendorList['comparitive_statement_remarks'] : '';
          }
          
          if (savedDataLength > 0) {
            for (const [index, x] of Object.entries(this.savedListArray)) {
              let savedListItem : any =  x;
              if(this.savedListArray[index]) {
                mainVendorListArray[index]['data'].forEach((y, index2) => {
                  savedListItem.forEach((z) => {
                    if(y.vendor_id === z.vendor && y.item === z.item) {
                      this.comparativeListData[index][index2] = z;
                      this.comparativeListData[index][index2].cId = y.id;
                      this.discountCalc(index, index2, z.discount, y.price);
                    }
                  });
                });
              }
            }
          }
        }

        if (statusDetails) {
          this.statusDetails = statusDetails['result'];
          if (this.statusDetails.status > 3) {
            this.approveDisable = true;
          }
        }
        if (permissionList) {
          var userid = this.localService.getJsonValue('userid');

          if (permissionList['result'].user_approve_details.length > 0) {
            this.permissionList = permissionList['result'];
            this.permissionList.user_approve_details.forEach(x => {
              if (x.user_details.id == userid) {
                this.currentUserArray = x;

              } else {
                this.loading = LoadingState.Ready;
              }
            });
          }

          //check if user approved and disabled fields

          for (const [index, x] of Object.entries(this.savedListArray)) {
            var checkUserSubmit = this.approvalVendorList[index].filter(x => x.user_details.id == userid)[0];
            if (checkUserSubmit != undefined) {
              if (checkUserSubmit.approval || this.finalPermissionApproval[index].approval) {
                this.disabledField[index] = 1;
              }
            } else {
              if (this.statusDetails.status > 3 && this.finalPermissionApproval[index].approval != null) {
                this.disabledField[index] = 1;
              }
            }
          }

        }


      },
      err => {
        this.loading = LoadingState.Ready;
      }
    )
  }

  discountCalc(group, index, percent, total) {
    this.totalAmount = total * percent / 100;
    this.totalAmount = total - this.totalAmount;
    this.comparativeListData[group][index].totalAmount = this.totalAmount;
    this.price[index].final = this.totalAmount.toFixed(3);

    if (this.price[index].final) {
      this.extraChargesPackagingForwarding(index, this.comparativeListData[group][index].packaging_and_forwarding, this.price[index].final, group);
      this.extraChargesFreight(index, this.comparativeListData[group][index].freight_up_to_site, this.price[index].final, group);
      this.extraChargesCGST(index, this.comparativeListData[group][index].cgst, this.price[index].final, group);
      this.extraChargesSGST(index, this.comparativeListData[group][index].sgst, this.price[index].final, group);
      this.extraChargesIGST(index, this.comparativeListData[group][index].igst, this.price[index].final, group);

      this.getAllTotalAmount(index, this.price[index].final, group);
    } else {
      this.extraChargesPackagingForwarding(index, this.comparativeListData[group][index].packaging_and_forwarding, total, group);
      this.extraChargesFreight(index, this.comparativeListData[group][index].freight_up_to_site, total, group);
      this.extraChargesCGST(index, this.comparativeListData[group][index].cgst, total, group);
      this.extraChargesSGST(index, this.comparativeListData[group][index].sgst, total, group);
      this.extraChargesIGST(index, this.comparativeListData[group][index].igst, total, group);

      this.getAllTotalAmount(index, total, group);
    }

  }

  basePriceIteract(index, event, basePrice, item) {
    if (event === '') {
      this.comparativeListData[index].base_price = 0;
    } else {
      this.comparativeListData[index].base_price = event;
    }

    this.getAllTotalAmount(index, basePrice, item);
  }

  // extraChargesPackagingForwarding(index: number, percent: number, basePrice: number) {
  //   if (this.price[index].final) {
  //     this.packaging_forwarding = parseFloat(((this.price[index].final * this.comparativeListing[index].quantity) * percent / 100).toFixed(3));
  //   } else {
  //     this.packaging_forwarding = parseFloat(((basePrice * this.comparativeListing[index].quantity) * percent / 100).toFixed(3));
  //   }
  //   this.price[index].packaging_forwarding = this.packaging_forwarding;
  //   this.getAllTotalAmount(index, basePrice);
  // }
  // extraChargesFreight(index, percent, basePrice) {
  //   if (percent == '') {
  //     this.freight_up_to_site = 0;
  //   } else {
  //     this.freight_up_to_site = parseFloat(percent);
  //   }
  //   this.price[index].freight_up_to_site = this.freight_up_to_site;
  //   this.getAllTotalAmount(index, basePrice);
  // }

  extraChargesPackagingForwarding(index: number, percent: number, basePrice: number, item?) {    

    if (this.price[index].final) {
      this.packaging_forwarding = parseFloat(((this.price[index].final * this.comparativeListing[item]['data'][index].quantity) * percent / 100).toFixed(3));
      this.price[index].packaging_forwarding = this.packaging_forwarding;
      this.extraChargesCGST(index, this.comparativeListData[item][index].cgst, this.price[index].final, item);
      this.extraChargesSGST(index, this.comparativeListData[item][index].sgst, this.price[index].final, item);
      this.extraChargesIGST(index, this.comparativeListData[item][index].igst, this.price[index].final, item);

      this.getAllTotalAmount(index, this.price[index].final, item);
    } else {
      this.packaging_forwarding = parseFloat(((basePrice * this.comparativeListing[item]['data'][index].quantity) * percent / 100).toFixed(3));
      this.price[index].packaging_forwarding = this.packaging_forwarding;
      this.extraChargesCGST(index, this.comparativeListData[item][index].cgst, basePrice, item);
      this.extraChargesSGST(index, this.comparativeListData[item][index].sgst, basePrice, item);
      this.extraChargesIGST(index, this.comparativeListData[item][index].igst, basePrice, item);

      this.getAllTotalAmount(index, basePrice, item);
    }
  }
  
  extraChargesFreight(index, percent, basePrice, item) {
    if (percent == '') {
      this.freight_up_to_site = 0;
    } else {
      this.freight_up_to_site = parseFloat(percent);
    }
    this.price[index].freight_up_to_site = this.freight_up_to_site;

    if (this.price[index].final) {
      this.extraChargesCGST(index, this.comparativeListData[item][index].cgst, this.price[index].final, item);
      this.extraChargesSGST(index, this.comparativeListData[item][index].sgst, this.price[index].final, item);
      this.extraChargesIGST(index, this.comparativeListData[item][index].igst, this.price[index].final, item);

      this.getAllTotalAmount(index, this.price[index].final, item);
    } else {
      this.extraChargesCGST(index, this.comparativeListData[item][index].cgst, basePrice, item);
      this.extraChargesSGST(index, this.comparativeListData[item][index].sgst, basePrice, item);
      this.extraChargesIGST(index, this.comparativeListData[item][index].igst, basePrice, item);

      this.getAllTotalAmount(index, basePrice, item);
    }
  }

  extraChargesCGST(index, percent, basePrice, item) {
    if (this.price[index].final) {
      this.cgst = parseFloat((((this.price[index].final * this.comparativeListing[item]['data'][index].quantity)+this.price[index].freight_up_to_site+this.price[index].packaging_forwarding) * percent / 100).toFixed(3));
    } else {
      this.cgst = parseFloat((((basePrice * this.comparativeListing[item]['data'][index].quantity)+this.price[index].freight_up_to_site+this.price[index].packaging_forwarding) * percent / 100).toFixed(3));
    }

    this.price[index].cgst = this.cgst;
    this.getAllTotalAmount(index, basePrice, item);
  }

  extraChargesSGST(index, percent, basePrice, item?) {
    if (this.price[index].final) {
      this.sgst = parseFloat((((this.price[index].final * this.comparativeListing[item]['data'][index].quantity)+this.price[index].freight_up_to_site+this.price[index].packaging_forwarding) * percent / 100).toFixed(3));
    } else {
      this.sgst = parseFloat((((basePrice * this.comparativeListing[item]['data'][index].quantity)+this.price[index].freight_up_to_site+this.price[index].packaging_forwarding) * percent / 100).toFixed(3));
    }

    this.price[index].sgst = this.sgst;
    this.getAllTotalAmount(index, basePrice, item);
  }

  extraChargesIGST(index, percent, basePrice, item?) {
    if (this.price[index].final) {
      this.igst = parseFloat((((this.price[index].final * this.comparativeListing[item]['data'][index].quantity)+this.price[index].freight_up_to_site+this.price[index].packaging_forwarding) * percent / 100).toFixed(3));
    } else {
      this.igst = parseFloat((((basePrice * this.comparativeListing[item]['data'][index].quantity)+this.price[index].freight_up_to_site+this.price[index].packaging_forwarding) * percent / 100).toFixed(3));
    }

    this.price[index].igst = this.igst;
    this.getAllTotalAmount(index, basePrice, item);
  }

  getAllTotalAmount(index: number, basePrice: number, item?) {
    if (this.price[index].packaging_forwarding != undefined) {
      this.price[index].packaging_forwarding = this.price[index].packaging_forwarding;
    } else {
      this.price[index].packaging_forwarding = 0;
    }

    if (this.price[index].freight_up_to_site != undefined) {
      this.price[index].freight_up_to_site = this.price[index].freight_up_to_site;
    } else {
      this.price[index].freight_up_to_site = 0;
    }

    if (this.price[index].cgst != undefined) {
      this.price[index].cgst = this.price[index].cgst;
    } else {
      this.price[index].cgst = 0;
    }

    if (this.price[index].sgst != undefined) {
      this.price[index].sgst = this.price[index].sgst;
    } else {
      this.price[index].sgst = 0;
    }

    if (this.price[index].igst != undefined) {
      this.price[index].igst = this.price[index].igst;
    } else {
      this.price[index].igst = 0;
    }

    if (this.comparativeListing[item]['data'][index].base_price === '') {
      this.comparativeListing[item]['data'][index].base_price = 0;

    } else {
      this.comparativeListing[item]['data'][index].base_price = this.comparativeListing[item]['data'][index].base_price;
    }

    if (this.price[index].final) {
      this.price[index].netAmount = (this.price[index].final * this.comparativeListing[item]['data'][index].quantity) + this.price[index].packaging_forwarding + this.price[index].freight_up_to_site + this.price[index].cgst +
        this.price[index].sgst + this.price[index].igst;
    } else {
      this.price[index].netAmount = (basePrice * this.comparativeListing[item]['data'][index].quantity) + this.price[index].packaging_forwarding + this.price[index].freight_up_to_site + this.price[index].cgst +
        this.price[index].sgst + this.price[index].igst;
    }
    this.price[index].netAmount = parseFloat(this.price[index].netAmount).toFixed(3);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  saveComparitive(item) {

    var forkArray = [];
    var tempArray = [];
    tempArray = this.comparativeListing[item]['data'];
    tempArray.forEach((x, index) => {

      if (this.price[index].final) {
        this.price[index].final = this.price[index].final;

      } else {
        this.price[index].final = this.comparativeListData[item][index].totalAmount;
      }
      let data = {
        requisitions_master: this.requisitionId,
        vendor: x.vendor_id,
        uom: this.comparativeListData[item][index].uom,
        item: x.item,
        discount: this.comparativeListData[item][index].discount,
        final_price: (this.price[index].final ? (this.price[index].final * x.quantity) : (x.price * x.quantity)).toFixed(3),
        price_basis: (this.price[index].final ? (this.price[index].final * x.quantity) : (x.price * x.quantity)).toFixed(3),
        make: this.comparativeListData[item][index].make,
        base_price: (this.price[index].final ? (this.price[index].final * x.quantity) : (x.price * x.quantity)).toFixed(3),
        packaging_and_forwarding: this.comparativeListData[item][index].packaging_and_forwarding,
        freight_up_to_site: parseInt(this.comparativeListData[item][index].freight_up_to_site),
        cgst: this.comparativeListData[item][index].cgst,
        sgst: this.comparativeListData[item][index].sgst,
        igst: this.comparativeListData[item][index].igst,
        payment_terms: x.payment_terms,
        delivery_time: this.deliveryTime[index],
        total_order_value: this.price[index].netAmount ? this.price[index].netAmount : (x.price * x.quantity),
        net_landed_cost: this.price[index].netAmount ? this.price[index].netAmount : (x.price * x.quantity),
        inco_terms: this.comparativeListData[item][index].inco_terms,
        warranty_guarantee: this.comparativeListData[item][index].warranty_guarantee,
        remarks: this.remarks[item],
        quotation_group: item
      }
      if (this.comparativeListData[item][index].cId == null) {
        forkArray.push(this.executionPurchaseService.addComparativeItem(data));
      } else {
        forkArray.push(this.executionPurchaseService.updateComparativeItem(this.comparativeListData[item][index].cId, data));
      }
    });

    forkJoin(forkArray).subscribe(
      resp => {
        this.loadQuotationData();
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been saved', '', {
          timeOut: 3000,
        });
        this.editEnabled[item] = true;
        this.dialogRef.close(true)
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.dialogRef.close(true)
      }
    );
  }

  getVendorForApproval(e) {
    this.vendorForApproval = e;
    if (this.vendorForApproval == undefined) {
      this.validationError = true;
    } else {
      this.validationError = false;
    }
  }


  

  editEnabledEvent() {
    this.editEnabled = !this.editEnabled;
  }

}
