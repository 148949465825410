import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddContactModalComponent } from '../add-contact-modal/add-contact-modal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-resource-contact-inner-tab-col',
  templateUrl: './resource-contact-inner-tab-col.component.html',
  styleUrls: ['./resource-contact-inner-tab-col.component.scss']
})
export class ResourceContactInnerTabColComponent implements OnInit {

  @Input() tenderId: number;
  @Input() data: any;
  loading: LoadingState = LoadingState.NotReady;
  designationId: number;
  tenderContactList: any = [];
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService
  ) { }

  ngOnInit() {
    this.designationId = this.data['id']
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    var params = '';
    params = '?tender=' + this.tenderId + '&designation=' + this.designationId
    forkArray.push(this.tenderService.getTenderResourceContactList(params))
    forkJoin(forkArray).subscribe(
      (res: any[]) => {
        //console.log(res)
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.tenderContactList = res[i]['result'];
          }
        }
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }



  openContactModal() {
    let dialogRef = this.dialog.open(AddContactModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, designationId: this.designationId }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if (result['data'] != undefined) {
        this.tenderContactList.push(result['data'])
      }
    })
  }

  editContact(i) {
    let contactDetailsData = Object.assign({}, this.tenderContactList[i]);
    let dialogRef = this.dialog.open(AddContactModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, designationId: this.designationId, contactDetails: contactDetailsData }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if (result['data'] != undefined) {
        this.tenderContactList[i] = result['data']
      }
    })
  }

  delete(i) {
    this.loading = LoadingState.Processing
    this.tenderService.deleteTenderResourceContact(this.tenderContactList[i]['id']).subscribe(
      res => {
       // console.log(res)
        this.tenderContactList.splice(i, 1)
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }

}
