import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-utilities-fuel-inner-tab',
  templateUrl: './utilities-fuel-inner-tab.component.html',
  styleUrls: ['./utilities-fuel-inner-tab.component.scss']
})
export class UtilitiesFuelInnerTabComponent implements OnInit {
 
  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  fuelDocumentList: any=[];
  fuelDetails: any =[];
  @Input() isEdit: boolean;
  @Input() isFieldError: boolean;
  approveStatus:any = [];

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private perExecutionService:PerExecutionService,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    //console.log(this.projectId);

    this.form = this.formBuilder.group({
      project:[this.projectId],
      available:[null,Validators.required],
      type_of_fuel:[null,Validators.required],
      volume_required:[null,Validators.required],
      supplier_name:[null,Validators.required],
      contact_no:[null,Validators.required],
      supplier_address:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
       // this.createDocument()
      ])
    })

    if(this.projectId){
      this.getFuelDetails();
      this.getStatus();

    }
   
   //this.getFuelDocument();
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      fuel_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }

  save()
  {
    
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var fuel_documents_data = [];
      fuel_documents_data = this.form.value.documents;
      //console.log(this.form.value);

      const data = {
        project: this.projectId,
        available: this.form.value.available,
        supplier_name:this.form.value.supplier_name,
        contact_no: this.form.value.contact_no,
        supplier_address: this.form.value.supplier_address,
        type_of_fuel: this.form.value.type_of_fuel,
        volume_required: this.form.value.volume_required,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }
      //console.log(data);

      this.fuelDocumentList = [];
      this.perExecutionService.addFuelUtilitiesAminites(data).subscribe(
        res => {
         // console.log(res);
          var forkArray = [];

          fuel_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.fuel_document, x.fuel_document['name']);
              forkArray.push(this.perExecutionService.addFuelDocumentUtilitiesAminites(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.fuelDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )


          this.loading = LoadingState.Ready;
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
        })
    }else
    {
      this.markFormGroupTouched(this.form);
    }
  }

  getFuelDetails(){
    
    this.perExecutionService.getFuelUtilitiesAminites(this.projectId).subscribe(
      res =>{
        if(res['result'][0]){
          this.fuelDetails = res['result'][0];
         // console.log(this.fuelDetails);
          this.fuelDocumentList = res['result'][0].document_details;

          var start_date = new Date(this.fuelDetails['requirment_s_date'])
            this.fuelDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.fuelDetails['requirment_e_date'])
            this.fuelDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          this.form.patchValue({
            latitude: this.fuelDetails.latitude,
            longitude: this.fuelDetails.longitude
          })
          this.fuelDetails.budgeted_cost = parseFloat(this.fuelDetails.budgeted_cost).toFixed(2);
          this.fuelDetails.executed_cost = parseFloat(this.fuelDetails.executed_cost).toFixed(2);
          
        }
        this.loading = LoadingState.Ready;
      },
      error=>{
       // console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
       // console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }

  getFuelDocument(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getFuelDocumentUtilitiesAminites(this.projectId).subscribe(
      res => {
        
        this.fuelDocumentList = res['result'];
       // console.log(this.fuelDocumentList);
        this.loading = LoadingState.Ready;
      },
      error=>{
      //  console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.fuelDocumentList[index]['isEdit'] = true
    this.fuelDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.fuelDocumentList[index]['document_name'] == '') {
      this.fuelDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.fuelDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.fuelDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editFuelDocumentUtilitiesAminites(this.fuelDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.fuelDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    
    this.loading = LoadingState.Processing
    this.perExecutionService.deleteFuelDocumentUtilitiesAminites(this.fuelDocumentList[index]['id']).subscribe(
      res => {
        this.fuelDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready;


      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready;
      }
    )
  }

  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  handleAddressChange(address) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      supplier_address: formattedAddress
    })
  }

  onSelectFile(event, index){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          fuel_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  update(){
    if(this.form.valid){
     // console.log(this.fuelDetails);
      var fuel_documents_data = [];
      fuel_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        fuel_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project", this.projectId);
            formData.append("module_id", this.fuelDetails.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.fuel_document, x.fuel_document['name']);
            forkArray.push(this.perExecutionService.addFuelDocumentUtilitiesAminites(formData))

            
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.fuelDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateFuel();
      }

      else{
        this.updateFuel();
      }


    }
    else
    {
      this.markFormGroupTouched(this.form);
    }
  }

  updateFuel(){
    this.loading = LoadingState.Processing;
    const updateData = {
      project: this.projectId,
      available: this.form.value.available,
      supplier_name:this.form.value.supplier_name,
      contact_no: this.form.value.contact_no,
      supplier_address: this.form.value.supplier_address,
      type_of_fuel: this.form.value.type_of_fuel,
      volume_required: this.form.value.volume_required,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude,
      requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
      requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
      budgeted_cost: this.form.value.budgeted_cost,
      executed_cost: this.form.value.executed_cost,
    }

    this.perExecutionService.editFuelUtilitiesAminites(this.fuelDetails.id,updateData).subscribe(
      res =>{
       // console.log(res)
      //  this.fuelDetails = res;
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        
      },
      error =>{
       
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        //console.log(error)
      }
    )
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


}
