import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddPAndMModalComponent } from '../add-p-and-m-modal/add-p-and-m-modal.component';
import { forkJoin } from 'rxjs';
import { SettingService } from '../../../../core/services/setting.service';

@Component({
  selector: 'app-resource-contractors-vendors-inner-p-and-m-tab',
  templateUrl: './resource-contractors-vendors-inner-p-and-m-tab.component.html',
  styleUrls: ['./resource-contractors-vendors-inner-p-and-m-tab.component.scss']
})
export class ResourceContractorsVendorsInnerPAndMTabComponent implements OnInit {
  @Input() tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  tenderPMList: any = [];
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService,
    private settingService: SettingService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    var params = '';
    //params = '?tender=' + this.tenderId
    forkArray.push(this.settingService.getMachineryTypeListByTender(this.tenderId))
    forkJoin(forkArray).subscribe(
      (res: any[]) => {
      //  console.log(res)
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.tenderPMList = res[i]['result'];
           // console.log(this.tenderPMList)
          }
        }
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }



  openPMModal() {
    let dialogRef = this.dialog.open(AddPAndMModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result['data'] != undefined) {
        this.tenderPMList.push(result['data'])
      }
    })
  }

  editPM(i) {
    
    let PMDetailsData = Object.assign({}, this.tenderPMList[i]);
    let dialogRef = this.dialog.open(AddPAndMModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, PMDetails: PMDetailsData, checkReadonly: true }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderPMList[i] = result['data']
      }
    })
  }

}
