import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { SettingService } from '../../../../core/services/setting.service';
import { BillInvoiceService } from '../../../../core/services/bill-invoice.service';
import { LocalService } from '../../../../core/services/local.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-bill',
  templateUrl: './edit-bill.component.html',
  styleUrls: ['./edit-bill.component.scss']
})
export class EditBillComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  selectedDocument: boolean;
  projectList: any = [];
  fileDetails: any;
  constructor(
    public dialogRef: MatDialogRef<EditBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private billInvoiceService: BillInvoiceService,
    private toastr: ToastrService,
    private localService: LocalService,
  ) {
    console.log(data)
    this.projectList = data['projectList']
    this.fileDetails = data['details']
    this.fileDetails['remarks'] = ''
    if(this.fileDetails.remarks_details.length > 0){
      var index = this.fileDetails.remarks_details.findIndex(x => x.created_by == +this.localService.getJsonValue('userid'))
      if(index != -1){
        this.fileDetails['remarks'] = this.fileDetails.remarks_details[index]['remarks']
      }
    }
    console.log(this.fileDetails)
    
   }

  ngOnInit() {

    this.form = this.formBuilder.group({
      project: [{value:null,disabled: true}, Validators.required],
      category: [null],
      document_name: ['', Validators.required],
      document: [null],
      file: null,
      remarks: null,

    });
    this.loading = LoadingState.Ready
  }

  selectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {

        }
        this.form.patchValue({
          document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
       
        this.selectedDocument = true;
      }


    }
  }

  viewDoc(documentData){
    window.open(documentData,'Bill','height=700,width=800,scrollbars=yes');
  }

  save() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      const data: any = new FormData();      
      data.append("project ", this.fileDetails.project.id);
      data.append("category ", this.fileDetails.category.id);
      data.append("document_name ", this.fileDetails.document_name);
      if(this.form.value.document != null){
        data.append("document ", this.form.value.document);
      }    
      
      data.append("remarks ", this.fileDetails.remarks);
      this.billInvoiceService.updateBillInvoiceFile(this.fileDetails.id,data).subscribe(
        res => {
          this.loading = LoadingState.Ready
          this.toastr.success('File has been updated', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(true)
        },
        error => {
          // console.log(error);
          this.dialogRef.close(false)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
