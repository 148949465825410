import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-labour-toilet-inner-tab',
  templateUrl: './labour-toilet-inner-tab.component.html',
  styleUrls: ['./labour-toilet-inner-tab.component.scss']
})
export class LabourToiletInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  toiletDetail: any = [];
  toiletDocumentList :any = []
  listEdit:boolean;
  isToilet:boolean;
  structure_name: string;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private perExecutionService:PerExecutionService,
    public settingService:SettingService
  ) {
    this.isToilet=true;
    this.structure_name='yes';
   }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      available:[''],
      toi_available:[null,Validators.required],
      existing_arrangement:[null,Validators.required],
      details:[null,Validators.required],
      rate:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
    })
    
    if(this.projectId){
      this.getToiletDetails();
    }
  }

  save(){
    if(this.form.valid){
      var toilet_documents_data = [];
      toilet_documents_data = this.form.value.documents;
     // console.log(toilet_documents_data);

      this.loading = LoadingState.Processing

      const data = {
        project: this.projectId,
        toi_available:this.form.value.toi_available,
        existing_arrangement: this.form.value.existing_arrangement,
        details: this.form.value.details,
        rate: this.form.value.rate,
        
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }
      this.toiletDocumentList = [];
      this.perExecutionService.addLabourToiletOffice(data).subscribe(
        res => {
          var forkArray = [];

          toilet_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.toilet_document, x.toilet_document['name']);
              forkArray.push(this.perExecutionService.addLabourToiletDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.toiletDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error=>{
          //console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
     // console.log(data);
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

  getToiletDetails(){
    this.perExecutionService.getLabourToiletOffice(this.projectId).subscribe(
      res=>{
        if(res['result'][0]){
          this.toiletDetail = res['result'][0];
         // console.log(this.toiletDetail);
          this.toiletDocumentList = res['result'][0].document_details;
          var start_date = new Date(this.toiletDetail['requirment_s_date'])
            this.toiletDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.toiletDetail['requirment_e_date'])
            this.toiletDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
        
          this.toiletDetail.budgeted_cost = parseFloat(this.toiletDetail.budgeted_cost).toFixed(2);
          this.toiletDetail.executed_cost = parseFloat(this.toiletDetail.executed_cost).toFixed(2);
          if(this.toiletDetail.rate!=null){
            this.toiletDetail.rate = parseFloat(this.toiletDetail.rate).toFixed(2);
          }
          this.structure_name = this.toiletDetail.toi_available==true?'yes':'no';
          this.isToilet = this.toiletDetail.toi_available;

          this.form.patchValue({
            toi_available: this.toiletDetail.toi_available
                })
        }
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }


  update(){
    if(this.form.valid){
     // console.log(this.toiletDetail);
      var toilet_documents_data = [];
      toilet_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        toilet_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project", this.projectId);
            formData.append("module_id", this.toiletDetail.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.toilet_document, x.toilet_document['name']);
            forkArray.push(this.perExecutionService.addLabourToiletDocumentOffice(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.toiletDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateToilet();
      }
      else{
        this.updateToilet();
      }
    }
    else{
      this.markFormGroupTouched(this.form);
     // console.log(this.form.value);
    }
  }

  updateToilet(){
    if(this.form.valid){
      this.loading = LoadingState.Processing

      const updateData = {
        project: this.projectId,
        toi_available:this.form.value.toi_available,
        existing_arrangement: this.form.value.existing_arrangement,
        details: this.form.value.details,
        rate: this.form.value.rate,
        
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }

      this.perExecutionService.editLabourToiletOffice(this.toiletDetail.id,updateData).subscribe(
        res =>{
          //console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error =>{
         this.loading = LoadingState.Ready;
          //console.log(error)
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )

    }
  }


  // document section

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.toiletDocumentList[index]['isEdit'] = true
    this.toiletDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.toiletDocumentList[index]['document_name'] == '') {
      this.toiletDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.toiletDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.toiletDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editLabourToiletDocumentOffice(this.toiletDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.toiletDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteLabourToiletDocumentOffice(this.toiletDocumentList[index]['id']).subscribe(
      res => {
        this.toiletDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
	 })
    
  }

  // end of Document Section
  

  handleChange(e: any) {
    if (e.target.value == "yes") {
    this.isToilet= true;
    this.form.patchValue({
      rate: null
          })
    }
    else{
    this.isToilet= false;
    }
    this.form.patchValue({
    toi_available: this.isToilet
        })
  }
  createDocument(){
    return this.formBuilder.group({
      document_name: [null, Validators.required],
      document: [null, Validators.required],
      toilet_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }
  onSelectFile(event, index){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          toilet_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


}
