import { Component, OnInit,Output, EventEmitter,Input } from '@angular/core';
import {projectSiteServices} from '../../services/project-sites.service';

@Component({
  selector: 'app-project-site-filter',
  templateUrl: './project-site-filter.component.html',
  styleUrls: ['./project-site-filter.component.scss']
})
export class ProjectSiteFilterComponent implements OnInit {
  projectSiteList:any = [];
  @Input() project:any = [];
  @Output() getSelectedProjects: EventEmitter<number> =  new EventEmitter<number>();
  

  constructor(
    private projectSiteServices:projectSiteServices
  ) { }

  ngOnInit() {
    this.getProjectSiteLists();
  }

  getProjectSiteLists() {
    
    this.projectSiteServices.getProjectSitesList().subscribe(
      res => {
        this.projectSiteList = res['result'];
        
      },
      error => {
        //console.log(error); 
      }
    )
  }

  onSelectionChange() {
    this.getSelectedProjects.emit(this.project);
  }

}
