'use strict';

export const itemPerPage: number = 10;
export const paginationMaxSize: number = 5; 
//export const baseUrl: string = 'https://shyamsteel.tech/';
// export const baseUrl: string = 'http://3.109.115.146/dev/';
export const baseUrl: string = 'https://sftnirman.com/';
// export const baseUrl: string = 'http://demo.shyamfuture.com/';

export const pageSize: number = 10; 
export const itemPerPageNumList:any = [10,20,30,50,100];
export const worksheetDaysLimit: any = 3;
export const demo: boolean = false;
// AIzaSyAkkz_K6d5CBMaY6qOBVSCuybYnP_AkagU

// AIzaSyAjpzMOmHqopKNlStBXcqF6uUn4hl-VjxA
//All Report Designationgit