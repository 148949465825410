import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {BatchingPlantService} from '../../../services/batching-plant.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-daily-batch-report-preview-modal',
  templateUrl: './daily-batch-report-preview-modal.component.html',
  styleUrls: ['./daily-batch-report-preview-modal.component.scss']
})
export class DailyBatchReportPreviewModalComponent implements OnInit {
  item = {
    sand: 0,
    agg_10mm: 0,
    agg_20mm: 0,
    cement: 0,
    fly_ash: 0,
    water: 0,
    admixture: 0
  }
  constructor(
    public dialogRef: MatDialogRef<DailyBatchReportPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public batchingPlantService:BatchingPlantService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.getTotal()
  }

  getTotal(){
    if(this.data['entry_details'].length > 0){
      this.data['entry_details'].forEach(x => {
          this.item['sand'] += parseFloat(x.sand);
          this.item['agg_10mm'] += parseFloat(x.agg_10mm);
          this.item['agg_20mm'] += parseFloat(x.agg_20mm);
          this.item['cement'] += parseFloat(x.cement);
          this.item['fly_ash'] += parseFloat(x.fly_ash);
          this.item['water'] += parseFloat(x.water);
          this.item['admixture'] += parseFloat(x.admixture);

      });
    }
  }
  close(key:boolean){
    this.dialogRef.close(key);
  }

  approval(key:string){
    var data = {
      status_code : key
    }
    this.batchingPlantService.updateStausBatchReportDetails(this.data['id'],data).subscribe(
      res=>{
        this.toastr.success('status has been added', '', {
            timeOut: 3000,
        });
        this.close(true);
      },error=>{
        this.toastr.error('Internal server error', '', {
            timeOut: 3000,
        });
      }
    )
  }
}
