import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-office-vehicle-inner-tab',
  templateUrl: './office-vehicle-inner-tab.component.html',
  styleUrls: ['./office-vehicle-inner-tab.component.scss']
})
export class OfficeVehicleInnerTabComponent implements OnInit {
@Input() projectId:number;
@Input() approveStatus:any = [];

  constructor() { }

  ngOnInit() {
    //console.log(this.projectId);
  }

}
