import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { SettingService} from '../../../services/setting.service'

@Component({
  selector: 'app-add-payment-plan-model',
  templateUrl: './add-payment-plan-model.component.html',
  styleUrls: ['./add-payment-plan-model.component.scss']
})
export class AddPaymentPlanModelComponent implements OnInit {
  form: FormGroup;
  vendorDetails:any = [];
  paymentDetail:any = [];
  requisitionId:number;
  totalAmount:number = 0;
  due_amount = '';
  constructor(
    public dialogRef: MatDialogRef<AddPaymentPlanModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private executionPurchaseService:ExecutionPurchaseService,
    private toastr: ToastrService,
    public settingService:SettingService
  ) { 

    this.vendorDetails = this.data.vendor;
    this.paymentDetail = this.data.paymentDetail;
    this.requisitionId = this.data.requisitionId;
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      requisitions_master:this.requisitionId,
      due_amount:[null,Validators.required],
      due_date:[null,Validators.required],
      vendor:[null,Validators.required]
    })

  }

  close() {
    this.dialogRef.close(true);
  }
  getPaymentAmount(e){
    this.totalAmount = 0;
    this.paymentDetail.forEach((x,index) => {
      if(x.vendor == e){
        this.totalAmount += parseInt(x.price);
      }
    });

  }

  checkPaymentAmount(e){
    var val = parseInt(e);
    if(val > this.totalAmount){
      this.toastr.error('Total payable amount exceeded!', '', {
        timeOut: 3000,
      });
      this.due_amount = "";
    }

  }


  save(){
    if(this.form.valid){
      // console.log(this.form.value)
      var data = {
        requisitions_master:this.requisitionId,
        due_amount:this.form.value.due_amount,
        vendor:this.form.value.vendor,
        due_date:this.settingService.getProperDate(this.form.value.due_date).toISOString(),//aa
        
      }
      this.executionPurchaseService.addPaymentPlan(data).subscribe(
        res => {
         // console.log(res);
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
          this.form.reset();
          this.close();
        },
        error =>{
        //  console.log(error);
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
      // addPaymentPlan

    }else{
      this.markFormGroupTouched(this.form);
    }
  }



  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
