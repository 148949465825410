import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { StockService } from '../../../../services/stock.service';
import { SettingService } from '../../../../services/setting.service';
import { LoadingState } from '../../../../components/loading/loading.component';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { Moment } from 'moment';
import * as Globals from '../../../../globals';
import { ToastrService } from 'ngx-toastr';
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeComponent,
  OwlDateTimeFormats
} from 'ng-pick-datetime';
// import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { Router, ActivatedRoute } from '@angular/router';
import { AppPageStateConfig } from '../../../../../app.config';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'l LT',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-monthly-stock-report',
  templateUrl: './monthly-stock-report.component.html',
  styleUrls: ['./monthly-stock-report.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS },
  ]
})
export class MonthlyStockReportComponent implements OnInit {

  @Input() siteLocation: number;
  @Input() RefprojectId: number;
  @Output() siteIdEmit: EventEmitter<number> = new EventEmitter<number>();
  @Output() projectIdEmit: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  hasScroll: boolean = false
  scrollAmount;
  public loading: LoadingState = LoadingState.NotReady;
  dateTime: any = new Date();
  siteId: number = null;
  projectId: number = null;
  monthlyReport: any = [];
  monthString: any;
  dateList: any = [];
  itemList: any = [];
  month: string;
  year: string;

  defaultPagination: number;
  totalStockList: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  pageSize: number;
  search_key: any;
  stockReportConfig: any;

  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public minDate: Object = new Date(this.currentYear, this.currentMonth, 1);
  public maxDate: Object = new Date(this.currentYear, this.currentMonth + 1, 0);
  fromDate: string;
  toDate: string;
  dateRange: any;
  monthsList: any = [];

  constructor(
    public projectSiteServices: projectSiteServices,
    public stockService: StockService,
    private settingService: SettingService,
    private toastr: ToastrService,
    private element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.stockReportConfig = AppPageStateConfig.stockReportConfig.monthlyStockConfig;
  }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    this.year = this.dateTime.getFullYear()
    this.month = this.dateTime.getMonth() + 1;
    if (this.route.snapshot.params['projectId'] == undefined) {
      if (this.stockReportConfig.search_key != undefined) {
        this.search_key = this.stockReportConfig.search_key;
      }
      if (this.stockReportConfig.defaultPagination != null) {
        this.defaultPagination = this.stockReportConfig.defaultPagination;
      }
      if (this.stockReportConfig.itemPerPage != null) {
        this.itemPerPage = this.stockReportConfig.itemPerPage;
      }
      if (this.stockReportConfig.pageSize != null) {
        this.pageSize = this.stockReportConfig.pageSize;
      }

      if (this.stockReportConfig.year != null) {
        this.year = this.stockReportConfig.year;
      }

      if (this.stockReportConfig.month != null) {
        this.month = this.stockReportConfig.month;
      }

      if (this.stockReportConfig.maxDate != null) {
        this.maxDate = this.stockReportConfig.maxDate;
      }

      if (this.stockReportConfig.minDate != null) {
        this.minDate = this.stockReportConfig.minDate;
      }

    }
    
    this.loading = LoadingState.Processing;

    if ((this.siteLocation) && (this.RefprojectId)) {
      this.siteId = this.siteLocation;
      this.projectId = this.RefprojectId;
      this.getMonths(this.projectId);
    } else
      this.loading = LoadingState.Ready;
  }

  checkScroll() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    if (this.scrollAmount && (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth || this.scrollAmount.scrollWidth == this.scrollAmount.offsetWidth)) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }
  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  getMonths(projectId) {
    this.dateTimeReset();
    this.stockService.getMonthListofProject(this.projectId).subscribe(
      res => {
        var data = res['result'];
        data.forEach(element => {
          this.monthsList.push({ name: element, value: element })
        });
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.monthString = months[parseInt(this.month) - 1] + '-' + this.year;
        if (this.route.snapshot.params['projectId'] == undefined) {
          if (this.stockReportConfig.monthString != null) {
            this.monthString = this.stockReportConfig.monthString;
          }
        }
      },
      error => {
        console.log(error)
      }
    );
    this.getMonthlyReport()
  }

  selectDate(e) {
    this.dateTimeReset();
    var m = e.split('-');
    var mon = new Date(Date.parse(m[0] + " 1, 2012")).getMonth() + 1
    if (mon < 10)
      this.month = '0' + mon;
    else
      this.month = mon.toString();
    this.year = m[1];

    this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
    this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.monthString = this.monthString;
      this.stockReportConfig.month = this.month;
      this.stockReportConfig.year = this.year;
      this.stockReportConfig.minDate = this.minDate;
      this.stockReportConfig.maxDate = this.maxDate;
    }
    this.getMonthlyReport()
  }



  dateTimeReset() {
    this.dateRange = null;
    this.fromDate = null;
    this.toDate = null;
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.fromDate = new Date(event.value[0]).getDate().toString();
      this.toDate = new Date(event.value[1]).getDate().toString();
      this.getMonthlyReport()
    }
  }

  getMonthlyReport() {
    this.loading = LoadingState.Processing;
    let params: URLSearchParams = new URLSearchParams();
    params.set('page', this.defaultPagination.toString());
    if (this.itemPerPage)
      params.set('page_size', this.itemPerPage.toString());
    if (this.search_key != undefined)
      params.set('product_name', this.search_key)
    params.set('year', this.year);
    params.set('month', this.month);
    if (this.fromDate != undefined)
      params.set('from_date', this.fromDate)
    if (this.toDate != undefined)
      params.set('to_date', this.toDate)
    this.stockService.getMonthlyStockReportData(this.projectId, this.siteId, params).subscribe(
      res => {
        var data = res['results'];
        this.dateList = [];
        if (data.length > 0) {
          data[0].month_stock_list_details.forEach(e => {
            this.dateList.push(e.date)
          });
        }
        this.itemList = data;
        this.totalStockList = res['count'];
        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalStockList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
        }
        this.checkScroll();
        this.loading = LoadingState.Ready;
      },
      error => {
        if (error.status == 404 && this.stockReportConfig.defaultPagination > 1) {
          this.stockReportConfig.defaultPagination = 1;
          this.ngOnInit();
        }
        else {
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      }
    );
  }

  pagination() {
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.defaultPagination = this.defaultPagination;
    }
    this.getMonthlyReport();
  }

  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.defaultPagination = this.defaultPagination;
      this.stockReportConfig.itemPerPage = +item;
    }
    this.getMonthlyReport();
  }

  getSearchValueFromParent(siteId, projectId) {
    this.loading = LoadingState.Processing;
    this.siteId = siteId;
    this.projectId = projectId;
    this.getMonths(this.projectId);
  }

  exportReport() {
    // hit api for excel
    if (this.itemList.length > 0) {
      this.loading = LoadingState.Processing;
      let params: URLSearchParams = new URLSearchParams();
      if (this.search_key != undefined)
        params.set('product_name', this.search_key)
      params.set('year', this.year);
      params.set('month', this.month);
      if (this.fromDate != undefined)
        params.set('from_date', this.fromDate)
      if (this.toDate != undefined)
        params.set('to_date', this.toDate)
      this.stockService.getMonthlyStockExcel(this.projectId, this.siteId, params).subscribe(
        res => {
          // console.log(res)
          window.open(res.url)
          this.loading = LoadingState.Ready;
        },
        error => {
          // console.log(error)
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    } else {
      this.toastr.error('No data available to export', '', {
        timeOut: 3000,
      });
    }
  }
}
