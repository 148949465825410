import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddDesignationModalComponent } from '../add-designation-modal/add-designation-modal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-resource-contact-inner-tab',
  templateUrl: './resource-contact-inner-tab.component.html',
  styleUrls: ['./resource-contact-inner-tab.component.scss']
})
export class ResourceContactInnerTabComponent implements OnInit {

  @Input() tenderId: number;
  @Input() tenderDesignationList: any = [];
  loading: LoadingState = LoadingState.NotReady;
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService
  ) { }

  ngOnInit() {
    this.loading = LoadingState.Ready
  }

}
