import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class reportingService {

  constructor(private http: HttpClient) {
  }

  saveProgressData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/v2/execution_daily_progress/add/', str);
  }

  saveLabourData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/v2/execution_daily_progress/labour/add/', str);
  }

  savePMData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/v2/execution_daily_progress/pandm/add/', str);
  }

  saveWeeklyProgressData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/execution/weekly/progress/add/', str);
  }

  saveWeeklyLabourData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/execution/weekly/progress/labour/add/', str);
  }

  saveWeeklyMaterialData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/execution/weekly/progress/material/add/', str);
  }

  saveWeeklyMachinaryData(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/execution/weekly/progress/pandm/add/', str);
  }

  getProgressReportData(pid, sid, str): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/v2/execution_daily/progres/list/' + pid + '/' + sid + '/?' + str);
  }

  getLabourReportData(pid, sid, str): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/v2/execution_daily/labour/list/' + pid + '/' + sid + '/?' + str);
  }

  getMaterialReportData(pid, sid, str): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution_daily_progress/material/list/' + pid + '/' + sid + '/?' + str);
  }

  getPandMReportData(pid, sid, str): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/v2/execution_daily/pandm/list/' + pid + '/' + sid + '/?' + str);
  }

  getProjectPlan(pid, sid): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms_execution_project_planning_view/' + pid + '/' + sid + '/');
  }

  setProjectPlan(str): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms_execution_project_planning_add_or_update/', str);
  }

  getEmployeeListDataWP(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'v2/employee_list_without_details/?' + params);
  }
  // 15-06-2020
  fetchDailyProgressCommonData(pid, sid, str, report_type): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms_execution_daily_progress_common_data/' + pid + '/' + sid + '/?report_type='+report_type+'&' + str);
  }

  getEmployeeListByProjectDataWP(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'employee_list_by_site_project/' + id + '/');
  }

  saveMaterialData(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/execution_daily_progress/material/add/', data);
  }

  //22-06-2021

  addEmployeeAbsentData(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/execution_absent_employee_data_entry/add/', data);
  }

  getemployeeAbsentReportList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution_absent_employee_data_entry/list/?'+params);
  }

  addExpenseHead(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/expense_head/add/', data);
  }

  getExpenseHeadList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/expense_head/list/?'+ params);
  }

  editExpenseData(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/expense_head/edit/'+ id + '/', data);
  }

  getDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution_absent_employee_data_entry/edit/'+ id + '/');
  }

  editEmployeeAbsentData(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/execution_absent_employee_data_entry/edit/'+ id + '/', data);
  }

  getBalanceInHand(site, project, date): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_cash/opening_balance/details/?site_location=' + site + '&project=' + project + '&entry_date=' + date);
  }

  addCementRegister(params): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/cement_register/stock/add_or_update/', params);
  }

  getCementRegisterListing(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/cement_register/stock/report/?'+params);
  }

  getCementRegisterDetails(id): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/cement_register/stock/details/'+id+'/');
  }

  getCementRegisterExcelDownload(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/cement_register/stock/report/?'+ params);
  }

  addSiteCash(params: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/site_cash/balance/add_or_update/', params);
  }

  getSiteCashListing(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_cash/balance/report/?'+params);
  }

  getSiteCashDetails(itemId): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_cash/balance/details/'+itemId+'/');
  }

  updateSiteCash(params: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/site_cash/balance/add_or_update/', params);
  }

  getBalanceInHandCementRegister(project: number, site: number, cement_type: string): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/cement_register/opening_stock/details/?project='+project+'&site_location='+site+'&cement_type='+cement_type);
  }

  getEmployeeAbsentExcelDownload(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution_absent_employee_data_entry/list/?'+ params);
  }

  fetchWeeklyCommonData(project, site, start_date, end_date, report_type): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution_weekly_progress_common_data/?report_type='+ report_type + '&start_date=' + start_date + '&end_date=' + end_date + '&site_location=' + site + '&project_id=' + project);
  }

  getLabourWeeklyReportData(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution/weekly/labour/list/?' + params);
  }

  getPandMWeeklyReportData(str): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution/weekly/pandm/list/?' + str);
  }

  getProgressWeeklyReportData(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution/weekly/progress/list/?' + params);
  }

  getPlanningReportData(str): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/execution/weekly/planning/report/?' + str );
  }
}
