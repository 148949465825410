import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {
  constructor(
    private http: HttpClient
  ) {

  }

  getObjectList(id: number, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'other_list/' + id + '/?' + params)
  }

  deleteObject(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'other_delete/' + id + '/', {})
  }

  addObject(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'other_add/', data)
  }

  editObject(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'other_edit/' + id + '/', data)
  }

  getSubObjectList(module_id: number, parent_id: number, params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'other_list_by_parent/' + module_id + '/' + parent_id + '/?' + params)
  }

}
