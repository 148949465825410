import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-surevey-actual-inner-tab',
  templateUrl: './surevey-actual-inner-tab.component.html',
  styleUrls: ['./surevey-actual-inner-tab.component.scss']
})
export class SureveyActualInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  reqSurveyInstrumentList :any = [];
  reqSurveyDetail: any = [];
  reqSurveyInstrumentDetail:any = [];
  reqSurveyInstrumentDocumentList:any = [];
  listEdit:boolean;
  survey_instrument_type_tab:number=2;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      survey_instrument_type_tab:[this.survey_instrument_type_tab],
      instrument_types:this.formBuilder.group({
        type_of_instrument:[null,Validators.required],
        quantity:[null,Validators.required],
        total_cost:[null,Validators.required],
      }),
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
    })
    if(this.projectId){
      this.getRequiredSurevey();
      this.getInstrumentList();
    }
  }

  save(){
    if(this.form.valid){
      var inst_documents_data = [];
      inst_documents_data = this.form.value.documents;

      const data = {
          project: this.projectId,
          survey_instrument_type_tab:this.survey_instrument_type_tab,
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost,
          instrument_types:
          {
            project_id:this.projectId,
            type_of_instrument:this.form.value.instrument_types.type_of_instrument,
            quantity:this.form.value.instrument_types.quantity,
            total_cost:this.form.value.instrument_types.total_cost
          }
      }

      this.reqSurveyInstrumentDocumentList = [];
      this.perExecutionService.addReqSurveyOffice(data).subscribe(
        res => {
         // console.log(res);
          var forkArray = [];

          inst_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.instrument_types.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.survey_document, x.survey_document['name']);
              forkArray.push(this.perExecutionService.addReqSurveyInstrumentDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.reqSurveyInstrumentDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )

          this.getInstrumentList(); 
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error=>{
        //  console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        })

     // console.log(data);

    }else
    {
     // console.log("error");
      this.markFormGroupTouched(this.form);
    
    }
  }

  getRequiredSurevey(){
    this.perExecutionService.getReqSurveyOffice(this.projectId,this.survey_instrument_type_tab).subscribe(
      res => {
       if(res['result'][0]){
        this.reqSurveyDetail = res['result'][0];
       // console.log(this.reqSurveyDetail);

        var start_date = new Date(this.reqSurveyDetail['requirment_s_date'])
            this.reqSurveyDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.reqSurveyDetail['requirment_e_date'])
            this.reqSurveyDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          
          this.reqSurveyDetail.budgeted_cost = parseFloat(this.reqSurveyDetail.budgeted_cost).toFixed(2);
          this.reqSurveyDetail.executed_cost = parseFloat(this.reqSurveyDetail.executed_cost).toFixed(2);
       }
       
        this.loading = LoadingState.Ready;
      },
      error =>{
       // console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  getInstrumentList(){
    
    this.perExecutionService.getReqSurveyInstrumentListOffice(this.projectId,this.survey_instrument_type_tab).subscribe(
      res => {
        this.reqSurveyInstrumentList = res['result'];
        
        // console.log(this.reqSurveyInstrumentList);
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  editInstrument(data){
    this.reqSurveyInstrumentDetail = data;
   // console.log(this.reqSurveyInstrumentDetail);
    this.reqSurveyInstrumentDocumentList = this.reqSurveyInstrumentDetail.document_details;
    this.listEdit = true;
    this.getInstrumentList(); 
  }

  deleteInstrument(id:number){
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteReqSurveyInstrumentListOffice(id).subscribe(
        res => {
         this.getInstrumentList();
          // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })

   
  }

    
  update(){
      if(this.form.valid){

        this.loading = LoadingState.Processing;
        var inst_documents_data = [];
        inst_documents_data = this.form.value.documents;

        const updateData = {
          project:this.projectId,
          survey_instrument_type_tab:this.survey_instrument_type_tab,
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost,
          instrument_types:
          {             
            project_id:this.projectId,
            type_of_instrument:this.form.value.instrument_types.type_of_instrument,
            quantity:this.form.value.instrument_types.quantity,
            total_cost:this.form.value.instrument_types.total_cost
          }
        }
      //console.log(updateData);

    this.perExecutionService.editReqSurveyOffice(this.reqSurveyDetail.id,updateData).subscribe(
      res =>{
        // console.log(res);
        // console.log(res.tiffin_box_types.id)
       
        if(this.form.value.documents.length > 0){
          var forkArray = [];
          inst_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project",this.projectId);
            formData.append("module_id", res.instrument_types.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.survey_document, x.survey_document['name']);
            forkArray.push(this.perExecutionService.addReqSurveyInstrumentDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            result => {
              // console.log(result)
              result.forEach(y => {
                this.reqSurveyInstrumentDocumentList.push(y)
              })
             
              // this.add_tender_document();
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
         }
        this.getInstrumentList();
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been saved','',{
          timeOut: 3000,
          });
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      })
    }
    else{
      this.markFormGroupTouched(this.form);
     // console.log("error");
    }
  }

  updateInstrument()
  {
   
    var inst_documents_data = [];
    inst_documents_data = this.form.value.documents;

    if(this.form.valid){

      this.loading = LoadingState.Processing;
      const updateInstData = {
        project:this.projectId,
        survey_instrument:this.reqSurveyInstrumentDetail.survey_instrument,
        type_of_instrument:this.form.value.instrument_types.type_of_instrument,
        quantity:this.form.value.instrument_types.quantity,
        total_cost:this.form.value.instrument_types.total_cost,
        survey_instruments:{
          survey_instrument_type_tab:this.survey_instrument_type_tab,
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost
        }
      }
     // console.log(updateInstData);

      //this.tiffinBoxDocumentList = [];
      this.perExecutionService.editReqSurveyInstrumentListOffice(this.reqSurveyInstrumentDetail.id,updateInstData).subscribe(
        res =>{
        //  console.log(res);
          var forkArray = [];

          if(this.form.value.documents.length > 0){
            inst_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", this.reqSurveyInstrumentDetail.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.survey_document, x.survey_document['name']);
              forkArray.push(this.perExecutionService.addReqSurveyInstrumentDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.reqSurveyInstrumentDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
      }
      this.getInstrumentList(); 
      this.loading = LoadingState.Ready;
      this.toastr.success('Data has been saved','',{
        timeOut: 3000,
        });
       
        },
        error =>{
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
      

    }else{
     // console.log("error");
    }
  }


  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.reqSurveyInstrumentDocumentList[index]['isEdit'] = true
    this.reqSurveyInstrumentDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.reqSurveyInstrumentDocumentList[index]['document_name'] == '') {
      this.reqSurveyInstrumentDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.reqSurveyInstrumentDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.reqSurveyInstrumentDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editReqSurveyInstrumentDocumentOffice(this.reqSurveyInstrumentDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.reqSurveyInstrumentDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
    this.perExecutionService.deleteReqSurveyInstrumentDocumentOffice(this.reqSurveyInstrumentDocumentList[index]['id']).subscribe(
      res => {
        this.reqSurveyInstrumentDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
        this. getInstrumentList();
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
	 })
    
  }


  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      survey_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);

  }

  onSelectFile(event, index){
    console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          survey_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.markFormGroupTouched(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
   }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }


}
