import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingService } from '../../../../core/services/setting.service';
import { ExecutionPurchaseService } from '../../../../core/services/execution-purchase.service';
import { forkJoin } from 'rxjs';
import { LoadingState } from '../../loading/loading.component';
import { AddMaterialModalComponent } from '../add-material-modal/add-material-modal.component';

@Component({
  selector: 'app-raw-material-tab',
  templateUrl: './raw-material-tab.component.html',
  styleUrls: ['./raw-material-tab.component.scss']
})
export class RawMaterialTabComponent implements OnInit {

  @Input() tenderId: number;
  // @Input() tenderMaterialList: any = [];
  type: string;
  tenderMaterialList: any = [];
  loading: LoadingState = LoadingState.NotReady;
  typeId:number;
  constructor(
    public dialog: MatDialog,
    private settingService: SettingService,
    private executionPurchaseService: ExecutionPurchaseService
  ) { }

  ngOnInit() {
    this.type = "vendor";
    this.getMaterialType()
  }

  getMaterialType() {
    this.settingService.getMaterialType().subscribe(
      res => {
        var masterData = res['result'];
        this.typeId = masterData.filter(x => x.type_name == "Materials")[0].code;
       // console.log(this.typeId)
        if (this.typeId) {
          this.loadData()
        }
      }
    )
  }

  loadData() {
    var forkArray = [];
    let params: URLSearchParams = new URLSearchParams();
    var is_master = 1;
    var itemPerPage = 0;
    params.set('page_size', itemPerPage.toString());
    params.set('type_code', this.typeId.toString());
    params.set('is_master', is_master.toString());
    forkArray.push(this.executionPurchaseService.getMaterialList(params))
    forkJoin(forkArray).subscribe(
      ([materialData]) => {
        // console.log(result)
        this.tenderMaterialList = materialData['results'];
        //console.log(this.tenderMaterialList)
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }  

  openMaterialModal() {
    let dialogRef = this.dialog.open(AddMaterialModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, type: 'material' }
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log(result)
      if (result['data'] != undefined) {
        this.tenderMaterialList.push(result['data'])
      }
    })
  }

}
