import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss']
})
export class RemarksComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RemarksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  getLetter(author){
    var x = author.split(' ');
    if(x.length > 1){
      return x[0].charAt(0) + x[1].charAt(0)
    }
    else{
      return x[0].charAt(0)
    }
    
  }

}
