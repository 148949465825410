import { Injectable,Output,EventEmitter } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  httpHeaderOptions: any;
  currentMessage = new BehaviorSubject(null);
  @Output() getTourTravelNotificationStatus: EventEmitter<any> = new EventEmitter();
  @Output() getDailyExpenseNotificationStatus: EventEmitter<any> = new EventEmitter();
  @Output() getBillInvoiceNotificationStatus: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {

  }
  /**
   * Broadcast Notification
   * 
   */
  tourTravelNotificationStatus(data) {
    if (data == true) {
      this.getTourTravelNotificationStatus.emit(true);
      return
    } else {
      this.getTourTravelNotificationStatus.emit(false);
      return
    }
  }

  dailyExpenseNotificationStatus(data) {
    if (data == true) {
      this.getDailyExpenseNotificationStatus.emit(true);
      return
    } else {
      this.getDailyExpenseNotificationStatus.emit(false);
      return
    }
  }

 billInvoiceNotificationStatus(data) {
    if (data == true) {
      this.getBillInvoiceNotificationStatus.emit(true);
      return
    } else {
      this.getBillInvoiceNotificationStatus.emit(false);
      return
    }
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      }
    )
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        var postData = { "device_token": token, "device_type": "web" }
        this.http.post(environment.apiEndpoint + 'add_device_token/', postData, this.httpHeaderOptions).subscribe(
          (res) => {
            this.updateToken(userId, token);
          }
        );

      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  // receiveMessage() {
  //   this.angularFireMessaging.messages.subscribe(
  //     (payload) => {
  //       console.log("new message received. ", payload);
  //       this.currentMessage.next(payload);
  //     }
  //   )
  // }

  /**
   * Get notification
   * notification/list/pms/
   */
  getNotificationListing() {
    return this.http.get(environment.apiEndpoint + 'notification/list/pms/', this.httpHeaderOptions);
  }

  readNotification(id: number) {
    return this.http.put(environment.apiEndpoint + 'read_notification/' + id + '/', {}, this.httpHeaderOptions);
  }
}
