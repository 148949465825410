import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { reportingService } from 'src/app/core/services/reporting.service';
import { SettingService } from 'src/app/core/services/setting.service';
import { LoadingState } from '../../loading/loading.component';

@Component({
  selector: 'app-weekly-labour',
  templateUrl: './weekly-labour.component.html',
  styleUrls: ['./weekly-labour.component.scss']
})
export class WeeklyLabourComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  labourform: FormGroup;
  @Input() sitearr: any = [];
  @Input() unitarr: any = [];
  @Input() labourDetailsData: any;
  @Input() contractorList: any = [];
  @Input() projectList: any = [];
  @Input() boqList: any = [];
  @Input() activityList: any = [];
  @Output() setDetailsData = new EventEmitter<any>();
  @Output() setSiteData = new EventEmitter<any>();
  @Output() setBoqData = new EventEmitter<any>();
  start_date: any = null;
  end_date:any = null;
  dateRange: any = [];
  boqDescription = null;

  constructor(private formBuilder: FormBuilder, private settingService: SettingService,
    public reportService: reportingService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.labourform = this.formBuilder.group({
      project_id: [null, Validators.required],
      site_location: [null, Validators.required],
      date_range: [null],
      boq: ['', Validators.required],
      labour_data: this.formBuilder.array([this.addDataFormGroup()])
    });

    if (this.labourDetailsData == undefined) {
      this.labourDetailsData = {
        project_id: null,
        site_location: null,
        date_entry: null,
        boq: ''
      }
    }
    this.loading = LoadingState.Ready;
  }

  addDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      name_of_contractor_id: [null, Validators.required],
      contractor_type: ['PRW', Validators.required],
      required_skilled: ['', Validators.required],
      required_unskilled: ['', Validators.required],
      required_semi_skilled: ['', Validators.required],
      activity_id: [null, Validators.required],
      planned_quantum_of_work: [null, Validators.required],
      available_skilled : [null, Validators.required],
      available_semi_skilled : [null, Validators.required],
      available_unskilled : [null, Validators.required],
      mitigation_plan_arrangements_getting_extra_labour: [null, Validators.required
       ]
    });
  }

  removeDataFormGroup(index) {
    const control = <FormArray>this.labourform.controls['labour_data'];
    control.removeAt(index);
  }

  addButtonClick(): void {
    const control = <FormArray>this.labourform.controls['labour_data'];
    control.push(this.addDataFormGroup());
  }
  
  getFormArrayControl(form) {
    return form.get('labour_data').controls
  }

  onProgressDateSelected() {

  }

  markFormGroupTouched(formGroup: FormGroup) {console.log(formGroup);
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.start_date = this.settingService.formatDate(event.value[0])
      this.end_date = this.settingService.formatDate(event.value[1])
      this.onDateSelected();
    }
  }

  saveLabourdata() {
    if (this.labourform.valid && this.start_date != null && this.end_date != null) {
      this.loading = LoadingState.Processing
      const formData: any = new FormData();
      formData.append('type_of_report', '2');
      formData.append('project_id', this.labourform.value.project_id);
      formData.append('site_location', this.labourform.value.site_location);
      formData.append('boq', this.labourform.value.boq);
      formData.append('start_date',this.start_date);
      formData.append('end_date',this.end_date);
      var labour_data = [];
      this.labourform.value.labour_data.forEach(x => {
        var activity_details_id = x.activity_id
        var activity_details = []
        activity_details_id.forEach(x => {
          var d = {
            activity_id: x
          }
          activity_details.push(d)
        })
        labour_data.push({
          activity_details: activity_details,
          name_of_contractor_id: x.name_of_contractor_id,
          contractor_type: x.contractor_type,
          required_skilled: x.required_skilled,
          required_unskilled: x.required_unskilled,
          required_semi_skilled: x.required_semi_skilled,
          available_skilled: x.available_skilled,
          available_semi_skilled: x.available_semi_skilled,
          available_unskilled: x.available_unskilled,
          planned_quantum_of_work: x.planned_quantum_of_work,
          mitigation_plan_arrangements_getting_extra_labour: x.mitigation_plan_arrangements_getting_extra_labour
        })
      });
      formData.append('labour_data', JSON.stringify(labour_data));
      this.reportService.saveWeeklyLabourData(formData).subscribe(
        data => {
          var res: any = data;
          this.toastr.success('Data has been succesfully saved !', '', {
            timeOut: 3000,
          });
          var objData = Object.assign([], this.labourform.value.labour_data)
          for (var i = objData.length - 1; i >= 0; i--) {
            this.removeDataFormGroup(i)
          }
          this.addButtonClick()
          this.loading = LoadingState.Ready;
          this.labourform.reset();
          this.boqDescription = '';
        },
        error => {
          this.toastr.error('Internal error occurred !', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      );
    }
    else {
      this.markFormGroupTouched(this.labourform);
    }
  }

  labourSiteOnchange(val) {
    this.setSiteData.emit(val);
  }

  labourProjectOnChange(val) {
    this.setBoqData.emit(val);
  }

  onBOQSelection(val) {
    if(val) {
      this.boqDescription = this.boqList.filter(x => x.id == val)[0]['item_description'];
    } else {
      this.boqDescription = null
    }
  }

  onDateSelected() {
    if (this.start_date != null) {
      this.setDetailsData.emit(this.labourDetailsData)
      if (this.labourDetailsData.site_location != null && this.labourDetailsData.project_id != null) {
        this.reportService.fetchWeeklyCommonData(this.labourDetailsData.project_id, this.labourDetailsData.site_location,this.start_date, this.end_date, 1).subscribe(
          res => {
            //console.log(res)
            if (res['result'] != '') {
              this.labourDetailsData.boq = res['result']['boq'];
              this.onBOQSelection(this.labourDetailsData.boq);
            }
            else {
              this.labourDetailsData.boq = null;
              this.boqDescription = '';
            }
            this.setDetailsData.emit(this.labourDetailsData)
          },
          error => {
            //console.log(error)
          }
        )
      }
    }

  }
}
