import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-attendance-summary',
  templateUrl: './attendance-summary.component.html',
  styleUrls: ['./attendance-summary.component.scss']
})
export class AttendanceSummaryComponent implements OnInit {

  @Output() resetHeader = new EventEmitter();
  @Output() getMonthYear = new EventEmitter();
  yearInt: number;
  nextMonthDataAfter: any;
  currentFinancialYear: any;
  previousFinancialYear: any;
  activeIdString: string = "new_summary";
  activeTab: any = 'new_summary';
  myAttendanceConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;

  constructor() {
    this.myAttendanceConfig = AppPageStateConfig.myAttendanceConfig.attendanceSummeryConfig;
    this.activeTab = this.myAttendanceConfig.tab;

   }

  ngOnInit() {
    var date = new Date();
    var month = date.getMonth() + 1
    this.yearInt = new Date().getFullYear();
    if (month == 1 || month == 2 || month == 3) {
      this.currentFinancialYear = this.yearInt - 1 + '-' + this.yearInt;
      this.previousFinancialYear = this.yearInt - 2 + '-' + (this.yearInt - 1);
    }
    else {
      this.currentFinancialYear = this.yearInt + '-' + (this.yearInt + 1);
      this.previousFinancialYear = this.yearInt - 1 + '-' + (this.yearInt);
    }
    this.resetHeader.emit(this.activeIdString);

  }

  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
   
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }

  changeHeader(e) {
    // console.log(e)
    this.activeIdString = e.nextId
    // console.log(this.activeIdString)
    this.resetHeader.emit(this.activeIdString);
    this.activeTab = e.nextId;
    this.myAttendanceConfig.tab = this.activeTab

  }

  resetMonthYear(data){
    this.getMonthYear.emit(data);
  }

}
