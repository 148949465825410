import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadingState } from '../../../components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-daily-material',
  templateUrl: './daily-material.component.html',
  styleUrls: ['./daily-material.component.scss']
})
export class DailyMaterialComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  @Input() sitearr: any = [];
  @Input() employeeList: any = [];
  @Input() materialDetailsData: any;
  @Input() projectList: any = [];
  @Input() boqList: any = [];
  @Input() activityList: any = [];
  @Output() setDetailsData = new EventEmitter<any>();
  @Output() setSiteData = new EventEmitter<any>();
  @Output() setBoqData = new EventEmitter<any>();
  materialList: any = [];
  unitRecievedList: any = [];
  unitDispatchedList: any = [];
  boqDescription = null;
  
  constructor(private formBuilder: FormBuilder,
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.loading = LoadingState.Processing;
    this.form = this.formBuilder.group({
      type_of_report: 1,
      project_id: [null, Validators.required],
      site_location: [null, Validators.required],
      date_entry: [null, Validators.required],
      weather: ['', Validators.required],
      date_of_completion: [null, Validators.required],
      milestone_to_be_completed: ['', Validators.required],
      boq: ['', Validators.required],
      rainfall_from: [''],
      rainfall_to: [''],
      temperature_in_cel: ['', Validators.required],
      ir_problem: [''],
      local_issue: [''],
      client_visit: [''],
      administrative_issue: [''],
      document_ir: [null],
      document_li: [null],
      document_cv: [null],
      document_ai: [null],
      material_recieved_data: this.formBuilder.array([this.addMaterialRecievedDataFormGroup()]),
      material_dispatch_data: this.formBuilder.array([this.addMaterialDispatchedDataFormGroup()])
    });

    this.loadData();
    if (this.materialDetailsData == undefined) {
      this.materialDetailsData = {
        project_id: null,
        site_location: null,
        date_entry: null,
        date_of_completion: null,
        weather: '',
        milestone_to_be_completed: '',
        boq: '',
        rainfall_from: '',
        rainfall_to: '',
        temperature_in_cel: '',
        ir_problem: null,
        local_issue: null,
        client_visit: null,
        administrative_issue: null,
      }
    } else {
      if(this.materialDetailsData.date_entry != null) {
        this.onProgressDateSelected();
      }
    }
  }

  loadData() {
    var params = 'page_size=0'
    var forkArray = [];
    forkArray.push(this.settingService.getMaterialListWithoutStock(params))
    forkJoin(forkArray).subscribe(
      ([materialdata]) => {
        if(materialdata){
          this.materialList = materialdata['results'];
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }  

  onMaterialChange(itemId, unitFor, index) {
    if(unitFor == 'received') {
      this.unitRecievedList = this.materialList.filter(x => x.id === itemId)[0]['materials_unit_details'];
    } else {
      this.unitDispatchedList = this.materialList.filter(x => x.id === itemId)[0]['materials_unit_details'];;
    }
  }

  getMinDate() {
    const current = new Date();
    if (current.getMonth() == 0 && current.getDate() == 1) {
      return {
        year: current.getFullYear() - 1,
        month: 12,
        day: 31
      };
    }
    else if (current.getDate() == 1) {
      var dateObj = new Date();
      dateObj.setDate(0)
      return {
        year: dateObj.getFullYear(),
        month: dateObj.getMonth() + 1,
        day: dateObj.getDate()
      };
    }
    else {
      return {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() - 1
      };
    }
  }

  getCompletionMinDate(form: FormGroup) {
    if (form.value.date_entry) {
      return {
        year: form.value.date_entry.year,
        month: form.value.date_entry.month,
        day: form.value.date_entry.day
      };
    }
    else {
      const current = new Date();
      return {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() - 1
      };
    }
  }

  addMaterialRecievedDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      received_material_id: [null, Validators.required],
      received_qty : ['', Validators.required],
      received_uom_id: [null, Validators.required],
    })
  }

  onProgressDateSelected() {
    if (this.materialDetailsData.date_entry) {
      this.setDetailsData.emit(this.materialDetailsData)
      var date = this.settingService.getProperDate(this.materialDetailsData.date_entry)
      var str = 'date=' + date.toISOString().substr(0, 10);
      if (this.materialDetailsData.site_location != null && this.materialDetailsData.project_id != null) {
        this.reportService.fetchDailyProgressCommonData(this.materialDetailsData.project_id, this.materialDetailsData.site_location, str, 4).subscribe(
          res => {
            //console.log(res)
            if (res['result'] != '') {
              var date = new Date(res['result']['date_of_completion'])
              this.materialDetailsData.date_of_completion = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
              }
              this.materialDetailsData.weather = res['result']['weather'];
              this.materialDetailsData.milestone_to_be_completed = res['result']['milestone_to_be_completed'];
              this.materialDetailsData.temperature_in_cel = res['result']['temperature_in_cel'];
              this.materialDetailsData.ir_problem = res['result']['ir_problem'];
              this.materialDetailsData.local_issue = res['result']['local_issue'];
              this.materialDetailsData.client_visit = res['result']['client_visit'];
              this.materialDetailsData.administrative_issue = res['result']['administrative_issue'];
              this.materialDetailsData.boq = res['result']['boq'];
              this.onBOQSelection(this.materialDetailsData.boq);
            }
            else {
              this.materialDetailsData.date_of_completion = null;
              this.materialDetailsData.weather = '';
              this.materialDetailsData.milestone_to_be_completed = '';
              this.materialDetailsData.temperature_in_cel = '';
              this.materialDetailsData.ir_problem = null;
              this.materialDetailsData.local_issue = null;
              this.materialDetailsData.client_visit = null;
              this.materialDetailsData.administrative_issue = null;
              this.materialDetailsData.boq = null;
              this.boqDescription = '';
            }
            this.setDetailsData.emit(this.materialDetailsData)
          },
          error => {
            //console.log(error)
          }
        )
      }
    }
  }

  removeRecievedDataFormGroup(index) {
    const control = <FormArray>this.form.controls['material_recieved_data'];
    control.removeAt(index);
  }

  addMaterialRecievedButtonClick(): void {
    const control = <FormArray>this.form.controls['material_recieved_data'];
    control.push(this.addMaterialRecievedDataFormGroup());
  }
  
  getFormArrayControl(form) {
    return form.get('material_recieved_data').controls
  }

  addMaterialDispatchedDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      dispatched_material_id: [null, Validators.required],
      dispatched_qty : ['', Validators.required],
      dispatched_uom_id: [null, Validators.required],
    })
  }

  removeDispatchedDataFormGroup(index) {
    const control = <FormArray>this.form.controls['material_dispatch_data'];
    control.removeAt(index);
  }

  addMaterialDispatchedButtonClick(): void {
    const control = <FormArray>this.form.controls['material_dispatch_data'];
    control.push(this.addMaterialDispatchedDataFormGroup());
  }
  
  getDispatchedFormArrayControl(form) {
    return form.get('material_dispatch_data').controls
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  savedata() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing
      const formData: any = new FormData();
      formData.append('type_of_report', '4');
      formData.append('project_id', this.form.value.project_id);
      formData.append('site_location', this.form.value.site_location);
      formData.append('date_entry', this.settingService.getProperDate(this.form.value.date_entry).toISOString());
      formData.append('weather', this.form.value.weather);
      formData.append('date_of_completion', this.settingService.getProperDate(this.form.value.date_of_completion).toISOString());
      formData.append('milestone_to_be_completed', this.form.value.milestone_to_be_completed);
      formData.append('boq', this.form.value.boq);
      if(this.form.value.rainfall_from != '' && this.form.value.rainfall_from != null) {
        formData.append('rainfall_from', this.form.value.rainfall_from.hour + ':' + this.form.value.rainfall_from.minute);
      } 
      if(this.form.value.rainfall_to != '' && this.form.value.rainfall_to != null) {
        formData.append('rainfall_to', this.form.value.rainfall_to.hour + ':' + this.form.value.rainfall_to.minute);
      }
      formData.append('temperature_in_cel', this.form.value.temperature_in_cel);
      if(this.form.value.document_ir != null && this.form.value.document_ir != undefined){
        formData.append('ir_problem',this.form.value.document_ir);
      }
      if(this.form.value.document_li != null && this.form.value.document_li != undefined){
        formData.append('local_issue',this.form.value.document_li);
      }
      if(this.form.value.document_cv != null && this.form.value.document_cv != undefined){
        formData.append('client_visit',this.form.value.document_cv);
      }
      if(this.form.value.document_ai != null && this.form.value.document_ai != undefined){
        formData.append('administrative_issue',this.form.value.document_ai);
      }
      if(this.form.value.material_dispatch_data.length > 0) {
        formData.append('material_dispatch_data',JSON.stringify(this.form.value.material_dispatch_data));
      }
      if(this.form.value.material_recieved_data.length > 0) {
        formData.append('material_received_data',JSON.stringify(this.form.value.material_recieved_data));
      }
      this.reportService.saveMaterialData(formData).subscribe(
        data => {
          this.form.reset();
          this.boqDescription = '';
          var res: any = data;
          this.toastr.success('Data has been succesfully saved !', '', {
            timeOut: 3000,
          });
          var objData = Object.assign([], this.form.value.material_dispatch_data)
          for (var i = objData.length - 1; i >= 0; i--) {
            this.removeDispatchedDataFormGroup(i);
            this.removeRecievedDataFormGroup(i);
          }
          this.addMaterialDispatchedButtonClick();
          this.addMaterialRecievedButtonClick()
          this.loading = LoadingState.Ready
        },
        error => {
          this.toastr.error('Internal error occurred !', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      );
    }
    else {
      this.markFormGroupTouched(this.form);
    }
  }

  progressSiteOnchange(val) {
    this.setSiteData.emit(val);
  }

  progressProjectOnChange(val) {
    this.setBoqData.emit(val);
  }

  weatherChange(e){
    this.setDetailsData.emit(this.materialDetailsData)
  }

  milestoneChange(e){
    this.setDetailsData.emit(this.materialDetailsData)
  }

  onDateCompletionSelected() {
    if (this.materialDetailsData.date_of_completion) {
      this.setDetailsData.emit(this.materialDetailsData)
    }
  }

  onDocumentSelect(event, fileFor) {
      if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg"
        || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpj"
        || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/doc" || event.target.files[0].type == "application/docx"
        || event.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        || event.target.files[0].type == "application/msword"
      ) {
        if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
            var reader = new FileReader();
            reader.onload = (event) => {
  
            }
            if(fileFor == "ai") {
              this.form.patchValue({
                document_ai: event.target.files[0]
              })
            } else if(fileFor == "li") {
              this.form.patchValue({
                document_li: event.target.files[0]
              })
            } else if(fileFor == "ir") {
              this.form.patchValue({
                document_ir: event.target.files[0]
              })
            } else if(fileFor == "cv") {
              this.form.patchValue({
                document_cv: event.target.files[0]
              })
            }
            
            reader.readAsDataURL(event.target.files[i]);
          }
        }
      } else {
        this.toastr.error('Image/PDF/Word file type allowed', '', {
          timeOut: 3000,
        });
      }
  }

  onBOQSelection(val) {
    if(val) {
      this.boqDescription = this.boqList.filter(x => x.id == val)[0]['item_description'];
    } else {
      this.boqDescription = null
    }
  }

  viewDoc(item: any) {
    window.open(item, '', 'height=700,width=800,scrollbars=yes');
  }

  removeDoc(source) {
    this.loading = LoadingState.Processing;
    if(source == 'ir') {
      this.materialDetailsData.ir_problem = null;
    } else if (source == 'cv') {
      this.materialDetailsData.client_visit = null;
    } else if (source == 'li') {
      this.materialDetailsData.local_issue = null;
    } else if (source == 'ai') {
      this.materialDetailsData.administrative_issue = null;
    }
    this.loading = LoadingState.Ready;
  }
}
