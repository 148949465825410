import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../../components/loading/loading.component';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { LocalService } from '../../../../services/local.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-requisition-approval-modal',
  templateUrl: './requisition-approval-modal.component.html',
  styleUrls: ['./requisition-approval-modal.component.scss']
})
export class RequisitionApprovalModalComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  requisitionData: any;
  isModify: boolean = false;
  userId: number;
  currentApproveUser: any;
  checkStatus: any;
  itemData: any;
  higherLevelPermission:number;
  permissionList: any = [];
  requisitionId: number;
  constructor(
    public dialogRef: MatDialogRef<RequisitionApprovalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    // console.log(this.data)
    this.form = this.formBuilder.group({
      arm_approval: [1, Validators.required],
      modification_quantity: [{ value: null, disabled: true }],
      approver_remarks: [''],
    })
    this.requisitionData = this.data['result'];
    this.itemData = this.data.item; console.log(this.data);
    // this.currentApproveUser = this.data.currentApproveUser;
    this.requisitionId = this.requisitionData.requisition_master.id
    this.getPermissionLevel()
    
  }

  getPermissionLevel() {
    this.loading = LoadingState.Processing;
    let params: URLSearchParams = new URLSearchParams();
    params.set('section', 'requisition');
    params.set('requisition', this.requisitionId?this.requisitionId.toString():'');
    this.executionPurchaseService.getPermissionLevel(params).subscribe(
      res => {
        var userid = this.localService.getJsonValue('userid');
        if (res['result'].user_approve_details.length > 0) {
          this.permissionList = res['result'];
          this.higherLevelPermission = this.permissionList['user_approve_details'].length - 1;
          this.permissionList.user_approve_details.forEach(x => {
            if (x.user_details.id == userid) {
              this.currentApproveUser = x;
            }
          });
        }
        console.log(this.currentApproveUser)
        this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
      }
    )
  }

  

  

  checkRequisitionApproval(event) {
    this.form.patchValue(
      {
        approved_expenses: null
      }
    )
    let radioValue = event.value;
    if (radioValue == '2') {
      this.isModify = true;
      this.form.controls['modification_quantity'].enable();
      this.form.controls['modification_quantity'].setValidators([Validators.required]);
      this.form.controls['modification_quantity'].setErrors({ required: true });
    } else {
      this.isModify = false;
      this.form.controls['modification_quantity'].disable();
      this.form.controls['modification_quantity'].clearValidators();
      this.form.controls['modification_quantity'].setErrors(null);
    }
  }

  apply() {

    if (this.form.valid) {
      this.loading = LoadingState.Processing
      var dataObj = {
        requisitions_master: this.requisitionData.requisition_master.id,
        project: this.requisitionData.requisition_master.project,
        site_location: this.requisitionData.requisition_master.site_location,
        type: this.requisitionData.requisition_master.type.id,
        item_approval_details: []
      }
      var itemDetails = [];
      var d = {
        uom: this.itemData['uom'],
        as_per_drawing: this.itemData['actual_consumption']['as_per_drawing'],
        current_stock: this.itemData['current_stock'],
        initial_estimate: this.itemData['actual_consumption']['initial_estimate'],
        item: this.itemData['item_details']['id'],
        arm_approval: this.form.value.arm_approval,
        permission_level: this.currentApproveUser.id,
        approved_quantity: this.itemData['quantity'],
        approver_remarks: this.form.value.approver_remarks,
      }
      if(this.form.value.arm_approval == 2){
        d['approved_quantity'] = this.form.value.modification_quantity
      }
      itemDetails.push(d);
      dataObj['item_approval_details'] = itemDetails;
      this.executionPurchaseService.submitApprovals(dataObj).subscribe(
        res => {
          this.loading = LoadingState.Ready;
          if(this.form.value.arm_approval == 0) {
            this.toastr.success('Data has been Rejected', '', {
              timeOut: 3000,
            });
          } else {
            this.toastr.success('Data has been Approved', '', {
              timeOut: 3000,
            });
          }
          
          this.dialogRef.close(true);
        },
        error => {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(false);
        }
      )

    } else {
      this.markFormGroupTouched(this.form)
    }
  }

  


  

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
