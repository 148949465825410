import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor(private http: HttpClient) {
  }

  getHODReportingHeadMaster() {
    return this.http.get(environment.apiEndpoint + 'employee_list_wo_pagination/');
  }

  getHODReportingHeadMasterByTeam(params) {
    return this.http.get(environment.apiEndpoint + 'employee_list_wo_pagination/?' + params);
  }

  getEmployeeListWithOutDetails(params) {
    return this.http.get(environment.apiEndpoint + 'v2/employee_list_without_details/?' + params);
  }
  getGradeList() {
    return this.http.get(environment.apiEndpoint + 'grade_add/');
  }
  getEmployeList(params) {
    return this.http.get(environment.apiEndpoint + 'employee_list/?' + params);
  }

  exportEmployeList(params) {
    return this.http.get(environment.apiEndpoint + 'employee_list_export_download/?' + params);
  }

  deactivateEmployee(id: number, data: any) {
    return this.http.put(environment.apiEndpoint + 'employee_lock_unlock/' + id + '/', data);
  }
  addEmployee(data: any) {
    return this.http.post(environment.apiEndpoint + 'employee_add/', data);
  }

  importCSV(data: any) {
    return this.http.post(environment.apiEndpoint + 'employee_add_by_csv_or_excel/', data);
  }


  getDesignation() {
    return this.http.get(environment.apiEndpoint + 't_core_designation_add/');
  }
  getDepartment() {
    return this.http.get(environment.apiEndpoint + 't_core_department_add/');
  }

  getSubDepartment(id: number) {
    return this.http.get(environment.apiEndpoint + 't_core_sub_department/' + id + '/');
  }

  getEmployeeDetails(id: number, params) {
    return this.http.get(environment.apiEndpoint + 'employee_edit/' + id + '/?' + params);
  }
  getBenifitsMaster() {
    return this.http.get(environment.apiEndpoint + 'hrms_benefits_provided_add/');
  }
  getFacilitiesMaster() {
    return this.http.get(environment.apiEndpoint + 'hrms_other_facilities_add/');
  }

  getCompany() {
    return this.http.get(environment.apiEndpoint + 't_core_company_add/');
  }

  updateEmployeeRole(id: number, params, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'employee_edit/' + id + '/?' + params, data)
  }

  deleteDocument(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'hrms_employee_document_delete/' + id + '/', {})
  }
  deleteProfileDocument(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'hrms_employee_profile_document_delete/' + id + '/', {})
  }

  addDocument(data: any) {
    return this.http.post(environment.apiEndpoint + 'hrms_employee_document_add/', data);
  }
  addPersonalTabDocument(data: any) {
    return this.http.post(environment.apiEndpoint + 'hrms_employee_profile_document_add/', data);
  }

  getQualificationMaster() {
    return this.http.get(environment.apiEndpoint + 'qualification_master_add/');
  }

  academicQualificationAdd(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'hrms_employee_academic_qualification_add/', data);
  }

  academicQualificationDocAdd(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'hrms_employee_academic_qualification_document_add/', data);
  }

  academicQualificationDelete(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'hrms_employee_academic_qualification_delete/' + id + '/', {})
  }

  getEmployeeSalaryType() {
    return this.http.get(environment.apiEndpoint + 't_core_salary_type_add/');
  }

  getEmployeeReportForHR(params) {
    return this.http.get(environment.apiEndpoint + 'employee_list_for_hr/?' + params);
  }

  exportEmployeeReportForHR() {
    return this.http.get(environment.apiEndpoint + 'employee_list_for_hr_export_download/');
  }

  getNewJoiners(params) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_new_joiner_report/?' + params);
  }

  exportNewJoiners(params) {
    return this.http.get(environment.apiEndpoint + 'v2/attendance_new_joiner_report_export_download/?' + params);
  }

  getWorkFromHomeReportList(params) {
    return this.http.get(environment.apiEndpoint + 'v2/work_from_home_report/?' + params);
  }

  downloadWorkFromHomeReport(params) {
    return this.http.get(environment.apiEndpoint + 'v2/work_from_home_report/download/?' + params);
  }

  verifyPassword(data: any) {
    return this.http.post(environment.apiEndpoint + 'v2/employee_view_password_verification/', data);
  }

  getEmployeListWithSite() {
    return this.http.get(environment.apiEndpoint + 'v2/employee_list_without_details/?module=pms');
  }

  getSiteForEmployee(userId) {
    return this.http.get(environment.apiEndpoint + 'pms/project_sites/user/?user='+userId);
  }
}
