import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-vehicle-vehicle-inner-tab',
  templateUrl: './vehicle-vehicle-inner-tab.component.html',
  styleUrls: ['./vehicle-vehicle-inner-tab.component.scss']
})
export class VehicleVehicleInnerTabComponent implements OnInit {
  @Input() projectId:number;
  isRental:boolean;
  vehical:string;
  form: FormGroup;
  vehicle_type:number = 1;
  vehicleDetail:any = [];
  vehicleDriverList:any = [];
  vehicleDocumentList:any = [];
  loading: LoadingState = LoadingState.NotReady;
  driverDetails:any = [];
  listEdit:boolean;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    public settingService:SettingService,
  ) { 
    this.isRental=true;
    this.vehical = 'rental';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      vehical:[this.vehical],
      vehicle_type:[this.vehicle_type,Validators.required],
      vehicle_model:[null,Validators.required],
      vehicle_details:[null,Validators.required],
      Rate_per_day:[null],
      vehicle_cost:[null],
      quantity:[null,Validators.required],
      owner_name:[null],
      owner_contact_details:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      vehicle_driver:this.formBuilder.array([
        this.createVehicleDriver()
      ]),
      documents:this.formBuilder.array([
        //this.createDocument()
      ])

    })
    if(this.projectId){
      this.getVehicleDetails();
      this.getVehicleDriverList();
    }
    
  }

  handleChange(e: any) {
   
    if (e.target.value == "rental") {
      this.isRental= true;
      this.vehicle_type=1;
      this.form.patchValue({
        vehicle_cost: null,  
      })
    }
    else{
      this.isRental= false;
      this.vehicle_type=2;
      this.form.patchValue({
        Rate_per_day: null,
        owner_name: null,
        owner_contact_details: null
      })
    } 
    this.form.patchValue({
      vehicle_type: this.vehicle_type
          })
  }

  save(){
    if(this.form.valid){
      var v_box_documents_data = [];
      var v_driver_data = [];
      v_box_documents_data = this.form.value.documents;
      v_driver_data = this.form.value.vehicle_driver;

      const data = {
        project:this.projectId,
        vehicle_type:this.form.value.vehicle_type,
        vehicle_model:this.form.value.vehicle_model,
        vehicle_details:this.form.value.vehicle_details,
        vehicle_cost:this.form.value.vehicle_cost,
        Rate_per_day:this.form.value.Rate_per_day,
        quantity:this.form.value.quantity,
        owner_name:this.form.value.owner_name,
        owner_contact_details:this.form.value.owner_contact_details,

        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
        vehicle_driver:v_driver_data
       
      }

      this.vehicleDocumentList = [];
      this.perExecutionService.addVehicleOffice(data).subscribe(
        res => {
          console.log(res);
          var forkArray = [];

          v_box_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.vehicle_document, x.vehicle_document['name']);
              forkArray.push(this.perExecutionService.addVehicleDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.vehicleDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )

          this. getVehicleDriverList(); 
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
          console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        })

      console.log(data);
    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  getVehicleDetails(){
    this.perExecutionService.getVehicleOffice(this.projectId).subscribe(
      res => {
       if(res['result'][0]){
        this.vehicleDetail = res['result'][0];
        this.vehicleDocumentList = res['result'][0].document_details;
        console.log(this.vehicleDetail);

        var start_date = new Date(this.vehicleDetail['requirment_s_date'])
            this.vehicleDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.vehicleDetail['requirment_e_date'])
            this.vehicleDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
            this.form.patchValue({
                       vehicle_type: this.vehicleDetail.vehicle_type
            })
          this.vehicleDetail.budgeted_cost = parseFloat(this.vehicleDetail.budgeted_cost).toFixed(2);
          if(this.vehicleDetail.Rate_per_day!=null){
            this.vehicleDetail.Rate_per_day = parseFloat(this.vehicleDetail.Rate_per_day).toFixed(2);
          }
         
          this.vehicleDetail.executed_cost = parseFloat(this.vehicleDetail.executed_cost).toFixed(2);

          this.isRental = this.vehicleDetail.vehicle_type == 1?true:false;
          this.vehical = this.vehicleDetail.vehicle_type == 1?'rental':'purchase';

          if(this.vehicleDetail.vehicle_type == 1){
            this.form.patchValue({
              vehicle_cost: null,  
            })
          }
          else{
            this.form.patchValue({
              Rate_per_day: null,
              owner_name: null,
              owner_contact_details: null
            })
          }
         

       }
       
        this.loading = LoadingState.Ready;
      },
      error =>{
        console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.vehicleDocumentList[index]['isEdit'] = true
    this.vehicleDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.vehicleDocumentList[index]['document_name'] == '') {
      this.vehicleDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.vehicleDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.vehicleDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editVehicleDocumentOffice(this.vehicleDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.vehicleDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
    this.perExecutionService.deleteVehicleDocumentOffice(this.vehicleDocumentList[index]['id']).subscribe(
      res => {
        this.vehicleDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
	 })

    
  }
  getVehicleDriverList(){
    this.perExecutionService.getVehicleDriverListOffice(this.projectId).subscribe(
      res => {
        this.vehicleDriverList = res['result'];

        this.loading = LoadingState.Ready;
      },
      error =>{
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  update(){
    if(this.form.valid){

      this.loading = LoadingState.Processing;
      var v_documents_data = [];
      var v_driver_data =[];
      v_documents_data = this.form.value.documents;
      v_driver_data = this.form.value.vehicle_driver;

      const data = {
        project:this.projectId,
        vehicle_type:this.form.value.vehicle_type,
        vehicle_model:this.form.value.vehicle_model,
        vehicle_details:this.form.value.vehicle_details,
        vehicle_cost:this.form.value.vehicle_cost,
        Rate_per_day:this.form.value.Rate_per_day,
        quantity:this.form.value.quantity,
        owner_name:this.form.value.owner_name,
        owner_contact_details:this.form.value.owner_contact_details,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
        vehicle_driver:v_driver_data
      }
      this.vehicleDocumentList = [];
      this.perExecutionService.editVehicleOffice(this.vehicleDetail.id,data).subscribe(
        res => {
          var forkArray = [];

          v_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.vehicle_document, x.vehicle_document['name']);
              forkArray.push(this.perExecutionService.addVehicleDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.vehicleDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )

          this.getVehicleDriverList();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });  
        },
        error=>{
          console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
		  
        }
      )
      
    }
    else{
      this.markFormGroupTouched(this.form);
     // console.log("error");
    }
}

updateDriver()
{
  var v_documents_data = [];
  v_documents_data = this.form.value.documents;

  if(this.form.valid){

    this.loading = LoadingState.Processing;
    const updateDriverData = {
      project:this.projectId,
      vehicle_driver:this.driverDetails.vehicle_driver,
      driver_name:this.form.value.vehicle_driver[0].driver_name,
      driver_contact_details:this.form.value.vehicle_driver[0].driver_contact_details,
      vehicle_numberplate:this.form.value.vehicle_driver[0].vehicle_numberplate,

      vehicle_details:{
        vehicle_type:this.form.value.vehicle_type,
        vehicle_model:this.form.value.vehicle_model,
        vehicle_details:this.form.value.vehicle_details,
        vehicle_cost:this.form.value.vehicle_cost,
        Rate_per_day:this.form.value.Rate_per_day,
        quantity:this.form.value.quantity,
        owner_name:this.form.value.owner_name,
        owner_contact_details:this.form.value.owner_contact_details,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
      }
    }
    console.log(updateDriverData);

    //this.tiffinBoxDocumentList = [];
    this.perExecutionService.editVehicleDriverOffice(this.driverDetails.id,updateDriverData).subscribe(
      res =>{
        console.log(res);
        var forkArray = [];

        if(this.form.value.documents.length > 0){
          v_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project",this.projectId);
            formData.append("module_id", this.vehicleDetail.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.vehicle_document, x.vehicle_document['name']);
            forkArray.push(this.perExecutionService.addVehicleDocumentOffice(formData))
        })
        forkJoin(forkArray).subscribe(
          (result: any[]) => {
            // console.log(result)
            result.forEach(y => {
              this.vehicleDocumentList.push(y)
            })
            
            // this.add_tender_document()
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
    }
    this.getVehicleDriverList(); 
    this.loading = LoadingState.Ready;
    this.toastr.success('Data has been saved','',{
      timeOut: 3000,
    });
      },
      error =>{
        console.log(error);
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
    
      }
    )
    

  }else{
    console.log("error");
  }
}

  editDriver(data){
    this.driverDetails = data;
    console.log(this.driverDetails);
    this.listEdit = true;
   
   }
  deleteDriver(id:number){

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteVehicleDriverListOffice(id).subscribe(
        res => {
          this.getVehicleDriverList();
          this.listEdit = false;
          this.driverDetails = [];
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })
   
  }


  //Add Driver
  createVehicleDriver(){
    return this.formBuilder.group({
      project_id:this.projectId,
      driver_name:[null,Validators.required],
      driver_contact_details:[null,Validators.required],
      vehicle_numberplate:[null,Validators.required]
    })
  }

  deleteVehicleDriver(index:number)
  {
    const control = <FormArray>this.form.controls['vehicle_driver'];
    control.removeAt(index);
  }

  getFormArrayControl(form) {
    return form.get('vehicle_driver').controls
  }

  addVehicleDriver()
  {
    const control = <FormArray>this.form.controls['vehicle_driver'];
    control.push(this.createVehicleDriver());
  }

// Add Doc

createDocument(){
  return this.formBuilder.group({
    document_name: [null, Validators.required],
    document: [null, Validators.required],
    vehicle_document: null
  })
}

getFormArrayControlDoc(form) {
  return form.get('documents').controls
}

addDocument()
{
  const control = <FormArray>this.form.controls['documents'];
  control.push(this.createDocument());
}

deleteDocument(index:number)
{
  const control = <FormArray>this.form.controls['documents'];
  control.removeAt(index);
}

onSelectFile(event, index){
  console.log(event.target.files);
  if (event.target.files && event.target.files[0]) {
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();
      reader.onload = (event) => {
        // console.log(event.target['result']);
      }
      const control = <FormArray>this.form.controls['documents'];
      control.at(index).patchValue({
        document: event.target.files[0]['name'],
        vehicle_document: event.target.files[0]
      })
      reader.readAsDataURL(event.target.files[i]);
    }
  }
}

markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    if (control.controls) {
      control.controls.forEach(c => this.markFormGroupTouched(c));
    }
  });
}

isFieldValid(form: FormGroup, field: string) {
  return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
}

displayFieldCss(form: FormGroup, field: string) {
  return {
    'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
    'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
  };
}

}
