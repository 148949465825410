import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  constructor(
    private http: HttpClient
  ) {
    
  } 

  getInsuranceList(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/insurance_details/list/?' + params)
  } 

  getSumValue(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/insurance_details/list/sum/?' + params)
  } 

  addInsurance(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/insurance_details/add/', data)
  }

  getInsuranceDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/insurance_details/edit/' + id + '/')
  }


  updateInsurance(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/insurance_details/update/' + id + '/', data)
  }

  deleteInsurance(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/insurance_details/update/' + id + '/', data)
  }

  downloadInsuranceReport(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/insurance_details/list/download/?' + params)
  }
}
