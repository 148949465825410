import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DailyWorksheetService } from '../../../services/daily-worksheet.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/setting.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as Globals from '../../../globals';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;

  taskForm: FormGroup
  appointmentForm: FormGroup;
  manualEntryForm: FormGroup;
  taskList = []
  appointmentList = []
  type:number = 3
  maxStartDate: any
  maxEndDate: any;  
  minDate: any = new Date(Date.now() - Globals.worksheetDaysLimit * 24 * 60 * 60 * 1000);
  taskData:any = {
    start_time:null,
    end_time:null,
    date:null,
    work:null
  };
  constructor(
    public dialogRef: MatDialogRef<AddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private worksheetService: DailyWorksheetService,
    private toastr: ToastrService,
    private settingService: SettingService,
  ) { }

  ngOnInit() {
    this.loading = LoadingState.Ready;
    this.maxStartDate =  this.settingService.formatDate(new Date()) + " 23:59:00";
    this.manualEntryForm = this.formBuilder.group({
      date:[null,Validators.required],     
      start_time:[null,Validators.required],
      end_time:[null,Validators.required],
      work:[null,Validators.required]
    });
   
    // this.getDailySheetTaskAndAppointmentList()
  }


  // getDailySheetTaskAndAppointmentList(){
  //   this.loading = LoadingState.Processing;
  //   var limitDays = "days="+Globals.timesheetDaysLimit
  //   var forkArray = [];
  //   forkArray.push(this.taskService.getDailySheetTaskList(limitDays))
  //   forkArray.push(this.taskService.getDailySheetAppointmentList(limitDays))
  //   forkJoin(forkArray).subscribe(
  //     ([taskList,appointmentList]) => {
  //       if(taskList){
  //         this.taskList = taskList['result']
  //       }
  //       if(appointmentList){
  //         this.appointmentList = appointmentList['result']
  //       }
  //       this.loading = LoadingState.Ready;
  //     },
  //     err => {
  //       this.loading = LoadingState.Ready;
  //       this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //     }
  //   )
  // }

  dateHandler($event) {
    this.maxEndDate = this.settingService.formatDate(this.taskData.start_time) + " 23:59:00"
  }

  dateReset(){    
    this.taskData.date = '';    
  }

  startTimeReset(){        
    this.taskData.start_time = '';    
  }

  endTimeReset(){    
    this.taskData.end_time = '';    
  }


  submitTask(){   
    if(this.manualEntryForm.valid){
      this.loading = LoadingState.Processing;
      let data = {
        date:this.settingService.getOwlProperDate(this.manualEntryForm.value.date),
        work:this.manualEntryForm.value.work,
        start_time:this.settingService.getOwlProperDate(this.manualEntryForm.value.start_time),
        end_time:this.settingService.getOwlProperDate(this.manualEntryForm.value.end_time)
      }
      var datum: any = [];
      datum.push(data)
      this.worksheetService.addDailyWorksheet({'works_done': datum}).subscribe(
        res=>{            
          this.close(true);
        },
        error=>{
          this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready;
        }
      )
     
    }else{
      this.markFormGroupTouched(this.manualEntryForm);
    }
  }

  // submitAppointment(){
  //   if(this.appointmentForm.valid){
  //     this.loading = LoadingState.Processing;
  //     let data = {
  //       appointment:this.appointmentForm.value.appointment,
  //       task_description:this.appointmentForm.value.task_description,
  //       start_time:this.settingService.getOwlProperDate(this.appointmentForm.value.start_time),
  //       end_time:this.settingService.getOwlProperDate(this.appointmentForm.value.end_time)
  //     }
  //     this.taskService.addTaskinTimesheetV2(data).subscribe(
  //       res=>{            
  //         this.close(true);
  //       },
  //       error=>{
  //         this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //       this.loading = LoadingState.Ready;
  //       }
  //     )
  //   }else{
  //     this.markFormGroupTouched(this.appointmentForm);
  //   }
  // }

  // submitManualEntry(){
  //   console.log(this.manualEntryForm.value)   
  //   if(this.manualEntryForm.valid){
  //     this.loading = LoadingState.Processing;
  //     let data = {
  //       task_name:this.manualEntryForm.value.task_name,
  //       task_description:this.manualEntryForm.value.task_description,
  //       start_time:this.settingService.getOwlProperDate(this.manualEntryForm.value.start_time),
  //       end_time:this.settingService.getOwlProperDate(this.manualEntryForm.value.end_time)
  //     }
  //     this.taskService.addTaskinTimesheet(data).subscribe(
  //       res=>{            
  //         this.close(true);
  //       },
  //       error=>{
  //         this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //       this.loading = LoadingState.Ready;
  //       }
  //     )    
  //   }else{
  //     this.markFormGroupTouched(this.manualEntryForm);
  //   }
  // }

  // setType(type){
  //   this.type = type.value
  // }


  close(key:boolean) {
    this.dialogRef.close(key);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
