import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractorsService {

  constructor(
    private http: HttpClient
  ) {
    
  } 

 
  // contractor type
  getContractorTypeList(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/contractors/category/list/?' + params)
  }

  addContractorType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/contractors/category/add/', data)
  }

  getContractorTypeDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/contractors/category/edit/' + id + '/')
  }
  
  updateContractorType(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/contractors/category/edit/' + id + '/', data)
  }

  deleteContractorType(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/contractors/category/delete/' + id + '/', data)
  }

  // contractor

  getContractorList(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/contractors/list/?' + params)
  } 

  addContractor(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/contractors/add/', data)
  }

  getContractorDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/contractors/edit/' + id + '/')
  }


  updateContractor(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/contractors/edit/' + id + '/', data)
  }

  deleteContractor(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/contractors/delete/' + id + '/', data)
  }

  downloadContractorReport(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/contractors/list/download/?' + params)
  }


  

}
