import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { SettingService } from 'src/app/core/services/setting.service';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.scss']
})
export class WeeklyComponent implements OnInit {
  reportarr: any = [];
  @Input() sitearr: any = [];
  @Input() projarr: any = [];
  @Input() siteid: number;
  @Input() projectid: number;
  @Input() currentmaintab: string;
  @Output() getSelectedSite: EventEmitter<number> = new EventEmitter<number>();
  sweekstartdate: any;
  sweekenddate: any;
  csvLinkWeekly: string;
  currenttab: string = 'progress';
  progressDownloadLink: string;
  labourDownloadLink: string;
  PMDownloadLink: string;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  weeklyProgressReportConfog:any;
  startDate: any;
  endDate: any;
  dateRange: any;

  constructor(private settingService: SettingService,) {
    this.weeklyProgressReportConfog = AppPageStateConfig.progressReportsConfig.weeklyProgressReportConfog;
    this.currenttab = this.weeklyProgressReportConfog.tab;

    if(this.weeklyProgressReportConfog.startDate!=null){
      this.sweekstartdate = this.weeklyProgressReportConfog.startDate;
      this.sweekenddate = this.weeklyProgressReportConfog.endDate;
    }
   }

  ngOnInit() {

  }

  ngOnChanges() {

  }

  setProjectId(value) {
    this.getSelectedSite.emit(value)
  }

  fetchReports() {
    this.weeklyProgressReportConfog.startDate = this.sweekstartdate;
    this.weeklyProgressReportConfog.endDate = this.sweekenddate;
  }

  getProgressDownloadLink(val) {
    this.progressDownloadLink = val;
    this.checkDownloadLink()
  }


  getLabourDownloadLink(val) {
    this.labourDownloadLink = val;
    this.checkDownloadLink()
  }

  getPMDownloadLink(val) {
    this.PMDownloadLink = val;
    this.checkDownloadLink()
  }

  onTabChange($event) {
    this.currenttab = $event.nextId;
    this.weeklyProgressReportConfog.tab = this.currenttab;
    this.checkDownloadLink()
  }

  ngAfterViewInit() {
    this.switchNgBTab(this.currenttab);
   
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }

  checkDownloadLink() {
    this.csvLinkWeekly = null;
    if (this.currenttab == 'labour') {
      this.csvLinkWeekly = this.labourDownloadLink
    } else if (this.currenttab == 'pandm') {
      this.csvLinkWeekly = this.PMDownloadLink
    } else if (this.currenttab == 'progress') {
      this.csvLinkWeekly = this.progressDownloadLink
    }
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.weeklyProgressReportConfog.startDate = this.settingService.formatDate(event.value[0])
      this.weeklyProgressReportConfog.endDate = this.settingService.formatDate(event.value[1])
      this.sweekstartdate = this.settingService.formatDate(event.value[0]);
      this.sweekenddate = this.settingService.formatDate(event.value[1]);
    }
  }
}
