import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-rawmaterial-material-yard-inner-tab',
  templateUrl: './rawmaterial-material-yard-inner-tab.component.html',
  styleUrls: ['./rawmaterial-material-yard-inner-tab.component.scss']
})
export class RawmaterialMaterialYardInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  materialDetail: any = [];
  materialDocumentList :any = []
  listEdit:boolean;
  isPrivateLand:boolean;
  structure_type: string;
  land_type:number=1;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public settingService:SettingService,

  ) { 
    this.isPrivateLand=true;
    this.structure_type='private';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      available:[''],
      land_type:[this.land_type],
      protection_type:[null,Validators.required],
      area_of_yard:[null,Validators.required],
      rental_charge:[null,Validators.required],
      area_owner_name:[null],
      area_owner_phone_no:[null],
      area_owner_address:[null],
      latitude:[null],
      longitude:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
    })

    if(this.projectId){
      this.getMaterialDetails();
    }
  }

  save(){
    if(this.form.valid){
      var mat_documents_data = [];
      mat_documents_data = this.form.value.documents;
     // console.log(mat_documents_data);

      this.loading = LoadingState.Processing

      const data = {
        project: this.projectId,
        land_type: this.form.value.land_type,
        protection_type:this.form.value.protection_type,
        area_of_yard: this.form.value.area_of_yard,
        rental_charge: this.form.value.rental_charge,
        area_owner_name: this.form.value.area_owner_name,
        area_owner_phone_no: this.form.value.area_owner_phone_no,
        area_owner_address: this.form.value.area_owner_address,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }
      this.materialDocumentList = [];
      this.perExecutionService.addMaterialYardOffice(data).subscribe(
        res => {
          var forkArray = [];

          mat_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.yard_document, x.yard_document['name']);
              forkArray.push(this.perExecutionService.addMaterialYardDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.materialDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
     // console.log(data);
    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  getMaterialDetails(){
    this.perExecutionService.getMaterialYardOffice(this.projectId).subscribe(
      res=>{
        if(res['result'][0]){
          this.materialDetail = res['result'][0];
         // console.log(this.materialDetail);
          this.materialDocumentList = res['result'][0].document_details;
          var start_date = new Date(this.materialDetail['requirment_s_date'])
            this.materialDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.materialDetail['requirment_e_date'])
            this.materialDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          this.form.patchValue({
            latitude: this.materialDetail.latitude,
            longitude: this.materialDetail.longitude,
            land_type: this.materialDetail.land_type
          })

          this.materialDetail.budgeted_cost = parseFloat(this.materialDetail.budgeted_cost).toFixed(2);
          this.materialDetail.executed_cost = parseFloat(this.materialDetail.executed_cost).toFixed(2);
          this.materialDetail.rental_charge = parseFloat(this.materialDetail.rental_charge).toFixed(2);
          this.land_type = this.materialDetail.land_type;
          this.structure_type = this.materialDetail.land_type==1?'private':'goverment';
          this.isPrivateLand = this.materialDetail.land_type==1?true:false;

        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
          });
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.materialDocumentList[index]['isEdit'] = true
    this.materialDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.materialDocumentList[index]['document_name'] == '') {
      this.materialDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.materialDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.materialDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editMaterialYardDocumentOffice(this.materialDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.materialDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteMaterialYardDocumentOffice(this.materialDocumentList[index]['id']).subscribe(
        res => {
          this.materialDocumentList.splice(index, 1)
          // console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
   })
   
   
  }

  update(){
    if(this.form.valid){
      //console.log(this.materialDetail);
      var mat_documents_data = [];
      mat_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        mat_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project", this.projectId);
            formData.append("module_id", this.materialDetail.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.yard_document, x.yard_document['name']);
            forkArray.push(this.perExecutionService.addMaterialYardDocumentOffice(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.materialDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateMat();
      }

      else{
        this.updateMat();
      }
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

  updateMat(){
    if(this.form.valid){
      this.loading = LoadingState.Processing

      const updateData = {
        project: this.projectId,
        land_type: this.form.value.land_type,
        protection_type:this.form.value.protection_type,
        area_of_yard: this.form.value.area_of_yard,
        rental_charge: this.form.value.rental_charge,
        area_owner_name: this.form.value.area_owner_name,
        area_owner_phone_no: this.form.value.area_owner_phone_no,
        area_owner_address: this.form.value.area_owner_address,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }

      this.perExecutionService.editMaterialYardOffice(this.materialDetail.id,updateData).subscribe(
        res =>{
          //console.log(res)
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error =>{
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )

    }
  }

  handleChange(e: any) {
   
    if (e.target.value == "private") {
      this.isPrivateLand= true;
      this.land_type = 1;
     
    }
    else{
    this.isPrivateLand= false;
    this.land_type = 2;
      this.form.patchValue({
        area_owner_name: null,
        area_owner_phone_no: null,
        area_owner_address: null,
        latitude: null,
        longitude: null
      })
    }

    this.form.patchValue({
      land_type: this.land_type
        })
  }

  handleAddressChange(address) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      area_owner_address: formattedAddress
    })
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: [null, Validators.required],
      document: [null, Validators.required],
      yard_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }
  onSelectFile(event, index){
   // console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          yard_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
