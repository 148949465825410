import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../loading/loading.component';
import { ToastrService } from 'ngx-toastr';
import * as Globals from '../../../../globals';
import { AttendanceService } from '../../../../services/attendance.service';
import { LocalService } from '../../../../services/local.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { SettingService } from '../../../../services/setting.service';
import { DocumentViewModalComponent } from '../../../../components/document-view-modal/document-view-modal.component';
import { AppPageStateConfig } from '../../../../../app.config';

@Component({
  selector: 'app-advance-leave-report',
  templateUrl: './advance-leave-report.component.html',
  styleUrls: ['./advance-leave-report.component.scss']
})
export class AdvanceLeaveReportComponent implements OnInit {
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  @Input() employeeId: number;
  loading: LoadingState = LoadingState.NotReady;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  leaveReport: any = [];
  totalLeaveReport: number;
  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList: any = [];
  pageSize: number;
  search_key = '';
  headerThOption = [];
  sort_by = '';
  sort_type = '';
  CL = '';
  EL = '';
  absent = '';
  designationList: any;
  departmentList: any;
  approvalArray: any = [];
  reportTypeCheckbox: any = [];
  approveTypeCheckbox: any = [];
  approveCheckList: any = [];
  // leaveCheckList: any = [];
  leaveTypes = '';
  leaveStartDate = ''
  leaveEndDate = ''
  dateRange: any = null;
  scrollAmount;
  hasScroll: boolean = false;
  advanceLeaveConfig: any;
  leaveTypeCheckList: any = [];
  leaveTypesData: any = [];
  constructor(
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private route: ActivatedRoute,
    private settingService: SettingService,
    private element: ElementRef,
    private localService: LocalService,
  ) {
    this.advanceLeaveConfig = AppPageStateConfig.attendanceConfig.attendanceLeaveReportConfig.advanceLeaveConfig;
  }

  ngOnInit() {
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;

    if (this.advanceLeaveConfig.defaultPagination != null) {
      this.defaultPagination = this.advanceLeaveConfig.defaultPagination;
    }
    if (this.advanceLeaveConfig.itemPerPage != null) {
      this.itemPerPage = this.advanceLeaveConfig.itemPerPage;
    }
    if (this.advanceLeaveConfig.pageSize != null) {
      this.pageSize = this.advanceLeaveConfig.pageSize;
    }

    this.headerThOption = [
      {
        id: 1,
        name: "Name",
        code: "name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 10,
        name: "Designation",
        code: "designation",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 11,
        name: "Department",
        code: "department",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 12,
        name: "Reporting Head",
        code: "reporting_head",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 13,
        name: "HOD",
        code: "hod",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Date of Application",
        code: "date_of_application",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Start Date",
        code: "start_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "End Date",
        code: "end_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 9,
        name: "Leave Count",
        code: "leave_count",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Leave Type",
        code: "leave_type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Reason of Leave",
        code: "reason_of_leave",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 8,
        name: "Approval Status",
        code: "approval_status",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
    ];

    //this.getLeaveLsit();
  }

  ngOnChanges() {
    if (this.advanceLeaveConfig.dateRange != null) {
      this.dateRange = this.advanceLeaveConfig.dateRange;
    }

    if (this.advanceLeaveConfig.leaveStartDate != '' && this.advanceLeaveConfig.leaveEndDate != '') {
      this.leaveStartDate = this.advanceLeaveConfig.leaveStartDate;
      this.leaveEndDate = this.advanceLeaveConfig.leaveEndDate;
    }

    if (this.advanceLeaveConfig.approveTypeCheckbox.length > 0) {
      this.approveTypeCheckbox = this.advanceLeaveConfig.approveTypeCheckbox;
    }

    if (this.advanceLeaveConfig.approveCheckList.length > 0) {
      this.approveCheckList = this.advanceLeaveConfig.approveCheckList;
    }

    if (this.advanceLeaveConfig.reportTypeCheckbox.length > 0) {
      this.reportTypeCheckbox = this.advanceLeaveConfig.reportTypeCheckbox;
    }

    if(this.advanceLeaveConfig.leaveType) {
      this.leaveTypesData = this.advanceLeaveConfig.leaveType.split(',');
      let checkList = this.advanceLeaveConfig.leaveCheckbox.split(',');
      checkList.forEach((x,i) => {
        if((/true/i).test(x)){
          this.leaveTypeCheckList[i] = (/true/i).test(x)
        }
      });
    } 
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    if (this.advanceLeaveConfig.defaultPagination != null) {
      this.defaultPagination = this.advanceLeaveConfig.defaultPagination;
    }
    if (this.advanceLeaveConfig.itemPerPage != null) {
      this.itemPerPage = this.advanceLeaveConfig.itemPerPage;
    }
    if (this.advanceLeaveConfig.pageSize != null) {
      this.pageSize = this.advanceLeaveConfig.pageSize;
    }
    this.dataFilter();
  }

  ngAfterViewInit() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    if (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }

  getLeaveLsit() {
    //console.log(this.employeeId)
    var userid = this.localService.getJsonValue('userid');

    this.loading = LoadingState.Processing;
    let param: URLSearchParams = new URLSearchParams();
    param.set('page', this.defaultPagination.toString());
    param.set('team_approval_flag', '1');

    if (this.employeeId != undefined) {
      param.set('users', this.employeeId.toString());
    }
    if (this.search_key != '') {
      param.set('search', this.search_key.toString());
    }
    if (this.leaveTypesData.length > 0) {
      param.set('leave_type', this.leaveTypesData.toString());
    }
    if (this.approveTypeCheckbox.length > 0) {
      param.set('approved_type', this.approveTypeCheckbox.toString());
    }
    if (this.leaveStartDate != '') {
      param.set('from_date', this.leaveStartDate.toString());
    }
    if (this.leaveEndDate != '') {
      param.set('to_date', this.leaveEndDate.toString());
    }
    if (this.sort_by != '') {
      param.set('field_name', this.sort_by.toString());
    }
    if (this.itemPerPage) {
      param.set('page_count', this.itemPerPage.toString());
    }
    if (this.sort_type != '') {
      param.set('order_by', this.sort_type.toString());
    }

    param.set('attendance_type', 'PMS');

    this.attendanceService.getAdvanceLeaveReport(param).subscribe(
      res => {
        this.totalLeaveReport = res['count'];
        this.leaveReport = res['results'];

        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalLeaveReport > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination

        }
        else {
          this.upper_count = this.totalLeaveReport
        }
        this.loading = LoadingState.Ready;

      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
      }
    )
  }

  exportReport() {
    //console.log(this.employeeId)
    var userid = this.localService.getJsonValue('userid');

    this.loading = LoadingState.Processing;
    let param: URLSearchParams = new URLSearchParams();
    param.set('team_approval_flag', '1');

    if (this.employeeId != undefined) {
      param.set('users', this.employeeId.toString());
    }
    if (this.search_key != '') {
      param.set('search', this.search_key.toString());
    }
    if (this.leaveTypesData.length > 0) {
      param.set('leave_type', this.leaveTypesData.toString());
    }
    if (this.approveTypeCheckbox.length > 0) {
      param.set('approved_type', this.approveTypeCheckbox.toString());
    }
    if (this.leaveStartDate != '') {
      param.set('from_date', this.leaveStartDate.toString());
    }
    if (this.leaveEndDate != '') {
      param.set('to_date', this.leaveEndDate.toString());
    }
    if (this.sort_by != '') {
      param.set('field_name', this.sort_by.toString());
    }

    if (this.sort_type != '') {
      param.set('order_by', this.sort_type.toString());
    }

    param.set('attendance_type', 'PMS');

    this.attendanceService.exportAdvanceLeaveReport(param).subscribe(
      res => {
        //console.log(res)
        var downloadURL = res['url']
        if (downloadURL != null) {
          window.open(downloadURL, '_blank')
        }
        this.loading = LoadingState.Ready;

      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Internal Server error !', '', {
          timeOut: 3000,
        });
      }
    )
  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.advanceLeaveConfig.defaultPagination = this.defaultPagination;
    this.getLeaveLsit();
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.getLeaveLsit();
  };

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.advanceLeaveConfig.approveTypeCheckbox = this.approveTypeCheckbox;
    this.advanceLeaveConfig.approveCheckList = this.approveCheckList;
    this.advanceLeaveConfig.reportTypeCheckbox = this.reportTypeCheckbox;
    this.defaultPagination = 1;
    this.advanceLeaveConfig.defaultPagination = this.defaultPagination;
    this.pageSize = this.itemPerPage;
    this.advanceLeaveConfig.pageSize = +this.itemPerPage;
    this.getLeaveLsit();

  }

  reload() {
    this.loading = LoadingState.Processing;
    this.reportTypeCheckbox = [];
    this.dateRange = null;
    this.advanceLeaveConfig.dateRange = this.dateRange;
    this.leaveStartDate = '';
    this.advanceLeaveConfig.leaveStartDate = this.leaveStartDate;
    this.leaveEndDate = '';
    this.advanceLeaveConfig.leaveEndDate = this.leaveEndDate;
    this.approveTypeCheckbox = [];
    this.advanceLeaveConfig.approveTypeCheckbox = this.approveTypeCheckbox;
    this.approveCheckList = [];
    this.advanceLeaveConfig.approveCheckList = this.approveCheckList;
    this.advanceLeaveConfig.leaveType = null;
    this.advanceLeaveConfig.leaveCheckbox = null;
    this.employeeId = undefined;
    this.sort_type = '';
    this.sort_by = '';
    this.itemPerPage = Globals.itemPerPage;
    this.advanceLeaveConfig.itemPerPage = this.itemPerPage;
    this.search_key = '';

    this.pageSize = Globals.pageSize;
    this.advanceLeaveConfig.pageSize = this.pageSize;
    this.advanceLeaveConfig.reportTypeCheckbox = this.reportTypeCheckbox;
    this.getLeaveLsit();
    //this.loading = LoadingState.Ready;

  }

  getSearchValueFromParent(val: any) {
    this.search_key = val;
    if (this.search_key != '') {
      this.dataFilter();
    }
  }

  approveType(index, e) {
    if (e.checked == true) {
      if (this.approveTypeCheckbox.indexOf(e.source.value) == -1) {
        this.approveTypeCheckbox.push(e.source.value);
      }
      this.approveCheckList[index] = true;
    }
    else {
      this.approveTypeCheckbox.splice(this.approveTypeCheckbox.indexOf(e.source.value), 1);
      this.approveCheckList[index] = false;
    }
    this.dataFilter();
  }

  leaveFilter(index, e) {
    if (e.checked == true) {
      if (this.leaveTypesData.indexOf(e.source.value) == -1) {
        this.leaveTypesData.push(e.source.value);
      }
      this.leaveTypeCheckList[index] = true;
    }
    else {
      this.leaveTypesData.splice(this.leaveTypesData.indexOf(e.source.value), 1);
      this.leaveTypeCheckList[index] = false;
    }
    this.advanceLeaveConfig.leaveType = this.leaveTypesData.toString();
    this.advanceLeaveConfig.leaveCheckbox = this.leaveTypeCheckList.toString();
    this.dataFilter();
  }

  dateTimeReset() {
    this.dateRange = null;
    this.advanceLeaveConfig.dateRange = this.dateRange;
    this.leaveStartDate = '';
    this.advanceLeaveConfig.leaveStartDate = this.leaveStartDate;
    this.leaveEndDate = '';
    this.advanceLeaveConfig.leaveEndDate = this.leaveEndDate;
    this.dataFilter()
  }
  formatHandler(event) {
    if (event.value[0] != null) {
      this.leaveStartDate = this.settingService.formatDate(event.value[0]);
      this.advanceLeaveConfig.leaveStartDate = this.leaveStartDate;
      this.leaveEndDate = this.settingService.formatDate(event.value[1]);
      this.advanceLeaveConfig.leaveEndDate = this.leaveEndDate;
      this.advanceLeaveConfig.dateRange = this.dateRange;
      this.dataFilter()
    }
    else {
      this.reload()
    }

  }

  viewDocument(items: any) {
    window.open(items, '', 'height=700,width=800,scrollbars=yes');
  }

  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }


  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.advanceLeaveConfig.itemPerPage = +item;
    this.dataFilter();
  }

  getConfigData(data) {
    this.headerThOption = data
  }

  ShowColunm(column_code) {
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if (index != -1) {
      if (this.headerThOption[index]['selected']) {
        return true;
      }
      else {
        return false;
      }
    }
  }

}
