import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../services/setting.service';
import { LocalService } from '../../services/local.service';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  isSidebar: boolean;
  navItems: any = [];
  @Input() isOpen: boolean;
  is_superuser;
  permissionLevel;
  settingsVisible: number = 0;
  projectVisible: number = 0;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private settingService: SettingService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
    this.isSidebar = true;

    this.is_superuser = this.localService.getJsonValue('is_superuser');

    if(this.is_superuser)
    {
      this.permissionLevel = 3
    }
    else
    {
      this.permissionLevel = 0;
    }

    if(this.settingService.havePermission('Project Site','') || this.settingService.havePermission('Machinery Working Category','') || this.settingService.havePermission('Machinery Type','') || this.settingService.havePermission('Material','') || this.settingService.havePermission('Vendor','') || this.settingService.havePermission('Contractor','') || this.settingService.havePermission('Operator','') || this.settingService.havePermission('Crusher','')|| this.settingService.havePermission('Partners','') || this.settingService.havePermission('Requisition Type','') || this.settingService.havePermission('Payment Terms','') || this.settingService.havePermission('Activities','') || this.settingService.havePermission('Transport Cost','') || this.settingService.havePermission('Stock Issue Mode',''))
    {
      this.settingsVisible = 1; 
    }

    if(this.settingService.havePermission('Project','') && this.settingService.havePermission('Project List','Project'))
    {
      this.projectVisible = 1; 
    }
  
    this.navItems = [
      {
        name: 'Dashboard',
        link: 'dashboard',
        icon: 'fa fa-tachometer',
        isSubMenu: false,
        havePermission:true,
        img: ''
      },
      // {
      //   name: 'Objects',
      //   link: 'objects',
      //   icon: 'fa fa-cube',
      //   isSubMenu: false,
      //   havePermission: this.settingService.havePermission('Objects',''),
      // },
      //{
      //  name: 'Tender',
      //  link: 'tender',
      //  icon: 'fa fa-gavel',
      //  isSubMenu: false,
      //  havePermission: this.settingService.havePermission('Tender',''),
      //},
      {
       name: 'Project',
       link: 'project',
       icon: 'fa fa-building-o',
       isSubMenu: false,
       havePermission: this.projectVisible,
       img:""
      },
      {
        name: 'Attendance',
        link: 'attendance',
        icon: 'fa fa-calendar',
        isSubMenu: false,
        havePermission: this.settingService.havePermission('Attendance',''),
        img: ''
      },
      {
       name: 'Manpower',
       link: 'manpower',
       icon: 'fa fa-id-card-o',
       isSubMenu: false,
       havePermission: this.settingService.havePermission('Manpower',''),
       img:''
      },
      //{
      //  name: 'Machinery',
      //  link: 'machinery',
      //  icon: null,
      //  isSubMenu: false,
      //  havePermission: this.settingService.havePermission('Machinery',''),
      //  img:'assets/img/machinery_new.png'
      //},
      //{
      //  name: 'Insurance',
      //  link: 'insurance',
      //  icon: null,
      //  isSubMenu: false,
      //  havePermission: this.settingService.havePermission('Insurance',''),
      //  img:'assets/img/insurance_new.png'
      //},
      {
       name: 'Settings',
       link: '',
       icon: 'fa fa-cog',
       isSubMenu: true,
       havePermission: this.settingsVisible,
       items: [
        //  {
        //    name: 'User Application Permission',
        //    link: 'user-application-permission',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('User Application Permission',''),
        //  },
         {
           name: 'Project Site',
           link: 'project-site',
           icon: 'fa fa-wpexplorer',
           isSubMenu: false,
           havePermission: this.settingService.havePermission('Project Site',''),
         },
        //  {
        //    name: 'Machinery Working Category',
        //    link: 'machinery-working-category',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Machinery Working Category',''),
        //  },
        //  {
        //    name: 'Machinery Type',
        //    link: 'machinery-type',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Machinery Type',''),
        //  },
        //  {
        //    name: 'Material',
        //    link: 'material',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Material',''),
        //  },
        //  {
        //    name: 'Vendor',
        //    link: 'vendor',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Vendor',''),
        //  },
        //  {
        //    name: 'Contractor',
        //    link: 'contractor',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Contractor',''),
        //  },
        //  {
        //    name: 'Operator',
        //    link: 'operator',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Operator',''),
        //  },
        //  {
        //    name: 'Crusher',
        //    link: 'crusher',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Crusher',''),
        //  },
        //  {
        //    name: 'Partners',
        //    link: 'partners',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Partners',''),
        //  },
        //  {
        //    name: 'Requisition Type',
        //    link: 'requisition-type',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Requisition Type',''),
        //  },
        //  {
        //    name: 'Payment Terms',
        //    link: 'payment-terms',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Payment Terms',''),
        //  },
        //  {
        //    name: 'BOQ',
        //    link: 'boq',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('BOQ',''),
        //  },
        //  {
        //    name: 'Activities',
        //    link: 'activities',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Activities',''),
        //  },
        //  {
        //    name: 'Transport Cost',
        //    link: 'transport-cost',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Transport Cost',''),
        //  },
        //  {
        //    name: 'Stock Issue Mode',
        //    link: 'stock-issue-mode',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Stock Issue Mode',''),
        //  },
        //  {
        //    name: 'MR Approval Permission',
        //    link: 'approval-permission',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.permissionLevel,
        //  },
        //  {
        //    name: 'Assets',
        //    link: 'assets',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Assets',''),
        //  },
        //  {
        //    name: 'Spares (Material)',
        //    link: 'spares',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Spares (Material)',''),
        //  },
        //  {
        //    name: 'Consumables',
        //    link: 'consumables',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Consumables',''),
        //  },
        //  {
        //    name: 'Fuel and Lubricants',
        //    link: 'fuelandlubricants',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Fuel and Lubricants',''),
        //  },
        //  {
        //    name: 'Electrical Items',
        //    link: 'electricalitems',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Electrical Items',''),
        //  },
        //  {
        //    name: 'General Tools',
        //    link: 'generaltools',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('General Tools',''),
        //  },
        //  {
        //    name: 'Safety',
        //    link: 'safety',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Safety',''),
        //  },
        //  {
        //    name: 'Daily Expense Approver',
        //    link: 'daily-expense-approver',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Daily Expense Approver','Daily Expense'),
        //  },
        //  {
        //    name: 'Tour & Travel Approver',
        //    link: 'tour-and-travel-approver',
        //    icon: 'fa fa-gavel',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Tour & Travel Approver','Tours and Travel'),
        //  },
        //  {
        //    name: 'Bill Invoice Category',
        //    link: 'bill-invoice-category',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Bill Invoice Category','Bills & Invoices'),
        //  },
        //  {
        //    name: 'Expense Head',
        //    link: 'expense-head',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Expense Head',''),
        //  },
        //  {
        //    name: 'Batching Plant',
        //    link: 'batching-plant-master',
        //    icon: 'fa fa-wpexplorer',
        //    isSubMenu: false,
        //    havePermission: this.settingService.havePermission('Batching Plant',''),
        //  },
          
          
       ]
      }
    ]
  }

  sideBarToggle() {
    this.isSidebar = !this.isSidebar;
  }

  menuTap(item) {
    console.log(item)
    if (item.isSubMenu) {
      item['isOpen'] = !item['isOpen'];
    }
    else {
      this.router.navigateByUrl('/' + item.link);
    }

  }

  noPermission(){

    this.toastr.error("Sorry! You don't have any permission to access this section.","",{
      timeOut: 3000,
      });
  }

}
