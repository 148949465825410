import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-site-mobilization-furniture-inner-tab',
  templateUrl: './site-mobilization-furniture-inner-tab.component.html',
  styleUrls: ['./site-mobilization-furniture-inner-tab.component.scss']
})
export class SiteMobilizationFurnitureInnerTabComponent implements OnInit {
  @Input() projectId:number;
  @Input() tabId:number;
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  furnitureTypeList: any = [];
  furnitureDetails: any =[];
  furnitureDocumentList:any =[];
  furnitureTypeDetails:any = [];
  listEdit: boolean;
  isEdit:boolean;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  approveStatus:any = [];


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      project:[this.projectId],
      guest_house_type:[null,Validators.required],
      transporation_cost:[null,Validators.required],
      furniture_requirements:this.formBuilder.array([
       this.createFurnitureRequirements()
      ])

    })
    this.getFurnitureDetail();
    this.getStatus();
  }

  createFurnitureRequirements(){
   return this.formBuilder.group({
      type:[null,Validators.required],
      count:[null,Validators.required],
      local_rate:[null,Validators.required],
      document_name:[null,Validators.required],
      document:[null,Validators.required],
      furniture_document:null
    })
  }

  deleteFurnitureRequirements(index:number)
  {
    const control = <FormArray>this.form.controls['furniture_requirements'];
    control.removeAt(index);
  }

 

  getFormArrayControl(form) {
    return form.get('furniture_requirements').controls
  }

  addFurniture()
  {
    const control = <FormArray>this.form.controls['furniture_requirements'];
    control.push(this.createFurnitureRequirements());
  }

  onSelectFile(event, index){
  //  console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['furniture_requirements'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          furniture_document: event.target.files[0]
          
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  submit()
  {
   if(this.form.valid){
    this.loading = LoadingState.Processing
      // console.log(this.form.value);

      var furniture_requirements_data = [];
      furniture_requirements_data = this.form.value.furniture_requirements;
      const data = {
        project:this.projectId,
        guest_house_type:this.form.value.guest_house_type,
        transporation_cost:this.form.value.transporation_cost
      }

      this.furnitureDocumentList = [];
      this.perExecutionService.addfurnitureSiteMobilization(data).subscribe(
        res =>{
         // console.log(res);
          var forkArray = [];

          furniture_requirements_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("f_requirements", res.id);
              formData.append("type", x.type);
              formData.append("count", x.count);
              formData.append("local_rate", x.local_rate);
              formData.append("document_name", x.document_name);
              formData.append("document", x.furniture_document, x.furniture_document['name']);
              forkArray.push(this.perExecutionService.addfurnitureRequirementSiteMobilization(formData))

          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.furnitureDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
          //console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

  getFurnitureDetail(){
    this.perExecutionService.getfurnitureSiteMobilization(this.projectId).subscribe(
      res=> {
        //console.log(res);
        if(res['result']){
          this.furnitureDetails = res['result'];
           //console.log(this.furnitureDetails);
            this.furnitureDetails.transporation_cost = parseFloat(this.furnitureDetails.transporation_cost).toFixed(2);
            this.getFurnitureTypeDetail();
           }
         this.loading = LoadingState.Ready;
      },
      error=>{
        //console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        //console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }

  getFurnitureTypeDetail(){
    this.perExecutionService.getfurnitureTypeSiteMobilization(this.projectId).subscribe(
      res=> {
        this.furnitureTypeList = res['result'];
      //  console.log(this.furnitureTypeList);
        this.loading = LoadingState.Ready;
      },
      error=>{
        //console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  edittFurniture(data){
    this.furnitureTypeDetails = data;
   // console.log(this.furnitureTypeDetails);
   
    this.listEdit = true;
    this.getFurnitureTypeDetail(); 
    this.isEdit= false;
  }

  deleteFurniture(id){

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deletefurnitureTypeSiteMobilization(id).subscribe(
      res => {
       this.getFurnitureTypeDetail();
        // console.log(res)
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
	 })
    
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.isEdit= true
    //this.furnitureDocumentList[index]['isFieldError'] = false
  }

  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  renameDoc(documentData) {
    
    const updateDoc = {
      document_name:documentData.document_name
    }
      this.perExecutionService.editfurnitureTypeDocumentSiteMobilization(documentData.id, updateDoc).subscribe(
        res => {
          // console.log(res)
         this.isEdit = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    
  }

  update(){
      if(this.form.valid){
        this.loading = LoadingState.Processing
        // console.log(this.form.value);
  
        var furniture_requirements_data = [];
        furniture_requirements_data = this.form.value.furniture_requirements;
        const data = {
          project:this.projectId,
          guest_house_type:this.form.value.guest_house_type,
          transporation_cost:this.form.value.transporation_cost
        }

        this.furnitureDocumentList = [];
        this.perExecutionService.editfurnitureSiteMobilization(this.furnitureDetails.id,data).subscribe(
        res =>{
         // console.log(res);
          var forkArray = [];

          furniture_requirements_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("f_requirements", res.id);
              formData.append("type", x.type);
              formData.append("count", x.count);
              formData.append("local_rate", x.local_rate);
              if(x.furniture_document){
                formData.append("document_name", x.document_name);
                formData.append("document", x.furniture_document, x.furniture_document['name']);
              }
              else{
                formData.append("document_name", null);
                formData.append("document", null);
              }
             
              forkArray.push(this.perExecutionService.addfurnitureRequirementSiteMobilization(formData))

          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.furnitureDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.getFurnitureTypeDetail();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
     
      }
      else{
        this.markFormGroupTouched(this.form);
      }
  }

  updateFurnitureType()
  {
    if(this.form.valid){
      this.loading = LoadingState.Processing;

      const furnitureData = {
        project:this.projectId,
        guest_house_type:this.form.value.guest_house_type,
        transporation_cost:this.form.value.transporation_cost
      }
      console.log(this.form.value);

      

      this.perExecutionService.editfurnitureSiteMobilization(this.furnitureDetails.id,furnitureData).subscribe(
        res =>{
          //console.log(res);
          if(res){
            const furnitureTypeData = {
              f_requirements:res.id,
              type:this.form.value.furniture_requirements[0].type,
              count:this.form.value.furniture_requirements[0].count,
              local_rate:this.form.value.furniture_requirements[0].local_rate
             }

             this.perExecutionService.editfurnitureTypeSiteMobilization(this.furnitureTypeDetails.id,furnitureTypeData).subscribe(
               res=>{
               // console.log(res);
               },
               error =>{
                 //console.log(error);
               }
             )
            
          }
          this.getFurnitureTypeDetail();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error => {
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
