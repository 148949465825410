import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { LoadingState } from '../../../components/loading/loading.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit {

  @Input() currentmaintab: string;
  @Input() date: any;
  @Input() sweekstartdate: any;
  @Input() sweekenddate: any;
  @Input() smonthdate: any;
  @Input() projectid: number;
  @Input() siteid: number;
  public loading: LoadingState = LoadingState.NotReady;
  materialParam: any = '';
  reportarr: any = [];
  @Output() getDownloadLink: EventEmitter<number> = new EventEmitter<number>();
  
  constructor(
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    // console.log(this.currentmaintab)
    this.getProgressReports()
  }

  getProgressReports() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'daily') {

      if (this.date) {
        let date = this.settingService.getProperDate(this.date)
        this.materialParam = 'date=' + date.toISOString().substr(0, 10);//aa
      }
      else {
        this.materialParam = '';
      }

    }
    else if (this.currentmaintab == 'weekly') {
      if (this.sweekstartdate) {
        let sdate = this.sweekstartdate;
        let edate = this.sweekenddate;
        this.materialParam = 'start_date=' + sdate + '&end_date=' + edate;//aa //aa
      }
      else {
        this.materialParam = '';
      }

    }
    else if (this.currentmaintab == 'monthly') {
      if (this.smonthdate) {
        let date = this.settingService.getOwlProperDate(this.smonthdate);
        // console.log(date);
        let sdate = new Date(date.getFullYear(), date.getMonth(), 1);
        let edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
        sdate = this.settingService.getOwlProperDate(sdate);
        edate = this.settingService.getOwlProperDate(edate);
        this.materialParam = 'start_date=' + sdate.toISOString().substr(0, 10) + '&end_date=' + edate.toISOString().substr(0, 10);//aa //aa
      }
      else {
        this.materialParam = '';
      }

    }

    // console.log(this.progressparam)

    if (this.projectid && this.siteid && this.materialParam) {

      this.reportService.getMaterialReportData(this.projectid, this.siteid, this.materialParam).subscribe(
        data => {
          var res: any = data['results'];
          this.reportarr = [];
          this.reportarr = res.details
          this.getDownloadLink.emit(res.url)
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready;
          console.log(error)
        }
      );
    }
    else {

      this.loading = LoadingState.Ready;

    }

  }

  excelDownload() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'daily') {
      if (this.date) {
        let date = this.settingService.getProperDate(this.date);
        this.materialParam = 'is_download=yes&date=' + date.toISOString().substr(0, 10);//aa
      }
      else {
        this.materialParam = '';
      }
    } else if (this.currentmaintab == 'weekly') {
      if (this.sweekstartdate) {
        let sdate = this.sweekstartdate;
        let edate = this.sweekenddate;
        this.materialParam = 'is_download=yes&start_date=' + sdate + '&end_date=' + edate
      }
      else {
        this.materialParam = '';
      }
    } else if (this.currentmaintab == 'monthly') {
      if (this.smonthdate) {
        let date = this.settingService.getOwlProperDate(this.smonthdate);
        // console.log(date);
        let sdate = new Date(date.getFullYear(), date.getMonth(), 1);
        let edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
        sdate = this.settingService.getOwlProperDate(sdate);
        edate = this.settingService.getOwlProperDate(edate);
        this.materialParam = 'is_download=yes&start_date=' + sdate.toISOString().substr(0, 10) + '&end_date=' + edate.toISOString().substr(0, 10);//aa //aa
      }
      else {
        this.materialParam = '';
      }
    }
    if (this.projectid && this.siteid && this.materialParam) {
      this.reportService.getMaterialReportData(this.projectid, this.siteid, this.materialParam).subscribe(
        data => {
          window.open(data.url, '_blank');
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready;
          console.log(error)
        }
      );
    }
    else {
      this.loading = LoadingState.Ready;
    }
  }

}
