import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-leave-record',
  templateUrl: './leave-record.component.html',
  styleUrls: ['./leave-record.component.scss']
})
export class LeaveRecordComponent implements OnInit {

  pieChartLegend = false;
  pieChartOptions = {
    responsive: true
  }

  pieChartLabels = ['Logged In', 'Total'];
  pieChartColor: any = [
    {
      backgroundColor: ['rgba(42,63,84,0.9)',
        'rgba(51, 181, 229, 0.8)'
      ],
      borderWidth: 0,
    }
  ]

  @Input() firstPieChartData: any = [
    {
      data: []
    }
  ];

  @Input() secondPieChartData: any = [
    {
      data: []
    }
  ];

  @Input() flexiLeaves: any = {};
  salayTypeCode: string;
  constructor() { }

  ngOnInit() {
    //console.log(this.flexiLeaves)
    this.salayTypeCode = this.flexiLeaves['salary_type_code']
   // console.log(this.secondPieChartData)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['flexiLeaves']['currentValue']){
      this.flexiLeaves = changes['flexiLeaves']['currentValue']
      this.firstPieChartData = changes['firstPieChartData']['currentValue']
      this.secondPieChartData = changes['secondPieChartData']['currentValue']
      this.salayTypeCode = this.flexiLeaves['salary_type_code']
    }
    
  }
}
