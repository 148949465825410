import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cost-analysis-inner-tab',
  templateUrl: './cost-analysis-inner-tab.component.html',
  styleUrls: ['./cost-analysis-inner-tab.component.scss']
})
export class CostAnalysisInnerTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() analysisType:number; 
  @Input() approveStatus:any = [];
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  keyword = 'name';
  data:any = [];
  costAnalysisDocumentList:any = [];
  costAnalysisDetail:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
		public dialog: MatDialog,
    private perExecutionService:PerExecutionService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project: [this.projectId],
      analysis_type:[this.analysisType],
      document_name: [null, Validators.required],
      document: [null, Validators.required],
      analysis_document: null

    })

    if(this.projectId && this.analysisType){
      this.getExistDocuments();
    }
  }

  save(){
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var d = new Date();
      
      const formData: any = new FormData();
        formData.append("project",this.projectId);
        formData.append("analysis_type",this.analysisType);
        formData.append("document_name", this.form.value.document_name);
        formData.append("document", this.form.value.analysis_document, this.form.value.analysis_document['name']);
        formData.append("date", d.toISOString());//aa

      this.perExecutionService.addDocumentCostAnalysis(formData).subscribe(
        res=>{
         // console.log(res);
         this.getExistDocuments();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
            });
        },
        error => {
          //console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
            });
        }
      )
     
    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  getExistDocuments(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getDocumentCostAnalysis(this.projectId,this.analysisType).subscribe(
      res=>{
        //console.log(res);
          this.costAnalysisDetail = res;

      if(this.costAnalysisDetail){
        this.costAnalysisDocumentList = this.costAnalysisDetail['result'];
        this.costAnalysisDetail['result'].forEach(x => {
          this.data.push(x.document_name)
        });
      }
        this.loading = LoadingState.Ready;
      },
      error =>{
       // console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }



  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  onSelectFile(event){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
         // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        this.form.patchValue({
          document: event.target.files[0]['name'],
          analysis_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  selectEvent(item) {
   
  }
 
  onChangeSearch(val: string) {
    
  }
  
  onFocused(e){
    
  }
  
}
