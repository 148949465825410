import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { SettingService } from '../../../../core/services/setting.service';
import { BillInvoiceService } from '../../../../core/services/bill-invoice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-new-bill',
  templateUrl: './add-new-bill.component.html',
  styleUrls: ['./add-new-bill.component.scss']
})
export class AddNewBillComponent implements OnInit {
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  selectedDocument: boolean;
  projectList: any = [];
  category: number;
  constructor(
    public dialogRef: MatDialogRef<AddNewBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private billInvoiceService: BillInvoiceService,
    private toastr: ToastrService
  ) {
    console.log(data)
    this.projectList = data['projectList']
    this.category = data['category']
   }

  ngOnInit() {

    this.form = this.formBuilder.group({
      project: [null, Validators.required],
      category: [null],
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      file: null,
      remarks: null,

    });
    this.loading = LoadingState.Ready
  }

  selectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {

        }
        this.form.patchValue({
          document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
       
        this.selectedDocument = true;
      }


    }
  }
  save() {
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      const data: any = new FormData();      
      data.append("project ", this.form.value.project);
      data.append("category ", this.category);
      data.append("document_name ", this.form.value.document_name);      
      data.append("document ", this.form.value.document);
      data.append("remarks ", this.form.value.remarks);
      this.billInvoiceService.addBillInvoiceFile(data).subscribe(
        res => {
          this.loading = LoadingState.Ready
          this.toastr.success('New file has been added', '', {
            timeOut: 3000,
          });
          this.dialogRef.close(true)
        },
        error => {
          // console.log(error);
          this.dialogRef.close(false)
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
