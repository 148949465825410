import { trigger, state, style, animate, transition, animation } from '@angular/animations';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

export let fadeInOut = trigger('fadeInOut',[
    state('void',style(
      {
        opacity:0,
        transform: 'perspective(400px) rotateX(90deg)',
        
    })),
    transition(':enter',[
      animate('1s ease-out')
    ]),
    transition(':leave',[
      style({transform: 'translateX(0%)',opacity:.2}),
      animate('0.5s ease-out', style({transform: 'translateX(100%)'}))
    ])
    
  ])

 