import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'app-site-mobilization-tab',
  templateUrl: './site-mobilization-tab.component.html',
  styleUrls: ['./site-mobilization-tab.component.scss']
})
export class SiteMobilizationTabComponent implements OnInit {

  @Input() projectId:number;
  @Input() tabId:number;

  constructor() { }

  ngOnInit() {
    //console.log(this.projectId);
  }


  


}
