import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { SettingService} from '../../../services/setting.service'
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tender-status-tab',
  templateUrl: './tender-status-tab.component.html',
  styleUrls: ['./tender-status-tab.component.scss']
})
export class TenderStatusTabComponent implements OnInit {
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  loading: LoadingState = LoadingState.NotReady;
  awardedForm: FormGroup;
  nonAwardedForm: FormGroup;
  tenderStatusDetails: any = {
    is_awarded: true
  };
  tenderBasicDetails: any;
  participantList: any = [];
  comparisonDataList: any = [];
  tenderFormView: boolean;
  currentPosition: string;
  tenderExtraDocumentList: any = [];
  constructor(
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
    private router: Router,
    public settingService:SettingService,
  ) { }



  ngOnInit() {
    this.awardedForm = this.formBuilder.group({
      date_of_awarding: [null, Validators.required],
      loi_issued_on: [null, Validators.required],
      documents: this.formBuilder.array([]),
    });
    this.nonAwardedForm = this.formBuilder.group({
      position: ['', Validators.required],
      reason: ['', Validators.required],
      percentage_of_preference: ['', Validators.required],
      documents: this.formBuilder.array([]),
    });
    // this.loading = LoadingState.Ready;
    this.loadData();
  }

  calculatePos() {
    var index = this.participantList.findIndex(x => x.field_label == "Quoted Price")
    var posIndex = this.participantList.findIndex(x => x.field_label == "Position")
    var nameIndex = this.participantList.findIndex(x => x.field_label == "Name")
    var quotedData = [];
    quotedData = Object.assign([], this.participantList[index]['field_value']);
    for (var i = 0; i < this.participantList[index]['field_value'].length; i++) {
      var min = Math.min(...quotedData)
      var minIndex = this.participantList[index]['field_value'].findIndex(y => y == min)
      this.participantList[posIndex]['field_value'][minIndex] = "L" + (i + 1)
      var valIndex = quotedData.findIndex(z => z == min)
      quotedData.splice(valIndex, 1)
    }
    // 
    var l1QuotedVal = Math.min(...this.participantList[index]['field_value'])
    //console.log(l1QuotedVal)
    // 
    var currentCompanyIndex = this.participantList[nameIndex]['field_value'].findIndex(n => n.toLowerCase() == this.tenderBasicDetails.site_location_details.company_name.toLowerCase())
    if (currentCompanyIndex != -1) {
      this.currentPosition = this.participantList[posIndex]['field_value'][currentCompanyIndex]
     // console.log(this.currentPosition)
      var currentCompanyQuotedVal = this.participantList[index]['field_value'][currentCompanyIndex]
    //  console.log(currentCompanyQuotedVal)
      if (this.currentPosition == "L1") {
        this.tenderStatusDetails.is_awarded = true
      }
      else {
        var diff = currentCompanyQuotedVal - l1QuotedVal
       // console.log(diff)
        var percentagePreference = diff / l1QuotedVal
       // console.log(percentagePreference)
        this.nonAwardedForm.patchValue({
          percentage_of_preference: percentagePreference
        })
        this.tenderStatusDetails.is_awarded = false
      }
      this.nonAwardedForm.patchValue({
        position: this.currentPosition
      })
      this.tenderFormView = true
    }
  }

  // awardedForm
  create_document_row() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document_file: [null, Validators.required],
      extra_document: null
    });
  }

  add_document_row() {
    const control = <FormArray>this.awardedForm.controls['documents'];
    control.push(this.create_document_row());
  }

  delete_document_row(index: number) {
    const control = <FormArray>this.awardedForm.controls['documents'];
    control.removeAt(index);
  }

  getDocumentFormArrayControl(form) {
    return form.get('documents').controls
  }

  documentOnSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.awardedForm.controls['documents'];
        control.at(index).patchValue({
          document_file: event.target.files[0]['name'],
          extra_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }



  // nonAwardedForm
  create_non_awarded_document_row() {
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document_file: [null, Validators.required],
      extra_document: null
    });
  }

  add_non_awarded_document_row() {
    const control = <FormArray>this.nonAwardedForm.controls['documents'];
    control.push(this.create_non_awarded_document_row());
  }

  delete_non_awarded_document_row(index: number) {
    const control = <FormArray>this.nonAwardedForm.controls['documents'];
    control.removeAt(index);
  }

  getNonAwardedDocumentFormArrayControl(form) {
    return form.get('documents').controls
  }

  nonAwardedDocumentOnSelectFile(event, index) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.nonAwardedForm.controls['documents'];
        control.at(index).patchValue({
          document_file: event.target.files[0]['name'],
          extra_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  // 

  loadData() {
    var forkArray = [];
    forkArray.push(this.tenderService.getTenderBasicDetails(this.tenderId))
    forkArray.push(this.tenderService.getTenderStatusDetails(this.tenderId))
    forkJoin(forkArray).subscribe(
      ([basicDetails,statusDetails]) => {
       // console.log(basicDetails)
        this.tenderBasicDetails = basicDetails;
       // console.log(statusDetails)
        if (statusDetails['result'] != "" && statusDetails['result'] != null) {
          this.tenderStatusDetails = statusDetails['result'];
          this.participantList = this.tenderStatusDetails['participents_field_label_value'];
          this.comparisonDataList = this.tenderStatusDetails['comparison_field_label_value'];
          this.tenderExtraDocumentList = this.tenderStatusDetails['document_details'];
          if (this.tenderStatusDetails['date_of_awarding'] != null && this.tenderStatusDetails['loi_issued_on'] != null) {
            var date_of_awarding = new Date(this.tenderStatusDetails['date_of_awarding'])
            var loi_issued_on = new Date(this.tenderStatusDetails['loi_issued_on'])
            this.awardedForm.patchValue({
              date_of_awarding: {
                year: date_of_awarding.getFullYear(),
                month: date_of_awarding.getMonth() + 1,
                day: date_of_awarding.getDate()
              },
              loi_issued_on: {
                year: loi_issued_on.getFullYear(),
                month: loi_issued_on.getMonth() + 1,
                day: loi_issued_on.getDate()
              }
            })
          }
          this.nonAwardedForm.patchValue({
            position: this.tenderStatusDetails['position'],
            reason: this.tenderStatusDetails['reason'],
            percentage_of_preference: this.tenderStatusDetails['percentage_of_preference']
          })
          this.tenderFormView = true

        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  onSelectParticipantFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }

        const formData: any = new FormData();
        formData.append("tender", this.tenderId);
        formData.append("participents_document", event.target.files[0], event.target.files[0]['name']);
        this.loading = LoadingState.Processing
        this.tenderService.uploadTenderParticipent(formData).subscribe(
          res => {
           // console.log(res)
            this.participantList = res['participents_field_label_value']
            this.calculatePos()
            this.loading = LoadingState.Ready
          },
          error => {
            console.log(error)
            this.loading = LoadingState.Ready
          }
        )
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSelectComparisonChartFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }

        const formData: any = new FormData();
        formData.append("tender", this.tenderId);
        formData.append("comparison_document", event.target.files[0], event.target.files[0]['name']);
        this.loading = LoadingState.Processing
        this.tenderService.uploadTenderComparison(formData).subscribe(
          res => {
           // console.log(res)
            this.comparisonDataList = res['comparison_field_label_value']
            this.loading = LoadingState.Ready
          },
          error => {
            console.log(error)
            this.loading = LoadingState.Ready
          }
        )

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


  saveAwardedData() {
    //console.log(this.awardedForm.value)
    if (this.awardedForm.valid) {
      var documents_data = [];
      documents_data = this.awardedForm.value.documents;
      
      var date_of_awarding = this.settingService.getProperDate(this.awardedForm.value.date_of_awarding)
      // 
      var loi_issued_on = this.settingService.getProperDate(this.awardedForm.value.loi_issued_on)
      var data = {
        tender: this.tenderId,
        is_awarded: this.tenderStatusDetails.is_awarded,
        date_of_awarding: date_of_awarding.toISOString(),//aa
        loi_issued_on: loi_issued_on.toISOString(),//aa
        participents_field_label_value: this.participantList,
        comparison_field_label_value: this.comparisonDataList,
      }
      this.loading = LoadingState.Processing
      this.tenderService.addTenderStatus(this.tenderId, data).subscribe(
        res => {
         // console.log(res)
          this.router.navigate(['/project/edit-project/'+res.project_id+'/']);
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      )
      if (documents_data.length > 0) {
        var forkArray = [];
        documents_data.forEach(x => {
          const formData: any = new FormData();
          formData.append("tender", this.tenderId);
          formData.append("document_name", x.document_name);
          formData.append("document", x.extra_document, x.extra_document['name']);
          forkArray.push(this.tenderService.addTenderExtraDoc(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.tenderExtraDocumentList.push(y)
            })
            for (var k = documents_data.length - 1; k >= 0; k--) {
              this.delete_document_row(k)
            }
          },
          err => {
            // console.log(err)
          }
        )
      }

    }
    else {
      this.markFormGroupTouched(this.awardedForm)
    }
  }


  saveNonAwardedData() {
    //console.log(this.nonAwardedForm.value)
    if (this.nonAwardedForm.valid) {
      var documents_data = [];
      documents_data = this.nonAwardedForm.value.documents;
      var data = {
        tender: this.tenderId,
        is_awarded: this.tenderStatusDetails.is_awarded,
        position: this.nonAwardedForm.value.position,
        reason: this.nonAwardedForm.value.reason,
        percentage_of_preference: this.nonAwardedForm.value.percentage_of_preference,
        participents_field_label_value: this.participantList,
        comparison_field_label_value: this.comparisonDataList,
      }
      this.loading = LoadingState.Processing
      this.tenderService.addTenderStatus(this.tenderId, data).subscribe(
        res => {
        //  console.log(res)
          
          this.router.navigate(['/project/edit-project/'+res.project_id+'/']);
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      )
      if (documents_data.length > 0) {
        var forkArray = [];
        documents_data.forEach(x => {
          const formData: any = new FormData();
          formData.append("tender", this.tenderId);
          formData.append("document_name", x.document_name);
          formData.append("document", x.extra_document, x.extra_document['name']);
          forkArray.push(this.tenderService.addTenderExtraDoc(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.tenderExtraDocumentList.push(y)
            })
            for (var k = documents_data.length - 1; k >= 0; k--) {
              this.delete_non_awarded_document_row(k)
            }
          },
          err => {
            // console.log(err)
          }
        )
      }
    }
    else {
      this.markFormGroupTouched(this.nonAwardedForm)
    }
  }

  deleteDoc(i: number) {
    this.loading = LoadingState.Processing
    this.tenderService.deleteTenderExtraDoc(this.tenderExtraDocumentList[i]['id']).subscribe(
      res => {
       // console.log(res)
        this.tenderExtraDocumentList.splice(i, 1)
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  getStyleClass(val) {
    return {
      'own': this.tenderBasicDetails.site_location_details.company_name == val
    }
  }

}
