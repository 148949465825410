import { Component, OnInit,Input} from '@angular/core';
import {PerExecutionService} from '../../../services/per-execution.service';
import { LoadingState } from '../../loading/loading.component';

@Component({
  selector: 'app-manpower-tab',
  templateUrl: './manpower-tab.component.html',
  styleUrls: ['./manpower-tab.component.scss']
})
export class ManpowerTabComponent implements OnInit {

  @Input() projectId:number; 
  @Input() tabId:number; 
  loading: LoadingState = LoadingState.NotReady;
  masterData:any = [];
  approveStatus:any = [];

  constructor(
    private perExecutionService:PerExecutionService
  ) { }

  ngOnInit() {
    //console.log(this.projectId);
    if(this.projectId){
      this.getMasterData();
      this.getStatus();
    }
  }

  getMasterData(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getMasterManpower().subscribe(
      res => {
        this.masterData = res['result'];
        //console.log(this.masterData);
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        //console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }

}
