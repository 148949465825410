import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../services/setting.service';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-comparative-statement',
  templateUrl: './comparative-statement.component.html',
  styleUrls: ['./comparative-statement.component.scss']
})
export class ComparativeStatementComponent implements OnInit {
  @Input() requisitionId:number;
  @Input() vendorList:any =[];
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  quotationItemList:any = [];
  disabledField: number;
  requisitionApprovalStatus:any = [];
  btnShow:boolean = false;
  checkStatus:any;
  viewSelection: any;

  @Output() checkStatusEvent = new EventEmitter<string>();


  constructor(
    private formBuilder: FormBuilder,
    private settingService:SettingService,
    private executionPurchaseService:ExecutionPurchaseService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.viewSelection = 'material';
    if(this.requisitionId){
      this.getQuotationItem();
    }
    this.disabledField = 0;
  }

  checkEventStatus(event){
  this.checkStatusEvent.next();
  }
  
  getQuotationItem(){
    
    var forkArray = [];
    forkArray.push(this.executionPurchaseService.getrequisitionSubmitForApproval(this.requisitionId))
    forkArray.push(this.executionPurchaseService.getPurchaseItemQuotation(this.requisitionId))
    forkJoin(forkArray).subscribe(
      ([checkStatus,quotationItemList]) => {
    
          if(checkStatus){
            this.checkStatus = checkStatus['result'];
            if(this.checkStatus.status == 3){
              this.btnShow = true
            }else{
              this.btnShow = false
            }
          }
          if(quotationItemList){
            this.quotationItemList = quotationItemList['result']['Item_Approved_List'];
          //  console.log(this.quotationItemList)
          }
     
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
      
      }
    )

  

  }

  submitForApproval(){
    this.loading = LoadingState.Processing;
    var data = {
      status:4
    }

    this.executionPurchaseService.updateSubmitForApproval(this.requisitionId,data).subscribe(
      res => {
        this.requisitionApprovalStatus = res;
        this.checkStatusEvent.next();
        this.btnShow = false;
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been Approved','',{
          timeOut: 3000,
        });
      },
      error=>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
  
}
