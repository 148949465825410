import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { TenderService } from '../../../services/tender.service';
import { forkJoin } from 'rxjs';
import { LoadingState } from '../../loading/loading.component';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-eligibility-tab',
  templateUrl: './eligibility-tab.component.html',
  styleUrls: ['./eligibility-tab.component.scss']
})
export class EligibilityTabComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  @Input() tenderId: number;
  @Output() valueChange = new EventEmitter();
  technicalEligibilityList: any = [];
  financialEligibilityList: any = [];
  specialEligibilityList: any = [];
  
  visibleKey: boolean;
  addTenderConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  activeTab: any = 'technical_eligibility';

  constructor(
    private tenderService: TenderService
  ) {
    this.addTenderConfig = AppPageStateConfig.addTenderConfig.eligibilityConfig;
    this.activeTab = this.addTenderConfig.tab;
  }

  ngOnInit() {
    //console.log(this.tenderId)
    //this.setDefaultData();
    this.getTenderEligibilityFieldsList()
  }

  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
  }

  switchNgBTab(id: string) {
   // console.log(id)
    this.ctdTabset.select(id);
  }

  getTenderEligibilityFieldsList() {
    var forkArray = [];
    forkArray.push(this.tenderService.getTenderEligibilityFieldsList(this.tenderId, 'technical'))
    forkArray.push(this.tenderService.getTenderEligibilityFieldsList(this.tenderId, 'financial'))
    forkArray.push(this.tenderService.getTenderEligibilityFieldsList(this.tenderId, 'special'))
    forkJoin(forkArray).subscribe(
      (result: any[]) => {
        
        for (var i = 0; i < result.length; i++) {
          if (i == 0) {
            if (result[i]['result'].length > 0) {
              this.technicalEligibilityList = [];
              result[i]['result'].forEach(x => {
                
                var d = {
                  field_label: x['field_label'],
                  eligible: x['eligible'],
                  field_value: x['field_value']
                }
                this.technicalEligibilityList.push(d)
              })
             
            }
          }
          if (i == 1) {
            if (result[i]['result'].length > 0) {
              this.financialEligibilityList = [];
              result[i]['result'].forEach(x => {
                var d = {
                  field_label: x['field_label'],
                  eligible: x['eligible'],
                  field_value: x['field_value']
                }
                this.financialEligibilityList.push(d)
              })
            }
          }
          if (i == 2) {
            if (result[i]['result'].length > 0) {
              this.specialEligibilityList = [];
              result[i]['result'].forEach(x => {
                var d = {
                  field_label: x['field_label'],
                  eligible: x['eligible'],
                  field_value: x['field_value']
                }
                this.specialEligibilityList.push(d)
              })
            }
          }
        }
        this.visibleKey = true;
      
        this.setDefaultData()
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }

  setDefaultData() {

    if(this.technicalEligibilityList.length==0)
    {
      this.technicalEligibilityList = [
        {
          field_label: 'construction work experience',
          eligible: null,
          field_value: ''
        },
        {
          field_label: 'key work experience',
          eligible: null,
          field_value: ''
        },
        {
          field_label: 'similar nature of work',
          eligible: null,
          field_value: ''
        },
        {
          field_label: 'machinery list',
          eligible: null,
          field_value: ''
        }
      ]
    }
    if(this.financialEligibilityList.length==0)
    {
      
      this.financialEligibilityList = [
        {
          field_label: 'bid capacity',
          eligible: null,
          field_value: ''
        },
        {
          field_label: 'turn over',
          eligible: null,
          field_value: ''
        },
        {
          field_label: 'key work value(%)',
          eligible: null,
          field_value: ''
        },
        {
          field_label: 'net worth',
          eligible: null,
          field_value: ''
        }
      ]
    }
  }

  getTechnicalTabValue(data) {
    
    
    this.technicalEligibilityList = data;
  }

  getFinancialTabValue(data) {
   // console.log(data)
    this.financialEligibilityList = data;
  }

  getSpecialTabValue(data) {
    //console.log(data)
    this.specialEligibilityList = data;
  }

  onTabChange(event) {
    //console.log(event)
    this.activeTab = event.nextId;
   // this.addTenderConfig.tab = this.activeTab
   
  }

}
