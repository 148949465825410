import { Component, OnInit, Input } from '@angular/core';
import { LoadingState } from '../loading/loading.component';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {

  @Input() type;
  loading: LoadingState = LoadingState.NotReady;

  constructor() { }

  ngOnInit() {
  }

  dataSearch() {
    this.loading = LoadingState.NotReady;
    
  }
}
