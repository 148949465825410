import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { LoadingState } from '../../../components/loading/loading.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() currentmaintab: string;
  @Input() date: any;
  @Input() smonthdate: any;
  @Input() projectid: number;
  @Input() siteid: number;
  locationName: string;
  boq: string;
  machinary: string
  approx_quantity_of_work_done_till_last_day: number;
  labour_details: any = [];
  public loading: LoadingState = LoadingState.NotReady;
  progressparam: any = '';
  reportarr: any = [];
  @Output() getDownloadLink: EventEmitter<number> = new EventEmitter<number>();
  constructor(
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    // console.log(this.currentmaintab)
    this.getProgressReports()
  }

  getProgressReports() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'daily') {

      if (this.date) {
        let date = this.settingService.getProperDate(this.date)
        this.progressparam = 'date=' + date.toISOString().substr(0, 10);//aa
      }
      else {
        this.progressparam = '';
      }

    }
    else if (this.currentmaintab == 'monthly') {
      if (this.smonthdate) {
        let date = this.settingService.getOwlProperDate(this.smonthdate);
        // console.log(date);
        let sdate = new Date(date.getFullYear(), date.getMonth(), 1);
        let edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
        sdate = this.settingService.getOwlProperDate(sdate);
        edate = this.settingService.getOwlProperDate(edate);
        this.progressparam = 'start_date=' + sdate.toISOString().substr(0, 10) + '&end_date=' + edate.toISOString().substr(0, 10);//aa //aa
      }
      else {
        this.progressparam = '';
      }

    }

    if (this.projectid && this.siteid && this.progressparam) {
      if(this.currentmaintab == 'daily' || this.currentmaintab == 'monthly') {
        this.reportService.getProgressReportData(this.projectid, this.siteid, this.progressparam).subscribe(
          data => {
            var res: any = data['results'];
            this.reportarr = [];
            this.reportarr = res.execution_daily_pro
            this.locationName = res.site_location_name;
            this.boq = res.boq;
            this.approx_quantity_of_work_done_till_last_day = res.approx_quantity_of_work_done_till_last_day;
            this.machinary = res.machinary_details;
            this.labour_details = res.labour_details;
            this.getDownloadLink.emit(res.url)
            this.loading = LoadingState.Ready;
          },
          error => {
            this.loading = LoadingState.Ready;
            console.log(error)
          }
        );
      } else if(this.currentmaintab == 'weekly') {
        this.reportService.getProgressWeeklyReportData(this.progressparam).subscribe(
          data => {
            var res: any = data['results'];
            this.reportarr = [];
            this.reportarr = res.execution_daily_pro
            this.locationName = res.site_location_name;
            this.boq = res.boq;
            this.approx_quantity_of_work_done_till_last_day = res.approx_quantity_of_work_done_till_last_day;
            this.machinary = res.machinary_details;
            this.labour_details = res.labour_details;
            this.loading = LoadingState.Ready;
          },
          error => {
            this.loading = LoadingState.Ready;
            console.log(error)
          }
        );
      }
      
    }
    else {

      this.loading = LoadingState.Ready;

    }

  }

  excelDownload() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'daily') {
      if (this.date) {
        let date = this.settingService.getProperDate(this.date);
        this.progressparam = 'is_download=yes&date=' + date.toISOString().substr(0, 10);//aa
      }
      else {
        this.progressparam = '';
      }
    } else if (this.currentmaintab == 'monthly') {
      if (this.smonthdate) {
        let date = this.settingService.getOwlProperDate(this.smonthdate);
        // console.log(date);
        let sdate = new Date(date.getFullYear(), date.getMonth(), 1);
        let edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
        sdate = this.settingService.getOwlProperDate(sdate);
        edate = this.settingService.getOwlProperDate(edate);
        this.progressparam = 'is_download=yes&start_date=' + sdate.toISOString().substr(0, 10) + '&end_date=' + edate.toISOString().substr(0, 10);//aa //aa
      }
      else {
        this.progressparam = '';
      }
    }
    if (this.projectid && this.siteid && this.progressparam) {
      if(this.currentmaintab === 'daily' || this.currentmaintab === 'monthly') {
        this.reportService.getProgressReportData(this.projectid, this.siteid, this.progressparam).subscribe(
          data => {
            window.open(data.url, '_blank');
            this.loading = LoadingState.Ready;
          },
          error => {
            this.loading = LoadingState.Ready;
            console.log(error)
          }
        );
      }
    }
    else {
      this.loading = LoadingState.Ready;
    }
  }

}
