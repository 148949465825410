import { Component, OnInit, Input, ViewChild} from '@angular/core';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-resource-tab',
  templateUrl: './resource-tab.component.html',
  styleUrls: ['./resource-tab.component.scss']
})
export class ResourceTabComponent implements OnInit {
  @Input() tenderId: number;
  addTenderConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  activeTab: any = 'material';

  constructor() {
    this.addTenderConfig = AppPageStateConfig.addTenderConfig.surveyConfig;
    this.activeTab = this.addTenderConfig.resourceConfig.tab;
   }

  ngOnInit() {
   // console.log(this.tenderId)
  }


  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
  }

  switchNgBTab(id: string) {
    //console.log(id)
    this.ctdTabset.select(id);
  }

  onTabChange(event) {
   // console.log(event)
    this.activeTab = event.nextId;
  //  this.addTenderConfig.resourceConfig.tab = this.activeTab
   
  }
}
