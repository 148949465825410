import { Component, OnInit,Input } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { SettingService } from '../../../../core/services/setting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import {AttendanceService} from '../../../../core/services/attendance.service';
import {LocalService} from '../../../../core/services/local.service';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employee-attendance',
  templateUrl: './employee-attendance.component.html',
  styleUrls: ['./employee-attendance.component.scss']
})
export class EmployeeAttendanceComponent implements OnInit {

  @Input() employeeId: any;

  loading: LoadingState = LoadingState.NotReady;
  zoom: number = 15;
  lat:number;
  lng:number;
  siteLat:number;
  siteLng:number;
  officeLat:number;
  officeLng:number;
  radius:number;
  markers: marker[] =[];
  attendanceTrackerData:any = [];
  employeeDetails:any = [];
  moduleId: number;
  long_lat_details:any = [];
  gestHouseLat:number;
  gestHouseLng:number;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  starTime = '';
  endTime='';
  selectedId:number;
  logList = [];
  totalLogList: any = [];
  selectedItem: any = [];
  allChecked: boolean;
  projectSiteLat:number;
  projectSiteLng:number;
  currentDate:any;
  deviation_details:any = [];
  attendanceId:number;
  headerThOption:any = [];
  attendanceData:any = [];
  maxDate:any = [];

  constructor(
    private settingService: SettingService,
    private router: Router,
    private route: ActivatedRoute,
    private attendanceService:AttendanceService,
    private location: Location,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private localService: LocalService,
  ) { 
    var date = new Date()
    this.currentDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
    this.maxDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
  }

  ngOnInit() {
    this.siteLat = 22.5726;
    this.siteLng = 88.3639;
    this.moduleId = +this.localService.getJsonValue('currentModuleId');
    if(this.employeeId!=null){
    //  this.getMarkerLog();
      this.getLogDetail();
    }else{
      this.loading = LoadingState.Ready;
    }
   

    this.headerThOption = [
      {  
        id:1,
        name: "Check In",
        code: "check_in",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      },
      {  
        id:2,
        name: "Check Out",
        code: "check_out",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      } ,
      {  
        id:3,
        name: "Deviation",
        code: "deviation",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      },
      {  
        id:4,
        name: "Leave Type",
        code: "leave_type",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      },
      {  
        id:5,
        name: "Justification",
        code: "justification",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      },
      {  
        id:6,
        name:"Approval Status",
        code: "approval_status",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      },
      {  
        id:7,
        name:"Distance From Site",
        code: "distance_From_site",
        sort_type:'',
        has_tooltip:false,
        tooltip_msg:'',
        selected: true
      }
      
    ];

    
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  
  clickedMarker(label: string, index: number) {
    //console.log(`clicked the marker: ${label || index}`)
  }
  
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    //console.log('dragEnd', m, $event);
  }

  getLogDetail()
  {
    this.loading = LoadingState.Processing;
    let params: URLSearchParams = new URLSearchParams();
    params.set('employee',this.employeeId);
    params.set('date',this.currentDate.year+"-"+this.currentDate.month+"-"+this.currentDate.day);

    
    this.attendanceService.getAttendanceMapowerTrackReport(params).subscribe(
      res=>{
     
        this.attendanceTrackerData = res['results'];
        if(this.attendanceTrackerData.length>0){
          this.attendanceData = this.attendanceTrackerData[0];
          this.deviation_details = this.attendanceTrackerData[0].deviation_details; 
          this.attendanceId = this.attendanceTrackerData[0].id;
          if(this.attendanceId){
            this.getMarkerLog();
          }
          if(this.attendanceTrackerData[0].user_project.id){
            this.siteLng = parseFloat(this.attendanceTrackerData[0].user_project.site_location.site_longitude);
            this.siteLat = parseFloat(this.attendanceTrackerData[0].user_project.site_location.site_latitude);
  
            this.projectSiteLng = parseFloat(this.attendanceTrackerData[0].user_project.site_location.site_longitude);
            this.projectSiteLat = parseFloat(this.attendanceTrackerData[0].user_project.site_location.site_latitude);
  
            this.officeLng = parseFloat(this.attendanceTrackerData[0].user_project.site_location.office_longitude);
            this.officeLat = parseFloat(this.attendanceTrackerData[0].user_project.site_location.office_latitude);
  
            this.gestHouseLat=parseFloat(this.attendanceTrackerData[0].user_project.site_location.gest_house_latitude);
            this.gestHouseLng=parseFloat(this.attendanceTrackerData[0].user_project.site_location.gest_house_longitude);
  
            this.long_lat_details = this.attendanceTrackerData[0].user_project.long_lat_details;
           
            this.radius = parseInt(this.attendanceTrackerData[0].user_project.site_location.geo_fencing_area);
          }
          
        }else{
          this.attendanceData = [];
          this.logList = [];
          this.markers = [];
          this.deviation_details = [];
          this.selectedId = null;
        }
       // this.employeeDetails = this.attendanceTrackerData.employee_details;

        

        this.loading = LoadingState.Ready;
      },
      error =>{
       // console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }


  getMarkerLog(){
    this.loading = LoadingState.Processing;
    let params: URLSearchParams = new URLSearchParams();

    params.set('attandance', this.attendanceId.toString());
    if(this.starTime!=''){
      params.set('start_time', decodeURI(this.starTime.toString()));
    }
    if(this.endTime!=''){
      params.set('end_time', decodeURI(this.endTime.toString()));
    }

    this.attendanceService.getAttendanceMarker(params).subscribe(
      res =>{
        this.markers = [];
        if(res['results'])
              {
                this.logList = res['results'];
                if(this.totalLogList.length == 0){
                  this.totalLogList = Object.assign([],res['results'])
                }                
                 res['results'].forEach(element => {
                  this.markers.push({
                    lat: parseFloat(element.latitude),
                    lng: parseFloat(element.longitude),
                    label:'',
                    lb:element.address,
                    draggable: false
                  });

                
                });
                
              }
        this.loading = LoadingState.Ready;
      },
      error=>{
        //console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  getTrakerByDeviation(log:any){
    this.starTime     = log.from_time;
    this.endTime      = log.to_time;
    this.selectedId   = log.id;
    this.getMarkerLog();

  }

  reload(){
    this.loading = LoadingState.Processing;
    this.starTime = '';
    this.endTime  = '';
    this.selectedId = null;
    this.getMarkerLog();
    
  }

  getHighestDistance(id:number){ //Bubai: get deviations lat long list
    if(Object.keys(this.attendanceTrackerData[0].user_project).length > 0){
      var deviationDetail = this.attendanceTrackerData[0].deviation_details.filter(x=> x.id == id)[0];
      var latLng = this.totalLogList.filter(x=> x.time >= deviationDetail.from_time && x.time <= deviationDetail.to_time);
      var distanceArray = [];
      latLng.forEach(element => {
        let response = this.distance(this.attendanceTrackerData[0].user_project.site_location.site_latitude,this.attendanceTrackerData[0].user_project.site_location.site_longitude,element.latitude,element.longitude).toFixed(1);
        distanceArray.push(parseFloat(response))
      });
      if(distanceArray.length > 0){
        return Math.max(...distanceArray) + " KM"
      }
      else{
        return "0 KM"
      }

    }
    
  }


  distance(lat1, lon1, lat2, lon2) { //Bubai: Calculate distance between to place
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
            c(lat1 * p) * c(lat2 * p) * 
            (1 - c((lon2 - lon1) * p))/2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  scroll = (event: any): void => {
    // console.log('hii')
    if(this.selectedItem.length > 0){
      let prevScrollpos = 150;
      let currentScrollPos = window.pageYOffset;
     
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("bulkPunchApproval").classList.remove("fixedTotop");
        } else {
          document.getElementById("bulkPunchApproval").classList.add("fixedTotop");
        }
    }  
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  
  getConfigData(data){
    this.headerThOption = data
  }

  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }

  requireDate(event) {
    if (event != null) {
     var requireDate = new Date(event.year, event.month - 1, event.day);
      this.currentDate = {
        year: requireDate.getFullYear(),
        month: requireDate.getMonth() + 1,
        day: requireDate.getDate()
      }
      this.getLogDetail();
     }

  }

}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  lb?:string;
  draggable: boolean;
}