import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-office-survey-instrument-inner-tab',
  templateUrl: './office-survey-instrument-inner-tab.component.html',
  styleUrls: ['./office-survey-instrument-inner-tab.component.scss']
})
export class OfficeSurveyInstrumentInnerTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() approveStatus:any = [];
  constructor() { }

  ngOnInit() {
  }

}
