import { Component, OnInit, Input} from '@angular/core';
import { LoadingState } from '../../../loading/loading.component';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import * as Globals from '../../../../globals';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { SettingService } from '../../../../services/setting.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApprovalLevelBottomSheetComponent } from '../approval-level-bottom-sheet/approval-level-bottom-sheet.component';
import {AppPageStateConfig} from '../../../../../app.config';

@Component({
  selector: 'app-approvals-quotation',
  templateUrl: './approvals-quotation.component.html',
  styleUrls: ['./approvals-quotation.component.scss']
})
export class ApprovalsQuotationComponent implements OnInit {
  @Input() searchKeyword: string
  headerThOption = [];
 
  loading: LoadingState = LoadingState.NotReady;
  pendingRequisitionQuationList:any = [];
  totalPendingRequisitionQuationList:number;
  projectChecked = [];
  typeChecked:number;

  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList:any = [];
  pageSize:number;
  requisitionTypeList = []
  //filter
  fromDate = '';
  toDate = '';
  search_key = '';
  sort_by = '';
  sort_type = '';
  dateRange: any;
  quotationConfig:any;

  constructor(
    private executionPurchaseService: ExecutionPurchaseService,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private projectSiteServices: projectSiteServices,
    public dialog: MatDialog,
    private settingService: SettingService
  ) {
    this.quotationConfig = AppPageStateConfig.requisitionApprovalConfig.quotationConfig;
   }

  ngOnInit() {
    
    if (this.quotationConfig.fromDate != '') {
      this.fromDate = this.quotationConfig.fromDate;
    }
    if (this.quotationConfig.toDate != '') {
      this.toDate = this.quotationConfig.toDate;
    }
    if (this.quotationConfig.dateRange != null) {
      this.dateRange = this.quotationConfig.dateRange;
    }
    if (this.quotationConfig.projectChecked.length>0) {
      this.projectChecked = this.quotationConfig.projectChecked;
    }
    if (this.quotationConfig.typeChecked!=null) {
      this.typeChecked = this.quotationConfig.typeChecked;
    }
    this.headerThOption = [
      {
        id: 1,
        name: "Project ID",
        code: "project",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Site",
        code: "site_location",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Requirement Date",
        code: "mr_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "Type",
        code: "type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 5,
        name: "Comparitive Statment",
        code: "comparative_statement",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 6,
        name: "Item Detail",
        code: "item_detail",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
    ]
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    if (this.quotationConfig.defaultPagination != null) {
      this.defaultPagination = this.quotationConfig.defaultPagination;
    }
    if (this.quotationConfig.itemPerPage != null) {
      this.itemPerPage = this.quotationConfig.itemPerPage;
    }
    if (this.quotationConfig.pageSize != null) {
      this.pageSize = this.quotationConfig.pageSize;
    }
  }

  ngOnChanges() {
    if(this.searchKeyword != '' && this.searchKeyword != undefined){
      this.search_key = this.searchKeyword;
    }else{
      this.search_key = ''
    }
    this.getRequisitionType();
  }


  getConfigData(data){
    this.headerThOption = data
  }

  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }


  getRequisitionType(){
    this.loading = LoadingState.Processing
    this.executionPurchaseService.requisitionTypes().subscribe(
      res=>{
        this.requisitionTypeList = res['result'];
        this.getRequisitionQuationPendingList();
       
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getRequisitionQuationPendingList(){
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    params.set('section_name', 'comparative statement');
    params.set('page', this.defaultPagination.toString());

    if (this.fromDate != '') {
      params.set('start_date', this.fromDate.toString());
    }
    if (this.toDate != '') {
      params.set('end_date', this.toDate.toString());
    }
    if (this.itemPerPage) {
      params.set('page_count', this.itemPerPage.toString());
    }
    if (this.projectChecked.length > 0) {
      params.set('site_location', decodeURI(this.projectChecked.toString()));
    }
   
    if (this.typeChecked != undefined) {
      params.set('type_name', this.typeChecked.toString());
    }

    if (this.search_key != '') {
      params.set('search', this.search_key.toString());
    }
    if (this.sort_by != '') {
      params.set('field_name', this.sort_by.toString());
    }
    if (this.sort_type != '') {
      params.set('order_by', this.sort_type.toString());
    }

    this.executionPurchaseService.getPendingQuotationApprovalList(params).subscribe(
      res=>{
        this.totalPendingRequisitionQuationList = res.count;
        this.pendingRequisitionQuationList = res['results'];

        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalPendingRequisitionQuationList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
         
        }
        else {
          this.upper_count = this.totalPendingRequisitionQuationList;
        }
        this.loading = LoadingState.Ready;
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  redirect(id:number){
    const navigationExtras: NavigationExtras = {
      state: {
        activestate:'comparitive-statement'
      }
    };
    this.router.navigate(['execution-purchase/add-requisition/'+id],navigationExtras);
    //this.router.navigateByUrl('execution-purchase/add-requisition/'+id+'/comparitive-statement');
   
  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.getRequisitionQuationPendingList();
  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.quotationConfig.defaultPagination = this.defaultPagination;
    this.getRequisitionQuationPendingList();
  }

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.pageSize = this.itemPerPage;
    
    this.defaultPagination = 1;
    this.quotationConfig.typeChecked = this.typeChecked;
    this.quotationConfig.defaultPagination = this.defaultPagination;
    this.quotationConfig.pageSize = +this.itemPerPage;
    this.getRequisitionQuationPendingList();

  }

  reload() {
    this.loading = LoadingState.Processing;
    this.fromDate = '';
    this.toDate = '';
    this.sort_type = '';
    this.sort_by = '';
    this.search_key = '';
    this.projectChecked = [];
    this.typeChecked = null;
    this.itemPerPage = Globals.itemPerPage;
    
    this.pageSize =  Globals.pageSize;
    this.quotationConfig.dateRange = this.dateRange;
    this.quotationConfig.typeChecked = this.typeChecked;
    this.quotationConfig.fromDate = this.fromDate;
    this.quotationConfig.toDate = this.toDate;
    this.quotationConfig.defaultPagination = this.defaultPagination;
    this.quotationConfig.itemPerPage = this.itemPerPage;
    this.quotationConfig.projectChecked = this.projectChecked;
    this.getRequisitionQuationPendingList();

  }

  openBottomSheet(permission:any) {
    // this._bottomSheet.open(ApprovalLevelBottomSheetComponent,{
    //   data: {from:'quotaion',result:permission}
    // });
    let dialogRef = this.dialog.open(ApprovalLevelBottomSheetComponent, {
      // width: '525px',
      data: {from:'quotaion',result:permission}
    });
    dialogRef.afterClosed().subscribe(result => {
     
    })
  }

  dateTimeReset(){
    this.dateRange = null;
    this.fromDate = '';
    this.toDate = '';
   
    this.quotationConfig.fromDate = this.fromDate;
    this.quotationConfig.toDate = this.toDate;
    this.quotationConfig.dateRange = this.dateRange;
    this.dataFilter();
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.fromDate = this.settingService.formatDate(event.value[0])
      this.toDate = this.settingService.formatDate(event.value[1])
      this.quotationConfig.fromDate = this.fromDate;
      this.quotationConfig.toDate = this.toDate;
      this.quotationConfig.dateRange = this.dateRange;
      this.dataFilter()
    }
    else {
      this.reload()
    }

  }
  


   onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.quotationConfig.itemPerPage = +item;
    this.dataFilter();
  }

  onListProjectsSelectionChange(project): void {
     this.projectChecked = project;
     this.quotationConfig.projectChecked = this.projectChecked;
    this.dataFilter();
  }
}
