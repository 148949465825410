import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service'
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-connection-electrical-inner-tab',
  templateUrl: './connection-electrical-inner-tab.component.html',
  styleUrls: ['./connection-electrical-inner-tab.component.scss']
})
export class ConnectionElectricalInnerTabComponent implements OnInit {
  form: FormGroup;
  @Input() projectId:number;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  @ViewChild("placesRef", {static: true}) placesRef: GooglePlaceDirective;
  electricalDetails:any = [];
  loading: LoadingState = LoadingState.NotReady;
  electricalDocumentList:any = [];
  


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public settingService:SettingService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      
      connection_type:[null,Validators.required],
      option:[null,Validators.required],
      nearby_elec_off_address:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      contact_no:[null,Validators.required],
      detailed_procedure:[null,Validators.required],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
  
     })

     if(this.projectId){
      this.getElectricalDetails();
     }
  }

  handleAddressChange(address) {
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      nearby_elec_off_address: formattedAddress
    })
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      electrical_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);
  }

  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  save(){
    if(this.form.valid){

      var electrical_documents_data = [];
      electrical_documents_data = this.form.value.documents;
      console.log(this.form.value);

      const data = {
        project: this.projectId,
        
        connection_type: this.form.value.connection_type,
        option:this.form.value.option,
        nearby_elec_off_address: this.form.value.nearby_elec_off_address,
        contact_no: this.form.value.contact_no,
        detailed_procedure: this.form.value.detailed_procedure,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }

     

      
      this.electricalDocumentList = [];
      this.perExecutionService.addElectricalConnectionOffice(data).subscribe(
        res => {
          var forkArray = [];

          electrical_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.electrical_document, x.electrical_document['name']);
              forkArray.push(this.perExecutionService.addElectricalConnectionDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.electricalDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
          this.loading = LoadingState.Ready;
          this.getElectricalDetails();
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

      
    }else{
      this.markFormGroupTouched(this.form);
    }
  }


  getElectricalDetails(){
    this.perExecutionService.getElectricalConnectionOffice(this.projectId).subscribe(
      res=>{
        if(res['result'][0]){
          this.electricalDetails = res['result'][0];
          this.electricalDocumentList = res['result'][0].document_details;
         // console.log(this.electricalDetails);

          var start_date = new Date(this.electricalDetails['requirment_s_date'])
            this.electricalDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.electricalDetails['requirment_e_date'])
            this.electricalDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          this.form.patchValue({
            latitude: this.electricalDetails.latitude,
            longitude: this.electricalDetails.longitude
          })
          this.electricalDetails.budgeted_cost = parseFloat(this.electricalDetails.budgeted_cost).toFixed(2);
          this.electricalDetails.executed_cost = parseFloat(this.electricalDetails.executed_cost).toFixed(2);
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.electricalDocumentList[index]['isEdit'] = true
    this.electricalDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.electricalDocumentList[index]['document_name'] == '') {
      this.electricalDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.electricalDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.electricalDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editElectricalConnectionDocumentOffice(this.electricalDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.electricalDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteElectricalConnectionDocumentOffice(this.electricalDocumentList[index]['id']).subscribe(
      res => {
        this.electricalDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
	 }
	 this.dialogRef = null;
   })
   
    
  }

  update(){
    if(this.form.valid){
      //console.log(this.electricalDetails);
      var water_documents_data = [];
      water_documents_data = this.form.value.documents;

      if(this.form.value.documents.length > 0){
        var forkArray = [];
        water_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project",this.projectId);
              formData.append("module_id", this.electricalDetails.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.electrical_document, x.electrical_document['name']);
              forkArray.push(this.perExecutionService.addElectricalConnectionDocumentOffice(formData))
        })
        forkJoin(forkArray).subscribe(
          result => {
            // console.log(result)
            result.forEach(y => {
              this.electricalDocumentList.push(y)
            })
           
            // this.add_tender_document();
            this.loading = LoadingState.Ready;
          },
          err => {
            this.loading = LoadingState.Ready;
            // console.log(err)
          }
        )
        this.updateElectrical();
      }

      else{
        this.updateElectrical();
      }


    }
  }

  updateElectrical(){
    if(this.form.valid){
      this.loading = LoadingState.Processing

      const updateData = {
        project: this.projectId,
    
        connection_type: this.form.value.connection_type,
        option:this.form.value.option,
        nearby_elec_off_address: this.form.value.nearby_elec_off_address,
        contact_no: this.form.value.contact_no,
        detailed_procedure: this.form.value.detailed_procedure,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date: this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date: this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
      }
    //  console.log(updateData);
      this.perExecutionService.editElectricalConnectionOffice(this.electricalDetails.id,updateData).subscribe(
        res =>{
          //console.log(res)
            this.loading = LoadingState.Ready;
             
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error =>{
            this.loading = LoadingState.Ready;
          //console.log(error)
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }



  onSelectFile(event, index){
   // console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          electrical_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
