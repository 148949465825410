import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SettingService } from '../../../../core/services/setting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../../core/components/loading/loading.component';
import { PlantMachineryService } from '../../../../core/services/plant-machinery.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as Globals from '../../../../core/globals';
import { AppPageStateConfig } from '../../../../app.config';
import { projectSiteServices } from '../../../../core/services/project-sites.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-daily-consumption',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyConsumptionComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  machineryReportDataList: any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  @ViewChild('target', {static: true}) tableContent: ElementRef;

  scrollAmount: any;
  hasScroll: boolean;
  headerThOption = [];

  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList: any = [];
  pageSize: number;
  totalMachineryReportDataList: number;
  sort_by = '';
  sort_type = '';
  search_key = '';
  dieselConsumptionConfig: any;
  projectId: number;
  sitearr: any = [];
  projarr: any = [];
  siteId: number;
  currentDate: any;
  disabled: boolean = false;

  constructor(
    private settingService: SettingService,
    private router: Router,
    private route: ActivatedRoute,
    private plantMachineryService: PlantMachineryService,
    private location: Location,
    public dialog: MatDialog,
    private element: ElementRef,
    private toastr: ToastrService,
    private siteServices: projectSiteServices
  ) {
    this.dieselConsumptionConfig = AppPageStateConfig.machineryManagementConfig.dieselConsumptionConfig.dailyConfig;
    var date = new Date()
    this.currentDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
  }

  ngOnInit() {
    if (this.route.snapshot.params['siteLocation'] != undefined && this.route.snapshot.params['projectId'] != undefined) {
      this.siteId = parseInt(this.route.snapshot.params['siteLocation']);
      this.siteOnchange(this.siteId);
      this.projectId = parseInt(this.route.snapshot.params['projectId']);
      this.disabled = true;
      this.dieselConsumptionConfig.currentDate = null;
      this.dieselConsumptionConfig.projectId = null;
      this.dieselConsumptionConfig.siteId = null;
    } else {
      if (this.dieselConsumptionConfig.currentDate != null) {
        this.currentDate = this.dieselConsumptionConfig.currentDate;
      }
      if (this.dieselConsumptionConfig.projectId != null) {
        this.projectId = this.dieselConsumptionConfig.projectId;
      }
      if (this.dieselConsumptionConfig.siteId != null) {
        this.siteId = this.dieselConsumptionConfig.siteId;
        this.siteOnchange(this.dieselConsumptionConfig.siteId)
      }
      this.disabled = false;
    }


    this.headerThOption = [
      {
        id: 1,
        name: "SL No.",
        code: "equipment_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 3,
        name: "Equipment",
        code: "machine_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 4,
        name: "Diesel Consumption By Equipment",
        code: "diesel_consumption_by_equipment",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 5,
        name: "Opening Meter Reading",
        code: "opening_meter_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 6,
        name: "Closing Meter Reading",
        code: "closing_meter_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 16,
        name: "Opening Hours Reading",
        code: "opening_hr_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 17,
        name: "Closing Hours Reading",
        code: "closing_hr_reading",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 7,
        name: "Difference in Reading Hrs",
        code: "difference_Hrs",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 18,
        name: "Difference in Reading Km",
        code: "difference_kms",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 8,
        name: "Running KM/Hrs",
        code: "",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },

      {
        id: 10,
        name: "HSD Average",
        code: "hsd_average",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 11,
        name: "Standard Fuel Consumption",
        code: "standard_fuel_consumption",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 12,
        name: "Contractor Name",
        code: "",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },

      {
        id: 14,
        name: "Last EM Date",
        code: "last_em_maintenance_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },
      {
        id: 15,
        name: "Next EM Schedule",
        code: "next_em_maintenance_schedule",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: ''
      },

    ];

    this.loadData();

  }

  getScrollWidth() {
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    this.hasScroll = (this.scrollAmount.scrollWidth >= this.scrollAmount.offsetWidth) ? true : false;
  }
  loadData() {
    var forkArray = [];
    forkArray.push(this.siteServices.getProjectSitesList())
    forkJoin(forkArray).subscribe(
      ([projectSitaData]) => {
        this.sitearr = projectSitaData['result'];
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  siteOnchange(val) {
    if (val) {
      this.loading = LoadingState.Processing;
      this.siteServices.getProjectBySite(val).subscribe(
        data => {

          this.projarr = [];
          var res: any = data['result'];
          this.projarr = res;
          this.loading = LoadingState.Ready;
         
          if (this.route.snapshot.params['projectId'] == undefined) {
            this.projectId = this.projarr[0]['id'];
            this.dieselConsumptionConfig.siteId = val;
            this.dieselConsumptionConfig.projectId = this.projectId;
          }
          this.projectOnchange(this.projectId)
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready;
        }
      );

    }
  }

  projectOnchange(val) {
    if (val) {
      this.getMachineryReportDataList();
    }
  }

  onDateSelect($event) {
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.dieselConsumptionConfig.currentDate = $event;
    }
    if (this.projectId != undefined) {
      this.getMachineryReportDataList();
    }

  }


  getMachineryReportDataList() {

    this.loading = LoadingState.Processing;
    let params: URLSearchParams = new URLSearchParams();
    // params.set('page', this.defaultPagination.toString());
    params.set('date', this.currentDate['year'] + "-" + this.currentDate['month'] + "-" + this.currentDate['day']);
    // if (this.itemPerPage) {
    //   params.set('page_count', this.itemPerPage.toString());
    // }
    if (this.projectId != undefined && this.projectId != null) {
      params.set('project', this.projectId.toString());
    }

    //shorting
    if (this.sort_by != '') {
      params.set('field_name', this.sort_by.toString());
    }
    if (this.sort_type != '') {
      params.set('order_by', this.sort_type.toString());
    }
    // if (this.search_key != '') {
    //   params.set('search', this.search_key.toString());
    // }

    this.plantMachineryService.getMachineryReportDataListV2(params).subscribe(
      res => {
        // console.log(res);
        this.totalMachineryReportDataList = res['count'];
        this.machineryReportDataList = res['results'];
        this.getScrollWidth();
        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalMachineryReportDataList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination
        }
        else {
          this.upper_count = this.totalMachineryReportDataList;
        }

        this.loading = LoadingState.Ready
      },
      error => {
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready
      }
    )
  }

  excelDownload() {
    this.loading = LoadingState.Processing;
    let params: URLSearchParams = new URLSearchParams();
    params.set('date', this.currentDate['year'] + "-" + this.currentDate['month'] + "-" + this.currentDate['day']);
    if (this.projectId != undefined && this.projectId != null) {
      params.set('project', this.projectId.toString());
    }

    //shorting
    if (this.sort_by != '') {
      params.set('field_name', this.sort_by.toString());
    }
    if (this.sort_type != '') {
      params.set('order_by', this.sort_type.toString());
    }

    this.plantMachineryService.getMachineryReportDataListExcelDownloadV2(params).subscribe(
      res => {
        if (res['results']['url'] == null) {
          this.toastr.error('No data Found !', '', {
            timeOut: 3000,
          });
        } else {
          window.open(res['results']['url'], '_blank');
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )

  }


  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  // new code added

  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }



  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.defaultPagination = 1;
    this.getMachineryReportDataList();
  }


  redirect() {
    this.router.navigateByUrl('/machinery-report/add-machinery-data');
  }

}
