import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-utilities-cook-inner-tab',
  templateUrl: './utilities-cook-inner-tab.component.html',
  styleUrls: ['./utilities-cook-inner-tab.component.scss']
})
export class UtilitiesCookInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  cookDetail :any = [];
  approveStatus:any = [];

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    //console.log(this.projectId);

    this.form = this.formBuilder.group({
      project:[this.projectId],
      available:[null,Validators.required],
      cook_name:[null,Validators.required],
      cook_conatct_no:[null,Validators.required],
      chargs:[null,Validators.required],
      name_of_agency:[null,Validators.required],
      agency_contact_no:[null,Validators.required],
      agency_addr:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
     
    });
    if(this.projectId){
      this.getCookDetails();
      this.getStatus();
    }
    
  }

  save()
  {
    if(this.form.valid){
      this.loading = LoadingState.NotReady;
      var data = {
        project: this.projectId,
        available: this.form.value.available,
        cook_name: this.form.value.cook_name,
        cook_conatct_no: this.form.value.cook_conatct_no,
        chargs: this.form.value.chargs,
        name_of_agency: this.form.value.name_of_agency,
        agency_contact_no: this.form.value.agency_contact_no,
        agency_addr: this.form.value.agency_addr,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost
      }

      this.perExecutionService.addCookUtilitiesAminites(data).subscribe(
        res=> {
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error => {
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }else
    {
      this.markFormGroupTouched(this.form);
    }
  }

  getCookDetails(){
    this.loading = LoadingState.NotReady;
    this.perExecutionService.getCookUtilitiesAminites(this.projectId).subscribe(
      res => {
        if(res['result'][0]){
            //  console.log(res);
            this.cookDetail = res['result'][0];
           // console.log(this.cookDetail);
            var start_date = new Date(this.cookDetail['requirment_s_date'])
            this.cookDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.cookDetail['requirment_e_date'])
            this.cookDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }

            this.form.patchValue({
              latitude: this.cookDetail.latitude,
              longitude: this.cookDetail.longitude
            })

            this.cookDetail.chargs = parseFloat(this.cookDetail.chargs).toFixed(2);
            this.cookDetail.budgeted_cost = parseFloat(this.cookDetail.budgeted_cost).toFixed(2);
            this.cookDetail.executed_cost = parseFloat(this.cookDetail.executed_cost).toFixed(2);
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      }
    )
  }

  update(){
      if(this.form.valid){
        this.loading = LoadingState.NotReady;
        var data ={
          project: this.projectId,
          available: this.form.value.available,
          cook_name: this.form.value.cook_name,
          cook_conatct_no: this.form.value.cook_conatct_no,
          chargs: this.form.value.chargs,
          name_of_agency: this.form.value.name_of_agency,
          agency_contact_no: this.form.value.agency_contact_no,
          agency_addr: this.form.value.agency_addr,
          latitude: this.form.value.latitude,
          longitude: this.form.value.longitude,
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost: this.form.value.budgeted_cost,
          executed_cost: this.form.value.executed_cost
        }
       // console.log(this.form.value);
        this.perExecutionService.updateCookUtilitiesAminites(this.cookDetail.id,data).subscribe(
          res => {
           // console.log(res);

            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
            });
          },
          error=> {
           // console.log(error);
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        )
      }
      else{
        this.markFormGroupTouched(this.form);
      }
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
      //  console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }

  handleAddressChange(address){
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      agency_addr: formattedAddress
    })
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
