import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import {AttendanceService} from '../../../core/services/attendance.service';
import {LoadingState} from '../../../core/components/loading/loading.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-leave-approval-form',
  templateUrl: './leave-approval-form.component.html',
  styleUrls: ['./leave-approval-form.component.scss']
})
export class LeaveApprovalFormComponent implements OnInit {
  @Input() leaveData:any;
  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  isHide:boolean;

  constructor(
    private formBuilder: FormBuilder,
    private attendanceService:AttendanceService,
    private router:Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    
    this.form = this.formBuilder.group({
      approved_status:[null,Validators.required],
      justification:[null],
      leaveId:[this.leaveData.id]
     })
  }

  submit(id:number){
    this.loading = LoadingState.Processing;
    if(this.form.valid){
      var data = {
        approved_status: this.form.value.approved_status,
        justification:this.form.value.justification
      } 

      this.attendanceService.updateAdvanceLeave(id,data).subscribe(
        res=>{
          //console.log(res);
          if(res['result'].approved_status==2 || res['result'].approved_status==3){
            this.isHide = true;
          }
          
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error =>{
          //console.log(error);
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )


    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
