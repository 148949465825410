import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApplicationService {


  constructor(private http: HttpClient) {
  }

  applicationList() {
    return this.http.get(environment.apiEndpoint + 'add_application/');
  }

  addApplication(str) {
    return this.http.post(environment.apiEndpoint + 'add_applications/', str);
  }

  applicationPermissionList() {
    return this.http.get(environment.apiEndpoint + 'applications_list/');
  }

  editApplication(id, str) {
    return this.http.put(environment.apiEndpoint + 'edit_applications/' + id + '/', str);
  }

  getApplication(id) {
    return this.http.get(environment.apiEndpoint + 'edit_applications/' + id + '/');
  }
}
