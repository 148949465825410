import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule } from '@agm/core';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
// import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module';
import { ChartsModule } from 'ng2-charts'
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../../environments/environment';
// guard
import { AuthGuard } from './guards/auth.guard';

// service
import { ValidationService } from './services/validation.service';
import { TenderService } from './services/tender.service';
import { SettingService } from './services/setting.service';
import { projectSiteServices } from './services/project-sites.service';
import { ProjectServices } from './services/project.service';
import { AttendanceService } from './services/attendance.service';
import { PlantMachineryService } from './services/plant-machinery.service';
import { ManpowerService } from './services/manpower.service';
import { ObjectService } from './services/object.service';
import { ApplicationService } from './services/application.service';
import { UserService } from './services/user.service';
import {PerExecutionService} from './services/per-execution.service';
import {TravelAndTourService} from './services/travel-and-tour.service';
import {DailyExpenseService} from './services/daily-expense.service';
import {LiveTrackingService} from './services/live-tracking.service';
import {EmployeesService} from './services/employees.service';
import {BatchingPlantService} from './services/batching-plant.service';
import {InterceptorService} from './services/interceptor.service';
import {NotificationService} from './services/notification.service';
import {BillInvoiceService} from './services/bill-invoice.service';
import {ContractorsService} from './services/contractors.service';
import {InsuranceService} from './services/insurance.service';
import {StorageService} from './services/storage.service';
import {LocalService} from './services/local.service';

// directive
import { NumberDirective } from './directive/number.directive';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

//----------------Material----------------//
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperIntl, MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// component
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ErrorComponent } from './components/error/error.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { EligibilityTabComponent } from './components/tender-section/eligibility-tab/eligibility-tab.component';
import { BidderTypeTabComponent } from './components/tender-section/bidder-type-tab/bidder-type-tab.component';
import { SurveyDetailsTabComponent } from './components/tender-section/survey-details-tab/survey-details-tab.component';
import { InitialCostingTabComponent } from './components/tender-section/initial-costing-tab/initial-costing-tab.component';
import { DocumentsTabComponent } from './components/tender-section/documents-tab/documents-tab.component';
import { TenderStatusTabComponent } from './components/tender-section/tender-status-tab/tender-status-tab.component';
import { NewTenderTabComponent } from './components/tender-section/new-tender-tab/new-tender-tab.component';
import { AddCriteriaModalComponent } from './components/tender-section/add-criteria-modal/add-criteria-modal.component';
import { AddPartnerModalComponent } from './components/tender-section/add-partner-modal/add-partner-modal.component';
import { AddLocationModalComponent } from './components/tender-section/add-location-modal/add-location-modal.component';
import { AddPhotoModalComponent } from './components/tender-section/add-photo-modal/add-photo-modal.component';
import { AddSupplierModalComponent } from './components/tender-section/add-supplier-modal/add-supplier-modal.component';

import { AddMaterialModalComponent } from './components/tender-section/add-material-modal/add-material-modal.component';
import { AddEstablishmentModalComponent } from './components/tender-section/add-establishment-modal/add-establishment-modal.component';
import { AddDataFactorModalComponent } from './components/tender-section/add-data-factor-modal/add-data-factor-modal.component';
import { AddContractorModalComponent } from './components/tender-section/add-contractor-modal/add-contractor-modal.component';
import { AddPAndMModalComponent } from './components/tender-section/add-p-and-m-modal/add-p-and-m-modal.component';
import { AddDesignationModalComponent } from './components/tender-section/add-designation-modal/add-designation-modal.component';
import { SitePhotoTabComponent } from './components/tender-section/site-photo-tab/site-photo-tab.component';
import { CoordinateTabComponent } from './components/tender-section/coordinate-tab/coordinate-tab.component';
import { ResourceTabComponent } from './components/tender-section/resource-tab/resource-tab.component';
import { RawMaterialTabComponent } from './components/tender-section/raw-material-tab/raw-material-tab.component';
import { CrusherTabComponent } from './components/tender-section/crusher-tab/crusher-tab.component';
import { RawMaterialCrusherInnerTabComponent } from './components/tender-section/raw-material-crusher-inner-tab/raw-material-crusher-inner-tab.component';
import { RawMaterialCrusherInnerTabColComponent } from './components/tender-section/raw-material-crusher-inner-tab-col/raw-material-crusher-inner-tab-col.component';

import { ResourceMaterialTabComponent } from './components/tender-section/resource-material-tab/resource-material-tab.component';
import { ResourceEstablishmentTabComponent } from './components/tender-section/resource-establishment-tab/resource-establishment-tab.component';
import { ResourceHydrologicalTabComponent } from './components/tender-section/resource-hydrological-tab/resource-hydrological-tab.component';
import { ResourceContarctorVendorTabComponent } from './components/tender-section/resource-contarctor-vendor-tab/resource-contarctor-vendor-tab.component';
import { ResourceContactTabComponent } from './components/tender-section/resource-contact-tab/resource-contact-tab.component';

import { ResourceMaterialInnerTabComponent } from './components/tender-section/resource-material-inner-tab/resource-material-inner-tab.component';
import { AddResourceSupplierModalComponent } from './components/tender-section/add-resource-supplier-modal/add-resource-supplier-modal.component';


import { ResourceContractorsVendorsInnerContractorTabComponent } from './components/tender-section/resource-contractors-vendors-inner-contractor-tab/resource-contractors-vendors-inner-contractor-tab.component';
import { ResourceContractorsVendorsInnerPAndMTabComponent } from './components/tender-section/resource-contractors-vendors-inner-p-and-m-tab/resource-contractors-vendors-inner-p-and-m-tab.component';

import { ResourceContactInnerTabComponent } from './components/tender-section/resource-contact-inner-tab/resource-contact-inner-tab.component';
import { ResourceContactInnerTabColComponent } from './components/tender-section/resource-contact-inner-tab-col/resource-contact-inner-tab-col.component';

import { LoadingComponent } from './components/loading/loading.component';
import { EligibilityTechnicalInnerTabComponent } from './components/tender-section/eligibility-technical-inner-tab/eligibility-technical-inner-tab.component';
import { EligibilityFinancialInnerTabComponent } from './components/tender-section/eligibility-financial-inner-tab/eligibility-financial-inner-tab.component';
import { EligibilitySpecialInnerTabComponent } from './components/tender-section/eligibility-special-inner-tab/eligibility-special-inner-tab.component';
import { EligibilityInnerDataTabComponent } from './components/tender-section/eligibility-inner-data-tab/eligibility-inner-data-tab.component';
import { ListSearchComponent } from './components/list-search/list-search.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { ListTableComponent } from './components/list-table/list-table.component';
import { DocumentViewModalComponent } from './components/document-view-modal/document-view-modal.component';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { AddObjectModalComponent } from './components/object-section/add-object-modal/add-object-modal.component';
import { AddSubObjectModalComponent } from './components/object-section/add-sub-object-modal/add-sub-object-modal.component';
import {AttendanceApprovalFormComponent} from './components/attendance-approval-form/attendance-approval-form.component';
import { SiteMobilizationTabComponent } from './components/pre-execution-section/site-mobilization-tab/site-mobilization-tab.component';
import { PAndMTabComponent } from './components/pre-execution-section/p-and-m-tab/p-and-m-tab.component';
import { OfficeSetupTabComponent } from './components/pre-execution-section/office-setup-tab/office-setup-tab.component';
import { ManpowerTabComponent } from './components/pre-execution-section/manpower-tab/manpower-tab.component';
import { CostAnalysisTabComponent } from './components/pre-execution-section/cost-analysis-tab/cost-analysis-tab.component';
import { ContractorFinalizationTabComponent } from './components/pre-execution-section/contractor-finalization-tab/contractor-finalization-tab.component';
import { SitePujaTabComponent } from './components/pre-execution-section/site-puja-tab/site-puja-tab.component';
import { SiteMobilizationGuestHouseInnerTabComponent } from './components/pre-execution-section/site-mobilization-guest-house-inner-tab/site-mobilization-guest-house-inner-tab.component';
import { SiteMobilizationFurnitureInnerTabComponent } from './components/pre-execution-section/site-mobilization-furniture-inner-tab/site-mobilization-furniture-inner-tab.component';
import { SiteMobilizationUtilitiesInnerTabComponent } from './components/pre-execution-section/site-mobilization-utilities-inner-tab/site-mobilization-utilities-inner-tab.component';
import { UtilitiesElectricalInnerTabComponent } from './components/pre-execution-section/utilities-electrical-inner-tab/utilities-electrical-inner-tab.component';
import { UtilitiesWaterInnerTabComponent } from './components/pre-execution-section/utilities-water-inner-tab/utilities-water-inner-tab.component';
import { UtilitiesFuelInnerTabComponent } from './components/pre-execution-section/utilities-fuel-inner-tab/utilities-fuel-inner-tab.component';
import { UtilitiesUtensilsInnerTabComponent } from './components/pre-execution-section/utilities-utensils-inner-tab/utilities-utensils-inner-tab.component';
import { UtilitiesTiffinBoxInnerTabComponent } from './components/pre-execution-section/utilities-tiffin-box-inner-tab/utilities-tiffin-box-inner-tab.component';
import { UtilitiesCookInnerTabComponent } from './components/pre-execution-section/utilities-cook-inner-tab/utilities-cook-inner-tab.component';
import { AddHydrologicalModalComponent } from './components/tender-section/add-hydrological-modal/add-hydrological-modal.component';
import { AddContactModalComponent } from './components/tender-section/add-contact-modal/add-contact-modal.component';
import { OfficeStructureInnerTabComponent } from './components/pre-execution-section/office-structure-inner-tab/office-structure-inner-tab.component';
import { OfficeConnectionsInnerTabComponent } from './components/pre-execution-section/office-connections-inner-tab/office-connections-inner-tab.component';
import { OfficeFurnitureInnerTabComponent } from './components/pre-execution-section/office-furniture-inner-tab/office-furniture-inner-tab.component';
import { OfficeComputerInnerTabComponent } from './components/pre-execution-section/office-computer-inner-tab/office-computer-inner-tab.component';
import { OfficeStationaryInnerTabComponent } from './components/pre-execution-section/office-stationary-inner-tab/office-stationary-inner-tab.component';
import { OfficeToiletInnerTabComponent } from './components/pre-execution-section/office-toilet-inner-tab/office-toilet-inner-tab.component';
import { OfficeVehicleInnerTabComponent } from './components/pre-execution-section/office-vehicle-inner-tab/office-vehicle-inner-tab.component';
import { OfficeLabourInnerTabComponent } from './components/pre-execution-section/office-labour-inner-tab/office-labour-inner-tab.component';
import { OfficeRawmaterialInnerTabComponent } from './components/pre-execution-section/office-rawmaterial-inner-tab/office-rawmaterial-inner-tab.component';
import { OfficeSurveyInstrumentInnerTabComponent } from './components/pre-execution-section/office-survey-instrument-inner-tab/office-survey-instrument-inner-tab.component';
import { OfficeSafetyInnerTabComponent } from './components/pre-execution-section/office-safety-inner-tab/office-safety-inner-tab.component';
import { OfficeSecuurityRoomInnerTabComponent } from './components/pre-execution-section/office-secuurity-room-inner-tab/office-secuurity-room-inner-tab.component';
import { ConnectionElectricalInnerTabComponent } from './components/pre-execution-section/connection-electrical-inner-tab/connection-electrical-inner-tab.component';
import { ConnectionWaterInnerTabComponent } from './components/pre-execution-section/connection-water-inner-tab/connection-water-inner-tab.component';
import { ConnectionInternetInnerTabComponent } from './components/pre-execution-section/connection-internet-inner-tab/connection-internet-inner-tab.component';
import { FurnitureFileCabinetInnerTabComponent } from './components/pre-execution-section/furniture-file-cabinet-inner-tab/furniture-file-cabinet-inner-tab.component';
import { FurnitureTableInnerTabComponent } from './components/pre-execution-section/furniture-table-inner-tab/furniture-table-inner-tab.component';
import { FurnitureChairInnerTabComponent } from './components/pre-execution-section/furniture-chair-inner-tab/furniture-chair-inner-tab.component';
import { VehicleVehicleInnerTabComponent } from './components/pre-execution-section/vehicle-vehicle-inner-tab/vehicle-vehicle-inner-tab.component';
import { VehicleBikeInnerTabComponent } from './components/pre-execution-section/vehicle-bike-inner-tab/vehicle-bike-inner-tab.component';
import { LabourHutmentInnerTabComponent } from './components/pre-execution-section/labour-hutment-inner-tab/labour-hutment-inner-tab.component';
import { LabourToiletInnerTabComponent } from './components/pre-execution-section/labour-toilet-inner-tab/labour-toilet-inner-tab.component';
import { LabourWaterConnectionInnerTabComponent } from './components/pre-execution-section/labour-water-connection-inner-tab/labour-water-connection-inner-tab.component';
import { LabourElectricalConnectionInnerTabComponent } from './components/pre-execution-section/labour-electrical-connection-inner-tab/labour-electrical-connection-inner-tab.component';
import { RawmaterialMaterialYardInnerTabComponent } from './components/pre-execution-section/rawmaterial-material-yard-inner-tab/rawmaterial-material-yard-inner-tab.component';
import { RawmaterialCementGodownInnerTabComponent } from './components/pre-execution-section/rawmaterial-cement-godown-inner-tab/rawmaterial-cement-godown-inner-tab.component';
import { RawmaterialQaQcLabInnerTabComponent } from './components/pre-execution-section/rawmaterial-qa-qc-lab-inner-tab/rawmaterial-qa-qc-lab-inner-tab.component';
import { SureveyRequiredInnerTabComponent } from './components/pre-execution-section/surevey-required-inner-tab/surevey-required-inner-tab.component';
import { SureveyActualInnerTabComponent } from './components/pre-execution-section/surevey-actual-inner-tab/surevey-actual-inner-tab.component';
import { TenderApprovalTabComponent } from './components/tender-section/tender-approval-tab/tender-approval-tab.component';
import { OfficeContainerComponent } from './components/pre-execution-section/office-structure-inner-tab/office-container/office-container.component';
import { ExistingBrickWorkComponent } from './components/pre-execution-section/office-structure-inner-tab/existing-brick-work/existing-brick-work.component';
import { PAndMInnerTabComponent } from './components/pre-execution-section/p-and-m-inner-tab/p-and-m-inner-tab.component';
import { ManpowerInnerTabComponent } from './components/pre-execution-section/manpower-inner-tab/manpower-inner-tab.component';
import { CostAnalysisInnerTabComponent } from './components/pre-execution-section/cost-analysis-inner-tab/cost-analysis-inner-tab.component';
import { SiteMobilizationApproveTabComponent } from './components/pre-execution-approve/site-mobilization-approve-tab/site-mobilization-approve-tab.component';
import { OfficeSetupApproveTabComponent } from './components/pre-execution-approve/office-setup-approve-tab/office-setup-approve-tab.component';
import { PAndMApproveTabComponent } from './components/pre-execution-approve/p-and-m-approve-tab/p-and-m-approve-tab.component';
import { ManpowerApproveTabComponent } from './components/pre-execution-approve/manpower-approve-tab/manpower-approve-tab.component';
import { CostAnalysisApproveTabComponent } from './components/pre-execution-approve/cost-analysis-approve-tab/cost-analysis-approve-tab.component';
import { ContractorFinalizationApproveTabComponent } from './components/pre-execution-approve/contractor-finalization-approve-tab/contractor-finalization-approve-tab.component';
import { SitePujaApproveTabComponent } from './components/pre-execution-approve/site-puja-approve-tab/site-puja-approve-tab.component';
import { NewRequisitionTabComponent } from './components/purchase-section/new-requisition-tab/new-requisition-tab.component';
import { RequisitionApprovalComponent } from './components/purchase-section/requisition-approval/requisition-approval.component';
import { QuotationComponent } from './components/purchase-section/quotation/quotation.component';
import { ComparativeStatementComponent } from './components/purchase-section/comparative-statement/comparative-statement.component';
import { PurchaseOrderComponent } from './components/purchase-section/purchase-order/purchase-order.component';
import { DispatchTabComponent } from './components/purchase-section/dispatch-tab/dispatch-tab.component';
import { DeliveryTabComponent } from './components/purchase-section/delivery-tab/delivery-tab.component';
import { PaymentTabComponent } from './components/purchase-section/payment-tab/payment-tab.component';
import { QuotationInnerComponent } from './components/purchase-section/quotation/quotation-inner/quotation-inner.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MaterialStockStatementComponent } from './components/stock/report/material-stock-statement/material-stock-statement.component';
import { MonthlyStockReportComponent } from './components/stock/report/monthly-stock-report/monthly-stock-report.component';
import { CurrentStockComponent } from './components/stock/report/current-stock/current-stock.component';
import { IssueListComponent } from './components/stock/issue/issue-list/issue-list.component';
import { NewIssueSlipComponent } from './components/stock/issue/new-issue-slip/new-issue-slip.component';
import { ComarativeStatementInnerComponent } from './components/purchase-section/comparative-statement/comarative-statement-inner/comarative-statement-inner.component';
import { LeaveApprovalFormComponent } from './components/leave-approval-form/leave-approval-form.component';
import { AddPaymentPlanModelComponent } from './components/purchase-section/add-payment-plan-model/add-payment-plan-model.component';
import { ExternalUserAddComponent } from './components/setting/external-user-add/external-user-add.component';
import { ExternalUserEditComponent } from './components/setting/external-user-edit/external-user-edit.component';
import { ExternalUserListComponent } from './components/setting/external-user-list/external-user-list.component';
import { PoItemListModelComponent } from './components/purchase-section/po-item-list-model/po-item-list-model.component';
import { AddConveyanceModelComponent } from './components/add-conveyance-model/add-conveyance-model.component';
import { InformationShowDialogueComponent } from './components/information-show-dialogue/information-show-dialogue.component';
import { ItemPerPageFilterComponent } from './components/item-per-page-filter/item-per-page-filter.component';
import { ProjectSiteFilterComponent } from './components/project-site-filter/project-site-filter.component';
import { ApprovalsQuotationComponent } from './components/purchase-section/approval-list/approvals-quotation/approvals-quotation.component';
import { ApprovalsRequisitionComponent } from './components/purchase-section/approval-list/approvals-requisition/approvals-requisition.component';
import { ApprovalLevelBottomSheetComponent } from './components/purchase-section/approval-list/approval-level-bottom-sheet/approval-level-bottom-sheet.component';
import { ThreeDecimalNumberDirective } from './directive/three-decimal-number.directive';
import { MaterialDocumentViewModalComponent } from './components/material-document-view-modal/material-document-view-modal.component';
import { PurchaseEquipmentsComponent } from './components/machinery-tabs/purchase-equipments/purchase-equipments.component';
import { RentedEquipmentsComponent } from './components/machinery-tabs/rented-equipments/rented-equipments.component';
import { EquipmentsFromContractorComponent } from './components/machinery-tabs/equipments-from-contractor/equipments-from-contractor.component';
import { EquipmentsOnLeaseComponent } from './components/machinery-tabs/equipments-on-lease/equipments-on-lease.component';
import { ConfirmDialogExpenseComponent } from './components/confirm-dialog-expense/confirm-dialog-expense.component';
import { DailyAttendanceComponent } from './components/attendance/daily-attendance/daily-attendance.component';
import { LeavesComponent } from './components/attendance/leaves/leaves.component';
import { FixedColumnDirective } from './directive/fixed-column.directive';
import { ConfigComponent } from './components/config/config.component';
import { AdvanceComponent } from './components/attendance/leaves/advance/advance.component';
import { SpecialComponent } from './components/attendance/leaves/special/special.component';
import { ManpowerTransferComponent } from './components/manpower-transfer/manpower-transfer.component';
import { MachineryTransferComponent } from './components/machinery-transfer/machinery-transfer.component';
import { EmployeeLiveTrackingComponent } from './components/manpower-live-tracking/employee-live-tracking/employee-live-tracking.component';
import { EmployeeAttendanceComponent } from './components/manpower-live-tracking/employee-attendance/employee-attendance.component';
import { ProjectLiveTrackingComponent } from './components/project-live-tracking/project-live-tracking.component';
import { LeaveRecordComponent } from './components/attendance/leave-record/leave-record.component';
import { DailyComponent } from './components/progress-report/daily/daily.component';
import { WeeklyComponent } from './components/progress-report/weekly/weekly.component';
import { MonthlyComponent } from './components/progress-report/monthly/monthly.component';
import { ProgressComponent } from './components/progress-report/progress/progress.component';
import { LabourComponent } from './components/progress-report/labour/labour.component';
import { PMComponent } from './components/progress-report/p-m/p-m.component';
import { AttendanceSummaryComponent } from './components/attendance/attendance-summary/attendance-summary.component';
import { NewAttendanceComponent } from './components/attendance/attendance-summary/new-attendance/new-attendance.component';
import { OldAttendanceComponent } from './components/attendance/attendance-summary/old-attendance/old-attendance.component';
import { PreviousLeaveRecordComponent } from './components/attendance/previous-leave-record/previous-leave-record.component';
import { NormalLeaveReportComponent } from './components/attendance/leave-report/normal-leave-report/normal-leave-report.component';
import { AdvanceLeaveReportComponent } from './components/attendance/leave-report/advance-leave-report/advance-leave-report.component';
import { NormalLeaveComponent } from './components/attendance/leave-approval/normal-leave/normal-leave.component';
import { AdvanceLeaveComponent } from './components/attendance/leave-approval/advance-leave/advance-leave.component';
import { ApprovalDialogComponent } from './components/dialog/approval-dialog/approval-dialog.component';
import { LeaveStatusModalComponent } from './components/modal/leave-status-modal/leave-status-modal.component';
import { FlexiDailyAttendacneModalComponent } from './components/modal/flexi-daily-attendacne-modal/flexi-daily-attendacne-modal.component';
import { DailyProgessComponent } from './components/daily-data/daily-progess/daily-progess.component';
import { DailyLabourComponent } from './components/daily-data/daily-labour/daily-labour.component';
import { DailyPMComponent } from './components/daily-data/daily-p-m/daily-p-m.component';
import { DieselConsumptionComponent } from './components/diesel-consumption/diesel-consumption.component';
import { DateRangeWiseComponent } from './components/diesel-consumption-report/date-range-wise/date-range-wise.component';
import { DailyConsumptionComponent } from './components/diesel-consumption-report/daily/daily.component';
import { RemarksComponent } from './components/daily-expense/remarks/remarks.component';
import { ApprovalModalComponent } from './components/daily-expense/approval-modal/approval-modal.component';
import { ApprovalStatusComponent } from './components/daily-expense/approval-status/approval-status.component';
import { QuotationApprovalComponent } from './components/purchase-section/approval-list/quotation-approval/quotation-approval.component';
import { AddComponent } from './components/daily-worksheet/add/add.component';
import { EditComponent } from './components/daily-worksheet/edit/edit.component';
import { ViewComponent } from './components/daily-worksheet/view/view.component';
import { RankingModalComponent } from './components/purchase-section/approval-list/ranking-modal/ranking-modal.component';
import { QuotationApprovalModalComponent } from './components/purchase-section/approval-list/quotation-approval-modal/quotation-approval-modal.component';
import { QuotationApprovalConfirmModalComponent } from './components/purchase-section/approval-list/quotation-approval-confirm-modal/quotation-approval-confirm-modal.component';
import { ApproveRejectModalComponent } from './components/tourandtravel/approve-reject-modal/approve-reject-modal.component';
import { TravelAndTourExpenseModalComponent } from './components/modal/travel-and-tour-expense-modal/travel-and-tour-expense-modal.component';
import { TravelAndTourApprovalStatusModalComponent } from './components/modal/travel-and-tour-approval-status-modal/travel-and-tour-approval-status-modal.component';
import { TravelAndTourRemarksModalComponent } from './components/modal/travel-and-tour-remarks-modal/travel-and-tour-remarks-modal.component';
import { ComparativeStatementAllviewsComponent } from './components/purchase-section/comparative-statement/comparative-statement-allviews/comparative-statement-allviews.component';
import { RequisitionApprovalModalComponent } from './components/purchase-section/approval-list/requisition-approval-modal/requisition-approval-modal.component';
import { DailyBatchReportPreviewModalComponent } from './components/modal/daily-batch-report-preview-modal/daily-batch-report-preview-modal.component';
import { AddNewBillComponent } from './components/bills-invoices/add-new-bill/add-new-bill.component';
import { BillRemarksComponent } from './components/bills-invoices/bill-remarks/bill-remarks.component';
import { EditBillComponent } from './components/bills-invoices/edit-bill/edit-bill.component';
import { BillApprovalConfirmComponent } from './components/bills-invoices/bill-approval-confirm/bill-approval-confirm.component';
import { BillsInvoicesApprovalStatusComponent } from './components/bills-invoices/approval-status/approval-status.component';
import { TypeModalComponent } from './components/contractor/type-modal/type-modal.component';
import { ContractorModalComponent } from './components/contractor/contractor-modal/contractor-modal.component';
import { DailyMaterialComponent } from './components/daily-data/daily-material/daily-material.component';
import { WeeklyProgressComponent } from './components/weekly-data/weekly-progress/weekly-progress.component';
import { WeeklyLabourComponent } from './components/weekly-data/weekly-labour/weekly-labour.component';
import { WeeklyMaterialComponent } from './components/weekly-data/weekly-material/weekly-material.component';
import { TwoDecimalNumberDirective } from './directive/two-decimal-number.directive';
import { MaterialComponent } from './components/progress-report/material/material.component';
import { WeeklyPAndMComponent } from './components/weekly-data/weekly-p-and-m/weekly-p-and-m.component';
import { WeeklyReportProgressComponent } from './components/progress-report/weekly-report-progress/weekly-report-progress.component';
import { WeeklyReportLabourComponent } from './components/progress-report/weekly-report-labour/weekly-report-labour.component';
import { WeeklyReportPandmComponent } from './components/progress-report/weekly-report-pandm/weekly-report-pandm.component';
import { WeeklyPlanningComponent } from './components/progress-report/weekly-planning/weekly-planning.component';

export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};


@NgModule({
  declarations: [
    SidenavComponent,
    ErrorComponent,
    HeaderComponent,
    FooterComponent,
    EligibilityTabComponent,
    BidderTypeTabComponent,
    SurveyDetailsTabComponent,
    InitialCostingTabComponent,
    DocumentsTabComponent,
    TenderStatusTabComponent,
    NewTenderTabComponent,
    AddCriteriaModalComponent,
    AddPartnerModalComponent,
    AddLocationModalComponent,
    AddPhotoModalComponent,
    AddSupplierModalComponent,
    AddMaterialModalComponent,
    AddEstablishmentModalComponent,
    AddDataFactorModalComponent,
    AddContractorModalComponent,
    AddPAndMModalComponent,
    AddDesignationModalComponent,
    SitePhotoTabComponent,
    CoordinateTabComponent,
    ResourceTabComponent,
    RawMaterialTabComponent,
    CrusherTabComponent,
    RawMaterialCrusherInnerTabComponent,
    RawMaterialCrusherInnerTabColComponent,
    ResourceMaterialTabComponent,
    ResourceEstablishmentTabComponent,
    ResourceHydrologicalTabComponent,
    ResourceContarctorVendorTabComponent,
    ResourceContactTabComponent,
    ResourceMaterialInnerTabComponent,
    AddResourceSupplierModalComponent,
    ResourceContractorsVendorsInnerContractorTabComponent,
    ResourceContractorsVendorsInnerPAndMTabComponent,
    ResourceContactInnerTabComponent,
    ResourceContactInnerTabColComponent,
    LoadingComponent,
    EligibilityTechnicalInnerTabComponent,
    EligibilityFinancialInnerTabComponent,
    EligibilitySpecialInnerTabComponent,
    EligibilityInnerDataTabComponent,
    ListSearchComponent,
    ListFilterComponent,
    ListTableComponent,
    DocumentViewModalComponent,
    NumberDirective,
    TimeFormatPipe,
    AddObjectModalComponent,
    AddSubObjectModalComponent,
    AttendanceApprovalFormComponent,
    SiteMobilizationTabComponent,
    PAndMTabComponent,
    OfficeSetupTabComponent,
    ManpowerTabComponent,
    CostAnalysisTabComponent,
    ContractorFinalizationTabComponent,
    SitePujaTabComponent,
    SiteMobilizationGuestHouseInnerTabComponent,
    SiteMobilizationFurnitureInnerTabComponent,
    SiteMobilizationUtilitiesInnerTabComponent,
    UtilitiesElectricalInnerTabComponent,
    UtilitiesWaterInnerTabComponent,
    UtilitiesFuelInnerTabComponent,
    UtilitiesUtensilsInnerTabComponent,
    UtilitiesTiffinBoxInnerTabComponent,
    UtilitiesCookInnerTabComponent,
    AddHydrologicalModalComponent,
    AddContactModalComponent,
    OfficeStructureInnerTabComponent,
    OfficeConnectionsInnerTabComponent,
    OfficeFurnitureInnerTabComponent,
    OfficeComputerInnerTabComponent,
    OfficeStationaryInnerTabComponent,
    OfficeToiletInnerTabComponent,
    OfficeVehicleInnerTabComponent,
    OfficeLabourInnerTabComponent,
    OfficeRawmaterialInnerTabComponent,
    OfficeSurveyInstrumentInnerTabComponent,
    OfficeSafetyInnerTabComponent,
    OfficeSecuurityRoomInnerTabComponent,
    ConnectionElectricalInnerTabComponent,
    ConnectionWaterInnerTabComponent,
    ConnectionInternetInnerTabComponent,
    FurnitureFileCabinetInnerTabComponent,
    FurnitureTableInnerTabComponent,
    FurnitureChairInnerTabComponent,
    VehicleVehicleInnerTabComponent,
    VehicleBikeInnerTabComponent,
    LabourHutmentInnerTabComponent,
    LabourToiletInnerTabComponent,
    LabourWaterConnectionInnerTabComponent,
    LabourElectricalConnectionInnerTabComponent,
    RawmaterialMaterialYardInnerTabComponent,
    RawmaterialCementGodownInnerTabComponent,
    RawmaterialQaQcLabInnerTabComponent,
    SureveyRequiredInnerTabComponent,
    SureveyActualInnerTabComponent,
    TenderApprovalTabComponent,
    OfficeContainerComponent,
    ExistingBrickWorkComponent,
    PAndMInnerTabComponent,
    ManpowerInnerTabComponent,
    CostAnalysisInnerTabComponent,
    SiteMobilizationApproveTabComponent,
    OfficeSetupApproveTabComponent,
    PAndMApproveTabComponent,
    ManpowerApproveTabComponent,
    CostAnalysisApproveTabComponent,
    ContractorFinalizationApproveTabComponent,
    SitePujaApproveTabComponent,
    NewRequisitionTabComponent,
    RequisitionApprovalComponent,
    QuotationComponent,
    ComparativeStatementComponent,
    PurchaseOrderComponent,
    DispatchTabComponent,
    DeliveryTabComponent,
    PaymentTabComponent,
    QuotationInnerComponent,
    ConfirmDialogComponent,
    PaginationComponent,
    MaterialStockStatementComponent,
    MonthlyStockReportComponent,
    CurrentStockComponent,
    IssueListComponent,
    NewIssueSlipComponent,
    ComarativeStatementInnerComponent,
    LeaveApprovalFormComponent,
    AddPaymentPlanModelComponent,
    ExternalUserAddComponent,
    ExternalUserEditComponent,
    ExternalUserListComponent,
    PoItemListModelComponent,
    AddConveyanceModelComponent,
    InformationShowDialogueComponent,
    ItemPerPageFilterComponent,
    ProjectSiteFilterComponent,
    ApprovalsQuotationComponent,
    ApprovalsRequisitionComponent,
    ApprovalLevelBottomSheetComponent,
    ThreeDecimalNumberDirective,
    MaterialDocumentViewModalComponent,
    PurchaseEquipmentsComponent,
    RentedEquipmentsComponent,
    EquipmentsFromContractorComponent,
    EquipmentsOnLeaseComponent,
    ConfirmDialogExpenseComponent,
    DailyAttendanceComponent,
    LeavesComponent,
    FixedColumnDirective,
    ConfigComponent,
    AdvanceComponent,
    SpecialComponent,
    ManpowerTransferComponent,
    MachineryTransferComponent,
    EmployeeLiveTrackingComponent,
    EmployeeAttendanceComponent,
    ProjectLiveTrackingComponent,
    LeaveRecordComponent,
    DailyComponent,
    WeeklyComponent,
    MonthlyComponent,
    ProgressComponent,
    LabourComponent,
    PMComponent,
    AttendanceSummaryComponent,
    NewAttendanceComponent,
    OldAttendanceComponent,
    PreviousLeaveRecordComponent,
    NormalLeaveReportComponent,
    AdvanceLeaveReportComponent,
    NormalLeaveComponent,
    AdvanceLeaveComponent,
    ApprovalDialogComponent,
    LeaveStatusModalComponent,
    FlexiDailyAttendacneModalComponent,   
    DailyProgessComponent,
    DailyLabourComponent,
    DailyMaterialComponent,
    DailyPMComponent,
    DieselConsumptionComponent,
    DateRangeWiseComponent,
    DailyConsumptionComponent,
    RemarksComponent,
    ApprovalModalComponent,
    ApprovalStatusComponent,
    QuotationApprovalComponent,
    AddComponent,
    EditComponent,
    ViewComponent,
    RankingModalComponent,
    QuotationApprovalModalComponent,
    QuotationApprovalConfirmModalComponent,
    ComparativeStatementAllviewsComponent,
    RequisitionApprovalModalComponent,
    ApproveRejectModalComponent,
    TravelAndTourExpenseModalComponent,
    TravelAndTourApprovalStatusModalComponent,
    TravelAndTourRemarksModalComponent,
    DailyBatchReportPreviewModalComponent,
    AddNewBillComponent,
    BillRemarksComponent,
    EditBillComponent,
    BillApprovalConfirmComponent,
    BillsInvoicesApprovalStatusComponent,
    TypeModalComponent,
    ContractorModalComponent,
    WeeklyProgressComponent,
    WeeklyLabourComponent,
    WeeklyMaterialComponent,
    TwoDecimalNumberDirective,
    MaterialComponent,
    WeeklyPAndMComponent,
    WeeklyReportProgressComponent,
    WeeklyReportLabourComponent,
    WeeklyReportPandmComponent,
    WeeklyPlanningComponent
  ],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    AutocompleteLibModule,
    OwlMomentDateTimeModule,
    OwlDateTimeModule,
    GooglePlaceModule,
    NgxPermissionsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key
    }),
    //----------------Material----------------//
    MatAutocompleteModule, MatButtonModule, MatButtonToggleModule,
    MatCardModule, MatCheckboxModule, MatChipsModule, MatStepperModule, MatDatepickerModule,
    MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule,
    MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule,
    MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule, MatBottomSheetModule, MatFormFieldModule,
    //----------------Material----------------//
    ChartsModule
  ],
  exports: [
    NgbModule,
    SidenavComponent,
    ErrorComponent,
    HeaderComponent,
    FooterComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    AutocompleteLibModule,
    OwlMomentDateTimeModule,
    OwlDateTimeModule,
    GooglePlaceModule,
    AgmCoreModule,
    NumberDirective,
    ThreeDecimalNumberDirective,
    TimeFormatPipe,
    ConfigComponent,

    //----------------Material----------------//
    MatAutocompleteModule, MatButtonModule, MatButtonToggleModule,
    MatCardModule, MatCheckboxModule, MatChipsModule, MatStepperModule, MatDatepickerModule,
    MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule,
    MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule,
    MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule, MatBottomSheetModule, MatFormFieldModule,
    //----------------Material----------------//
    ChartsModule,
    EligibilityTabComponent,
    BidderTypeTabComponent,
    SurveyDetailsTabComponent,
    InitialCostingTabComponent,
    DocumentsTabComponent,
    TenderStatusTabComponent,
    NewTenderTabComponent,
    SitePhotoTabComponent,
    CoordinateTabComponent,
    ResourceTabComponent,
    RawMaterialTabComponent,
    CrusherTabComponent,
    RawMaterialCrusherInnerTabComponent,
    RawMaterialCrusherInnerTabColComponent,
    ResourceMaterialTabComponent,
    ResourceEstablishmentTabComponent,
    ResourceHydrologicalTabComponent,
    ResourceContarctorVendorTabComponent,
    ResourceContactTabComponent,
    ResourceMaterialInnerTabComponent,
    ResourceContractorsVendorsInnerContractorTabComponent,
    ResourceContractorsVendorsInnerPAndMTabComponent,
    ResourceContactInnerTabComponent,
    ResourceContactInnerTabColComponent,
    LoadingComponent,
    EligibilityTechnicalInnerTabComponent,
    EligibilityFinancialInnerTabComponent,
    EligibilitySpecialInnerTabComponent,
    EligibilityInnerDataTabComponent,
    ListSearchComponent,
    ListFilterComponent,
    ListTableComponent,
    AttendanceApprovalFormComponent,
    SiteMobilizationTabComponent,
    PAndMTabComponent,
    OfficeSetupTabComponent,
    ManpowerTabComponent,
    CostAnalysisTabComponent,
    ContractorFinalizationTabComponent,
    SitePujaTabComponent,
    SiteMobilizationGuestHouseInnerTabComponent,
    SiteMobilizationFurnitureInnerTabComponent,
    SiteMobilizationUtilitiesInnerTabComponent,
    UtilitiesElectricalInnerTabComponent,
    UtilitiesWaterInnerTabComponent,
    UtilitiesFuelInnerTabComponent,
    UtilitiesUtensilsInnerTabComponent,
    UtilitiesTiffinBoxInnerTabComponent,
    UtilitiesCookInnerTabComponent,
    OfficeStructureInnerTabComponent,
    OfficeConnectionsInnerTabComponent,
    OfficeFurnitureInnerTabComponent,
    OfficeComputerInnerTabComponent,
    OfficeStationaryInnerTabComponent,
    OfficeToiletInnerTabComponent,
    OfficeVehicleInnerTabComponent,
    OfficeLabourInnerTabComponent,
    OfficeRawmaterialInnerTabComponent,
    OfficeSurveyInstrumentInnerTabComponent,
    OfficeSafetyInnerTabComponent,
    OfficeSecuurityRoomInnerTabComponent,
    ConnectionElectricalInnerTabComponent,
    ConnectionWaterInnerTabComponent,
    ConnectionInternetInnerTabComponent,
    FurnitureFileCabinetInnerTabComponent,
    FurnitureTableInnerTabComponent,
    FurnitureChairInnerTabComponent,
    VehicleVehicleInnerTabComponent,
    VehicleBikeInnerTabComponent,
    LabourHutmentInnerTabComponent,
    LabourToiletInnerTabComponent,
    LabourWaterConnectionInnerTabComponent,
    LabourElectricalConnectionInnerTabComponent,
    RawmaterialMaterialYardInnerTabComponent,
    RawmaterialCementGodownInnerTabComponent,
    RawmaterialQaQcLabInnerTabComponent,
    SureveyRequiredInnerTabComponent,
    SureveyActualInnerTabComponent,
    TenderApprovalTabComponent,
    OfficeContainerComponent,
    ExistingBrickWorkComponent,
    PAndMInnerTabComponent,
    ManpowerInnerTabComponent,
    CostAnalysisInnerTabComponent,
    SiteMobilizationApproveTabComponent,
    OfficeSetupApproveTabComponent,
    PAndMApproveTabComponent,
    ManpowerApproveTabComponent,
    CostAnalysisApproveTabComponent,
    ContractorFinalizationApproveTabComponent,
    SitePujaApproveTabComponent,
    NewRequisitionTabComponent,
    RequisitionApprovalComponent,
    QuotationComponent,
    ComparativeStatementComponent,
    PurchaseOrderComponent,
    DispatchTabComponent,
    DeliveryTabComponent,
    PaymentTabComponent,
    QuotationInnerComponent,
    PaginationComponent,
    MaterialStockStatementComponent,
    MonthlyStockReportComponent,
    CurrentStockComponent,
    IssueListComponent,
    NewIssueSlipComponent,
    ComarativeStatementInnerComponent,
    LeaveApprovalFormComponent,
    ExternalUserAddComponent,
    ExternalUserEditComponent,
    ExternalUserListComponent,
    ItemPerPageFilterComponent,
    ProjectSiteFilterComponent,
    ApprovalsQuotationComponent,
    ApprovalsRequisitionComponent,
    PurchaseEquipmentsComponent,
    RentedEquipmentsComponent,
    EquipmentsFromContractorComponent,
    EquipmentsOnLeaseComponent,
    DailyAttendanceComponent,
    LeavesComponent,
    FixedColumnDirective,
    AdvanceComponent,
    SpecialComponent,
    ConfigComponent,
    EmployeeLiveTrackingComponent,
    EmployeeAttendanceComponent,
    ProjectLiveTrackingComponent,
    LeaveRecordComponent,
    DailyComponent,
    WeeklyComponent,
    MonthlyComponent,
    ProgressComponent,
    LabourComponent,
    PMComponent,
    AttendanceSummaryComponent,
    NewAttendanceComponent,
    OldAttendanceComponent,
    PreviousLeaveRecordComponent,
    NormalLeaveReportComponent,
    AdvanceLeaveReportComponent,
    NormalLeaveComponent,
    AdvanceLeaveComponent,
    DailyProgessComponent,
    DailyLabourComponent,
    DailyMaterialComponent,
    DailyPMComponent,
    DieselConsumptionComponent,
    DateRangeWiseComponent,
    DailyConsumptionComponent,
    QuotationApprovalComponent,
    WeeklyProgressComponent,
    WeeklyLabourComponent,
    WeeklyMaterialComponent,
    WeeklyPAndMComponent
  ],
  entryComponents: [
    AddCriteriaModalComponent,
    AddPartnerModalComponent,
    AddLocationModalComponent,
    AddPhotoModalComponent,
    AddSupplierModalComponent,
    AddMaterialModalComponent,
    AddEstablishmentModalComponent,
    AddDataFactorModalComponent,
    AddContractorModalComponent,
    AddPAndMModalComponent,
    AddDesignationModalComponent,
    AddResourceSupplierModalComponent,
    DocumentViewModalComponent,
    AddObjectModalComponent,
    AddSubObjectModalComponent,
    AddHydrologicalModalComponent,
    AddContactModalComponent,
    ConfirmDialogComponent,
    AddPaymentPlanModelComponent,
    PoItemListModelComponent,
    AddConveyanceModelComponent,
    InformationShowDialogueComponent,
    ApprovalLevelBottomSheetComponent,
    MaterialDocumentViewModalComponent,
    ConfirmDialogExpenseComponent,
    ManpowerTransferComponent,
    MachineryTransferComponent,
    ApprovalDialogComponent,
    LeaveStatusModalComponent,
    FlexiDailyAttendacneModalComponent,
    RemarksComponent,
    ApprovalModalComponent,
    ApprovalStatusComponent,
    AddComponent,
    EditComponent,
    ViewComponent,
    RankingModalComponent,
    QuotationApprovalModalComponent,
    QuotationApprovalConfirmModalComponent,
    RequisitionApprovalModalComponent,
    ApproveRejectModalComponent,
    TravelAndTourExpenseModalComponent,
    TravelAndTourApprovalStatusModalComponent,
    TravelAndTourRemarksModalComponent,
    DailyBatchReportPreviewModalComponent,
    AddNewBillComponent,
    BillRemarksComponent,
    EditBillComponent,
    BillApprovalConfirmComponent,
    BillsInvoicesApprovalStatusComponent,
    TypeModalComponent,
    ContractorModalComponent,
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        AuthGuard,
        ValidationService,
        TenderService,
        SettingService,
        projectSiteServices,
        ProjectServices,
        AttendanceService,
        PlantMachineryService,
        ManpowerService,
        ObjectService,
        ApplicationService,
        UserService,
        PerExecutionService,
        TravelAndTourService,
        DailyExpenseService,
        LiveTrackingService,
        EmployeesService,
        BatchingPlantService,
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        NotificationService,
        BillInvoiceService,
        ContractorsService,
        InsuranceService,
        StorageService,
        LocalService
      ]
    }
  }
}
