import { Component, OnInit, Input, ViewChild} from '@angular/core';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-resource-contarctor-vendor-tab',
  templateUrl: './resource-contarctor-vendor-tab.component.html',
  styleUrls: ['./resource-contarctor-vendor-tab.component.scss']
})
export class ResourceContarctorVendorTabComponent implements OnInit {
  @Input() tenderId: number;
  addTenderConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  activeTab: any = 'contractor';

  constructor() {
    this.addTenderConfig = AppPageStateConfig.addTenderConfig.surveyConfig;
    this.activeTab = this.addTenderConfig.resourceConfig.vendorConfig.tab;
   }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.switchNgBTab(this.activeTab);
  }

  switchNgBTab(id: string) {
    //console.log(id)
    this.ctdTabset.select(id);
  }
  
  onTabChange(event) {
    //console.log(event)
    this.activeTab = event.nextId;
   // this.addTenderConfig.resourceConfig.vendorConfig.tab = this.activeTab
   
  }
}
