import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-office-stationary-inner-tab',
  templateUrl: './office-stationary-inner-tab.component.html',
  styleUrls: ['./office-stationary-inner-tab.component.scss']
})
export class OfficeStationaryInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  form: FormGroup;
  stationaryDocumentList:any = [];
  loading: LoadingState = LoadingState.NotReady;
  stationaryListData :any = [];
  stationaryDetail: any = [];
  stationaryTypeDetails :any = []
  listEdit:boolean;
  
  filePerfix:string;
  @Input() approveStatus:any = [];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project:[this.projectId],
      
      stationary_details:this.formBuilder.group({
        item:[null,Validators.required],
        quantity:[null,Validators.required],
        rate:[null,Validators.required],
        supplier_name:[null,Validators.required],
        supplier_ph_no:[null,Validators.required]
        
      }),
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
    })

    if(this.projectId){
      this.getStationaryDetails();
      this.getStationaryList();
    }
  }

  save(){
    if(this.form.valid){
      var stationary_documents_data = [];
      stationary_documents_data = this.form.value.documents;

      const data = {
        project:this.projectId,
        
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost: this.form.value.budgeted_cost,
        executed_cost: this.form.value.executed_cost,
        stationary_details:
        {
          project:this.projectId,
          item:this.form.value.stationary_details.item,
          quantity:this.form.value.stationary_details.quantity,
          rate:this.form.value.stationary_details.rate,
          supplier_name:this.form.value.stationary_details.supplier_name,
          supplier_ph_no:this.form.value.stationary_details.supplier_ph_no
        }
      }

      this.stationaryDocumentList = [];
      this.perExecutionService.addStationaryOffice(data).subscribe(
        res => {
         // console.log(res);
          var forkArray = [];

          stationary_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.stationary_details.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.stationary_document, x.stationary_document['name']);
              forkArray.push(this.perExecutionService.addStationaryDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.stationaryDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )

          this.getStationaryList(); 
          this.getStationaryDetails();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        })

      //console.log(data);
    }
    else{
      //console.log("error");
     this.markFormGroupTouched(this.form);
    }
  }

  getStationaryDetails(){
    this.perExecutionService.getStationaryOffice(this.projectId).subscribe(
      res => {
       if(res['result'][0]){
        this.stationaryDetail = res['result'][0];
        //console.log(this.stationaryDetail);

        var start_date = new Date(this.stationaryDetail['requirment_s_date'])
            this.stationaryDetail.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.stationaryDetail['requirment_e_date'])
            this.stationaryDetail.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          
          this.stationaryDetail.budgeted_cost = parseFloat(this.stationaryDetail.budgeted_cost).toFixed(2);
          this.stationaryDetail.executed_cost = parseFloat(this.stationaryDetail.executed_cost).toFixed(2);
       }
       
        this.loading = LoadingState.Ready;
      },
      error =>{
        //console.log(error);
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
  getStationaryList(){
    
    this.perExecutionService.getStationaryListOffice(this.projectId).subscribe(
      res => {
        this.stationaryListData = res['result'];
        //console.log(this.stationaryListData);
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  update(){
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var stationary_documents_data = [];
      stationary_documents_data = this.form.value.documents;

      const updateData = {
          project:this.projectId,
          
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost,
          stationary_details:
          {
            project_id:this.projectId,
            item:this.form.value.stationary_details.item,
            quantity:this.form.value.stationary_details.quantity,
            rate:this.form.value.stationary_details.rate,
            supplier_name:this.form.value.stationary_details.supplier_name,
            supplier_ph_no:this.form.value.stationary_details.supplier_ph_no
          }
      }
      //console.log(updateData);

      this.perExecutionService.editStationaryOffice(this.stationaryDetail.id,updateData).subscribe(
        res =>{
           //console.log(res);
          if(this.form.value.documents.length > 0){
            var forkArray = [];
            stationary_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.stationary_details.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.stationary_document, x.stationary_document['name']);
              forkArray.push(this.perExecutionService.addStationaryDocumentOffice(formData))
            })
            forkJoin(forkArray).subscribe(
              result => {
                // console.log(result)
                result.forEach(y => {
                  this.stationaryDocumentList.push(y)
                })
               
                // this.add_tender_document();
                this.loading = LoadingState.Ready;
              },
              err => {
                this.loading = LoadingState.Ready;
                // console.log(err)
              }
            )
           }
          this. getStationaryList();
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error =>{
          this.loading = LoadingState.Ready;
         // console.log(error);
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        })

    }

    else{
     // console.log("error");
      this.markFormGroupTouched(this.form);
    }
  }

  updateStationaryList()
  {
  
    var tiffin_box_documents_data = [];
    tiffin_box_documents_data = this.form.value.documents;

    if(this.form.valid){

      this.loading = LoadingState.Processing;
      const updateStaData = {
        stn_requirements: this.stationaryDetail.id,
        project:this.projectId,
        item:this.form.value.stationary_details.item,
        quantity:this.form.value.stationary_details.quantity,
        rate:this.form.value.stationary_details.rate,
        supplier_name:this.form.value.stationary_details.supplier_name,
        supplier_ph_no:this.form.value.stationary_details.supplier_ph_no,
        stationary_details:{
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost
        }
      }
     // console.log(updateStaData);

      //this.tiffinBoxDocumentList = [];
      this.perExecutionService.editStationaryListOffice(this.stationaryTypeDetails.id,updateStaData).subscribe(
        res =>{
         // console.log(res);
          var forkArray = [];

          if(this.form.value.documents.length > 0){
            tiffin_box_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", this.stationaryTypeDetails.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.stationary_document, x.stationary_document['name']);
              forkArray.push(this.perExecutionService.addStationaryDocumentOffice(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.stationaryDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
      }
      this.getStationaryList(); 
      this.loading = LoadingState.Ready;
      this.toastr.success('Data has been saved','',{
        timeOut: 3000,
      });
        },
        error =>{
          //console.log(error);
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
      

    }else{
      console.log("error");
    }
  }

  

  editStationary(data){
    this.stationaryTypeDetails = data;
  // console.log(this.stationaryTypeDetails);
    this.stationaryDocumentList = this.stationaryTypeDetails.stationary_requirements_document_details;
    this.listEdit = true;
    this. getStationaryList(); 
    
  }

  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  
  deleteStationaryList(id){

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteStationaryListOffice(id).subscribe(
        res => {
         this.getStationaryList();
           //console.log(res)
          this.loading = LoadingState.Ready
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
   })
   
   
  }

  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.stationaryDocumentList[index]['isEdit'] = true
    this.stationaryDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.stationaryDocumentList[index]['document_name'] == '') {
      this.stationaryDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.stationaryDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.stationaryDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editStationaryDocumentOffice(this.stationaryDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.stationaryDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteStationaryDocumentOffice(this.stationaryDocumentList[index]['id']).subscribe(
        res => {
          this.stationaryDocumentList.splice(index, 1)
          // console.log(res)
          this.loading = LoadingState.Ready
          this.getStationaryList();
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
	 }
	 this.dialogRef = null;
	 })

   
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      stationary_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);

  }

  onSelectFile(event, index){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          stationary_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.markFormGroupTouched(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
   }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
