import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quotation-approval-confirm-modal',
  templateUrl: './quotation-approval-confirm-modal.component.html',
  styleUrls: ['./quotation-approval-confirm-modal.component.scss']
})
export class QuotationApprovalConfirmModalComponent implements OnInit {

  confirmMessage: string;

  constructor(
    public dialogRef: MatDialogRef<QuotationApprovalConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    // console.log(this.data)
  }
}
