import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import {NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap'
import { empty } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-site-mobilization-guest-house-inner-tab',
  templateUrl: './site-mobilization-guest-house-inner-tab.component.html',
  styleUrls: ['./site-mobilization-guest-house-inner-tab.component.scss'],
  providers: [NgbTimepickerConfig]
})
export class SiteMobilizationGuestHouseInnerTabComponent implements OnInit {

  form: FormGroup;
  loading: LoadingState = LoadingState.NotReady;
  @ViewChild("placesRef", {static: true}) placesRef: GooglePlaceDirective;
  @Input() projectId:number;
  @Input() tabId:number;
  getGuestHouseDetails:any = [];
  inTime: NgbTimeStruct;
  outTime: NgbTimeStruct;
  approveStatus:any = [];

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private config: NgbTimepickerConfig,
    private toastr: ToastrService,
    public settingService:SettingService,
  ) { 

    config.seconds = true;
    config.spinners = false;
  }

  ngOnInit() {
 // console.log(this.tabId);

    this.form = this.formBuilder.group({
      project:[this.projectId],
      cost:[null,Validators.required],
      address:[null,Validators.required],
      latitude:[null],
      longitude:[null],
      no_of_rooms:[null,Validators.required],
      capacity:[null,Validators.required],
      distence_from_site:[null,Validators.required],
      checkin_date:[null,Validators.required],
      check_in_time:[null],
      checkout_date:[null,Validators.required],
      check_out_time:[null],
      near_rail_station:[null],
      near_bus_stand:[null]

    })

    this.getGuestHouseFind();
    this.getStatus();

  }

  save()
  {

    if(this.form.valid)
    {
      this.loading = LoadingState.NotReady;
      //console.log(this.form.value);
      var data = {
        project:this.form.value.project,
        cost:this.form.value.cost,
        address:this.form.value.address,
        latitude:this.form.value.latitude,
        longitude:this.form.value.longitude,
        no_of_rooms:this.form.value.no_of_rooms,
        capacity:this.form.value.capacity,
        distence_from_site:this.form.value.distence_from_site,
        checkin_date:this.settingService.getProperDate(this.form.value.checkin_date).toISOString(),//aa
        checkout_date:this.settingService.getProperDate(this.form.value.checkout_date).toISOString(),//aa
        near_rail_station:this.form.value.near_rail_station,
        near_bus_stand:this.form.value.near_bus_stand,
      }

     // console.log(data);

      this.perExecutionService.addGuestHouseFinding(data).subscribe(
        res=>{
          
         // console.log(res);
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
         
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          
        }
      )
    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

 getGuestHouseFind(){
  this.loading = LoadingState.NotReady;
   this.perExecutionService.getGuestHouseFinding(this.projectId).subscribe(
    res =>{
      //console.log(typeof(res['result']));
    
      if(res['result']){
        this.getGuestHouseDetails = res['result'];
          
          var check_in_date = new Date(this.getGuestHouseDetails['checkin_date'])
          this.getGuestHouseDetails.checkin_date = {
            year: check_in_date.getFullYear(),
            month: check_in_date.getMonth()+1,
            day: check_in_date.getDate()
      
          }
          var check_out_date = new Date(this.getGuestHouseDetails['checkout_date'])
          this.getGuestHouseDetails.checkout_date = {
            year: check_out_date.getFullYear(),
            month: check_out_date.getMonth()+1,
            day: check_out_date.getDate()
            
          }

          this.form.patchValue({
            latitude: this.getGuestHouseDetails.latitude,
            longitude: this.getGuestHouseDetails.longitude
          })
    
        this.getGuestHouseDetails.cost = parseFloat(this.getGuestHouseDetails.cost).toFixed(2)
        this.getGuestHouseDetails.distence_from_site = parseFloat(this.getGuestHouseDetails.distence_from_site).toFixed(2)

        this.inTime = {hour: check_in_date.getHours(), minute: check_in_date.getMinutes(), second: check_in_date.getSeconds()};
        this.outTime = {hour: check_out_date.getHours(), minute: check_out_date.getMinutes(), second: check_out_date.getSeconds()};
       
        
      }
      this.loading = LoadingState.Ready;
      
    },
    error =>{
      //console.log(error);
      this.loading = LoadingState.Ready;
      this.toastr.error('Internal Server error !', '', {
        timeOut: 3000,
      });
    }
   )
 }

 getStatus(){
  this.loading = LoadingState.Processing;
  this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
    res =>{
      //console.log(res);
      if(res['result'][0]){
        this.approveStatus = res['result'][0];
        
       
      }
      this.loading = LoadingState.Ready;
    },
    error => {
      //console.log(error)
      this.loading = LoadingState.Ready;
    })

}

 update(){
    this.loading = LoadingState.NotReady;
    if(this.form.valid)
    {
      
      var data = {
        project:this.form.value.project,
        cost:this.form.value.cost,
        address:this.form.value.address,
        latitude:this.form.value.latitude,
        longitude:this.form.value.longitude,
        no_of_rooms:this.form.value.no_of_rooms,
        capacity:this.form.value.capacity,
        distence_from_site:this.form.value.distence_from_site,
        checkin_date:this.settingService.getProperDate(this.form.value.checkin_date).toISOString(),//aa
        checkout_date:this.settingService.getProperDate(this.form.value.checkout_date).toISOString(),//aa
        near_rail_station:this.form.value.near_rail_station,
        near_bus_stand:this.form.value.near_bus_stand,

      }

     // console.log(data);
      

      this.perExecutionService.updateGuestHouseFinding(this.getGuestHouseDetails.id,data).subscribe(
        res=>{
         // console.log(res);
          
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )

    }else{
      this.markFormGroupTouched(this.form);
    }
 }

  handleAddressChange(address) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      latitude: lat,
      longitude: lng,
      address: formattedAddress
    })
  }
  handleRailwayStationChange(address){
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      near_rail_station: formattedAddress
    })
  }
  handleBusStationChange(address){
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    this.form.patchValue({
      near_bus_stand: formattedAddress
    })
  }


  
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
  
 

}
