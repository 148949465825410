import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { StockService } from '../../../../services/stock.service';
import { LoadingState } from '../../../../components/loading/loading.component';
import * as Globals from '../../../../globals';
import { ToastrService } from 'ngx-toastr';
import { AppPageStateConfig } from '../../../../../app.config';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-current-stock',
  templateUrl: './current-stock.component.html',
  styleUrls: ['./current-stock.component.scss']
})
export class CurrentStockComponent implements OnInit {
  @Input() siteLocation: number;
  @Input() RefprojectId: number;
  @Output() siteIdEmit: EventEmitter<number> = new EventEmitter<number>();
  @Output() projectIdEmit: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('target', {static: true}) tableContent: ElementRef;
  hasScroll: boolean = false
  scrollAmount;
  public loading: LoadingState = LoadingState.NotReady;
  siteId: number = null;
  projectId: number = null;
  currentStock: any = [];
  itemList: any;
  defaultPagination: number;
  totalReportList: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  pageSize: number;
  headerThOption = [];
  sort_by = '';
  sort_type = '';
  search_key: any;
  stockReportConfig: any;

  constructor(
    public projectSiteServices: projectSiteServices,
    public stockService: StockService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private element: ElementRef
  ) {
    this.stockReportConfig = AppPageStateConfig.stockReportConfig.currentStockConfig;
  }

  ngOnInit() {
    //console.log(this.route.snapshot.params['siteLocation'])
    //console.log()
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    this.loading = LoadingState.Processing;

    if (this.route.snapshot.params['projectId']== undefined) {
      if (this.stockReportConfig.search_key != undefined) {
        this.search_key = this.stockReportConfig.search_key;
      }
      if (this.stockReportConfig.defaultPagination != null) {
        this.defaultPagination = this.stockReportConfig.defaultPagination;
      }
      if (this.stockReportConfig.itemPerPage != null) {
        this.itemPerPage = this.stockReportConfig.itemPerPage;
      }
      if (this.stockReportConfig.pageSize != null) {
        this.pageSize = this.stockReportConfig.pageSize;
      }
    }

    this.headerThOption = [
      {
        id: 1,
        name: "Item Name",
        code: "item_name",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 2,
        name: "Item Code",
        code: "item_code",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 3,
        name: "Unit",
        code: "unit",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      {
        id: 4,
        name: "Current Stock (Current Stock = initial Opening Stock + Cummilative Stock Received - Stock Issued till Date)",
        code: "closing_stock",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      }

    ];

    if ((this.siteLocation) && (this.RefprojectId)) {
      this.siteId = this.siteLocation;
      this.projectId = this.RefprojectId;
      this.getCurrentStock();
    } else
      this.loading = LoadingState.Ready;
  }

  checkScroll() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    if (this.scrollAmount && (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth || this.scrollAmount.scrollWidth == this.scrollAmount.offsetWidth)) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }
  }
  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

  sortTable(value) {
    this.loading = LoadingState.Processing;
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });
    this.sort_by = value;
    this.sort_type = type;
    this.defaultPagination = 1;
    this.getCurrentStock();
  }



  getCurrentStock() {
    this.loading = LoadingState.Processing;
    this.projectIdEmit.emit(this.projectId);
    if (this.projectId && this.siteId) {

      let params: URLSearchParams = new URLSearchParams();
      params.set('page', this.defaultPagination.toString());
      if (this.itemPerPage) {
        params.set('page_size', this.itemPerPage.toString());
      }
      if (this.search_key != undefined)
        params.set('product_name', this.search_key)
      if (this.sort_by != '') {
        params.set('field_name', this.sort_by.toString());
      }
      if (this.sort_type != '') {
        params.set('order_by', this.sort_type.toString());
      }
      this.stockService.getCurrentStockReportData(this.projectId, this.siteId, params).subscribe(
        res => {
          this.currentStock = res['results'];
          this.totalReportList = res['count'];
          this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
          this.lower_count = this.itemNo + 1;
          if (this.totalReportList > this.itemPerPage * this.defaultPagination) {
            this.upper_count = this.itemPerPage * this.defaultPagination
          }
          this.checkScroll();
          this.loading = LoadingState.Ready;
        },
        error => {
          if (error.status == 404 && this.stockReportConfig.defaultPagination > 1) {
            this.stockReportConfig.defaultPagination = 1;
            this.ngOnInit();
          }
          else{
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
            this.loading = LoadingState.Ready
          }
        }
      );
    } else {
      this.currentStock = [];
      this.loading = LoadingState.Ready;
    }
  }

  pagination() {
    this.loading = LoadingState.Processing;
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.defaultPagination = this.defaultPagination;
    }
    this.getCurrentStock();
  }

  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.defaultPagination = 1;
    this.pageSize = this.itemPerPage;
    if (this.route.snapshot.params['projectId'] == undefined) {
      this.stockReportConfig.defaultPagination = this.defaultPagination;
      this.stockReportConfig.itemPerPage = +item;
    }
    this.getCurrentStock();
  }

  getSearchValueFromParent(siteId, projectId) {
    this.siteId = siteId;
    this.projectId = projectId;
    this.getCurrentStock();
  }

  exportReport() {
    // hit api for excel
    if (this.currentStock.length > 0) {
      this.loading = LoadingState.Processing;
      let params: URLSearchParams = new URLSearchParams();
      if (this.search_key != undefined)
        params.set('product_name', this.search_key)
      if (this.sort_by != '') {
        params.set('field_name', this.sort_by.toString());
      }
      if (this.sort_type != '') {
        params.set('order_by', this.sort_type.toString());
      }
      this.stockService.getCurrentStockExcel(this.projectId, this.siteId, params).subscribe(
        res => {
          // console.log(res)
          window.open(res.url)
          this.loading = LoadingState.Ready;
        },
        error => {
          console.log(error)
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      );
    } else {
      this.toastr.error('No data available to export', '', {
        timeOut: 3000,
      });
    }
  }
}
