import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../../../core/services/setting.service';
import { ExecutionPurchaseService } from '../../../../../core/services/execution-purchase.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentViewModalComponent } from '../../../../../core/components/document-view-modal/document-view-modal.component';
import { InformationShowDialogueComponent } from '../../../../../core/components/information-show-dialogue/information-show-dialogue.component';
import { ConfirmDialogComponent } from '../../../../../core/components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-quotation-inner',
  templateUrl: './quotation-inner.component.html',
  styleUrls: ['./quotation-inner.component.scss']
})
export class QuotationInnerComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  @Output() checkStatusEvent = new EventEmitter<string>();
  form: FormGroup;
  vendorList: any = [];
  externalUserTypeList: any = [];
  vendorDetail: any = [];
  paymentTerm: any = [];
  purchaseQuotationLength: number;
  @Input() requisitionId: number;
  @Input() tempVendorList: any = [];
  @Input() itemDetail: any = [];
  @Input() disabledChild: number;
  @Output() btnShow = new EventEmitter<boolean>();

  purchaseQuotationList: any = [];
  previousPurchaseQuotationList: any = [];
  units: any = [];
  vendorCode: any = [];
  quotationQuantity: number;
  quotationItems: any;
  getSelectedVendor: any;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  has_comparitive_statement: any;
  quotationDetail: any = [];
  totalQuotationQuantity: number = 0;
  quotationItemQuantity: any = [];
  isEdit: boolean = false;
  quotationItemId: number;
  disabledVendor: any = [];
  selectedVendor: any = [];
  todayDate;
  formControlCount: number = 1;
  vendorListLenght: number;
  requisitionApprovalStatus: number;
  selectedFile: any = [];
  viewFile: string = '';
  averagePrice: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService,
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
   // console.log(this.itemDetail)
   // console.log(this.vendorList)
    this.vendorList = Object.assign([], this.tempVendorList);
    const current = new Date();
    this.todayDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.form = this.formBuilder.group({
      quotation_inner_tab: this.formBuilder.array([this.createQuotationForm()]),
    });

    this.loadQuotationData();

    this.quotationDetail = [
      {
        vendor: null,
        vnedor_code: null,
        payment_terms: null,
        quantity: null,
        approved_quantity: null,
        unit: null,
        price: null,
        delivery_date: null,
        remarks: null,
        gst_no: null
      }
    ]

    if (this.itemDetail.item_details.unit) {
      // this.quotationDetail[0].unit = this.itemDetail.item_details.unit;
    }
    //console.log(this.itemDetail);
  }

  save(key: boolean) {
   // console.log(this.form.value.quotation_inner_tab);

    if (this.form.valid && this.form.value.quotation_inner_tab[0]['quantity'] <= this.itemDetail.approved_quantity) {
      this.loading = LoadingState.Processing;
      var forkArray = [];
      var quotation_form_data = [];
      quotation_form_data = this.form.value.quotation_inner_tab;

      // console.log(quotation_form_data);

      quotation_form_data.forEach((x, index) => {
        const formData: any = new FormData();
        formData.append("requisitions_master", this.requisitionId);
        formData.append("vendor", x.vendor);
        formData.append("payment_terms", x.payment_terms);
        formData.append("quantity", x.quantity);
        formData.append("unit", x.unit);
        formData.append("price", x.price);
        if (x.remarks) {
          formData.append("remarks", x.remarks);
        }

        formData.append("delivery_date", this.settingService.getProperDate(x.delivery_date).toISOString());//aa
        if (x.document_name != null) {
          formData.append("document_name", x.document_name);
        }
        if (x.quotation_document != null) {
          formData.append("document", x.quotation_document, x.quotation_document['name']);
        }

        formData.append("item", this.itemDetail.item_type.item_id);
        //formData.append("item", this.itemDetail.item_details.id);
        formData.append("type", this.itemDetail.type);
        //console.log(formData);
        if (key == false) {
          forkArray.push(this.executionPurchaseService.addPurchaseQuotation(formData))
        }
        else {
          forkArray.push(this.executionPurchaseService.updatePurchaseQuotation(this.quotationItemId, formData))
          this.isEdit = false;
        }
        if (index != 0) {
          this.removeQuotation(0, false);
        }
        this.selectedFile = [];
      })
      forkJoin(forkArray).subscribe(
        (result: any[]) => {
         // console.log(result)

          this.reLoadQuotationData();
          // this.add_tender_document()
          // this.loading = LoadingState.Ready;

          this.toastr.success('Data has been saved', '', {
            timeOut: 3000,
          });

          this.viewFile = ''
          this.form.reset();


          // this.form.setValue['unit'] = this.itemDetail.item_details.Unit;

        },
        err => {
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          // console.log(err)
        }
      )


    } else {
      this.markFormGroupTouched(this.form);
      this.loading = LoadingState.Ready;
    }
  }


  submitForApprovalPerItem(id: number) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure for Approval?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing;
        this.executionPurchaseService.quotationItemApproval(id).subscribe(
          res => {
            this.loading = LoadingState.Ready;
            if (res.quotation_approved == true) {
              if (this.requisitionApprovalStatus['status'] < 3) {
                this.checkStatusEvent.next();
              }

              this.itemDetail.quotation_approved = true;
              this.loadQuotationData();
            }
          },
          error => {
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been Approved', '', {
              timeOut: 3000,
            });
          }
        )
      }
      this.dialogRef = null;
    })
  }

  submitForRevertApprovalPerItem(id: number) {

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure for Reverting the Approval?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing;
        this.executionPurchaseService.quotationItemRevertApproval(id).subscribe(
          res => { console.log(res);
            this.loading = LoadingState.Ready;
            if (res.quotation_approved == false) {
              // if (this.requisitionApprovalStatus['status'] < 3) {
              //   this.checkStatusEvent.next();
              // }

              this.itemDetail.quotation_approved = false;
            }
          },
          error => {
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been Approved', '', {
              timeOut: 3000,
            });
          }
        )
      }
      this.dialogRef = null;
    })
  }


  submitForApproval() {
    this.loading = LoadingState.Processing;
    var data = {
      status: 3
    }

    this.executionPurchaseService.updateSubmitForApproval(this.requisitionId, data).subscribe(
      res => {
        this.requisitionApprovalStatus = res;
        this.checkStatusEvent.next();

        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been Approved', '', {
          timeOut: 3000,
        });
      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  

  loadQuotationData() {
    this.loading = LoadingState.Processing;
    var forkArray = [];
    forkArray.push(this.executionPurchaseService.getPaymentTermsMaster())
    forkArray.push(this.executionPurchaseService.getUnit())
    forkArray.push(this.executionPurchaseService.getPreviousPurchaseQuotation(this.itemDetail.item_type.item_id, this.itemDetail.item_details.unit))
    forkArray.push(this.executionPurchaseService.getPurchaseQuotation(this.requisitionId, this.itemDetail.item_type.item_id, this.itemDetail.item_details.unit))
    forkArray.push(this.executionPurchaseService.getrequisitionSubmitForApproval(this.requisitionId))
    forkJoin(forkArray).subscribe(
      ([paymentTerm, units, previousPurchaseQuotationList, purchaseQuotationList, requisitionApprovalStatus]) => {
        if (paymentTerm) {
          this.paymentTerm = paymentTerm['result'];
        }
        if (units) {

          this.units = units;
        }
        if (previousPurchaseQuotationList) {
          this.averagePrice = previousPurchaseQuotationList['average_price'];
          this.previousPurchaseQuotationList = previousPurchaseQuotationList['result'];
        }
        if (purchaseQuotationList) {
          this.has_comparitive_statement = purchaseQuotationList['has_comparitive_statement'];
          this.purchaseQuotationList = purchaseQuotationList['result']['details_by_group'];
          this.purchaseQuotationLength = Object.keys(this.purchaseQuotationList).length;
          if (purchaseQuotationList['quotation_status'] == 1) {
            this.btnShow.emit(true);
          } else {
            this.btnShow.emit(false);
          }

        }

        if (requisitionApprovalStatus) {
          this.requisitionApprovalStatus = requisitionApprovalStatus['result'];
          if (this.requisitionApprovalStatus['status'] > 2) {
            this.btnShow.emit(false);
          }
        }

        if (this.purchaseQuotationList.length > 0) {
          this.purchaseQuotationList.forEach(x => {
            var index = this.vendorList.findIndex(z => z.id == x.vendor_id)
            if (index != -1) {
              this.vendorList.splice(index, 1);
            }

          })
        }

        this.vendorListLenght = this.vendorList.length;
        this.loading = LoadingState.Ready;

      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }

  reLoadQuotationData() {
    this.loading = LoadingState.Processing;
    var forkArray = [];
    forkArray.push(this.executionPurchaseService.getPurchaseQuotation(this.requisitionId, this.itemDetail.item_type.item_id, this.itemDetail.item_details.unit))
    forkArray.push(this.executionPurchaseService.getPreviousPurchaseQuotation(this.itemDetail.item_type.item_id, this.itemDetail.item_details.unit))
    forkJoin(forkArray).subscribe(
      ([purchaseQuotationList]) => {

        if (purchaseQuotationList) {
          this.purchaseQuotationList = purchaseQuotationList['result']['details_by_group'];
          this.purchaseQuotationLength = Object.keys(this.purchaseQuotationList).length;
          if (purchaseQuotationList['quotation_status'] == 1) {
            this.btnShow.emit(true);
          } else {
            this.btnShow.emit(false);
          }
          // this.vendorList = Object.assign([], this.tempVendorList);
          // if (Object.keys(this.purchaseQuotationList).length > 0) {
          //   for (let [key, value] of Object.entries(this.purchaseQuotationList)) {
          //     console.log('key', key);
          //     console.log('value', value);
          //     let tempData: any = [];
          //     tempData = value;
          //     tempData.forEach(element => {
          //       var index = this.vendorList.findIndex(z => z.id == x.vendor_id)
          //       if (index != -1) {
          //         this.vendorList.splice(index, 1);
          //       }
          //     });
              
          //   }
          // }
        //  console.log(this.vendorList)
         // this.vendorListLenght = this.vendorList.length;
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        // console.log(err)
      }
    )
  }


  getVendorCode(index: number, id: number) {

    this.loading = LoadingState.Processing;
    this.settingService.getExternalUserDetails(id).subscribe(
      res => {
        // console.log(res)
        this.vendorDetail = res;
       // console.log(this.quotationDetail)
       // console.log(this.itemDetail)
        this.quotationDetail[index].vnedor_code = res.code;
        this.quotationDetail[index].gst_no = res.gst_no;
        this.selectedVendor[index] = id;
        this.quotationDetail[index].unit = this.itemDetail.item_details.unit;
        this.quotationDetail[index].quantity = this.itemDetail.item_details.quantity;
        this.quotationDetail[index].approved_quantity = this.itemDetail.approved_quantity;


        this.loading = LoadingState.Ready
      },
      error => {
        //console.log(error)

        this.loading = LoadingState.Ready
      }
    )

  }

  checkQuantity(index) {

    var requisitionItemQuantity = this.itemDetail.item_details.quantity;
    this.totalQuotationQuantity = 0;

    this.quotationItemQuantity[index] = parseInt(this.quotationDetail[index].quantity);
    //console.log(this.quotationItemQuantity);
    this.quotationItemQuantity.forEach(x => {
      this.totalQuotationQuantity += x;
    })

    //console.log(this.totalQuotationQuantity);
    if (this.totalQuotationQuantity > requisitionItemQuantity) {
      this.quotationDetail[index]['quantity'] = '';
      this.quotationItemQuantity[index] = 0;
      this.toastr.error('Total quantity exceeded!', '', {
        timeOut: 3000,
      });
    }


  }

  trackSelectedVendor(index, id) {
   // console.log(id)
    let vendorArray = [];

    var quotation_form_data = [];
    quotation_form_data = this.form.value.quotation_inner_tab;
    // console.log(quotation_form_data)
    quotation_form_data.forEach(x => {
      vendorArray.push(x.vendor);
      this.getSelectedVendor = x.vendor;
    });
    this.getSelectedVendor = vendorArray;
  }

  createQuotationForm() {
    return this.formBuilder.group({
      requisitions_master: [this.requisitionId],
      vendor: [null, Validators.required],
      vnedor_code: [null],
      payment_terms: [null, Validators.required],
      quantity: [null, Validators.required],
      unit: [null],
      price: [null, Validators.required],
      delivery_date: [null, Validators.required],
      remarks: [null],
      document_name: [null],
      document: [null],
      quotation_document: null,
      file: null

    })
  }


  add_quotation() {

    const control = <FormArray>this.form.controls['quotation_inner_tab'];
    control.push(this.createQuotationForm());
    this.formControlCount += 1;
    if (this.itemDetail.item_details.unit) {
      var previousUnit = this.itemDetail.item_details.unit;
    } else {
      var previousUnit = null;
    }
    var q = {
      vendor: null,
      vnedor_code: null,
      payment_terms: null,
      quantity: null,
      unit: previousUnit,
      price: null,
      delivery_date: null,
      remarks: null,
    }
    this.quotationDetail.push(q);
    let addToListVendor = [];

    this.form.value.quotation_inner_tab.forEach(x => {
      if (x.vendor != null) {
        addToListVendor.push(x.vendor);
      }
    })

    // this.vendorList.forEach((z,index) => {

    //   if (addToListVendor.indexOf(z.id) !== -1) {
    //      this.vendorList.splice(index, 1);
    //      } 
    // })
  }

  rejectQuotation(itemDetailsId:number, id: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure for Rejecting the Approval?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing;
        this.executionPurchaseService.quotationItemRevertApproval(itemDetailsId).subscribe(
          res => { 
            this.loading = LoadingState.Ready;
            if (res.quotation_approved == false) {
              this.itemDetail.quotation_approved = false;
              this.executionPurchaseService.deleteSavedQuotations(id).subscribe(
                res => {
                  this.form.reset();
                  this.selectedFile = [];
                  this.viewFile = '';
                  this.reLoadQuotationData();
                },
                error => {
                  //console.log(error);
                  this.loading = LoadingState.Ready
                }
              )
            }
          },
          error => {
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been Rejected', '', {
              timeOut: 3000,
            });
          }
        )
      }
      this.dialogRef = null;
    })
  }

  removeQuotation(index: number, key: boolean) {
    if (key == true) {
      var removedFromVendorList = [];
      let removedVendorId = this.form.value.quotation_inner_tab[index].vendor;
      removedFromVendorList.push(removedVendorId);
      if (removedVendorId != undefined) {
        //console.log(this.tempVendorList);
        let getVendorToList = this.tempVendorList.filter(x => x.id == removedVendorId)

        let a = this.vendorList.filter(z => z.id == removedVendorId);
        if (a.length < 1) {
          this.vendorList.push(getVendorToList[0])
        }
      }
    }


    const control = <FormArray>this.form.controls['quotation_inner_tab'];
    control.removeAt(index);
    // console.log(this.vendorCode);
    this.vendorCode.splice(index, 1);
    this.quotationItemQuantity.splice(index, 1);
    this.quotationDetail.splice(index, 1);

    this.formControlCount -= 1;
  }
  getFormArrayControl(form) {
    return form.get('quotation_inner_tab').controls
  }

  onSelectFile(event, index) {
   // console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['quotation_inner_tab'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          quotation_document: event.target.files[0]

        })
        reader.readAsDataURL(event.target.files[i]);
        var data = { index: index }
        this.selectedFile.push(data);
        //console.log(this.selectedFile)
      }

    }
  }

  viewQuotationDocument(documents: any) {
    var data = [];
    if (documents.document_name != null)
      data.push({
        name: documents.document_name,
        url: documents.document
      });

    if (data.length > 0) {
      let dialogRef = this.dialog.open(DocumentViewModalComponent, {
        width: '525px',
        data: { items: data }
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log(result)
      })
    }
  }

  viewVendorDetails(vendor: any) {
    //console.log(vendor)
    if (vendor) {
      let dialogRef = this.dialog.open(InformationShowDialogueComponent, {
        width: '525px',
        data: vendor,
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log(result)
      })
    }

  }

  editSavedQuotation(data: any) {
    this.selectedFile = [];
    this.viewFile = '';
    this.quotationItemId = data.id;

    this.quotationDetail[0].vendor_name = data.vendor_name;
    this.quotationDetail[0].vendor = data.vendor_id;
    this.quotationDetail[0].vnedor_code = data.vendor_code;
    this.quotationDetail[0].payment_terms = data.payment_terms;
    this.quotationDetail[0].quantity = data.quantity;
    this.quotationDetail[0].approved_quantity = data.quantity;
    this.quotationDetail[0].unit = data.unit;
    this.quotationDetail[0].price = data.price;
    var start_date = new Date(data.delivery_date)
    this.quotationDetail[0].delivery_date = {
      year: start_date.getFullYear(),
      month: start_date.getMonth() + 1,
      day: start_date.getDate()
    }
    this.quotationDetail[0].document_name = data.document_name;
    this.quotationDetail[0].document = data.document;
    this.quotationDetail[0].remarks = data.remarks;
    if (this.quotationDetail[0].document) {
      this.viewFile = this.quotationDetail[0].document;
      var data: any = { index: 0 }
      this.selectedFile.push(data);
    }
    //console.log(this.viewFile)
    this.isEdit = true;


  }

  deleteSavedQuotation(id: number) {
    this.loading = LoadingState.Ready;
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(id);
        this.loading = LoadingState.Processing;

        this.executionPurchaseService.deleteSavedQuotations(id).subscribe(
          res => {
            this.form.reset();
            this.selectedFile = [];
            this.viewFile = '';
            this.reLoadQuotationData();
            // this.loading = LoadingState.Ready;
          },
          error => {
            //console.log(error);
            this.loading = LoadingState.Ready
          }
        )
      }
      this.dialogRef = null;
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }



}
