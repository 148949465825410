import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-add-contact-modal',
  templateUrl: './add-contact-modal.component.html',
  styleUrls: ['./add-contact-modal.component.scss']
})
export class AddContactModalComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  tenderId: number;
  contactDetails: any;
  form: FormGroup;
  modal_title: string;
  designationId: number;
  constructor(
    public dialogRef: MatDialogRef<AddContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenderService: TenderService,
    private formBuilder: FormBuilder,
  ) {
    this.tenderId = data['tenderId'];
    this.designationId = data['designationId'];
    if (data['contactDetails'] != undefined) {
      this.contactDetails = data['contactDetails']
    }

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tender: [this.tenderId, Validators.required],
      designation: [this.designationId, Validators.required],
      prev_field_details_exist: 'no',
      field_details: this.formBuilder.array([])
    });

    if (this.contactDetails != undefined) {
      if (this.contactDetails['field_details'].length > 0) {
        this.form.patchValue({
          prev_field_details_exist: 'yes'
        })
        this.contactDetails['field_details'].forEach(x => {
          this.add_field_details(x.field_label, x.field_value, x.field_type)
        })
      }
    }
    else {
      this.add_field_details("Name", "", "text")
      this.add_field_details("Contact No", "", "phone")
      this.add_field_details("Email", "", "email")
    }
    this.loading = LoadingState.Ready
  }

  create_field_details(label, value, type) {
    if (type == "text") {
      return this.formBuilder.group({
        field_label: [label, Validators.required],
        field_value: [value, Validators.required],
        field_type: [type, Validators.required]
      });
    }
    else if (type == "email") {
      return this.formBuilder.group({
        field_label: [label, Validators.required],
        field_value: [value, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ]],
        field_type: [type, Validators.required]
      });
    }
    else if (type == "phone") {
      return this.formBuilder.group({
        field_label: [label, Validators.required],
        field_value: [value, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ]],
        field_type: [type, Validators.required]
      });
    }

  }

  add_field_details(label, value, type) {
    const control = <FormArray>this.form.controls['field_details'];
    control.push(this.create_field_details(label, value, type));
  }

  delete_field_details(index: number) {
    const control = <FormArray>this.form.controls['field_details'];
    control.removeAt(index);
  }

  getFormArrayControl(form) {
    return form.get('field_details').controls
  }

  close() {
    this.dialogRef.close(true);
  }

  save() {
   // console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing
      this.tenderService.addTenderResourceContact(this.form.value).subscribe(
        res => {
         // console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  update() {
    console.log(this.form.value)
    if (this.form.valid) {
      this.loading = LoadingState.Processing;
      var contactId = this.contactDetails['id']
      this.tenderService.editTenderResourceContact(contactId, this.form.value).subscribe(
        res => {
         // console.log(res)
          this.dialogRef.close({ data: res });
          this.loading = LoadingState.Ready
        },
        error => {
          console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
    else {
      this.markFormGroupTouched(this.form)
    }
  }

  // 
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
