import { Component, OnInit, Input, Output,EventEmitter} from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../services/setting.service';
import { LocalService } from '../../../services/local.service';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
// import { restoreView } from '@angular/core/src/render3';
import { DocumentViewModalComponent } from '../../../../core/components/document-view-modal/document-view-modal.component';
import {PoItemListModelComponent} from '../po-item-list-model/po-item-list-model.component';


@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {
  @Input() requisitionId: number;
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  vendorList: any = [];
  externalUserTypeList: any = [];
  transportType: number;
  orderFromVendor: any =[];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  selectedItems: any = [];
  selectedItemsDetails:any =[[]];
  getQuantity:any = [[]];
  totalItemQuantity:any = [];

  po_master_arr:any = [];
  poDocumentList:any = [];

  selectItemWithParentIndex:any = [[]];
  
  completed_status:number = 0;
  itemLenghtPerVendor:any =[];
  itemFormControlLenght:any = [];
  totalVendorCount:number;
  totalPoFormControlCount:number = 1;
  purchaseOrderList:any = [];
  checkItemQuantity:any = [];
  approvedItemList:any = [];
  approvedForPoItemList:any = [];
  vendorDetails:any = [];
  getVendorId:number;
  getSelectItemWithUom:any = [];
  checkAllItemAvailableQuantity:boolean;
  isItemQuantityFiled:boolean;
  tempSelectedItems:any = [];
  transportCostListing:any = [];
  activeTransport: boolean = false;
  transportCost: number = 0;
  transportCostType: number;
  currentUserArray: any = []
  permissionList: any = []
  selectedFile: any = [];
  purchaseStatus: number;

  @Output() checkStatusEvent = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService,
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public localService: LocalService,

  ) {
    this.purchaseStatus = 1;
  }


  ngOnInit() {

   
    this.form = this.formBuilder.group({
      purchaseOrderFormArray: this.formBuilder.array([])
    })

    this.setPurchaseOrderForm();


    this.selectedItemsDetails[0] = [
      {
        id:null,
        mat_code:null,
        name:null,
        payment_terms_name:null,
        price:null,
        quantity:null,
        unit_id:null,
        unit_name:null,
        availavle_quantity:null
      }
    ];

    this.selectedItems[0] = [
      {
        id:null,
        mat_code:null,
        name:null,
        payment_terms_name:null,
        price:null,
        quantity:null,
        unit_id:null,
        unit_name:null,
        availavle_quantity:null,
        disabled:false
      }
    ];
    this.tempSelectedItems[0] = [
      {
        id:null,
        mat_code:null,
        name:null,
        payment_terms_name:null,
        price:null,
        quantity:null,
        unit_id:null,
        unit_name:null,
        availavle_quantity:null,
        disabled:false
      }
    ];

    this.selectItemWithParentIndex[0] = [
     {
      item:null, 
      quantity:null,
      unit_id:null,
      unit_name:null,
      availavle_quantity:null
     }
    ];
    this.getQuantity[0] = [{
      itemId:null,
      ItemsQuantity:null
    }]
    //console.log(this.selectItemWithParentIndex);
    
    this.getPurchaseOrderVendor();
    this.getPOList();
  }
  data = {
    purchaseOrderFormArray: [
      {
        vendor: null,
        poItemsMAP: [
          {

            item: null,
            quantity: "",
            uom: "",
            uom_name:''
          }
        ],
        date_of_po: null,
        po_no: null,
        po_amount: null,
        documents:[
          {
            document_name:null,
            document:null,
            po_document:null
            
          }
        ],
        transport_cost: 0,
        transport_cost_type: null,
      }
    ]
  }

  getPurchaseOrderVendor() {
    let params: URLSearchParams = new URLSearchParams();
    params.set('section', 'comparative statement');

    this.loading = LoadingState.Processing;
    var forkArray = [];

    forkArray.push(this.executionPurchaseService.getPOVendorsList(this.requisitionId))
    forkArray.push(this.executionPurchaseService.getPurchaseOrderList(this.requisitionId))
   // forkArray.push(this.executionPurchaseService.getPOVendorsList(this.requisitionId))
    forkArray.push(this.executionPurchaseService.transportCostList())
    forkArray.push(this.executionPurchaseService.getPermissionLevel(params))

    forkJoin(forkArray).subscribe(
      ([poVendorList,purchaseOrderList,transportCostListing,permissionList]) => {
    
          if(poVendorList){
            this.orderFromVendor = poVendorList['result'];
            this.totalVendorCount = this.orderFromVendor.length;
           
            this.vendorDetails = poVendorList['result'];
           // console.log(this.vendorDetails)
           
            this.vendorDetails.forEach((x,index)=>{
             x.item_details.forEach((item,itemIndex)=>{
              if(item.available_quantity === 0){
                //x.item_details.splice(itemIndex,1);
              
                this.checkAllItemAvailableQuantity = true;
              }
              else{
                this.checkAllItemAvailableQuantity = false;
                
              }
             })
            
            })
          }
          if(purchaseOrderList){
            this.purchaseStatus = purchaseOrderList['status'];
            this.purchaseOrderList = purchaseOrderList['result'];
            
            this.purchaseOrderList.forEach(x=>{
              this.approvedForPoItemList.push(x.items)
            });

            if(this.purchaseStatus > 1){
              this.checkStatusEvent.next();
            }

          }
          // if(i == 2){
          //   this.vendorDetails = result[i]['result'];
          //   console.log(this.vendorDetails)
           
          //   this.vendorDetails.forEach((x,index)=>{
          //    x.item_details.forEach((item,itemIndex)=>{
          //     if(item.available_quantity === 0){
          //       //x.item_details.splice(itemIndex,1);
              
          //       this.checkAllItemAvailableQuantity = true;
          //     }
          //     else{
          //       this.checkAllItemAvailableQuantity = false;
                
          //     }
          //    })
            
          //   })
           
          // }
          if(transportCostListing){
            this.transportCostListing = transportCostListing['result'];
          
          }

          if(permissionList){
            var userid = this.localService.getJsonValue('userid');
      
            if(permissionList['result'].user_approve_details.length > 0){
              this.permissionList = permissionList['result'];
              this.permissionList.user_approve_details.forEach(x => {
                if(x.user_details.id == userid){
                  this.currentUserArray = x;
                }
              });
            }

           // console.log(this.currentUserArray)
          }

        this.loading = LoadingState.Ready;
      },
      err => {
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready;
        
      }
    )
  }
  
  getTransportType(){

    var costType = this.transportCostListing.filter(x => x.id == this.transportCostType)[0];
   // console.log()
    if(costType.name == 'For'){
      //this.transportCost = 0;
      this.activeTransport = false;
     //this.form.controls['transport_cost'].setErrors(null);
     //this.form.controls['transport_cost'].clearValidators();
    }
    if(costType.name == 'Cost to Company'){
      //this.transportCost = 0;
      this.activeTransport = true;
     // this.form.controls['transport_cost'].setErrors(null);
     // this.form.controls['transport_cost'].clearValidators();
    }
    if(costType.name == 'Quoted by vendor'){
      
      //this.transportCost = 0;
     // this.form.controls['transport_cost'].setValidators([Validators.required]);
      this.activeTransport = true;
    }
  }

  onChangeVendor(event: number,parentIndex){
   
    this.getVendorId = event;
    this.selectedItems[parentIndex] = [];
    this.tempSelectedItems[parentIndex] = [];
    this.itemLenghtPerVendor[parentIndex] = [];
    this.selectItemWithParentIndex[parentIndex][0].item = null;
   
   
  
    this.vendorDetails.forEach((x,index) => {
    if(x.vendor_id && event == x.vendor_id){
   
      x.item_details.forEach((k,index) => {
        
         //
        let q = {
                id:k.item,
                mat_code:k.mat_code,
                name:k.name,
                payment_terms_name:k.payment_terms_name,
                price:k.price,
                quantity:k.available_quantity,
                unit_id:k.unit_id,
                unit_name:k.unit,
                availavle_quantity:k.available_quantity,
                disabled:false
              }
       
        this.selectedItems[parentIndex].push(q);
        this.tempSelectedItems[parentIndex].push(q);
       
        
      })
      
     
    }
    });

   this.itemLenghtPerVendor[parentIndex].push(this.selectedItems[parentIndex].length);
   this.itemFormControlLenght[parentIndex] =1;
  }

  getSelectedItem(e,index,parentIndex){
    //console.log(e);

    if(this.selectedItems[parentIndex].length >0){
      this.selectedItems[parentIndex].forEach(element => {
         //console.log(element)
        if(element.id == e.itemId && element.unit_id == e.unitId){
          this.selectedItemsDetails[parentIndex][index] = element;
          this.selectedItemsDetails[parentIndex][index].disabled = true;
        
         }
      });
     
    }
    this.selectItemWithParentIndex[parentIndex][index].unit_name = this.selectedItemsDetails[parentIndex][index].unit_name;
    this.selectItemWithParentIndex[parentIndex][index].unit_id = this.selectedItemsDetails[parentIndex][index].unit_id;
  }


 
  onSelectFile(event, index,parentIndex){
    //console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['purchaseOrderFormArray']['controls'][parentIndex]['controls']['documents'];
        //console.log(control)
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          po_document: event.target.files[0]
          
        })
        reader.readAsDataURL(event.target.files[i]);
        var data = { index: index}
        this.selectedFile.push(data);
        //console.log(this.selectedFile)
      }
    }
  }
  
  savePurchaseOrder(){
    
    if(this.form.valid){

      
      this.loading = LoadingState.Processing;
      this.po_master_arr = [];
      var po_documents_data = [];
      
  
      this.form.value.purchaseOrderFormArray.forEach((x,index)=>{
        //console.log(x.poItemsMAP)
        var required_on_date = this.settingService.getProperDate(x.date_of_po);
        x.date_of_po = required_on_date.toISOString();//aa
        x.poItemsMAP.forEach((y,index1) => {
          x.poItemsMAP[index1].item = y.item.itemId
        })
        this.po_master_arr = x;
        po_documents_data = x.documents;
        
        var data = {
          //completed_status:this.completed_status,
          requisitions_master:this.requisitionId,
          po_master:[this.po_master_arr],
        }

      
        
        this.poDocumentList = [];
        this.executionPurchaseService.addPurchaseOrder(data).subscribe(
          res=>{
            this.checkStatusEvent.next();
            var forkArray = [];
          
            if(po_documents_data[0].document && po_documents_data.length > 0)
              {
                po_documents_data.forEach(x => {
                  const formData: any = new FormData(); 
                  formData.append("purchase_order", res.po_master[0].id);
                  formData.append("document_name", x.document_name);
                  formData.append("document", x.po_document, x.po_document['name']);
                  forkArray.push(this.executionPurchaseService.addPurchaseOrderDocument(formData))
              })
            }else{
              this.getPOList();
              this.getPurchaseOrderVendor();
            }
            forkJoin(forkArray).subscribe(
              (result: any[]) => {
                // console.log(result)
                result.forEach(y => {
                  this.poDocumentList.push(y)
                })
                
                this.getPOList();
                this.getPurchaseOrderVendor();
                this.loading = LoadingState.Ready;
              },
              err => {
                this.loading = LoadingState.Ready;
                
              }
            )
            this.toastr.success('Data has been Approved','',{
              timeOut: 3000,
            });
          
          },
          error=>{
            console.log(error);
            this.loading = LoadingState.Ready;
          }
        )
        if(index!=0){
          this.removePo(index);
        }
        this.selectedFile = [];
          this.form.reset();

      })
     
    }else{
      this.markFormGroupTouched(this.form)
    }
    
  }

 
  addNewpo() {
    
    let control = <FormArray>this.form.controls.purchaseOrderFormArray;
    control.push(
      this.formBuilder.group({
        vendor: [null, Validators.required],
        date_of_po: [null, Validators.required],
        po_no: [null, Validators.required],
        po_amount: [null, Validators.required],
        documents: this.formBuilder.array([]),
        
        transport_cost: [0, Validators.required],
        transport_cost_type: [null, Validators.required],
        poItemsMAP: this.formBuilder.array([this.poItemCreate()])
      })
    )

    this.totalPoFormControlCount = control.length;
    this.selectedItemsDetails[control.length -1] = [
      {
        id:null,
        mat_code:null,
        name:null,
        payment_terms_name:null,
        price:null,
        quantity:null,
        unit_id:null,
        unit_name:null,
        availavle_quantity:null
      }
    ];
    
    this.selectItemWithParentIndex[control.length -1] = [
      {
       quantity:null,
       unit_id:null,
       unit_name:null,
       availavle_quantity:null
      }
     ];

     this.getQuantity[control.length -1] = [{
      itemId:null,
      ItemsQuantity:null
    }]

    
  }

  removePo(index) {
    let control = <FormArray>this.form.controls.purchaseOrderFormArray;
    control.removeAt(index)
    
  
  this.selectItemWithParentIndex.splice(index,1);
  this.selectedItemsDetails.splice(index,1);
  this.getQuantity.splice(index,1)
  }

  poItemCreate(){
    return  this.formBuilder.group({
      item: [null, Validators.required],
      quantity: ["", Validators.required],
      uom: ["", Validators.required],
      uom_name:[""]
    })
  }

  addNewPOItem(control,parentIndex) {
  // console.log(this.getQuantity)
   
   this.selectedItems[parentIndex].forEach((x,index)=>{
     this.getQuantity[parentIndex].forEach((y,i)=>{
       if(x.id == y.itemId && x.unit_id == y.unit_id){
        this.selectedItems[parentIndex].splice(index,1);
       }
     })

      
   })
  //console.log(this.selectedItems)
    control.push(
      this.formBuilder.group({
        item: [null, Validators.required],
        quantity: ["", Validators.required],
        uom: ["", Validators.required],
        uom_name:[""]
      }))

     
      this.itemFormControlLenght[parentIndex] += 1;
      

      let q = {
        id:null,
        mat_code:null,
        name:null,
        payment_terms_name:null,
        price:null,
        quantity:null,
        unit_id:null,
        unit_name:null,
        availavle_quantity:null
      }
      let p = {
        quantity:null,
        unit_id:null,
        unit_name:null,
        availavle_quantity:null
      };
      this.selectedItemsDetails[parentIndex].push(q);
      this.selectItemWithParentIndex[parentIndex].push(p);
      this.isItemQuantityFiled = false;
     
  }

  addNewDocument(control){
    control.push(
      this.formBuilder.group({
        document_name: [null, Validators.required],
        document: [null, Validators.required],
        po_document: null,
      }))
  }
  
  deleteItems(control,index,parentIndex,item) {

    this.selectedItemsDetails[parentIndex][index].disabled = false;
    control.removeAt(index);
    this.selectedItemsDetails[parentIndex].splice(index, 1);
    // let itemId = this.getQuantity[parentIndex][index].itemId?this.getQuantity[parentIndex][index].itemId:'';
   // console.log(item)
    if(item!=undefined){
      
      let getRemovedItem = this.tempSelectedItems[parentIndex].filter(x => (x.id == item.itemId && x.unit_id == item.unitId))[0];
      let checkItem = this.selectedItems[parentIndex].filter(x => (x.id == item.itemId && x.unit_id == item.unitId))[0];
      getRemovedItem.availavle_quantity = getRemovedItem.quantity;
     // console.log(getRemovedItem)
      if(checkItem==undefined){
        this.selectedItems[parentIndex].push(getRemovedItem)
      }
    }
    this.getQuantity[parentIndex].splice(index,1);
   
    
    this.selectItemWithParentIndex[parentIndex].splice(index,1);
    this.itemFormControlLenght[parentIndex] -= 1;
    this.isItemQuantityFiled = true;

  }

  deleteDocument(control,index){
    control.removeAt(index);
  }

  setPurchaseOrderForm() {
    let control = <FormArray>this.form.controls.purchaseOrderFormArray;
    this.data.purchaseOrderFormArray.forEach(x => {
      control.push(this.formBuilder.group({
        vendor: [x.vendor, Validators.required],
        date_of_po: [x.date_of_po, Validators.required],
        po_no: [x.po_no, Validators.required],
        po_amount: [x.po_amount, Validators.required],
        documents: this.createDocument(x),
        
        transport_cost: [x.transport_cost],
        transport_cost_type: [x.transport_cost_type, Validators.required],
        poItemsMAP: this.setpurchaseOrderItems(x)
      }))
    })
   // console.log(control);
  }

  setpurchaseOrderItems(x) {
   // console.log(x)
    let arr = new FormArray([])
    x.poItemsMAP.forEach(y => {
      arr.push(this.formBuilder.group({
        item: [y.item, Validators.required],
        quantity: [y.quantity, Validators.required],
        uom: [y.uom, Validators.required],
        uom_name:[y.uom_name, Validators.required]
      }))
    })
    return arr;
  }

  createDocument(x){
    let doc = new FormArray([])
    x.documents.forEach(y => {
      doc.push(this.formBuilder.group({
        document_name: [y.document_name],
        document: [y.document],
        po_document:null

      }))
    })
    return doc;
    
  }



  checkQuantity(index,e,parentIndex,uomId){

    var selectedItemsQuantity = parseFloat(this.selectedItemsDetails[parentIndex][index].quantity);
    var unit_id = this.selectedItemsDetails[parentIndex][index].unit_id;
    this.isItemQuantityFiled = false;


    var previousSelectedItemsQuantity  = selectedItemsQuantity;
    var getItemQuantity = parseFloat(e);
    // console.log(getItemQuantity)
   
    this.getQuantity[parentIndex][index] = {itemId:this.selectedItemsDetails[parentIndex][index].id,ItemsQuantity:getItemQuantity,unit_id:uomId};
    this.getSelectItemWithUom = {itemId:this.selectedItemsDetails[parentIndex][index].id,unit_id:uomId};
    //console.log(this.getQuantity)
    
    if(e>selectedItemsQuantity && uomId==unit_id)
    {
      
      this.selectItemWithParentIndex[parentIndex][index].quantity = '';
        this.toastr.error('Total quantity exceeded!', '', {
            timeOut: 3000,
          });

    }
    
    else{
       this.selectedItemsDetails[parentIndex][index].availavle_quantity -= getItemQuantity;
        
      var check = 0;
      this.getQuantity[parentIndex].forEach(x=>{
       
        if(this.selectedItemsDetails[parentIndex][index].id == x.itemId && this.selectedItemsDetails[parentIndex][index].unit_id == x.unit_id){
         
         check += x.ItemsQuantity;

        }
        
        if(check > this.selectedItemsDetails[parentIndex][index].quantity){
          this.selectItemWithParentIndex[parentIndex][index].quantity = '';
          this.toastr.error('Total quantity of'+ this.selectedItemsDetails[parentIndex][index].name + 'is exceeded!', '', {
            timeOut: 3000,
          });
        }else{
          this.isItemQuantityFiled = true;
        } 
        
      })

      
    }

    // console.log(this.selectedItemsDetails[parentIndex][index].availavle_quantity);

   
  }

  getPOList(){
    this.loading = LoadingState.Processing;
    this.executionPurchaseService.getPurchaseOrderList(this.requisitionId).subscribe(
      res=>{
         

        this.purchaseOrderList = res['result'];
      

        this.loading = LoadingState.Ready;
      },
      error=>{
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  submitForApproval(){
    var params = {
     completed_status:2
    }

    this.executionPurchaseService.updateSubmitForApproval(this.requisitionId,params).subscribe(
      res => {
        //this.checkEventStatus.next();
        this.checkStatusEvent.next();
        this.getPOList();
        this.getPurchaseOrderVendor();
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been Approved','',{
          timeOut: 3000,
        });
      },
      error=>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
 

  viewPoDocument(documents: any){
   // console.log(documents)
    var data = [];
    documents.forEach(x =>{
      if (x.document_name != null)
      data.push({
        name: x.document_name,
        url: x.document
      });
    })
   // console.log(data);

      if (data.length > 0) {
        let dialogRef = this.dialog.open(DocumentViewModalComponent, {
          width: '525px',
          data: { items: data }
        });
        dialogRef.afterClosed().subscribe(result => {
         // console.log(result)
        })
      }
  }

  viewitems(items:any){
    let dialogRef = this.dialog.open(PoItemListModelComponent, {
      width: '600px',
      data: items
     
    });
    dialogRef.afterClosed().subscribe(result => {
     

    })
  }
  
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
