import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenderService } from '../../../services/tender.service';
import { LoadingState } from '../../loading/loading.component';
import { AddContractorModalComponent } from '../add-contractor-modal/add-contractor-modal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-resource-contractors-vendors-inner-contractor-tab',
  templateUrl: './resource-contractors-vendors-inner-contractor-tab.component.html',
  styleUrls: ['./resource-contractors-vendors-inner-contractor-tab.component.scss']
})
export class ResourceContractorsVendorsInnerContractorTabComponent implements OnInit {
  @Input() tenderId: number;
  loading: LoadingState = LoadingState.NotReady;
  tenderContractorList: any = [];
  constructor(
    public dialog: MatDialog,
    private tenderService: TenderService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    var forkArray = [];
    var params = '';
    params = '?tender=' + this.tenderId
    forkArray.push(this.tenderService.getTenderResourceContractorList(params))
    forkJoin(forkArray).subscribe(
      (res: any[]) => {
        //console.log(res)
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.tenderContractorList = res[i]['result'];
          }
        }
        this.loading = LoadingState.Ready
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }



  openContractorModal() {
    let dialogRef = this.dialog.open(AddContractorModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if (result['data'] != undefined) {
        this.tenderContractorList.push(result['data'])
      }
    })
  }

  editContractor(i) {
    let ContractorDetailsData = Object.assign({}, this.tenderContractorList[i]);
    let dialogRef = this.dialog.open(AddContractorModalComponent, {
      width: '525px',
      data: { tenderId: this.tenderId, ContractorDetails: ContractorDetailsData }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if (result['data'] != undefined) {
        this.tenderContractorList[i] = result['data']
      }
    })
  }

}
