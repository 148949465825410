import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { SettingService } from '../../../services/setting.service';
import { ExecutionPurchaseService } from '../../../services/execution-purchase.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { DocumentViewModalComponent } from '../../../../core/components/document-view-modal/document-view-modal.component';
import { AddPaymentPlanModelComponent } from '../add-payment-plan-model/add-payment-plan-model.component';

@Component({
  selector: 'app-payment-tab',
  templateUrl: './payment-tab.component.html',
  styleUrls: ['./payment-tab.component.scss']
})
export class PaymentTabComponent implements OnInit {
  @Input() requisitionId: number;
  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  totalPaymentAmount: any = [];
  totalPayable: number = 0;


  vendorDetails:any = [];
  vendorPoList:any = [];
  paymentList:any = [];
  totalPaidAmount:number;
  checkPoForPaymentAmount:any = [];
  paymentPlanList:any = [];
  paymentPlanDueAmount:number;
  perPoMaxAmount:number;
  paymentDetails:any = [];
  deliveryStatus:number;
  completed_status:number = 0;
  selectedFile: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService,
    private executionPurchaseService: ExecutionPurchaseService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {

    
  }


  ngOnInit() {

    this.form = this.formBuilder.group({
      PaymentFromData: this.formBuilder.array([
        this.createPaymentForm()
      ])
    })
    this.paymentDetails = [
      {
        payment_amount:null,
        payment_date:null,
        invoice_number:null,
        transaction_id:null,
        vendor:null,
        po_no:null
      }
    ]
    if(this.requisitionId){
      this.loadPaymentData();
      this.getPaymentList();
      this.getPaymentPlanList();
    }
    
  }


  loadPaymentData() {
    this.loading = LoadingState.Processing;
    var forkArray = [];

    forkArray.push(this.executionPurchaseService.getTotalAmountPayable(this.requisitionId))

    forkJoin(forkArray).subscribe(
      (result: any[]) => {
       
        for (var i = 0; i < result.length; i++) {
          if(i == 0){
            var getArray = [];
            this.totalPaymentAmount = result[i]['result'];
           
            //get sum of amount
            this.totalPaymentAmount.forEach((x,index) => {
             this.totalPayable += parseFloat(x.price);
                getArray.push({id:x.vendor,vendor_name:x.vendor_name,vendor_code:x.vendor_code});
             });

          }
          this.vendorDetails = this.removeDuplicates(getArray, "id");
          
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
        this.loading = LoadingState.Ready;

      }
    )
    
  }

  save(){
    if(this.form.valid){
      this.loading = LoadingState.Processing;
      var payment_data = [];
      var payment_document_data = [];
      payment_data = this.form.value.PaymentFromData;

      payment_data.forEach((x,index)=>{
        payment_document_data = x.documents;

        var data = {
          requisitions_master:this.requisitionId,
          completed_status:this.completed_status,
          payment_amount:x.payment_amount,
          payment_date:this.settingService.getProperDate(x.payment_date).toISOString(),//aa
          invoice_number:x.invoice_number,
          transaction_id:x.transaction_id,
          vendor:x.vendor,
          po_no:x.po_no,
         
        }

        this.executionPurchaseService.addPayment(data).subscribe(
          res => {
           // console.log(res);
            var forkArray = [];
            if(payment_document_data.length > 0)
              {
                payment_document_data.forEach(y => {
                  
                  const formData: any = new FormData(); 
                  formData.append("purchases_made", res['id']);
                  formData.append("document_name", y.document_name);
                  formData.append("document", y.payment_document, y.payment_document['name']);
                  forkArray.push(this.executionPurchaseService.addPaymentDocument(formData))
 
              })

              forkJoin(forkArray).subscribe(
                (result: any[]) => {
                  // console.log(result)
                  result.forEach((y,index) => {
                    this.deleteDocument(index,0);
                  })
                  
                  
                  this.loading = LoadingState.Ready;
                },
                err => {
                  this.loading = LoadingState.Ready;
                  
                }
              )
            }
            this.selectedFile = [];
            this.getPaymentList();
            this.loading = LoadingState.Ready;
            this.toastr.success('Data has been saved','',{
              timeOut: 3000,
            });
            this.form.reset();
          },
          error => {
           // console.log(error);
            this.loading = LoadingState.Ready;
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
          }
        )
        
      })


    }
    else{
      this.markFormGroupTouched(this.form);
    }
  }

  getPaymentList(){
    this.totalPaidAmount = 0;
    this.executionPurchaseService.getPaymentList(this.requisitionId).subscribe(
      res=>{
        this.deliveryStatus = res['completed_status'];
      //  console.log(this.deliveryStatus)
        this.paymentList = res['result'];
        this.paymentList.forEach(x => {
          this.totalPaidAmount += parseFloat(x.payment_amount);
        })
       
        //console.log(res)
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  getPaymentPlanList(){
    this.paymentPlanDueAmount = 0;
    this.executionPurchaseService.getPaymentPlanList(this.requisitionId).subscribe(
      res=>{
        this.paymentPlanList = res['result'];
        this.paymentPlanList.forEach(x => {
          this.paymentPlanDueAmount += parseInt(x.due_amount);
        })
      // console.log(this.paymentPlanList)
      },
      error =>{
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }
  
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
  }

  getVendorsPo(e,index){
    this.resetAll(index,'vendor');
    this.vendorPoList = [];
    this.totalPaymentAmount.forEach((x,index)=>{
      if(e==x.vendor){
        if(this.vendorPoList.indexOf(x.po_no)==-1){
            this.vendorPoList.push(x.po_no);
        }

      }
    })

  }

  resetAll(index:number,key:string){
    //console.log(this.form.value)
    const control = <FormArray>this.form.controls['PaymentFromData']['controls'];
    if(key == 'vendor'){
        control[index].patchValue({
          po_no:null,
          invoice_number:null,
          payment_amount:null,
          payment_date:null,
          transaction_id:null
      })
    }else{
      control[index].patchValue({
        payment_amount:null
      })
    }
   
  }
  getAmountPerPo(e,index)
  {
    this.resetAll(index,'po');
    this.perPoMaxAmount = 0;
    this.totalPaymentAmount.forEach(x=>{
     if(e==x.po_no){
      this.perPoMaxAmount += parseFloat(x.price);
     }
   })

   this.paymentList.forEach(y =>{
    if(e==y.po_no){
      this.perPoMaxAmount -= parseFloat(y.payment_amount);
     }

   })

 
  }


  checkPaymentAmount(e,index){
    var val = parseFloat(e);
    if(val > this.perPoMaxAmount){
      this.toastr.error('Total payable amount exceeded!', '', {
        timeOut: 3000,
      });
      this.paymentDetails[index].payment_amount = "";
    }

    
    if(this.deliveryStatus == 4)
    {
      if((this.totalPaidAmount+val)==this.totalPayable){
        this.completed_status = 5;
      }
    }
    
  }

  createPaymentForm(){
    return this.formBuilder.group({
      requisitions_master:this.requisitionId,
      completed_status:this.completed_status,
      payment_amount:[null,Validators.required],
      payment_date:[null,Validators.required],
      invoice_number:[null,Validators.required],
      transaction_id:[null,Validators.required],
      vendor:[null,Validators.required],
      po_no:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
       ])

    })
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: [''],
      document: [null],
      payment_document: null
    })
  }

  addNewDocument(parentIndex){
    const control = <FormArray>this.form.controls['PaymentFromData']['controls'][parentIndex]['controls']['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index,parentIndex){
    const control = <FormArray>this.form.controls['PaymentFromData']['controls'][parentIndex]['controls']['documents'];
    control.removeAt(index);
    // this.returnGood.splice(index, 1);
  }
 
  onSelectFile(event,index,parentIndex,key:string){
    
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
       
          const control = <FormArray>this.form.controls['PaymentFromData']['controls'][parentIndex]['controls'][key];
      
        
       
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          payment_document: event.target.files[0]
          
        })
        reader.readAsDataURL(event.target.files[i]);
        var data = { index: index}
        this.selectedFile.push(data);
      }
    }
  }

  viewPoDocument(documents: any){
    // console.log(documents)
     var data = [];
     documents.forEach(x =>{
       if (x.document_name != null)
       data.push({
         name: x.document_name,
         url: x.document
       });
     })
   //  console.log(data);
 
       if (data.length > 0) {
         let dialogRef = this.dialog.open(DocumentViewModalComponent, {
           width: '525px',
           data: { items: data }
         });
         dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
         })
       }
   }

  openAddPaymentModal(){
    let dialogRef = this.dialog.open(AddPaymentPlanModelComponent, {
      width: '525px',
      data: {vendor: this.vendorDetails,requisitionId:this.requisitionId,paymentDetail: this.totalPaymentAmount}
     
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPaymentPlanList();

    })
   }


  addPaymentForm() {
    let control = <FormArray>this.form.controls.PaymentFromData;
    control.push(
      this.formBuilder.group({

        payment_amount: [''],
        payment_date: [''],
        invoice_number: [''],
        transaction_id: [''],
        vendor_name: [''],
        p_o_number: [''],
        documents: this.formBuilder.array([])
      })
    )
  }
  

 
  getFormArrayControl(form) {
    return form.get('PaymentFromData').controls
  }



  



  removePaymentForm(index: number) {
    const control = <FormArray>this.form.controls['PaymentFromData'];
    control.removeAt(index);
  }



  

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
