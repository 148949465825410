import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenderService {
  constructor(
    private http: HttpClient
  ) {

  }

  addNewTender(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tenders_add/', data)
  }

  getTenderType(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_type_master_add/')
  }

  getTenderBasicDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tenders_edit/' + id + '/')
  }

  editTender(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tenders_edit/' + id + '/', data)
  }

  addTenderDoc(data: any) {
    return this.http.post(environment.apiEndpoint + 'tenders_doc_add/', data)
  }

  getTenderDocList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_doc_by_tender_id/' + id + '/')
  }

  editTenderDoc(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tenders_doc_edit/' + id + '/', data)
  }

  deleteTenderDoc(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tenders_doc_delete/' + id + '/', {})
  }

  tenderEligibilityFieldsAdd(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_eligibility_fields_add/', data)
  }

  getTenderEligibilityFieldsList(tenderId: number, eligibilityType: string): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_eligibility_fields_list/' + tenderId + '/' + eligibilityType + '/')
  }

  getTenderEligibilityStatusReason(tenderId: number, eligibilityType: string): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_not_eligibility_reason_add/' + tenderId + '/' + eligibilityType + '/')
  }

  addTenderEligibilityStatusReason(tenderId: number, eligibilityType: string, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_not_eligibility_reason_add/' + tenderId + '/' + eligibilityType + '/', data)
  }

  addTenderBidderType(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_bidder_type_add/', data)
  }

  getBidderDetailsByTenderId(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_bidder_details_by_tender_id/' + id + '/')
  }

  updateTenderBidderType(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_bidder_details_by_tender_id/' + id + '/', data)
  }

  getPartnerList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'partners_add/')
  }
  getPartnerDetail(data): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'partners_edit/' + data + '/')
  }
  editPartnerDetail(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'partners_edit/' + id + '/', data)
  }

  deletePartner(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'partners_delete/' + id + '/', {})
  }

  addPartner(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'partners_add/', data)
  }

  addTenderSitePhoto(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_site_photos_add/', data)
  }

  editTenderSitePhoto(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_site_photos_edit/' + id + '/', data)
  }

  deleteTenderSitePhoto(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_site_photos_delete/' + id + '/', {})
  }
  // 
  getTenderSitePhotoList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_site_photos_list/' + id + '/')
  }

  addTenderCoordinate(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_location_add/', data)
  }

  getTenderCoordinateList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_location_list/' + id + '/')
  }

  editTenderCoordinate(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_location_edit/' + id + '/', data)
  }

  deleteTenderCoordinate(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_location_delete/' + id + '/', {})
  }
  // 
  getTenderMaterialUnitList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'unit_add/')
  }

  addTenderMaterial(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_materials_add/', data)
  }

  getTenderMaterialList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_materials_list/' + id + '/')
  }

  editTenderMaterial(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_materials_edit/' + id + '/', data)
  }

  deleteTenderMaterial(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_materials_delete/' + id + '/', {})
  }
  // 
  addTenderSupplierCrusher(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_co_supplier_add/', data)
  }

  getTenderSupplierCrusherList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_co_supplier_list/' + params)
  }

  getTenderRawMaterialSupplierList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_materials_external_user_mapping_list/' + params)
  }

  addTenderRawMaterialSupplier(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_materials_external_user_mapping_add/', data)
  }

  editTenderSupplierCrusher(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_co_supplier_edit/' + id + '/', data)
  }

  deleteTenderSupplierCrusher(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_co_supplier_delete/' + id + '/', {})
  }
  // 
  addTenderResourceMaterialSupplier(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_material_supplier_add/', data)
  }

  getTenderResourceMaterialSupplierList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_material_supplier_list/' + params)
  }

  editTenderResourceMaterialSupplier(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_material_supplier_edit/' + id + '/', data)
  }

  deleteTenderResourceMaterialSupplier(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_material_supplier_delete/' + id + '/', {})
  }
  // 
  addTenderResourceEstablishment(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_establishment_add/', data)
  }

  getTenderResourceEstablishmentList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_establishment_add/' + params)
  }

  editTenderResourceEstablishment(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_establishment_edit/' + id + '/', data)
  }

  deleteTenderResourceEstablishment(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_establishment_delete/' + id + '/', {})
  }
  // 
  addTenderResourceHydrological(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_hydrological_add/', data)
  }

  getTenderResourceHydrologicalList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_hydrological_add/' + params)
  }

  editTenderResourceHydrological(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_hydrological_edit/' + id + '/', data)
  }

  deleteTenderResourceHydrological(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_hydrological_delete/' + id + '/', {})
  }
  // 
  addTenderResourceContractor(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_contarctor_w_type_add/', data)
  }

  getTenderResourceContractorList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_contarctor_w_type_add/' + params)
  }

  editTenderResourceContractor(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_contarctor_w_type_edit/' + id + '/', data)
  }

  deleteTenderResourceContractor(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_contarctor_w_type_delete/' + id + '/', {})
  }
  //
  getTenderResourcePMModelList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_vendor_model_master_add/')
  }

  addTenderResourcePM(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_vendor_add/', data)
  }

  getTenderResourcePMList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_vendor_add/' + params)
  }

  editTenderResourcePM(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_vendor_edit/' + id + '/', data)
  }

  deleteTenderResourcePM(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_vendor_delete/' + id + '/', {})
  }
  //
  addTenderResourceDesignation(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_contact_designation_add/', data)
  }

  getTenderResourceDesignationList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contact_designation_add/' + params)
  }

  editTenderResourceDesignation(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contact_designation_edit/' + id + '/', data)
  }

  deleteTenderResourceDesignation(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contact_designation_delete/' + id + '/', {})
  }
  // 
  addTenderResourceContact(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_survey_resource_contact_details_add/', data)
  }

  getTenderResourceContactList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contact_details_add/' + params)
  }

  editTenderResourceContact(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contact_details_edit/' + id + '/', data)
  }

  deleteTenderResourceContact(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_survey_resource_contact_details_delete/' + id + '/', {})
  }

  // 
  uploadTenderInitialCosting(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_initial_costing_upload_file/', data)
  }

  addTenderInitialCosting(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_initial_costing_add/', data)
  }

  getTenderInitialCosting(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_initial_costing_details/' + id + '/')
  }
  // 
  getTenderDocumentCheckEligible(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_check_tab_document_upload_add/' + id + '/')
  }

  addTenderDocumentCheckEligible(id: number, data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_check_tab_document_upload_add/' + id + '/', data)
  }

  addTenderEligibilityFieldDocument(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_eligibility_field_document_add/' + id + '/', data)
  }

  addTenderEligibilityTabDocument(tenderId: number, type: string, data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_eligibility_tab_document_add/' + tenderId + '/' + type + '/', data)
  }

  getTenderEligibilityTabDocument(tenderId: number, type: string): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_eligibility_tab_document_add/' + tenderId + '/' + type + '/')
  }

  deleteTenderEligibilityTabDocument(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_eligibility_tab_document_delete/' + id + '/', {})
  }

  getTenderPriceTabDocument(tenderId: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_tab_document_price_add/?tender=' + tenderId)
  }

  addTenderPriceTabDocument(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_tab_document_price_add/', data)
  }

  deleteTenderPriceTabDocument(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_tab_document_price_delete/' + id + '/', {})
  }
  // 
  addTenderApproval(id: number, data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_approval_add_or_update/' + id + '/', data)
  }

  getTenderApprovalDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_details_for_approval/' + id + '/')
  }

  // 
  addTenderStatus(id: number, data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_status_add_or_update/?tender=' + id, data)
  }

  getTenderStatusDetails(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_status_add_or_update/?tender=' + id)
  }

  uploadTenderParticipent(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_stat_participents_f_view/', data)
  }

  uploadTenderComparison(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_stat_comparison_f_view/', data)
  }

  addTenderExtraDoc(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'tender_stat_document_add/', data)
  }

  deleteTenderExtraDoc(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tender_stat_document_delete/' + id + '/', {})
  }

  // getMachineryTypeList(): Observable<any> {
  //   return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_machinery_type_add/')
  // }

  getMachineryTypeByTenderList(id: number): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tender_survey_resource_contractors_o_vendors_machinery_type_de_add/?tender=' + id)
  }


  //getTenderListWithFilter

  getTenderList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tenders_list/?' + params)
  }

  archiveTender(id: number): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'tenders_archive/' + id + '/', {})
  }

  getArchivedTenderList(params): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tenders_archive_list/?' + params)
  }

  getTenderListCount(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'tenders_list_count/')
  }

  tenderStatusUpdate(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/tender/status/update/' + id + '/', data)
  }


}