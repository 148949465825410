import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-eligibility-technical-inner-tab',
  templateUrl: './eligibility-technical-inner-tab.component.html',
  styleUrls: ['./eligibility-technical-inner-tab.component.scss']
})
export class EligibilityTechnicalInnerTabComponent implements OnInit {
  @Input() tenderId: number;
  @Input() eligibilityList: any;
  eligibilityType: string;
  @Output() valueChange = new EventEmitter();
  constructor() {
    this.eligibilityType = "technical";
  }

  ngOnInit() {
    //console.log(this.tenderId)

   
    //console.log("eligibilityList =>" + this.eligibilityList)
  }

  getInnerDataTabValue(data: any) {
    //console.log(data)
    //console.log("dsdsdasasdfasdfasd")
    this.eligibilityList = data;
    this.valueChange.emit(this.eligibilityList)
  }
}
