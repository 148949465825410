import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DailyExpenseService {
  constructor(
    private http: HttpClient
  ) {
    
  }

  addDailyExpense(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/daily_expence/add/', data)
  }

  getDailyExpense(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'daily_expence_add/?' + params)
  }

  getDailyExpenseReport(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'daily_expence_report/?' + params)
  }

  dailyExpApproval(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'daily_expence_status_update/' + id + '/', data)
  }

  getDailyExpenseAccountApprover(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/account_user/add/')
  }
  addDailyExpenseAccountApprover(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/account_user/add/', data)
  }
  updateDailyExpenseAccountApprover(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/account_user/edit/' + id + '/', data)
  }

  getDailyExpenseHOApprover(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/ho_user/add/')
  }
  addDailyExpenseHOApprover(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/ho_user/add/', data)
  }
  updateDailyExpenseHOApprover(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/ho_user/edit/' + id + '/', data)
  }

  // 10-08-2020
  dailyExpenseReportApproval(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/daily_expence/list/?' + params)
  }

  dailyExpStatusUpdate(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/daily_expence/status/update/', data)
  }

  dailyExpPaymentUpdate(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/daily_expence/payment/update/', data)
  }

  dailyExpenseReportDownload(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/daily_expence/list/download/?' + params)
  }

}
