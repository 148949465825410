import { Component } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { LocalService } from "./core/services/local.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "npm";
  constructor(
    private permissionsService: NgxPermissionsService,
    private localService: LocalService
  ) {
    // this.localService.setJsonValue('loggedinToken', '19ed6c45db068096f0284f49e67d830ce814e770a829cfec0b2b08edd1ffb74d');
    // this.localService.setJsonValue('userid', '9')
  }

  ngOnInit(): void {
    this.loadPermission();
  }

  loadPermission() {
    this.permissionsService.flushPermissions();
    const perm = ["dsd"];
    perm.push(this.localService.getJsonValue("userRole"));
    this.permissionsService.addPermission(perm);
    this.permissionsService.loadPermissions(
      perm,
      (permissionName, permissionStore) => {
        return !!permissionStore[permissionName];
      }
    );
  }
}
