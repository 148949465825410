import { Component, OnInit, Input } from '@angular/core';
import {PerExecutionService} from '../../../services/per-execution.service';
import { LoadingState } from '../../loading/loading.component';

@Component({
  selector: 'app-p-and-m-tab',
  templateUrl: './p-and-m-tab.component.html',
  styleUrls: ['./p-and-m-tab.component.scss']
})
export class PAndMTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() tabId:number; 
  loading: LoadingState = LoadingState.NotReady;
  masterData:any = [];
  approveStatus:any = [];

  constructor(
    private perExecutionService:PerExecutionService
  ) { }

  ngOnInit() {
    //console.log(this.projectId);
    this.getMasterData();
    this.getStatus();
  }

  getMasterData(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.machinaryTypeListByProject(this.projectId).subscribe(
      res => {
        this.masterData = res['result'];
       
        this.loading = LoadingState.Ready;
      },
      error => {
      //  console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  slideIt(actionReturn) {
    
    var slidingDiv =  document.getElementById("toggel-inner-nav").querySelector("ul");
    var divWidth = slidingDiv.offsetWidth - document.getElementById("toggel-inner-nav").offsetWidth;
  //  console.log(divWidth);
    
    // var stopPosition = 50;
    // var startPosition = 1500;
    
    if (actionReturn ==  "toRight") {
     // console.log(parseInt(getComputedStyle(slidingDiv).left));
      if (parseInt(getComputedStyle(slidingDiv).left) > -385)
      {

        slidingDiv.style.left = parseInt(getComputedStyle(slidingDiv).left) -divWidth+ "px";
        //setTimeout(slideIt, 1);
      }
    }
    if (actionReturn ==  "toLeft") {
      if (parseInt(getComputedStyle(slidingDiv).left) < 0 ) {
        slidingDiv.style.left = parseInt(getComputedStyle(slidingDiv).left) + divWidth + "px";
       // setTimeout(slideIt, 1);
      }
    }
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
       // console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }

}
