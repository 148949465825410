import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'app-office-furniture-inner-tab',
  templateUrl: './office-furniture-inner-tab.component.html',
  styleUrls: ['./office-furniture-inner-tab.component.scss']
})
export class OfficeFurnitureInnerTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() approveStatus:any = [];
  constructor() { }

  ngOnInit() {
    //console.log(this.projectId);
  }

}
