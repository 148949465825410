import { Component, OnInit,Input} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';
import { SettingService} from '../../../services/setting.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../core/components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-utilities-tiffin-box-inner-tab',
  templateUrl: './utilities-tiffin-box-inner-tab.component.html',
  styleUrls: ['./utilities-tiffin-box-inner-tab.component.scss']
})
export class UtilitiesTiffinBoxInnerTabComponent implements OnInit {

  @Input() projectId:number; 
  @Input() tabId:number; 
  form: FormGroup;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  tiffinBoxDocumentList:any = [];
  loading: LoadingState = LoadingState.NotReady;
  tiffinBoxListData :any = [];
  tiffinBoxDetails: any = [];
  tiffinBoxTypeDetails :any = []
  listEdit:boolean;
  approveStatus:any = [];

 


  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private perExecutionService:PerExecutionService,
    private toastr: ToastrService,
    public settingService:SettingService,
  ) { }

  ngOnInit() {
    //console.log(this.projectId);

    this.form = this.formBuilder.group({
      project:[this.projectId],
      available:[null,Validators.required],
      tiffin_box_types:this.formBuilder.group({
        make_of_tiffin_box:[null,Validators.required],
        quantity:[null,Validators.required],
        rate:[null,Validators.required],
      }),
      requirment_s_date:[null,Validators.required],
      requirment_e_date:[null,Validators.required],
      budgeted_cost:[null,Validators.required],
      executed_cost:[null,Validators.required],
      documents:this.formBuilder.array([
        //this.createDocument()
      ])
    })
    if(this.projectId){
      this.getTiffinBoxList();
      this.getTiffinBoxDetails();
      this.getStatus();
    }
   
  }

  createDocument(){
    return this.formBuilder.group({
      document_name: ['', Validators.required],
      document: [null, Validators.required],
      tiffin_document: null
    })
  }

  getFormArrayControl(form) {
    return form.get('documents').controls
  }

  addDocument()
  {
    const control = <FormArray>this.form.controls['documents'];
    control.push(this.createDocument());
  }

  deleteDocument(index:number)
  {
    const control = <FormArray>this.form.controls['documents'];
    control.removeAt(index);

  }

  viewDoc(documentData){
    window.open(documentData.document,documentData.document_name,'height=700,width=800,scrollbars=yes');
  }

  save()
  {
    if(this.form.valid){
      var tiffin_box_documents_data = [];
      tiffin_box_documents_data = this.form.value.documents;

      const data = {
        project:this.projectId,
        available:this.form.value.available,
        requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
        requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
        budgeted_cost:this.form.value.budgeted_cost,
        executed_cost:this.form.value.executed_cost,
        tiffin_box_types:
        {             
          project:this.projectId,
          make_of_tiffin_box:this.form.value.tiffin_box_types.make_of_tiffin_box,
          quantity:this.form.value.tiffin_box_types.quantity,
          rate:this.form.value.tiffin_box_types.rate
        }
      }

      this.tiffinBoxDocumentList = [];
      this.perExecutionService.addTiffinBoxUtilitiesAminites(data).subscribe(
        res => {
          console.log(res);
          var forkArray = [];

          tiffin_box_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", res.tiffin_box_types.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.tiffin_document, x.tiffin_document['name']);
              forkArray.push(this.perExecutionService.addTiffinBoxDocumentUtilitiesAminites(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.tiffinBoxDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )

          this. getTiffinBoxList(); 
          this.loading = LoadingState.Ready;
          this.toastr.success('Data has been saved','',{
            timeOut: 3000,
          });
        },
        error=>{
         // console.log(error);
          this.loading = LoadingState.Ready;
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        })

     // console.log(data);

    }else
    {
      console.log("error");
      this.markFormGroupTouched(this.form);
    
    }
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
       // console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }


  getTiffinBoxDetails(){
    this.perExecutionService.getTiffinBoxUtilitiesAminites(this.projectId).subscribe(
      res => {
       if(res['result'][0]){
        this.tiffinBoxDetails = res['result'][0];
       // console.log(this.tiffinBoxDetails);

        var start_date = new Date(this.tiffinBoxDetails['requirment_s_date'])
            this.tiffinBoxDetails.requirment_s_date = {
              year: start_date.getFullYear(),
              month: start_date.getMonth()+1,
              day: start_date.getDate()
        
            }
            var end_date = new Date(this.tiffinBoxDetails['requirment_e_date'])
            this.tiffinBoxDetails.requirment_e_date = {
              year: end_date.getFullYear(),
              month: end_date.getMonth()+1,
              day: end_date.getDate()
              
            }
          
          this.tiffinBoxDetails.budgeted_cost = parseFloat(this.tiffinBoxDetails.budgeted_cost).toFixed(2);
          this.tiffinBoxDetails.executed_cost = parseFloat(this.tiffinBoxDetails.executed_cost).toFixed(2);
       }
       
        this.loading = LoadingState.Ready;
      },
      error =>{
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  getTiffinBoxList(){
    
    this.perExecutionService.getTiffinBoxListUtilitiesAminites(this.projectId).subscribe(
      res => {
        this.tiffinBoxListData = res['result'];
        
         //console.log(this.tiffinBoxListData);
        this.loading = LoadingState.Ready;
      },
      error => {
        console.log(error);
        this.loading = LoadingState.Ready;
      }
    )
  }

  deleteTiffinBoxList(id){
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    this.dialogRef.afterClosed().subscribe(result => {
     if(result){
      this.loading = LoadingState.Processing
      this.perExecutionService.deleteTiffinBoxListUtilitiesAminites(id).subscribe(
        res => {
        this.getTiffinBoxList();
         // console.log(res)
          this.loading = LoadingState.Ready
          this.toastr.success('Data has been deleted','',{
            timeOut: 3000,
          });
        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
		  
        }
      )
	 }
	 this.dialogRef = null;
	 })
    
  }

  edittffin(data){
    this.tiffinBoxTypeDetails = data;
   // console.log(this.tiffinBoxTypeDetails);
    this.tiffinBoxDocumentList = this.tiffinBoxTypeDetails.tiffin_box_document_details;
    this.listEdit = true;
    this. getTiffinBoxList(); 
  }

  // update only Tiffin Box data with document
  updateTiffinBox()
  {
    //console.log(this.tiffinBoxTypeDetails);
    //console.log(this.tiffinBoxDetails);
    var tiffin_box_documents_data = [];
    tiffin_box_documents_data = this.form.value.documents;

    if(this.form.valid){

      this.loading = LoadingState.Processing;
      const updateTiffinData = {
        make_of_tiffin_box: this.form.value.tiffin_box_types.make_of_tiffin_box,
        quantity: this.form.value.tiffin_box_types.quantity,
        rate: this.form.value.tiffin_box_types.rate,
        project: this.projectId,
        tiffin_box: this.tiffinBoxTypeDetails.tiffin_box,
        tiffin_box_details:{
          available:this.form.value.available,
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost,
        }
      }
      //console.log(updateTiffinData);

      //this.tiffinBoxDocumentList = [];
      this.perExecutionService.editTiffinBoxListUtilitiesAminites(this.tiffinBoxTypeDetails.id,updateTiffinData).subscribe(
        res =>{
          console.log(res);
          var forkArray = [];

          if(this.form.value.documents.length > 0){
            tiffin_box_documents_data.forEach(x => {
              const formData: any = new FormData();
              formData.append("project",this.projectId);
              formData.append("module_id", this.tiffinBoxTypeDetails.id);
              formData.append("document_name", x.document_name);
              formData.append("document", x.tiffin_document, x.tiffin_document['name']);
              forkArray.push(this.perExecutionService.addTiffinBoxDocumentUtilitiesAminites(formData))
          })
          forkJoin(forkArray).subscribe(
            (result: any[]) => {
              // console.log(result)
              result.forEach(y => {
                this.tiffinBoxDocumentList.push(y)
              })
              
              // this.add_tender_document()
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
      }
      this. getTiffinBoxList(); 
      this.loading = LoadingState.Ready;
      this.toastr.success('Data has been saved','',{
        timeOut: 3000,
      });
       
        },
        error =>{
          console.log(error);
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
        }
      )
      

    }else{
       this.markFormGroupTouched(this.form);
    }
  }

  // update Tiffin Box Form add tiffin box data 
  update(){
      if(this.form.valid){

        this.loading = LoadingState.Processing;
        var tiffin_box_documents_data = [];
        tiffin_box_documents_data = this.form.value.documents;

        const updateData = {
          project:this.projectId,
          available:this.form.value.available,
          requirment_s_date:this.settingService.getProperDate(this.form.value.requirment_s_date).toISOString(),//aa
          requirment_e_date:this.settingService.getProperDate(this.form.value.requirment_e_date).toISOString(),//aa
          budgeted_cost:this.form.value.budgeted_cost,
          executed_cost:this.form.value.executed_cost,
          tiffin_box_types:
          {             
            project:this.projectId,
            make_of_tiffin_box:this.form.value.tiffin_box_types.make_of_tiffin_box,
            quantity:this.form.value.tiffin_box_types.quantity,
            rate:this.form.value.tiffin_box_types.rate
          }
        }
      //console.log(updateData);

    this.perExecutionService.editTiffinBoxUtilitiesAminites(this.tiffinBoxDetails.id,updateData).subscribe(
      res =>{
        // console.log(res);
        // console.log(res.tiffin_box_types.id)
       
        if(this.form.value.documents.length > 0){
          var forkArray = [];
          tiffin_box_documents_data.forEach(x => {
            const formData: any = new FormData();
            formData.append("project",this.projectId);
            formData.append("module_id", res.tiffin_box_types.id);
            formData.append("document_name", x.document_name);
            formData.append("document", x.tiffin_document, x.tiffin_document['name']);
            forkArray.push(this.perExecutionService.addTiffinBoxDocumentUtilitiesAminites(formData))
          })
          forkJoin(forkArray).subscribe(
            result => {
              // console.log(result)
              result.forEach(y => {
                this.tiffinBoxDocumentList.push(y)
              })
             
              // this.add_tender_document();
              this.loading = LoadingState.Ready;
            },
            err => {
              this.loading = LoadingState.Ready;
              // console.log(err)
            }
          )
         }
        this. getTiffinBoxList();
        this.loading = LoadingState.Ready;
        this.toastr.success('Data has been saved','',{
          timeOut: 3000,
        });
      },
      error =>{
        this.loading = LoadingState.Ready;
        console.log(error)
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      })
    }
    else{
      this.markFormGroupTouched(this.form);
      console.log("error");
    }
  }


  getFile(file: string) {
    var fileName = file.split('.')
    var fileExt = fileName[fileName.length - 1];
    if (fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png') {
      return file;
    }
    else {
      return 'assets/img/pdf.png'
    }
  }

  editDoc(index) {
    this.tiffinBoxDocumentList[index]['isEdit'] = true
    this.tiffinBoxDocumentList[index]['isFieldError'] = false
  }

  renameDoc(index) {
    if (this.tiffinBoxDocumentList[index]['document_name'] == '') {
      this.tiffinBoxDocumentList[index]['isFieldError'] = true;
    }
    else {
      this.loading = LoadingState.Processing
      this.tiffinBoxDocumentList[index]['isFieldError'] = false;
      var data = {
        document_name: this.tiffinBoxDocumentList[index]['document_name']
      }

      
      this.perExecutionService.editTiffinBoxDocumentUtilitiesAminites(this.tiffinBoxDocumentList[index]['id'], data).subscribe(
        res => {
          // console.log(res)
          this.tiffinBoxDocumentList[index]['isEdit'] = false
          this.loading = LoadingState.Ready

        },
        error => {
          // console.log(error)
          this.loading = LoadingState.Ready
        }
      )
    }
  }

  deleteDoc(index) {
    this.loading = LoadingState.Processing
    this.perExecutionService.deleteTiffinBoxDocumentUtilitiesAminites(this.tiffinBoxDocumentList[index]['id']).subscribe(
      res => {
        this.tiffinBoxDocumentList.splice(index, 1)
        // console.log(res)
        this.loading = LoadingState.Ready
        this. getTiffinBoxList();
      },
      error => {
        // console.log(error)
        this.loading = LoadingState.Ready
      }
    )
  }



  onSelectFile(event, index){
    console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target['result']);
        }
        const control = <FormArray>this.form.controls['documents'];
        control.at(index).patchValue({
          document: event.target.files[0]['name'],
          tiffin_document: event.target.files[0]
        })
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.markFormGroupTouched(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
   }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

}
