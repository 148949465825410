import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as Globals from '../../../core/globals';
import { NotificationService } from '../../services/notification.service';
import { LocalService } from '../../services/local.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedin: Boolean = false;
  is_superuser: any;
  apps: any = [];
  applicationList: any = [];
  name: string = '';
  email: string = '';
  image: string = null;
  applist: any;
  message: any;
  notificationList = []
  demo = Globals.demo;
  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private localService: LocalService,
  ) { }

  ngOnInit() {
   this.loggedin = (this.localService.getJsonValue('isLoggedin') ? true : false);
    this.is_superuser = (this.localService.getJsonValue('is_superuser') ? true : false);
    if (this.loggedin) {
      this.name = this.localService.getJsonValue('name');
      this.email = this.localService.getJsonValue('email');
      this.image = this.localService.getJsonValue('image');
      this.fetchApps();
      this.getNotificationListing()
      this.notificationService.requestPermission(this.localService.getJsonValue('userid'))
      this.receiveMessage()
      this.message = this.notificationService.currentMessage
    }
  }

  receiveMessage(){
    navigator.serviceWorker.addEventListener("message",(event) => {
      console.log(event)
      if(event['data']['firebaseMessagingData']['data']['type'] == "travel-and-tour"){
        this.notificationService.tourTravelNotificationStatus(true);
      }
      else if(event['data']['firebaseMessagingData']['data']['type'] == "daily-expense"){
        this.notificationService.dailyExpenseNotificationStatus(true);
      }
      else if(event['data']['firebaseMessagingData']['data']['type'] == "bills-invoices"){
        this.notificationService.dailyExpenseNotificationStatus(true);
      }
      
      this.toastr.info(event['data']['firebaseMessagingData']['notification']['title'], '', {
        timeOut: 3000,
      });
      //notification received from firebase, now call list
      this.getNotificationListing()
    } )
  }

  getNotificationListing(){
    this.notificationService.getNotificationListing().subscribe(
      response => {
        this.notificationList = response['result']
      },
      error => {
        this.toastr.error('Error in realtime notification receive', '', {
          timeOut: 3000,
        });
      }
    );
  }

  viewNotification(object,index){
    var type = object.notification.data.type
    var sub_type = object.notification.data.sub_type
    var notificationId = object.notification.id

    //read notification
    this.notificationService.readNotification(notificationId).subscribe(
      response => {
        this.notificationList.splice(index, 1);
        //OPEN POPUP
        this.router.navigateByUrl(type+'/'+sub_type)        
      },
      error => {
        this.toastr.error('Error in read notification', '', {
          timeOut: 3000,
        });
      }
    )

  }

  goToSsil(){
    window.open(Globals.baseUrl, "_self");
  }


  fetchApps() {
    this.applicationList = [];
    if (this.is_superuser == true || this.is_superuser == "true") {
      this.applicationService.applicationList().subscribe(
        response => {
          this.apps = response;
          this.apps.forEach(element => {
            this.applicationList.push({
              'module': element,
              'permissions': {
                'cp_g': 7,
                'cp_o': 7,
                'cp_u': 7
              }
            });
          });
          // console.log(this.applicationList)
        },
        error => {
          // console.log(error)
        }
      );
    } else {
      this.applist = JSON.parse(this.localService.getJsonValue("module_access"));
      this.applist.forEach(element => {
        this.applicationList.push({
          'module': element.module,
          'permissions': element.permissions
        });
      });
    }
  }

  goLanding() {
    window.open(Globals.baseUrl+"/#/landing", "_self");
    // window.location.href = "http://166.62.54.122/ssilmanagement/#/landing";
    // this.router.navigate(['/landing'])
  }
  goPMS() {
    window.open(Globals.baseUrl+"/#/pms", "_self");
    // window.location.href = "http://166.62.54.122/ssilmanagement/#/landing";
    // this.router.navigate(['/landing'])
  }

  goAdmin() {
    window.open(Globals.baseUrl+"/#/admin", "_self");
    // window.location.href = "http://166.62.54.122/ssilmanagement/#/admin";
    // this.router.navigate(['/admin'])
  }

  goprofile() {
    window.open(Globals.baseUrl+"/#/account", "_self");
    // window.location.href = "http://166.62.54.122/ssilmanagement/#/account";
    // this.router.navigate(['/account'])
  }

  gopass() {
    window.open(Globals.baseUrl+"/#/pms/profile/resetpassword", "_self");
    // window.location.href = "http://166.62.54.122/ssilmanagement/#/account/changepassword";
    // this.router.navigate(['/account/changepassword'])
  }

  goSpecialPage(moduledet) {
    var userStr = this.localService.getJsonValue('loggedUserName');
    var userNewStr = btoa(userStr);
    if (moduledet.cm_url == 'tms') {
      var url = "http://13.232.240.233:8001/login/" + userNewStr;
      window.open(url, "_self");
    }
  }

  goPage(url,id) {
    this.localService.setJsonValue("currentModuleId", id)
    if (url == 'pms') {
      window.open(Globals.baseUrl + url, "_self");
    }
    else if (url == 'vms') {
      window.open(Globals.baseUrl + url, "_self");
    }
    else if (url == "hrms") {
      window.open(Globals.baseUrl + url, "_self");
    }
    else if (url == "etask") {
      window.open(Globals.baseUrl + url, "_self");
    }
    else if (url == "eticket") {
      window.open(Globals.baseUrl + url, "_self");
    }
    else {
      window.open(url, "_self");
    }

  }

  logout() {
    this.userService.logout().subscribe(
      response => {
        this.localService.clearToken();
        this.loggedin = false;
        window.open(Globals.baseUrl, "_self");
        // this.router.navigate(['/home'])
      },
      error => {
        //console.log(error)
        this.localService.clearToken();
        this.loggedin = false;
        window.open(Globals.baseUrl, "_self");
        // this.router.navigate(['/home'])
      }
    );
  }

}
