import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add-conveyance-model',
  templateUrl: './add-conveyance-model.component.html',
  styleUrls: ['./add-conveyance-model.component.scss']
})
export class AddConveyanceModelComponent implements OnInit {

  form: FormGroup;
  @ViewChild("placesRef", {static: true}) placesRef: GooglePlaceDirective;

  constructor(
    public dialogRef: MatDialogRef<AddConveyanceModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    

  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      date:[null,Validators.required],
      fromAddress:[null,Validators.required],
      toAddress:[null,Validators.required],
      purpose:[null,Validators.required],
      allotedBy:[null,Validators.required],
      amount:[null,Validators.required],

    })
  }

  handleFromAddressChange(address) {
    // Do some stuff
    //console.log(address)
    var lat = address.geometry.location.lat();
    var lng = address.geometry.location.lng();
    var formattedAddress = address.formatted_address
    
    // this.form.patchValue({
    //   latitude: lat,
    //   longitude: lng,
    //   address: formattedAddress
    // })
  }

  submit(){
    if(this.form.valid){

    }else{
      this.markFormGroupTouched(this.form);
    }
  }

  close() {
    this.dialogRef.close(true);
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
