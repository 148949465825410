import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { reportingService } from 'src/app/core/services/reporting.service';
import { SettingService } from 'src/app/core/services/setting.service';
import { LoadingState } from '../../loading/loading.component';

@Component({
  selector: 'app-weekly-material',
  templateUrl: './weekly-material.component.html',
  styleUrls: ['./weekly-material.component.scss']
})
export class WeeklyMaterialComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;
  form: FormGroup;
  @Input() sitearr: any = [];
  @Input() materialDetailsData: any;
  @Input() projectList: any = [];
  @Input() boqList: any = [];
  @Input() activityList: any = [];
  @Output() setDetailsData = new EventEmitter<any>();
  @Output() setSiteData = new EventEmitter<any>();
  @Output() setBoqData = new EventEmitter<any>();
  start_date: any = null;
  end_date:any = null;
  materialList: any = [];
  unitList: any = []
  dateRange: any = [];
  boqDescription = null;

  constructor(private formBuilder: FormBuilder, private settingService: SettingService,
    public reportService: reportingService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      project_id: [null, Validators.required],
      site_location: [null, Validators.required],
      date_range: [null],
      boq: ['', Validators.required],
      material_data: this.formBuilder.array([this.addDataFormGroup()])
    });

    if (this.materialDetailsData == undefined) {
      this.materialDetailsData = {
        project_id: null,
        site_location: null,
        date_range: null,
        boq: ''
      }
    }
    this.loading = LoadingState.Ready;
    this.loadData();
  }

  addDataFormGroup(): FormGroup {
    return this.formBuilder.group({
      materials: [null, Validators.required],
      required_qty: [null, Validators.required],
      available_qty: ['', Validators.required],
      uom: ['', Validators.required]
    });
  }

  loadData() {
    this.loading = LoadingState.Processing;
    var params = 'page_size=0'
    var forkArray = [];
    forkArray.push(this.settingService.getMaterialTypeList(params))
    forkJoin(forkArray).subscribe(
      ([materialdata]) => {
        if(materialdata){
          this.materialList = materialdata['results'];
        }
        this.loading = LoadingState.Ready;
      },
      err => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }  

  removeDataFormGroup(index) {
    const control = <FormArray>this.form.controls['material_data'];
    control.removeAt(index);
  }

  addButtonClick(): void {
    const control = <FormArray>this.form.controls['material_data'];
    control.push(this.addDataFormGroup());
  }
  
  getFormArrayControl(form) {
    return form.get('material_data').controls
  }

  onProgressDateSelected() {

  }

  onMaterialChange(itemId) {
    this.unitList = this.materialList.filter(x => x.id === itemId)[0]['materials_unit_details'];
    
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.start_date = this.settingService.formatDate(event.value[0])
      this.end_date = this.settingService.formatDate(event.value[1])
      this.onDateSelected();
    }
  }

  savedata() {
    if (this.form.valid && this.start_date != null && this.end_date != null) {
      this.loading = LoadingState.Processing
      const formData: any = new FormData();
      formData.append('type_of_report', '4');
      formData.append('project_id', this.form.value.project_id);
      formData.append('site_location', this.form.value.site_location);
      formData.append('boq', this.form.value.boq);
      formData.append('start_date',this.start_date);
      formData.append('end_date',this.end_date);
      var material_data = [];
      this.form.value.material_data.forEach(x => {
        material_data.push({
          materials_id: x.materials,
          required_qty: x.required_qty,
          available_qty: x.available_qty,
          uom_id: x.uom
        })
      });
      formData.append('material_data', JSON.stringify(material_data));
      this.reportService.saveWeeklyMaterialData(formData).subscribe(
        data => {
          var res: any = data;
          this.toastr.success('Data has been succesfully saved !', '', {
            timeOut: 3000,
          });
          var objData = Object.assign([], this.form.value.material_data)
          for (var i = objData.length - 1; i >= 0; i--) {
            this.removeDataFormGroup(i)
          }
          this.addButtonClick();
          this.form.reset();
          this.boqDescription = '';
          this.loading = LoadingState.Ready;
        },
        error => {
          this.toastr.error('Internal error occurred !', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready
        }
      );
    }
    else {
      this.markFormGroupTouched(this.form);
    }
  }

  siteOnchange(val) {
    this.setSiteData.emit(val);
  }

  projectOnChange(val) {
    this.setBoqData.emit(val);
  }

  onBOQSelection(val) {
    if(val) {
      this.boqDescription = this.boqList.filter(x => x.id == val)[0]['item_description'];
    } else {
      this.boqDescription = null
    }
  }

  onDateSelected() {
    if (this.start_date != null) {
      this.setDetailsData.emit(this.materialDetailsData)
      if (this.materialDetailsData.site_location != null && this.materialDetailsData.project_id != null) {
        this.reportService.fetchWeeklyCommonData(this.materialDetailsData.project_id, this.materialDetailsData.site_location,this.start_date, this.end_date, 4).subscribe(
          res => {
            //console.log(res)
            if (res['result'] != '') {
              this.materialDetailsData.boq = res['result']['boq'];
              this.onBOQSelection(this.materialDetailsData.boq);
            }
            else {
              this.materialDetailsData.boq = null;
              this.boqDescription = '';
            }
            this.setDetailsData.emit(this.materialDetailsData)
          },
          error => {
            //console.log(error)
          }
        )
      }
    }

  }
}
