import { Component, OnInit, Input } from '@angular/core';
import { LoadingState } from '../../loading/loading.component';
import {PerExecutionService} from '../../../services/per-execution.service';

@Component({
  selector: 'app-cost-analysis-tab',
  templateUrl: './cost-analysis-tab.component.html',
  styleUrls: ['./cost-analysis-tab.component.scss']
})
export class CostAnalysisTabComponent implements OnInit {
  @Input() projectId:number; 
  @Input() tabId:number; 
  loading: LoadingState = LoadingState.NotReady;
  approveStatus:any = [];

  constructor(
    private perExecutionService:PerExecutionService
  ) { }

  ngOnInit() {
    if(this.projectId){
      this.getStatus();
    }
  }

  getStatus(){
    this.loading = LoadingState.Processing;
    this.perExecutionService.getApprovalTab(this.projectId,this.tabId).subscribe(
      res =>{
        //console.log(res);
        if(res['result'][0]){
          this.approveStatus = res['result'][0];
          
         
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        //console.log(error)
        this.loading = LoadingState.Ready;
      })
  
  }


}
