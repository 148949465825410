import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import * as _moment from 'moment';
import { OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
import { SettingService } from '../../../services/setting.service';
import {AppPageStateConfig} from '../../../../app.config';

const moment = (_moment as any).default ? (_moment as any).default : _moment
export const MY_MOMENT_DATE_TIME_FORMATS = {
  parseInput: 'MM/YYYY',
  fullPickerInput: 'l LT',
  datePickerInput: 'MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS },
  ]
})
export class MonthlyComponent implements OnInit {
  reportarr: any = [];
  @Input() sitearr: any = [];
  @Input() projarr: any = [];
  @Input() siteid: number;
  @Input() projectid: number;
  @Input() currentmaintab: string;
  @Output() getSelectedSite: EventEmitter<number> = new EventEmitter<number>();
  public date = new FormControl(moment());
  csvLinkMonthly: string;
  smonthdate: any;
  currenttab: string = 'progress';
  progressDownloadLink: string;
  labourDownloadLink: string;
  PMDownloadLink: string;
  monthlyProgressReportConfig:any;
  @ViewChild('ctdTabset', {static: true}) ctdTabset;


  constructor(
    private settingService: SettingService
  ) {
    this.monthlyProgressReportConfig = AppPageStateConfig.progressReportsConfig.monthlyProgressReportConfig;
    this.currenttab = this.monthlyProgressReportConfig.tab;
   
   }

  ngOnInit() {
    this.smonthdate = this.settingService.formatDate(this.date.value);

    this.fetchReports();
   
  }

  ngOnChanges() {

  }

  setProjectId(value) {
    this.getSelectedSite.emit(value)
  }

  fetchReports() {
    this.smonthdate = this.settingService.formatDate(this.date.value);
    // console.log(this.smonthdate)
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.monthlyProgressReportConfig.date = this.date.value;
    datepicker.close();
    this.fetchReports()
  }

  getProgressDownloadLink(val) {
    this.progressDownloadLink = val;
    this.checkDownloadLink()
  }


  getLabourDownloadLink(val) {
    this.labourDownloadLink = val;
    this.checkDownloadLink()
  }

  getPMDownloadLink(val) {
    this.PMDownloadLink = val;
    this.checkDownloadLink()
  }

  onTabChange($event) {
    this.currenttab = $event.nextId;
    this.monthlyProgressReportConfig.tab = this.currenttab;
    this.checkDownloadLink()
  }

  ngAfterViewInit() {
    this.switchNgBTab(this.currenttab);
   
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }

  checkDownloadLink() {
    this.csvLinkMonthly = null;
    if (this.currenttab == 'labour') {
      this.csvLinkMonthly = this.labourDownloadLink
    } else if (this.currenttab == 'pandm') {
      this.csvLinkMonthly = this.PMDownloadLink
    } else if (this.currenttab == 'progress') {
      this.csvLinkMonthly = this.progressDownloadLink
    }
  }

}
