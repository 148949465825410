import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingService } from '../../../core/services/setting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingState } from '../../../core/components/loading/loading.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-information-show-dialogue',
  templateUrl: './information-show-dialogue.component.html',
  styleUrls: ['./information-show-dialogue.component.scss']
})
export class InformationShowDialogueComponent implements OnInit {
  loading: LoadingState = LoadingState.NotReady;
  userType:string;
  userTypeStr:string;
  userTypeId:number;
  vendorDetail: any = [];
  externalUserTypeList: any = [];

  constructor(
    public dialogRef: MatDialogRef<InformationShowDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingService: SettingService,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    //console.log(this.data.vendor_id)
    this.getExternalUserList();
  }


  // getExternalUserTypeList(){
  //   this.loading = LoadingState.Processing;
  //   this.settingService.getExternalUserTypeList().subscribe(
  //     res => {
        
  //       this.externalUserTypeList = res;

  //       var index = this.externalUserTypeList.findIndex(x => x['type_name'].toLowerCase() == 'vendor');
        
  //       if (index != -1) {
  //           this.userTypeId= this.externalUserTypeList[index]['id'];
  //           this.getExternalUserList();
  //       }
  //       else{
  //         this.loading = LoadingState.Ready;
        
         
  //       }
  //     },
  //     error=>{
       
  //       this.loading = LoadingState.Ready;
        
  //       this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //     }
  //   )
  // };

  getExternalUserList()
  {
    // this.settingService.getExternalUserListByType(user_type).subscribe(
    //   res => {
        
    //     this.vendorDetail = res['result'];
    //     this.loading = LoadingState.Ready;
    //     this.vendorDetail = this.vendorDetail.filter(x => x.id == this.data.vendor_id)[0]
    //     console.log(this.vendorDetail)
    //   },
    //   error=>{
    //     this.loading = LoadingState.Ready;
        
    //     this.toastr.error('Something went wrong', '', {
    //       timeOut: 3000,
    //     });
    //   }
    // )

    this.settingService.getExternalUserDetails(this.data.vendor_id).subscribe(
      res=>{
        this.vendorDetail = res;
       this.loading = LoadingState.Ready;
      },
      error=>{
        this.loading = LoadingState.Ready;
        
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  close() {
    this.dialogRef.close(true);
  }

}
