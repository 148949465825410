import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillInvoiceService {

  constructor(
    private http: HttpClient
  ) {
    
  } 

  getBillInvoiceHOApprover(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_bills_invoices_ho_user/add/')
  }
  addBillInvoiceHOApprover(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/site_bills_invoices_ho_user/add/', data)
  }
  updateBillInvoiceHOApprover(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/site_bills_invoices_ho_user/edit/' + id + '/', data)
  }

  getBillInvoiceCategoryList(): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_bills_invoices/category/list/?page_size=0')
  }

  getBillInvoiceCategoryDetails(id: number,): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_bills_invoices/category/edit/' + id + '/')
  }


  addBillInvoiceCategory(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/site_bills_invoices/category/add/', data)
  }

  editBillInvoiceCategory(id: number, data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/site_bills_invoices/category/edit/' + id + '/', data)
  }

  addBillInvoiceFile(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/site_bills_invoices/add/', data)
  }

  updateBillInvoiceFile(id,data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/site_bills_invoices/edit/'+id+'/', data)
  }
  
  deleteBillInvoiceFile(id,data: any): Observable<any> {
    return this.http.put(environment.apiEndpoint + 'pms/site_bills_invoices/delete/'+id+'/', data)
  }

  getBillInvoice(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_bills_invoices/list/?' + params)
  }

  getBillInvoiceReportDownload(params: any): Observable<any> {
    return this.http.get(environment.apiEndpoint + 'pms/site_bills_invoices/list/download/?' + params)
  }

  billInvoiceFileApproval(data: any): Observable<any> {
    return this.http.post(environment.apiEndpoint + 'pms/site_bills_invoices/approval/', data)
  }

  
}
