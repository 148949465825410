import { Component, OnInit, Input,ElementRef,ViewChild } from '@angular/core';
import { LoadingState } from '../../../loading/loading.component';
import { ExecutionPurchaseService } from '../../../../services/execution-purchase.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import * as Globals from '../../../../globals';
import { projectSiteServices } from '../../../../services/project-sites.service';
import { LocalService } from '../../../../services/local.service';
import { SettingService } from '../../../../services/setting.service';
import { ApprovalLevelBottomSheetComponent } from '../approval-level-bottom-sheet/approval-level-bottom-sheet.component';
import { RequisitionApprovalModalComponent } from '../requisition-approval-modal/requisition-approval-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import {AppPageStateConfig} from '../../../../../app.config';

@Component({
  selector: 'app-approvals-requisition',
  templateUrl: './approvals-requisition.component.html',
  styleUrls: ['./approvals-requisition.component.scss']
})
export class ApprovalsRequisitionComponent implements OnInit {
  @Input() searchKeyword: string
  headerThOption = [];

  loading: LoadingState = LoadingState.NotReady;
  pendingRequisitionList: any = [];
  totalPendingRequisitionList: number;
  projectChecked = [];
  allChecked: boolean;
  typeChecked: number;
  bulkApproval: null;
  higherLevelPermission:number;
  defaultPagination: number;
  itemNo: number;
  lower_count: number;
  upper_count: number;
  paginationMaxSize: number;
  itemPerPage: number;
  itemPerPageNumList: any = [];
  pageSize: number;
  multipleApprovalItemData: any = [];

  //filter
  fromDate = '';
  toDate = '';
  search_key = '';
  sort_by = '';
  sort_type = '';
  dateRange: any;
  requisitionTypeList = []
  permissionList: any = [];
  currentApproveUser: any;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  requisitionConfig:any;
  selectedItem: any = [];
  scrollAmount: any;
  hasScroll: boolean;
  @ViewChild('target', {static: true}) tableContent: ElementRef;

  constructor(
    private executionPurchaseService: ExecutionPurchaseService,
    public router: Router,
    private toastr: ToastrService,
    private settingService: SettingService,
    public dialog: MatDialog,
    private element: ElementRef,
    private localService: LocalService,
  ) { 
    this.requisitionConfig = AppPageStateConfig.requisitionApprovalConfig.requisitionConfig;
  }

  ngOnInit() {
    if (this.requisitionConfig.searchKeyword != '' && this.searchKeyword != undefined) {
      this.searchKeyword = this.requisitionConfig.searchKeyword;
    }
    if (this.requisitionConfig.fromDate != '') {
      this.fromDate = this.requisitionConfig.fromDate;
    }
    if (this.requisitionConfig.toDate != '') {
      this.toDate = this.requisitionConfig.toDate;
    }
    if (this.requisitionConfig.dateRange != null) {
      this.dateRange = this.requisitionConfig.dateRange;
    }
    if (this.requisitionConfig.projectChecked.length>0) {
      this.projectChecked = this.requisitionConfig.projectChecked;
    }
    if (this.requisitionConfig.typeChecked!=null) {
      this.typeChecked = this.requisitionConfig.typeChecked;
    }
    this.headerThOption = [
      // {
      //   id: 1,
      //   name: "Project ID",
      //   code: "project",
      //   sort_type: '',
      //   has_tooltip: false,
      //   tooltip_msg: '',
      //   selected: true
      // },
      {
        id: 2,
        name: "Site Location",
        code: "site_location",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true,
        class: 'fixed-col'
      },
      {
        id: 3,
        name: "M.R Date",
        code: "mr_date",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true,
        class: 'fixed-col'
      },
      {
        id: 4,
        name: "Type",
        code: "type",
        sort_type: '',
        has_tooltip: false,
        tooltip_msg: '',
        selected: true
      },
      // {
      //   id: 5,
      //   name: "Item Detail",
      //   code: "item_detail",
      //   sort_type: '',
      //   has_tooltip: false,
      //   tooltip_msg: '',
      //   selected: true
      // }
    ];
    this.itemNo = 0;
    this.defaultPagination = 1;
    this.paginationMaxSize = Globals.paginationMaxSize;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    if (this.requisitionConfig.defaultPagination != null) {
      this.defaultPagination = this.requisitionConfig.defaultPagination;
    }
    if (this.requisitionConfig.itemPerPage != null) {
      this.itemPerPage = this.requisitionConfig.itemPerPage;
    }
    if (this.requisitionConfig.pageSize != null) {
      this.pageSize = this.requisitionConfig.pageSize;
    }
    window.addEventListener('scroll', this.scroll, true);
  }

  ngOnChanges() {
    if (this.searchKeyword != '' && this.searchKeyword != undefined) {
      this.search_key = this.searchKeyword;
    } else {
      this.search_key = ''
    }
    this.getRequisitionType();
    // this.getPermissionLevel();
   // console.log(this.currentApproveUser)

  }

  ngAfterViewInit() { //check if table has scroll
    this.scrollAmount = this.element.nativeElement.querySelector('.table-responsive');
    this.hasScroll = (this.scrollAmount.scrollWidth > this.scrollAmount.offsetWidth) ? true : false;
  }

  getConfigData(data){
    this.headerThOption = data
  }

  ShowColunm(column_code){
    var index = this.headerThOption.findIndex(x => x.code == column_code)
    if(index != -1){
      if(this.headerThOption[index]['selected']){
        return true;
      }
      else{
        return false;
      }
    }
  }

  // getPermissionLevel() {
  //   let params: URLSearchParams = new URLSearchParams();
  //   params.set('section', 'requisition');
  //   this.executionPurchaseService.getPermissionLevel(params).subscribe(
  //     res => {
  //       var userid = this.localService.getJsonValue('userid');
  //       if (res['result'].user_approve_details.length > 0) {
  //         this.permissionList = res['result'];
  //         this.higherLevelPermission = this.permissionList['user_approve_details'].length - 1;
  //         this.permissionList.user_approve_details.forEach(x => {
  //           if (x.user_details.id == userid) {
  //             this.currentApproveUser = x;
  //           }
  //         });
  //       }
  //       //console.log(this.currentApproveUser)
  //     },
  //     error => {

  //     }
  //   )
  // }

  openApprovalDialog(requisition_data:number,item: any, i: number, j: number): void {
    console.log(requisition_data)
    console.log(item)
    const approvalDialogRef = this.dialog.open(RequisitionApprovalModalComponent, {
      width: '450px',
      closeOnNavigation: true,
      data: {result: requisition_data,item: item }
    });
    approvalDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getRequisitionApprovalPendingList()
      }

    });
  }

  

   scroll = (event: any): void => {
    // console.log('hii')
    if (this.selectedItem.length > 0) {
      let prevScrollpos = 150;
      let currentScrollPos = window.pageYOffset;

      if (prevScrollpos > currentScrollPos) {
        document.getElementById("bulkPunchApproval").classList.remove("fixedTotop");
      } else {
        document.getElementById("bulkPunchApproval").classList.add("fixedTotop");
      }
    }
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scrollToRight() {
    this.tableContent.nativeElement.scrollLeft += 150;
  }
  scrollToLeft() {
    this.tableContent.nativeElement.scrollLeft -= 150;
  }

   checkedAll(event) {
    this.pendingRequisitionList.forEach((x, index) => { 
      this.checkedThisRow(x, event)
    })
  }

  checkedThisRow(x, event) {
    if (event.checked) {
      x.checked = true;
      var index = this.selectedItem.findIndex(k => k.requisition_master.id == x.requisition_master.id); 
      if (index == -1) {
        this.selectedItem.push(x)
      }
    }
    else {
      x.checked = false;
      var index = this.selectedItem.findIndex(k => k.id == x.id)
      if (index != -1) {
        this.selectedItem.splice(index, 1)
      }
    }
    this.checkAllReverse()

  }

  checkAllReverse() {
    this.allChecked = true;
    this.pendingRequisitionList.forEach((x, index) => {
      if (!x.checked) {
        this.allChecked = false;
      }
    })
  }

  getRequisitionType() {
    this.loading = LoadingState.Processing
    this.executionPurchaseService.requisitionTypes().subscribe(
      res => {
        this.requisitionTypeList = res['result'];
        this.getRequisitionApprovalPendingList();

      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )
  }

  openBottomSheet(permission_details: any) {
    let dialogRef = this.dialog.open(ApprovalLevelBottomSheetComponent, {
      // width: '525px',
      data: { from: 'requisition', result: permission_details }
    });
    dialogRef.afterClosed().subscribe(result => {

    })
  }

  getRequisitionApprovalPendingList() {
    this.loading = LoadingState.Processing
    let params: URLSearchParams = new URLSearchParams();
    params.set('section_name', 'requisition');
    params.set('page', this.defaultPagination.toString());

    if (this.fromDate != '') {
      params.set('start_date', this.fromDate.toString());
    }
    if (this.toDate != '') {
      params.set('end_date', this.toDate.toString());
    }
    if (this.itemPerPage) {
      params.set('page_count', this.itemPerPage.toString());
    }
    if (this.projectChecked.length > 0) {
      params.set('site_location', decodeURI(this.projectChecked.toString()));
    }

    if (this.typeChecked != undefined) {
      params.set('type_name', this.typeChecked.toString());
    }
    if (this.search_key != '') {
      params.set('search', this.search_key.toString());
    }
    if (this.sort_by != '') {
      params.set('field_name', this.sort_by.toString());
    }
    if (this.sort_type != '') {
      params.set('order_by', this.sort_type.toString());
    }

    this.executionPurchaseService.getPendingApprovalList(params).subscribe(
      res => {
        this.pendingRequisitionList = res['results'];
        this.totalPendingRequisitionList = res.count;

        this.itemNo = (this.defaultPagination - 1) * this.itemPerPage;
        this.lower_count = this.itemNo + 1;
        if (this.totalPendingRequisitionList > this.itemPerPage * this.defaultPagination) {
          this.upper_count = this.itemPerPage * this.defaultPagination

        }
        else {
          this.upper_count = this.totalPendingRequisitionList;
        }
        this.loading = LoadingState.Ready;
      },
      error => {
        this.loading = LoadingState.Ready;
        this.toastr.error('Something went wrong', '', {
          timeOut: 3000,
        });
      }
    )

  }

  redirect(id: number) {
    const navigationExtras: NavigationExtras = {
      state: {
        activestate:'requisition-approval'
      }
    };
    this.router.navigate(['execution-purchase/add-requisition/'+id],navigationExtras);
    //this.router.navigateByUrl('execution-purchase/add-requisition/' + id + '/requisition-approval');

  }

  sortTable(value) {
    let type = '';
    this.headerThOption.forEach(function (optionValue) {
      if (optionValue.code == value) {
        if (optionValue.sort_type == 'desc') {
          type = 'asc';
        }
        else {
          type = 'desc';
        }
        optionValue.sort_type = type;
      }
      else {
        optionValue.sort_type = '';
      }
    });

    this.sort_by = value;
    this.sort_type = type;
    this.loading = LoadingState.Processing;
    this.defaultPagination = 1;
    this.getRequisitionApprovalPendingList();
  }

  pagination() {
    this.loading = LoadingState.Processing;
    this.requisitionConfig.defaultPagination = this.defaultPagination;
    this.getRequisitionApprovalPendingList();
  }

  dataFilter() {
    this.loading = LoadingState.Processing;
    this.pageSize = this.itemPerPage;
    this.defaultPagination = 1;
    this.requisitionConfig.typeChecked = this.typeChecked;
    this.requisitionConfig.defaultPagination = this.defaultPagination;
    this.requisitionConfig.pageSize = +this.itemPerPage;
    this.getRequisitionApprovalPendingList();
  }

  reload() {
    this.loading = LoadingState.Processing;
    this.fromDate = '';
    this.toDate = '';
    this.sort_type = '';
    this.sort_by = '';
    this.search_key = '';
    this.projectChecked = [];
    this.typeChecked = null;
    this.itemPerPage = Globals.itemPerPage;
    this.pageSize = Globals.pageSize;
    
    this.requisitionConfig.dateRange = this.dateRange;
    this.requisitionConfig.typeChecked = this.typeChecked;
    this.requisitionConfig.fromDate = this.fromDate;
    this.requisitionConfig.toDate = this.toDate;
    this.requisitionConfig.defaultPagination = this.defaultPagination;
    this.requisitionConfig.itemPerPage = this.itemPerPage;
    this.requisitionConfig.projectChecked = this.projectChecked;

    this.getRequisitionApprovalPendingList();

  }

  dateTimeReset() {
    this.dateRange = null;
    this.fromDate = '';
    this.toDate = '';
    this.requisitionConfig.fromDate = this.fromDate;
    this.requisitionConfig.toDate = this.toDate;
    this.requisitionConfig.dateRange = this.dateRange;
    this.dataFilter();
  }

  formatHandler(event) {
    if (event.value[0] != null) {
      this.fromDate = this.settingService.formatDate(event.value[0])
      this.toDate = this.settingService.formatDate(event.value[1])
      this.requisitionConfig.fromDate = this.fromDate;
      this.requisitionConfig.toDate = this.toDate;
      this.requisitionConfig.dateRange = this.dateRange;
      this.dataFilter()
    }
    else {
      this.reload()
    }

  }


  onListCountSelectionChange(item): void {
    this.itemPerPage = item;
    this.requisitionConfig.itemPerPage = +item;
    this.dataFilter();
  }

  onListProjectsSelectionChange(project): void {
    this.projectChecked = project;
    this.requisitionConfig.projectChecked = this.projectChecked;
    this.dataFilter();
  }

  bulkAction(val) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.confirmMessage = "Are you sure for this Action?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = LoadingState.Processing;
        this.selectedItem.forEach(x => {
          var data = {
            requisitions_master: x.requisition_master.id,
            project: x.requisition_master.project,
            site_location: x.requisition_master.site_location,
            type: x.requisition_master.type.id,
            item_approval_details: [],
          };
          var itemDetails = []
          x.requisition_master.requisition_details.forEach(value => {
            var d = {
              uom: value.uom,
              as_per_drawing: value.actual_consumption.as_per_drawing,
              current_stock: value.current_stock,
              initial_estimate: value.actual_consumption.initial_estimate,
              item: value.item_details.id,
              arm_approval: +val,
              // permission_level: this.currentApproveUser.id,
              approved_quantity: value.quantity,
            }
            itemDetails.push(d);
          });
          data.item_approval_details = itemDetails;
          this.multipleApprovalItemData.push(data);
        }); 
        var dataObj = {
          'requisition_details' : this.multipleApprovalItemData,
        }
        this.executionPurchaseService.btachPurchaseRequisitionApproval(dataObj).subscribe(
          res => {
            this.loading = LoadingState.Ready
            this.checkAllReverse();
            this.bulkApproval = null;
            this.getRequisitionApprovalPendingList();
            this.selectedItem = [];
          },
          error => {
            // console.log(error)
            this.toastr.error('Something went wrong', '', {
              timeOut: 3000,
            });
            this.loading = LoadingState.Ready
          }
        );
      } else {
        this.bulkApproval = null;
      }
    })
  }

}
