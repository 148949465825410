import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import {AppPageStateConfig} from '../../../../app.config';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {
  reportarr: any = [];
  @Input() sitearr: any = [];
  @Input() projarr: any = [];
  @Input() siteid: number;
  @Input() projectid: number;
  @Input() currentmaintab: string;
  @Output() getSelectedSite: EventEmitter<number> = new EventEmitter<number>();
  date: any;
  currenttab: string = 'progress';
  @ViewChild('ctdTabset', {static: true}) ctdTabset;
  dailyProgressReportConfig:any;


  constructor() {
    this.dailyProgressReportConfig = AppPageStateConfig.progressReportsConfig.dailyProgressReportConfig;
    this.currenttab = this.dailyProgressReportConfig.tab;

    if(this.dailyProgressReportConfig.date!=null){
      this.date = this.dailyProgressReportConfig.date;
    }
   }

  ngOnInit() {

  }

  ngOnChanges() {

  }

  setProjectId(value) {
    this.getSelectedSite.emit(value)
  }

  fetchReports() {
    this.dailyProgressReportConfig.date = this.date;
  }

  onTabChange($event) {
    this.currenttab = $event.nextId;
    this.dailyProgressReportConfig.tab = this.currenttab;
  }

  ngAfterViewInit() {
    this.switchNgBTab(this.currenttab);
   
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }
}
