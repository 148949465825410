import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DailyWorksheetService } from '../../../services/daily-worksheet.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/setting.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LoadingState } from '../../loading/loading.component';
import { forkJoin } from 'rxjs';
import * as Globals from '../../../globals';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  loading: LoadingState = LoadingState.NotReady;

  taskForm: FormGroup
  appointmentForm: FormGroup;
  manualEntryForm: FormGroup;
  taskList = []
  appointmentList = []
  type:number
  maxStartDate: any
  maxEndDate: any;
  taskData:any = {
    start_time:null,
    end_time:null,
    date:null,
    work_done:null
  };
  minDate: any = new Date(Date.now() - Globals.worksheetDaysLimit * 24 * 60 * 60 * 1000);

  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private taskService: DailyWorksheetService,
    private toastr: ToastrService,
    private settingService: SettingService,
  ) { 
    console.log(this.data)
  }

  ngOnInit() {
      this.maxStartDate =  this.settingService.formatDate(new Date()) + " 23:59:00";
      this.taskData.sheet_id = this.data.details.id;
      this.taskData.start_time = this.data.details.start_time;
      this.taskData.end_time = this.data.details.end_time;
      this.taskData.date = this.data.details.date;
      this.taskData.work_done = this.data.details.work_done;
      this.type = this.data.type;
      
      this.maxEndDate = this.settingService.formatDate(this.taskData.start_time) + " 23:59:00"
      
      // if(this.data.details.type == 1)
      // this.type = 1     
      
      // if(this.data.details.type == 2)
      // this.type = 2     

      // if(this.data.details.type == 3)
      // this.type = 3      
    
    
    this.taskForm = this.formBuilder.group({
      task:[null,Validators.required],
      start_time:[null,Validators.required],
      end_time:[null,Validators.required]
    }) 
    this.appointmentForm = this.formBuilder.group({
      appointment:[null,Validators.required],
      start_time:[null,Validators.required],
      end_time:[null,Validators.required]
    })  
    this.manualEntryForm = this.formBuilder.group({
      date:[null,Validators.required],     
      start_time:[null,Validators.required],
      end_time:[null,Validators.required],
      work_done:[null,Validators.required]
    });
    this.loading = LoadingState.Ready;
    // this.getDailySheetTaskAndAppointmentList()
  }


  // getDailySheetTaskAndAppointmentList(){
  //   this.loading = LoadingState.Processing;
  //   var limitDays = "days="+Globals.timesheetDaysLimit
  //   var forkArray = [];
  //   forkArray.push(this.taskService.getDailySheetTaskList(limitDays))
  //   forkArray.push(this.taskService.getDailySheetAppointmentList(limitDays))
  //   forkJoin(forkArray).subscribe(
  //     ([taskList,appointmentList]) => {
  //       if(taskList){
  //         this.taskList = taskList['result']
  //       }
  //       if(appointmentList){
  //         this.appointmentList = appointmentList['result']
  //       }
  //       this.loading = LoadingState.Ready;
  //     },
  //     err => {
  //       this.loading = LoadingState.Ready;
  //       this.toastr.error('Something went wrong', '', {
  //         timeOut: 3000,
  //       });
  //     }
  //   )
  // }

  dateHandler($event) {
    this.maxEndDate = this.settingService.formatDate(this.taskData.start_time) + " 23:59:00"
  }

  dateReset(){        
    this.taskData.date = '';    
  }

  startTimeReset(){        
    this.taskData.start_time = '';    
  }

  endTimeReset(){    
    this.taskData.end_time = '';    
  }


  // submitTask(){   
  //   if(this.taskForm.valid){      
  //     this.loading = LoadingState.Processing;
  //     let data = {
  //       task:this.taskForm.value.task,
  //       task_description:this.taskForm.value.task_description,
  //       start_time:this.settingService.getOwlProperDate(this.taskForm.value.start_time),
  //       end_time:this.settingService.getOwlProperDate(this.taskForm.value.end_time)
  //     }
  //     this.taskService.editTaskinTimesheetV2(this.taskData.sheet_id, data).subscribe(
  //       res=>{            
  //         this.close(true);
  //       },
  //       error=>{
  //         this.toastr.error('Something went wrong', '', {
  //           timeOut: 3000,
  //         });
  //         this.loading = LoadingState.Ready;
  //       }
  //     )
  //   }else{
  //     this.markFormGroupTouched(this.taskForm);
  //   }
  // }

  // submitAppointment(){
  //   if(this.appointmentForm.valid){
  //     this.loading = LoadingState.Processing;
  //     let data = {
  //       appointment:this.appointmentForm.value.appointment,
  //       start_time:this.settingService.getOwlProperDate(this.appointmentForm.value.start_time),
  //       end_time:this.settingService.getOwlProperDate(this.appointmentForm.value.end_time)
  //     }
  //     this.taskService.editTaskinTimesheetV2(this.taskData.sheet_id, data).subscribe(
  //       res=>{            
  //         this.close(true);
  //       },
  //       error=>{
  //         this.toastr.error('Something went wrong', '', {
  //           timeOut: 3000,
  //         });
  //         this.loading = LoadingState.Ready;
  //       }
  //     )
  //   }else{
  //     this.markFormGroupTouched(this.appointmentForm);
  //   }
  // }

  submitManualEntry(){
    // console.log(this.manualEntryForm.value)   
    if(this.manualEntryForm.valid){
      this.loading = LoadingState.Processing;
      let data = {
        date:this.settingService.getOwlProperDate(this.manualEntryForm.value.date),
        work_done:this.manualEntryForm.value.work_done,
        start_time:this.settingService.getOwlProperDate(this.manualEntryForm.value.start_time),
        end_time:this.settingService.getOwlProperDate(this.manualEntryForm.value.end_time)
      }
      this.taskService.updateDailyWorksheet(this.taskData.sheet_id, data).subscribe(
        res=>{            
          this.close(true);
        },
        error=>{
          this.toastr.error('Something went wrong', '', {
            timeOut: 3000,
          });
          this.loading = LoadingState.Ready;
        }
      )    
    }else{
      this.markFormGroupTouched(this.manualEntryForm);
    }
  }

  setType(type){
    this.type = type.value
    if(this.type == this.data.details.type){
      this.taskData.sheet_id = this.data.details.id;
      this.taskData.task_description = this.data.details.task_description;
      this.taskData.task_name = this.data.details.task_name;
      this.taskData.start_time = this.data.details.start_time;
      this.taskData.end_time = this.data.details.end_time;
      this.taskData.appointment = this.data.details.appointment;
      this.taskData.task = this.data.details.task;

      if(this.data.details.type == 1)
      this.type = 1     
      
      if(this.data.details.type == 2)
      this.type = 2     

      if(this.data.details.type == 3)
      this.type = 3   
    }else{
      this.taskData = {
        sheet_id: this.data.details.id,
        task_name:null,
        task_description:null,
        start_time:this.data.details.start_time,
        end_time:this.data.details.end_time,
        appointment: null,
        task: null
      };
    }

    console.log(this.taskData)
  }


  close(key:boolean) {
    this.dialogRef.close(key);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && (form.get(field).dirty || form.get(field).touched);
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'is-invalid': form.get(field).invalid && (form.get(field).dirty || form.get(field).touched),
      'is-valid': form.get(field).valid && (form.get(field).dirty || form.get(field).touched)
    };
  }
}
