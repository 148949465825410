import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { projectSiteServices } from '../../../services/project-sites.service';
import { reportingService } from '../../../services/reporting.service';
import { SettingService } from '../../../services/setting.service';
import { LoadingState } from '../../../components/loading/loading.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-weekly-report-pandm',
  templateUrl: './weekly-report-pandm.component.html',
  styleUrls: ['./weekly-report-pandm.component.scss']
})
export class WeeklyReportPandmComponent implements OnInit {

  @Input() currentmaintab: string;
  @Input() date: any;
  @Input() sweekstartdate: any;
  @Input() sweekenddate: any;
  @Input() smonthdate: any;
  @Input() projectid: number;
  @Input() siteid: number;
  public loading: LoadingState = LoadingState.NotReady;
  progressparam: any = '';
  reportarr: any = [];
  locationName: string;
  constructor(
    public sitesService: projectSiteServices,
    public reportService: reportingService,
    public settingService: SettingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getPandMReports();
  }

  getPandMReports() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'weekly') {
      if (this.sweekstartdate) {
        let sdate = this.sweekstartdate;
        let edate = this.sweekenddate;
        this.progressparam = 'start_date=' + sdate + '&end_date=' + edate + '&site_location=' + this.siteid + '&project=' + this.projectid;//aa //aa
      }
      else {
        this.progressparam = '';
      }
    }
    // console.log(this.progressparam)

    if (this.projectid && this.siteid && this.progressparam) {
      this.reportService.getPandMWeeklyReportData(this.progressparam).subscribe(
        data => {
          console.log(data)
          var res: any = data['results'];
          this.reportarr = [];
          this.reportarr = res;
          this.loading = LoadingState.Ready;
          let tempString = '';
          this.reportarr.forEach((el, key) => {
            if(el.machinery_required_during_next_week != null) {
              el.machinery_required_during_next_week.activity_details.forEach(act => {
                if(tempString != '') {
                  tempString = tempString + act.name + ',';
                } else {
                  tempString = act.name + ',';
                }
              });
              tempString = tempString.slice(0,-1);
            }
            this.reportarr[key].next_week_activity = tempString
            tempString = '';
          });
        },
        error => {
          this.loading = LoadingState.Ready;
          console.log(error)
        }
      );
    }
    else {
      this.loading = LoadingState.Ready;
    }
  }

  excelDownload() {
    this.loading = LoadingState.Processing;
    if (this.currentmaintab == 'weekly') {
      if (this.sweekstartdate) {
        let sdate = this.sweekstartdate;
        let edate = this.sweekenddate;
        this.progressparam = 'is_download=yes&start_date=' + sdate + '&end_date=' + edate + '&site_location=' + this.siteid + '&project=' + this.projectid;
      }
      else {
        this.progressparam = '';
      }
    }
    if (this.projectid && this.siteid && this.progressparam) {
      this.reportService.getPandMWeeklyReportData(this.progressparam).subscribe(
        data => {
          window.open(data.url, '_blank');
          this.loading = LoadingState.Ready;
        },
        error => {
          this.loading = LoadingState.Ready;
          console.log(error)
        }
      );
    }
    else {
      this.loading = LoadingState.Ready;
    }
  }
}
