import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { SettingService } from '../../../services/setting.service'
import { AttendanceService } from '../../../services/attendance.service';
import { EmployeesService } from '../../../services/employees.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-flexi-daily-attendacne-modal',
  templateUrl: './flexi-daily-attendacne-modal.component.html',
  styleUrls: ['./flexi-daily-attendacne-modal.component.scss']
})
export class FlexiDailyAttendacneModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FlexiDailyAttendacneModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingService:SettingService,
  ) { }

  ngOnInit() {
    //console.log(this.data)
  }

  close(){
    
      
    this.dialogRef.close();
    
    // console.log(data)
  }
}
